import { assignInlineVars } from '@vanilla-extract/dynamic'
import clsx from 'clsx'
import { FormEventHandler, forwardRef } from 'react'
import { Dialog as AriaDialog, DialogProps as AriaDialogProps } from 'react-aria-components'
import { isDefined, isNil } from 'typeguards'
import { elementIsNotInDialogWhitelist } from '../../utils'
import { Box } from '../box'
import { Flex } from '../flex'
import { Grid } from '../grid'
import { Modal } from '../modal'
import { Separator } from '../separator'
import { Text } from '../text'
import {
  buttonsWrapperCx,
  dialogCx,
  dialogWidthVar,
  dialogWrapperCx,
  overlayCx,
  separatorCx,
} from './Dialog.css'
import { ControlledDialogProps, UncontrolledDialogProps } from './types'

type DialogContentsProps = (UncontrolledDialogProps | ControlledDialogProps) & {
  buttons?: AriaDialogProps['children']
  close: VoidFunction
  isOpen: boolean
  width?: string
}

export const DialogContents = forwardRef<HTMLDivElement, DialogContentsProps>(
  (
    {
      isDismissable,
      onOpenChange,
      isOpen,
      className,
      title,
      description,
      children,
      contentLayout = 'default',
      buttons,
      close,
      onSubmit,
      width,
      ...props
    },
    ref
  ) => {
    const isForm = isDefined(onSubmit)
    const handleSubmit: FormEventHandler<HTMLElement> = (e) => {
      e.preventDefault()
      e.stopPropagation()
      onSubmit?.(close)
    }
    return (
      <Modal
        className={dialogWrapperCx}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        shouldCloseOnInteractOutside={elementIsNotInDialogWhitelist}
        isDismissable={isDismissable}
        overlayClassName={overlayCx}
        style={assignInlineVars({ [dialogWidthVar]: width })}
      >
        <AriaDialog {...props} className={clsx([dialogCx, className])} aria-label={title} ref={ref}>
          <Flex
            direction="column"
            rowGap="1200"
            as={isForm ? 'form' : 'div'}
            onSubmit={handleSubmit}
          >
            <Grid py="000" px="900">
              <Text variant="headingSm" color="colorsTextNeutralDefaultHighContrast" role="heading">
                {title}
              </Text>
              {isDefined(description) && (
                <Text variant="paragraphMd" color="colorsTextNeutralDefaultLowContrast">
                  {description}
                </Text>
              )}
            </Grid>
            {children && (
              <Box
                px={contentLayout === 'insert' ? '000' : '900'}
                pb={isNil(buttons) ? '900' : undefined}
              >
                {typeof children === 'function' ? children({ close }) : children}
              </Box>
            )}

            {buttons && (
              <>
                <Box className={separatorCx}>
                  <Separator spacing="none" orientation="horizontal" />
                </Box>
                <div className={buttonsWrapperCx}>
                  {typeof buttons === 'function' ? buttons({ close }) : buttons}
                </div>
              </>
            )}
          </Flex>
        </AriaDialog>
      </Modal>
    )
  }
)

DialogContents.displayName = 'DialogContents'
