import { StyleObject } from 'typ'
import { COLLAPSED_SIDE_NAV_WIDTH, SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from './constants'
import { scrollbars } from './scrollbars'

export const space = [0, 4, 8, 16, 24, 32, 48, 64, 96, 128]
export const sizes = {
  'sidebar-width': SIDE_NAV_WIDTH,
  'collapsed-sidebar-width': COLLAPSED_SIDE_NAV_WIDTH,
}

export const stableOverflowRules: StyleObject = {
  overflowX: 'hidden',
  overflowY: 'auto',
  scrollbarGutter: 'stable',
}

type Layout =
  | 'container'
  | 'max-width'
  | 'tc-container'
  | 'tc-table-container'
  | 'reports-container'
  | 'full-page-list-container'
  | 'slim-container'
  | 'step-process-container'
  | 'step-process-no-footer-container'
  | 'step-process-sized-container'

export const layout: Record<Layout, StyleObject> = {
  container: {
    maxWidth: 1440,
    mx: 'auto',
    py: 3,
    px: [4, 4, 4, 4, 4, 5, 5],
  },
  'max-width': {
    maxWidth: '100%',
    mx: 'auto',
    py: 5,
    px: [4, 4, 4, 4, 4, 5, 5],
  },
  'tc-container': {
    ...stableOverflowRules,
    maxWidth: ['100%'],
    mx: 0,
    pt: 0,
    pb: 5,
    px: 5,
    height: `calc(100vh - ${TOP_NAV_HEIGHT})`,
    ...scrollbars,
  },
  'tc-table-container': {
    bg: 'white',
    maxWidth: '100%',
    mx: 0,
    pt: 0,
    px: 5,
    pb: 5,
    height: `calc(100vh - ${TOP_NAV_HEIGHT})`,
    ...scrollbars,
    ...stableOverflowRules,
  },
  'reports-container': {
    ...stableOverflowRules,
    bg: 'white',
    width: '100vw',
    pt: 5,
    px: 5,
    pb: 5,
    height: `calc(100vh - ${TOP_NAV_HEIGHT})`,
    maxHeight: `calc(100vh - ${TOP_NAV_HEIGHT})`,
    ...scrollbars,
  },
  'full-page-list-container': {
    bg: 'white',
    maxWidth: '100%',
    m: 0,
    p: 0,
    height: `calc(100vh - ${TOP_NAV_HEIGHT})`,
  },
  'slim-container': {
    maxWidth: '62.5rem',
    width: '62.5rem',
    mx: 'auto',
  },
  'step-process-container': {
    ...stableOverflowRules,
    ...scrollbars,
    height: `calc(100vh - ${TOP_NAV_HEIGHT} * 2)`,
    py: 4,
    px: 4,
  },
  'step-process-no-footer-container': {
    ...stableOverflowRules,
    ...scrollbars,
    height: `calc(100vh - ${TOP_NAV_HEIGHT})`,
    py: 4,
    px: 4,
  },
  'step-process-sized-container': {
    maxWidth: '75rem',
    minWidth: '62.5rem',
    width: '100%',
  },
}
