import clsx from 'clsx'
import { forwardRef } from 'react'
import { mergeProps, useHover } from 'react-aria'
import { isDefined } from 'typeguards'
import { Box } from '../../box'
import { Flex } from '../../flex'
import { Label } from '../../label'
import { Text } from '../../text'
import { FieldWrapperProps } from '../types'
import { contentsCx, fieldWrapperCx, labelCx } from './FieldWrapper.css'
import { HelpText } from './HelpText'

export const FieldWrapper = forwardRef<HTMLDivElement, FieldWrapperProps>(
  (
    {
      characterCount,
      children,
      className,
      errorId,
      hasFocus,
      helpText,
      hideLabel,
      id,
      isDisabled,
      isError,
      label,
      labelProps,
      size = 'md',
      inputElement = 'input',
      inputWrapperProps,
      ...props
    },
    ref
  ) => {
    const { hoverProps, isHovered } = useHover({ isDisabled })

    return (
      <Box
        position="relative"
        {...props}
        ref={ref}
        data-error={Boolean(isError)}
        data-size={size}
        className={className}
      >
        <Flex align="center" justify="space-between">
          {label && (
            <Label
              data-hidden={Boolean(hideLabel)}
              htmlFor={id}
              {...labelProps}
              className={labelCx({ isHidden: hideLabel })}
            >
              {label}
            </Label>
          )}
          {characterCount && (
            <Text as="span" variant="labelXs" color="baseColorsNeutral11">
              {characterCount}
            </Text>
          )}
        </Flex>
        <div
          className={clsx([
            fieldWrapperCx({
              size,
              isError,
              isDisabled,
              isActive: hasFocus || isHovered,
              inputElement,
            }),
          ])}
          {...mergeProps(hoverProps, inputWrapperProps)}
        >
          <div className={contentsCx}>{children}</div>
        </div>
        {isDefined(helpText) && (
          <HelpText id={errorId} isError={isError} isDisabled={isDisabled}>
            {helpText}
          </HelpText>
        )}
      </Box>
    )
  }
)

FieldWrapper.displayName = 'FieldWrapper'
