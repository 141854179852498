import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form'
import { AlphanumericProps, NumberProps, PatternProps, TextProps } from '../input-boxes/types'
import { TextareaField, TextareaFieldProps } from '../input-fields'
import { ControlledFieldCoreProps } from './types'

type Omitted = 'inputRef' | 'isError' | 'helpText' | 'onChange' | 'value' | 'onBlur' | 'name'
type FilteredTextareaFieldProps =
  | Omit<AlphanumericProps, Omitted>
  | Omit<NumberProps, Omitted>
  | Omit<PatternProps, Omitted>
  | Omit<TextProps, Omitted>

type ControlledTextAreaFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'disabled' | 'render'> &
  TextareaFieldProps &
  ControlledFieldCoreProps &
  FilteredTextareaFieldProps

export const ControlledTextAreaField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  isDisabled,
  hideHelpText,
  ...props
}: ControlledTextAreaFieldProps<TFieldValues, TName>) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    shouldUnregister={shouldUnregister}
    defaultValue={defaultValue}
    disabled={isDisabled}
    render={({ field: { disabled, ref, ...rest }, fieldState }) => (
      <TextareaField
        {...props}
        {...rest}
        isDisabled={disabled || isDisabled}
        inputRef={ref}
        isError={Boolean(fieldState.error)}
        helpText={hideHelpText ? undefined : fieldState.error?.message ?? ''}
      />
    )}
  />
)
