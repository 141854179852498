import clsx from 'clsx'
import { Toaster, ToasterProps } from 'sonner'
import { DIALOG_WHITELIST_CLASSNAME } from '../../constants'
import { sonnerContainerCx } from './Toast.css'

export interface ToastContainerProps {
  id?: string
}

export const SonnerToastContainer = (props: ToasterProps) => (
  <Toaster
    position="bottom-right"
    expand
    className={clsx([sonnerContainerCx, DIALOG_WHITELIST_CLASSNAME])} // The toast-container class is used to prevent the modal from closing when clicking on a toast
    gap={8}
    pauseWhenPageIsHidden
    toastOptions={{
      unstyled: true,
    }}
    {...props}
  />
)
