/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { useTheme } from 'theme'
import { ToastContainer as BaseToastContainer } from 'toast'

export interface ToastContainerProps {
  id?: string
}
export const ToastContainer: FC<ToastContainerProps> = ({ id = 'default' }) => {
  const theme = useTheme()

  return (
    <BaseToastContainer
      containerId={id}
      bodyStyle={{
        fontSize: theme.fontSizes[2],
        fontFamily: theme.fonts.body,
        color: theme.rawColors.text,
        lineHeight: '150%',
      }}
    />
  )
}
