const ADMIN_BASE_PATH = 'tensorenergy.jp'

// HTTPS
export const ADMIN_LOCAL_BASE_URL = 'http://localhost:3000'
export const ADMIN_DEV_BASE_URL = `https://ta536dev.${ADMIN_BASE_PATH}`
export const ADMIN_STAGING_BASE_URL = `https://ta536staging.${ADMIN_BASE_PATH}`
export const ADMIN_PROD_BASE_URL = `https://ta536.${ADMIN_BASE_PATH}`

// AUTH
export const ADMIN_LOGOUT_URL = '/logout'

// VERCEL EDGE CONFIG
export const GET_VERCEL_EDGE_CONFIG_API_URL = '/api/get-vercel-edge-config'
export const UPDATE_VERCEL_EDGE_CONFIG_API_URL = '/api/update-vercel-edge-config'

// WORKSPACES
export const ADMIN_WORKSPACES_BASE_URL = '/workspaces'
export const ADMIN_WORKSPACES_LIST_URL = `${ADMIN_WORKSPACES_BASE_URL}/list`
export const ADMIN_WORKSPACES_VIEW_URL = `${ADMIN_WORKSPACES_BASE_URL}/view`

// USERS
export const ADMIN_USERS_BASE_URL = '/users'
export const ADMIN_USERS_LIST_URL = `${ADMIN_USERS_BASE_URL}/list`
export const ADMIN_USERS_VIEW_URL = `${ADMIN_USERS_BASE_URL}/view`

// SIMULATED SITES
export const ADMIN_SIMULATED_SITES_BASE_URL = '/simulated-sites'
export const ADMIN_SIMULATED_SITES_LIST_URL = `${ADMIN_SIMULATED_SITES_BASE_URL}/list`
export const ADMIN_SIMULATED_SITES_VIEW_URL = `${ADMIN_SIMULATED_SITES_BASE_URL}/view`
export const ADMIN_SIMULATED_SITES_NEW_URL = `${ADMIN_SIMULATED_SITES_BASE_URL}/new`
