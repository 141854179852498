import { useContext } from 'react'
import { OverlayTriggerStateContext } from 'react-aria-components'

export const useDialogContext = () => {
  const context = useContext(OverlayTriggerStateContext)

  if (!context) throw Error('useDialogContext must be used within a DialogTrigger component')

  return context
}
