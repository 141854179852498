import clsx from 'clsx'
import {
  MutableRefObject,
  ReactElement,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react'
import { Placement, useFocusVisible } from 'react-aria'
import { ButtonProps, Dialog, DialogTrigger, OverlayArrow } from 'react-aria-components'
import { TestProps } from 'typ'
import { Popover } from '../popover'
import { arrowCx, contentsWrapperCx, popoverCx } from './PopupCard.css'

export type PopupCardImperativeRef = {
  close: VoidFunction
}
export interface PopupCardTriggerProps extends TestProps {
  className?: string
  children: ReactElement<ButtonProps>
  popupContents: ReactNode
  placement?: Placement
  imperativeRef?: MutableRefObject<PopupCardImperativeRef | null>
}

export const PopupCardTrigger = forwardRef<HTMLDivElement, PopupCardTriggerProps>(
  (
    { className, popupContents, placement = 'top left', children, imperativeRef, ...props },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const { isFocusVisible } = useFocusVisible()

    // If we find this problematic, the second best option is to use a render props pattern
    useImperativeHandle(imperativeRef, () => ({
      close: () => setIsOpen(false),
    }))

    return (
      <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
        {children}
        <Popover placement={placement} className={popoverCx} ref={ref} offset={8}>
          <Dialog
            {...props}
            role="dialog"
            className={clsx([contentsWrapperCx({ isFocusVisible }), className])}
          >
            {popupContents}
          </Dialog>
          <OverlayArrow
            className={({ placement: arrowPlacement }) => arrowCx({ placement: arrowPlacement })}
          />
        </Popover>
      </DialogTrigger>
    )
  }
)

PopupCardTrigger.displayName = 'PopupCardTrigger'
