import { forwardRef } from 'react'
import { ControlledDialog } from './ControlledDialog'
import { UncontrolledDialog } from './UncontrolledDialog'
import { ControlledDialogProps, UncontrolledDialogProps } from './types'

const isControlled = (
  props: UncontrolledDialogProps | ControlledDialogProps
): props is ControlledDialogProps => 'isOpen' in props && 'onOpenChange' in props

export type DialogProps = ControlledDialogProps | UncontrolledDialogProps
export const Dialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) =>
  isControlled(props) ? (
    <ControlledDialog {...props} ref={ref} />
  ) : (
    <UncontrolledDialog {...props} ref={ref} />
  )
)

Dialog.displayName = 'Dialog'
