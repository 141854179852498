import { usePrevious } from 'hooks'
import { forwardRef, useEffect } from 'react'
import { DialogContents } from './DialogContents'
import { useDialogContext } from './context'
import { UncontrolledDialogProps } from './types'

export const UncontrolledDialog = forwardRef<HTMLDivElement, UncontrolledDialogProps>(
  ({ onOpenChange, ...props }, ref) => {
    const { isOpen, setOpen, close } = useDialogContext()
    const previousIsOpen = usePrevious(isOpen)

    useEffect(() => {
      if (previousIsOpen !== isOpen) {
        onOpenChange?.(isOpen)
      }
    }, [isOpen, onOpenChange, previousIsOpen])

    return (
      <DialogContents {...props} isOpen={isOpen} onOpenChange={setOpen} close={close} ref={ref} />
    )
  }
)

UncontrolledDialog.displayName = 'UncontrolledDialog'
