var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b2cfd32629187b366513ab34dda29cc9572a02c6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable sonarjs/no-identical-functions */
import { init as initSentry, Replay } from '@sentry/nextjs'
import { filterEventsByEnv, ignoredClientErrorMessages } from 'sentry-utils'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT =
  process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
const isProd =
  !['development', 'test'].includes(process.env.NODE_ENV) && SENTRY_ENVIRONMENT === 'production'

if (isProd) {
  initSentry({
    dsn:
      SENTRY_DSN ||
      'https://b73f196de4d24ed0a4dcb6b66d80aace@o1299879.ingest.sentry.io/4504019700154368',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: SENTRY_ENVIRONMENT === 'production' ? 1.0 : 0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    beforeSendTransaction: filterEventsByEnv('production'),
    beforeSend: filterEventsByEnv('production'),
    ignoreErrors: ignoredClientErrorMessages,
  })
}
