import { forwardRef } from 'react'
import { InfoFillIcon } from '../../../icons'
import { HelpTextProps } from '../types'
import { helpTextCx } from './HelpText.css'

export const HelpText = forwardRef<HTMLSpanElement, HelpTextProps>(
  ({ children, isError, isDisabled, ...props }, ref) => {
    const isIconHidden = !children || (typeof children === 'string' && children.length === 0)
    return (
      <div className={helpTextCx({ isError, isDisabled })}>
        {!isIconHidden && <InfoFillIcon size="3xs" />}
        <span {...props} data-error={Boolean(isError)} ref={ref}>
          {children ?? ''}
        </span>
      </div>
    )
  }
)

HelpText.displayName = 'HelpText'
