import { useComposition } from 'hooks'

type UseCompositionHandlersProps<T> = T & {
  isComposing?: boolean
  onCompositionStartCapture?: React.CompositionEventHandler
  onCompositionEndCapture?: React.CompositionEventHandler
}
export function useCompositionHandlersProps<T>(props: UseCompositionHandlersProps<T>) {
  const {
    isComposing: externalIsComposing,
    onCompositionEndCapture: externalOnCompositionEndCapture,
    onCompositionStartCapture: externalOnCompositionStartCapture,
    ...rest
  } = props
  const { isComposing: internalIsComposing, compositionHandlers: internalCompositionHandlers } =
    useComposition()
  return {
    isComposing: externalIsComposing ?? internalIsComposing,
    onCompositionStartCapture:
      externalOnCompositionStartCapture ?? internalCompositionHandlers.onCompositionStartCapture,
    onCompositionEndCapture:
      externalOnCompositionEndCapture ?? internalCompositionHandlers.onCompositionEndCapture,
    ...rest,
  }
}
