import { CompositionEvent, useCallback, useMemo, useState } from 'react'

export function useComposition() {
  const [isComposing, setIsComposing] = useState(false)

  const handleComposition = useCallback(
    (event: CompositionEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.type === 'compositionstart') {
        setIsComposing(true)
      }
      if (event.type === 'compositionend') {
        setIsComposing(false)
      }
    },
    []
  )
  const compositionHandlers = useMemo(
    () => ({
      onCompositionStartCapture: handleComposition,
      onCompositionEndCapture: handleComposition,
    }),
    [handleComposition]
  )
  return { isComposing, handleComposition, compositionHandlers }
}
