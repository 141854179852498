import clsx from 'clsx'
import { ComponentPropsWithoutRef, PropsWithChildren, forwardRef } from 'react'
import { TestProps } from 'typ'
import { sprinkles } from 'vanilla-extract-config'
import { BoxLayoutProps } from '../../types'
import { extractBoxLayoutProps } from '../../utils'
import { FontVariant, textCx } from './Text.css'

export type TextProps = PropsWithChildren &
  ComponentPropsWithoutRef<'div'> &
  TestProps &
  BoxLayoutProps & {
    as?: React.ElementType
    variant?: FontVariant
    ellipsize?: boolean
    flattenLineHeight?: boolean
  }
export const Text = forwardRef<HTMLDivElement, TextProps>(
  (
    {
      as: Component,
      children,
      className,
      ellipsize,
      variant = 'labelMd',
      flattenLineHeight,
      ...props
    },
    ref
  ) => {
    const Element = Component || 'div'
    const { layoutProps, testProps, colorProps, ...coreProps } = extractBoxLayoutProps(props)

    return (
      <Element
        {...testProps}
        {...coreProps}
        className={clsx([
          sprinkles({ ...layoutProps, ...colorProps }),
          textCx({ variant, ellipsize, flattenLineHeight }),
          className,
        ])}
        ref={ref}
      >
        {children}
      </Element>
    )
  }
)

Text.displayName = 'Text'
