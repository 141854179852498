import { KeyboardEvent, KeyboardEventHandler } from 'react'

export const getPreventEnterDefault = (
  isPreventing: boolean,
  cb?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (isPreventing)
    return (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (['Enter', 'NumpadEnter'].includes(e.code)) {
        e.preventDefault()
      }
      cb?.(e)
    }
  return cb
}
