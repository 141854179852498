import { yupResolver } from '@hookform/resolvers/yup'
import { captureMessage as emitSentryMessage } from '@sentry/nextjs'
import { Auth } from 'aws-amplify'
import { formatFullName } from 'format'
import { useCurrentUserAccountQuery } from 'generated/tensor-api/generated-types'
import { TENSOR_CLOUD_CONTACT_US_API_URL } from 'links'
import { log } from 'logger'
import useTranslation from 'next-translate/useTranslation'
import { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import store from 'store'
import {
  ControlledTextAreaField,
  Dialog,
  DialogCancelButton,
  DialogConfirmButton,
  toast,
} from 'ui-v2'
import { validateCustomerSupportInput } from 'validation'
import * as Yup from 'yup'
import { containerCx } from './ContactSupportModal.css'

type ContactUsRequestPayload = {
  currentUserName: string
  email: string
  message: string
  workspaceId: string
  workspaceName: string
}

export interface ContactSupportModalProps {}

const UNKNOWN = 'Unknown'
export const d = {
  contactSupportTitle: 'common:contact-support-modal.title',
  contactSupportPlaceholder: 'common:contact-support-modal.input-placeholder',
  cancelBtn: 'buttons:cancel',
  sendBtn: 'buttons:send',
  required: 'validation:required',
  successToast: 'toasts:contact-support-success',
  errorToast: 'toasts:contact-support-error',
}

export const ContactSupportModal: FC<ContactSupportModalProps> = () => {
  const { t, lang } = useTranslation()
  const currentWorkspace = store.useCurrentWorkspace()
  const isOpen = store.useIsContactSupportModalOpen()
  const closeModal = store.useCloseContactSupportModal()
  const [email, setEmail] = useState('')
  const { data: userData } = useCurrentUserAccountQuery()

  const workspaceId = currentWorkspace?.id || UNKNOWN
  const workspaceName = currentWorkspace?.name || UNKNOWN
  const currentUserName = useMemo(() => {
    if (!userData || !userData.currentUserAccount) return UNKNOWN
    return formatFullName(
      userData.currentUserAccount.firstName,
      userData.currentUserAccount.lastName,
      lang
    )
  }, [lang, userData])

  useEffect(() => {
    const getCurrentAuthenticatedUserEmail = async () => {
      if (userData?.currentUserAccount?.email) setEmail(userData.currentUserAccount.email)
      else {
        try {
          const user = await Auth.currentAuthenticatedUser()
          setEmail(user.username)
        } catch (error) {
          log.debug(error)
          setEmail('Unknown')
        }
      }
    }
    if (!email) {
      getCurrentAuthenticatedUserEmail()
    }
  }, [email, userData?.currentUserAccount?.email])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        contactSupportInput: validateCustomerSupportInput(t),
      }),
    [t]
  )

  const defaultValues: Yup.InferType<typeof validationSchema> = {
    contactSupportInput: '',
  }

  const {
    handleSubmit: submitForm,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues,
  })

  const handleSubmit = submitForm(async (values: { contactSupportInput: string }) => {
    try {
      log.debug('values', values)
      // eslint-disable-next-line global-require

      const payload: ContactUsRequestPayload = {
        currentUserName,
        email,
        workspaceName,
        workspaceId,
        message: values.contactSupportInput,
      }
      await fetch(TENSOR_CLOUD_CONTACT_US_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      reset()
      toast.success(t(d.successToast), { toastId: 'contact-support-success' })
    } catch (error) {
      log.error(error)
      toast.error(t(d.errorToast), { toastId: 'contact-support-error' })
      emitSentryMessage('Could not create Linear ticket through Contact support form', 'error')
    }
    closeModal()
  })
  const handleClose = () => {
    closeModal()
    reset()
  }
  return (
    <Dialog
      width="max-content"
      onSubmit={() => handleSubmit()}
      isDismissable
      isOpen={isOpen}
      onOpenChange={handleClose}
      title={t(d.contactSupportTitle)}
      buttons={({ close }) => (
        <>
          <DialogCancelButton onPress={close} isDisabled={isSubmitting}>
            {t(d.cancelBtn)}
          </DialogCancelButton>
          <DialogConfirmButton type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
            {t(d.sendBtn)}
          </DialogConfirmButton>
        </>
      )}
    >
      <div className={containerCx}>
        <ControlledTextAreaField
          control={control}
          name="contactSupportInput"
          label={t(d.contactSupportTitle)}
          hideLabel
          placeholder={t(d.contactSupportPlaceholder)}
          rows={9}
          autoFocus
        />
      </div>
    </Dialog>
  )
}
