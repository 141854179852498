import { FC } from 'react'
import { FillIcon } from './common/FillIcon'
import { SvgIconProps } from './types'

export const InfoFillIcon: FC<SvgIconProps> = ({ ...props }) => (
  <FillIcon
    {...props}
    data-testid="info-fill-icon"
    d="M7.99998 0C3.58171 0 0 3.58172 0 7.99997C0 12.4182 3.58171 16 7.99998 16C12.4182 16 15.9999 12.4182 15.9999 7.99997C15.9999 3.58172 12.4182 0 7.99998 0ZM8.90594 4.37648C8.90594 4.87682 8.50033 5.28243 7.99999 5.28243C7.49965 5.28243 7.09403 4.87682 7.09403 4.37648C7.09403 3.87613 7.49965 3.47053 7.99999 3.47053C8.50033 3.47053 8.90594 3.87613 8.90594 4.37648ZM6.18821 6.18839H6.79218H8.00012C8.33369 6.18839 8.60409 6.45878 8.60409 6.79236V11.0201H9.20806H9.81203V12.2281H9.20806H8.00012H6.79218H6.18821V11.0201H6.79218H7.39615V7.39633H6.79218H6.18821V6.18839Z"
  />
)
