/* eslint-disable  */
import { CSSObject } from 'typ'
import { noSelect } from './utils'

const baseStyles: CSSObject = {
  display: 'grid',
  placeItems: 'center',
  height: '1.625rem',
  padding: '0rem 1rem',
  borderRadius: 'max',
  fontSize: 1,
  lineHeight: '1rem',
  fontWeight: 400,
  whiteSpace: 'nowrap',
  position: 'relative',
  width: '100%',
  maxWidth: 'max-content',
  fontFamily: 'body',
  cursor: 'pointer',
  transition: 'background .15s',
  border: '1px solid',
  ...noSelect,
}

const primaryStaticScheme: CSSObject = {
  color: 'primary',
  bg: 'primaryTint2',
  borderColor: 'primaryTint2',
}
const dangerStaticScheme: CSSObject = {
  color: 'danger',
  bg: 'dangerTint1',
  borderColor: 'dangerTint1',
}
const successStaticScheme: CSSObject = {
  color: 'success',
  bg: 'successTint1',
  borderColor: 'successTint1',
}
const warningStaticScheme: CSSObject = {
  color: 'warningShade1',
  bg: 'warningTint1',
  borderColor: 'warningTint1',
}
const infoStaticScheme: CSSObject = {
  color: 'info',
  bg: 'infoLight',
  borderColor: 'infoLight',
}
const grayStaticScheme: CSSObject = {
  color: 'black',
  bg: 'none',
  borderColor: 'steel3',
}
const primaryFlatScheme: CSSObject = {
  color: 'white',
  bg: 'primary',
  borderColor: 'primary',
}
const dangerFlatScheme: CSSObject = {
  color: 'white',
  bg: 'danger',
  borderColor: 'danger',
}
const successFlatScheme: CSSObject = {
  color: 'white',
  bg: 'success',
  borderColor: 'success',
}
const warningFlatScheme: CSSObject = {
  color: 'white',
  bg: 'warning',
  borderColor: 'warning',
}
const infoFlatScheme: CSSObject = {
  bg: 'info',
  color: 'white',
  borderColor: 'info',
}
const grayFlatScheme: CSSObject = {
  bg: 'none',
  color: 'black',
  borderColor: 'steel3',
}
const primaryContainedScheme: CSSObject = {
  bg: 'white',
  color: 'primary',
  borderColor: 'primary',
}
const dangerContainedScheme: CSSObject = {
  bg: 'white',
  color: 'danger',
  borderColor: 'danger',
}
const successContainedScheme: CSSObject = {
  bg: 'white',
  color: 'success',
  borderColor: 'success',
}
const warningContainedScheme: CSSObject = {
  bg: 'white',
  color: 'warningShade1',
  borderColor: 'warningShade1',
}
const infoContainedScheme: CSSObject = {
  bg: 'white',
  color: 'info',
  borderColor: 'info',
}
const grayContainedScheme: CSSObject = {
  bg: 'none',
  color: 'black',
  borderColor: 'steel3',
}

export const pills: Record<string, CSSObject> = {
  'primary-static': {
    ...baseStyles,
    ...primaryStaticScheme,
  },
  'danger-static': {
    ...baseStyles,
    ...dangerStaticScheme,
  },
  'success-static': {
    ...baseStyles,
    ...successStaticScheme,
  },
  'warning-static': {
    ...baseStyles,
    ...warningStaticScheme,
  },
  'info-static': {
    ...baseStyles,
    ...infoStaticScheme,
  },
  'gray-static': {
    ...baseStyles,
    ...grayStaticScheme,
  },

  'primary-flat': {
    ...baseStyles,
    ...primaryFlatScheme,
  },
  'primary-contained': {
    ...baseStyles,
    ...primaryContainedScheme,
  },
  primary: {
    ...baseStyles,
    ...primaryContainedScheme,
    '&:hover': {
      bg: 'primaryTint2',
    },
  },
  'primary-checked': {
    ...baseStyles,
    ...primaryFlatScheme,
    '&:hover': {
      borderColor: 'primaryShade3',
    },
  },
  'danger-flat': {
    ...baseStyles,
    ...dangerFlatScheme,
  },
  'danger-contained': {
    ...baseStyles,
    ...dangerContainedScheme,
  },
  danger: {
    ...baseStyles,
    ...dangerContainedScheme,
    '&:hover': {
      bg: 'dangerTint1',
    },
  },
  'danger-checked': {
    ...baseStyles,
    ...dangerFlatScheme,
    '&:hover': {
      borderColor: 'dangerShade2',
    },
  },
  'success-flat': {
    ...baseStyles,
    ...successFlatScheme,
  },
  'success-contained': {
    ...baseStyles,
    ...successContainedScheme,
  },
  success: {
    ...baseStyles,
    ...successContainedScheme,
    '&:hover': {
      bg: 'successTint1',
    },
  },
  'success-checked': {
    ...baseStyles,
    ...successFlatScheme,
    '&:hover': {
      borderColor: 'successShade2',
    },
  },
  'warning-flat': {
    ...baseStyles,
    ...warningFlatScheme,
  },
  'warning-contained': {
    ...baseStyles,
    ...warningContainedScheme,
  },
  warning: {
    ...baseStyles,
    ...warningContainedScheme,
    '&:hover': {
      bg: 'warningTint1',
    },
  },
  'warning-checked': {
    ...baseStyles,
    ...warningFlatScheme,
    '&:hover': {
      borderColor: 'warningShade1',
    },
  },
  'info-flat': {
    ...baseStyles,
    ...infoFlatScheme,
  },
  'info-contained': {
    ...baseStyles,
    ...infoContainedScheme,
  },
  'gray-flat': {
    ...baseStyles,
    ...grayFlatScheme,
  },
  'gray-contained': {
    ...baseStyles,
    ...grayContainedScheme,
  },
}
