import * as Apollo from '@apollo/client'
import * as ApolloReactCommon from '@apollo/client'
import { gql } from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** Custom type to handle maps. Maps types into map[string]interface{} Go type. */
  Map: { input: any; output: any }
  /** Custom type to handle time. Formats in 2006-01-02T15:04:05.999999999Z07:00 */
  Time: { input: any; output: any }
  /** Custom type to handle file uploads */
  Upload: { input: any; output: any }
}

export type AcceptWorkspaceInviteInput = {
  invitationId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type AcceptWorkspaceInvitePayload = {
  __typename?: 'AcceptWorkspaceInvitePayload'
  errors?: Maybe<Array<AcceptWorkspaceInviteProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type AcceptWorkspaceInviteProblems = ExpiredInvitationProblem | InvalidInvitationProblem

/** An Actor is a participant of an action. */
export type Actor = {
  __typename?: 'Actor'
  /** Email address of the actor */
  email: Scalars['String']['output']
  /** First name of the actor */
  firstName: Scalars['String']['output']
  /** An unique, non-human readable id */
  id: Scalars['ID']['output']
  /** Last name of the actor */
  lastName: Scalars['String']['output']
  /** An object containing various urls for the actor's profile picture */
  profilePicture?: Maybe<ProfilePicture>
  /** Workspace of the actor. A null value indicates actor is no longer part of the workspace */
  workspace?: Maybe<Workspace>
}

export type AddAssetsToBalancingGroupData = {
  __typename?: 'AddAssetsToBalancingGroupData'
  /** Record that assets were added to */
  record: BalancingGroup
  /** ID of the record. Format = UUID */
  recordId: Scalars['ID']['output']
}

export type AddAssetsToBalancingGroupInput = {
  /** IDs of the assets to add to the balancing group */
  assetIds: Array<Scalars['ID']['input']>
  /** ID of the balancing group to add assets to. Format = UUID */
  id: Scalars['ID']['input']
  /** Workspace the balancing group is associated with. Format = UUID */
  workspaceId: Scalars['ID']['input']
}

export type AddAssetsToBalancingGroupPayload = {
  __typename?: 'AddAssetsToBalancingGroupPayload'
  /** Returned data payload from mutation */
  data?: Maybe<AddAssetsToBalancingGroupData>
  /** All errors that occurred during mutation */
  errors?: Maybe<Array<AddAssetsToBalancingGroupProblems>>
  /** Recursive query property that provides access to any query in the Query namespace */
  query?: Maybe<Query>
  /** Flag that indicates whether the mutation was successful or not. */
  success: Scalars['Boolean']['output']
}

/** All problems that can occur during add assets to a balancing group */
export type AddAssetsToBalancingGroupProblems =
  | AuthorizationProblem
  | ResourceDoesNotExistProblem
  | ResourceIsAlreadyInUseProblem

export type AddAssetsToPpaData = {
  __typename?: 'AddAssetsToPpaData'
  record: PpaX
  recordId: Scalars['ID']['output']
}

export type AddAssetsToPpaInput = {
  /** IDs of the assets that should be attached to the ppa */
  assetIds: Array<Scalars['ID']['input']>
  /** ID of the ppa to add assets to */
  id: Scalars['ID']['input']
  /** ID of the workspace the ppa is associated with */
  workspaceId: Scalars['ID']['input']
}

export type AddAssetsToPpaPayload = {
  __typename?: 'AddAssetsToPpaPayload'
  data?: Maybe<AddAssetsToPpaData>
  errors?: Maybe<Array<AddAssetsToPpaProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type AddAssetsToPpaProblems =
  | AuthorizationProblem
  | ResourceDoesNotExistProblem
  | ResourceIsAlreadyInUseProblem

export type AddAssetsToSpvData = {
  __typename?: 'AddAssetsToSpvData'
  record: Spv
  recordId: Scalars['ID']['output']
}

export type AddAssetsToSpvInput = {
  assetIds: Array<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type AddAssetsToSpvPayload = {
  __typename?: 'AddAssetsToSpvPayload'
  data?: Maybe<AddAssetsToSpvData>
  errors?: Maybe<Array<AddAssetsToSpvProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type AddAssetsToSpvProblems =
  | AuthorizationProblem
  | ResourceDoesNotExistProblem
  | ResourceIsAlreadyInUseProblem

export type AddSolarAssetsPpaData = {
  __typename?: 'AddSolarAssetsPpaData'
  record: SolarAsset
  recordId: Scalars['ID']['output']
}

export type AddSolarAssetsPpaDataInput = {
  ppaId: Scalars['ID']['input']
}

export type AddSolarAssetsPpaInput = {
  data: AddSolarAssetsPpaDataInput
  ids: Array<Scalars['ID']['input']>
  organizationId: Scalars['ID']['input']
}

export type AddSolarAssetsPpaPayload = {
  __typename?: 'AddSolarAssetsPpaPayload'
  data?: Maybe<Array<AddSolarAssetsPpaData>>
  errors?: Maybe<Array<AddSolarAssetsPpaProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type AddSolarAssetsPpaProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type ArchiveReportData = {
  __typename?: 'ArchiveReportData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type ArchiveReportInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type ArchiveReportPayload = {
  __typename?: 'ArchiveReportPayload'
  data?: Maybe<ArchiveReportData>
  errors?: Maybe<Array<ArchiveReportProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type ArchiveReportProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export enum AssetStatus {
  Maintenance = 'MAINTENANCE',
  Operation = 'OPERATION',
  Planning = 'PLANNING',
}

export type AssumptionValues = {
  __typename?: 'AssumptionValues'
  value: Scalars['Float']['output']
  year: Scalars['Int']['output']
}

export type AssumptionValuesInput = {
  value: Scalars['Float']['input']
  year: Scalars['Int']['input']
}

export type AuthorizationProblem = ProblemInterface & {
  __typename?: 'AuthorizationProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
}

/** A virtual energy quantity account. Used to balance generation and consumption */
export type BalancingGroup = {
  __typename?: 'BalancingGroup'
  /** Assets that are in the balancing group */
  assets: Array<SolarAsset>
  /** A unique identifier assigned by the utility grid zone */
  balancingGroupCode: Scalars['String']['output']
  /** Issued by the TSO and serves as an additional identifier for each balancing group */
  contractClassificationNumber1?: Maybe<Scalars['String']['output']>
  /** Time the balancing group was created at */
  createdAt: Scalars['Time']['output']
  /** Forecasts that were generated for the balancing group */
  forecasts: BalancingGroupForecasts
  /** Utility grid zone where the balancing group operates in */
  gridZone?: Maybe<GridZone>
  /** Flag to indicate if the balancing group as a whole has any forecasts */
  hasForecasts: Scalars['Boolean']['output']
  /** Flag to indicate if there is metric data such as forecasts/actual/metrics on the balancing group level */
  hasMetricData: Scalars['Boolean']['output']
  /** A unique, non-human readable UUID */
  id: Scalars['ID']['output']
  /** Assigned value of imbalance for intraday optimization, represented in JPY/kWh */
  imbalanceValue: Scalars['Float']['output']
  /** Indicates if the balancing group is the workspace default */
  isDefault: Scalars['Boolean']['output']
  /** Name of balancing group */
  name: Scalars['String']['output']
  /** Total number of assets that are in the balancing group */
  totalAssets: Scalars['Int']['output']
  /** Time the balancing group was updated at */
  updatedAt: Scalars['Time']['output']
  /** ID of the workspace the balancing group is in. Format = UUID */
  workspaceId: Scalars['ID']['output']
}

export type BalancingGroupAlreadyExistsProblem = ProblemInterface & {
  __typename?: 'BalancingGroupAlreadyExistsProblem'
  /** [internal] Message used for debugging purposes */
  message: Scalars['String']['output']
  /** Property type that did not meet constraints */
  type: BalancingGroupAlreadyExistsProblemType
}

export enum BalancingGroupAlreadyExistsProblemType {
  BalancingGroupCode = 'BALANCING_GROUP_CODE',
  Name = 'NAME',
}

export type BalancingGroupFilter = {
  /** Filter by grid zone */
  gridZone?: InputMaybe<GridZone>
  /** Filter by default balancing groups */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  /** Filter by balancing groups that only contain occto compliant assets */
  isOcctoCompliant?: InputMaybe<Scalars['Boolean']['input']>
  /** Filter by text search. Includes name and balancingGroupCode properties in search. */
  text?: InputMaybe<Scalars['String']['input']>
}

export type BalancingGroupFilterVariables = {
  __typename?: 'BalancingGroupFilterVariables'
  /** Filter by grid zone */
  gridZone?: Maybe<GridZone>
  /** Filter by default balancing groups */
  isDefault?: Maybe<Scalars['Boolean']['output']>
  /** Filter by balancing groups that only contain occto compliant assets */
  isOcctoCompliant?: Maybe<Scalars['Boolean']['output']>
  /** Filter by text search. Includes name and balancingGroupCode properties in search. */
  text?: Maybe<Scalars['String']['output']>
}

export type BalancingGroupForecasts = {
  __typename?: 'BalancingGroupForecasts'
  /**
   * Aggegrated electricity generation plan for occto compliant assets.
   * An occto-compliant asset is as follows:
   *     1. Asset is part of a workspace
   *     2. Asset is not part of the default balancing group
   *     3. Asset is marked as operational
   *     4. Asset COD is in the past
   *     5. Asset has a complete data record for occto-related fields (contract classification number 2 & grid code)
   */
  generationPlan?: Maybe<BalancingGroupGenerationPlan>
  /** Long-term monthly electricity generation plan for OCCTO compliant assets */
  occtoMonthlyGenerationPlan?: Maybe<BalancingGroupOcctoMonthlyGenerationPlan>
  /** Aggregated long-term weekly electricity generation plan for OCCTO compliant assets */
  occtoWeeklyGenerationPlan?: Maybe<BalancingGroupOcctoWeeklyGenerationPlan>
  /** Long-term yearly electricity generatino plan for OCCTO compliant assets */
  occtoYearlyGenerationPlan?: Maybe<BalancingGroupGenerationPlan>
}

export type BalancingGroupForecastsGenerationPlanArgs = {
  input: BalancingGroupGenerationPlanInput
}

export type BalancingGroupForecastsOcctoMonthlyGenerationPlanArgs = {
  input: BalancingGroupGenerationPlanInput
}

export type BalancingGroupForecastsOcctoWeeklyGenerationPlanArgs = {
  input: BalancingGroupOcctoWeeklyGenerationPlanInput
}

export type BalancingGroupForecastsOcctoYearlyGenerationPlanArgs = {
  input: BalancingGroupGenerationPlanInput
}

export type BalancingGroupGenerationPlan = {
  __typename?: 'BalancingGroupGenerationPlan'
  /** Electricity generation plan in kilowatt hour (kWh) for daily plans or kilowatt (kW) for yearly plans. Should contain 48 values for a daily plan and 24 value for a yearly plan */
  plan: SingleSeries
  /** Total assets that are included in the plan */
  totalAssets: Scalars['Int']['output']
  /** Total volume of electricity generation in kilowatt hour (kWh) for daily plans or kilowatt (kW) for yearly plans */
  totalGeneration: Scalars['Float']['output']
}

export type BalancingGroupGenerationPlanInput = {
  date: Scalars['Time']['input']
}

export type BalancingGroupList = {
  __typename?: 'BalancingGroupList'
  /** Additional information about the pagination */
  pageInfo: PaginationInfo
  /** Variables that were passed into the query */
  queryVariables: BalancingGroupListQueryVariables
  /** List of balancing groups */
  records: Array<BalancingGroup>
}

export type BalancingGroupListInput = {
  /** Page number */
  page?: Scalars['Int']['input']
  /** Number of records per page */
  perPage?: Scalars['Int']['input']
  /** ID of the workspace. Format = UUID */
  workspaceId: Scalars['ID']['input']
}

export type BalancingGroupListInputVariables = {
  __typename?: 'BalancingGroupListInputVariables'
  /** Page number */
  page: Scalars['Int']['output']
  /** Number of records per page */
  perPage: Scalars['Int']['output']
  /** ID of the workspace. Format = UUID */
  workspaceId: Scalars['ID']['output']
}

export type BalancingGroupListQueryVariables = {
  __typename?: 'BalancingGroupListQueryVariables'
  /** Filter variables passed into the resolver */
  filter?: Maybe<BalancingGroupFilterVariables>
  /** Input variables passed into the resolver */
  input: BalancingGroupListInputVariables
  /** Sort variable passed into the resolver */
  sort?: Maybe<BalancingGroupSort>
}

export type BalancingGroupMutations = {
  __typename?: 'BalancingGroupMutations'
  /** Mutation to add assets to a balancing group */
  addAssets: AddAssetsToBalancingGroupPayload
  /** Mutation to create a balancing group */
  create: CreateBalancingGroupPayload
  /** Mutation to delete a balancing group */
  delete: DeleteBalancingGroupPayload
  /** Mutation to remove assets to a balancing group */
  removeAssets: RemoveAssetsFromBalancingGroupPayload
  /** Mutation to rename a balancing group */
  rename: RenameBalancingGroupPayload
  /** Mutation to update a balancing group */
  update: UpdateBalancingGroupPayload
}

export type BalancingGroupMutationsAddAssetsArgs = {
  input: AddAssetsToBalancingGroupInput
}

export type BalancingGroupMutationsCreateArgs = {
  input: CreateBalancingGroupInput
}

export type BalancingGroupMutationsDeleteArgs = {
  input: DeleteBalancingGroupInput
}

export type BalancingGroupMutationsRemoveAssetsArgs = {
  input: RemoveAssetsFromBalancingGroupInput
}

export type BalancingGroupMutationsRenameArgs = {
  input: RenameBalancingGroupInput
}

export type BalancingGroupMutationsUpdateArgs = {
  input: UpdateBalancingGroupInput
}

export type BalancingGroupOcctoMonthlyGenerationPlan = {
  __typename?: 'BalancingGroupOcctoMonthlyGenerationPlan'
  /** Electricity generation plan in kilowatt hour (kW) */
  plan: Array<BalancingGroupOcctoMonthlyGenerationPlanRecord>
  /** Total assets that are included in the plan */
  totalAssets: Scalars['Int']['output']
}

/**
 * An OCCTO long term monthly generation plan record that contains the expected max and min.
 * All numeric values are expressed in kilowatt (kW)
 */
export type BalancingGroupOcctoMonthlyGenerationPlanRecord = {
  __typename?: 'BalancingGroupOcctoMonthlyGenerationPlanRecord'
  /** Expected high demand in kW for the generation plan */
  high: Scalars['Float']['output']
  /** Expected low demand in kW for the generation plan */
  low: Scalars['Float']['output']
  /** Details regarding the time slot */
  timeSlot: OcctoMonthlyPlanTimeSlot
}

export type BalancingGroupOcctoWeeklyGenerationPlan = {
  __typename?: 'BalancingGroupOcctoWeeklyGenerationPlan'
  /** Series of records representing an OCCTO generation plan */
  plan: Array<BalancingGroupOcctoWeeklyGenerationPlanRecord>
  /** Total number of assets included in the plan */
  totalAssets: Scalars['Int']['output']
}

export type BalancingGroupOcctoWeeklyGenerationPlanInput = {
  /** Starting date of the long term generation plan */
  date: Scalars['Time']['input']
  /** Update date of the OCCTO long term plan. If this field is supplied, it is assumed the long term plan should be a weekly update plan. */
  updateDate?: InputMaybe<Scalars['Time']['input']>
}

/**
 * An OCCTO long term weekly generation plan record that contains the expected high and low demand.
 * All numeric values are expressed in kilowatt (kW)
 */
export type BalancingGroupOcctoWeeklyGenerationPlanRecord = {
  __typename?: 'BalancingGroupOcctoWeeklyGenerationPlanRecord'
  /** Date and time of the plan slot */
  datetime: Scalars['Time']['output']
  /** High demand value in kW for the generation plan */
  high: Scalars['Float']['output']
  /** Low demand value in kW for the generation plan */
  low: Scalars['Float']['output']
}

export enum BalancingGroupSort {
  BalancingGroupIdAsc = 'BALANCING_GROUP_ID_ASC',
  BalancingGroupIdDesc = 'BALANCING_GROUP_ID_DESC',
  DateAddedAsc = 'DATE_ADDED_ASC',
  DateAddedDesc = 'DATE_ADDED_DESC',
  GridZoneAsc = 'GRID_ZONE_ASC',
  GridZoneDesc = 'GRID_ZONE_DESC',
  LastEditAsc = 'LAST_EDIT_ASC',
  LastEditDesc = 'LAST_EDIT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  TotalAssetsAsc = 'TOTAL_ASSETS_ASC',
  TotalAssetsDesc = 'TOTAL_ASSETS_DESC',
}

/** Basic Asset interface; can be extended */
export type BaseAsset = {
  acCapacity: Scalars['Float']['output']
  dcCapacity: Scalars['Float']['output']
  gridZone: GridZone
  id: Scalars['ID']['output']
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
  name: Scalars['String']['output']
  ppa?: Maybe<BasePpa>
}

/** Basic PPA interface; can be extended */
export type BasePpa = {
  /** Total AC Capacity of all assets in the PPA in kW */
  capacity: Scalars['Float']['output']
  /** ID of the PPA */
  id: Scalars['ID']['output']
  /** Name of the PPA */
  name: Scalars['String']['output']
  /** Number of assets in the PPA */
  totalAssets: Scalars['Int']['output']
}

export type Baseline = {
  __typename?: 'Baseline'
  /** Balance sheet for the baseline. Value will be null if statementStatus is not COMPLETED */
  balanceSheet?: Maybe<BaselineStatement>
  /** Cash flow statement for the baseline. Value will be null if statementStatus is not COMPLETED */
  cashflowStatement?: Maybe<BaselineStatement>
  /** Time the baseline was created at */
  createdAt: Scalars['Time']['output']
  /** Unique, non-human readable ID */
  id: Scalars['ID']['output']
  /** Last user to make modification of the baseline */
  lastModifiedBy: Actor
  /** Name of the baseline */
  name: Scalars['String']['output']
  /** Profit loss statement for the baseline. Value will be null if statementStatus is not COMPLETED */
  profitLossStatement?: Maybe<BaselineStatement>
  /** Unique, human readable ID. Formatted as {WORKSPACE_CODE}-B-{NUMBER} */
  readableId: Scalars['String']['output']
  /** Scenario associated with the original generation of the financial statement */
  scenario: MacroScenario
  /** SPV the baseline is associated with */
  spv: Spv
  /** Current creation status of the financial statement for the baseline. */
  statementStatus: BaselineStatementStatus
  /** Time the baseline was last updated at */
  updatedAt: Scalars['Time']['output']
}

export type BaselineMutations = {
  __typename?: 'BaselineMutations'
  create: CreateBaselinePayload
  update: UpdateBaselinePayload
}

export type BaselineMutationsCreateArgs = {
  input: CreateBaselineInput
}

export type BaselineMutationsUpdateArgs = {
  input: UpdateBaselineInput
}

export type BaselineNode = {
  __typename?: 'BaselineNode'
  /** children for the node */
  children: Array<BaselineNode>
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** Index for the node that indicates order position */
  index: Scalars['Int']['output']
  /** Names for the node */
  name: BaselineNodeName
  /** ID of parent node */
  parentId?: Maybe<Scalars['ID']['output']>
  /** Type of node */
  type: NodeType
  /** Values for the baseline node */
  values: Array<Scalars['Float']['output']>
}

export type BaselineNodeName = {
  __typename?: 'BaselineNodeName'
  /** Name in English */
  en: Scalars['String']['output']
  /** Name in Japanese */
  ja: Scalars['String']['output']
}

export type BaselineResourceDoesNotExistProblem = ProblemInterface & {
  __typename?: 'BaselineResourceDoesNotExistProblem'
  message: Scalars['String']['output']
  recordId: Scalars['ID']['output']
  type: BaselineResourceDoesNotExistProblemType
}

export enum BaselineResourceDoesNotExistProblemType {
  Scenario = 'SCENARIO',
  Spv = 'SPV',
}

export type BaselineStatement = {
  __typename?: 'BaselineStatement'
  /** End time of the baseline statement */
  end: Scalars['Time']['output']
  /** Interval of the statement in months. Ex. 1 = MONTHLY, 12 = YEARLY */
  interval: Scalars['Int']['output']
  /** All nodes that builds the statement */
  nodes: Array<BaselineNode>
  /** Start time of the baseline statement */
  start: Scalars['Time']['output']
}

export enum BaselineStatementStatus {
  Completed = 'COMPLETED',
  CreationInProgress = 'CREATION_IN_PROGRESS',
  Failed = 'FAILED',
}

export type BaselinesMutations = {
  __typename?: 'BaselinesMutations'
  deleteMany: DeleteManyBaselinesPayload
}

export type BaselinesMutationsDeleteManyArgs = {
  input: DeleteManyBaselinesInput
}

export type Battery = {
  __typename?: 'Battery'
  /** Battery charge/discharge actuals series. Values are represented in kilowatt hour (kWh) */
  batteryChargeActuals: HighPerformanceNullableNumberSeries
  /** Battery charge/discharge forecast series. Values are represented in kilowatt hour (kWh) */
  batteryChargeForecast: HighPerformanceNullableNumberSeries
  /**
   * Actual current state of charge, represented in kilowatt hour.
   * To calculate the state of charge in percentage, take the returned value and divide it by the energy capacity.
   * Value is null if the current state of charge is unknown
   */
  currentStateOfCharge?: Maybe<Scalars['Float']['output']>
  /** Estimate of battery cycle cost in jpy/kWh */
  cycleCost: Scalars['Float']['output']
  /** Energy capacity of the battery in kWh */
  energyCapacity: Scalars['Float']['output']
  /** Number of cycles before the battery capacity drops below OEM warranty threshold */
  expectedCycleLife: Scalars['Int']['output']
  /** Unique, non-human readable id */
  id: Scalars['ID']['output']
  /** Flag that indicates whether the battery is deleted or not */
  isDeleted: Scalars['Boolean']['output']
  /** Battery current live status */
  liveStatus: BatteryLiveStatus
  /** Maximum state of charge of the battery during operation represented as a decimal */
  maximumStateOfCharge: Scalars['Float']['output']
  /** Minimum state of charge of the battery during operation represented as a decimal */
  minimumStateOfCharge: Scalars['Float']['output']
  /** Date the battery will start operation */
  operationalStartDate: Scalars['Time']['output']
  /** Power capacity of the battery in kW */
  powerCapacity: Scalars['Float']['output']
  /** Combined efficiency of battery and integrated inverter represented as a decimal */
  roundtripEfficiency: Scalars['Float']['output']
  /** Current development phase of the battery */
  status: BatteryStatus
  /** Total number of cycles used by the battery */
  totalCyclesUsed: Scalars['Float']['output']
  /** Total amount of energy discharge sinced COD in kilowatt hour (kWh) */
  totalEnergyDischarged: Scalars['Float']['output']
  /** Number of cycles covered by battery's warranty */
  warrantyCycleLimit: Scalars['Float']['output']
  /** Warranty period of the battery in years */
  warrantyPeriod: Scalars['Int']['output']
}

export type BatteryBatteryChargeActualsArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type BatteryBatteryChargeForecastArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export enum BatteryLiveStatus {
  Charging = 'CHARGING',
  Discharging = 'DISCHARGING',
  Error = 'ERROR',
  Idle = 'IDLE',
}

export enum BatteryStatus {
  Maintenance = 'MAINTENANCE',
  Operation = 'OPERATION',
  Planning = 'PLANNING',
}

export type BatteryValidationProblem = ProblemInterface & {
  __typename?: 'BatteryValidationProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** The record created by merging the desired state and current state */
  record: Battery
  /** ID of the battery */
  recordId: Scalars['ID']['output']
}

export type BenchmarkIndicator = {
  __typename?: 'BenchmarkIndicator'
  /** The performance represented by decimal (multiply by 100 for percentage value) when benchmarked against another entity. */
  benchmark: Scalars['Float']['output']
  /** The actual value of the benchmarked entity */
  value: Scalars['Float']['output']
}

export enum CapexCategory {
  CivilWork = 'CIVIL_WORK',
  DesignAndEngineering = 'DESIGN_AND_ENGINEERING',
  Hardware = 'HARDWARE',
  Installation = 'INSTALLATION',
  Interconnection = 'INTERCONNECTION',
  LandAcquisition = 'LAND_ACQUISITION',
  Other = 'OTHER',
  Permitting = 'PERMITTING',
  ProjectAcquisition = 'PROJECT_ACQUISITION',
}

export type CapexPayment = {
  __typename?: 'CAPEXPayment'
  /** Amount of CAPEX payment in JPY */
  amount: Scalars['Float']['output']
  /** Category of the CAPEX payment item. This is used to group CAPEX payments in the UI and to run benchmarks. */
  category: CapexCategory
  /** Whether the CAPEX payment includes consumption tax */
  consumptionTax: Scalars['Boolean']['output']
  /** Date when CAPEX payment has been or is planned to be made by the SPV */
  date: Scalars['Time']['output']
  /** Description of the CAPEX payment item. Can be freely defined by the user. */
  description?: Maybe<Scalars['String']['output']>
  /** A unique, non-human readable id */
  id: Scalars['ID']['output']
}

export type CapexPaymentValidationProblem = ProblemInterface & {
  __typename?: 'CAPEXPaymentValidationProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** The record created by merging the desired state and current state */
  record: CapexPayment
  /** ID of the capex payment */
  recordId: Scalars['ID']['output']
}

export type ChangeUserPreferencesData = {
  __typename?: 'ChangeUserPreferencesData'
  record: User
  recordId: Scalars['ID']['output']
}

export type ChangeUserPreferencesInput = {
  /** ID of the user */
  id: Scalars['ID']['input']
  /** Flag that indicates if the user is currently opted into receiving activity notifications via the platform/app */
  isOptedIntoActivityAppNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving activity notifications via email */
  isOptedIntoActivityEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving alert notifications via the platform/app */
  isOptedIntoAlertAppNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving alert notifications via email */
  isOptedIntoAlertEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving reminder notifications via the platform/app */
  isOptedIntoReminderAppNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving reminder notifications via email */
  isOptedIntoReminderEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** ID of the workspace the user is associated with */
  workspaceId: Scalars['ID']['input']
}

export type ChangeUserPreferencesPayload = {
  __typename?: 'ChangeUserPreferencesPayload'
  data?: Maybe<ChangeUserPreferencesData>
  errors?: Maybe<Array<ChangeUserPreferencesProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type ChangeUserPreferencesProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type ChangeUserRoleData = {
  __typename?: 'ChangeUserRoleData'
  record: User
  recordId: Scalars['ID']['output']
}

export type ChangeUserRoleInput = {
  id: Scalars['ID']['input']
  role: UserPermission
  workspaceId: Scalars['ID']['input']
}

export type ChangeUserRolePayload = {
  __typename?: 'ChangeUserRolePayload'
  data?: Maybe<ChangeUserRoleData>
  errors?: Maybe<Array<ChangeUserRoleProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type ChangeUserRoleProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type ConfirmCognitoEmailUpdateData = {
  __typename?: 'ConfirmCognitoEmailUpdateData'
  email: Scalars['String']['output']
}

export type ConfirmCognitoEmailUpdateInput = {
  accessToken: Scalars['String']['input']
  code: Scalars['String']['input']
}

export type ConfirmCognitoEmailUpdatePayload = {
  __typename?: 'ConfirmCognitoEmailUpdatePayload'
  data?: Maybe<ConfirmCognitoEmailUpdateData>
  errors?: Maybe<Array<ConfirmCognitoEmailUpdateProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type ConfirmCognitoEmailUpdateProblems = ProblemInterface & {
  __typename?: 'ConfirmCognitoEmailUpdateProblems'
  /**
   * [internal] A short message of what occured.
   * Intended for internal use, so it is recommended that maching occurs
   * agains the __typename and not the message.
   */
  message: Scalars['String']['output']
}

export type ConfirmJepxTradeData = {
  __typename?: 'ConfirmJepxTradeData'
  record: JepxTrades
  recordId: Scalars['ID']['output']
}

export type ConfirmJepxTradeInput = {
  /** Date to confirm the trade on */
  date: Scalars['Time']['input']
  /** The grid zone to confirm the trade in */
  gridZone: GridZone
  /** Trade positions to open */
  trades: Array<JepxTradeDetailsInput>
  /** ID of the workspace the trade is associated with */
  workspaceId: Scalars['ID']['input']
}

export type ConfirmJepxTradePayload = {
  __typename?: 'ConfirmJepxTradePayload'
  data?: Maybe<ConfirmJepxTradeData>
  errors?: Maybe<Array<ConfirmJepxTradeProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type ConfirmJepxTradeProblems =
  | AuthorizationProblem
  | NotWithinTimeFrameProblem
  | ResourceDoesNotExistProblem

export type CopyBaselineData = {
  __typename?: 'CopyBaselineData'
  record?: Maybe<Baseline>
  recordId: Scalars['ID']['output']
}

export type CopyBaselineInput = {
  /** The number of copies to create of the baseline */
  copies?: Scalars['Int']['input']
  /** The ID of the baseline */
  id: Scalars['ID']['input']
  /** The workspace ID the baseline is related to */
  workspaceId: Scalars['ID']['input']
}

export type CopyBaselinePayload = {
  __typename?: 'CopyBaselinePayload'
  /** Returned entities that were created */
  data?: Maybe<Array<Maybe<CopyBaselineData>>>
  /** Any errors that occurred during the operation */
  errors?: Maybe<Array<CopyBaselineProblems>>
  /** Recursive query */
  query?: Maybe<Query>
  /** Flag that indicates whether the operation succeeded or not */
  success: Scalars['Boolean']['output']
}

export type CopyBaselineProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type CopySolarAssetData = {
  __typename?: 'CopySolarAssetData'
  record: SolarAsset
  recordId: Scalars['ID']['output']
}

export type CopySolarAssetInput = {
  /** The number of copies to create of the asset */
  copies: Scalars['Int']['input']
  /** The ID of the asset */
  id: Scalars['ID']['input']
  /** The workspace ID the asset is related to */
  workspaceId: Scalars['ID']['input']
}

export type CopySolarAssetPayload = {
  __typename?: 'CopySolarAssetPayload'
  data?: Maybe<Array<CopySolarAssetData>>
  errors?: Maybe<Array<CopySolarAssetProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CopySolarAssetProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export enum CouponPaymentFrequency {
  Annual = 'ANNUAL',
  SemiAnnual = 'SEMI_ANNUAL',
}

export type CreateAssumptionProblems = AuthorizationProblem | ResourceAlreadyExistsProblem

export type CreateBalancingGroupData = {
  __typename?: 'CreateBalancingGroupData'
  /** Record that was created. */
  record: BalancingGroup
  /** ID of the record. Format = UUID */
  recordId: Scalars['ID']['output']
}

export type CreateBalancingGroupInput = {
  /** IDs of the assets to add to the balancing group */
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** The unique identifier for the balancing group. Code must be 5 characters in length. */
  balancingGroupCode: Scalars['String']['input']
  /** Issued by the TSO and serves as an additional identifier for each balancing group */
  contractClassificationNumber1?: InputMaybe<Scalars['String']['input']>
  /** Grid zone the balancing group is based in */
  gridZone: GridZone
  /** Assigned value of imbalance for intraday optimization, represented in JPY/kWh */
  imbalanceValue: Scalars['Float']['input']
  /** Name of the balancing group */
  name: Scalars['String']['input']
  /** ID of the workspace the balancing group is associated with. Format = UUID */
  workspaceId: Scalars['ID']['input']
}

export type CreateBalancingGroupPayload = {
  __typename?: 'CreateBalancingGroupPayload'
  /** Returned data payload from mutation */
  data?: Maybe<CreateBalancingGroupData>
  /** Any errors that occurred during mutation */
  errors?: Maybe<Array<CreateBalancingGroupProblems>>
  /** Recursive query property that provides access to any query in the Query namespace */
  query?: Maybe<Query>
  /** Flag that indicates whether the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

/** All problems that can occur during balancing group creation */
export type CreateBalancingGroupProblems =
  | AuthorizationProblem
  | BalancingGroupAlreadyExistsProblem
  | MissingDependentsProblem
  | ResourceDoesNotExistProblem
  | ResourceIsAlreadyInUseProblem

export type CreateBaselineData = {
  __typename?: 'CreateBaselineData'
  record: Baseline
  recordId: Scalars['ID']['output']
}

export type CreateBaselineInput = {
  /** Desired name of the baseline */
  name: Scalars['String']['input']
  /** ID of the scenario to associate with the baseline  */
  scenarioId: Scalars['ID']['input']
  /** ID of the SPV to associate with the baseline */
  spvId: Scalars['ID']['input']
  /** ID of the workspace the baseline should be associated with */
  workspaceId: Scalars['ID']['input']
}

export type CreateBaselinePayload = {
  __typename?: 'CreateBaselinePayload'
  /** Returned entity that was created */
  data?: Maybe<CreateBaselineData>
  /** Any errors that occurred during the operation */
  errors?: Maybe<Array<CreateBaselineProblems>>
  /** Recursive query */
  query?: Maybe<Query>
  /** Flag that indicates whether the operation succeeded or not */
  success: Scalars['Boolean']['output']
}

export type CreateBaselineProblems = AuthorizationProblem | BaselineResourceDoesNotExistProblem

export type CreateBatteryInput = {
  /** Estimate of battery cycle cost in jpy/kWh */
  cycleCost: Scalars['Float']['input']
  /** Energy capacity of the battery in kWh */
  energyCapacity: Scalars['Float']['input']
  /** Number of cycles before the battery capacity drops below OEM warranty threshold */
  expectedCycleLife: Scalars['Int']['input']
  /** Unique, non-human readable id */
  id: Scalars['ID']['input']
  /** Maximum state of charge of the battery during operation represented as a decimal */
  maximumStateOfCharge: Scalars['Float']['input']
  /** Minimum state of charge of the battery during operation represented as a decimal */
  minimumStateOfCharge: Scalars['Float']['input']
  /** Date the battery will start operation */
  operationalStartDate: Scalars['Time']['input']
  /** Power capacity of the battery in kW */
  powerCapacity: Scalars['Float']['input']
  /** Combined efficiency of battery and integrated inverter represented as a decimal */
  roundtripEfficiency: Scalars['Float']['input']
  /** Current development phase of the battery */
  status: BatteryStatus
  /** Warranty period of the battery in years */
  warrantyPeriod: Scalars['Int']['input']
}

export type CreateCapexPaymentInput = {
  /** Amount of CAPEX payment in JPY */
  amount: Scalars['Float']['input']
  /** Category of the CAPEX payment item. This is used to group CAPEX payments in the UI and to run benchmarks. */
  category: CapexCategory
  /** Whether the CAPEX payment includes consumption tax */
  consumptionTax: Scalars['Boolean']['input']
  /** Date when CAPEX payment has been or is planned to be made by the SPV */
  date: Scalars['Time']['input']
  /** Description of the CAPEX payment item. Can be freely defined by the user. */
  description?: InputMaybe<Scalars['String']['input']>
  /** A unique, non-human readable id */
  id: Scalars['ID']['input']
}

export type CreateCurtailmentData = {
  __typename?: 'CreateCurtailmentData'
  record: ScenarioCurtailment
  recordId: Scalars['ID']['output']
}

export type CreateCurtailmentDataInput = {
  assumptions: RegionalAssumptionsInput
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type CreateCurtailmentInput = {
  data: CreateCurtailmentDataInput
}

export type CreateCurtailmentPayload = {
  __typename?: 'CreateCurtailmentPayload'
  data?: Maybe<CreateCurtailmentData>
  errors?: Maybe<Array<CreateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreateForecastMapeDayAheadData = {
  __typename?: 'CreateForecastMapeDayAheadData'
  record: ScenarioForecastMapeDayAhead
  recordId: Scalars['ID']['output']
}

export type CreateForecastMapeDayAheadDataInput = {
  assumptions: Array<AssumptionValuesInput>
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type CreateForecastMapeDayAheadInput = {
  data: CreateForecastMapeDayAheadDataInput
}

export type CreateForecastMapeDayAheadPayload = {
  __typename?: 'CreateForecastMapeDayAheadPayload'
  data?: Maybe<CreateForecastMapeDayAheadData>
  errors?: Maybe<Array<CreateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreateForecastMapeIntradayData = {
  __typename?: 'CreateForecastMapeIntradayData'
  record: ScenarioForecastMapeIntraday
  recordId: Scalars['ID']['output']
}

export type CreateForecastMapeIntradayDataInput = {
  assumptions: Array<AssumptionValuesInput>
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type CreateForecastMapeIntradayInput = {
  data: CreateForecastMapeIntradayDataInput
}

export type CreateForecastMapeIntradayPayload = {
  __typename?: 'CreateForecastMapeIntradayPayload'
  data?: Maybe<CreateForecastMapeIntradayData>
  errors?: Maybe<Array<CreateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreateInflationData = {
  __typename?: 'CreateInflationData'
  record: ScenarioInflation
  recordId: Scalars['ID']['output']
}

export type CreateInflationDataInput = {
  assumptions?: InputMaybe<Array<AssumptionValuesInput>>
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type CreateInflationInput = {
  data: CreateInflationDataInput
}

export type CreateInflationPayload = {
  __typename?: 'CreateInflationPayload'
  data?: Maybe<CreateInflationData>
  errors?: Maybe<Array<CreateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreatePpaData = {
  __typename?: 'CreatePpaData'
  record: PpaX
  recordId: Scalars['ID']['output']
  solarAssetsAffected: Array<Scalars['ID']['output']>
}

export type CreatePpaDataInput = {
  /** Calculation interval of the PPA in minutes */
  calculationInterval: Scalars['Int']['input']
  /** Upper price limit for PPAs with floating pricing scheme in JPY/kWh */
  capPrice?: InputMaybe<Array<PpaPriceSchemeSetInput>>
  /** How many percent over or under the settlement price the PPA should be settled for. For PPAs with discount pricing scheme only */
  discountToMarket?: InputMaybe<Array<PpaPriceSchemeSetInput>>
  /** Specifies whether IPP or offtaker (electricity buyer) is the beneficiary of FIT or FIP payments. Currently, setting this to offtaker simply removes the FIP/FIT cash flow to zero. */
  fipBeneficiary: FipBeneficiary
  /** Percentage of the PPA that is settled at fixed price. For PPAs with floating pricing scheme only */
  fixedShare?: InputMaybe<Scalars['Float']['input']>
  /** Percentage of the PPA that is settled at floating price. For PPAs with floating pricing scheme only */
  floatingShare?: InputMaybe<Scalars['Float']['input']>
  /** Lower price limit for PPAs with floating pricing scheme in JPY/kWh */
  floorPrice: Array<PpaPriceSchemeSetInput>
  /** Flag that indicates if the PPA is making use of a flexible pricing scheme */
  isFlexiblePricingScheme: Scalars['Boolean']['input']
  /** Name of the ppa */
  name: Scalars['String']['input']
  /** Negative pricing floor in JPY that specifies how the PPA mechanics behave if the settlement price is negative. */
  negativePriceFloor?: InputMaybe<Scalars['Float']['input']>
  offtakerId?: InputMaybe<Scalars['ID']['input']>
  /** ID of the organization/workspace the ppa is associated with */
  organizationId: Scalars['ID']['input']
  /** Strike price escalation rate for PPAs with fixed escalation pricing scheme in %/year */
  priceEscalationRate?: InputMaybe<Scalars['Float']['input']>
  /** Pricing scheme of the PPA */
  pricingScheme: PricingScheme
  /** Market from which prices the settlement price is derived. Can be set to Day-ahead or Intraday. */
  settlementMarket: SettlementMarket
  /** Only available for Day-ahead settlement market. Pricing zone from which prices the settlement price is derived. Can be set to any of the 10 pricing zones in Japan. */
  settlementPricingZone: SettlementPricingZone
  /** List of solar asset IDs that are included in the PPA */
  solarAssetIds: Array<Scalars['ID']['input']>
  /** Start date of the PPA */
  startDate: Scalars['Time']['input']
  /** Current status of the ppa */
  status: PpaStatus
  /** Strike price for PPAs with fixed pricing scheme in JPY/kWh */
  strikePrice?: InputMaybe<Array<PpaPriceSchemeSetInput>>
  /** Term of the PPA in years */
  term: Scalars['Int']['input']
  /** The type of ppa */
  type: PpaType
}

export type CreatePpaPayload = {
  __typename?: 'CreatePpaPayload'
  data?: Maybe<CreatePpaData>
  errors?: Maybe<Array<CreatePpaProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreatePpaProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceDoesNotExistProblem
  | ResourceIsAlreadyInUseProblem

export type CreatePpaXInput = {
  data: CreatePpaDataInput
}

export type CreatePriceAverageData = {
  __typename?: 'CreatePriceAverageData'
  record: ScenarioPriceAverage
  recordId: Scalars['ID']['output']
}

export type CreatePriceAverageDataInput = {
  assumptions: RegionalWithSystemAssumptionsInput
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type CreatePriceAverageInput = {
  data: CreatePriceAverageDataInput
}

export type CreatePriceAveragePayload = {
  __typename?: 'CreatePriceAveragePayload'
  data?: Maybe<CreatePriceAverageData>
  errors?: Maybe<Array<CreateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreatePriceVolatilityData = {
  __typename?: 'CreatePriceVolatilityData'
  record: ScenarioPriceVolatility
  recordId: Scalars['ID']['output']
}

export type CreatePriceVolatilityDataInput = {
  assumptions: RegionalWithSystemAssumptionsInput
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type CreatePriceVolatilityInput = {
  data: CreatePriceVolatilityDataInput
}

export type CreatePriceVolatilityPayload = {
  __typename?: 'CreatePriceVolatilityPayload'
  data?: Maybe<CreatePriceVolatilityData>
  errors?: Maybe<Array<CreateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreateReportData = {
  __typename?: 'CreateReportData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type CreateReportInput = {
  language: Language
  organizationId: Scalars['String']['input']
  scope: ReportScopeInput
}

export type CreateReportPayload = {
  __typename?: 'CreateReportPayload'
  data?: Maybe<CreateReportData>
  errors?: Maybe<Array<CreateReportProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreateReportProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type CreateScenarioData = {
  __typename?: 'CreateScenarioData'
  record: MacroScenario
  recordId: Scalars['ID']['output']
}

export type CreateScenarioDataInput = {
  curtailmentId: Scalars['ID']['input']
  description: Scalars['String']['input']
  forecastMapeDayAheadId: Scalars['ID']['input']
  forecastMapeIntradayId: Scalars['ID']['input']
  inflationId: Scalars['ID']['input']
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
  priceAverageId: Scalars['ID']['input']
  priceVolatilityId: Scalars['ID']['input']
}

export type CreateScenarioInput = {
  data: CreateScenarioDataInput
}

export type CreateScenarioProblems =
  | AuthorizationProblem
  | MissingDependentsProblem
  | ResourceAlreadyExistsProblem

export type CreateScenarioResult = {
  __typename?: 'CreateScenarioResult'
  data?: Maybe<CreateScenarioData>
  errors?: Maybe<Array<CreateScenarioProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreateSolarAssetData = {
  __typename?: 'CreateSolarAssetData'
  record: SolarAsset
  recordId: Scalars['ID']['output']
}

export type CreateSolarAssetDataInput = {
  /** Percentage of electricity lost when passing through cables on the AC side. Defaults to 1 percent. */
  acCableLosses: Scalars['Float']['input']
  /** The asset's inverter AC capacity in W */
  acCapacity: Scalars['Float']['input']
  /** The asset's altitude in meters */
  altitude: Scalars['Float']['input']
  /** The asset's solar panel degradation in decimal of DC output. */
  annualDegradation: Scalars['Float']['input']
  /** Asset management costs in JPY/kWp/year */
  assetManagementCosts: Array<OpexCostSetInput>
  /** Co-located battery connected to the asset */
  battery?: InputMaybe<CreateBatteryInput>
  /** Capital expenditure payment items */
  capexPayments: Array<CreateCapexPaymentInput>
  /** Grid interconnection date */
  cod: Scalars['Time']['input']
  /** Issued by the TSO and serves as an additional identifier for each asset */
  contractClassificationNumber2?: InputMaybe<Scalars['String']['input']>
  dcCableLosses?: InputMaybe<Scalars['Float']['input']>
  /** Decommission reserve costs in JPY per kWh of generated electricity */
  decommissionReserveCosts: Array<OpexCostSetInput>
  /** Company that's leading the asset development */
  developerId?: InputMaybe<Scalars['ID']['input']>
  /** Annual electricity losses due to unforseen downtime in percent of AC output */
  downtimeRate: Scalars['Float']['input']
  /** ID of the associated epc company */
  epcCompanyId?: InputMaybe<Scalars['ID']['input']>
  /** Grid code of the asset */
  gridCode?: InputMaybe<Scalars['String']['input']>
  /** Utility grid zone where the asset is located */
  gridZone: GridZone
  /** Insurance costs in JPY/kWp/year */
  insuranceCosts: Array<OpexCostSetInput>
  /** Inverter replacement costs in JPY per replacement. Assumed to be incurred at the end of the inverter warranty period. */
  inverterCost: Array<OpexCostSetInput>
  /** Percentage of electricity lost when passing through the inverter on the AC side */
  inverterLosses: Scalars['Float']['input']
  /** Inverter warranty period in years */
  inverterWarrantyPeriod: Scalars['Int']['input']
  /** Is the solar asset using the advanced losses setting? */
  isUsingAdvancedLosses: Scalars['Boolean']['input']
  /** Land lease costs in JPY/kWp/year */
  landLeaseCosts: Array<OpexCostSetInput>
  /** Distance north or south of the equator in degrees */
  latitude: Scalars['Float']['input']
  /** Asset lifetime in years */
  lifetime: Scalars['Int']['input']
  /** Co-located load connected to the asset */
  load?: InputMaybe<CreateSolarAssetLoadInput>
  /** User-defined name of the asset location. Used in-lieu of addresses, which can be ambiguous. */
  locationName: Scalars['String']['input']
  /** Distance east or west of the prime meridian in degrees */
  longitude: Scalars['Float']['input']
  /** Day of the month on which the meter is read by the utility */
  macroScenarioId: Scalars['ID']['input']
  /** Day of the month on which the meter is read by the utility */
  meteringDate: Scalars['Int']['input']
  /** Unique ID issued by the Ministry of Economy, Trade and Industry (METI) for managing the asset in METI's online systems */
  metiRegistrationid: Scalars['String']['input']
  /** Unique ID issued by the Ministry of Economy, Trade and Industry (METI) for each asset */
  metiid: Scalars['String']['input']
  /** User-defined asset name */
  name: Scalars['String']['input']
  /** Operation and maintenance costs in JPY/kWp/year */
  omCosts: Array<OpexCostSetInput>
  /** ID of the associated om provider */
  omProviderId?: InputMaybe<Scalars['ID']['input']>
  /** ID of the workspace/organization the asset belongs in */
  organizationId: Scalars['ID']['input']
  /** Other operational costs in JPY/year */
  otherCosts: Array<OpexCostSetInput>
  /** ID of the associated ppa */
  ppaId?: InputMaybe<Scalars['ID']['input']>
  /**
   * Annual property tax is levied on the total book value of the asset. Regulation applies a 17% linear annual depreciation on solar PV assets.
   * Defaults to 1.4% annually of asset book value at that year.
   */
  propertyTaxRate: Array<OpexCostSetInput>
  /** Solar PV module (panel) type. Not user definable for the time being. */
  pvArray: Array<PvModuleInput>
  /** Percent of electricity lost to shading by trees, buildings, etc. */
  shadingLosses: Scalars['Float']['input']
  /** Whether an asset is still under development, operational, or under maintenance */
  status: AssetStatus
  /** Subsidy rate granted for the asset in JPY/kWh. Can be FIT or FIP */
  subsidyRate?: InputMaybe<Scalars['Float']['input']>
  /** Subsidy scheme the asset operates under. Can be feed-in-tariff, feed-in-premium, or no subsidy. */
  subsidyScheme: SubsidyScheme
  /** Start date of the asset subsidy scheme */
  subsidyStartDate?: InputMaybe<Scalars['Time']['input']>
  /** Unique ID issued by the electric utility of the grid zone where the asset is located */
  utilityid: Scalars['String']['input']
}

export type CreateSolarAssetInput = {
  data: CreateSolarAssetDataInput
}

export type CreateSolarAssetLoadInput = {
  /** Average load of the entity in kW */
  averageLoad: Scalars['Float']['input']
  /** Unique, non-human readable id */
  id: Scalars['ID']['input']
  /** Maximum load of the entity in kW */
  maximumLoad: Scalars['Float']['input']
  /** Minimum load of the entity in kW */
  minimumLoad: Scalars['Float']['input']
  /** User-defined asset load name */
  name: Scalars['String']['input']
  /** User-defined notes */
  notes: Scalars['String']['input']
  /** Date the load entity will start operation */
  operationalStartDate: Scalars['Time']['input']
  /** Indicates the current state of the load entity */
  status: SolarAssetLoadStatus
  /** User-defined asset load type (TDB) */
  type: SolarAssetLoadType
}

export type CreateSolarAssetPayload = {
  __typename?: 'CreateSolarAssetPayload'
  data?: Maybe<CreateSolarAssetData>
  errors?: Maybe<Array<CreateSolarAssetProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreateSolarAssetProblems = AuthorizationProblem | SolarAssetAlreadyExistsProblem

export type CreateSpvData = {
  __typename?: 'CreateSpvData'
  record: Spv
  recordId: Scalars['ID']['output']
}

export type CreateSpvInput = {
  /** IDs of the assets */
  assetIds: Array<Scalars['ID']['input']>
  /** Registered ID of the legal entity. */
  companyRegistrationId?: InputMaybe<Scalars['String']['input']>
  /** Type of legal entity */
  companyType: SpvCompanyType
  /** How often payments are made to equity investors */
  couponPaymentFrequency: CouponPaymentFrequency
  /**
   * For annual payments, the month the payout will be every year.
   * For biannual payments, the first payout month in the year.
   */
  couponPaymentMonth: Month
  /** Percentage of profit and loss paid out to TK investors represented as a decimal */
  couponPayoutPercentage: Scalars['Float']['input']
  /** Financial year the SPV follows */
  financialYear: Month
  /** The investments the SPV received */
  investments: Array<CreateSpvInvestmentInput>
  /** Date when the SPV legal entity was created */
  legalEntityCreationDate?: InputMaybe<Scalars['Time']['input']>
  /** Registered name of the legal entity in english */
  legalEntityNameEn?: InputMaybe<Scalars['String']['input']>
  /** Registered name of the legal entity in japanese */
  legalEntityNameJa: Scalars['String']['input']
  /** The loans the SPV took out */
  loans: Array<CreateSpvLoanInput>
  /** Percentage of debt-service coverage ratio represented as a decimal */
  targetDSCR?: InputMaybe<Scalars['Float']['input']>
  /** ID of the workspace the SPV belongs to */
  workspaceId: Scalars['ID']['input']
}

export type CreateSpvInvestmentInput = {
  /** Amount of money invested by the Investor in JPY */
  amount: Scalars['Float']['input']
  /** Unique identifier for the investment */
  id: Scalars['ID']['input']
  /** Date the investment was made */
  investmentDate: Scalars['Time']['input']
  /** Name of the investor that has made the investment */
  investorName: Scalars['String']['input']
}

export type CreateSpvLoanInput = {
  /** Amount of money being loaned in JPY */
  amount: Scalars['Float']['input']
  /** Description of the loan */
  description: Scalars['String']['input']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['input']
  /** Interest rate of the given loan represented as a decimal */
  interestRate: Scalars['Float']['input']
  /** Name of the Lender that has given the loan */
  lenderName: Scalars['String']['input']
  /** Date when the loaned money amount is received */
  loanReceivedDate: Scalars['Time']['input']
  /** Date when repayment of the loan ends */
  repaymentEndDate: Scalars['Time']['input']
  /** Frequency the loan is repaid at */
  repaymentFrequency: RepaymentFrequency
  /** Date when repayment of the loan begins */
  repaymentStartDate: Scalars['Time']['input']
}

export type CreateSpvPayload = {
  __typename?: 'CreateSpvPayload'
  data?: Maybe<CreateSpvData>
  errors?: Maybe<Array<CreateSpvProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreateSpvProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceIsAlreadyInUseProblem
  | SpvResourceDoesNotExistProblem

export type CreateThirdPartyCompanyData = {
  __typename?: 'CreateThirdPartyCompanyData'
  record: ThirdPartyCompany
  recordId: Scalars['ID']['output']
}

export type CreateThirdPartyCompanyInput = {
  name: Scalars['String']['input']
  type: ThirdPartyCompanyType
  workspaceId: Scalars['ID']['input']
}

export type CreateThirdPartyCompanyPayload = {
  __typename?: 'CreateThirdPartyCompanyPayload'
  data?: Maybe<CreateThirdPartyCompanyData>
  errors?: Maybe<Array<CreateThirdPartyCompanyProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type CreateThirdPartyCompanyProblems = AuthorizationProblem | ResourceAlreadyExistsProblem

export type CreateWorkspaceData = {
  __typename?: 'CreateWorkspaceData'
  /** The created workspace */
  record: Workspace
  /** ID of the created workspace */
  recordId: Scalars['ID']['output']
}

export type CreateWorkspaceInput = {
  name: Scalars['String']['input']
  prefix: Scalars['String']['input']
}

export type CreateWorkspaceInputV2 = {
  invitationId: Scalars['ID']['input']
  name: Scalars['String']['input']
  prefix: Scalars['String']['input']
}

export type CreateWorkspacePayload = {
  __typename?: 'CreateWorkspacePayload'
  /** The created workspace */
  data?: Maybe<CreateWorkspaceData>
  /** Any problems that occur during the operation */
  errors?: Maybe<Array<CreateWorkspaceProblems>>
  /** Recursive query to allow data fetching in a single network trip */
  query?: Maybe<Query>
  /** Flag that indicates if the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

export type CreateWorkspaceProblems = WorkspaceAlreadyExistsProblem | WorkspaceInvitationIdProblem

export type CurtailmentList = {
  __typename?: 'CurtailmentList'
  pageInfo: PaginationInfo
  records: Array<ScenarioCurtailment>
}

export type CurtailmentMutations = {
  __typename?: 'CurtailmentMutations'
  create: CreateCurtailmentPayload
  delete: DeleteCurtailmentPayload
  deleteAndReplace: DeleteCurtailmentPayload
  update: UpdateCurtailmentPayload
}

export type CurtailmentMutationsCreateArgs = {
  input: CreateCurtailmentInput
}

export type CurtailmentMutationsDeleteArgs = {
  input: DeleteAssumptionInput
}

export type CurtailmentMutationsDeleteAndReplaceArgs = {
  input: DeleteAndReplaceAssumptionInput
}

export type CurtailmentMutationsUpdateArgs = {
  input: UpdateCurtailmentInput
}

export type CustomElectricityGenerationForecast = {
  __typename?: 'CustomElectricityGenerationForecast'
  /** List of assets that were included in the forecast */
  assets: Array<SolarAsset>
  /** Rows to be included in the custom forecast */
  rows: Array<CustomElectricityGenerationForecastRow>
}

export type CustomElectricityGenerationForecastInput = {
  /** Time the desired forecasts was created at. Field is ignored unless type is equal to FIXED. */
  createdAt?: InputMaybe<Scalars['Time']['input']>
  /** End time for the desired forecast range */
  end: Scalars['Time']['input']
  /** An offset in relation to the forecast start time, represented in seconds. Field is ignored unless type is equal to OFFSET */
  offset?: InputMaybe<Scalars['Int']['input']>
  /** Scope of the forecast */
  scope: CustomElectricityGenerationForecastScope
  /** ID of the desired scope entity. This field is required to be set if the scope is equal to ASSET or BALANCING_GROUP */
  scopeId?: InputMaybe<Scalars['ID']['input']>
  /** Start time for the desired forecast range */
  start: Scalars['Time']['input']
  /** Type of forecast */
  type: CustomElectricityGenerationForecastType
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type CustomElectricityGenerationForecastRow = {
  __typename?: 'CustomElectricityGenerationForecastRow'
  /** Datetime for the row */
  datetime: Scalars['Time']['output']
  /** Total amount of forecasted generation, expressed in kilowatt hour (kWh) */
  total: Scalars['Float']['output']
  /** Asset forecasted values for the row */
  values: Array<CustomElectricityGenerationForecastRowValue>
}

export type CustomElectricityGenerationForecastRowValue = {
  __typename?: 'CustomElectricityGenerationForecastRowValue'
  /** ID of the asset the value is associated with */
  assetId: Scalars['ID']['output']
  /** Forecasted generation value, expressed in kilowatt hour (kWh). A null value indicates there was no value for that row */
  value?: Maybe<Scalars['Float']['output']>
}

/** Valid scopes that are available for electricity generation forecasts */
export enum CustomElectricityGenerationForecastScope {
  /** Forecast scope of an asset */
  Asset = 'ASSET',
  /** Forecast scope of a balancing group */
  BalancingGroup = 'BALANCING_GROUP',
  /** Forecast scope of a workspace */
  Workspace = 'WORKSPACE',
}

/** Available types of electricity generation forecasts that can be generated */
export enum CustomElectricityGenerationForecastType {
  /** DAY_AHEAD */
  DayAhead = 'DAY_AHEAD',
  /** FIXED */
  Fixed = 'FIXED',
  /** LATEST */
  Latest = 'LATEST',
  /** OFFSET */
  Offset = 'OFFSET',
}

export type DayAheadPriceVolumes = {
  __typename?: 'DayAheadPriceVolumes'
  vol: DayAheadVolumes
  zone: ZonePrices
}

export type DayAheadSeries = {
  __typename?: 'DayAheadSeries'
  set: Array<DayAheadSeriesSet>
  updatedAt: Scalars['Time']['output']
}

export type DayAheadSeriesSet = {
  __typename?: 'DayAheadSeriesSet'
  t: Scalars['Time']['output']
  v: DayAheadPriceVolumes
}

export type DayAheadVolumes = {
  __typename?: 'DayAheadVolumes'
  /** Day-ahead trading volume in kWh */
  da?: Maybe<Scalars['Float']['output']>
  /** Intraday trading volume in kWh */
  itd?: Maybe<Scalars['Float']['output']>
}

export type DeleteAndReplaceAssumptionInput = {
  id: Scalars['ID']['input']
  replacementId: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type DeleteAssumptionInput = {
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type DeleteAssumptionProblems =
  | AuthorizationProblem
  | ReplacementDoesNotExistProblem
  | ReplacementRequiredProblem
  | ResourceDoesNotExistProblem

export type DeleteBalancingGroupData = {
  __typename?: 'DeleteBalancingGroupData'
  /** Record that was deleted */
  record: BalancingGroup
  /** ID of the record. Format = UUID */
  recordId: Scalars['ID']['output']
}

export type DeleteBalancingGroupInput = {
  /** ID of the balancing group. Format = UUID */
  id: Scalars['ID']['input']
  /** ID of the workspace the balancing group is associated with. Format = UUID */
  workspaceId: Scalars['ID']['input']
}

export type DeleteBalancingGroupPayload = {
  __typename?: 'DeleteBalancingGroupPayload'
  /** Return data payload from mutation */
  data?: Maybe<DeleteBalancingGroupData>
  /** Any errors that occurred during the mutation */
  errors?: Maybe<Array<DeleteBalancingGroupProblems>>
  /** Recursive query property that provides access to any query in the Query namespace */
  query?: Maybe<Query>
  /** Flag that indicates whether the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

/** All problems that can occur during deleting a balancing group */
export type DeleteBalancingGroupProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeleteCurtailmentData = {
  __typename?: 'DeleteCurtailmentData'
  record: ScenarioCurtailment
  recordId: Scalars['ID']['output']
}

export type DeleteCurtailmentPayload = {
  __typename?: 'DeleteCurtailmentPayload'
  data?: Maybe<DeleteCurtailmentData>
  errors?: Maybe<Array<DeleteAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteForecastMapeDayAheadData = {
  __typename?: 'DeleteForecastMapeDayAheadData'
  record: ScenarioForecastMapeDayAhead
  recordId: Scalars['ID']['output']
}

export type DeleteForecastMapeDayAheadPayload = {
  __typename?: 'DeleteForecastMapeDayAheadPayload'
  data?: Maybe<DeleteForecastMapeDayAheadData>
  errors?: Maybe<Array<DeleteAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteForecastMapeIntradayData = {
  __typename?: 'DeleteForecastMapeIntradayData'
  record: ScenarioForecastMapeIntraday
  recordId: Scalars['ID']['output']
}

export type DeleteForecastMapeIntradayPayload = {
  __typename?: 'DeleteForecastMapeIntradayPayload'
  data?: Maybe<DeleteForecastMapeIntradayData>
  errors?: Maybe<Array<DeleteAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteInflationData = {
  __typename?: 'DeleteInflationData'
  record: ScenarioInflation
  recordId: Scalars['ID']['output']
}

export type DeleteInflationPayload = {
  __typename?: 'DeleteInflationPayload'
  data?: Maybe<DeleteInflationData>
  errors?: Maybe<Array<DeleteAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteManyBaselinesData = {
  __typename?: 'DeleteManyBaselinesData'
  record: Baseline
  recordId: Scalars['ID']['output']
}

export type DeleteManyBaselinesInput = {
  /** ID of the baseline */
  ids: Array<Scalars['ID']['input']>
  /** ID of the workspace associated with the baseline */
  workspaceId: Scalars['ID']['input']
}

export type DeleteManyBaselinesPayload = {
  __typename?: 'DeleteManyBaselinesPayload'
  /** Returned entities that were deleted */
  data?: Maybe<Array<DeleteManyBaselinesData>>
  /** Any errors that occurred during the operation */
  errors?: Maybe<Array<DeleteManyBaselinesProblems>>
  /** Recursive query */
  query?: Maybe<Query>
  /** Flag that indicates whether the operation succeeded or not */
  success: Scalars['Boolean']['output']
}

export type DeleteManyBaselinesProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeleteManyPpasData = {
  __typename?: 'DeleteManyPpasData'
  record: PpaX
  recordId: Scalars['ID']['output']
}

export type DeleteManyPpasInput = {
  ids: Array<Scalars['ID']['input']>
  workspaceId: Scalars['ID']['input']
}

export type DeleteManyPpasPayload = {
  __typename?: 'DeleteManyPpasPayload'
  data?: Maybe<Array<DeleteManyPpasData>>
  errors?: Maybe<Array<DeleteManyPpasProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteManyPpasProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeleteManyReportsData = {
  __typename?: 'DeleteManyReportsData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type DeleteManyReportsInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>
  organizationId: Scalars['ID']['input']
}

export type DeleteManyReportsPayload = {
  __typename?: 'DeleteManyReportsPayload'
  data?: Maybe<Array<DeleteManyReportsData>>
  errors?: Maybe<Array<DeleteManyReportsProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteManyReportsProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeleteManyScenariosData = {
  __typename?: 'DeleteManyScenariosData'
  record: MacroScenario
  recordId: Scalars['ID']['output']
}

export type DeleteManyScenariosInput = {
  ids: Array<Scalars['ID']['input']>
  workspaceId: Scalars['ID']['input']
}

export type DeleteManyScenariosPayload = {
  __typename?: 'DeleteManyScenariosPayload'
  data?: Maybe<Array<DeleteManyScenariosData>>
  errors?: Maybe<Array<DeleteManyScenariosProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteManyScenariosProblems =
  | AuthorizationProblem
  | ResourceCannotBeDeletedProblem
  | ResourceDoesNotExistProblem

export type DeleteManySolarAssetsData = {
  __typename?: 'DeleteManySolarAssetsData'
  record: SolarAsset
  recordId: Scalars['ID']['output']
}

export type DeleteManySolarAssetsInput = {
  ids: Array<Scalars['ID']['input']>
  workspaceId: Scalars['ID']['input']
}

export type DeleteManySolarAssetsPayload = {
  __typename?: 'DeleteManySolarAssetsPayload'
  data?: Maybe<Array<DeleteManySolarAssetsData>>
  errors?: Maybe<Array<DeleteManySolarAssetsProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteManySolarAssetsProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeleteManyThirdPartyCompaniesData = {
  __typename?: 'DeleteManyThirdPartyCompaniesData'
  record: ThirdPartyCompany
  recordId: Scalars['ID']['output']
}

export type DeleteManyThirdPartyCompaniesInput = {
  ids: Array<Scalars['ID']['input']>
  workspaceId: Scalars['ID']['input']
}

export type DeleteManyThirdPartyCompaniesPayload = {
  __typename?: 'DeleteManyThirdPartyCompaniesPayload'
  data?: Maybe<Array<DeleteManyThirdPartyCompaniesData>>
  errors?: Maybe<Array<DeleteManyThirdPartyCompaniesProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteManyThirdPartyCompaniesProblems =
  | AuthorizationProblem
  | ResourceDoesNotExistProblem

export type DeletePpaData = {
  __typename?: 'DeletePpaData'
  record: PpaX
  recordId: Scalars['ID']['output']
  solarAssetsAffected: Array<Scalars['ID']['output']>
}

export type DeletePpaPayload = {
  __typename?: 'DeletePpaPayload'
  data?: Maybe<DeletePpaData>
  errors?: Maybe<Array<DeletePpaProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeletePpaProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeletePpaXInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type DeletePriceAverageData = {
  __typename?: 'DeletePriceAverageData'
  record: ScenarioPriceAverage
  recordId: Scalars['ID']['output']
}

export type DeletePriceAveragePayload = {
  __typename?: 'DeletePriceAveragePayload'
  data?: Maybe<DeletePriceAverageData>
  errors?: Maybe<Array<DeleteAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeletePriceVolatilityData = {
  __typename?: 'DeletePriceVolatilityData'
  record: ScenarioPriceVolatility
  recordId: Scalars['ID']['output']
}

export type DeletePriceVolatilityPayload = {
  __typename?: 'DeletePriceVolatilityPayload'
  data?: Maybe<DeletePriceVolatilityData>
  errors?: Maybe<Array<DeleteAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteReportData = {
  __typename?: 'DeleteReportData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type DeleteReportInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type DeleteReportPayload = {
  __typename?: 'DeleteReportPayload'
  data?: Maybe<DeleteReportData>
  errors?: Maybe<Array<DeleteReportProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteReportProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeleteScenarioData = {
  __typename?: 'DeleteScenarioData'
  record: MacroScenario
  recordId: Scalars['ID']['output']
}

export type DeleteScenarioInput = {
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type DeleteScenarioPayload = {
  __typename?: 'DeleteScenarioPayload'
  data?: Maybe<DeleteScenarioData>
  errors?: Maybe<Array<DeleteScenarioProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteScenarioProblems =
  | AuthorizationProblem
  | ResourceCannotBeDeletedProblem
  | ResourceDoesNotExistProblem

export type DeleteSolarAssetData = {
  __typename?: 'DeleteSolarAssetData'
  record: SolarAsset
  recordId: Scalars['ID']['output']
}

export type DeleteSolarAssetInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type DeleteSolarAssetPayload = {
  __typename?: 'DeleteSolarAssetPayload'
  data?: Maybe<DeleteSolarAssetData>
  errors?: Maybe<Array<DeleteSolarAssetProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteSolarAssetProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeleteSpvData = {
  __typename?: 'DeleteSpvData'
  record: Spv
  recordId: Scalars['ID']['output']
}

export type DeleteSpvInput = {
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type DeleteSpvPayload = {
  __typename?: 'DeleteSpvPayload'
  data?: Maybe<DeleteSpvData>
  errors?: Maybe<Array<DeleteSpvProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteSpvProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeleteThirdPartyCompanyData = {
  __typename?: 'DeleteThirdPartyCompanyData'
  record: ThirdPartyCompany
  recordId: Scalars['ID']['output']
}

export type DeleteThirdPartyCompanyInput = {
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type DeleteThirdPartyCompanyPayload = {
  __typename?: 'DeleteThirdPartyCompanyPayload'
  data?: Maybe<DeleteThirdPartyCompanyData>
  errors?: Maybe<Array<DeleteThirdPartyCompanyProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type DeleteThirdPartyCompanyProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type DeleteWorkspaceApiKeyData = {
  __typename?: 'DeleteWorkspaceApiKeyData'
  /** Workspace API key that was deleted */
  record: WorkspaceApiKey
  /** ID of the deleted workspace api key */
  recordId: Scalars['ID']['output']
}

export type DeleteWorkspaceApiKeyInput = {
  /** ID of the workspace api key to delete */
  id: Scalars['ID']['input']
  /** ID of the workspace associated with the key */
  workspaceId: Scalars['ID']['input']
}

export type DeleteWorkspaceApiKeyPayload = {
  __typename?: 'DeleteWorkspaceApiKeyPayload'
  /** Deleted workspace api key */
  data?: Maybe<DeleteWorkspaceApiKeyData>
  /** Any problems that occurred during the operation */
  errors?: Maybe<Array<DeleteWorkspaceApiKeyProblems>>
  /** Recursive query to allow data fetching in a single network trip */
  query?: Maybe<Query>
  /** Flag that indicates if the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

export type DeleteWorkspaceApiKeyProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type ElectricityConsumptionSeries = {
  __typename?: 'ElectricityConsumptionSeries'
  set: Array<ElectricityConsumptionSeriesSet>
  updatedAt: Scalars['Time']['output']
}

export type ElectricityConsumptionSeriesSet = {
  __typename?: 'ElectricityConsumptionSeriesSet'
  av: ElectricityConsumptionVolumes
  sv: ElectricityConsumptionVolumes
  t: Scalars['Time']['output']
}

export type ElectricityConsumptionVolumes = {
  __typename?: 'ElectricityConsumptionVolumes'
  /** Electricity demand in kWh */
  dem?: Maybe<Scalars['Float']['output']>
  /** Electricity sent out through interconnectors in kWh */
  itc?: Maybe<Scalars['Float']['output']>
  /** Electricity stored in pumped hydro in kWh */
  phy?: Maybe<Scalars['Float']['output']>
  /** Solar curtailment volume in kWh */
  sol?: Maybe<Scalars['Float']['output']>
  /** Wind curtailment volume in kWh */
  win?: Maybe<Scalars['Float']['output']>
}

export type ElectricityGenerationForecast = {
  __typename?: 'ElectricityGenerationForecast'
  /** Balancing group the forecast was intended for */
  balancingGroup: BalancingGroup
  /** Time the forecast was created at */
  createdAt: Scalars['Time']['output']
  /**
   * Unique identifier for the forecast. Since the forecasts are not pre-generated, the id will have the
   * following format to ensure uniqueness across the platform. {BALANCING_GROUP_ID}:{createdAt}
   */
  id: Scalars['ID']['output']
}

export type ElectricityGenerationForecasts = {
  __typename?: 'ElectricityGenerationForecasts'
  /** All forecasts available */
  records: Array<ElectricityGenerationForecast>
  /** Last time electricity generation forecasts were updated at */
  updatedAt?: Maybe<Scalars['Time']['output']>
}

export type ElectricityGenerationForecastsFilter = {
  /** The balancing group's forecasts to include in the return */
  balancingGroup?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ElectricityGenerationForecastsInput = {
  /** Time the interval should start from */
  from: Scalars['Time']['input']
  /** Time the interval should end to */
  to?: InputMaybe<Scalars['Time']['input']>
  /** ID of the workspace the forecasts are associated with */
  workspaceId: Scalars['ID']['input']
}

export type ElectricityGenerationSeries = {
  __typename?: 'ElectricityGenerationSeries'
  set: Array<ElectricityGenerationSeriesSet>
  updatedAt: Scalars['Time']['output']
}

export type ElectricityGenerationSeriesSet = {
  __typename?: 'ElectricityGenerationSeriesSet'
  av: ElectricityGenerationVolumes
  sv: ElectricityGenerationVolumes
  t: Scalars['Time']['output']
}

export type ElectricityGenerationVolumes = {
  __typename?: 'ElectricityGenerationVolumes'
  /** Biomass generation volume in kWh */
  bio?: Maybe<Scalars['Float']['output']>
  /** Geothermal generation volume in kWh */
  geo?: Maybe<Scalars['Float']['output']>
  /** Hydro generation volume in kWh */
  hyd?: Maybe<Scalars['Float']['output']>
  /** Electricity received through interconnectors in kWh */
  itc?: Maybe<Scalars['Float']['output']>
  /** Nuclear generation volume in kWh */
  nuc?: Maybe<Scalars['Float']['output']>
  /** Pumped hydro generation volume in kWh */
  phy?: Maybe<Scalars['Float']['output']>
  /** Solar generation volume in kWh */
  sol?: Maybe<Scalars['Float']['output']>
  /** Thermal generation volume in kWh */
  the?: Maybe<Scalars['Float']['output']>
  /** Wind generation volume in kWh */
  win?: Maybe<Scalars['Float']['output']>
}

export type ExpiredInvitationProblem = ProblemInterface & {
  __typename?: 'ExpiredInvitationProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
}

/** Reasons for a failure when editing scenarios */
export enum FailureReason {
  AssumptionInUse = 'ASSUMPTION_IN_USE',
  AssumptionNotFound = 'ASSUMPTION_NOT_FOUND',
  DefaultScenarioCantUpdateName = 'DEFAULT_SCENARIO_CANT_UPDATE_NAME',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NameAlreadyExists = 'NAME_ALREADY_EXISTS',
  RecordNotFound = 'RECORD_NOT_FOUND',
  ScenarioNotFound = 'SCENARIO_NOT_FOUND',
}

export type File = {
  __typename?: 'File'
  /** Inner contents of the file */
  content?: Maybe<Scalars['String']['output']>
  /** The representation header to indicate the original media type of the resource */
  contentType?: Maybe<Scalars['String']['output']>
  /** Time the file was created at */
  createdAt: Scalars['Time']['output']
  /** A unique, non-human readable ID for the file */
  id: Scalars['ID']['output']
  /** Name of the file */
  name: Scalars['String']['output']
  /** Status and any metadata about the status of the file */
  status: FileStatus
  /** Time the file was last updated at */
  updatedAt: Scalars['Time']['output']
}

export type FileStatus = {
  __typename?: 'FileStatus'
  status: FileStatusType
}

export enum FileStatusType {
  PartiallyProcessed = 'PARTIALLY_PROCESSED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  ProcessFailed = 'PROCESS_FAILED',
  Uploaded = 'UPLOADED',
  Uploading = 'UPLOADING',
  UploadFailed = 'UPLOAD_FAILED',
}

export type Filter = {
  endDate?: InputMaybe<Scalars['Time']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['Time']['input']>
  timeRange?: InputMaybe<SeriesTimeRange>
}

export enum FipBeneficiary {
  Ipp = 'IPP',
  Offtaker = 'OFFTAKER',
}

export type ForecastMapeDayAheadList = {
  __typename?: 'ForecastMapeDayAheadList'
  pageInfo: PaginationInfo
  records: Array<ScenarioForecastMapeDayAhead>
}

export type ForecastMapeDayAheadMutations = {
  __typename?: 'ForecastMapeDayAheadMutations'
  create: CreateForecastMapeDayAheadPayload
  delete: DeleteForecastMapeDayAheadPayload
  deleteAndReplace: DeleteForecastMapeDayAheadPayload
  update: UpdateForecastMapeDayAheadPayload
}

export type ForecastMapeDayAheadMutationsCreateArgs = {
  input: CreateForecastMapeDayAheadInput
}

export type ForecastMapeDayAheadMutationsDeleteArgs = {
  input: DeleteAssumptionInput
}

export type ForecastMapeDayAheadMutationsDeleteAndReplaceArgs = {
  input: DeleteAndReplaceAssumptionInput
}

export type ForecastMapeDayAheadMutationsUpdateArgs = {
  input: UpdateForecastMapeDayAheadInput
}

export type ForecastMapeIntradayList = {
  __typename?: 'ForecastMapeIntradayList'
  pageInfo: PaginationInfo
  records: Array<ScenarioForecastMapeIntraday>
}

export type ForecastMapeIntradayMutations = {
  __typename?: 'ForecastMapeIntradayMutations'
  create: CreateForecastMapeIntradayPayload
  delete: DeleteForecastMapeIntradayPayload
  deleteAndReplace: DeleteForecastMapeIntradayPayload
  update: UpdateForecastMapeIntradayPayload
}

export type ForecastMapeIntradayMutationsCreateArgs = {
  input: CreateForecastMapeIntradayInput
}

export type ForecastMapeIntradayMutationsDeleteArgs = {
  input: DeleteAssumptionInput
}

export type ForecastMapeIntradayMutationsDeleteAndReplaceArgs = {
  input: DeleteAndReplaceAssumptionInput
}

export type ForecastMapeIntradayMutationsUpdateArgs = {
  input: UpdateForecastMapeIntradayInput
}

export type ForecastMutations = {
  __typename?: 'ForecastMutations'
  generatePresignedUrl: GeneratePresignedUrlPayload
  /** @deprecated Has been moved to SolarAssetsMutations. Please use mutation.solarAssets.generateUploadActualsUrls instead. */
  generateUploadGenerationDataUrls: GenerateUploadGenerationDataUrlsPayload
}

export type ForecastMutationsGeneratePresignedUrlArgs = {
  input: GeneratePresignedUrlInput
}

export type ForecastMutationsGenerateUploadGenerationDataUrlsArgs = {
  input: GenerateUploadGenerationDataUrlsInput
}

export type GenerateFinancialStatementInput = {
  id: Scalars['ID']['input']
  language: Language
  workspaceId: Scalars['ID']['input']
}

export type GenerateFinancialStatementPayload = {
  __typename?: 'GenerateFinancialStatementPayload'
  /** A presigned url to download the financial statement from S3 */
  data?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<GenerateFinancialStatementProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type GenerateFinancialStatementProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type GenerateOcctoLongTermPlanUrlInput = {
  /** Starting date of the OCCTO long term plan */
  date: Scalars['Time']['input']
  /** Grid zone for the OCCTO long term plan */
  gridZone: GridZone
  /** Type of OCCTO long term plan */
  type: OcctoLongTermPlanType
  /** Update date of the OCCTO long term plan. This field is only acknowledged if the type is set to WEEKLY_UPDATE, otherwise it is ignored */
  updateDate?: InputMaybe<Scalars['Time']['input']>
  /** ID of the workspace that is associated with the OCCTO long term plan */
  workspaceId: Scalars['ID']['input']
}

export type GenerateOcctoLongTermPlanUrlPayload = {
  __typename?: 'GenerateOcctoLongTermPlanUrlPayload'
  /** URL of the Occto plan */
  data?: Maybe<Scalars['String']['output']>
  /** Any problems that occurred during operation */
  errors?: Maybe<Array<GenerateOcctoLongTermPlanUrlProblems>>
  /** Recursive query object */
  query?: Maybe<Query>
  /** Flag that indicates if the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

export type GenerateOcctoLongTermPlanUrlProblems =
  | AuthorizationProblem
  | GenerateOcctoPlanUrlApiProblem
  | ResourceDoesNotExistProblem

export type GenerateOcctoPlanUrlApiProblem = ProblemInterface & {
  __typename?: 'GenerateOcctoPlanUrlApiProblem'
  message: Scalars['String']['output']
  type: GenerateOcctoPlanUrlApiProblemType
}

export enum GenerateOcctoPlanUrlApiProblemType {
  InvalidSenderId = 'INVALID_SENDER_ID',
  InvalidSubmitterId = 'INVALID_SUBMITTER_ID',
}

export type GenerateOcctoPlanUrlInput = {
  /** Date to generate plan for */
  date: Scalars['Time']['input']
  /** Grid zone associated with the plan to generate */
  gridZone: GridZone
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type GenerateOcctoPlanUrlPayload = {
  __typename?: 'GenerateOcctoPlanUrlPayload'
  /** URL of the Occto plan */
  data: Scalars['String']['output']
  errors?: Maybe<Array<GenerateOcctoPlanUrlProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type GenerateOcctoPlanUrlProblems =
  | AuthorizationProblem
  | GenerateOcctoPlanUrlApiProblem
  | ResourceDoesNotExistProblem

export type GeneratePresignedUrlInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type GeneratePresignedUrlPayload = {
  __typename?: 'GeneratePresignedUrlPayload'
  data?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<GeneratePresignedUrlProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type GeneratePresignedUrlProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type GenerateSolarAssetUploadActualsUrlsData = {
  __typename?: 'GenerateSolarAssetUploadActualsUrlsData'
  /** Required headers to be sent along with the put presigned url in stringified JSON form. */
  headers: Scalars['String']['output']
  /** Key/name of the blob storage object */
  key: Scalars['String']['output']
  /** Presigned url to upload the data */
  url: Scalars['String']['output']
}

export type GenerateSolarAssetUploadActualsUrlsInput = {
  /** ID of the asset */
  assetId?: InputMaybe<Scalars['ID']['input']>
  /** File keys/names of the blob storage objects */
  keys: Array<Scalars['String']['input']>
  /** Type of upload actuals */
  type: SolarAssetActualsType
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type GenerateSolarAssetUploadActualsUrlsPayload = {
  __typename?: 'GenerateSolarAssetUploadActualsUrlsPayload'
  data?: Maybe<Array<GenerateSolarAssetUploadActualsUrlsData>>
  errors?: Maybe<Array<GenerateSolarAssetUploadActualsUrlsProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type GenerateSolarAssetUploadActualsUrlsProblems =
  | AuthorizationProblem
  | ResourceDoesNotExistProblem

export type GenerateUploadGenerationDataUrlsData = {
  __typename?: 'GenerateUploadGenerationDataUrlsData'
  /** Required headers to be sent along with the put presigned url in stringified JSON form. */
  headers: Scalars['String']['output']
  /** Key/name of the blob storage object */
  key: Scalars['String']['output']
  /** Presigned url to upload the data */
  url: Scalars['String']['output']
}

export type GenerateUploadGenerationDataUrlsInput = {
  /** ID of the asset */
  assetId?: InputMaybe<Scalars['ID']['input']>
  /** File keys/names of the blob storage objects */
  keys: Array<Scalars['String']['input']>
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type GenerateUploadGenerationDataUrlsPayload = {
  __typename?: 'GenerateUploadGenerationDataUrlsPayload'
  data?: Maybe<Array<GenerateUploadGenerationDataUrlsData>>
  errors?: Maybe<Array<GenerateUploadGenerationDataUrlsProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type GenerateUploadGenerationDataUrlsProblems =
  | AuthorizationProblem
  | ResourceDoesNotExistProblem

export type GenerateWorkspaceApiKeyData = {
  __typename?: 'GenerateWorkspaceApiKeyData'
  /** Workspace API key generated */
  record: WorkspaceApiKey
  /** ID of the workspace API key generated */
  recordId: Scalars['ID']['output']
}

export type GenerateWorkspaceApiKeyInput = {
  /** Name of the key */
  name: Scalars['String']['input']
  /** Permissions granted to the api key */
  permissions: Array<WorkspaceApiKeyPermission>
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type GenerateWorkspaceApiKeyPayload = {
  __typename?: 'GenerateWorkspaceApiKeyPayload'
  /** Generated workspace api key */
  data?: Maybe<GenerateWorkspaceApiKeyData>
  /** Any problems that occurred during the operation */
  errors?: Maybe<Array<GenerateWorkspaceApiKeyProblems>>
  /** Recursive query to allow data fetching in a single network trip */
  query?: Maybe<Query>
  /** Flag that indicates if the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

export type GenerateWorkspaceApiKeyProblems = AuthorizationProblem | ResourceAlreadyExistsProblem

export type GenerationPlan = {
  __typename?: 'GenerationPlan'
  /** Time the plan was generated at */
  generatedAt: Scalars['Time']['output']
  /** Electricity generation plan as a series. Values are in represented in kilowatt hour (kWh) */
  plan: Array<SingleSeriesSet>
  /** Total electricity generated in the plan */
  totalGeneration: Scalars['Float']['output']
}

export type GenerationUploadsInput = {
  workspaceId: Scalars['ID']['input']
}

export type GetAssumptionListInput = {
  organizationId: Scalars['ID']['input']
}

export enum GridZone {
  Chubu = 'CHUBU',
  Chugoku = 'CHUGOKU',
  Hokkaido = 'HOKKAIDO',
  Hokuriku = 'HOKURIKU',
  Kansai = 'KANSAI',
  Kyushu = 'KYUSHU',
  Okinawa = 'OKINAWA',
  Shikoku = 'SHIKOKU',
  Tohoku = 'TOHOKU',
  Tokyo = 'TOKYO',
}

export type HighPerformanceNullableNumberSeries = {
  __typename?: 'HighPerformanceNullableNumberSeries'
  /** Series of data. Every time slot (dependent on the interval) should always contain a value. Float or NULL */
  data: Array<Maybe<Scalars['Float']['output']>>
  /** End time of the series */
  endTime: Scalars['Time']['output']
  /** The interval the series follows in seconds. Ex. 30 minute interval = 1800s */
  interval: Scalars['Int']['output']
  /** Start time of the series */
  startTime: Scalars['Time']['output']
}

export type HighPerformanceNumberSeries = {
  __typename?: 'HighPerformanceNumberSeries'
  /** Series of data. Every time slot (dependent on the interval) should always contain a value. */
  data: Array<Scalars['Float']['output']>
  /** End time of the series */
  endTime: Scalars['Time']['output']
  /** The interval the series follows in seconds. Ex. 30 minute interval = 1800s */
  interval: Scalars['Int']['output']
  /** Start time of the series */
  startTime: Scalars['Time']['output']
}

export type IdFilter = {
  __typename?: 'IDFilter'
  ids: Array<Scalars['ID']['output']>
  isNil: Scalars['Boolean']['output']
}

export type IdFilterInput = {
  ids: Array<Scalars['ID']['input']>
  isNil: Scalars['Boolean']['input']
}

export type ImbalancePriceVolumes = {
  __typename?: 'ImbalancePriceVolumes'
  /** Imbalance price */
  price: ImbalancePrices
  /** Imbalance volume in kWh */
  vol?: Maybe<Scalars['Float']['output']>
}

export type ImbalancePrices = {
  __typename?: 'ImbalancePrices'
  /** Price for underproduction (deficit) in JPY/kWh */
  def?: Maybe<Scalars['Float']['output']>
  /** Price for overproduction (surplus) in JPY/kWh */
  sur?: Maybe<Scalars['Float']['output']>
}

export type ImbalanceSeries = {
  __typename?: 'ImbalanceSeries'
  set: Array<ImbalanceSeriesSet>
  updatedAt: Scalars['Time']['output']
}

export type ImbalanceSeriesSet = {
  __typename?: 'ImbalanceSeriesSet'
  /** Time of the imbalance */
  t: Scalars['Time']['output']
  /** Imbalance volume */
  v: ImbalancePriceVolumes
}

export type InflationList = {
  __typename?: 'InflationList'
  pageInfo: PaginationInfo
  records: Array<ScenarioInflation>
}

export type InflationMutations = {
  __typename?: 'InflationMutations'
  create: CreateInflationPayload
  delete: DeleteInflationPayload
  deleteAndReplace: DeleteInflationPayload
  update: UpdateInflationPayload
}

export type InflationMutationsCreateArgs = {
  input: CreateInflationInput
}

export type InflationMutationsDeleteArgs = {
  input: DeleteAssumptionInput
}

export type InflationMutationsDeleteAndReplaceArgs = {
  input: DeleteAndReplaceAssumptionInput
}

export type InflationMutationsUpdateArgs = {
  input: UpdateInflationInput
}

export type IntradaySeries = {
  __typename?: 'IntradaySeries'
  set: Array<IntradaySeriesSet>
  updatedAt: Scalars['Time']['output']
}

export type IntradaySeriesSet = {
  __typename?: 'IntradaySeriesSet'
  t: Scalars['Time']['output']
  v: IntradayVolumes
}

export type IntradayVolumes = {
  __typename?: 'IntradayVolumes'
  /** Average price in JPY/kWh */
  ap?: Maybe<Scalars['Float']['output']>
  /** Closing price in JPY/kWh */
  cp?: Maybe<Scalars['Float']['output']>
  /** High price in JPY/kWh */
  hp?: Maybe<Scalars['Float']['output']>
  /** Low price in JPY/kWh */
  lp?: Maybe<Scalars['Float']['output']>
  /** Opening price in JPY/kWh */
  op?: Maybe<Scalars['Float']['output']>
  /** Volume in kWh */
  vol?: Maybe<Scalars['Float']['output']>
}

export type InvalidInvitationProblem = ProblemInterface & {
  __typename?: 'InvalidInvitationProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
}

export type Invitation = {
  __typename?: 'Invitation'
  createdAt: Scalars['Time']['output']
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  redirectUrl?: Maybe<Scalars['String']['output']>
  status: InvitationStatus
  type: InvitationType
  updatedAt: Scalars['Time']['output']
  used: Scalars['Boolean']['output']
  username?: Maybe<Scalars['String']['output']>
  workspaceName?: Maybe<Scalars['String']['output']>
}

export enum InvitationErrorCode {
  AwsIssue = 'AWS_ISSUE',
  DbIssue = 'DB_ISSUE',
  EmailAlreadyAMember = 'EMAIL_ALREADY_A_MEMBER',
  EmailInvitedRecently = 'EMAIL_INVITED_RECENTLY',
  NoDisposableEmail = 'NO_DISPOSABLE_EMAIL',
  SendgridIssue = 'SENDGRID_ISSUE',
  SendRateLimitReached = 'SEND_RATE_LIMIT_REACHED',
  ServerError = 'SERVER_ERROR',
}

export type InvitationInput = {
  email: Scalars['String']['input']
  id: Scalars['String']['input']
}

export enum InvitationStatus {
  Invalid = 'INVALID',
  Valid = 'VALID',
}

export enum InvitationType {
  CreateWorkspace = 'CREATE_WORKSPACE',
  JoinWorkspace = 'JOIN_WORKSPACE',
}

export type IrrBenchmarkIndicator = {
  __typename?: 'IrrBenchmarkIndicator'
  calculation?: Maybe<BenchmarkIndicator>
  error?: Maybe<IrrCalculationError>
}

export enum IrrCalculationError {
  ExcessiveNegativeCashFlow = 'EXCESSIVE_NEGATIVE_CASH_FLOW',
  ExcessivePositiveCashFlow = 'EXCESSIVE_POSITIVE_CASH_FLOW',
  NoNegativeCashFlow = 'NO_NEGATIVE_CASH_FLOW',
  NoPositiveCashFlow = 'NO_POSITIVE_CASH_FLOW',
  OutOfBoundaries = 'OUT_OF_BOUNDARIES',
}

export enum JepxTradeBidType {
  Custom = 'CUSTOM',
  Planned = 'PLANNED',
}

export type JepxTradeDetails = {
  __typename?: 'JepxTradeDetails'
  /** Type of bid when the position was opened */
  bidType?: Maybe<JepxTradeBidType>
  /** Bid/ask price to trade energy in Japanese Yen (JPY) */
  price: Scalars['Float']['output']
  /** Time slot of the JEPX trade */
  timeSlot: Scalars['Time']['output']
  /** Bid/ask volume of energy to trade in kilowatt hour (kWh) */
  volume: Scalars['Float']['output']
}

export type JepxTradeDetailsInput = {
  /** Bid/ask price to trade energy in Japanese Yen (JPY) */
  price: Scalars['Float']['input']
  /** Bid/ask volume of energy to trade in kilowatt hour (kWh) */
  volume: Scalars['Float']['input']
}

export enum JepxTradeStatus {
  /** Trades have been confirmed */
  Confirmed = 'CONFIRMED',
  /** No trades have been made */
  None = 'NONE',
  /** Open positions have been established */
  Open = 'OPEN',
}

export type JepxTrades = {
  __typename?: 'JepxTrades'
  /** Date the trades are associated with */
  date: Scalars['Time']['output']
  /** Total volume of energy in kilowatt hour (kWh) */
  totalVolume: Scalars['Float']['output']
  /** JEPX trading plan for a given day */
  trades: Array<JepxTradeDetails>
  /** Time when trades were last updated at */
  updatedAt?: Maybe<Scalars['Time']['output']>
}

export type JepxTrading = {
  __typename?: 'JepxTrading'
  /** Date of the JEPX trade */
  date: Scalars['Time']['output']
  /** Time the generation plan was last generated */
  generatedAt?: Maybe<Scalars['Time']['output']>
  /** Summed generation plan associated for the date */
  generationPlan?: Maybe<GenerationPlan>
  /** Indicates if a generation plan exists for the associated date */
  hasPlan: Scalars['Boolean']['output']
  /**
   * ID of the JEPX trade.
   * Note: This ID will be generated by using the date and workspace id in the
   * following format {date}:{workspaceId}
   */
  id: Scalars['String']['output']
  /**
   * All trading zones that are available for JEPX trading.
   * Only zones that have valid generation plans will be provided values.
   */
  tradingZones: JepxTradingZones
}

export type JepxTradingHistory = {
  __typename?: 'JepxTradingHistory'
  /** Actor who did the action */
  actor: Actor
  /**
   * The bid type of the JEPX position. If the position is confirmed, it indicates
   * what type of bid was originally confirmed.
   */
  bidType: JepxTradeBidType
  /** Time the trade was made at */
  createdAt: Scalars['Time']['output']
  /** Date the trade was intended for */
  date: Scalars['Time']['output']
  /** Grid zone the trade was for */
  gridZone: GridZone
  /** Unique, non-human readable UUID */
  id: Scalars['ID']['output']
  /** Status of the JEPX trade */
  status: JepxTradingHistoryStatus
  /** Total volume of energy traded in kilowatt hour (kWh) */
  totalVolume: Scalars['Float']['output']
}

export type JepxTradingHistoryFilter = {
  /** Filter history by creation datetime using a start/from timestamp. */
  from?: InputMaybe<Scalars['Time']['input']>
}

export type JepxTradingHistoryInput = {
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export enum JepxTradingHistoryStatus {
  Confirmed = 'CONFIRMED',
  Open = 'OPEN',
}

export type JepxTradingInput = {
  date: Scalars['Time']['input']
  workspaceId: Scalars['ID']['input']
}

export type JepxTradingMutations = {
  __typename?: 'JepxTradingMutations'
  confirmTrade: ConfirmJepxTradePayload
  openCustomPosition: OpenCustomJepxPositionPayload
  openPlannedPosition: OpenPlannedJepxPositionPayload
}

export type JepxTradingMutationsConfirmTradeArgs = {
  input: ConfirmJepxTradeInput
}

export type JepxTradingMutationsOpenCustomPositionArgs = {
  input: OpenCustomJepxPositionInput
}

export type JepxTradingMutationsOpenPlannedPositionArgs = {
  input: OpenPlannedJepxPositionInput
}

export type JepxTradingZone = {
  __typename?: 'JepxTradingZone'
  /** Current confirmed positions in the trade zone */
  confirmedTrades?: Maybe<JepxTrades>
  /** Electricity Generation plan */
  generationPlan: GenerationPlan
  /** Flag that indicates the zone has price actuals */
  hasPriceActuals: Scalars['Boolean']['output']
  /** Current open positions in the trade zone */
  openPositions?: Maybe<JepxTrades>
  /** Price actuals for the zone */
  priceActuals: Array<NilableSingleSeriesSet>
  /** Price forecasts for the zone */
  priceForecast: Array<NilableSingleSeriesSet>
  /** The current status of the trade zone. */
  status: JepxTradeStatus
}

export type JepxTradingZones = {
  __typename?: 'JepxTradingZones'
  /** Chubu trading zone */
  chubu?: Maybe<JepxTradingZone>
  /** Chugoku trading zone */
  chugoku?: Maybe<JepxTradingZone>
  /** Hokkaido trading zone */
  hokkaido?: Maybe<JepxTradingZone>
  /** Hokuriku trading zone */
  hokuriku?: Maybe<JepxTradingZone>
  /** Kansai trading zone */
  kansai?: Maybe<JepxTradingZone>
  /** Kyushu trading zone */
  kyushu?: Maybe<JepxTradingZone>
  /** Shikoku trading zone */
  shikoku?: Maybe<JepxTradingZone>
  /** Tohoku trading zone */
  tohoku?: Maybe<JepxTradingZone>
  /** Tokyo trading zone */
  tokyo?: Maybe<JepxTradingZone>
}

/** Currently supports English and Japanese */
export enum Language {
  En = 'EN',
  Jp = 'JP',
}

export type LoginWithMagicLinkProblem = ProblemInterface & {
  __typename?: 'LoginWithMagicLinkProblem'
  /**
   * [internal] A short message of what occured.
   * Intended for internal use, so it is recommended that maching occurs
   * agains the __typename and not the message.
   */
  message: Scalars['String']['output']
}

export type MacroScenario = {
  __typename?: 'MacroScenario'
  /** The time the scenario was created at */
  createdAt: Scalars['Time']['output']
  /** Curtailment scenario assumption */
  curtailment: ScenarioCurtailment
  /** User defined description for the scenario */
  description: Scalars['String']['output']
  /** Forecast MAPE Dayahead assumption */
  forecastMapeDayAhead: ScenarioForecastMapeDayAhead
  /** Forecast MAPE Intraday assumption */
  forecastMapeIntraday: ScenarioForecastMapeIntraday
  /** A unique, non-human readable id */
  id: Scalars['ID']['output']
  /** Inflation scenario assumption */
  inflation: ScenarioInflation
  /** Is the scenario the tensor default? */
  isDefault: Scalars['Boolean']['output']
  /** User defined name for the scenario */
  name: Scalars['String']['output']
  /** [Internal] The uuid of the organization/workspace the scenario belongs to */
  organizationId: Scalars['ID']['output']
  /** Average market price scenario assumption */
  priceAverage: ScenarioPriceAverage
  /** Market price volatility scenario assumption */
  priceVolatility: ScenarioPriceVolatility
  /** A persistent, sequential human readable id */
  readableId: Scalars['String']['output']
  /** The time the scenario was last updated */
  updatedAt: Scalars['Time']['output']
}

export type MacroScenarioAssumptionCurtailments = {
  __typename?: 'MacroScenarioAssumptionCurtailments'
  chubu: SingleSeries
  chugoku: SingleSeries
  hokkaido: SingleSeries
  hokuriku: SingleSeries
  kansai: SingleSeries
  kyushu: SingleSeries
  okinawa: SingleSeries
  shikoku: SingleSeries
  tohoku: SingleSeries
  tokyo: SingleSeries
}

export type MacroScenarioAssumptionPrices = {
  __typename?: 'MacroScenarioAssumptionPrices'
  chubu: SingleSeries
  chugoku: SingleSeries
  hokkaido: SingleSeries
  hokuriku: SingleSeries
  kansai: SingleSeries
  kyushu: SingleSeries
  okinawa: SingleSeries
  shikoku: SingleSeries
  system: SingleSeries
  tohoku: SingleSeries
  tokyo: SingleSeries
}

export type MacroScenarioReportScope = {
  __typename?: 'MacroScenarioReportScope'
  averageSystemPriceYearlySeries: MacroScenarioAssumptionPrices
  curtailmentYearlySeries: MacroScenarioAssumptionCurtailments
  forecastErrorDayAheadYearlySeries: SingleSeries
  forecastErrorIntradayYearlySeries: SingleSeries
  inflationRateYearlySeries: SingleSeries
  meanAveragePriceDeviationYearlySeries: MacroScenarioAssumptionPrices
  records: Array<ReportMacroScenario>
}

export type MacroScenarioReportScopeAverageSystemPriceYearlySeriesArgs = {
  input: MacroScenarioSeriesInput
}

export type MacroScenarioReportScopeCurtailmentYearlySeriesArgs = {
  input: MacroScenarioSeriesInput
}

export type MacroScenarioReportScopeForecastErrorDayAheadYearlySeriesArgs = {
  input: MacroScenarioSeriesInput
}

export type MacroScenarioReportScopeForecastErrorIntradayYearlySeriesArgs = {
  input: MacroScenarioSeriesInput
}

export type MacroScenarioReportScopeInflationRateYearlySeriesArgs = {
  input: MacroScenarioSeriesInput
}

export type MacroScenarioReportScopeMeanAveragePriceDeviationYearlySeriesArgs = {
  input: MacroScenarioSeriesInput
}

export type MacroScenarioSeriesInput = {
  id: Scalars['ID']['input']
}

export type MarkAllNotificationsAsReadInput = {
  /** Mark all notifications as read up to this date and time. */
  markAsUnreadUntil: Scalars['Time']['input']
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type MarkAllNotificationsAsReadPayload = {
  __typename?: 'MarkAllNotificationsAsReadPayload'
  errors?: Maybe<Array<MarkAllNotificationsAsReadProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type MarkAllNotificationsAsReadProblems = AuthorizationProblem

export type MarkNotificationAsReadData = {
  __typename?: 'MarkNotificationAsReadData'
  /** Notification record that was changed */
  record: Notification
  /** ID of the notification */
  recordId: Scalars['ID']['output']
}

export type MarkNotificationAsReadInput = {
  /** ID of the notification */
  id: Scalars['ID']['input']
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type MarkNotificationAsReadPayload = {
  __typename?: 'MarkNotificationAsReadPayload'
  data: MarkNotificationAsReadData
  errors?: Maybe<Array<MarkNotificationAsReadProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type MarkNotificationAsReadProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type MarkNotificationAsUnreadData = {
  __typename?: 'MarkNotificationAsUnreadData'
  /** Notification record that was changed */
  record: Notification
  /** ID of the notification */
  recordId: Scalars['ID']['output']
}

export type MarkNotificationAsUnreadInput = {
  /** ID of the notification */
  id: Scalars['ID']['input']
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type MarkNotificationAsUnreadPayload = {
  __typename?: 'MarkNotificationAsUnreadPayload'
  data: MarkNotificationAsUnreadData
  errors?: Maybe<Array<MarkNotificationAsUnreadProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type MarkNotificationAsUnreadProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type MarketIntelligence = {
  __typename?: 'MarketIntelligence'
  certificates?: Maybe<MarketIntelligenceCertificates>
  dayAhead?: Maybe<MarketIntelligenceDayAhead>
  electricityConsumption?: Maybe<MarketIntelligenceElectricityConsumption>
  electricityGeneration?: Maybe<MarketIntelligenceElectricityGeneration>
  imbalance?: Maybe<MarketIntelligenceImbalance>
  intraday?: Maybe<MarketIntelligenceIntraday>
  priceForecast?: Maybe<MarketIntelligencePriceForecast>
}

export type MarketIntelligenceCertificates = {
  __typename?: 'MarketIntelligenceCertificates'
  nfcPriceVolumeSeries: NfcSeries
}

export type MarketIntelligenceCertificatesNfcPriceVolumeSeriesArgs = {
  input?: InputMaybe<MarketIntelligenceSeriesInput>
}

export enum MarketIntelligenceChartEntity {
  Certificates = 'CERTIFICATES',
  Dayahead = 'DAYAHEAD',
  ElectricityConsumption = 'ELECTRICITY_CONSUMPTION',
  ElectricityGeneration = 'ELECTRICITY_GENERATION',
  Imbalance = 'IMBALANCE',
  Intraday = 'INTRADAY',
  PriceForecast = 'PRICE_FORECAST',
}

export type MarketIntelligenceDayAhead = {
  __typename?: 'MarketIntelligenceDayAhead'
  dayAheadPriceVolumeSeries: DayAheadSeries
  maxDate?: Maybe<Scalars['Time']['output']>
  minDate?: Maybe<Scalars['Time']['output']>
  /** Day-ahead prices by zone. Values are represented in JPY/kWh */
  zonePriceSeries: HighPerformanceNullableNumberSeries
}

export type MarketIntelligenceDayAheadDayAheadPriceVolumeSeriesArgs = {
  input: MarketIntelligenceSeriesInput
}

export type MarketIntelligenceDayAheadZonePriceSeriesArgs = {
  input: MarketIntelligenceDayAheadZonePriceSeriesInput
}

export type MarketIntelligenceDayAheadZonePriceSeriesInput = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
  zone: GridZone
}

export type MarketIntelligenceElectricityConsumption = {
  __typename?: 'MarketIntelligenceElectricityConsumption'
  electricityConsumptionSeries: ElectricityConsumptionSeries
  maxDates: ZoneDates
  minDates: ZoneDates
}

export type MarketIntelligenceElectricityConsumptionElectricityConsumptionSeriesArgs = {
  input?: InputMaybe<MarketIntelligenceSeriesInput>
}

export type MarketIntelligenceElectricityGeneration = {
  __typename?: 'MarketIntelligenceElectricityGeneration'
  electricityGenerationSeries: ElectricityGenerationSeries
  maxDates: ZoneDates
  minDates: ZoneDates
}

export type MarketIntelligenceElectricityGenerationElectricityGenerationSeriesArgs = {
  input?: InputMaybe<MarketIntelligenceSeriesInput>
}

export type MarketIntelligenceImbalance = {
  __typename?: 'MarketIntelligenceImbalance'
  imbalanceVolumesSeries: ImbalanceSeries
  maxDate?: Maybe<Scalars['Time']['output']>
  minDate?: Maybe<Scalars['Time']['output']>
  /** Imbalance prices by zone. Values are represented in JPY/kWh */
  zonePriceSeries: HighPerformanceNullableNumberSeries
}

export type MarketIntelligenceImbalanceImbalanceVolumesSeriesArgs = {
  input?: InputMaybe<MarketIntelligenceSeriesInput>
}

export type MarketIntelligenceImbalanceZonePriceSeriesArgs = {
  input: MarketIntelligenceImbalanceZonePriceSeriesInput
}

export type MarketIntelligenceImbalanceZonePriceSeriesInput = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
  zone: GridZone
}

export type MarketIntelligenceIntraday = {
  __typename?: 'MarketIntelligenceIntraday'
  maxDate?: Maybe<Scalars['Time']['output']>
  minDate?: Maybe<Scalars['Time']['output']>
  priceVolumeSeries: IntradaySeries
}

export type MarketIntelligenceIntradayPriceVolumeSeriesArgs = {
  input: MarketIntelligenceSeriesInput
}

export type MarketIntelligencePriceForecast = {
  __typename?: 'MarketIntelligencePriceForecast'
  electricityMarketPriceForecastSeries: PriceForecastSeries
  maxDate?: Maybe<Scalars['Time']['output']>
  minDate?: Maybe<Scalars['Time']['output']>
  /** Day-ahead prices by zone. Values are represented in JPY/kWh */
  zoneDayAheadPriceSeries: HighPerformanceNullableNumberSeries
  /** Imbalance prices by zone. Values are represented in JPY/kWh */
  zoneImbalancePriceSeries: HighPerformanceNullableNumberSeries
}

export type MarketIntelligencePriceForecastElectricityMarketPriceForecastSeriesArgs = {
  input: MarketIntelligenceSeriesInput
}

export type MarketIntelligencePriceForecastZoneDayAheadPriceSeriesArgs = {
  input: MarketIntelligenceDayAheadZonePriceSeriesInput
}

export type MarketIntelligencePriceForecastZoneImbalancePriceSeriesArgs = {
  input: MarketIntelligenceImbalanceZonePriceSeriesInput
}

export enum MarketIntelligencePricingZone {
  Chubu = 'CHUBU',
  Chugoku = 'CHUGOKU',
  Hokkaido = 'HOKKAIDO',
  Hokuriku = 'HOKURIKU',
  Kansai = 'KANSAI',
  Kyushu = 'KYUSHU',
  Okinawa = 'OKINAWA',
  Shikoku = 'SHIKOKU',
  System = 'SYSTEM',
  Tohoku = 'TOHOKU',
  Tokyo = 'TOKYO',
}

export type MarketIntelligenceSeriesInput = {
  /** End date of the series */
  endDate: Scalars['Time']['input']
  /** Start date of the series */
  startDate: Scalars['Time']['input']
  /** Pre-made time range. This will set the startDate and endDate automatically. */
  timeRange?: InputMaybe<Scalars['String']['input']>
  /** Utility grid zone the series relates to */
  zone?: InputMaybe<MarketIntelligencePricingZone>
}

export type MissingDependentsProblem = ProblemInterface & {
  __typename?: 'MissingDependentsProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** ID of the resource */
  recordId: Scalars['ID']['output']
}

export type ModifySolarAssetPlanData = {
  __typename?: 'ModifySolarAssetPlanData'
  record: SolarAsset
  recordId: Scalars['ID']['output']
}

export type ModifySolarAssetPlanInput = {
  /** Date of plan to make modifications to */
  date: Scalars['Time']['input']
  /** ID of the solar asset */
  id: Scalars['ID']['input']
  /** Desired plan values. Array must include values for each 48 time slots */
  plan: Array<SolarAssetPlanInput>
  /** ID of the workspace the solar asset plan is associated with */
  workspaceId: Scalars['ID']['input']
}

export type ModifySolarAssetPlanPayload = {
  __typename?: 'ModifySolarAssetPlanPayload'
  data: ModifySolarAssetPlanData
  errors?: Maybe<Array<ModifySolarAssetPlanProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type ModifySolarAssetPlanProblems =
  | AuthorizationProblem
  | ResourceDoesNotExistProblem
  | SolarAssetPlanDoesNotMatchProblem

export enum ModuleType {
  MonoSi = 'MONO_SI',
  PolySi = 'POLY_SI',
  ThinFilm = 'THIN_FILM',
}

export enum Month {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER',
}

export enum MountType {
  Fixed = 'FIXED',
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Mutation namespace that contains actions for a single balancing group */
  balancingGroup: BalancingGroupMutations
  /** Mutation namespace that contains actions for a single baseline */
  baseline: BaselineMutations
  /** Mutation namespace that contains actions for many baselines */
  baselines: BaselinesMutations
  /** Mutation namespace that contains actions for electricity generation forecasts */
  forecast: ForecastMutations
  /** Mutation namespace that contains actions for jepx trading */
  jepxTrading: JepxTradingMutations
  /** Mutation namespace that contains actions for notifications */
  notification: NotificationMutations
  /** Mutation namespace that contains actions for Occto reporting */
  occtoReporting: OcctoReportingMutations
  /** Mutation namespace that contains actions for a single ppa */
  ppa: PpaMutations
  /** Mutation namespace that contains actions for one or more ppas */
  ppas: PpasMutations
  /** Mutation namespace that contains actions for a single report */
  report: ReportMutations
  /** Mutation namespace that contains actions for one or more reports */
  reports: ReportsMutations
  /** Mutation namespace that contains actions for a single scenario */
  scenario: ScenarioMutations
  /** Mutation namespace that contains actions for scenario assumption */
  scenarioAssumption: ScenarioAssumptionMutations
  /** Mutation namespace that contains actions for one or more scenarios */
  scenarios: ScenariosMutations
  /** Mutation to send invitations to the platform (to be replaced with v2) */
  sendInvitations: SendInvitationsOutput
  /** Mutation to send invitations to the platform */
  sendInvitationsV2: SendInvitationsOutput
  /** Mutation namespace that contains actions for single solar asset */
  solarAsset: SolarAssetMutations
  /** Mutation namespace that contains actions for one or more solar assets */
  solarAssets: SolarAssetsMutations
  /** Mutation namespace that contains actions for a single spv */
  spv: SpvMutations
  /** Mutation namespace that contains actions for one or more third party companies */
  thirdPartyCompanies: ThirdPartyCompaniesMutations
  /** Mutation namespace that contains actions for a single third party company */
  thirdPartyCompany: ThirdPartyCompanyMutations
  /** Mutation namespace that contains actions for a workspace user */
  user: UserMutations
  /** Mutation namespace that contains actions for user account */
  userAccount: UserAccountMutations
  /** Mutation namespace that contains actions for a single workspace */
  workspace: WorkspaceMutations
}

export type MutationSendInvitationsArgs = {
  input: SendInvitationsInput
}

export type MutationSendInvitationsV2Args = {
  input: SendInvitationsInput
}

export type NfcSeries = {
  __typename?: 'NfcSeries'
  fit: Array<NfcSeriesSet>
  nfn: Array<NfcSeriesSet>
  nfr: Array<NfcSeriesSet>
  updatedAt: Scalars['Time']['output']
}

export type NfcSeriesSet = {
  __typename?: 'NfcSeriesSet'
  t: Scalars['Time']['output']
  v: NfcVolumes
}

export type NfcVolumes = {
  __typename?: 'NfcVolumes'
  /** Average price in JPY/kWh */
  avg?: Maybe<Scalars['Float']['output']>
  /** High price in JPY/kWh */
  hi?: Maybe<Scalars['Float']['output']>
  /** Low price in JPY/kWh */
  lo?: Maybe<Scalars['Float']['output']>
  /** Traded volume in kWh */
  vol?: Maybe<Scalars['Float']['output']>
}

export type NilableSingleSeriesSet = {
  __typename?: 'NilableSingleSeriesSet'
  t: Scalars['Time']['output']
  v?: Maybe<Scalars['Float']['output']>
}

export enum NodeType {
  ExpandableNode = 'EXPANDABLE_NODE',
  Leaf = 'LEAF',
  Node = 'NODE',
}

export type NotWithinTimeFrameProblem = ProblemInterface & {
  __typename?: 'NotWithinTimeFrameProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
}

export type Notification = {
  /** Category of the notification */
  category: NotificationCategory
  /** The date and time the notification was created */
  createdAt: Scalars['Time']['output']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** Indicates the current status of the notification */
  status: NotificationStatus
  /** The date and time the notification was last updated */
  updatedAt: Scalars['Time']['output']
}

export enum NotificationCategory {
  Activity = 'ACTIVITY',
  Alert = 'ALERT',
  Reminder = 'REMINDER',
}

export type NotificationJepxOpenedPosition = Notification & {
  __typename?: 'NotificationJepxOpenedPosition'
  /** The actor that triggered the notification */
  actor: Actor
  /** Category of the notification */
  category: NotificationCategory
  /** The date and time the notification was created */
  createdAt: Scalars['Time']['output']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** The metadata for the notification */
  metadata: NotificationJepxOpenedPositionMetadata
  /** Indicates the current status of the notification */
  status: NotificationStatus
  /** The date and time the notification was last updated */
  updatedAt: Scalars['Time']['output']
}

export type NotificationJepxOpenedPositionMetadata = {
  __typename?: 'NotificationJepxOpenedPositionMetadata'
  /** The date and time the trade position was opened for */
  date: Scalars['Time']['output']
  /** The grid zone the trade position was open in */
  gridZone: GridZone
}

export type NotificationJepxTradeConfirmationReminder = Notification & {
  __typename?: 'NotificationJepxTradeConfirmationReminder'
  /** Category of the notification */
  category: NotificationCategory
  /** The date and time the notification was created */
  createdAt: Scalars['Time']['output']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** The metadata for the notification */
  metadata: NotificationJepxTradeConfirmationReminderMetadata
  /** Indicates the current status of the notification */
  status: NotificationStatus
  /** The date and time the notification was last updated */
  updatedAt: Scalars['Time']['output']
}

export type NotificationJepxTradeConfirmationReminderMetadata = {
  __typename?: 'NotificationJepxTradeConfirmationReminderMetadata'
  /** The date and time the trade confirmation reminder is for */
  date: Scalars['Time']['output']
  /** The grid zone the trade confirmation reminder is for */
  gridZone: GridZone
}

export type NotificationJepxTradeConfirmed = Notification & {
  __typename?: 'NotificationJepxTradeConfirmed'
  /** The actor that triggered the notification */
  actor: Actor
  /** Category of the notification */
  category: NotificationCategory
  /** The date and time the notification was created */
  createdAt: Scalars['Time']['output']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** The metadata for the notification */
  metadata: NotificationJepxTradeConfirmedMetadata
  /** Indicates the current status of the notification */
  status: NotificationStatus
  /** The date and time the notification was last updated */
  updatedAt: Scalars['Time']['output']
}

export type NotificationJepxTradeConfirmedMetadata = {
  __typename?: 'NotificationJepxTradeConfirmedMetadata'
  /** The date and time the trade was confirmed for */
  date: Scalars['Time']['output']
  /** The grid zone the trade was confirmed in */
  gridZone: GridZone
}

export type NotificationJepxTradingReminder = Notification & {
  __typename?: 'NotificationJepxTradingReminder'
  /** Category of the notification */
  category: NotificationCategory
  /** The date and time the notification was created */
  createdAt: Scalars['Time']['output']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** The metadata for the notification */
  metadata: NotificationJepxTradingReminderMetadata
  /** Indicates the current status of the notification */
  status: NotificationStatus
  /** The date and time the notification was last updated */
  updatedAt: Scalars['Time']['output']
}

export type NotificationJepxTradingReminderMetadata = {
  __typename?: 'NotificationJepxTradingReminderMetadata'
  /** The date and time the trade reminder is for */
  date: Scalars['Time']['output']
  /** The grid zone the trade reminder is intended for */
  gridZone: GridZone
}

export type NotificationList = {
  __typename?: 'NotificationList'
  /** The list of notification */
  records: Array<Notification>
  /** The total number of unread notifications. Field is not affected by any filters */
  unreadCount: Scalars['Int']['output']
}

export type NotificationListFilter = {
  /** Filter history by creation datetime using a start/from timestamp. */
  from?: InputMaybe<Scalars['Time']['input']>
  /** Filter history by creation datetime using a end/to timestamp */
  to?: InputMaybe<Scalars['Time']['input']>
}

export type NotificationListInput = {
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type NotificationMutations = {
  __typename?: 'NotificationMutations'
  markAllAsRead: MarkAllNotificationsAsReadPayload
  markAsRead: MarkNotificationAsReadPayload
  markAsUnread: MarkNotificationAsUnreadPayload
}

export type NotificationMutationsMarkAllAsReadArgs = {
  input: MarkAllNotificationsAsReadInput
}

export type NotificationMutationsMarkAsReadArgs = {
  input: MarkNotificationAsReadInput
}

export type NotificationMutationsMarkAsUnreadArgs = {
  input: MarkNotificationAsUnreadInput
}

export enum NotificationStatus {
  Read = 'READ',
  Unread = 'UNREAD',
}

export type NotificationTsoPlanSubmissionReminder = Notification & {
  __typename?: 'NotificationTSOPlanSubmissionReminder'
  /** Category of the notification */
  category: NotificationCategory
  /** The date and time the notification was created */
  createdAt: Scalars['Time']['output']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** The metadata for the notification */
  metadata: NotificationTsoPlanSubmissionReminderMetadata
  /** Boolean that indicates if the notification has been read */
  status: NotificationStatus
  /** The date and time the notification was last updated */
  updatedAt: Scalars['Time']['output']
}

export type NotificationTsoPlanSubmissionReminderMetadata = {
  __typename?: 'NotificationTSOPlanSubmissionReminderMetadata'
  /** The date and time the TSO plan submission reminder is for */
  date: Scalars['Time']['output']
  /** The grid zone the TSO plan submission reminder is for */
  gridZone: GridZone
}

export type NotificationTsoPlanSubmitted = Notification & {
  __typename?: 'NotificationTSOPlanSubmitted'
  /** The actor that triggered the notification */
  actor: Actor
  /** Category of the notification */
  category: NotificationCategory
  /** The date and time the notification was created */
  createdAt: Scalars['Time']['output']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** The metadata for the notification */
  metadata: NotificationTsoPlanSubmittedMetadata
  /** Indicates the current status of the notification */
  status: NotificationStatus
  /** The date and time the notification was last updated */
  updatedAt: Scalars['Time']['output']
}

export type NotificationTsoPlanSubmittedMetadata = {
  __typename?: 'NotificationTSOPlanSubmittedMetadata'
  /** The date and time the TSO plan was submitted for */
  date: Scalars['Time']['output']
  /** The grid zone the TSO plan was submitted in */
  gridZone: GridZone
}

export type OcctoDayAheadPlan = {
  __typename?: 'OcctoDayAheadPlan'
  /** Date of OCCTO report */
  date: Scalars['Time']['output']
  /** Indicates if a generation plan exists for the associated date */
  hasPlan: Scalars['Boolean']['output']
  /**
   * ID of the JEPX trade.
   * Note: This ID will be generated by using the date and workspace id in the
   * following format {date}:{workspaceId}
   */
  id: Scalars['String']['output']
  /** Time when the plan was last updated at */
  updatedAt?: Maybe<Scalars['Time']['output']>
  /** OCCTO reporting zones */
  zones: OcctoReportingZones
}

export type OcctoDayAheadPlanInput = {
  /** Date of the desired occto reporting */
  date: Scalars['Time']['input']
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export enum OcctoGenerationPlanGenerationMethod {
  Dynamic = 'DYNAMIC',
  GenerationPlanEqualSalePlan = 'GENERATION_PLAN_EQUAL_SALE_PLAN',
}

export enum OcctoLongTermPlanType {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  WeeklyUpdate = 'WEEKLY_UPDATE',
  Yearly = 'YEARLY',
}

/** Details regarding a specific time slot in an OCCTO monthly generation plan */
export type OcctoMonthlyPlanTimeSlot = {
  __typename?: 'OcctoMonthlyPlanTimeSlot'
  /** Date and time of the plan slot */
  datetime: Scalars['Time']['output']
  /** Flag that indicates if the datetime is considered a weekend or not */
  isWeekend: Scalars['Boolean']['output']
  /** The week number within the month. This will always be a value between 1-5 since there are no more than 5 total weeks in a month. */
  weekNumberInMonth: Scalars['Int']['output']
}

export type OcctoReportingMutations = {
  __typename?: 'OcctoReportingMutations'
  /** Generates a URL where the created long-term OCCTO plan can be downloaded from */
  generateLongTermPlanUrl: GenerateOcctoLongTermPlanUrlPayload
  /** Generate Occto plan url */
  generatePlanUrl: GenerateOcctoPlanUrlPayload
  /** Submits Occto plan */
  submitPlan: SubmitOcctoPlanPayload
  /** Unsubmit Occto plan */
  unsubmitPlan: UnsubmitOcctoPlanPayload
}

export type OcctoReportingMutationsGenerateLongTermPlanUrlArgs = {
  input: GenerateOcctoLongTermPlanUrlInput
}

export type OcctoReportingMutationsGeneratePlanUrlArgs = {
  input: GenerateOcctoPlanUrlInput
}

export type OcctoReportingMutationsSubmitPlanArgs = {
  input: SubmitOcctoPlanInput
}

export type OcctoReportingMutationsUnsubmitPlanArgs = {
  input: UnsubmitOcctoPlanInput
}

export type OcctoReportingZone = {
  __typename?: 'OcctoReportingZone'
  /** Balancing groups within the grid zone. Only includes balancing groups that have OCCTO-qualified assets */
  balancingGroups: Array<BalancingGroup>
  /** Date of OCCTO report */
  date: Scalars['Time']['output']
  /** Electricity plan for the grid zone */
  generationPlan: OcctoReportingZonePlan
  /** Procurement plan for the grid zone */
  procurementPlan: OcctoReportingZonePlan
  /** Sales plan for the grid zone */
  salesPlan: OcctoReportingZonePlan
  /** Status of grid zone. Indicates if the OCCTO plan was submitted */
  status: OcctoReportingZoneStatus
  /** Number of reportable assets in the grid zone */
  totalAssets: Scalars['Int']['output']
}

export type OcctoReportingZonePlan = {
  __typename?: 'OcctoReportingZonePlan'
  /** Plan series for the grid zone in kilowatt hour (kWh). Should be 48 time slots for the day. */
  plan: SingleSeries
  /** Total volume of energy in kilowatt hour (kWh) */
  totalVolume: Scalars['Float']['output']
}

export enum OcctoReportingZoneStatus {
  NotSubmitted = 'NOT_SUBMITTED',
  Submitted = 'SUBMITTED',
}

export type OcctoReportingZones = {
  __typename?: 'OcctoReportingZones'
  /** Chubu reporting zone */
  chubu?: Maybe<OcctoReportingZone>
  /** Chugoku reporting zone */
  chugoku?: Maybe<OcctoReportingZone>
  /** Hokkaido reporting zone */
  hokkaido?: Maybe<OcctoReportingZone>
  /** Hokuriku reporting zone */
  hokuriku?: Maybe<OcctoReportingZone>
  /** Kansai reporting zone */
  kansai?: Maybe<OcctoReportingZone>
  /** Kyushu reporting zone */
  kyushu?: Maybe<OcctoReportingZone>
  /** Shikoku reporting zone */
  shikoku?: Maybe<OcctoReportingZone>
  /** Tohoku reporting zone */
  tohoku?: Maybe<OcctoReportingZone>
  /** Tokyo reporting zone */
  tokyo?: Maybe<OcctoReportingZone>
}

export type OcctoSubmitterData = {
  __typename?: 'OcctoSubmitterData'
  /** The submitter ID or code */
  code?: Maybe<Scalars['String']['output']>
  /** The name/title that corresponds to the ID or code */
  name?: Maybe<Scalars['String']['output']>
}

export type OcctoSubmitterDataInput = {
  /** The submitter ID or code */
  code?: InputMaybe<Scalars['String']['input']>
  /** The name/title that corresponds to the ID or code */
  name?: InputMaybe<Scalars['String']['input']>
}

export type OcctoSubmitterGridZones = {
  __typename?: 'OcctoSubmitterGridZones'
  /** Submitter details for Chubu grid zone */
  chubu: OcctoSubmitterData
  /** Submitter details for Chugoku grid zone */
  chugoku: OcctoSubmitterData
  /** Submitter details for Hokkaido grid zone */
  hokkaido: OcctoSubmitterData
  /** Submitter details for Hokuriku grid zone */
  hokuriku: OcctoSubmitterData
  /** Submitter details for Kansai grid zone */
  kansai: OcctoSubmitterData
  /** Submitter details for KyushuChubu grid zone */
  kyushu: OcctoSubmitterData
  /** Submitter details for Okinawa grid zone */
  okinawa: OcctoSubmitterData
  /** Submitter details for Shikoku grid zone */
  shikoku: OcctoSubmitterData
  /** Submitter details for Tohoku grid zone */
  tohoku: OcctoSubmitterData
  /** Submitter details for Tokyo grid zone */
  tokyo: OcctoSubmitterData
}

export type OcctoSubmitterGridZonesInput = {
  /** Submitter details for Chubu grid zone */
  chubu?: InputMaybe<OcctoSubmitterDataInput>
  /** Submitter details for Chugoku grid zone */
  chugoku?: InputMaybe<OcctoSubmitterDataInput>
  /** Submitter details for Hokkaido grid zone */
  hokkaido?: InputMaybe<OcctoSubmitterDataInput>
  /** Submitter details for Hokuriku grid zone */
  hokuriku?: InputMaybe<OcctoSubmitterDataInput>
  /** Submitter details for Kansai grid zone */
  kansai?: InputMaybe<OcctoSubmitterDataInput>
  /** Submitter details for Kyushu grid zone */
  kyushu?: InputMaybe<OcctoSubmitterDataInput>
  /** Submitter details for Okinawa grid zone */
  okinawa?: InputMaybe<OcctoSubmitterDataInput>
  /** Submitter details for Shikoku grid zone */
  shikoku?: InputMaybe<OcctoSubmitterDataInput>
  /** Submitter details for Tohoku grid zone */
  tohoku?: InputMaybe<OcctoSubmitterDataInput>
  /** Submitter details for Tokyo grid zone */
  tokyo?: InputMaybe<OcctoSubmitterDataInput>
}

export type OpenCustomJepxPositionData = {
  __typename?: 'OpenCustomJepxPositionData'
  record: JepxTrades
  recordId: Scalars['ID']['output']
}

export type OpenCustomJepxPositionInput = {
  /** Date to open a position on */
  date: Scalars['Time']['input']
  /** The grid zone to open a position in */
  gridZone: GridZone
  /** Trade positions to open */
  trades: Array<JepxTradeDetailsInput>
  /** ID of the workspace the trade is associated with */
  workspaceId: Scalars['ID']['input']
}

export type OpenCustomJepxPositionPayload = {
  __typename?: 'OpenCustomJepxPositionPayload'
  data?: Maybe<OpenCustomJepxPositionData>
  errors?: Maybe<Array<OpenCustomJepxPositionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type OpenCustomJepxPositionProblems =
  | AuthorizationProblem
  | NotWithinTimeFrameProblem
  | ResourceDoesNotExistProblem

export type OpenPlannedJepxPositionData = {
  __typename?: 'OpenPlannedJepxPositionData'
  record: JepxTrades
  recordId: Scalars['ID']['output']
}

export type OpenPlannedJepxPositionInput = {
  /** Date to open a position on */
  date: Scalars['Time']['input']
  /** The grid zone to open a position in */
  gridZone: GridZone
  /** ID of the workspace the trade is associated with */
  workspaceId: Scalars['ID']['input']
}

export type OpenPlannedJepxPositionPayload = {
  __typename?: 'OpenPlannedJepxPositionPayload'
  data?: Maybe<OpenPlannedJepxPositionData>
  errors?: Maybe<Array<OpenPlannedJepxPositionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type OpenPlannedJepxPositionProblems =
  | AuthorizationProblem
  | NotWithinTimeFrameProblem
  | ResourceDoesNotExistProblem

export type OpexCostSet = {
  __typename?: 'OpexCostSet'
  /** OPEX cost value in JPY */
  value: Scalars['Float']['output']
  /** Year for which OPEX cost value is valid */
  year: Scalars['Int']['output']
}

export type OpexCostSetInput = {
  /** OPEX cost value in JPY */
  value: Scalars['Float']['input']
  /** Year for which OPEX cost value is valid */
  year: Scalars['Int']['input']
}

export type Organization = {
  __typename?: 'Organization'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PvModuleGeneric = {
  __typename?: 'PVModuleGeneric'
  /** Mounting azimuth in degrees. Only used for fixed tilt systems. */
  azimuth: Scalars['Float']['output']
  /** A unique, non-human readable id */
  id: Scalars['ID']['output']
  /** Solar module model */
  moduleModel?: Maybe<Scalars['String']['output']>
  /** Parameters of the module */
  moduleParameters: PvModuleGenericParams
  /** Solar module type. Can be monocrystalline, multicrystalline, or thin film. */
  moduleType: ModuleType
  /** Number of solar modules per string */
  modulesPerString?: Maybe<Scalars['Int']['output']>
  /** Mounting type. Can be fixed tilt, single axis tracking, or dual axis tracking. */
  mountType: MountType
  /** Number of strings per inverter */
  strings?: Maybe<Scalars['Int']['output']>
  /** Mounting tilt in degrees. Only used for fixed tilt systems. */
  tilt: Scalars['Float']['output']
}

export type PvModuleGenericParams = {
  __typename?: 'PVModuleGenericParams'
  /** The temperature coefficient in units of 1/C. Typically -0.002 to -0.005 per degree C. Will replace temperatureLosses as we move to custom panels. */
  gammaPdc?: Maybe<Scalars['Float']['output']>
  /** Nameplate DC rating */
  pdc0: Scalars['Float']['output']
}

export type PageInfo = {
  __typename?: 'PageInfo'
  /** The ending cursor of the selected edges */
  endCursor: Scalars['String']['output']
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** The starting cursor of the selected edges */
  startCursor: Scalars['String']['output']
}

export type PaginationInfo = {
  __typename?: 'PaginationInfo'
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** Is the collection we are requesting data from empty, independently of our filters? */
  isEmpty: Scalars['Boolean']['output']
  /** Current page number */
  page: Scalars['Int']['output']
  /** Number of items per page */
  perPage: Scalars['Int']['output']
  /** Total number of pages */
  totalPages: Scalars['Int']['output']
  /** Total number of items */
  totalRecords: Scalars['Int']['output']
}

export type PpaFilter = {
  /** PPA status */
  status?: InputMaybe<PpaStatus>
  /** Filter text string */
  text?: InputMaybe<Scalars['String']['input']>
}

export type PpaFilterVariables = {
  __typename?: 'PpaFilterVariables'
  status?: Maybe<PpaStatus>
  text?: Maybe<Scalars['String']['output']>
}

export type PpaList = {
  __typename?: 'PpaList'
  pageInfo: PaginationInfo
  queryVariables: PpaListQueryVariables
  records: Array<PpaX>
}

export type PpaListInput = {
  organizationId: Scalars['ID']['input']
  page?: Scalars['Int']['input']
  perPage?: Scalars['Int']['input']
}

export type PpaListInputVariables = {
  __typename?: 'PpaListInputVariables'
  organizationId: Scalars['ID']['output']
  page: Scalars['Int']['output']
  perPage: Scalars['Int']['output']
}

export type PpaListQueryVariables = {
  __typename?: 'PpaListQueryVariables'
  filter?: Maybe<PpaFilterVariables>
  input: PpaListInputVariables
  sort?: Maybe<PpaSort>
}

export type PpaMutations = {
  __typename?: 'PpaMutations'
  addAssets: AddAssetsToPpaPayload
  create: CreatePpaPayload
  delete: DeletePpaPayload
  removeAssets: RemoveAssetsFromPpaPayload
  update: UpdatePpaPayload
}

export type PpaMutationsAddAssetsArgs = {
  input: AddAssetsToPpaInput
}

export type PpaMutationsCreateArgs = {
  input: CreatePpaXInput
}

export type PpaMutationsDeleteArgs = {
  input: DeletePpaXInput
}

export type PpaMutationsRemoveAssetsArgs = {
  input: RemoveAssetsFromPpaInput
}

export type PpaMutationsUpdateArgs = {
  input: UpdatePpaXInput
}

export type PpaPriceSchemeSet = {
  __typename?: 'PpaPriceSchemeSet'
  /** Value of the ppa price scheme */
  value: Scalars['Float']['output']
  /** Year for which ppa price scheme cost value is valid */
  year: Scalars['Int']['output']
}

export type PpaPriceSchemeSetInput = {
  /** Value of the ppa price scheme */
  value: Scalars['Float']['input']
  /** Year for which ppa price scheme cost value is valid */
  year: Scalars['Int']['input']
}

export type PpaReportScope = {
  __typename?: 'PpaReportScope'
  keyIndicators: PpaReportScopeKeyIndicators
  records: Array<ReportPpa>
  total: Scalars['Int']['output']
}

export type PpaReportScopeKeyIndicatorsArgs = {
  input: ReportScopeBaseInput
}

export type PpaReportScopeRecordsArgs = {
  input: ReportScopeBaseInput
  sort?: InputMaybe<PpaSort>
}

export type PpaReportScopeKeyIndicators = {
  __typename?: 'PpaReportScopeKeyIndicators'
  total: Scalars['Int']['output']
}

export enum PpaSort {
  CapacityAsc = 'CAPACITY_ASC',
  CapacityDesc = 'CAPACITY_DESC',
  DateAddedAsc = 'DATE_ADDED_ASC',
  DateAddedDesc = 'DATE_ADDED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastEditAsc = 'LAST_EDIT_ASC',
  LastEditDesc = 'LAST_EDIT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OfftakerAsc = 'OFFTAKER_ASC',
  OfftakerDesc = 'OFFTAKER_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TermAsc = 'TERM_ASC',
  TermDesc = 'TERM_DESC',
  TotalAssetsAsc = 'TOTAL_ASSETS_ASC',
  TotalAssetsDesc = 'TOTAL_ASSETS_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
}

export enum PpaStatus {
  Draft = 'DRAFT',
  Signed = 'SIGNED',
  Started = 'STARTED',
}

export enum PpaType {
  OffSite = 'OFF_SITE',
  OnSite = 'ON_SITE',
  SelfWheeling = 'SELF_WHEELING',
  Vppa = 'VPPA',
}

export type PpaVolumes = {
  __typename?: 'PpaVolumes'
  /** Total generation of all assets in the PPA in kWh */
  averageAnnualGeneration: Scalars['Float']['output']
  /** Total AC capacity of all assets in the PPA in W */
  totalAcCapacity: Scalars['Float']['output']
  /** Total DC capacity of all assets in the PPA in Wp */
  totalDcCapacity: Scalars['Float']['output']
}

export type PpaX = {
  __typename?: 'PpaX'
  /** Interval over which prices are averaged to calculate at the settlement price in minutes */
  calculationInterval: Scalars['Int']['output']
  /** Cap price for PPAs with collar pricing schemes in JPY/kWh */
  capPrice?: Maybe<Array<PpaPriceSchemeSet>>
  /** Total DC capacity of all assets in the PPA in W */
  capacity: Scalars['Float']['output']
  /** Date and time the PPA was created */
  createdAt: Scalars['Time']['output']
  /** Whether the PPA has been deleted */
  deleted: Scalars['Boolean']['output']
  /** How many percent over or under the settlement price the PPA should be settled for. For PPAs with discount pricing scheme only */
  discountToMarket?: Maybe<Array<PpaPriceSchemeSet>>
  /** Specifies whether IPP or offtaker (electricity buyer) is the beneficiary of FIT or FIP payments. Currently, setting this to offtaker simply removes the FIP/FIT cash flow to zero. */
  fipBeneficiary: FipBeneficiary
  /** Shared of asset capacity under fixed pricing scheme in PPA in percent */
  fixedShare: Scalars['Float']['output']
  /** Shared of asset capacity under floating pricing scheme in PPA in percent */
  floatingShare: Scalars['Float']['output']
  /** Lower price limit for PPAs with floating pricing scheme in JPY/kWh */
  floorPrice: Array<PpaPriceSchemeSet>
  /** A unique, non-human readable id */
  id: Scalars['ID']['output']
  /** Flag that indicates if the PPA is making use of a flexible pricing scheme */
  isFlexiblePricingScheme: Scalars['Boolean']['output']
  /** Name of the PPA */
  name: Scalars['String']['output']
  /** Negative pricing floor in JPY that specifies how the PPA mechanics behave if the settlement price is negative. */
  negativePriceFloor: Scalars['Float']['output']
  /** Offtaker if the PPA is of type VPPA or Off-site */
  offtaker?: Maybe<ThirdPartyCompany>
  organizationId: Scalars['ID']['output']
  /** Price escalation rate for PPAs with fixed escalation pricing scheme in percent */
  priceEscalationRate: Scalars['Float']['output']
  /** Pricing scheme of the PPA */
  pricingScheme: PricingScheme
  /** A persistent, sequential human readable id */
  readableId: Scalars['String']['output']
  /** Market from which prices the settlement price is derived. Can be set to Day-ahead or Intraday. */
  settlementMarket: SettlementMarket
  /** Only available for Day-ahead settlement market. Pricing zone from which prices the settlement price is derived. Can be set to any of the 10 pricing zones in Japan. */
  settlementPricingZone: SettlementPricingZone
  /** List of solar assets in the PPA */
  solarAssets: Array<SolarAsset>
  /** Start date of the PPA */
  startDate: Scalars['Time']['output']
  /** Status of the PPA */
  status: PpaStatus
  /** Strike price for PPAs with fixed pricing scheme in JPY/kWh */
  strikePrice?: Maybe<Array<PpaPriceSchemeSet>>
  /** Term of the PPA in years */
  term: Scalars['Int']['output']
  /** Number of total assets included in PPA */
  totalAssets: Scalars['Int']['output']
  /** Type of the PPA */
  type: PpaType
  /** Datetime when PPA was last updated */
  updatedAt: Scalars['Time']['output']
  /** User who last updated the PPA */
  userUpdatedAt: Scalars['Time']['output']
  /** Total AC, DC and generation volumes for all assets in PPA */
  volumes: PpaVolumes
}

export type PpasMutations = {
  __typename?: 'PpasMutations'
  deleteMany: DeleteManyPpasPayload
}

export type PpasMutationsDeleteManyArgs = {
  input: DeleteManyPpasInput
}

export type PriceAverageList = {
  __typename?: 'PriceAverageList'
  pageInfo: PaginationInfo
  records: Array<ScenarioPriceAverage>
}

export type PriceAverageMutations = {
  __typename?: 'PriceAverageMutations'
  create: CreatePriceAveragePayload
  delete: DeletePriceAveragePayload
  deleteAndReplace: DeletePriceAveragePayload
  update: UpdatePriceAveragePayload
}

export type PriceAverageMutationsCreateArgs = {
  input: CreatePriceAverageInput
}

export type PriceAverageMutationsDeleteArgs = {
  input: DeleteAssumptionInput
}

export type PriceAverageMutationsDeleteAndReplaceArgs = {
  input: DeleteAndReplaceAssumptionInput
}

export type PriceAverageMutationsUpdateArgs = {
  input: UpdatePriceAverageInput
}

export type PriceForecastSeries = {
  __typename?: 'PriceForecastSeries'
  set: Array<PriceForecastSeriesSet>
}

export type PriceForecastSeriesSet = {
  __typename?: 'PriceForecastSeriesSet'
  t: Scalars['Time']['output']
  /** Price forecast value in JPY/kWh */
  v: ZonePrices
}

export type PriceVolatilityList = {
  __typename?: 'PriceVolatilityList'
  pageInfo: PaginationInfo
  records: Array<ScenarioPriceVolatility>
}

export type PriceVolatilityMutations = {
  __typename?: 'PriceVolatilityMutations'
  create: CreatePriceVolatilityPayload
  delete: DeletePriceVolatilityPayload
  deleteAndReplace: DeletePriceVolatilityPayload
  update: UpdatePriceVolatilityPayload
}

export type PriceVolatilityMutationsCreateArgs = {
  input: CreatePriceVolatilityInput
}

export type PriceVolatilityMutationsDeleteArgs = {
  input: DeleteAssumptionInput
}

export type PriceVolatilityMutationsDeleteAndReplaceArgs = {
  input: DeleteAndReplaceAssumptionInput
}

export type PriceVolatilityMutationsUpdateArgs = {
  input: UpdatePriceVolatilityInput
}

export enum PricingScheme {
  Clawback = 'CLAWBACK',
  Collar = 'COLLAR',
  DiscountCollar = 'DISCOUNT_COLLAR',
  DiscountFloor = 'DISCOUNT_FLOOR',
  Fixed = 'FIXED',
  FixedEscalation = 'FIXED_ESCALATION',
  FixedInflationEscalation = 'FIXED_INFLATION_ESCALATION',
  HybridAsset = 'HYBRID_ASSET',
  HybridGeneration = 'HYBRID_GENERATION',
  ReverseCollar = 'REVERSE_COLLAR',
}

export type ProblemInterface = {
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
}

export type ProfilePicture = {
  __typename?: 'ProfilePicture'
  /** The default profile picture url */
  default: Scalars['String']['output']
  /** A small version of the profile picture */
  small: Scalars['String']['output']
}

export type PvModuleInput = {
  /** Mounting azimuth in degrees. Only used for fixed tilt systems. */
  azimuth: Scalars['Float']['input']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['input']
  /** Parameters of the PV module */
  moduleParameters: PvModuleParametersInput
  /** Solar module type. Can be monocrystalline, multicrystalline, or thin film. */
  moduleType: ModuleType
  /** Mounting type. Can be fixed tilt, single axis tracking, or dual axis tracking. */
  mountType: MountType
  /** Mounting tilt in degrees. Only used for fixed tilt systems. */
  tilt: Scalars['Float']['input']
}

export type PvModuleParametersInput = {
  /** The temperature coefficient in units of 1/C. Typically -0.002 to -0.005 per degree C. Will replace temperatureLosses as we move to custom panels. */
  gammaPdc: Scalars['Float']['input']
  /** Nameplate DC rating */
  pdc0: Scalars['Float']['input']
}

export type Query = {
  __typename?: 'Query'
  /** Fetches a single balancing group */
  balancingGroup: BalancingGroup
  /** Fetches a offset/limit paginated list of balancing groups */
  balancingGroupList: BalancingGroupList
  /** Fetches a non-paginated list of balancing groups */
  balancingGroups: Array<BalancingGroup>
  /** Fetches a single baseline */
  baseline: Baseline
  /** Fetches a list of non-paginated baselines */
  baselines: Array<Baseline>
  /** Fetches the current user account */
  currentUserAccount?: Maybe<UserAccount>
  /** Fetches a custom electricity generation forecast */
  customForecast: CustomElectricityGenerationForecast
  /** Fetches a non-paginated list of electricity generation forecasts */
  forecasts: ElectricityGenerationForecasts
  /**
   * Fetches a non-paginated lists of all previously uploaded electricity generation actuals
   * @deprecated No longer supported. Use solarAssetActualsUploads instead.
   */
  generationUploads: Array<File>
  /** Fetches an invitation */
  invitation?: Maybe<Invitation>
  /** Fetches JEPX trades */
  jepxTrading: JepxTrading
  /** Fetches JEPX trading history */
  jepxTradingHistory: Array<JepxTradingHistory>
  /** Query namespace that contains queries for market intelligence data series */
  marketIntelligence: MarketIntelligence
  /** Fetches notifications for the current user account and a specific workspace */
  notifications: NotificationList
  /** Fetches Occto day ahead plans */
  occtoDayAheadPlan: OcctoDayAheadPlan
  /** Fetches a single ppa */
  ppa?: Maybe<PpaX>
  /** Fetches a offset/limit paginated list of ppas */
  ppaList: PpaList
  /** Fetches a single report */
  report?: Maybe<Report>
  /** Fetches a offset/limit paginated list of reports */
  reportList: ReportList
  /** Fetches a single scenario */
  scenario?: Maybe<MacroScenario>
  /** Namespace for scenario assumption queries */
  scenarioAssumption: ScenarioAssumptionQuery
  /** Namespace for scenario assumptions queries */
  scenarioAssumptions: ScenarioAssumptionsQuery
  /** Fetches a offset/limit paginated list of scenarios */
  scenarioList: ScenarioList
  /** Fetches a single solar asset */
  solarAsset: SolarAsset
  /** Fetches a non-paginated list of all previously uploaded solar asset actuals */
  solarAssetActualsUploads: Array<File>
  /** Fetches a offset/limit paginated list of solar assets */
  solarAssetList: SolarAssetList
  /** Fetches a single spv */
  spv: Spv
  /** Fetches a offset/limit paginated list of spvs */
  spvList: SpvList
  /** Fetches all third party companies */
  thirdPartyCompanyList: ThirdPartyCompanyList
  /** Validates an invitation */
  validateInvitation?: Maybe<Invitation>
  /** Fetches a single workspace */
  workspace?: Maybe<Workspace>
}

export type QueryBalancingGroupArgs = {
  input: QueryEntityInput
}

export type QueryBalancingGroupListArgs = {
  filter?: InputMaybe<BalancingGroupFilter>
  input: BalancingGroupListInput
  sort?: InputMaybe<BalancingGroupSort>
}

export type QueryBalancingGroupsArgs = {
  filter?: InputMaybe<BalancingGroupFilter>
  workspaceId: Scalars['ID']['input']
}

export type QueryBaselineArgs = {
  input: QueryEntityInput
}

export type QueryBaselinesArgs = {
  limit?: Scalars['Int']['input']
  workspaceId: Scalars['ID']['input']
}

export type QueryCustomForecastArgs = {
  input: CustomElectricityGenerationForecastInput
}

export type QueryForecastsArgs = {
  filter?: InputMaybe<ElectricityGenerationForecastsFilter>
  input: ElectricityGenerationForecastsInput
}

export type QueryGenerationUploadsArgs = {
  input: GenerationUploadsInput
}

export type QueryInvitationArgs = {
  input: InvitationInput
}

export type QueryJepxTradingArgs = {
  input: JepxTradingInput
}

export type QueryJepxTradingHistoryArgs = {
  filter?: InputMaybe<JepxTradingHistoryFilter>
  input: JepxTradingHistoryInput
}

export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationListFilter>
  input: NotificationListInput
}

export type QueryOcctoDayAheadPlanArgs = {
  input: OcctoDayAheadPlanInput
}

export type QueryPpaArgs = {
  input: QueryEntityInput
}

export type QueryPpaListArgs = {
  filter?: InputMaybe<PpaFilter>
  input: PpaListInput
  sort?: InputMaybe<PpaSort>
}

export type QueryReportArgs = {
  input: QueryEntityInput
}

export type QueryReportListArgs = {
  filter?: InputMaybe<ReportFilter>
  input: ReportListInput
  sort?: InputMaybe<ReportSort>
}

export type QueryScenarioArgs = {
  input: QueryEntityInput
}

export type QueryScenarioListArgs = {
  filter?: InputMaybe<ScenarioFilter>
  input: ScenarioListInput
  sort?: InputMaybe<ScenarioSort>
}

export type QuerySolarAssetArgs = {
  input: SolarAssetInput
}

export type QuerySolarAssetActualsUploadsArgs = {
  input: SolarAssetActualsUploadsInput
}

export type QuerySolarAssetListArgs = {
  filter?: InputMaybe<SolarAssetFilter>
  input: SolarAssetListInput
  sort?: InputMaybe<SolarAssetSort>
}

export type QuerySpvArgs = {
  input: QueryEntityInput
}

export type QuerySpvListArgs = {
  filter?: InputMaybe<SpvFilter>
  input: SpvListInput
  sort?: InputMaybe<SpvSort>
}

export type QueryThirdPartyCompanyListArgs = {
  filter?: InputMaybe<ThirdPartyCompanyFilter>
  input: ThirdPartyCompanyListInput
  sort?: InputMaybe<ThirdPartyCompanySort>
}

export type QueryValidateInvitationArgs = {
  input: ValidateInvitationInput
}

export type QueryWorkspaceArgs = {
  input: Scalars['ID']['input']
}

export type QueryEntityInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type RegionalAssumptions = {
  __typename?: 'RegionalAssumptions'
  chubu: Array<AssumptionValues>
  chugoku: Array<AssumptionValues>
  hokkaido: Array<AssumptionValues>
  hokuriku: Array<AssumptionValues>
  kansai: Array<AssumptionValues>
  kyushu: Array<AssumptionValues>
  okinawa: Array<AssumptionValues>
  shikoku: Array<AssumptionValues>
  tohoku: Array<AssumptionValues>
  tokyo: Array<AssumptionValues>
}

export type RegionalAssumptionsInput = {
  chubu: Array<AssumptionValuesInput>
  chugoku: Array<AssumptionValuesInput>
  hokkaido: Array<AssumptionValuesInput>
  hokuriku: Array<AssumptionValuesInput>
  kansai: Array<AssumptionValuesInput>
  kyushu: Array<AssumptionValuesInput>
  okinawa: Array<AssumptionValuesInput>
  shikoku: Array<AssumptionValuesInput>
  tohoku: Array<AssumptionValuesInput>
  tokyo: Array<AssumptionValuesInput>
}

export type RegionalWithSystemAssumptions = {
  __typename?: 'RegionalWithSystemAssumptions'
  chubu: Array<AssumptionValues>
  chugoku: Array<AssumptionValues>
  hokkaido: Array<AssumptionValues>
  hokuriku: Array<AssumptionValues>
  kansai: Array<AssumptionValues>
  kyushu: Array<AssumptionValues>
  okinawa: Array<AssumptionValues>
  shikoku: Array<AssumptionValues>
  system: Array<AssumptionValues>
  tohoku: Array<AssumptionValues>
  tokyo: Array<AssumptionValues>
}

export type RegionalWithSystemAssumptionsInput = {
  chubu: Array<AssumptionValuesInput>
  chugoku: Array<AssumptionValuesInput>
  hokkaido: Array<AssumptionValuesInput>
  hokuriku: Array<AssumptionValuesInput>
  kansai: Array<AssumptionValuesInput>
  kyushu: Array<AssumptionValuesInput>
  okinawa: Array<AssumptionValuesInput>
  shikoku: Array<AssumptionValuesInput>
  system: Array<AssumptionValuesInput>
  tohoku: Array<AssumptionValuesInput>
  tokyo: Array<AssumptionValuesInput>
}

export type RemoveAssetsFromBalancingGroupData = {
  __typename?: 'RemoveAssetsFromBalancingGroupData'
  /** Record that assets were removed from */
  record: BalancingGroup
  /** ID of the record. Format = UUID */
  recordId: Scalars['ID']['output']
}

export type RemoveAssetsFromBalancingGroupInput = {
  /** IDs of the assets to remove from the balancing group. */
  assetIds: Array<Scalars['ID']['input']>
  /** ID of the balancing group to remove assets from. Format = UUID */
  id: Scalars['ID']['input']
  /** ID of the workspace the balancing group is associated with. Format = UUID */
  workspaceId: Scalars['ID']['input']
}

export type RemoveAssetsFromBalancingGroupPayload = {
  __typename?: 'RemoveAssetsFromBalancingGroupPayload'
  /** Return data payload from mutation */
  data?: Maybe<RemoveAssetsFromBalancingGroupData>
  /** Any errors that occurred during the mutation */
  errors?: Maybe<Array<RemoveAssetsFromBalancingGroupProblems>>
  /** Recursive query property that provides access to any query in the Query namespace */
  query?: Maybe<Query>
  /** Flag that indicates whether the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

/** All problems that can occur during removal of assets from a balancing groups */
export type RemoveAssetsFromBalancingGroupProblems =
  | AuthorizationProblem
  | ResourceDoesNotExistProblem

export type RemoveAssetsFromPpaData = {
  __typename?: 'RemoveAssetsFromPpaData'
  record: PpaX
  recordId: Scalars['ID']['output']
}

export type RemoveAssetsFromPpaInput = {
  /** IDs of the assets that should be removed from the ppa */
  assetIds: Array<Scalars['ID']['input']>
  /** ID of the ppa to add assets to */
  id: Scalars['ID']['input']
  /** ID of the workspace the ppa is associated with */
  workspaceId: Scalars['ID']['input']
}

export type RemoveAssetsFromPpaPayload = {
  __typename?: 'RemoveAssetsFromPpaPayload'
  data?: Maybe<RemoveAssetsFromPpaData>
  errors?: Maybe<Array<RemoveAssetsFromPpaProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type RemoveAssetsFromPpaProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type RemoveAssetsFromSpvData = {
  __typename?: 'RemoveAssetsFromSpvData'
  record: Spv
  recordId: Scalars['ID']['output']
}

export type RemoveAssetsFromSpvInput = {
  assetIds: Array<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type RemoveAssetsFromSpvPayload = {
  __typename?: 'RemoveAssetsFromSpvPayload'
  data?: Maybe<RemoveAssetsFromSpvData>
  errors?: Maybe<Array<RemoveAssetsFromSpvProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type RemoveAssetsFromSpvProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type RemoveProfilePictureData = {
  __typename?: 'RemoveProfilePictureData'
  /** User account record that was changed */
  record: UserAccount
  /** Id of the user account */
  recordId: Scalars['String']['output']
}

export type RemoveProfilePictureInput = {
  /** ID of the user */
  id: Scalars['ID']['input']
}

export type RemoveProfilePicturePayload = {
  __typename?: 'RemoveProfilePicturePayload'
  data: RemoveProfilePictureData
  errors?: Maybe<Array<RemoveProfilePictureProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type RemoveProfilePictureProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type RemoveUserInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  workspaceId?: InputMaybe<Scalars['ID']['input']>
}

export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload'
  data?: Maybe<Scalars['ID']['output']>
  errors?: Maybe<Array<RemoveUserProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type RemoveUserProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type RenameBalancingGroupData = {
  __typename?: 'RenameBalancingGroupData'
  /** Record that was renamed */
  record: BalancingGroup
  /** ID of the record. Format = UUID */
  recordId: Scalars['ID']['output']
}

export type RenameBalancingGroupInput = {
  /** ID of the balancing group to rename. Format = UUID */
  id: Scalars['ID']['input']
  /** Desired name for the balancing group */
  name: Scalars['String']['input']
  /** ID of the workspace the balancing group is associated with. Format = UUID */
  workspaceId: Scalars['ID']['input']
}

export type RenameBalancingGroupPayload = {
  __typename?: 'RenameBalancingGroupPayload'
  /** Returned data payload from mutation */
  data?: Maybe<RenameBalancingGroupData>
  /** Any errors that occurred during the mutation */
  errors?: Maybe<Array<RenameBalancingGroupProblems>>
  /** Recursive query property that provides access to any query in the Query namespace */
  query?: Maybe<Query>
  /** Flag that indicates whether the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

/** All problems that can occur when renaming a balancing group */
export type RenameBalancingGroupProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceDoesNotExistProblem

export type RenameReportData = {
  __typename?: 'RenameReportData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type RenameReportInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type RenameReportPayload = {
  __typename?: 'RenameReportPayload'
  data?: Maybe<RenameReportData>
  errors?: Maybe<Array<RenameReportProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type RenameReportProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceDoesNotExistProblem

export type RenameThirdPartyCompanyData = {
  __typename?: 'RenameThirdPartyCompanyData'
  record: ThirdPartyCompany
  recordId: Scalars['ID']['output']
}

export type RenameThirdPartyCompanyInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  workspaceId: Scalars['ID']['input']
}

export type RenameThirdPartyCompanyPayload = {
  __typename?: 'RenameThirdPartyCompanyPayload'
  data?: Maybe<RenameThirdPartyCompanyData>
  errors?: Maybe<Array<RenameThirdPartyCompanyProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type RenameThirdPartyCompanyProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceDoesNotExistProblem

export enum RepaymentFrequency {
  Biannually = 'BIANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY',
}

export type ReplacementDoesNotExistProblem = ProblemInterface & {
  __typename?: 'ReplacementDoesNotExistProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** ID of the resource */
  recordId: Scalars['ID']['output']
}

export type ReplacementRequiredProblem = ProblemInterface & {
  __typename?: 'ReplacementRequiredProblem'
  /** List of IDs of the dependents that require replacement */
  dependentIds?: Maybe<Array<Scalars['ID']['output']>>
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** ID of the resource */
  recordId: Scalars['ID']['output']
}

export type Report = {
  __typename?: 'Report'
  /** Time at when share access to this report expires. Null indicates that there is no expiration */
  accessExpiry?: Maybe<Scalars['Time']['output']>
  /** The creator of the report. */
  author: User
  /** The time the report was created at. */
  createdAt: Scalars['Time']['output']
  /** A unique, non-human readable id. */
  id: Scalars['ID']['output']
  /** Is the report archived? */
  isArchived: Scalars['Boolean']['output']
  /** Is the report set as a baseline? */
  isBaseline: Scalars['Boolean']['output']
  /** Is the report shareable with other users? */
  isShareable: Scalars['Boolean']['output']
  /** Is the report starred? */
  isStarred: Scalars['Boolean']['output']
  /** The name of the organization that the report is being issued by. */
  issuedBy?: Maybe<Scalars['String']['output']>
  /** The name of organization that the report is being issued for. */
  issuedFor?: Maybe<Scalars['String']['output']>
  /** The name of the report. */
  name: Scalars['String']['output']
  /** The organization that the report is created in. */
  organization: Organization
  /** "A persistent, sequential human readable id" */
  readableId: Scalars['String']['output']
  /** The scope of the report. The number of assets, ppas, spvs, and macroscenarios that are included in the report. */
  scope: ReportScope
  /** List of emails that this report has been shared with. */
  sharedWith: Array<Scalars['String']['output']>
  /**
   * The status of a report's simulation run. The simulation status can only be SUCCEEDED if
   * all assets within a report's scope has been successful.
   */
  simulationStatus: SimulationStatus
  /** The time the report was last updated at. */
  updatedAt: Scalars['Time']['output']
  /** The workspace the report is associated with */
  workspace: Workspace
}

export type ReportFilter = {
  category?: InputMaybe<ReportFilterCategory>
  text?: InputMaybe<Scalars['String']['input']>
}

export enum ReportFilterCategory {
  Archived = 'ARCHIVED',
  Baselines = 'BASELINES',
  Shared = 'SHARED',
  Starred = 'STARRED',
}

export type ReportFilterVariables = {
  __typename?: 'ReportFilterVariables'
  category?: Maybe<ReportFilterCategory>
  text?: Maybe<Scalars['String']['output']>
}

export type ReportList = {
  __typename?: 'ReportList'
  pageInfo: PaginationInfo
  queryVariables: ReportListQueryVariables
  records: Array<Report>
}

export type ReportListInput = {
  organizationId: Scalars['ID']['input']
  page?: Scalars['Int']['input']
  perPage?: Scalars['Int']['input']
}

export type ReportListInputVariables = {
  __typename?: 'ReportListInputVariables'
  organizationId: Scalars['ID']['output']
  page: Scalars['Int']['output']
  perPage: Scalars['Int']['output']
}

export type ReportListQueryVariables = {
  __typename?: 'ReportListQueryVariables'
  filter?: Maybe<ReportFilterVariables>
  input: ReportListInputVariables
  sort?: Maybe<ReportSort>
}

export type ReportMacroScenario = {
  __typename?: 'ReportMacroScenario'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  isDefault: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export type ReportMutations = {
  __typename?: 'ReportMutations'
  archive: ArchiveReportPayload
  create: CreateReportPayload
  delete: DeleteReportPayload
  rename: RenameReportPayload
  sendMagicLink: SendReportMagicLinkPayload
  setAccessExpiry: SetReportAccessExpiryPayload
  share: ShareReportPayload
  star: StarReportPayload
  unarchive: UnarchiveReportPayload
  unshare: UnshareReportPayload
  unstar: UnstarReportPayload
  updateLabels: UpdateReportLabelsPayload
}

export type ReportMutationsArchiveArgs = {
  input: ArchiveReportInput
}

export type ReportMutationsCreateArgs = {
  input: CreateReportInput
}

export type ReportMutationsDeleteArgs = {
  input: DeleteReportInput
}

export type ReportMutationsRenameArgs = {
  input: RenameReportInput
}

export type ReportMutationsSendMagicLinkArgs = {
  input: SendReportMagicLinkInput
}

export type ReportMutationsSetAccessExpiryArgs = {
  input: SetReportAccessExpiryInput
}

export type ReportMutationsShareArgs = {
  input: ShareReportInput
}

export type ReportMutationsStarArgs = {
  input: StarReportInput
}

export type ReportMutationsUnarchiveArgs = {
  input: UnarchiveReportInput
}

export type ReportMutationsUnshareArgs = {
  input: UnshareReportInput
}

export type ReportMutationsUnstarArgs = {
  input: UnstarReportInput
}

export type ReportMutationsUpdateLabelsArgs = {
  input: UpdateReportLabelsInput
}

export type ReportPpa = BasePpa & {
  __typename?: 'ReportPpa'
  /** Interval over which prices are averaged to calculate at the settlement price in minutes */
  calculationInterval: Scalars['Int']['output']
  /** Cap price for PPAs with collar pricing schemes in JPY/kWh */
  capPrice: Array<PpaPriceSchemeSet>
  /** Total DC capacity of all assets in the PPA in kW */
  capacity: Scalars['Float']['output']
  /** Date and time the PPA was created */
  createdAt: Scalars['Time']['output']
  /** Whether the PPA has been deleted */
  deleted: Scalars['Boolean']['output']
  /** How many percent over or under the settlement price the PPA should be settled for. For PPAs with discount pricing scheme only */
  discountToMarket: Array<PpaPriceSchemeSet>
  /** Specifies whether IPP or offtaker (electricity buyer) is the beneficiary of FIT or FIP payments. Currently, setting this to offtaker simply removes the FIP/FIT cash flow to zero. */
  fipBeneficiary: FipBeneficiary
  /** Shared of asset capacity under fixed pricing scheme in PPA in percent */
  fixedShare: Scalars['Float']['output']
  /** Shared of asset capacity under floating pricing scheme in PPA in percent */
  floatingShare: Scalars['Float']['output']
  /** Lower price limit for PPAs with floating pricing scheme in JPY/kWh */
  floorPrice: Array<PpaPriceSchemeSet>
  id: Scalars['ID']['output']
  /** Flag that indicates if the PPA is making use of a flexible pricing scheme */
  isFlexiblePricingScheme: Scalars['Boolean']['output']
  /** Name of the PPA */
  name: Scalars['String']['output']
  /** Negative pricing floor in JPY that specifies how the PPA mechanics behave if the settlement price is negative. */
  negativePriceFloor: Scalars['Float']['output']
  /** Offtaker if the PPA is of type VPPA or Off-site */
  offtaker?: Maybe<ThirdPartyCompany>
  organizationId: Scalars['ID']['output']
  /** Price escalation rate for PPAs with fixed escalation pricing scheme in percent */
  priceEscalationRate: Scalars['Float']['output']
  /** Pricing scheme of the PPA */
  pricingScheme: PricingScheme
  /** Market from which prices the settlement price is derived. Can be set to Day-ahead or Intraday. */
  settlementMarket: SettlementMarket
  /** Only available for Day-ahead settlement market. Pricing zone from which prices the settlement price is derived. Can be set to any of the 10 pricing zones in Japan. */
  settlementPricingZone: SettlementPricingZone
  /** List of solar assets in the PPA */
  solarAssets: Array<ReportSolarAsset>
  /** Start date of the PPA */
  startDate: Scalars['Time']['output']
  /** Status of the PPA */
  status: PpaStatus
  /** Strike price for PPAs with fixed pricing scheme in JPY/kWh */
  strikePrice: Array<PpaPriceSchemeSet>
  /** Term of the PPA in years */
  term: Scalars['Int']['output']
  /** Number of total assets included in PPA */
  totalAssets: Scalars['Int']['output']
  /** Type of the PPA */
  type: PpaType
  /** Datetime when PPA was last updated */
  updatedAt: Scalars['Time']['output']
  /** User who last updated the PPA */
  userUpdatedAt: Scalars['Time']['output']
  /** Total AC, DC and generation volumes for all assets in PPA */
  volumes: PpaVolumes
}

export type ReportScope = {
  __typename?: 'ReportScope'
  macroScenarios: MacroScenarioReportScope
  ppa: PpaReportScope
  solarAssets: SolarAssetReportScope
}

export type ReportScopeBaseInput = {
  ids: Array<Scalars['ID']['input']>
  organizationId: Scalars['ID']['input']
  type: ReportScopeFilterType
}

export enum ReportScopeFilterType {
  Asset = 'ASSET',
  Ppa = 'PPA',
}

export type ReportScopeInput = {
  macroScenarioIds: Array<Scalars['ID']['input']>
  solarAssetIds: Array<Scalars['ID']['input']>
}

export type ReportSimulationSummary = {
  __typename?: 'ReportSimulationSummary'
  assetManagementCostsMonthlySeries: SingleSeries
  assetManagementCostsYearlySeries: SingleSeries
  assetManagementLifetimeTotal: Scalars['Float']['output']
  capexLifetimeTotal: Scalars['Float']['output']
  capexMonthlySeries: SingleSeries
  capexYearlySeries: SingleSeries
  cumulativeNetCashflowMonthlySeries: SingleSeries
  cumulativeNetCashflowYearlySeries: SingleSeries
  curtailmentMonthlySeries: SingleSeries
  curtailmentYearlySeries: SingleSeries
  decommissionReserveCostsMonthlySeries: SingleSeries
  decommissionReserveCostsYearlySeries: SingleSeries
  decommissionReserveLifetimeTotal: Scalars['Float']['output']
  hardwareReplacementCostsMonthlySeries: SingleSeries
  hardwareReplacementCostsYearlySeries: SingleSeries
  hardwareReplacementLifetimeTotal: Scalars['Float']['output']
  insuranceCostsMonthlySeries: SingleSeries
  insuranceCostsYearlySeries: SingleSeries
  insuranceLifetimeTotal: Scalars['Float']['output']
  landLeaseCostsMonthlySeries: SingleSeries
  landLeaseCostsYearlySeries: SingleSeries
  landLeaseLifetimeTotal: Scalars['Float']['output']
  marketRevenueLifetimeTotal: Scalars['Float']['output']
  marketRevenueMonthlySeries: SingleSeries
  marketRevenueYearlySeries: SingleSeries
  maxDate?: Maybe<Scalars['Time']['output']>
  minDate?: Maybe<Scalars['Time']['output']>
  netCashflowLifetimeTotal: Scalars['Float']['output']
  netCashflowMonthlySeries: SingleSeries
  netCashflowYearlySeries: SingleSeries
  netGenerationLifetimeTotal: Scalars['Float']['output']
  netGenerationMonthlySeries: SingleSeries
  netGenerationYearlySeries: SingleSeries
  omCostsMonthlySeries: SingleSeries
  omCostsYearlySeries: SingleSeries
  omLifetimeTotal: Scalars['Float']['output']
  operatingCashflowMonthlySeries: SingleSeries
  operatingCashflowYearlySeries: SingleSeries
  opexLifetimeTotal: Scalars['Float']['output']
  opexTotalCostsMonthlySeries: SingleSeries
  opexTotalCostsYearlySeries: SingleSeries
  otherCostsMonthlySeries: SingleSeries
  otherCostsYearlySeries: SingleSeries
  otherLifetimeTotal: Scalars['Float']['output']
  ppaCashflowLifetimeTotal: Scalars['Float']['output']
  ppaCashflowMonthlySeries: SingleSeries
  ppaCashflowYearlySeries: SingleSeries
  subsidyRevenueLifetimeTotal: Scalars['Float']['output']
  subsidyRevenueMonthlySeries: SingleSeries
  subsidyRevenueYearlySeries: SingleSeries
  taxCostsMonthlySeries: SingleSeries
  taxCostsYearlySeries: SingleSeries
  taxLifetimeTotal: Scalars['Float']['output']
  totalGenerationMonthlySeries: SingleSeries
  totalGenerationYearlySeries: SingleSeries
  totalLifetimeRevenue: Scalars['Float']['output']
}

export type ReportSimulationSummaryMaxDateArgs = {
  timeRange: SeriesTimeRange
}

export type ReportSimulationSummaryMinDateArgs = {
  timeRange: SeriesTimeRange
}

export type ReportSolarAsset = BaseAsset & {
  __typename?: 'ReportSolarAsset'
  acCapacity: Scalars['Float']['output']
  dcCapacity: Scalars['Float']['output']
  gridZone: GridZone
  id: Scalars['ID']['output']
  irr?: Maybe<Scalars['Float']['output']>
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
  name: Scalars['String']['output']
  ppa?: Maybe<BasePpa>
  solarYieldFirstYear?: Maybe<Scalars['Float']['output']>
}

export enum ReportSort {
  AuthorAsc = 'AUTHOR_ASC',
  AuthorDesc = 'AUTHOR_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ScopeAsc = 'SCOPE_ASC',
  ScopeDesc = 'SCOPE_DESC',
}

export type ReportsMutations = {
  __typename?: 'ReportsMutations'
  deleteMany: DeleteManyReportsPayload
}

export type ReportsMutationsDeleteManyArgs = {
  input: DeleteManyReportsInput
}

export type RequestCognitoEmailUpdateData = {
  __typename?: 'RequestCognitoEmailUpdateData'
  email: Scalars['String']['output']
}

export type RequestCognitoEmailUpdateInput = {
  email: Scalars['String']['input']
}

export type RequestCognitoEmailUpdatePayload = {
  __typename?: 'RequestCognitoEmailUpdatePayload'
  data?: Maybe<RequestCognitoEmailUpdateData>
  errors?: Maybe<Array<RequestCognitoEmailUpdateProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type RequestCognitoEmailUpdateProblems = ProblemInterface & {
  __typename?: 'RequestCognitoEmailUpdateProblems'
  /**
   * [internal] A short message of what occured.
   * Intended for internal use, so it is recommended that maching occurs
   * agains the __typename and not the message.
   */
  message: Scalars['String']['output']
}

export type ResourceAlreadyExistsProblem = ProblemInterface & {
  __typename?: 'ResourceAlreadyExistsProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
}

export type ResourceCannotBeDeletedProblem = ProblemInterface & {
  __typename?: 'ResourceCannotBeDeletedProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** ID of the resource */
  recordId: Scalars['ID']['output']
}

export type ResourceCannotBeModifiedProblem = ProblemInterface & {
  __typename?: 'ResourceCannotBeModifiedProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** ID of the resource */
  recordId: Scalars['ID']['output']
}

export type ResourceDoesNotExistProblem = ProblemInterface & {
  __typename?: 'ResourceDoesNotExistProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** ID of the resource */
  recordId: Scalars['ID']['output']
}

export type ResourceIsAlreadyInUseProblem = ProblemInterface & {
  __typename?: 'ResourceIsAlreadyInUseProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** ID of the resource */
  recordId: Scalars['ID']['output']
}

export type ResourceIsAssignedProblem = ProblemInterface & {
  __typename?: 'ResourceIsAssignedProblem'
  /** ID to the entity that the resource is assigned to */
  assignedToId: Scalars['ID']['output']
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** ID of the resource */
  recordId: Scalars['ID']['output']
}

/** User roles on the platform */
export enum Role {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  User = 'USER',
}

export type SsProperty = {
  __typename?: 'SSProperty'
  p50?: Maybe<Scalars['Float']['output']>
  p70?: Maybe<Scalars['Float']['output']>
  p90?: Maybe<Scalars['Float']['output']>
}

export type ScenarioAssumption = {
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isDeletable: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  scenarios: Array<MacroScenario>
  updatedAt: Scalars['Time']['output']
}

export type ScenarioAssumptionMutations = {
  __typename?: 'ScenarioAssumptionMutations'
  curtailment: CurtailmentMutations
  forecastMapeDayAhead: ForecastMapeDayAheadMutations
  forecastMapeIntraday: ForecastMapeIntradayMutations
  inflation: InflationMutations
  priceAverage: PriceAverageMutations
  priceVolatility: PriceVolatilityMutations
}

export type ScenarioAssumptionQuery = {
  __typename?: 'ScenarioAssumptionQuery'
  curtailment: ScenarioCurtailment
  forecastMapeDayAhead: ScenarioForecastMapeDayAhead
  forecastMapeIntraday: ScenarioForecastMapeIntraday
  inflation: ScenarioInflation
  priceAverage: ScenarioPriceAverage
  priceVolatility: ScenarioPriceVolatility
}

export type ScenarioAssumptionQueryCurtailmentArgs = {
  input: QueryEntityInput
}

export type ScenarioAssumptionQueryForecastMapeDayAheadArgs = {
  input: QueryEntityInput
}

export type ScenarioAssumptionQueryForecastMapeIntradayArgs = {
  input: QueryEntityInput
}

export type ScenarioAssumptionQueryInflationArgs = {
  input: QueryEntityInput
}

export type ScenarioAssumptionQueryPriceAverageArgs = {
  input: QueryEntityInput
}

export type ScenarioAssumptionQueryPriceVolatilityArgs = {
  input: QueryEntityInput
}

export type ScenarioAssumptionsQuery = {
  __typename?: 'ScenarioAssumptionsQuery'
  curtailment: CurtailmentList
  forecastMapeDayAhead: ForecastMapeDayAheadList
  forecastMapeIntraday: ForecastMapeIntradayList
  inflation: InflationList
  priceAverage: PriceAverageList
  priceVolatility: PriceVolatilityList
}

export type ScenarioAssumptionsQueryCurtailmentArgs = {
  input: GetAssumptionListInput
}

export type ScenarioAssumptionsQueryForecastMapeDayAheadArgs = {
  input: GetAssumptionListInput
}

export type ScenarioAssumptionsQueryForecastMapeIntradayArgs = {
  input: GetAssumptionListInput
}

export type ScenarioAssumptionsQueryInflationArgs = {
  input: GetAssumptionListInput
}

export type ScenarioAssumptionsQueryPriceAverageArgs = {
  input: GetAssumptionListInput
}

export type ScenarioAssumptionsQueryPriceVolatilityArgs = {
  input: GetAssumptionListInput
}

export type ScenarioCurtailment = ScenarioAssumption & {
  __typename?: 'ScenarioCurtailment'
  assumptions: RegionalAssumptions
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isDeletable: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  scenarios: Array<MacroScenario>
  updatedAt: Scalars['Time']['output']
}

export type ScenarioFilter = {
  text?: InputMaybe<Scalars['String']['input']>
}

export type ScenarioFilterVariables = {
  __typename?: 'ScenarioFilterVariables'
  text?: Maybe<Scalars['String']['output']>
}

export type ScenarioForecastMapeDayAhead = ScenarioAssumption & {
  __typename?: 'ScenarioForecastMapeDayAhead'
  assumptions: Array<AssumptionValues>
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isDeletable: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  scenarios: Array<MacroScenario>
  updatedAt: Scalars['Time']['output']
}

export type ScenarioForecastMapeIntraday = ScenarioAssumption & {
  __typename?: 'ScenarioForecastMapeIntraday'
  assumptions: Array<AssumptionValues>
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isDeletable: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  scenarios: Array<MacroScenario>
  updatedAt: Scalars['Time']['output']
}

export type ScenarioInflation = ScenarioAssumption & {
  __typename?: 'ScenarioInflation'
  assumptions: Array<AssumptionValues>
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isDeletable: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  scenarios: Array<MacroScenario>
  updatedAt: Scalars['Time']['output']
}

export type ScenarioList = {
  __typename?: 'ScenarioList'
  pageInfo: PaginationInfo
  queryVariables: ScenarioListQueryVariables
  records: Array<MacroScenario>
}

export type ScenarioListInput = {
  organizationId: Scalars['ID']['input']
  page?: Scalars['Int']['input']
  perPage?: Scalars['Int']['input']
}

export type ScenarioListInputVariables = {
  __typename?: 'ScenarioListInputVariables'
  organizationId: Scalars['ID']['output']
  page: Scalars['Int']['output']
  perPage: Scalars['Int']['output']
}

export type ScenarioListQueryVariables = {
  __typename?: 'ScenarioListQueryVariables'
  filter?: Maybe<ScenarioFilterVariables>
  input: ScenarioListInputVariables
  sort?: Maybe<ScenarioSort>
}

export type ScenarioMutations = {
  __typename?: 'ScenarioMutations'
  create: CreateScenarioResult
  delete: DeleteScenarioPayload
  update: UpdateScenarioResult
}

export type ScenarioMutationsCreateArgs = {
  input: CreateScenarioInput
}

export type ScenarioMutationsDeleteArgs = {
  input: DeleteScenarioInput
}

export type ScenarioMutationsUpdateArgs = {
  input: UpdateScenarioInput
}

export type ScenarioPriceAverage = ScenarioAssumption & {
  __typename?: 'ScenarioPriceAverage'
  assumptions: RegionalWithSystemAssumptions
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isDeletable: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  scenarios: Array<MacroScenario>
  updatedAt: Scalars['Time']['output']
}

export type ScenarioPriceVolatility = ScenarioAssumption & {
  __typename?: 'ScenarioPriceVolatility'
  assumptions: RegionalWithSystemAssumptions
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isDeletable: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  scenarios: Array<MacroScenario>
  updatedAt: Scalars['Time']['output']
}

export enum ScenarioSort {
  DateAddedAsc = 'DATE_ADDED_ASC',
  DateAddedDesc = 'DATE_ADDED_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastEditAsc = 'LAST_EDIT_ASC',
  LastEditDesc = 'LAST_EDIT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type ScenariosMutations = {
  __typename?: 'ScenariosMutations'
  deleteMany: DeleteManyScenariosPayload
}

export type ScenariosMutationsDeleteManyArgs = {
  input: DeleteManyScenariosInput
}

export type SendLoginMagicLinkInput = {
  /** Email address to send the magic link to. */
  email: Scalars['String']['input']
  /** Invitation id if the user was invited to join/create a workspace. */
  invitationId?: InputMaybe<Scalars['ID']['input']>
  /** The language of the email to be sent. */
  language: Language
}

export type SendLoginMagicLinkPayload = {
  __typename?: 'SendLoginMagicLinkPayload'
  errors?: Maybe<Array<LoginWithMagicLinkProblem>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type SendReportMagicLinkInput = {
  email: Scalars['String']['input']
  id: Scalars['String']['input']
  language: Language
  workspaceId: Scalars['String']['input']
}

export type SendReportMagicLinkPayload = {
  __typename?: 'SendReportMagicLinkPayload'
  errors?: Maybe<Array<SendReportMagicLinkProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type SendReportMagicLinkProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type SeriesOptions = {
  filter: Filter
}

export enum SeriesTimeRange {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type SetReportAccessExpiryData = {
  __typename?: 'SetReportAccessExpiryData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type SetReportAccessExpiryInput = {
  accessExpiry?: InputMaybe<Scalars['Time']['input']>
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type SetReportAccessExpiryPayload = {
  __typename?: 'SetReportAccessExpiryPayload'
  data?: Maybe<SetReportAccessExpiryData>
  errors?: Maybe<Array<SetReportAccessExpiryProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type SetReportAccessExpiryProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export enum SettlementMarket {
  DayAhead = 'DAY_AHEAD',
  Intraday = 'INTRADAY',
}

export enum SettlementPricingZone {
  AssetGridArea = 'ASSET_GRID_AREA',
  Chubu = 'CHUBU',
  Chugoku = 'CHUGOKU',
  Hokkaido = 'HOKKAIDO',
  Hokuriku = 'HOKURIKU',
  Kansai = 'KANSAI',
  Kyushu = 'KYUSHU',
  Shikoku = 'SHIKOKU',
  System = 'SYSTEM',
  Tohoku = 'TOHOKU',
  Tokyo = 'TOKYO',
}

export type ShareReportData = {
  __typename?: 'ShareReportData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type ShareReportInput = {
  emails: Array<Scalars['String']['input']>
  id: Scalars['ID']['input']
  language: Language
  workspaceId: Scalars['ID']['input']
}

export type ShareReportPayload = {
  __typename?: 'ShareReportPayload'
  data?: Maybe<ShareReportData>
  errors?: Maybe<Array<ShareReportProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type ShareReportProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export enum SimulationStatus {
  Aborted = 'ABORTED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Runnable = 'RUNNABLE',
  Running = 'RUNNING',
  Starting = 'STARTING',
  Submitted = 'SUBMITTED',
  Succeeded = 'SUCCEEDED',
  TimedOut = 'TIMED_OUT',
}

export type SimulationSummary = {
  __typename?: 'SimulationSummary'
  assetManagementCostsMonthlySeries: SingleSeries
  assetManagementCostsYearlySeries: SingleSeries
  assetManagementLifetimeTotal: Scalars['Float']['output']
  /** Average revenues from FIT or FIP payouts in JPY per kWh of generated electricity over the system lifetime */
  averageSubsidyRevenue?: Maybe<Scalars['Float']['output']>
  capexLifetimeTotal: Scalars['Float']['output']
  capexMonthlySeries: SingleSeries
  capexYearlySeries: SingleSeries
  cumulativeNetCashflowMonthlySeries: SingleSeries
  cumulativeNetCashflowYearlySeries: SingleSeries
  curtailmentMonthlySeries: SingleSeries
  curtailmentYearlySeries: SingleSeries
  decommissionReserveCostsMonthlySeries: SingleSeries
  decommissionReserveCostsYearlySeries: SingleSeries
  decommissionReserveLifetimeTotal: Scalars['Float']['output']
  fip: SsProperty
  /** Average subsidy revenues if an asset had been operating under the FIT regime in JPY per kWh of generated electricity over the system lifetime */
  fitBenchmark: SsProperty
  hardwareReplacementCostsMonthlySeries: SingleSeries
  hardwareReplacementCostsYearlySeries: SingleSeries
  hardwareReplacementLifetimeTotal: Scalars['Float']['output']
  id: Scalars['ID']['output']
  imbalance: SsProperty
  insuranceCostsMonthlySeries: SingleSeries
  insuranceCostsYearlySeries: SingleSeries
  insuranceLifetimeTotal: Scalars['Float']['output']
  /** Internal rate of return, the primary measure of an asset's profitability */
  irr: SsProperty
  landLeaseCostsMonthlySeries: SingleSeries
  landLeaseCostsYearlySeries: SingleSeries
  landLeaseLifetimeTotal: Scalars['Float']['output']
  lifetimeCurtailmentLosses?: Maybe<Scalars['Float']['output']>
  lifetimeNetRevenue: SsProperty
  marketRevenueLifetimeTotal: Scalars['Float']['output']
  marketRevenueMonthlySeries: SingleSeries
  marketRevenueYearlySeries: SingleSeries
  maxDate?: Maybe<Scalars['Time']['output']>
  minDate?: Maybe<Scalars['Time']['output']>
  netCashflowLifetimeTotal: Scalars['Float']['output']
  netCashflowMonthlySeries: SingleSeries
  netCashflowYearlySeries: SingleSeries
  /** Electricity generated by the asset in kWh with curtailment already subtracted */
  netGeneration: SsProperty
  netGenerationMonthlySeries: SingleSeries
  netGenerationYearlySeries: SingleSeries
  netRevenue: SsProperty
  omCostsMonthlySeries: SingleSeries
  omCostsYearlySeries: SingleSeries
  omLifetimeTotal: Scalars['Float']['output']
  operatingCashflowMonthlySeries: SingleSeries
  operatingCashflowYearlySeries: SingleSeries
  opexLifetimeTotal: Scalars['Float']['output']
  opexTotalCostsMonthlySeries: SingleSeries
  opexTotalCostsYearlySeries: SingleSeries
  otherCostsMonthlySeries: SingleSeries
  otherCostsYearlySeries: SingleSeries
  otherLifetimeTotal: Scalars['Float']['output']
  ppaCashflowLifetimeTotal: Scalars['Float']['output']
  ppaCashflowMonthlySeries: SingleSeries
  ppaCashflowYearlySeries: SingleSeries
  ppaSettlements: SsProperty
  solarYieldDistributionFirstYear: Array<SingleSeriesSet>
  solarYieldFirstYear?: Maybe<Scalars['Float']['output']>
  subsidyRevenueLifetimeTotal: Scalars['Float']['output']
  subsidyRevenueMonthlySeries: SingleSeries
  subsidyRevenueYearlySeries: SingleSeries
  taxCostsMonthlySeries: SingleSeries
  taxCostsYearlySeries: SingleSeries
  taxLifetimeTotal: Scalars['Float']['output']
  temperatureLosses: SsProperty
  totalCurtailment: SsProperty
  totalGeneration: SsProperty
  totalGenerationMonthlySeries: SingleSeries
  totalGenerationYearlySeries: SingleSeries
  totalLifetimeRevenue: Scalars['Float']['output']
  valuationMonthlySeries: SingleSeries
  valuationYearlySeries: SingleSeries
  wholesaleRevenue: SsProperty
}

export type SimulationSummaryAssetManagementCostsMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryAssetManagementCostsYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryCapexMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryCapexYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryCumulativeNetCashflowMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryCumulativeNetCashflowYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryCurtailmentMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryCurtailmentYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryDecommissionReserveCostsMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryDecommissionReserveCostsYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryHardwareReplacementCostsMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryHardwareReplacementCostsYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryInsuranceCostsMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryInsuranceCostsYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryLandLeaseCostsMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryLandLeaseCostsYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryMarketRevenueMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryMarketRevenueYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryMaxDateArgs = {
  timeRange: SeriesTimeRange
}

export type SimulationSummaryMinDateArgs = {
  timeRange: SeriesTimeRange
}

export type SimulationSummaryNetCashflowMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryNetCashflowYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryNetGenerationMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryNetGenerationYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryOmCostsMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryOmCostsYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryOperatingCashflowMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryOperatingCashflowYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryOpexTotalCostsMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryOpexTotalCostsYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryOtherCostsMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryOtherCostsYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryPpaCashflowMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryPpaCashflowYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummarySubsidyRevenueMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummarySubsidyRevenueYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryTaxCostsMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryTaxCostsYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryTotalGenerationMonthlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryTotalGenerationYearlySeriesArgs = {
  input?: InputMaybe<SeriesOptions>
}

export type SimulationSummaryValuationMonthlySeriesArgs = {
  input: SimulationSummaryValuationInput
}

export type SimulationSummaryValuationYearlySeriesArgs = {
  input: SimulationSummaryValuationInput
}

export type SimulationSummaryValuationInput = {
  milestones: Array<SingleSeriesSetInput>
}

export type SingleSeries = {
  __typename?: 'SingleSeries'
  set: Array<SingleSeriesSet>
  updatedAt?: Maybe<Scalars['Time']['output']>
}

export type SingleSeriesSet = {
  __typename?: 'SingleSeriesSet'
  t: Scalars['Time']['output']
  v: Scalars['Float']['output']
}

export type SingleSeriesSetInput = {
  /** Time of the set */
  t: Scalars['Time']['input']
  /** Value of the set */
  v: Scalars['Float']['input']
}

export type SolarAsset = {
  __typename?: 'SolarAsset'
  /** Percentage of electricity lost when passing through cables on the AC side. Defaults to 1 percent. */
  acCableLosses: Scalars['Float']['output']
  /** The asset's inverter AC capacity in W */
  acCapacity: Scalars['Float']['output']
  /** The asset's altitude in meters */
  altitude: Scalars['Float']['output']
  /** The asset's solar panel degradation in decimal (multiple by 100 for percent) of DC output. */
  annualDegradation: Scalars['Float']['output']
  /** Asset management costs in JPY/kWp/year */
  assetManagementCosts: Array<OpexCostSet>
  /** Balancing group the solar asset is a part of */
  balancingGroup: BalancingGroup
  /** Co-located battery connected to the solar asset */
  battery?: Maybe<Battery>
  /** Capital expenditure payment items */
  capexPayments: Array<Maybe<CapexPayment>>
  /** Grid interconnection date */
  cod: Scalars['Time']['output']
  /** Issued by the TSO and serves as an additional identifier for each asset */
  contractClassificationNumber2?: Maybe<Scalars['String']['output']>
  /** Time the asset was created */
  createdAt: Scalars['Time']['output']
  /** ID of the user who created the asset */
  creatorUserId: Scalars['ID']['output']
  /** Percentage of electricity lost when passing through cables on the DC side. This is not currently used. */
  dcCableLosses?: Maybe<Scalars['Float']['output']>
  /** The asset's panel DC capacity in Wp */
  dcCapacity: Scalars['Float']['output']
  /** Decommission reserve costs in JPY per kWh of generated electricity */
  decommissionReserveCosts: Array<OpexCostSet>
  /** Whether asset has been deleted */
  deleted: Scalars['Boolean']['output']
  /** Company that's leading the asset development */
  developer?: Maybe<ThirdPartyCompany>
  /** Annual electricity losses due to unforseen downtime in percent of AC output */
  downtimeRate: Scalars['Float']['output']
  /** Engineering, procurement, and construction (EPC) company that's leading the asset development */
  epcCompany?: Maybe<ThirdPartyCompany>
  /** Grid code of the asset */
  gridCode?: Maybe<Scalars['String']['output']>
  /** Utility grid zone where the asset is located */
  gridZone: GridZone
  /** A unique, non-human readable id */
  id: Scalars['ID']['output']
  /** Insurance costs in JPY/kWp/year */
  insuranceCosts: Array<OpexCostSet>
  /** Inverter replacement costs in JPY per replacement. Assumed to be incurred at the end of the inverter warranty period. */
  inverterCost: Array<OpexCostSet>
  /** Percentage of electricity lost when passing through the inverter on the AC side */
  inverterLosses: Scalars['Float']['output']
  /** Inverter warranty period in years */
  inverterWarrantyPeriod: Scalars['Int']['output']
  /** Is the solar asset using the advanced losses setting? */
  isUsingAdvancedLosses: Scalars['Boolean']['output']
  /** Land lease costs in JPY/kWp/year */
  landLeaseCosts: Array<OpexCostSet>
  /** Measurement of location north or south of the Equator in degrees */
  latitude: Scalars['Float']['output']
  /** Asset lifetime in years */
  lifetime: Scalars['Int']['output']
  /** Co-located load connected to the solar asset */
  load?: Maybe<SolarAssetLoad>
  /** User-defined name of the asset location. Used in-lieu of addresses, which can be ambiguous. */
  locationName: Scalars['String']['output']
  /** Measurement of location east or west of the prime meridian in degrees */
  longitude: Scalars['Float']['output']
  /** Scenario under which asset simulations are running per default */
  macroScenario: MacroScenario
  /** Day of the month on which the meter is read by the utility */
  meteringDate: Scalars['Int']['output']
  /** Unique ID issued by the Ministry of Economy, Trade and Industry (METI) */
  metiRegistrationid: Scalars['String']['output']
  /** Unique ID issued by the Ministry of Economy, Trade and Industry (METI) */
  metiid: Scalars['String']['output']
  /** User-defined asset name */
  name: Scalars['String']['output']
  /** Operation and maintenance costs in JPY/kWp/year */
  omCosts: Array<OpexCostSet>
  /** Company that provides operation and maintenance services for the asset */
  omProvider?: Maybe<ThirdPartyCompany>
  /** Operations of the asset. Includes data attributes related to asset forecasts and actuals. */
  operations?: Maybe<SolarAssetOperations>
  /** ID of the organization the solar asset belongs to. */
  organizationId: Scalars['ID']['output']
  /** Other operational costs in JPY/year */
  otherCosts: Array<OpexCostSet>
  /** Power purchase agreement the asset is operating under */
  ppa?: Maybe<PpaX>
  /**
   * Annual property tax is levied on the total book value of the asset. Regulation applies a 17% linear annual depreciation on solar PV assets.
   * Defaults to 1.4% annually of asset book value at that year. The value is represented in decimal.
   */
  propertyTaxRate: Array<OpexCostSet>
  /** Solar PV module (panel) type. Not user definable for the time being. */
  pvArray: Array<Maybe<PvModuleGeneric>>
  /** A persistent, sequential human readable id */
  readableId: Scalars['String']['output']
  /** Percent of electricity lost to shading by trees, buildings, etc. */
  shadingLosses: Scalars['Float']['output']
  /** Status of the asset's simulation */
  simulationStatus: SimulationStatus
  /** The results of the simulation */
  simulationSummary?: Maybe<SimulationSummary>
  /** SPV the asset is managed under */
  spv?: Maybe<Spv>
  /** Whether an asset is still under development, operational, or under maintenance */
  status: AssetStatus
  /** Subsidy rate granted for the asset in JPY/kWh. Can be FIT or FIP */
  subsidyRate?: Maybe<Scalars['Float']['output']>
  /** Subsidy scheme the asset operates under. Can be feed-in-tariff, feed-in-premium, or no subsidy. */
  subsidyScheme: SubsidyScheme
  /** Start date of the asset subsidy scheme */
  subsidyStartDate?: Maybe<Scalars['Time']['output']>
  /**
   * Percent of electricity lost or gained from ambient temperature vs. rated temperature in percent.
   * The PV cell temperature coefficient is the parameter needed to calculate losses due to heat above or below 25 degrees celsius, and it usually ranges between -0.29 and -0.5 %/°C.
   * This means that every 10 °C in excess results in a decrease in power of the module by 2,9% to 5%.
   */
  temperatureLosses: Scalars['Float']['output']
  /** Time the asset was last updated */
  updatedAt: Scalars['Time']['output']
  /** ID of the user who last updated the asset */
  userUpdatedAt: Scalars['Time']['output']
  /** Unique ID issued by the electric utility of the grid zone where the asset is located */
  utilityid: Scalars['String']['output']
}

export enum SolarAssetActualsType {
  GenerationMeter = 'GENERATION_METER',
  GenerationMonitoring = 'GENERATION_MONITORING',
  LoadDemand = 'LOAD_DEMAND',
}

export type SolarAssetActualsUploadsInput = {
  workspaceId: Scalars['ID']['input']
}

export type SolarAssetAlreadyExistsProblem = ProblemInterface & {
  __typename?: 'SolarAssetAlreadyExistsProblem'
  /** [Internal] Message for debugging purposes */
  message: Scalars['String']['output']
  /** Name of the solar asset that caused the problem */
  name: Scalars['String']['output']
  /** Indicates which property of the solar asset caused the problem */
  type: SolarAssetAlreadyExistsProblemType
}

export enum SolarAssetAlreadyExistsProblemType {
  /** The location id, formally known as the utility id. */
  LocationId = 'LOCATION_ID',
  /** The name of the solar asset */
  Name = 'NAME',
}

export enum SolarAssetCannotBeModifiedProblemProperty {
  GridZone = 'GRID_ZONE',
  Latitude = 'LATITUDE',
  Longitude = 'LONGITUDE',
}

export type SolarAssetFilter = {
  /** Filter by balancing groups */
  balancingGroup?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Filter by grid zone of the asset */
  gridZone?: InputMaybe<GridZone>
  /** Filter by PPAs */
  ppa?: InputMaybe<IdFilterInput>
  /** Filter by the SPV */
  spv?: InputMaybe<IdFilterInput>
  /** Filter by asset status */
  status?: InputMaybe<AssetStatus>
  /** Filter by name using text search */
  text?: InputMaybe<Scalars['String']['input']>
}

export type SolarAssetFilterVariables = {
  __typename?: 'SolarAssetFilterVariables'
  balancingGroup?: Maybe<Array<Scalars['ID']['output']>>
  gridZone?: Maybe<GridZone>
  ppa: IdFilter
  spv: IdFilter
  status?: Maybe<AssetStatus>
  text?: Maybe<Scalars['String']['output']>
}

export type SolarAssetInput = {
  assetId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type SolarAssetList = {
  __typename?: 'SolarAssetList'
  pageInfo: PaginationInfo
  queryVariables: SolarAssetListQueryVariables
  records: Array<SolarAsset>
}

export type SolarAssetListInput = {
  organizationId: Scalars['ID']['input']
  page?: Scalars['Int']['input']
  perPage?: Scalars['Int']['input']
}

export type SolarAssetListInputVariables = {
  __typename?: 'SolarAssetListInputVariables'
  organizationId: Scalars['ID']['output']
  page: Scalars['Int']['output']
  perPage: Scalars['Int']['output']
}

export type SolarAssetListQueryVariables = {
  __typename?: 'SolarAssetListQueryVariables'
  filter?: Maybe<SolarAssetFilterVariables>
  input: SolarAssetListInputVariables
  sort?: Maybe<SolarAssetSort>
}

export enum SolarAssetLiveStatus {
  Generating = 'GENERATING',
  Idle = 'IDLE',
  None = 'NONE',
}

export type SolarAssetLoad = {
  __typename?: 'SolarAssetLoad'
  /** Average load of the entity in kW */
  averageLoad: Scalars['Float']['output']
  /** Demand actuals series. Values are represented in kilowatt hour (kWh) */
  demandActuals: HighPerformanceNullableNumberSeries
  /** Demand forecast series. Values are represented in kilowatt hour (kWh) */
  demandForecast: HighPerformanceNullableNumberSeries
  /** Unique, non-human readable id */
  id: Scalars['ID']['output']
  /** Flag that indicates whether the asset load is deleted or not */
  isDeleted: Scalars['Boolean']['output']
  /** Maximum load of the entity in kW */
  maximumLoad: Scalars['Float']['output']
  /** Minimum load of the entity in kW */
  minimumLoad: Scalars['Float']['output']
  /** User-defined asset load name */
  name: Scalars['String']['output']
  /** User-defined notes */
  notes: Scalars['String']['output']
  /** Date the load entity will start operation */
  operationalStartDate: Scalars['Time']['output']
  /** Latest demand, value is represented in kW. Null is returned if there has been no value in the last 30 minutes */
  realTimeDemand?: Maybe<Scalars['Float']['output']>
  /** Indicates the current state of the load entity */
  status: SolarAssetLoadStatus
  /** User-defined asset load type (TDB) */
  type: SolarAssetLoadType
}

export type SolarAssetLoadDemandActualsArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetLoadDemandForecastArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export enum SolarAssetLoadStatus {
  Decommissioned = 'DECOMMISSIONED',
  Maintenance = 'MAINTENANCE',
  Operation = 'OPERATION',
  Planned = 'PLANNED',
}

export enum SolarAssetLoadType {
  Regular = 'REGULAR',
}

export type SolarAssetLoadValidationProblem = ProblemInterface & {
  __typename?: 'SolarAssetLoadValidationProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** The record created by merging the desired state and current state */
  record: SolarAssetLoad
  /** ID of the asset load */
  recordId: Scalars['ID']['output']
}

export type SolarAssetMutations = {
  __typename?: 'SolarAssetMutations'
  /** Makes N number of copies of the asset */
  copy: CopySolarAssetPayload
  /** Creates an asset */
  create: CreateSolarAssetPayload
  /** Delete the asset */
  delete: DeleteSolarAssetPayload
  /** Modifies an asset plan */
  modifyAssetPlan: ModifySolarAssetPlanPayload
  /** Manually triggers an asset's simulation */
  triggerSimulation: TriggerSolarAssetSimulationPayload
  /** Updates an asset */
  update: UpdateSolarAssetPayload
}

export type SolarAssetMutationsCopyArgs = {
  input: CopySolarAssetInput
}

export type SolarAssetMutationsCreateArgs = {
  input: CreateSolarAssetInput
}

export type SolarAssetMutationsDeleteArgs = {
  input: DeleteSolarAssetInput
}

export type SolarAssetMutationsModifyAssetPlanArgs = {
  input: ModifySolarAssetPlanInput
}

export type SolarAssetMutationsTriggerSimulationArgs = {
  input: TriggerSolarAssetSimulationInput
}

export type SolarAssetMutationsUpdateArgs = {
  input: UpdateSolarAssetInput
}

export type SolarAssetOperationAlarmEvent = {
  /** Time the alarm event was occurred */
  createdAt: Scalars['Time']['output']
  /** Flag that indicates if the event was resolved */
  isResolved: Scalars['Boolean']['output']
  /** Time the alarm was resolved at */
  resolvedAt?: Maybe<Scalars['Time']['output']>
}

export type SolarAssetOperationAlarmEventBatteryFault = SolarAssetOperationAlarmEvent & {
  __typename?: 'SolarAssetOperationAlarmEventBatteryFault'
  /** Time the alarm event was occurred */
  createdAt: Scalars['Time']['output']
  /** Flag that indicates if the event was resolved */
  isResolved: Scalars['Boolean']['output']
  /** Time the alarm was resolved at */
  resolvedAt?: Maybe<Scalars['Time']['output']>
}

export type SolarAssetOperationAlarmEventBatteryScheduledModified =
  SolarAssetOperationAlarmEvent & {
    __typename?: 'SolarAssetOperationAlarmEventBatteryScheduledModified'
    /** Time the alarm event was occurred */
    createdAt: Scalars['Time']['output']
    /** Flag that indicates if the event was resolved */
    isResolved: Scalars['Boolean']['output']
    /** Metadata related to the battery schedule modification event */
    metadata: SolarAssetOperationAlarmEventBatteryScheduledModifiedMetadata
    /** Time the alarm was resolved at */
    resolvedAt?: Maybe<Scalars['Time']['output']>
  }

export type SolarAssetOperationAlarmEventBatteryScheduledModifiedMetadata = {
  __typename?: 'SolarAssetOperationAlarmEventBatteryScheduledModifiedMetadata'
  /** The actor who modified the battery schedule */
  actor: Actor
  /** The date of the schedule that was modified */
  scheduleDate: Scalars['Time']['output']
}

export type SolarAssetOperationAlarmEventHighLatency = SolarAssetOperationAlarmEvent & {
  __typename?: 'SolarAssetOperationAlarmEventHighLatency'
  /** Time the alarm event was occurred */
  createdAt: Scalars['Time']['output']
  /** Flag that indicates if the event was resolved */
  isResolved: Scalars['Boolean']['output']
  /** Time the alarm was resolved at */
  resolvedAt?: Maybe<Scalars['Time']['output']>
}

export type SolarAssetOperationAlarmEventLossBatteryCommunication =
  SolarAssetOperationAlarmEvent & {
    __typename?: 'SolarAssetOperationAlarmEventLossBatteryCommunication'
    /** Time the alarm event was occurred */
    createdAt: Scalars['Time']['output']
    /** Flag that indicates if the event was resolved */
    isResolved: Scalars['Boolean']['output']
    /** Time the alarm was resolved at */
    resolvedAt?: Maybe<Scalars['Time']['output']>
  }

export type SolarAssetOperationAlarmEventLossMeterCommunication = SolarAssetOperationAlarmEvent & {
  __typename?: 'SolarAssetOperationAlarmEventLossMeterCommunication'
  /** Time the alarm event was occurred */
  createdAt: Scalars['Time']['output']
  /** Flag that indicates if the event was resolved */
  isResolved: Scalars['Boolean']['output']
  /** Time the alarm was resolved at */
  resolvedAt?: Maybe<Scalars['Time']['output']>
}

export type SolarAssetOperationAlarmEventManualScheduleOperation = SolarAssetOperationAlarmEvent & {
  __typename?: 'SolarAssetOperationAlarmEventManualScheduleOperation'
  /** Time the alarm event was occurred */
  createdAt: Scalars['Time']['output']
  /** Flag that indicates if the event was resolved */
  isResolved: Scalars['Boolean']['output']
  /** Time the alarm was resolved at */
  resolvedAt?: Maybe<Scalars['Time']['output']>
}

export type SolarAssetOperations = {
  __typename?: 'SolarAssetOperations'
  /** Alarms that have occurred recently. Returns the latest three alarms that have occurred. */
  alarms: Array<SolarAssetOperationAlarmEvent>
  /** Array of series of actual curtailment events. Empty array indicates there is no curtailment available */
  curtailmentActuals: HighPerformanceNullableNumberSeries
  /** Array of series of forecasted curtailment events. Empty array indicates there is no curtailemnt available */
  curtailmentForecast: HighPerformanceNullableNumberSeries
  /** Current live status of solar generation */
  liveStatus: SolarAssetLiveStatus
  /** Latest solar generation that has occurred, represented in kW. Null is returned if no value in the last 30 minutes */
  realTimeSolarGeneration?: Maybe<Scalars['Float']['output']>
  /** Latest energy that has been exported to the grid, value is represented in kW. Null is returned if no value in the last 30 minutes */
  realTimeTotalEnergyExported?: Maybe<Scalars['Float']['output']>
  /** Series of actual solar energy that has been exported to the grid. Values are represented in kilowatt hour (kWh) */
  solarEnergyExportedActuals: HighPerformanceNullableNumberSeries
  /** Series of forecasted solar energy that has been exported to the grid. Values are represented in kilowatt hour (kWh) */
  solarEnergyExportedForecast: HighPerformanceNullableNumberSeries
  /** Series of actual solar generation that has occurred. Values are represented in kilowatt hour (kWh) */
  solarGenerationActuals: HighPerformanceNullableNumberSeries
  /** Series of forecasted solar generation that was forecasted the previous day aka day-ahead forecasts. Values are represented in kilowatt hour (kWh) */
  solarGenerationDayAheadForecast: HighPerformanceNullableNumberSeries
  /** Series of the latest forecasted solar generation. Values are represented in kilowatt hour (kWh) */
  solarGenerationForecast: HighPerformanceNullableNumberSeries
  /** Series of actual energy, includes battery + solar that has been exported to the grid. Values are represented in kilowatt hour (kWh) */
  totalEnergyExportedActuals: HighPerformanceNullableNumberSeries
  /** Series of forecasted energy, includes battery + solar, that has been exported to the grid. Values are represented in kilowatt hour (kWh) */
  totalEnergyExportedForecast: HighPerformanceNullableNumberSeries
}

export type SolarAssetOperationsAlarmsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type SolarAssetOperationsCurtailmentActualsArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetOperationsCurtailmentForecastArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetOperationsSolarEnergyExportedActualsArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetOperationsSolarEnergyExportedForecastArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetOperationsSolarGenerationActualsArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetOperationsSolarGenerationDayAheadForecastArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetOperationsSolarGenerationForecastArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetOperationsTotalEnergyExportedActualsArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetOperationsTotalEnergyExportedForecastArgs = {
  end: Scalars['Time']['input']
  start: Scalars['Time']['input']
}

export type SolarAssetPlanDoesNotMatchProblem = ProblemInterface & {
  __typename?: 'SolarAssetPlanDoesNotMatchProblem'
  message: Scalars['String']['output']
}

export type SolarAssetPlanInput = {
  /** Amount of battery charge/discharge in kilowatt hour (kWh) */
  batteryCharge?: InputMaybe<Scalars['Float']['input']>
  /** Amount of demand in kilowatt hour (kWh) */
  demand?: InputMaybe<Scalars['Float']['input']>
  /** Amount of energy flowing through the site meter in kilowatt hour (kWh) */
  siteMeterFlow: Scalars['Float']['input']
  /** Amount of solar generation in kilowatt hour (kWh) */
  solarGeneration: Scalars['Float']['input']
}

export type SolarAssetPropertiesCannotBeModifiedProblem = ProblemInterface & {
  __typename?: 'SolarAssetPropertiesCannotBeModifiedProblem'
  message: Scalars['String']['output']
  properties: Array<SolarAssetCannotBeModifiedProblemProperty>
  recordId: Scalars['ID']['output']
}

export type SolarAssetReportScope = {
  __typename?: 'SolarAssetReportScope'
  capexPayments: Array<CapexPayment>
  keyIndicators: SolarAssetReportScopeKeyIndicators
  records: Array<ReportSolarAsset>
  simulationSummary: ReportSimulationSummary
  total: Scalars['Int']['output']
}

export type SolarAssetReportScopeCapexPaymentsArgs = {
  input: ReportScopeBaseInput
}

export type SolarAssetReportScopeKeyIndicatorsArgs = {
  input: SolarAssetReportScopeInput
}

export type SolarAssetReportScopeRecordsArgs = {
  input: SolarAssetReportScopeInput
  sort?: InputMaybe<SolarAssetSort>
}

export type SolarAssetReportScopeSimulationSummaryArgs = {
  input: SolarAssetReportScopeInput
}

export type SolarAssetReportScopeInput = {
  ids: Array<Scalars['ID']['input']>
  macroScenarioId: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  type: ReportScopeFilterType
}

export type SolarAssetReportScopeKeyIndicators = {
  __typename?: 'SolarAssetReportScopeKeyIndicators'
  acCapacity: Scalars['Float']['output']
  dcCapacity: Scalars['Float']['output']
  irr: IrrBenchmarkIndicator
  kwpCapex: BenchmarkIndicator
  kwpRevenues: BenchmarkIndicator
  lifetimeRevenues: BenchmarkIndicator
  total: Scalars['Int']['output']
  totalCapex: BenchmarkIndicator
}

export type SolarAssetResourceDoesNotExistProblem = ProblemInterface & {
  __typename?: 'SolarAssetResourceDoesNotExistProblem'
  message: Scalars['String']['output']
  recordId: Scalars['ID']['output']
  type: SolarAssetResourceDoesNotExistProblemType
}

export enum SolarAssetResourceDoesNotExistProblemType {
  Asset = 'ASSET',
  CapexPayment = 'CAPEX_PAYMENT',
  Developer = 'DEVELOPER',
  EpcCompany = 'EPC_COMPANY',
  OmProvider = 'OM_PROVIDER',
  Ppa = 'PPA',
  Scenario = 'SCENARIO',
}

export enum SolarAssetSort {
  AcCapacityAsc = 'AC_CAPACITY_ASC',
  AcCapacityDesc = 'AC_CAPACITY_DESC',
  CodAsc = 'COD_ASC',
  CodDesc = 'COD_DESC',
  DateAddedAsc = 'DATE_ADDED_ASC',
  DateAddedDesc = 'DATE_ADDED_DESC',
  DcCapacityAsc = 'DC_CAPACITY_ASC',
  DcCapacityDesc = 'DC_CAPACITY_DESC',
  GridZoneAsc = 'GRID_ZONE_ASC',
  GridZoneDesc = 'GRID_ZONE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IrrAsc = 'IRR_ASC',
  IrrDesc = 'IRR_DESC',
  LastEditAsc = 'LAST_EDIT_ASC',
  LastEditDesc = 'LAST_EDIT_DESC',
  LocationNameAsc = 'LOCATION_NAME_ASC',
  LocationNameDesc = 'LOCATION_NAME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PpaAsc = 'PPA_ASC',
  PpaDesc = 'PPA_DESC',
  SimulationStatusAsc = 'SIMULATION_STATUS_ASC',
  SimulationStatusDesc = 'SIMULATION_STATUS_DESC',
  SolarYieldAsc = 'SOLAR_YIELD_ASC',
  SolarYieldDesc = 'SOLAR_YIELD_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
}

export type SolarAssetsMutations = {
  __typename?: 'SolarAssetsMutations'
  /** Attaches a PPA to many solar assets */
  addPpa: AddSolarAssetsPpaPayload
  /** Delete many solar assets */
  deleteMany: DeleteManySolarAssetsPayload
  /** Generate PUT presigned URLs to upload solar asset actuals */
  generateUploadActualsUrls: GenerateSolarAssetUploadActualsUrlsPayload
}

export type SolarAssetsMutationsAddPpaArgs = {
  input: AddSolarAssetsPpaInput
}

export type SolarAssetsMutationsDeleteManyArgs = {
  input: DeleteManySolarAssetsInput
}

export type SolarAssetsMutationsGenerateUploadActualsUrlsArgs = {
  input: GenerateSolarAssetUploadActualsUrlsInput
}

export type Spv = {
  __typename?: 'Spv'
  /** Assets contained within the SPV */
  assets: Array<SolarAsset>
  /** Registered ID of the legal entity */
  companyRegistrationId?: Maybe<Scalars['String']['output']>
  /** Type of legal entity */
  companyType: SpvCompanyType
  /** How often payments are made to equity investors */
  couponPaymentFrequency: CouponPaymentFrequency
  /**
   * For annual payments, the month the payout will be every year.
   * For biannual payments, the first payout month in the year.
   */
  couponPaymentMonth: Month
  /** Percentage of profit and loss paid out to TK investors represented as a decimal */
  couponPayoutPercentage: Scalars['Float']['output']
  /** Time the SPV was created at */
  createdAt: Scalars['Time']['output']
  /** Financial year the SPV follows */
  financialYear: Month
  /** A unique, non-human readable id */
  id: Scalars['ID']['output']
  /** The investments the SPV received */
  investments: Array<SpvInvestment>
  /** Date when the SPV legal entity was created */
  legalEntityCreationDate?: Maybe<Scalars['Time']['output']>
  /** Registered name of the legal entity */
  legalEntityName: SpvLegalEntityName
  /** The loans the SPV took out */
  loans: Array<SpvLoan>
  /** A unique, human readable id */
  readableId: Scalars['String']['output']
  /** Percentage of debt-service coverage ratio represented as a decimal */
  targetDSCR?: Maybe<Scalars['Float']['output']>
  /** Total number of assets a part of the SPV */
  totalAssets: Scalars['Int']['output']
  /** Total DC capacity in watts the SPV houses */
  totalDcCapacity: Scalars['Float']['output']
  /** Time the SPV was last updated */
  updatedAt: Scalars['Time']['output']
  /** ID of the workspace the SPV belongs to */
  workspaceId: Scalars['ID']['output']
}

export enum SpvCompanyType {
  Gk = 'GK',
  Kk = 'KK',
}

export type SpvFilter = {
  text?: InputMaybe<Scalars['String']['input']>
}

export type SpvFilterVariables = {
  __typename?: 'SpvFilterVariables'
  text?: Maybe<Scalars['String']['output']>
}

export type SpvInvestment = {
  __typename?: 'SpvInvestment'
  /** Amount of money invested by the Investor in JPY */
  amount: Scalars['Float']['output']
  /** Unique identifier for the investment */
  id: Scalars['ID']['output']
  /** Date the investment was made */
  investmentDate: Scalars['Time']['output']
  /** Name of the investor that has made the investment */
  investorName: Scalars['String']['output']
}

export type SpvInvestmentValidationProblem = ProblemInterface & {
  __typename?: 'SpvInvestmentValidationProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** The record created by merging the desired state and current state */
  record: SpvInvestment
  /** ID of the spv investment */
  recordId: Scalars['ID']['output']
}

export type SpvLegalEntityName = {
  __typename?: 'SpvLegalEntityName'
  en?: Maybe<Scalars['String']['output']>
  ja: Scalars['String']['output']
}

export type SpvList = {
  __typename?: 'SpvList'
  pageInfo: PaginationInfo
  queryVariables: SpvListQueryVariables
  records: Array<Spv>
}

export type SpvListInput = {
  page?: Scalars['Int']['input']
  perPage?: Scalars['Int']['input']
  workspaceId: Scalars['ID']['input']
}

export type SpvListInputVariables = {
  __typename?: 'SpvListInputVariables'
  page: Scalars['Int']['output']
  perPage: Scalars['Int']['output']
  workspaceId: Scalars['ID']['output']
}

export type SpvListQueryVariables = {
  __typename?: 'SpvListQueryVariables'
  filter?: Maybe<SpvFilterVariables>
  input: SpvListInputVariables
  sort?: Maybe<SpvSort>
}

export type SpvLoan = {
  __typename?: 'SpvLoan'
  /** Amount of money being loaned in JPY */
  amount: Scalars['Float']['output']
  /** Description of the loan */
  description: Scalars['String']['output']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** Interest rate of the given loan represented as a decimal */
  interestRate: Scalars['Float']['output']
  /** Name of the Lender that has given the loan */
  lenderName: Scalars['String']['output']
  /** Date when the loaned money amount is received */
  loanReceivedDate: Scalars['Time']['output']
  /** Date when repayment of the loan ends */
  repaymentEndDate: Scalars['Time']['output']
  /** Frequency the loan is repaid at */
  repaymentFrequency: RepaymentFrequency
  /** Date when repayment of the loan begins */
  repaymentStartDate: Scalars['Time']['output']
}

export type SpvLoanValidationProblem = ProblemInterface & {
  __typename?: 'SpvLoanValidationProblem'
  /**
   * [internal] A short message of what occurred.
   * Intended for internal use, so it is recommended that matching occurs against the __typename and not the message
   */
  message: Scalars['String']['output']
  /** The record created by merging the desired state and current state */
  record: SpvLoan
  /** ID of the spv loan */
  recordId: Scalars['ID']['output']
}

export type SpvMutations = {
  __typename?: 'SpvMutations'
  addAssets: AddAssetsToSpvPayload
  create: CreateSpvPayload
  delete: DeleteSpvPayload
  generateFinancialStatement: GenerateFinancialStatementPayload
  removeAssets: RemoveAssetsFromSpvPayload
  update: UpdateSpvPayload
}

export type SpvMutationsAddAssetsArgs = {
  input: AddAssetsToSpvInput
}

export type SpvMutationsCreateArgs = {
  input: CreateSpvInput
}

export type SpvMutationsDeleteArgs = {
  input: DeleteSpvInput
}

export type SpvMutationsGenerateFinancialStatementArgs = {
  input: GenerateFinancialStatementInput
}

export type SpvMutationsRemoveAssetsArgs = {
  input: RemoveAssetsFromSpvInput
}

export type SpvMutationsUpdateArgs = {
  input: UpdateSpvInput
}

export type SpvResourceDoesNotExistProblem = ProblemInterface & {
  __typename?: 'SpvResourceDoesNotExistProblem'
  message: Scalars['String']['output']
  recordId: Scalars['ID']['output']
  type: SpvResourceDoesNotExistProblemType
}

export enum SpvResourceDoesNotExistProblemType {
  Asset = 'ASSET',
  Investment = 'INVESTMENT',
  Loan = 'LOAN',
  Spv = 'SPV',
}

export enum SpvSort {
  DateAddedAsc = 'DATE_ADDED_ASC',
  DateAddedDesc = 'DATE_ADDED_DESC',
  EnNameAsc = 'EN_NAME_ASC',
  EnNameDesc = 'EN_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  JaNameAsc = 'JA_NAME_ASC',
  JaNameDesc = 'JA_NAME_DESC',
  LastEditAsc = 'LAST_EDIT_ASC',
  LastEditDesc = 'LAST_EDIT_DESC',
  TotalAssetsAsc = 'TOTAL_ASSETS_ASC',
  TotalAssetsDesc = 'TOTAL_ASSETS_DESC',
  TotalDcCapacityAsc = 'TOTAL_DC_CAPACITY_ASC',
  TotalDcCapacityDesc = 'TOTAL_DC_CAPACITY_DESC',
}

export type StarReportData = {
  __typename?: 'StarReportData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type StarReportInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type StarReportPayload = {
  __typename?: 'StarReportPayload'
  data?: Maybe<StarReportData>
  errors?: Maybe<Array<StarReportProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type StarReportProblems = AuthorizationProblem | ResourceDoesNotExistProblem

/** String formats that are supported for @constraint directive */
export enum StringConstraintFormat {
  /** Email address */
  Email = 'EMAIL',
  /** Uniform resource identifier */
  Uri = 'URI',
  /** Universally unique identifier */
  Uuid = 'UUID',
}

export type SubmitOcctoPlanInput = {
  /** Date to mark the plan submit on */
  date: Scalars['Time']['input']
  /** Grid zone plan that was submitted */
  gridZone: GridZone
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type SubmitOcctoPlanPayload = {
  __typename?: 'SubmitOcctoPlanPayload'
  errors?: Maybe<Array<SubmitOcctoPlanProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type SubmitOcctoPlanProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export enum SubsidyScheme {
  /** Feed-in-premium */
  Fip = 'FIP',
  /** Feed-in-tariff */
  Fit = 'FIT',
  /** No subsidy */
  None = 'NONE',
}

export type ThirdPartyCompaniesMutations = {
  __typename?: 'ThirdPartyCompaniesMutations'
  deleteMany: DeleteManyThirdPartyCompaniesPayload
}

export type ThirdPartyCompaniesMutationsDeleteManyArgs = {
  input: DeleteManyThirdPartyCompaniesInput
}

export type ThirdPartyCompany = {
  __typename?: 'ThirdPartyCompany'
  /** The time the third party company was created at. */
  createdAt: Scalars['Time']['output']
  /** An unique identifier for the third party company. */
  id: Scalars['ID']['output']
  /** The name of the third party company. */
  name: Scalars['String']['output']
  /** Type of third party company */
  type: ThirdPartyCompanyType
  /** The time the third party company was last updated at. */
  updatedAt: Scalars['Time']['output']
}

export type ThirdPartyCompanyFilter = {
  text?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<ThirdPartyCompanyType>
}

export type ThirdPartyCompanyFilterVariables = {
  __typename?: 'ThirdPartyCompanyFilterVariables'
  text?: Maybe<Scalars['String']['output']>
}

export type ThirdPartyCompanyList = {
  __typename?: 'ThirdPartyCompanyList'
  pageInfo: PaginationInfo
  queryVariables: ThirdPartyCompanyListQueryVariables
  records: Array<ThirdPartyCompany>
}

export type ThirdPartyCompanyListInput = {
  page?: Scalars['Int']['input']
  perPage?: Scalars['Int']['input']
  workspaceId: Scalars['ID']['input']
}

export type ThirdPartyCompanyListInputVariables = {
  __typename?: 'ThirdPartyCompanyListInputVariables'
  page: Scalars['Int']['output']
  perPage: Scalars['Int']['output']
  workspaceId: Scalars['ID']['output']
}

export type ThirdPartyCompanyListQueryVariables = {
  __typename?: 'ThirdPartyCompanyListQueryVariables'
  filter?: Maybe<ThirdPartyCompanyFilterVariables>
  input: ThirdPartyCompanyListInputVariables
  sort?: Maybe<ThirdPartyCompanySort>
}

export type ThirdPartyCompanyMutations = {
  __typename?: 'ThirdPartyCompanyMutations'
  create: CreateThirdPartyCompanyPayload
  delete: DeleteThirdPartyCompanyPayload
  rename: RenameThirdPartyCompanyPayload
}

export type ThirdPartyCompanyMutationsCreateArgs = {
  input: CreateThirdPartyCompanyInput
}

export type ThirdPartyCompanyMutationsDeleteArgs = {
  input: DeleteThirdPartyCompanyInput
}

export type ThirdPartyCompanyMutationsRenameArgs = {
  input: RenameThirdPartyCompanyInput
}

export enum ThirdPartyCompanySort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

/** Type of Third party companies */
export enum ThirdPartyCompanyType {
  Developer = 'DEVELOPER',
  EpcCompany = 'EPC_COMPANY',
  Offtaker = 'OFFTAKER',
  OmProvider = 'OM_PROVIDER',
}

export type TriggerSolarAssetSimulationData = {
  __typename?: 'TriggerSolarAssetSimulationData'
  /** SolarAsset record */
  record: SolarAsset
  /** ID of the record */
  recordId: Scalars['ID']['output']
}

export type TriggerSolarAssetSimulationInput = {
  /** ID of the solar asset */
  id: Scalars['ID']['input']
  /** ID of the workspace the asset is associated with */
  workspaceId: Scalars['ID']['input']
}

export type TriggerSolarAssetSimulationPayload = {
  __typename?: 'TriggerSolarAssetSimulationPayload'
  /** Return data from the mutation */
  data?: Maybe<TriggerSolarAssetSimulationData>
  /** Any errors that occurred during the operation */
  errors?: Maybe<Array<TriggerSolarAssetSimulationProblems>>
  /** Recursive query */
  query?: Maybe<Query>
  /** Flag that indicates if the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

/** Any problems that could occur while triggering an asset simulation */
export type TriggerSolarAssetSimulationProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type UnarchiveReportData = {
  __typename?: 'UnarchiveReportData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type UnarchiveReportInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type UnarchiveReportPayload = {
  __typename?: 'UnarchiveReportPayload'
  data?: Maybe<UnarchiveReportData>
  errors?: Maybe<Array<UnarchiveReportProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UnarchiveReportProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type UnshareReportData = {
  __typename?: 'UnshareReportData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type UnshareReportInput = {
  emails: Array<Scalars['String']['input']>
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type UnshareReportPayload = {
  __typename?: 'UnshareReportPayload'
  data?: Maybe<UnshareReportData>
  errors?: Maybe<Array<UnshareReportProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UnshareReportProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type UnstarReportData = {
  __typename?: 'UnstarReportData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type UnstarReportInput = {
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type UnstarReportPayload = {
  __typename?: 'UnstarReportPayload'
  data?: Maybe<UnstarReportData>
  errors?: Maybe<Array<UnstarReportProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UnstarReportProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type UnsubmitOcctoPlanInput = {
  /** Date to mark plan as unsubmitted */
  date: Scalars['Time']['input']
  /** Grid zone associated with the plan to unsubmit */
  gridZone: GridZone
  /** ID of the workspace */
  workspaceId: Scalars['ID']['input']
}

export type UnsubmitOcctoPlanPayload = {
  __typename?: 'UnsubmitOcctoPlanPayload'
  errors?: Maybe<Array<UnsubmitOcctoPlanProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UnsubmitOcctoPlanProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type UpdateAssumptionProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceCannotBeModifiedProblem
  | ResourceDoesNotExistProblem

export type UpdateBalancingGroupData = {
  __typename?: 'UpdateBalancingGroupData'
  /** Record that was updated */
  record: BalancingGroup
  /** ID of the record. Format = UUID */
  recordId: Scalars['ID']['output']
}

export type UpdateBalancingGroupDataInput = {
  /** Desired balancing group code for the balancing group. Code must be 5 characters long. */
  balancingGroupCode?: InputMaybe<Scalars['String']['input']>
  /** Issued by the TSO and serves as an additional identifier for each balancing group */
  contractClassificationNumber1?: InputMaybe<Scalars['String']['input']>
  /** Assigned value of imbalance for intraday optimization, represented in JPY/kWh */
  imbalanceValue?: InputMaybe<Scalars['Float']['input']>
  /** Desired name for the balancing group */
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateBalancingGroupInput = {
  /** Updated properties of the balancing group */
  data?: InputMaybe<UpdateBalancingGroupDataInput>
  /** ID of the balancing group to update. Format = UUID */
  id: Scalars['ID']['input']
  /** ID of the workspace the balancing group is associated with. Format = UUID */
  workspaceId: Scalars['ID']['input']
}

export type UpdateBalancingGroupPayload = {
  __typename?: 'UpdateBalancingGroupPayload'
  /** Return data payload from mutation */
  data?: Maybe<UpdateBalancingGroupData>
  /** Any errors that occurred during the mutation */
  errors?: Maybe<Array<UpdateBalancingGroupProblems>>
  /** Recursive query property that provides access to any query in the Query namespace */
  query?: Maybe<Query>
  /** Flag that indicates whether the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

/** All problems that can occur while updating a balancing group */
export type UpdateBalancingGroupProblems =
  | AuthorizationProblem
  | BalancingGroupAlreadyExistsProblem
  | MissingDependentsProblem
  | ResourceDoesNotExistProblem

export type UpdateBaselineData = {
  __typename?: 'UpdateBaselineData'
  record: Baseline
  recordId: Scalars['ID']['output']
}

export type UpdateBaselineInput = {
  /** ID of the baseline to modify */
  id: Scalars['ID']['input']
  /** Desired name */
  name?: InputMaybe<Scalars['String']['input']>
  /** ID of the workspace the baseline is associated with */
  workspaceId: Scalars['ID']['input']
}

export type UpdateBaselinePayload = {
  __typename?: 'UpdateBaselinePayload'
  /** Returned entity that was updated */
  data?: Maybe<UpdateBaselineData>
  /** Any errors that occurred during the operation */
  errors?: Maybe<Array<UpdateBaselineProblems>>
  /** Recursive query */
  query?: Maybe<Query>
  /** Flag that indicates whether the operation succeeded or not */
  success: Scalars['Boolean']['output']
}

export type UpdateBaselineProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type UpdateBatteryInput = {
  /** Estimate of battery cycle cost in jpy/kWh */
  cycleCost?: InputMaybe<Scalars['Float']['input']>
  /** Energy capacity of the battery in kWh */
  energyCapacity?: InputMaybe<Scalars['Float']['input']>
  /** Number of cycles before the battery capacity drops below OEM warranty threshold */
  expectedCycleLife?: InputMaybe<Scalars['Int']['input']>
  /** Unique, non-human readable id */
  id: Scalars['ID']['input']
  /** Flag that indicates whether the battery is deleted or not */
  isDeleted: Scalars['Boolean']['input']
  /** Maximum state of charge of the battery during operation represented as a decimal */
  maximumStateOfCharge?: InputMaybe<Scalars['Float']['input']>
  /** Minimum state of charge of the battery during operation represented as a decimal */
  minimumStateOfCharge?: InputMaybe<Scalars['Float']['input']>
  /** Date the battery will start operation */
  operationalStartDate?: InputMaybe<Scalars['Time']['input']>
  /** Power capacity of the battery in kW */
  powerCapacity?: InputMaybe<Scalars['Float']['input']>
  /** Combined efficiency of battery and integrated inverter represented as a decimal */
  roundtripEfficiency?: InputMaybe<Scalars['Float']['input']>
  /** Current development phase of the battery */
  status?: InputMaybe<BatteryStatus>
  /** Warranty period of the battery in years */
  warrantyPeriod?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateCapexPaymentInput = {
  /** Amount of CAPEX payment in JPY */
  amount?: InputMaybe<Scalars['Float']['input']>
  /** Category of the CAPEX payment item. This is used to group CAPEX payments in the UI and to run benchmarks. */
  category?: InputMaybe<CapexCategory>
  /** Whether the CAPEX payment includes consumption tax */
  consumptionTax?: InputMaybe<Scalars['Boolean']['input']>
  /** Date when CAPEX payment has been or is planned to be made by the SPV */
  date?: InputMaybe<Scalars['Time']['input']>
  /** Description of the CAPEX payment item. Can be freely defined by the user. */
  description?: InputMaybe<Scalars['String']['input']>
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['input']
  /** Flag to indicate that the capex payment should be deleted during updated operation */
  isDeleted: Scalars['Boolean']['input']
}

export type UpdateCurtailmentData = {
  __typename?: 'UpdateCurtailmentData'
  record: ScenarioCurtailment
  recordId: Scalars['ID']['output']
}

export type UpdateCurtailmentDataInput = {
  assumptions?: InputMaybe<RegionalAssumptionsInput>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCurtailmentInput = {
  data: UpdateCurtailmentDataInput
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type UpdateCurtailmentPayload = {
  __typename?: 'UpdateCurtailmentPayload'
  data?: Maybe<UpdateCurtailmentData>
  errors?: Maybe<Array<UpdateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdateForecastMapeDayAheadData = {
  __typename?: 'UpdateForecastMapeDayAheadData'
  record: ScenarioForecastMapeDayAhead
  recordId: Scalars['ID']['output']
}

export type UpdateForecastMapeDayAheadDataInput = {
  assumptions?: InputMaybe<Array<AssumptionValuesInput>>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateForecastMapeDayAheadInput = {
  data: UpdateForecastMapeDayAheadDataInput
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type UpdateForecastMapeDayAheadPayload = {
  __typename?: 'UpdateForecastMapeDayAheadPayload'
  data?: Maybe<UpdateForecastMapeDayAheadData>
  errors?: Maybe<Array<UpdateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdateForecastMapeIntradayData = {
  __typename?: 'UpdateForecastMapeIntradayData'
  record: ScenarioForecastMapeIntraday
  recordId: Scalars['ID']['output']
}

export type UpdateForecastMapeIntradayDataInput = {
  assumptions?: InputMaybe<Array<AssumptionValuesInput>>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateForecastMapeIntradayInput = {
  data: UpdateForecastMapeIntradayDataInput
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type UpdateForecastMapeIntradayPayload = {
  __typename?: 'UpdateForecastMapeIntradayPayload'
  data?: Maybe<UpdateForecastMapeIntradayData>
  errors?: Maybe<Array<UpdateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdateInflationData = {
  __typename?: 'UpdateInflationData'
  record: ScenarioInflation
  recordId: Scalars['ID']['output']
}

export type UpdateInflationDataInput = {
  assumptions?: InputMaybe<Array<AssumptionValuesInput>>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateInflationInput = {
  data: UpdateInflationDataInput
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type UpdateInflationPayload = {
  __typename?: 'UpdateInflationPayload'
  data?: Maybe<UpdateInflationData>
  errors?: Maybe<Array<UpdateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdatePpaData = {
  __typename?: 'UpdatePpaData'
  record: PpaX
  recordId: Scalars['ID']['output']
  solarAssetsAffected: Array<Scalars['ID']['output']>
}

export type UpdatePpaDataInput = {
  /** Calculation interval of the PPA in minutes */
  calculationInterval?: InputMaybe<Scalars['Int']['input']>
  /** Upper price limit for PPAs with floating pricing scheme in JPY/kWh */
  capPrice?: InputMaybe<Array<PpaPriceSchemeSetInput>>
  /** How many percent over or under the settlement price the PPA should be settled for. For PPAs with discount pricing scheme only */
  discountToMarket?: InputMaybe<Array<PpaPriceSchemeSetInput>>
  /** Specifies whether IPP or offtaker (electricity buyer) is the beneficiary of FIT or FIP payments. Currently, setting this to offtaker simply removes the FIP/FIT cash flow to zero. */
  fipBeneficiary?: InputMaybe<FipBeneficiary>
  /** Percentage of the PPA that is settled at fixed price. For PPAs with floating pricing scheme only */
  fixedShare?: InputMaybe<Scalars['Float']['input']>
  /** Percentage of the PPA that is settled at floating price. For PPAs with floating pricing scheme only */
  floatingShare?: InputMaybe<Scalars['Float']['input']>
  /** Lower price limit for PPAs with floating pricing scheme in JPY/kWh */
  floorPrice?: InputMaybe<Array<PpaPriceSchemeSetInput>>
  /** Flag that indicates if the PPA is making use of a flexible pricing scheme */
  isFlexiblePricingScheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Name of the PPA */
  name?: InputMaybe<Scalars['String']['input']>
  /** Negative pricing floor in JPY that specifies how the PPA mechanics behave if the settlement price is negative. */
  negativePriceFloor?: InputMaybe<Scalars['Float']['input']>
  /** ID of the offtaker if the PPA is of type VPPA or Off-site */
  offtakerId?: InputMaybe<Scalars['ID']['input']>
  /** Strike price escalation rate for PPAs with fixed escalation pricing scheme in %/year */
  priceEscalationRate?: InputMaybe<Scalars['Float']['input']>
  /** Pricing scheme of the PPA */
  pricingScheme?: InputMaybe<PricingScheme>
  /** Market from which prices the settlement price is derived. Can be set to Day-ahead or Intraday. */
  settlementMarket?: InputMaybe<SettlementMarket>
  /** Only available for Day-ahead settlement market. Pricing zone from which prices the settlement price is derived. Can be set to any of the 10 pricing zones in Japan. */
  settlementPricingZone?: InputMaybe<SettlementPricingZone>
  /** IDs of the included solar PV assets */
  solarAssetIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Start date of the PPA */
  startDate?: InputMaybe<Scalars['Time']['input']>
  /** Status of the PPA */
  status?: InputMaybe<PpaStatus>
  /** Strike price for PPAs with fixed pricing scheme in JPY/kWh */
  strikePrice?: InputMaybe<Array<PpaPriceSchemeSetInput>>
  /** Term of the PPA in years */
  term?: InputMaybe<Scalars['Int']['input']>
  /** Type of the PPA */
  type?: InputMaybe<PpaType>
}

export type UpdatePpaPayload = {
  __typename?: 'UpdatePpaPayload'
  data?: Maybe<UpdatePpaData>
  errors?: Maybe<Array<UpdatePpaProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdatePpaProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceDoesNotExistProblem

export type UpdatePpaXInput = {
  data: UpdatePpaDataInput
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type UpdatePriceAverageData = {
  __typename?: 'UpdatePriceAverageData'
  record: ScenarioPriceAverage
  recordId: Scalars['ID']['output']
}

export type UpdatePriceAverageDataInput = {
  assumptions?: InputMaybe<RegionalWithSystemAssumptionsInput>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdatePriceAverageInput = {
  data: UpdatePriceAverageDataInput
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type UpdatePriceAveragePayload = {
  __typename?: 'UpdatePriceAveragePayload'
  data?: Maybe<UpdatePriceAverageData>
  errors?: Maybe<Array<UpdateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdatePriceVolatilityData = {
  __typename?: 'UpdatePriceVolatilityData'
  record: ScenarioPriceVolatility
  recordId: Scalars['ID']['output']
}

export type UpdatePriceVolatilityDataInput = {
  assumptions?: InputMaybe<RegionalWithSystemAssumptionsInput>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdatePriceVolatilityInput = {
  data: UpdatePriceVolatilityDataInput
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type UpdatePriceVolatilityPayload = {
  __typename?: 'UpdatePriceVolatilityPayload'
  data?: Maybe<UpdatePriceVolatilityData>
  errors?: Maybe<Array<UpdateAssumptionProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdateReportLabelsData = {
  __typename?: 'UpdateReportLabelsData'
  record: Report
  recordId: Scalars['ID']['output']
}

export type UpdateReportLabelsInput = {
  id: Scalars['ID']['input']
  labelIds: Array<Scalars['ID']['input']>
  organizationId: Scalars['ID']['input']
}

export type UpdateReportLabelsPayload = {
  __typename?: 'UpdateReportLabelsPayload'
  data?: Maybe<UpdateReportLabelsData>
  errors?: Maybe<Array<UpdateReportLabelsProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdateReportLabelsProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type UpdateScenarioData = {
  __typename?: 'UpdateScenarioData'
  record: MacroScenario
  recordId: Scalars['ID']['output']
}

export type UpdateScenarioDataInput = {
  curtailmentId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  forecastMapeDayAheadId?: InputMaybe<Scalars['ID']['input']>
  forecastMapeIntradayId?: InputMaybe<Scalars['ID']['input']>
  inflationId?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  priceAverageId?: InputMaybe<Scalars['ID']['input']>
  priceVolatilityId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateScenarioInput = {
  data: UpdateScenarioDataInput
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type UpdateScenarioProblems =
  | AuthorizationProblem
  | MissingDependentsProblem
  | ResourceAlreadyExistsProblem
  | ResourceCannotBeModifiedProblem
  | ResourceDoesNotExistProblem

export type UpdateScenarioResult = {
  __typename?: 'UpdateScenarioResult'
  data?: Maybe<UpdateScenarioData>
  errors?: Maybe<Array<UpdateScenarioProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdateSolarAssetData = {
  __typename?: 'UpdateSolarAssetData'
  record: SolarAsset
  recordId: Scalars['ID']['output']
}

export type UpdateSolarAssetDataInput = {
  /** Percentage of electricity lost when passing through cables on the AC side. Defaults to 1 percent. */
  acCableLosses?: InputMaybe<Scalars['Float']['input']>
  /** The asset's inverter AC capacity in W */
  acCapacity?: InputMaybe<Scalars['Float']['input']>
  /** The asset's altitude in meters */
  altitude?: InputMaybe<Scalars['Float']['input']>
  /** The asset's solar panel degradation in decimal of DC output. */
  annualDegradation?: InputMaybe<Scalars['Float']['input']>
  /** Asset management costs in JPY/kWp/year */
  assetManagementCosts?: InputMaybe<Array<OpexCostSetInput>>
  /** Co-located battery connected to the asset */
  battery?: InputMaybe<UpdateBatteryInput>
  /** Capital expenditure payment items */
  capexPayments?: InputMaybe<Array<UpdateCapexPaymentInput>>
  /** Grid interconnection date */
  cod?: InputMaybe<Scalars['Time']['input']>
  /** Issued by the TSO and serves as an additional identifier for each asset */
  contractClassificationNumber2?: InputMaybe<Scalars['String']['input']>
  /** Percentage of electricity lost when passing through cables on the DC side. This is not currently used. */
  dcCableLosses?: InputMaybe<Scalars['Float']['input']>
  /** Decommission reserve costs in JPY per kWh of generated electricity */
  decommissionReserveCosts?: InputMaybe<Array<OpexCostSetInput>>
  /** ID of the associated developer */
  developerId?: InputMaybe<Scalars['ID']['input']>
  /** Annual electricity losses due to unforseen downtime in percent of AC output */
  downtimeRate?: InputMaybe<Scalars['Float']['input']>
  /** ID of the associated epc company */
  epcCompanyId?: InputMaybe<Scalars['ID']['input']>
  /** Grid code of the asset */
  gridCode?: InputMaybe<Scalars['String']['input']>
  /** Utility grid zone where the asset is located */
  gridZone?: InputMaybe<GridZone>
  /** Insurance costs in JPY/kWp/year */
  insuranceCosts?: InputMaybe<Array<OpexCostSetInput>>
  /** Inverter replacement costs in JPY per replacement. Assumed to be incurred at the end of the inverter warranty period. */
  inverterCost?: InputMaybe<Array<OpexCostSetInput>>
  /** Percentage of electricity lost when passing through the inverter on the AC side */
  inverterLosses?: InputMaybe<Scalars['Float']['input']>
  /** Inverter warranty period in years */
  inverterWarrantyPeriod?: InputMaybe<Scalars['Int']['input']>
  /** Is the solar asset using the advanced losses setting? */
  isUsingAdvancedLosses?: InputMaybe<Scalars['Boolean']['input']>
  /** Land lease costs in JPY/kWp/year */
  landLeaseCosts?: InputMaybe<Array<OpexCostSetInput>>
  /** Distance north or south of the equator in degrees */
  latitude?: InputMaybe<Scalars['Float']['input']>
  /** Asset lifetime in years */
  lifetime?: InputMaybe<Scalars['Int']['input']>
  /** Co-located load connected to the asset */
  load?: InputMaybe<UpdateSolarAssetLoadInput>
  /** User-defined name of the asset location. Used in-lieu of addresses, which can be ambiguous. */
  locationName?: InputMaybe<Scalars['String']['input']>
  /** Distance east or west of the prime meridian in degrees */
  longitude?: InputMaybe<Scalars['Float']['input']>
  /** ID of the associated scenario */
  macroScenarioId?: InputMaybe<Scalars['ID']['input']>
  /** Day of the month on which the meter is read by the utility */
  meteringDate?: InputMaybe<Scalars['Int']['input']>
  /** Unique ID issued by the Ministry of Economy, Trade and Industry (METI) */
  metiRegistrationid?: InputMaybe<Scalars['String']['input']>
  /** Unique ID issued by the Ministry of Economy, Trade and Industry (METI) */
  metiid?: InputMaybe<Scalars['String']['input']>
  /** User-defined asset name */
  name?: InputMaybe<Scalars['String']['input']>
  /** Operation and maintenance costs in JPY/kWp/year */
  omCosts?: InputMaybe<Array<OpexCostSetInput>>
  /** ID of the associated om provider */
  omProviderId?: InputMaybe<Scalars['ID']['input']>
  /** Other operational costs in JPY/year */
  otherCosts?: InputMaybe<Array<OpexCostSetInput>>
  /** ID of the associated ppa */
  ppaId?: InputMaybe<Scalars['ID']['input']>
  /**
   * Annual property tax is levied on the total book value of the asset. Regulation applies a 17% linear annual depreciation on solar PV assets.
   * Defaults to 1.4% annually of asset book value at that year.
   */
  propertyTaxRate?: InputMaybe<Array<OpexCostSetInput>>
  /** Solar PV module (panel) type. Not user definable for the time being. */
  pvArray?: InputMaybe<Array<PvModuleInput>>
  /** Percent of electricity lost to shading by trees, buildings, etc. */
  shadingLosses?: InputMaybe<Scalars['Float']['input']>
  /** Whether an asset is still under development, operational, or under maintenance */
  status?: InputMaybe<AssetStatus>
  /** Subsidy rate granted for the asset in JPY/kWh. Can be FIT or FIP */
  subsidyRate?: InputMaybe<Scalars['Float']['input']>
  /** Subsidy scheme the asset operates under. Can be feed-in-tariff, feed-in-premium, or no subsidy. */
  subsidyScheme?: InputMaybe<SubsidyScheme>
  /** Start date of the asset subsidy scheme */
  subsidyStartDate?: InputMaybe<Scalars['Time']['input']>
  /** Unique ID issued by the electric utility of the grid zone where the asset is located */
  utilityid?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSolarAssetInput = {
  data: UpdateSolarAssetDataInput
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
}

export type UpdateSolarAssetLoadInput = {
  /** Average load of the entity in kW */
  averageLoad?: InputMaybe<Scalars['Float']['input']>
  /** Unique, non-human readable id */
  id: Scalars['ID']['input']
  /** Flag that indicates whether the asset load is deleted or not */
  isDeleted: Scalars['Boolean']['input']
  /** Maximum load of the entity in kW */
  maximumLoad?: InputMaybe<Scalars['Float']['input']>
  /** Minimum load of the entity in kW */
  minimumLoad?: InputMaybe<Scalars['Float']['input']>
  /** User-defined asset load name */
  name?: InputMaybe<Scalars['String']['input']>
  /** User-defined notes */
  notes?: InputMaybe<Scalars['String']['input']>
  /** Date the load entity will start operation */
  operationalStartDate?: InputMaybe<Scalars['Time']['input']>
  /** Indicates the current state of the load entity */
  status?: InputMaybe<SolarAssetLoadStatus>
  /** User-defined asset load type (TDB) */
  type?: InputMaybe<SolarAssetLoadType>
}

export type UpdateSolarAssetPayload = {
  __typename?: 'UpdateSolarAssetPayload'
  data?: Maybe<UpdateSolarAssetData>
  errors?: Maybe<Array<UpdateSolarAssetProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdateSolarAssetProblems =
  | AuthorizationProblem
  | BatteryValidationProblem
  | CapexPaymentValidationProblem
  | SolarAssetAlreadyExistsProblem
  | SolarAssetLoadValidationProblem
  | SolarAssetPropertiesCannotBeModifiedProblem
  | SolarAssetResourceDoesNotExistProblem

export type UpdateSpvData = {
  __typename?: 'UpdateSpvData'
  record: Spv
  recordId: Scalars['ID']['output']
}

export type UpdateSpvDataInput = {
  /** Registered ID of the legal entity. */
  companyRegistrationId?: InputMaybe<Scalars['String']['input']>
  /** Type of legal entity */
  companyType?: InputMaybe<SpvCompanyType>
  /** How often payments are made to equity investors */
  couponPaymentFrequency?: InputMaybe<CouponPaymentFrequency>
  /**
   * For annual payments, the month the payout will be every year.
   * For biannual payments, the first payout month in the year.
   */
  couponPaymentMonth?: InputMaybe<Month>
  /** Percentage of profit and loss paid out to TK investors represented as a decimal */
  couponPayoutPercentage?: InputMaybe<Scalars['Float']['input']>
  /** Financial year the SPV follows */
  financialYear?: InputMaybe<Month>
  /** The investments the SPV received */
  investments?: InputMaybe<Array<UpdateSpvInvestmentInput>>
  /** Date when the SPV legal entity was created */
  legalEntityCreationDate?: InputMaybe<Scalars['Time']['input']>
  /** Registered name of the legal entity in english */
  legalEntityNameEn?: InputMaybe<Scalars['String']['input']>
  /** Registered name of the legal entity in japanese */
  legalEntityNameJa?: InputMaybe<Scalars['String']['input']>
  /** The loans the SPV took out */
  loans?: InputMaybe<Array<UpdateSpvLoanInput>>
  /** Percentage of debt-service coverage ratio represented as a decimal */
  targetDSCR?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateSpvInput = {
  data: UpdateSpvDataInput
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}

export type UpdateSpvInvestmentInput = {
  /** Amount of money invested by the Investor in JPY */
  amount?: InputMaybe<Scalars['Float']['input']>
  /** Unique identifier for the investment */
  id: Scalars['ID']['input']
  /** Date the investment was made */
  investmentDate?: InputMaybe<Scalars['Time']['input']>
  /** Name of the investor that has made the investment */
  investorName?: InputMaybe<Scalars['String']['input']>
  /** Flag to indicate that the investment should be deleted during update operation */
  isDeleted: Scalars['Boolean']['input']
}

export type UpdateSpvLoanInput = {
  /** Amount of money being loaned in JPY */
  amount?: InputMaybe<Scalars['Float']['input']>
  /** Description of the loan */
  description?: InputMaybe<Scalars['String']['input']>
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['input']
  /** Interest rate of the given loan represented as a decimal */
  interestRate?: InputMaybe<Scalars['Float']['input']>
  /** Flag to indicate that the loan should be deleted during update operation */
  isDeleted: Scalars['Boolean']['input']
  /** Name of the Lender that has given the loan */
  lenderName?: InputMaybe<Scalars['String']['input']>
  /** Date when the loaned money amount is received */
  loanReceivedDate?: InputMaybe<Scalars['Time']['input']>
  /** Date when repayment of the loan ends */
  repaymentEndDate?: InputMaybe<Scalars['Time']['input']>
  /** Frequency the loan is repaid at */
  repaymentFrequency?: InputMaybe<RepaymentFrequency>
  /** Date when repayment of the loan begins */
  repaymentStartDate?: InputMaybe<Scalars['Time']['input']>
}

export type UpdateSpvPayload = {
  __typename?: 'UpdateSpvPayload'
  data?: Maybe<UpdateSpvData>
  errors?: Maybe<Array<UpdateSpvProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdateSpvProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceIsAlreadyInUseProblem
  | SpvInvestmentValidationProblem
  | SpvLoanValidationProblem
  | SpvResourceDoesNotExistProblem

export type UpdateUserAccountData = {
  __typename?: 'UpdateUserAccountData'
  record: UserAccount
  recordId: Scalars['ID']['output']
}

export type UpdateUserAccountDataInput = {
  /** Preferred unit to display electrical power values */
  electricalPowerUnit?: InputMaybe<UserElectricalPowerUnit>
  /** Preferred encoding to generate files with */
  fileEncodingFormat?: InputMaybe<UserFileEncodingFormat>
  /** First name */
  firstName?: InputMaybe<Scalars['String']['input']>
  /** Flag that indicates if the user is currently opted into receiving activity notifications via the platform/app */
  isOptedIntoActivityAppNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving activity notifications via email */
  isOptedIntoActivityEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving alert notifications via the platform/app */
  isOptedIntoAlertAppNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving alert notifications via email */
  isOptedIntoAlertEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving reminder notifications via the platform/app */
  isOptedIntoReminderAppNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Flag that indicates if the user is currently opted into receiving reminder notifications via email */
  isOptedIntoReminderEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>
  /** Preferred language */
  language?: InputMaybe<Language>
  /** Last name */
  lastName?: InputMaybe<Scalars['String']['input']>
  /** ID of the last used workspace */
  lastUsedWorkspaceId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateUserAccountInput = {
  data: UpdateUserAccountDataInput
  id: Scalars['ID']['input']
}

export type UpdateUserAccountPayload = {
  __typename?: 'UpdateUserAccountPayload'
  data?: Maybe<UpdateUserAccountData>
  errors?: Maybe<Array<UpdateUserAccountProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UpdateUserAccountProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type UpdateWorkspaceApiKeyData = {
  __typename?: 'UpdateWorkspaceApiKeyData'
  /** Workspace API key that was modified */
  record: WorkspaceApiKey
  /** ID of the modified workspace api key */
  recordId: Scalars['ID']['output']
}

export type UpdateWorkspaceApiKeyInput = {
  /** ID of the workspace api key to update */
  id: Scalars['ID']['input']
  /** Name of the API key */
  name?: InputMaybe<Scalars['String']['input']>
  /** Permissions granted to the api key */
  permissions?: InputMaybe<Array<WorkspaceApiKeyPermission>>
  /** ID of the workspace associated with the key */
  workspaceId: Scalars['ID']['input']
}

export type UpdateWorkspaceApiKeyPayload = {
  __typename?: 'UpdateWorkspaceApiKeyPayload'
  /** Updated workspace api key */
  data?: Maybe<UpdateWorkspaceApiKeyData>
  /** Any problems that occurred during the operation */
  errors?: Maybe<Array<UpdateWorkspaceApiKeyProblems>>
  /** Recursive query to allow data fetching in a single network trip */
  query?: Maybe<Query>
  /** Flag that indicates if the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

export type UpdateWorkspaceApiKeyProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceDoesNotExistProblem

export type UpdateWorkspaceData = {
  __typename?: 'UpdateWorkspaceData'
  /** Modified workspace */
  record: Workspace
  /** ID of the modified workspace */
  recordId: Scalars['ID']['output']
}

export type UpdateWorkspaceInput = {
  /** ID of the workspace to update */
  id: Scalars['ID']['input']
  /** New desired name of the workspace */
  name?: InputMaybe<Scalars['String']['input']>
  /** Method to generate OCCTO generation plans */
  occtoGenerationPlanGenerationMethod?: InputMaybe<OcctoGenerationPlanGenerationMethod>
  /** The sender details for OCCTO reporting */
  occtoSender?: InputMaybe<OcctoSubmitterDataInput>
  /** The submitter grid zone details for OCCTO reporting */
  occtoSubmitterGridZones?: InputMaybe<OcctoSubmitterGridZonesInput>
}

export type UpdateWorkspacePayload = {
  __typename?: 'UpdateWorkspacePayload'
  /** Modified workspace */
  data?: Maybe<UpdateWorkspaceData>
  /** Any problems that occurred during the operation */
  errors?: Maybe<Array<UpdateWorkspaceProblems>>
  /** Recursive query to allow data fetching in a single network trip */
  query?: Maybe<Query>
  /** Flag that indicates if the mutation was successful or not */
  success: Scalars['Boolean']['output']
}

export type UpdateWorkspaceProblems =
  | AuthorizationProblem
  | ResourceAlreadyExistsProblem
  | ResourceDoesNotExistProblem

export type UploadProfilePictureData = {
  __typename?: 'UploadProfilePictureData'
  /** User account record that was changed */
  record: UserAccount
  /** Id of the user account */
  recordId: Scalars['String']['output']
}

export type UploadProfilePictureInput = {
  /** Content type of the file. Currently only support image/jpeg */
  contentType: Scalars['String']['input']
  /** Profile pictrue data in base64 format */
  fileContent: Scalars['String']['input']
  /** ID of the user */
  id: Scalars['ID']['input']
}

export type UploadProfilePicturePayload = {
  __typename?: 'UploadProfilePicturePayload'
  data: UploadProfilePictureData
  errors?: Maybe<Array<UploadProfilePictureProblems>>
  query?: Maybe<Query>
  success: Scalars['Boolean']['output']
}

export type UploadProfilePictureProblems = AuthorizationProblem | ResourceDoesNotExistProblem

export type User = {
  __typename?: 'User'
  /** Time the user was created */
  createdAt: Scalars['Time']['output']
  /** Email address of the user */
  email: Scalars['String']['output']
  /** First name of the user */
  firstName: Scalars['String']['output']
  /** An unique, non-human readable id */
  id: Scalars['String']['output']
  /** Time the user was last active */
  lastActivityAt: Scalars['Time']['output']
  /** Last name of the user */
  lastName: Scalars['String']['output']
  /** User preferences such as language and other display options. */
  preferences?: Maybe<UserPreferences>
  /** An object containing varous urls for the user's profile picture */
  profilePicture?: Maybe<ProfilePicture>
  /** Role the user is assigned within the workspace */
  role?: Maybe<UserPermission>
  /** Time the user was last updated */
  updatedAt: Scalars['Time']['output']
  /** Username of the user */
  username?: Maybe<Scalars['String']['output']>
  /** Workspace the user is apart of */
  workspace: Workspace
}

export type UserAccount = {
  __typename?: 'UserAccount'
  /** Time the user was created */
  createdAt: Scalars['Time']['output']
  /** Email address of the user */
  email: Scalars['String']['output']
  /** First name of the user */
  firstName: Scalars['String']['output']
  /** An unique, non-human readable id */
  id: Scalars['ID']['output']
  /** Time the user was last active */
  lastActivityAt: Scalars['Time']['output']
  /** Last name of the user */
  lastName: Scalars['String']['output']
  /** ID of the workspace last accessed by the user */
  lastUsedWorkspaceId: Scalars['ID']['output']
  /** User preferences such as language and other display options. */
  preferences?: Maybe<UserPreferences>
  /** An object containing varous urls for the user's profile picture */
  profilePicture?: Maybe<ProfilePicture>
  /** Time the user was last updated */
  updatedAt: Scalars['Time']['output']
  /** Users belonging to this account */
  users: Array<User>
}

export type UserAccountMutations = {
  __typename?: 'UserAccountMutations'
  acceptWorkspaceInvite: AcceptWorkspaceInvitePayload
  removeProfilePicture: RemoveProfilePicturePayload
  update: UpdateUserAccountPayload
  uploadProfilePicture: UploadProfilePicturePayload
}

export type UserAccountMutationsAcceptWorkspaceInviteArgs = {
  input: AcceptWorkspaceInviteInput
}

export type UserAccountMutationsRemoveProfilePictureArgs = {
  input: RemoveProfilePictureInput
}

export type UserAccountMutationsUpdateArgs = {
  input: UpdateUserAccountInput
}

export type UserAccountMutationsUploadProfilePictureArgs = {
  input: UploadProfilePictureInput
}

export enum UserElectricalPowerUnit {
  Kilowatt = 'KILOWATT',
  Watt = 'WATT',
}

export enum UserFileEncodingFormat {
  ShiftJis = 'SHIFT_JIS',
  Utf8 = 'UTF8',
  Utf8WithBom = 'UTF8_WITH_BOM',
}

export type UserMutations = {
  __typename?: 'UserMutations'
  changePreferences: ChangeUserPreferencesPayload
  changeRole: ChangeUserRolePayload
  confirmCognitoEmailUpdate: ConfirmCognitoEmailUpdatePayload
  remove: RemoveUserPayload
  requestCognitoEmailUpdate: RequestCognitoEmailUpdatePayload
  sendLoginMagicLink: SendLoginMagicLinkPayload
}

export type UserMutationsChangePreferencesArgs = {
  input: ChangeUserPreferencesInput
}

export type UserMutationsChangeRoleArgs = {
  input: ChangeUserRoleInput
}

export type UserMutationsConfirmCognitoEmailUpdateArgs = {
  input?: InputMaybe<ConfirmCognitoEmailUpdateInput>
}

export type UserMutationsRemoveArgs = {
  input: RemoveUserInput
}

export type UserMutationsRequestCognitoEmailUpdateArgs = {
  input?: InputMaybe<RequestCognitoEmailUpdateInput>
}

export type UserMutationsSendLoginMagicLinkArgs = {
  input: SendLoginMagicLinkInput
}

export enum UserPermission {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  User = 'USER',
}

export type UserPreferences = {
  __typename?: 'UserPreferences'
  /** Preferred unit to display electrical power, currently supports kilowatts and watts */
  electricalPowerUnit: UserElectricalPowerUnit
  /** Preferred encoding format to use when generating csv/excel files */
  fileEncodingFormat: UserFileEncodingFormat
  /** Flag that indicates if the user is currently opted into receiving activity notifications via the platform/app */
  isOptedIntoActivityAppNotifications: Scalars['Boolean']['output']
  /** Flag that indicates if the user is currently opted into receiving activity notifications via email */
  isOptedIntoActivityEmailNotifications: Scalars['Boolean']['output']
  /** Flag that indicates if the user is currently opted into receiving alert notifications via the platform/app */
  isOptedIntoAlertAppNotifications: Scalars['Boolean']['output']
  /** Flag that indicates if the user is currently opted into receiving alert notifications via email */
  isOptedIntoAlertEmailNotifications: Scalars['Boolean']['output']
  /** Flag that indicates if the user is currently opted into receiving reminder notifications via the platform/app */
  isOptedIntoReminderAppNotifications: Scalars['Boolean']['output']
  /** Flag that indicates if the user is currently opted into receiving reminder notifications via email */
  isOptedIntoReminderEmailNotifications: Scalars['Boolean']['output']
  /** The preferred language, currently support English or Japanese */
  language: Language
}

export type ValidateInvitationInput = {
  id: Scalars['String']['input']
}

export type Workspace = {
  __typename?: 'Workspace'
  /** API keys generated for the workspace. Allows access to Tensor external API */
  apiKeys: Array<WorkspaceApiKey>
  /** Time the workspace was created */
  createdAt: Scalars['Time']['output']
  /** Unique non-human readable UUID */
  id: Scalars['ID']['output']
  /** Flag that indicates if a workspace is billable or is currently being billed */
  isBillable: Scalars['Boolean']['output']
  /** All members part of the workspace */
  members?: Maybe<Array<Maybe<User>>>
  /** Name of the workspace */
  name: Scalars['String']['output']
  /** Method to generate daily OCCTO generation plans */
  occtoGenerationPlanGenerationMethod: OcctoGenerationPlanGenerationMethod
  /** The sender details for OCCTO reporting */
  occtoSender: OcctoSubmitterData
  /** The submitter grid zone details for OCCTO reporting */
  occtoSubmitterGridZones: OcctoSubmitterGridZones
  /**
   * List of all grid zones that contain JEPX and OCCTO compliant assets. These
   * assets are considered ready for daily operations.
   */
  operationalGridZones: Array<GridZone>
  /** Three alphabet character prefix used for all readable IDs within the workspace */
  prefix: Scalars['String']['output']
  /** Time the workspace was last updated */
  updatedAt: Scalars['Time']['output']
}

export type WorkspaceAlreadyExistsProblem = ProblemInterface & {
  __typename?: 'WorkspaceAlreadyExistsProblem'
  /** [internal] Message that describes what went wrong */
  message: Scalars['String']['output']
  /** Type of workspace already exists problem */
  type: WorkspaceAlreadyExistsProblemType
}

export enum WorkspaceAlreadyExistsProblemType {
  Id = 'ID',
  Name = 'NAME',
}

export type WorkspaceApiKey = {
  __typename?: 'WorkspaceApiKey'
  /** Time the key was created at */
  createdAt: Scalars['Time']['output']
  /** Unique, non-human readable identifier */
  id: Scalars['ID']['output']
  /** Key value. Field will only be non-null when returned during key creation */
  key?: Maybe<Scalars['String']['output']>
  /** Time the key was last used. Field is null when the key is first generated */
  lastUsedAt?: Maybe<Scalars['Time']['output']>
  /** Name of the key */
  name: Scalars['String']['output']
  /** Permissions assigned to the API key */
  permissions: Array<WorkspaceApiKeyPermission>
}

export enum WorkspaceApiKeyPermission {
  AssetRead = 'ASSET_READ',
  GenerationForecastRead = 'GENERATION_FORECAST_READ',
  GenerationRead = 'GENERATION_READ',
  GenerationWrite = 'GENERATION_WRITE',
  PriceForecastRead = 'PRICE_FORECAST_READ',
}

export type WorkspaceInvitationIdProblem = ProblemInterface & {
  __typename?: 'WorkspaceInvitationIdProblem'
  /** [internal] Message that describes what went wrong */
  message: Scalars['String']['output']
}

export type WorkspaceMutations = {
  __typename?: 'WorkspaceMutations'
  /** Mutation that creates a workspace (to be replaced with v2) */
  create: CreateWorkspacePayload
  /** Mutation that creates a workspace */
  createV2: CreateWorkspacePayload
  /** Mutation that deletes a specific workspace api key */
  deleteApiKey: DeleteWorkspaceApiKeyPayload
  /** Mutation that generates a workspace api key */
  generateApiKey: GenerateWorkspaceApiKeyPayload
  /** Mutation that updates a workspace */
  update: UpdateWorkspacePayload
  /** Mutation that updates a specific workspace api key */
  updateApiKey: UpdateWorkspaceApiKeyPayload
}

export type WorkspaceMutationsCreateArgs = {
  input: CreateWorkspaceInput
}

export type WorkspaceMutationsCreateV2Args = {
  input: CreateWorkspaceInputV2
}

export type WorkspaceMutationsDeleteApiKeyArgs = {
  input: DeleteWorkspaceApiKeyInput
}

export type WorkspaceMutationsGenerateApiKeyArgs = {
  input: GenerateWorkspaceApiKeyInput
}

export type WorkspaceMutationsUpdateArgs = {
  input: UpdateWorkspaceInput
}

export type WorkspaceMutationsUpdateApiKeyArgs = {
  input: UpdateWorkspaceApiKeyInput
}

export type ZoneDates = {
  __typename?: 'ZoneDates'
  chb: Scalars['Time']['output']
  chg: Scalars['Time']['output']
  hkd: Scalars['Time']['output']
  hok: Scalars['Time']['output']
  kan: Scalars['Time']['output']
  kyu: Scalars['Time']['output']
  oki: Scalars['Time']['output']
  shi: Scalars['Time']['output']
  toh: Scalars['Time']['output']
  tok: Scalars['Time']['output']
}

export type ZonePrices = {
  __typename?: 'ZonePrices'
  /** Day-ahead price for Chubu utility grid zone in JPY/kWh */
  chb?: Maybe<Scalars['Float']['output']>
  /** Day-ahead price for Chugoku Denryoku utility grid zone in JPY/kWh */
  chg?: Maybe<Scalars['Float']['output']>
  /** Day-ahead price for Hokkaido utility grid zone in JPY/kWh */
  hkd?: Maybe<Scalars['Float']['output']>
  /** Day-ahead price for Hokuriku utility grid zone in JPY/kWh */
  hok?: Maybe<Scalars['Float']['output']>
  /** Day-ahead price for Kansai utility grid zone in JPY/kWh */
  kan?: Maybe<Scalars['Float']['output']>
  /** Day-ahead price for Kyushu utility grid zone in JPY/kWh */
  kyu?: Maybe<Scalars['Float']['output']>
  /** Day-ahead price for Okinawa utility grid zone in JPY/kWh */
  oki?: Maybe<Scalars['Float']['output']>
  /** Day-ahead price for Shikoku utility grid zone in JPY/kWh */
  shi?: Maybe<Scalars['Float']['output']>
  /** Day-ahead system price in JPY/kWh */
  sys?: Maybe<Scalars['Float']['output']>
  /** Day-ahead price for Tohoku utility grid zone in JPY/kWh */
  toh?: Maybe<Scalars['Float']['output']>
  /** Day-ahead price for Tokyo utility grid zone in JPY/kWh */
  tok?: Maybe<Scalars['Float']['output']>
}

export type SendInvitationOutput = {
  __typename?: 'sendInvitationOutput'
  email: Scalars['String']['output']
  error?: Maybe<Scalars['String']['output']>
  errorCode?: Maybe<InvitationErrorCode>
  succeed: Scalars['Boolean']['output']
}

export type SendInvitationsInput = {
  emails: Array<Scalars['String']['input']>
  language: Language
  organizationId: Scalars['ID']['input']
}

export type SendInvitationsOutput = {
  __typename?: 'sendInvitationsOutput'
  items: Array<SendInvitationOutput>
}

export type DayAheadMarketIntelQueryVariables = Exact<{
  actualsInput: MarketIntelligenceSeriesInput
  predictionsInput: MarketIntelligenceSeriesInput
}>

export type DayAheadMarketIntelQuery = {
  __typename?: 'Query'
  marketIntelligence: {
    __typename?: 'MarketIntelligence'
    dayAhead?: {
      __typename?: 'MarketIntelligenceDayAhead'
      maxDate?: any | null
      minDate?: any | null
      dayAheadPriceVolumeSeries: {
        __typename?: 'DayAheadSeries'
        updatedAt: any
        set: Array<{
          __typename?: 'DayAheadSeriesSet'
          t: any
          v: {
            __typename?: 'DayAheadPriceVolumes'
            vol: { __typename?: 'DayAheadVolumes'; da?: number | null; itd?: number | null }
            zone: {
              __typename?: 'ZonePrices'
              chg?: number | null
              chb?: number | null
              kyu?: number | null
              hkd?: number | null
              hok?: number | null
              tok?: number | null
              kan?: number | null
              toh?: number | null
              shi?: number | null
              oki?: number | null
              sys?: number | null
            }
          }
        }>
      }
    } | null
    priceForecast?: {
      __typename?: 'MarketIntelligencePriceForecast'
      maxDate?: any | null
      minDate?: any | null
      electricityMarketPriceForecastSeries: {
        __typename?: 'PriceForecastSeries'
        set: Array<{
          __typename?: 'PriceForecastSeriesSet'
          t: any
          v: {
            __typename?: 'ZonePrices'
            chg?: number | null
            chb?: number | null
            kyu?: number | null
            hkd?: number | null
            hok?: number | null
            tok?: number | null
            kan?: number | null
            toh?: number | null
            shi?: number | null
            oki?: number | null
            sys?: number | null
          }
        }>
      }
    } | null
  }
}

export type IntradayMarketIntelQueryVariables = Exact<{
  input: MarketIntelligenceSeriesInput
}>

export type IntradayMarketIntelQuery = {
  __typename?: 'Query'
  marketIntelligence: {
    __typename?: 'MarketIntelligence'
    intraday?: {
      __typename?: 'MarketIntelligenceIntraday'
      maxDate?: any | null
      minDate?: any | null
      priceVolumeSeries: {
        __typename?: 'IntradaySeries'
        updatedAt: any
        set: Array<{
          __typename?: 'IntradaySeriesSet'
          t: any
          v: {
            __typename?: 'IntradayVolumes'
            ap?: number | null
            cp?: number | null
            hp?: number | null
            lp?: number | null
            op?: number | null
            vol?: number | null
          }
        }>
      }
    } | null
  }
}

export type ImbalanceMarketIntelQueryVariables = Exact<{
  input: MarketIntelligenceSeriesInput
}>

export type ImbalanceMarketIntelQuery = {
  __typename?: 'Query'
  marketIntelligence: {
    __typename?: 'MarketIntelligence'
    imbalance?: {
      __typename?: 'MarketIntelligenceImbalance'
      imbalanceVolumesSeries: {
        __typename?: 'ImbalanceSeries'
        updatedAt: any
        set: Array<{
          __typename?: 'ImbalanceSeriesSet'
          t: any
          v: {
            __typename?: 'ImbalancePriceVolumes'
            vol?: number | null
            price: { __typename?: 'ImbalancePrices'; sur?: number | null; def?: number | null }
          }
        }>
      }
    } | null
  }
}

export type ConsumptionMarketIntelQueryVariables = Exact<{
  input: MarketIntelligenceSeriesInput
}>

export type ConsumptionMarketIntelQuery = {
  __typename?: 'Query'
  marketIntelligence: {
    __typename?: 'MarketIntelligence'
    electricityConsumption?: {
      __typename?: 'MarketIntelligenceElectricityConsumption'
      electricityConsumptionSeries: {
        __typename?: 'ElectricityConsumptionSeries'
        updatedAt: any
        set: Array<{
          __typename?: 'ElectricityConsumptionSeriesSet'
          t: any
          av: {
            __typename?: 'ElectricityConsumptionVolumes'
            dem?: number | null
            itc?: number | null
            phy?: number | null
            sol?: number | null
            win?: number | null
          }
          sv: {
            __typename?: 'ElectricityConsumptionVolumes'
            dem?: number | null
            itc?: number | null
            phy?: number | null
            sol?: number | null
            win?: number | null
          }
        }>
      }
    } | null
  }
}

export type GenerationMarketIntelQueryVariables = Exact<{
  input: MarketIntelligenceSeriesInput
}>

export type GenerationMarketIntelQuery = {
  __typename?: 'Query'
  marketIntelligence: {
    __typename?: 'MarketIntelligence'
    electricityGeneration?: {
      __typename?: 'MarketIntelligenceElectricityGeneration'
      electricityGenerationSeries: {
        __typename?: 'ElectricityGenerationSeries'
        updatedAt: any
        set: Array<{
          __typename?: 'ElectricityGenerationSeriesSet'
          t: any
          av: {
            __typename?: 'ElectricityGenerationVolumes'
            nuc?: number | null
            the?: number | null
            hyd?: number | null
            sol?: number | null
            win?: number | null
            bio?: number | null
            geo?: number | null
            phy?: number | null
            itc?: number | null
          }
          sv: {
            __typename?: 'ElectricityGenerationVolumes'
            nuc?: number | null
            the?: number | null
            hyd?: number | null
            sol?: number | null
            win?: number | null
            bio?: number | null
            geo?: number | null
            phy?: number | null
            itc?: number | null
          }
        }>
      }
    } | null
  }
}

export type CertificatesMarketIntelQueryVariables = Exact<{
  input: MarketIntelligenceSeriesInput
}>

export type CertificatesMarketIntelQuery = {
  __typename?: 'Query'
  marketIntelligence: {
    __typename?: 'MarketIntelligence'
    certificates?: {
      __typename?: 'MarketIntelligenceCertificates'
      nfcPriceVolumeSeries: {
        __typename?: 'NfcSeries'
        updatedAt: any
        nfn: Array<{
          __typename?: 'NfcSeriesSet'
          t: any
          v: {
            __typename?: 'NfcVolumes'
            hi?: number | null
            lo?: number | null
            avg?: number | null
            vol?: number | null
          }
        }>
        nfr: Array<{
          __typename?: 'NfcSeriesSet'
          t: any
          v: {
            __typename?: 'NfcVolumes'
            hi?: number | null
            lo?: number | null
            avg?: number | null
            vol?: number | null
          }
        }>
        fit: Array<{
          __typename?: 'NfcSeriesSet'
          t: any
          v: {
            __typename?: 'NfcVolumes'
            hi?: number | null
            lo?: number | null
            avg?: number | null
            vol?: number | null
          }
        }>
      }
    } | null
  }
}

export type ConsumptionMinAndMaxDatesQueryVariables = Exact<{ [key: string]: never }>

export type ConsumptionMinAndMaxDatesQuery = {
  __typename?: 'Query'
  marketIntelligence: {
    __typename?: 'MarketIntelligence'
    electricityConsumption?: {
      __typename?: 'MarketIntelligenceElectricityConsumption'
      maxDates: {
        __typename?: 'ZoneDates'
        chb: any
        chg: any
        hkd: any
        hok: any
        kyu: any
        kan: any
        oki: any
        shi: any
        toh: any
        tok: any
      }
      minDates: {
        __typename?: 'ZoneDates'
        chb: any
        chg: any
        hkd: any
        hok: any
        kyu: any
        kan: any
        oki: any
        shi: any
        toh: any
        tok: any
      }
    } | null
  }
}

export type GenerationMinAndMaxDatesQueryVariables = Exact<{ [key: string]: never }>

export type GenerationMinAndMaxDatesQuery = {
  __typename?: 'Query'
  marketIntelligence: {
    __typename?: 'MarketIntelligence'
    electricityGeneration?: {
      __typename?: 'MarketIntelligenceElectricityGeneration'
      maxDates: {
        __typename?: 'ZoneDates'
        chb: any
        chg: any
        hkd: any
        hok: any
        kyu: any
        kan: any
        oki: any
        shi: any
        toh: any
        tok: any
      }
      minDates: {
        __typename?: 'ZoneDates'
        chb: any
        chg: any
        hkd: any
        hok: any
        kyu: any
        kan: any
        oki: any
        shi: any
        toh: any
        tok: any
      }
    } | null
  }
}

export type BaseAssumptionFragment = {
  __typename?: 'AssumptionValues'
  year: number
  value: number
}

type ScenarioAssumptionBaseData_ScenarioCurtailment_Fragment = {
  __typename?: 'ScenarioCurtailment'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
}

type ScenarioAssumptionBaseData_ScenarioForecastMapeDayAhead_Fragment = {
  __typename?: 'ScenarioForecastMapeDayAhead'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
}

type ScenarioAssumptionBaseData_ScenarioForecastMapeIntraday_Fragment = {
  __typename?: 'ScenarioForecastMapeIntraday'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
}

type ScenarioAssumptionBaseData_ScenarioInflation_Fragment = {
  __typename?: 'ScenarioInflation'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
}

type ScenarioAssumptionBaseData_ScenarioPriceAverage_Fragment = {
  __typename?: 'ScenarioPriceAverage'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
}

type ScenarioAssumptionBaseData_ScenarioPriceVolatility_Fragment = {
  __typename?: 'ScenarioPriceVolatility'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
}

export type ScenarioAssumptionBaseDataFragment =
  | ScenarioAssumptionBaseData_ScenarioCurtailment_Fragment
  | ScenarioAssumptionBaseData_ScenarioForecastMapeDayAhead_Fragment
  | ScenarioAssumptionBaseData_ScenarioForecastMapeIntraday_Fragment
  | ScenarioAssumptionBaseData_ScenarioInflation_Fragment
  | ScenarioAssumptionBaseData_ScenarioPriceAverage_Fragment
  | ScenarioAssumptionBaseData_ScenarioPriceVolatility_Fragment

export type RegionalAssumptionsFragment = {
  __typename?: 'RegionalAssumptions'
  hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
}

export type RegionalAssumptionsWithSystemFragment = {
  __typename?: 'RegionalWithSystemAssumptions'
  system: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
}

type ScenarioAssumptionCrudData_ScenarioCurtailment_Fragment = {
  __typename?: 'ScenarioCurtailment'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

type ScenarioAssumptionCrudData_ScenarioForecastMapeDayAhead_Fragment = {
  __typename?: 'ScenarioForecastMapeDayAhead'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

type ScenarioAssumptionCrudData_ScenarioForecastMapeIntraday_Fragment = {
  __typename?: 'ScenarioForecastMapeIntraday'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

type ScenarioAssumptionCrudData_ScenarioInflation_Fragment = {
  __typename?: 'ScenarioInflation'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

type ScenarioAssumptionCrudData_ScenarioPriceAverage_Fragment = {
  __typename?: 'ScenarioPriceAverage'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

type ScenarioAssumptionCrudData_ScenarioPriceVolatility_Fragment = {
  __typename?: 'ScenarioPriceVolatility'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

export type ScenarioAssumptionCrudDataFragment =
  | ScenarioAssumptionCrudData_ScenarioCurtailment_Fragment
  | ScenarioAssumptionCrudData_ScenarioForecastMapeDayAhead_Fragment
  | ScenarioAssumptionCrudData_ScenarioForecastMapeIntraday_Fragment
  | ScenarioAssumptionCrudData_ScenarioInflation_Fragment
  | ScenarioAssumptionCrudData_ScenarioPriceAverage_Fragment
  | ScenarioAssumptionCrudData_ScenarioPriceVolatility_Fragment

export type InflationAssumptionCrudDataFragment = {
  __typename?: 'ScenarioInflation'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

export type DayAheadMapeAssumptionCrudDataFragment = {
  __typename?: 'ScenarioForecastMapeDayAhead'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

export type IntradayMapeAssumptionCrudDataFragment = {
  __typename?: 'ScenarioForecastMapeIntraday'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

export type PriceLevelAssumptionCrudDataFragment = {
  __typename?: 'ScenarioPriceAverage'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  assumptions: {
    __typename?: 'RegionalWithSystemAssumptions'
    system: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  }
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

export type PriceVolatilityAssumptionCrudDataFragment = {
  __typename?: 'ScenarioPriceVolatility'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  assumptions: {
    __typename?: 'RegionalWithSystemAssumptions'
    system: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  }
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

export type CurtailmentAssumptionCrudDataFragment = {
  __typename?: 'ScenarioCurtailment'
  id: string
  name: string
  isCustom: boolean
  isEditable: boolean
  isDeletable: boolean
  createdAt: any
  assumptions: {
    __typename?: 'RegionalAssumptions'
    hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
    okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
  }
  scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
}

type DeleteAssumptionErrors_AuthorizationProblem_Fragment = {
  __typename?: 'AuthorizationProblem'
  message: string
}

type DeleteAssumptionErrors_ReplacementDoesNotExistProblem_Fragment = {
  __typename?: 'ReplacementDoesNotExistProblem'
  message: string
}

type DeleteAssumptionErrors_ReplacementRequiredProblem_Fragment = {
  __typename?: 'ReplacementRequiredProblem'
  message: string
  recordId: string
  dependentIds?: Array<string> | null
}

type DeleteAssumptionErrors_ResourceDoesNotExistProblem_Fragment = {
  __typename?: 'ResourceDoesNotExistProblem'
  message: string
}

export type DeleteAssumptionErrorsFragment =
  | DeleteAssumptionErrors_AuthorizationProblem_Fragment
  | DeleteAssumptionErrors_ReplacementDoesNotExistProblem_Fragment
  | DeleteAssumptionErrors_ReplacementRequiredProblem_Fragment
  | DeleteAssumptionErrors_ResourceDoesNotExistProblem_Fragment

export type DeleteInflationDataFragment = {
  __typename?: 'DeleteInflationPayload'
  success: boolean
  errors?: Array<
    | { __typename?: 'AuthorizationProblem'; message: string }
    | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
    | {
        __typename?: 'ReplacementRequiredProblem'
        message: string
        recordId: string
        dependentIds?: Array<string> | null
      }
    | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
  > | null
  data?: {
    __typename?: 'DeleteInflationData'
    recordId: string
    record: {
      __typename?: 'ScenarioInflation'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  } | null
  query?: {
    __typename?: 'Query'
    scenarioAssumptions: {
      __typename?: 'ScenarioAssumptionsQuery'
      inflation: {
        __typename?: 'InflationList'
        records: Array<{
          __typename?: 'ScenarioInflation'
          id: string
          name: string
          isCustom: boolean
          isEditable: boolean
          isDeletable: boolean
          createdAt: any
        }>
      }
    }
  } | null
}

export type DeleteMapeDayAheadDataFragment = {
  __typename?: 'DeleteForecastMapeDayAheadPayload'
  success: boolean
  errors?: Array<
    | { __typename?: 'AuthorizationProblem'; message: string }
    | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
    | {
        __typename?: 'ReplacementRequiredProblem'
        message: string
        recordId: string
        dependentIds?: Array<string> | null
      }
    | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
  > | null
  data?: {
    __typename?: 'DeleteForecastMapeDayAheadData'
    recordId: string
    record: {
      __typename?: 'ScenarioForecastMapeDayAhead'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  } | null
  query?: {
    __typename?: 'Query'
    scenarioAssumptions: {
      __typename?: 'ScenarioAssumptionsQuery'
      forecastMapeDayAhead: {
        __typename?: 'ForecastMapeDayAheadList'
        records: Array<{
          __typename?: 'ScenarioForecastMapeDayAhead'
          id: string
          name: string
          isCustom: boolean
          isEditable: boolean
          isDeletable: boolean
          createdAt: any
        }>
      }
    }
  } | null
}

export type DeleteMapeIntradayDataFragment = {
  __typename?: 'DeleteForecastMapeIntradayPayload'
  success: boolean
  errors?: Array<
    | { __typename?: 'AuthorizationProblem'; message: string }
    | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
    | {
        __typename?: 'ReplacementRequiredProblem'
        message: string
        recordId: string
        dependentIds?: Array<string> | null
      }
    | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
  > | null
  data?: {
    __typename?: 'DeleteForecastMapeIntradayData'
    recordId: string
    record: {
      __typename?: 'ScenarioForecastMapeIntraday'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  } | null
  query?: {
    __typename?: 'Query'
    scenarioAssumptions: {
      __typename?: 'ScenarioAssumptionsQuery'
      forecastMapeIntraday: {
        __typename?: 'ForecastMapeIntradayList'
        records: Array<{
          __typename?: 'ScenarioForecastMapeIntraday'
          id: string
          name: string
          isCustom: boolean
          isEditable: boolean
          isDeletable: boolean
          createdAt: any
        }>
      }
    }
  } | null
}

export type DeletePriceLevelDataFragment = {
  __typename?: 'DeletePriceAveragePayload'
  success: boolean
  errors?: Array<
    | { __typename?: 'AuthorizationProblem'; message: string }
    | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
    | {
        __typename?: 'ReplacementRequiredProblem'
        message: string
        recordId: string
        dependentIds?: Array<string> | null
      }
    | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
  > | null
  data?: {
    __typename?: 'DeletePriceAverageData'
    recordId: string
    record: {
      __typename?: 'ScenarioPriceAverage'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  } | null
  query?: {
    __typename?: 'Query'
    scenarioAssumptions: {
      __typename?: 'ScenarioAssumptionsQuery'
      priceAverage: {
        __typename?: 'PriceAverageList'
        records: Array<{
          __typename?: 'ScenarioPriceAverage'
          id: string
          name: string
          isCustom: boolean
          isEditable: boolean
          isDeletable: boolean
          createdAt: any
        }>
      }
    }
  } | null
}

export type DeletePriceVolatilityDataFragment = {
  __typename?: 'DeletePriceVolatilityPayload'
  success: boolean
  errors?: Array<
    | { __typename?: 'AuthorizationProblem'; message: string }
    | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
    | {
        __typename?: 'ReplacementRequiredProblem'
        message: string
        recordId: string
        dependentIds?: Array<string> | null
      }
    | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
  > | null
  data?: {
    __typename?: 'DeletePriceVolatilityData'
    recordId: string
    record: {
      __typename?: 'ScenarioPriceVolatility'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  } | null
  query?: {
    __typename?: 'Query'
    scenarioAssumptions: {
      __typename?: 'ScenarioAssumptionsQuery'
      priceVolatility: {
        __typename?: 'PriceVolatilityList'
        records: Array<{
          __typename?: 'ScenarioPriceVolatility'
          id: string
          name: string
          isCustom: boolean
          isEditable: boolean
          isDeletable: boolean
          createdAt: any
        }>
      }
    }
  } | null
}

export type DeleteCurtailmentDataFragment = {
  __typename?: 'DeleteCurtailmentPayload'
  success: boolean
  errors?: Array<
    | { __typename?: 'AuthorizationProblem'; message: string }
    | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
    | {
        __typename?: 'ReplacementRequiredProblem'
        message: string
        recordId: string
        dependentIds?: Array<string> | null
      }
    | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
  > | null
  data?: {
    __typename?: 'DeleteCurtailmentData'
    recordId: string
    record: {
      __typename?: 'ScenarioCurtailment'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  } | null
  query?: {
    __typename?: 'Query'
    scenarioAssumptions: {
      __typename?: 'ScenarioAssumptionsQuery'
      curtailment: {
        __typename?: 'CurtailmentList'
        records: Array<{
          __typename?: 'ScenarioCurtailment'
          id: string
          name: string
          isCustom: boolean
          isEditable: boolean
          isDeletable: boolean
          createdAt: any
        }>
      }
    }
  } | null
}

export type InflationAssumptionOptionsQueryFragment = {
  __typename?: 'ScenarioAssumptionsQuery'
  inflation: {
    __typename?: 'InflationList'
    records: Array<{
      __typename?: 'ScenarioInflation'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
}

export type CurtailmentAssumptionOptionsQueryFragment = {
  __typename?: 'ScenarioAssumptionsQuery'
  curtailment: {
    __typename?: 'CurtailmentList'
    records: Array<{
      __typename?: 'ScenarioCurtailment'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
}

export type PriceAverageAssumptionOptionsQueryFragment = {
  __typename?: 'ScenarioAssumptionsQuery'
  priceAverage: {
    __typename?: 'PriceAverageList'
    records: Array<{
      __typename?: 'ScenarioPriceAverage'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
}

export type PriceVolatilityAssumptionOptionsQueryFragment = {
  __typename?: 'ScenarioAssumptionsQuery'
  priceVolatility: {
    __typename?: 'PriceVolatilityList'
    records: Array<{
      __typename?: 'ScenarioPriceVolatility'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
}

export type MapeDayAheadAssumptionOptionsQueryFragment = {
  __typename?: 'ScenarioAssumptionsQuery'
  forecastMapeDayAhead: {
    __typename?: 'ForecastMapeDayAheadList'
    records: Array<{
      __typename?: 'ScenarioForecastMapeDayAhead'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
}

export type MapeIntradayAssumptionOptionsQueryFragment = {
  __typename?: 'ScenarioAssumptionsQuery'
  forecastMapeIntraday: {
    __typename?: 'ForecastMapeIntradayList'
    records: Array<{
      __typename?: 'ScenarioForecastMapeIntraday'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
}

export type AssumptionOptionsBaseQueryFragment = {
  __typename?: 'ScenarioAssumptionsQuery'
  inflation: {
    __typename?: 'InflationList'
    records: Array<{
      __typename?: 'ScenarioInflation'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
  curtailment: {
    __typename?: 'CurtailmentList'
    records: Array<{
      __typename?: 'ScenarioCurtailment'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
  priceAverage: {
    __typename?: 'PriceAverageList'
    records: Array<{
      __typename?: 'ScenarioPriceAverage'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
  priceVolatility: {
    __typename?: 'PriceVolatilityList'
    records: Array<{
      __typename?: 'ScenarioPriceVolatility'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
  forecastMapeDayAhead: {
    __typename?: 'ForecastMapeDayAheadList'
    records: Array<{
      __typename?: 'ScenarioForecastMapeDayAhead'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
  forecastMapeIntraday: {
    __typename?: 'ForecastMapeIntradayList'
    records: Array<{
      __typename?: 'ScenarioForecastMapeIntraday'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }>
  }
}

export type AddInflationAssumptionMutationVariables = Exact<{
  input: CreateInflationInput
  queryInput: GetAssumptionListInput
}>

export type AddInflationAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    inflation: {
      __typename?: 'InflationMutations'
      create: {
        __typename?: 'CreateInflationPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        > | null
        data?: {
          __typename?: 'CreateInflationData'
          recordId: string
          record: {
            __typename?: 'ScenarioInflation'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            inflation: {
              __typename?: 'InflationList'
              records: Array<{
                __typename?: 'ScenarioInflation'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type AddMapeDayAheadAssumptionMutationVariables = Exact<{
  input: CreateForecastMapeDayAheadInput
  queryInput: GetAssumptionListInput
}>

export type AddMapeDayAheadAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    forecastMapeDayAhead: {
      __typename?: 'ForecastMapeDayAheadMutations'
      create: {
        __typename?: 'CreateForecastMapeDayAheadPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        > | null
        data?: {
          __typename?: 'CreateForecastMapeDayAheadData'
          recordId: string
          record: {
            __typename?: 'ScenarioForecastMapeDayAhead'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            forecastMapeDayAhead: {
              __typename?: 'ForecastMapeDayAheadList'
              records: Array<{
                __typename?: 'ScenarioForecastMapeDayAhead'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type AddMapeIntradayAssumptionMutationVariables = Exact<{
  input: CreateForecastMapeIntradayInput
  queryInput: GetAssumptionListInput
}>

export type AddMapeIntradayAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    forecastMapeIntraday: {
      __typename?: 'ForecastMapeIntradayMutations'
      create: {
        __typename?: 'CreateForecastMapeIntradayPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        > | null
        data?: {
          __typename?: 'CreateForecastMapeIntradayData'
          recordId: string
          record: {
            __typename?: 'ScenarioForecastMapeIntraday'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            forecastMapeIntraday: {
              __typename?: 'ForecastMapeIntradayList'
              records: Array<{
                __typename?: 'ScenarioForecastMapeIntraday'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type AddPriceLevelAssumptionMutationVariables = Exact<{
  input: CreatePriceAverageInput
  queryInput: GetAssumptionListInput
}>

export type AddPriceLevelAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    priceAverage: {
      __typename?: 'PriceAverageMutations'
      create: {
        __typename?: 'CreatePriceAveragePayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        > | null
        data?: {
          __typename?: 'CreatePriceAverageData'
          recordId: string
          record: {
            __typename?: 'ScenarioPriceAverage'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: {
              __typename?: 'RegionalWithSystemAssumptions'
              system: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            }
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            priceAverage: {
              __typename?: 'PriceAverageList'
              records: Array<{
                __typename?: 'ScenarioPriceAverage'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type AddPriceVolatilityAssumptionMutationVariables = Exact<{
  input: CreatePriceVolatilityInput
  queryInput: GetAssumptionListInput
}>

export type AddPriceVolatilityAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    priceVolatility: {
      __typename?: 'PriceVolatilityMutations'
      create: {
        __typename?: 'CreatePriceVolatilityPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        > | null
        data?: {
          __typename?: 'CreatePriceVolatilityData'
          recordId: string
          record: {
            __typename?: 'ScenarioPriceVolatility'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: {
              __typename?: 'RegionalWithSystemAssumptions'
              system: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            }
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            priceVolatility: {
              __typename?: 'PriceVolatilityList'
              records: Array<{
                __typename?: 'ScenarioPriceVolatility'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type AddCurtailmentAssumptionMutationVariables = Exact<{
  input: CreateCurtailmentInput
  queryInput: GetAssumptionListInput
}>

export type AddCurtailmentAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    curtailment: {
      __typename?: 'CurtailmentMutations'
      create: {
        __typename?: 'CreateCurtailmentPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        > | null
        data?: {
          __typename?: 'CreateCurtailmentData'
          recordId: string
          record: {
            __typename?: 'ScenarioCurtailment'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: {
              __typename?: 'RegionalAssumptions'
              hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            }
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            curtailment: {
              __typename?: 'CurtailmentList'
              records: Array<{
                __typename?: 'ScenarioCurtailment'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type UpdateInflationAssumptionMutationVariables = Exact<{
  input: UpdateInflationInput
  queryInput: GetAssumptionListInput
}>

export type UpdateInflationAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    inflation: {
      __typename?: 'InflationMutations'
      update: {
        __typename?: 'UpdateInflationPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
          | { __typename?: 'ResourceCannotBeModifiedProblem'; message: string }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'UpdateInflationData'
          recordId: string
          record: {
            __typename?: 'ScenarioInflation'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            inflation: {
              __typename?: 'InflationList'
              records: Array<{
                __typename?: 'ScenarioInflation'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type UpdateMapeDayAheadAssumptionMutationVariables = Exact<{
  input: UpdateForecastMapeDayAheadInput
  queryInput: GetAssumptionListInput
}>

export type UpdateMapeDayAheadAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    forecastMapeDayAhead: {
      __typename?: 'ForecastMapeDayAheadMutations'
      update: {
        __typename?: 'UpdateForecastMapeDayAheadPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
          | { __typename?: 'ResourceCannotBeModifiedProblem'; message: string }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'UpdateForecastMapeDayAheadData'
          recordId: string
          record: {
            __typename?: 'ScenarioForecastMapeDayAhead'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            forecastMapeDayAhead: {
              __typename?: 'ForecastMapeDayAheadList'
              records: Array<{
                __typename?: 'ScenarioForecastMapeDayAhead'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type UpdateMapeIntradayAssumptionMutationVariables = Exact<{
  input: UpdateForecastMapeIntradayInput
  queryInput: GetAssumptionListInput
}>

export type UpdateMapeIntradayAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    forecastMapeIntraday: {
      __typename?: 'ForecastMapeIntradayMutations'
      update: {
        __typename?: 'UpdateForecastMapeIntradayPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
          | { __typename?: 'ResourceCannotBeModifiedProblem'; message: string }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'UpdateForecastMapeIntradayData'
          recordId: string
          record: {
            __typename?: 'ScenarioForecastMapeIntraday'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            forecastMapeIntraday: {
              __typename?: 'ForecastMapeIntradayList'
              records: Array<{
                __typename?: 'ScenarioForecastMapeIntraday'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type UpdatePriceLevelAssumptionMutationVariables = Exact<{
  input: UpdatePriceAverageInput
  queryInput: GetAssumptionListInput
}>

export type UpdatePriceLevelAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    priceAverage: {
      __typename?: 'PriceAverageMutations'
      update: {
        __typename?: 'UpdatePriceAveragePayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
          | { __typename?: 'ResourceCannotBeModifiedProblem'; message: string }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'UpdatePriceAverageData'
          recordId: string
          record: {
            __typename?: 'ScenarioPriceAverage'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: {
              __typename?: 'RegionalWithSystemAssumptions'
              system: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            }
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            priceAverage: {
              __typename?: 'PriceAverageList'
              records: Array<{
                __typename?: 'ScenarioPriceAverage'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type UpdatePriceVolatilityAssumptionMutationVariables = Exact<{
  input: UpdatePriceVolatilityInput
  queryInput: GetAssumptionListInput
}>

export type UpdatePriceVolatilityAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    priceVolatility: {
      __typename?: 'PriceVolatilityMutations'
      update: {
        __typename?: 'UpdatePriceVolatilityPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
          | { __typename?: 'ResourceCannotBeModifiedProblem'; message: string }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'UpdatePriceVolatilityData'
          recordId: string
          record: {
            __typename?: 'ScenarioPriceVolatility'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: {
              __typename?: 'RegionalWithSystemAssumptions'
              system: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            }
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            priceVolatility: {
              __typename?: 'PriceVolatilityList'
              records: Array<{
                __typename?: 'ScenarioPriceVolatility'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type UpdateCurtailmentAssumptionMutationVariables = Exact<{
  input: UpdateCurtailmentInput
  queryInput: GetAssumptionListInput
}>

export type UpdateCurtailmentAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    curtailment: {
      __typename?: 'CurtailmentMutations'
      update: {
        __typename?: 'UpdateCurtailmentPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
          | { __typename?: 'ResourceCannotBeModifiedProblem'; message: string }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'UpdateCurtailmentData'
          recordId: string
          record: {
            __typename?: 'ScenarioCurtailment'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            assumptions: {
              __typename?: 'RegionalAssumptions'
              hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
              okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
            }
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            curtailment: {
              __typename?: 'CurtailmentList'
              records: Array<{
                __typename?: 'ScenarioCurtailment'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteInflationAssumptionMutationVariables = Exact<{
  input: DeleteAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteInflationAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    inflation: {
      __typename?: 'InflationMutations'
      delete: {
        __typename?: 'DeleteInflationPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeleteInflationData'
          recordId: string
          record: {
            __typename?: 'ScenarioInflation'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            inflation: {
              __typename?: 'InflationList'
              records: Array<{
                __typename?: 'ScenarioInflation'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteAndReplaceInflationAssumptionMutationVariables = Exact<{
  input: DeleteAndReplaceAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteAndReplaceInflationAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    inflation: {
      __typename?: 'InflationMutations'
      deleteAndReplace: {
        __typename?: 'DeleteInflationPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeleteInflationData'
          recordId: string
          record: {
            __typename?: 'ScenarioInflation'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            inflation: {
              __typename?: 'InflationList'
              records: Array<{
                __typename?: 'ScenarioInflation'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteMapeDayAheadAssumptionMutationVariables = Exact<{
  input: DeleteAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteMapeDayAheadAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    forecastMapeDayAhead: {
      __typename?: 'ForecastMapeDayAheadMutations'
      delete: {
        __typename?: 'DeleteForecastMapeDayAheadPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeleteForecastMapeDayAheadData'
          recordId: string
          record: {
            __typename?: 'ScenarioForecastMapeDayAhead'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            forecastMapeDayAhead: {
              __typename?: 'ForecastMapeDayAheadList'
              records: Array<{
                __typename?: 'ScenarioForecastMapeDayAhead'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteAndReplaceMapeDayAheadAssumptionMutationVariables = Exact<{
  input: DeleteAndReplaceAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteAndReplaceMapeDayAheadAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    forecastMapeDayAhead: {
      __typename?: 'ForecastMapeDayAheadMutations'
      deleteAndReplace: {
        __typename?: 'DeleteForecastMapeDayAheadPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeleteForecastMapeDayAheadData'
          recordId: string
          record: {
            __typename?: 'ScenarioForecastMapeDayAhead'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            forecastMapeDayAhead: {
              __typename?: 'ForecastMapeDayAheadList'
              records: Array<{
                __typename?: 'ScenarioForecastMapeDayAhead'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteMapeIntradayAssumptionMutationVariables = Exact<{
  input: DeleteAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteMapeIntradayAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    forecastMapeIntraday: {
      __typename?: 'ForecastMapeIntradayMutations'
      delete: {
        __typename?: 'DeleteForecastMapeIntradayPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeleteForecastMapeIntradayData'
          recordId: string
          record: {
            __typename?: 'ScenarioForecastMapeIntraday'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            forecastMapeIntraday: {
              __typename?: 'ForecastMapeIntradayList'
              records: Array<{
                __typename?: 'ScenarioForecastMapeIntraday'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteAndReplaceMapeIntradayAssumptionMutationVariables = Exact<{
  input: DeleteAndReplaceAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteAndReplaceMapeIntradayAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    forecastMapeIntraday: {
      __typename?: 'ForecastMapeIntradayMutations'
      deleteAndReplace: {
        __typename?: 'DeleteForecastMapeIntradayPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeleteForecastMapeIntradayData'
          recordId: string
          record: {
            __typename?: 'ScenarioForecastMapeIntraday'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            forecastMapeIntraday: {
              __typename?: 'ForecastMapeIntradayList'
              records: Array<{
                __typename?: 'ScenarioForecastMapeIntraday'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeletePriceLevelAssumptionMutationVariables = Exact<{
  input: DeleteAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeletePriceLevelAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    priceAverage: {
      __typename?: 'PriceAverageMutations'
      delete: {
        __typename?: 'DeletePriceAveragePayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeletePriceAverageData'
          recordId: string
          record: {
            __typename?: 'ScenarioPriceAverage'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            priceAverage: {
              __typename?: 'PriceAverageList'
              records: Array<{
                __typename?: 'ScenarioPriceAverage'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteAndReplacePriceLevelAssumptionMutationVariables = Exact<{
  input: DeleteAndReplaceAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteAndReplacePriceLevelAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    priceAverage: {
      __typename?: 'PriceAverageMutations'
      deleteAndReplace: {
        __typename?: 'DeletePriceAveragePayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeletePriceAverageData'
          recordId: string
          record: {
            __typename?: 'ScenarioPriceAverage'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            priceAverage: {
              __typename?: 'PriceAverageList'
              records: Array<{
                __typename?: 'ScenarioPriceAverage'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeletePriceVolatilityAssumptionMutationVariables = Exact<{
  input: DeleteAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeletePriceVolatilityAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    priceVolatility: {
      __typename?: 'PriceVolatilityMutations'
      delete: {
        __typename?: 'DeletePriceVolatilityPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeletePriceVolatilityData'
          recordId: string
          record: {
            __typename?: 'ScenarioPriceVolatility'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            priceVolatility: {
              __typename?: 'PriceVolatilityList'
              records: Array<{
                __typename?: 'ScenarioPriceVolatility'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteAndReplacePriceVolatilityAssumptionMutationVariables = Exact<{
  input: DeleteAndReplaceAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteAndReplacePriceVolatilityAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    priceVolatility: {
      __typename?: 'PriceVolatilityMutations'
      deleteAndReplace: {
        __typename?: 'DeletePriceVolatilityPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeletePriceVolatilityData'
          recordId: string
          record: {
            __typename?: 'ScenarioPriceVolatility'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            priceVolatility: {
              __typename?: 'PriceVolatilityList'
              records: Array<{
                __typename?: 'ScenarioPriceVolatility'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteCurtailmentAssumptionMutationVariables = Exact<{
  input: DeleteAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteCurtailmentAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    curtailment: {
      __typename?: 'CurtailmentMutations'
      delete: {
        __typename?: 'DeleteCurtailmentPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeleteCurtailmentData'
          recordId: string
          record: {
            __typename?: 'ScenarioCurtailment'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            curtailment: {
              __typename?: 'CurtailmentList'
              records: Array<{
                __typename?: 'ScenarioCurtailment'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type DeleteAndReplaceCurtailmentAssumptionMutationVariables = Exact<{
  input: DeleteAndReplaceAssumptionInput
  queryInput: GetAssumptionListInput
}>

export type DeleteAndReplaceCurtailmentAssumptionMutation = {
  __typename?: 'Mutation'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionMutations'
    curtailment: {
      __typename?: 'CurtailmentMutations'
      deleteAndReplace: {
        __typename?: 'DeleteCurtailmentPayload'
        success: boolean
        errors?: Array<
          | { __typename?: 'AuthorizationProblem'; message: string }
          | { __typename?: 'ReplacementDoesNotExistProblem'; message: string }
          | {
              __typename?: 'ReplacementRequiredProblem'
              message: string
              recordId: string
              dependentIds?: Array<string> | null
            }
          | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        > | null
        data?: {
          __typename?: 'DeleteCurtailmentData'
          recordId: string
          record: {
            __typename?: 'ScenarioCurtailment'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
            scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
          }
        } | null
        query?: {
          __typename?: 'Query'
          scenarioAssumptions: {
            __typename?: 'ScenarioAssumptionsQuery'
            curtailment: {
              __typename?: 'CurtailmentList'
              records: Array<{
                __typename?: 'ScenarioCurtailment'
                id: string
                name: string
                isCustom: boolean
                isEditable: boolean
                isDeletable: boolean
                createdAt: any
              }>
            }
          }
        } | null
      }
    }
  }
}

export type InflationAssumptionQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type InflationAssumptionQuery = {
  __typename?: 'Query'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionQuery'
    inflation: {
      __typename?: 'ScenarioInflation'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  }
}

export type CurtailmentAssumptionQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type CurtailmentAssumptionQuery = {
  __typename?: 'Query'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionQuery'
    curtailment: {
      __typename?: 'ScenarioCurtailment'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      assumptions: {
        __typename?: 'RegionalAssumptions'
        hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
      }
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  }
}

export type PriceLevelAssumptionQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type PriceLevelAssumptionQuery = {
  __typename?: 'Query'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionQuery'
    priceAverage: {
      __typename?: 'ScenarioPriceAverage'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      assumptions: {
        __typename?: 'RegionalWithSystemAssumptions'
        system: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
      }
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  }
}

export type PriceVolatilityAssumptionQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type PriceVolatilityAssumptionQuery = {
  __typename?: 'Query'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionQuery'
    priceVolatility: {
      __typename?: 'ScenarioPriceVolatility'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      assumptions: {
        __typename?: 'RegionalWithSystemAssumptions'
        system: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        hokkaido: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        tohoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        tokyo: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        chubu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        hokuriku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        kansai: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        chugoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        shikoku: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        kyushu: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
        okinawa: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
      }
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  }
}

export type DayAheadMapeAssumptionQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type DayAheadMapeAssumptionQuery = {
  __typename?: 'Query'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionQuery'
    forecastMapeDayAhead: {
      __typename?: 'ScenarioForecastMapeDayAhead'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  }
}

export type IntradayMapeAssumptionQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type IntradayMapeAssumptionQuery = {
  __typename?: 'Query'
  scenarioAssumption: {
    __typename?: 'ScenarioAssumptionQuery'
    forecastMapeIntraday: {
      __typename?: 'ScenarioForecastMapeIntraday'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
      assumptions: Array<{ __typename?: 'AssumptionValues'; year: number; value: number }>
      scenarios: Array<{ __typename?: 'MacroScenario'; id: string }>
    }
  }
}

export type InflationAssumptionsQueryVariables = Exact<{
  input: GetAssumptionListInput
}>

export type InflationAssumptionsQuery = {
  __typename?: 'Query'
  scenarioAssumptions: {
    __typename?: 'ScenarioAssumptionsQuery'
    inflation: {
      __typename?: 'InflationList'
      records: Array<{
        __typename?: 'ScenarioInflation'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
  }
}

export type CurtailmentAssumptionsQueryVariables = Exact<{
  input: GetAssumptionListInput
}>

export type CurtailmentAssumptionsQuery = {
  __typename?: 'Query'
  scenarioAssumptions: {
    __typename?: 'ScenarioAssumptionsQuery'
    curtailment: {
      __typename?: 'CurtailmentList'
      records: Array<{
        __typename?: 'ScenarioCurtailment'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
  }
}

export type PriceLevelAssumptionsQueryVariables = Exact<{
  input: GetAssumptionListInput
}>

export type PriceLevelAssumptionsQuery = {
  __typename?: 'Query'
  scenarioAssumptions: {
    __typename?: 'ScenarioAssumptionsQuery'
    priceAverage: {
      __typename?: 'PriceAverageList'
      records: Array<{
        __typename?: 'ScenarioPriceAverage'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
  }
}

export type PriceVolatilityAssumptionsQueryVariables = Exact<{
  input: GetAssumptionListInput
}>

export type PriceVolatilityAssumptionsQuery = {
  __typename?: 'Query'
  scenarioAssumptions: {
    __typename?: 'ScenarioAssumptionsQuery'
    priceVolatility: {
      __typename?: 'PriceVolatilityList'
      records: Array<{
        __typename?: 'ScenarioPriceVolatility'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
  }
}

export type DayAheadMapeAssumptionsQueryVariables = Exact<{
  input: GetAssumptionListInput
}>

export type DayAheadMapeAssumptionsQuery = {
  __typename?: 'Query'
  scenarioAssumptions: {
    __typename?: 'ScenarioAssumptionsQuery'
    forecastMapeDayAhead: {
      __typename?: 'ForecastMapeDayAheadList'
      records: Array<{
        __typename?: 'ScenarioForecastMapeDayAhead'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
  }
}

export type IntradayMapeAssumptionsQueryVariables = Exact<{
  input: GetAssumptionListInput
}>

export type IntradayMapeAssumptionsQuery = {
  __typename?: 'Query'
  scenarioAssumptions: {
    __typename?: 'ScenarioAssumptionsQuery'
    forecastMapeIntraday: {
      __typename?: 'ForecastMapeIntradayList'
      records: Array<{
        __typename?: 'ScenarioForecastMapeIntraday'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
  }
}

export type AssumptionsOptionsQueryVariables = Exact<{
  queryInput: GetAssumptionListInput
}>

export type AssumptionsOptionsQuery = {
  __typename?: 'Query'
  scenarioAssumptions: {
    __typename?: 'ScenarioAssumptionsQuery'
    inflation: {
      __typename?: 'InflationList'
      records: Array<{
        __typename?: 'ScenarioInflation'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
    curtailment: {
      __typename?: 'CurtailmentList'
      records: Array<{
        __typename?: 'ScenarioCurtailment'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
    priceAverage: {
      __typename?: 'PriceAverageList'
      records: Array<{
        __typename?: 'ScenarioPriceAverage'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
    priceVolatility: {
      __typename?: 'PriceVolatilityList'
      records: Array<{
        __typename?: 'ScenarioPriceVolatility'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
    forecastMapeDayAhead: {
      __typename?: 'ForecastMapeDayAheadList'
      records: Array<{
        __typename?: 'ScenarioForecastMapeDayAhead'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
    forecastMapeIntraday: {
      __typename?: 'ForecastMapeIntradayList'
      records: Array<{
        __typename?: 'ScenarioForecastMapeIntraday'
        id: string
        name: string
        isCustom: boolean
        isEditable: boolean
        isDeletable: boolean
        createdAt: any
      }>
    }
  }
}

export type SendLoginMagicLinkMutationVariables = Exact<{
  input: SendLoginMagicLinkInput
}>

export type SendLoginMagicLinkMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'UserMutations'
    sendLoginMagicLink: {
      __typename?: 'SendLoginMagicLinkPayload'
      success: boolean
      errors?: Array<{ __typename?: 'LoginWithMagicLinkProblem'; message: string }> | null
    }
  }
}

export type InvitationQueryVariables = Exact<{
  input: InvitationInput
}>

export type InvitationQuery = {
  __typename?: 'Query'
  invitation?: {
    __typename?: 'Invitation'
    email?: string | null
    id: string
    status: InvitationStatus
    type: InvitationType
    used: boolean
    workspaceName?: string | null
  } | null
}

export type OcctoPlanFragmentFragment = {
  __typename?: 'OcctoReportingZonePlan'
  totalVolume: number
  plan: {
    __typename?: 'SingleSeries'
    updatedAt?: any | null
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
}

export type OcctoReportingZoneDataFragment = {
  __typename?: 'OcctoReportingZone'
  date: any
  status: OcctoReportingZoneStatus
  totalAssets: number
  balancingGroups: Array<{
    __typename?: 'BalancingGroup'
    name: string
    id: string
    forecasts: {
      __typename?: 'BalancingGroupForecasts'
      generationPlan?: {
        __typename?: 'BalancingGroupGenerationPlan'
        totalAssets: number
        totalGeneration: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
    }
  }>
  generationPlan: {
    __typename?: 'OcctoReportingZonePlan'
    totalVolume: number
    plan: {
      __typename?: 'SingleSeries'
      updatedAt?: any | null
      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
    }
  }
  procurementPlan: {
    __typename?: 'OcctoReportingZonePlan'
    totalVolume: number
    plan: {
      __typename?: 'SingleSeries'
      updatedAt?: any | null
      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
    }
  }
  salesPlan: {
    __typename?: 'OcctoReportingZonePlan'
    totalVolume: number
    plan: {
      __typename?: 'SingleSeries'
      updatedAt?: any | null
      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
    }
  }
}

export type OcctoDayAheadPlanRecordFragment = {
  __typename?: 'OcctoDayAheadPlan'
  date: any
  id: string
  hasPlan: boolean
  updatedAt?: any | null
  zones: {
    __typename?: 'OcctoReportingZones'
    chubu?: {
      __typename?: 'OcctoReportingZone'
      date: any
      status: OcctoReportingZoneStatus
      totalAssets: number
      balancingGroups: Array<{
        __typename?: 'BalancingGroup'
        name: string
        id: string
        forecasts: {
          __typename?: 'BalancingGroupForecasts'
          generationPlan?: {
            __typename?: 'BalancingGroupGenerationPlan'
            totalAssets: number
            totalGeneration: number
            plan: {
              __typename?: 'SingleSeries'
              updatedAt?: any | null
              set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
            }
          } | null
        }
      }>
      generationPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      procurementPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      salesPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
    } | null
    chugoku?: {
      __typename?: 'OcctoReportingZone'
      date: any
      status: OcctoReportingZoneStatus
      totalAssets: number
      balancingGroups: Array<{
        __typename?: 'BalancingGroup'
        name: string
        id: string
        forecasts: {
          __typename?: 'BalancingGroupForecasts'
          generationPlan?: {
            __typename?: 'BalancingGroupGenerationPlan'
            totalAssets: number
            totalGeneration: number
            plan: {
              __typename?: 'SingleSeries'
              updatedAt?: any | null
              set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
            }
          } | null
        }
      }>
      generationPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      procurementPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      salesPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
    } | null
    hokkaido?: {
      __typename?: 'OcctoReportingZone'
      date: any
      status: OcctoReportingZoneStatus
      totalAssets: number
      balancingGroups: Array<{
        __typename?: 'BalancingGroup'
        name: string
        id: string
        forecasts: {
          __typename?: 'BalancingGroupForecasts'
          generationPlan?: {
            __typename?: 'BalancingGroupGenerationPlan'
            totalAssets: number
            totalGeneration: number
            plan: {
              __typename?: 'SingleSeries'
              updatedAt?: any | null
              set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
            }
          } | null
        }
      }>
      generationPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      procurementPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      salesPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
    } | null
    hokuriku?: {
      __typename?: 'OcctoReportingZone'
      date: any
      status: OcctoReportingZoneStatus
      totalAssets: number
      balancingGroups: Array<{
        __typename?: 'BalancingGroup'
        name: string
        id: string
        forecasts: {
          __typename?: 'BalancingGroupForecasts'
          generationPlan?: {
            __typename?: 'BalancingGroupGenerationPlan'
            totalAssets: number
            totalGeneration: number
            plan: {
              __typename?: 'SingleSeries'
              updatedAt?: any | null
              set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
            }
          } | null
        }
      }>
      generationPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      procurementPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      salesPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
    } | null
    kansai?: {
      __typename?: 'OcctoReportingZone'
      date: any
      status: OcctoReportingZoneStatus
      totalAssets: number
      balancingGroups: Array<{
        __typename?: 'BalancingGroup'
        name: string
        id: string
        forecasts: {
          __typename?: 'BalancingGroupForecasts'
          generationPlan?: {
            __typename?: 'BalancingGroupGenerationPlan'
            totalAssets: number
            totalGeneration: number
            plan: {
              __typename?: 'SingleSeries'
              updatedAt?: any | null
              set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
            }
          } | null
        }
      }>
      generationPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      procurementPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      salesPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
    } | null
    kyushu?: {
      __typename?: 'OcctoReportingZone'
      date: any
      status: OcctoReportingZoneStatus
      totalAssets: number
      balancingGroups: Array<{
        __typename?: 'BalancingGroup'
        name: string
        id: string
        forecasts: {
          __typename?: 'BalancingGroupForecasts'
          generationPlan?: {
            __typename?: 'BalancingGroupGenerationPlan'
            totalAssets: number
            totalGeneration: number
            plan: {
              __typename?: 'SingleSeries'
              updatedAt?: any | null
              set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
            }
          } | null
        }
      }>
      generationPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      procurementPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      salesPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
    } | null
    shikoku?: {
      __typename?: 'OcctoReportingZone'
      date: any
      status: OcctoReportingZoneStatus
      totalAssets: number
      balancingGroups: Array<{
        __typename?: 'BalancingGroup'
        name: string
        id: string
        forecasts: {
          __typename?: 'BalancingGroupForecasts'
          generationPlan?: {
            __typename?: 'BalancingGroupGenerationPlan'
            totalAssets: number
            totalGeneration: number
            plan: {
              __typename?: 'SingleSeries'
              updatedAt?: any | null
              set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
            }
          } | null
        }
      }>
      generationPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      procurementPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      salesPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
    } | null
    tohoku?: {
      __typename?: 'OcctoReportingZone'
      date: any
      status: OcctoReportingZoneStatus
      totalAssets: number
      balancingGroups: Array<{
        __typename?: 'BalancingGroup'
        name: string
        id: string
        forecasts: {
          __typename?: 'BalancingGroupForecasts'
          generationPlan?: {
            __typename?: 'BalancingGroupGenerationPlan'
            totalAssets: number
            totalGeneration: number
            plan: {
              __typename?: 'SingleSeries'
              updatedAt?: any | null
              set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
            }
          } | null
        }
      }>
      generationPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      procurementPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      salesPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
    } | null
    tokyo?: {
      __typename?: 'OcctoReportingZone'
      date: any
      status: OcctoReportingZoneStatus
      totalAssets: number
      balancingGroups: Array<{
        __typename?: 'BalancingGroup'
        name: string
        id: string
        forecasts: {
          __typename?: 'BalancingGroupForecasts'
          generationPlan?: {
            __typename?: 'BalancingGroupGenerationPlan'
            totalAssets: number
            totalGeneration: number
            plan: {
              __typename?: 'SingleSeries'
              updatedAt?: any | null
              set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
            }
          } | null
        }
      }>
      generationPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      procurementPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
      salesPlan: {
        __typename?: 'OcctoReportingZonePlan'
        totalVolume: number
        plan: {
          __typename?: 'SingleSeries'
          updatedAt?: any | null
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      }
    } | null
  }
}

export type SubmitOcctoPlanMutationVariables = Exact<{
  input: SubmitOcctoPlanInput
  queryInput: OcctoDayAheadPlanInput
  generationPlanInput: BalancingGroupGenerationPlanInput
}>

export type SubmitOcctoPlanMutation = {
  __typename?: 'Mutation'
  occtoReporting: {
    __typename?: 'OcctoReportingMutations'
    submitPlan: {
      __typename?: 'SubmitOcctoPlanPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        occtoDayAheadPlan: {
          __typename?: 'OcctoDayAheadPlan'
          date: any
          id: string
          hasPlan: boolean
          updatedAt?: any | null
          zones: {
            __typename?: 'OcctoReportingZones'
            chubu?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            chugoku?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            hokkaido?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            hokuriku?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            kansai?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            kyushu?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            shikoku?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            tohoku?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            tokyo?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
          }
        }
      } | null
    }
  }
}

export type UnSubmitOcctoPlanMutationVariables = Exact<{
  input: UnsubmitOcctoPlanInput
  queryInput: OcctoDayAheadPlanInput
  generationPlanInput: BalancingGroupGenerationPlanInput
}>

export type UnSubmitOcctoPlanMutation = {
  __typename?: 'Mutation'
  occtoReporting: {
    __typename?: 'OcctoReportingMutations'
    unsubmitPlan: {
      __typename?: 'UnsubmitOcctoPlanPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        occtoDayAheadPlan: {
          __typename?: 'OcctoDayAheadPlan'
          date: any
          id: string
          hasPlan: boolean
          updatedAt?: any | null
          zones: {
            __typename?: 'OcctoReportingZones'
            chubu?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            chugoku?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            hokkaido?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            hokuriku?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            kansai?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            kyushu?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            shikoku?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            tohoku?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
            tokyo?: {
              __typename?: 'OcctoReportingZone'
              date: any
              status: OcctoReportingZoneStatus
              totalAssets: number
              balancingGroups: Array<{
                __typename?: 'BalancingGroup'
                name: string
                id: string
                forecasts: {
                  __typename?: 'BalancingGroupForecasts'
                  generationPlan?: {
                    __typename?: 'BalancingGroupGenerationPlan'
                    totalAssets: number
                    totalGeneration: number
                    plan: {
                      __typename?: 'SingleSeries'
                      updatedAt?: any | null
                      set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                    }
                  } | null
                }
              }>
              generationPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              procurementPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
              salesPlan: {
                __typename?: 'OcctoReportingZonePlan'
                totalVolume: number
                plan: {
                  __typename?: 'SingleSeries'
                  updatedAt?: any | null
                  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
                }
              }
            } | null
          }
        }
      } | null
    }
  }
}

export type GenerateOcctoDayAheadPlanUrlMutationVariables = Exact<{
  input: GenerateOcctoPlanUrlInput
}>

export type GenerateOcctoDayAheadPlanUrlMutation = {
  __typename?: 'Mutation'
  occtoReporting: {
    __typename?: 'OcctoReportingMutations'
    generatePlanUrl: {
      __typename?: 'GenerateOcctoPlanUrlPayload'
      data: string
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | {
            __typename?: 'GenerateOcctoPlanUrlApiProblem'
            message: string
            type: GenerateOcctoPlanUrlApiProblemType
          }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type GenerateLongTermOcctoPlanUrlMutationVariables = Exact<{
  input: GenerateOcctoLongTermPlanUrlInput
}>

export type GenerateLongTermOcctoPlanUrlMutation = {
  __typename?: 'Mutation'
  occtoReporting: {
    __typename?: 'OcctoReportingMutations'
    generateLongTermPlanUrl: {
      __typename?: 'GenerateOcctoLongTermPlanUrlPayload'
      data?: string | null
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | {
            __typename?: 'GenerateOcctoPlanUrlApiProblem'
            message: string
            type: GenerateOcctoPlanUrlApiProblemType
          }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type OcctoDayAheadPlanQueryVariables = Exact<{
  input: OcctoDayAheadPlanInput
  generationPlanInput: BalancingGroupGenerationPlanInput
  workspaceId: Scalars['ID']['input']
}>

export type OcctoDayAheadPlanQuery = {
  __typename?: 'Query'
  occtoDayAheadPlan: {
    __typename?: 'OcctoDayAheadPlan'
    date: any
    id: string
    hasPlan: boolean
    updatedAt?: any | null
    zones: {
      __typename?: 'OcctoReportingZones'
      chubu?: {
        __typename?: 'OcctoReportingZone'
        date: any
        status: OcctoReportingZoneStatus
        totalAssets: number
        balancingGroups: Array<{
          __typename?: 'BalancingGroup'
          name: string
          id: string
          forecasts: {
            __typename?: 'BalancingGroupForecasts'
            generationPlan?: {
              __typename?: 'BalancingGroupGenerationPlan'
              totalAssets: number
              totalGeneration: number
              plan: {
                __typename?: 'SingleSeries'
                updatedAt?: any | null
                set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
            } | null
          }
        }>
        generationPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        procurementPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        salesPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      } | null
      chugoku?: {
        __typename?: 'OcctoReportingZone'
        date: any
        status: OcctoReportingZoneStatus
        totalAssets: number
        balancingGroups: Array<{
          __typename?: 'BalancingGroup'
          name: string
          id: string
          forecasts: {
            __typename?: 'BalancingGroupForecasts'
            generationPlan?: {
              __typename?: 'BalancingGroupGenerationPlan'
              totalAssets: number
              totalGeneration: number
              plan: {
                __typename?: 'SingleSeries'
                updatedAt?: any | null
                set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
            } | null
          }
        }>
        generationPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        procurementPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        salesPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      } | null
      hokkaido?: {
        __typename?: 'OcctoReportingZone'
        date: any
        status: OcctoReportingZoneStatus
        totalAssets: number
        balancingGroups: Array<{
          __typename?: 'BalancingGroup'
          name: string
          id: string
          forecasts: {
            __typename?: 'BalancingGroupForecasts'
            generationPlan?: {
              __typename?: 'BalancingGroupGenerationPlan'
              totalAssets: number
              totalGeneration: number
              plan: {
                __typename?: 'SingleSeries'
                updatedAt?: any | null
                set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
            } | null
          }
        }>
        generationPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        procurementPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        salesPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      } | null
      hokuriku?: {
        __typename?: 'OcctoReportingZone'
        date: any
        status: OcctoReportingZoneStatus
        totalAssets: number
        balancingGroups: Array<{
          __typename?: 'BalancingGroup'
          name: string
          id: string
          forecasts: {
            __typename?: 'BalancingGroupForecasts'
            generationPlan?: {
              __typename?: 'BalancingGroupGenerationPlan'
              totalAssets: number
              totalGeneration: number
              plan: {
                __typename?: 'SingleSeries'
                updatedAt?: any | null
                set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
            } | null
          }
        }>
        generationPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        procurementPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        salesPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      } | null
      kansai?: {
        __typename?: 'OcctoReportingZone'
        date: any
        status: OcctoReportingZoneStatus
        totalAssets: number
        balancingGroups: Array<{
          __typename?: 'BalancingGroup'
          name: string
          id: string
          forecasts: {
            __typename?: 'BalancingGroupForecasts'
            generationPlan?: {
              __typename?: 'BalancingGroupGenerationPlan'
              totalAssets: number
              totalGeneration: number
              plan: {
                __typename?: 'SingleSeries'
                updatedAt?: any | null
                set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
            } | null
          }
        }>
        generationPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        procurementPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        salesPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      } | null
      kyushu?: {
        __typename?: 'OcctoReportingZone'
        date: any
        status: OcctoReportingZoneStatus
        totalAssets: number
        balancingGroups: Array<{
          __typename?: 'BalancingGroup'
          name: string
          id: string
          forecasts: {
            __typename?: 'BalancingGroupForecasts'
            generationPlan?: {
              __typename?: 'BalancingGroupGenerationPlan'
              totalAssets: number
              totalGeneration: number
              plan: {
                __typename?: 'SingleSeries'
                updatedAt?: any | null
                set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
            } | null
          }
        }>
        generationPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        procurementPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        salesPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      } | null
      shikoku?: {
        __typename?: 'OcctoReportingZone'
        date: any
        status: OcctoReportingZoneStatus
        totalAssets: number
        balancingGroups: Array<{
          __typename?: 'BalancingGroup'
          name: string
          id: string
          forecasts: {
            __typename?: 'BalancingGroupForecasts'
            generationPlan?: {
              __typename?: 'BalancingGroupGenerationPlan'
              totalAssets: number
              totalGeneration: number
              plan: {
                __typename?: 'SingleSeries'
                updatedAt?: any | null
                set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
            } | null
          }
        }>
        generationPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        procurementPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        salesPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      } | null
      tohoku?: {
        __typename?: 'OcctoReportingZone'
        date: any
        status: OcctoReportingZoneStatus
        totalAssets: number
        balancingGroups: Array<{
          __typename?: 'BalancingGroup'
          name: string
          id: string
          forecasts: {
            __typename?: 'BalancingGroupForecasts'
            generationPlan?: {
              __typename?: 'BalancingGroupGenerationPlan'
              totalAssets: number
              totalGeneration: number
              plan: {
                __typename?: 'SingleSeries'
                updatedAt?: any | null
                set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
            } | null
          }
        }>
        generationPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        procurementPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        salesPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      } | null
      tokyo?: {
        __typename?: 'OcctoReportingZone'
        date: any
        status: OcctoReportingZoneStatus
        totalAssets: number
        balancingGroups: Array<{
          __typename?: 'BalancingGroup'
          name: string
          id: string
          forecasts: {
            __typename?: 'BalancingGroupForecasts'
            generationPlan?: {
              __typename?: 'BalancingGroupGenerationPlan'
              totalAssets: number
              totalGeneration: number
              plan: {
                __typename?: 'SingleSeries'
                updatedAt?: any | null
                set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
            } | null
          }
        }>
        generationPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        procurementPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        salesPlan: {
          __typename?: 'OcctoReportingZonePlan'
          totalVolume: number
          plan: {
            __typename?: 'SingleSeries'
            updatedAt?: any | null
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      } | null
    }
  }
  workspace?: {
    __typename?: 'Workspace'
    id: string
    isBillable: boolean
    operationalGridZones: Array<GridZone>
    occtoGenerationPlanGenerationMethod: OcctoGenerationPlanGenerationMethod
    occtoSender: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    occtoSubmitterGridZones: {
      __typename?: 'OcctoSubmitterGridZones'
      chubu: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      chugoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      hokkaido: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      hokuriku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      kansai: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      kyushu: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      shikoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      tohoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      tokyo: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    }
  } | null
}

export type OcctoWorkspaceSettingsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
}>

export type OcctoWorkspaceSettingsQuery = {
  __typename?: 'Query'
  workspace?: {
    __typename?: 'Workspace'
    id: string
    isBillable: boolean
    operationalGridZones: Array<GridZone>
    occtoGenerationPlanGenerationMethod: OcctoGenerationPlanGenerationMethod
    occtoSender: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    occtoSubmitterGridZones: {
      __typename?: 'OcctoSubmitterGridZones'
      chubu: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      chugoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      hokkaido: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      hokuriku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      kansai: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      kyushu: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      shikoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      tohoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      tokyo: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    }
  } | null
}

export type OcctoLongTermWeeklyPlanQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
  startDate: Scalars['Time']['input']
  gridZone: GridZone
  updateDate?: InputMaybe<Scalars['Time']['input']>
}>

export type OcctoLongTermWeeklyPlanQuery = {
  __typename?: 'Query'
  balancingGroups: Array<{
    __typename?: 'BalancingGroup'
    id: string
    name: string
    forecasts: {
      __typename?: 'BalancingGroupForecasts'
      occtoWeeklyGenerationPlan?: {
        __typename?: 'BalancingGroupOcctoWeeklyGenerationPlan'
        totalAssets: number
        plan: Array<{
          __typename?: 'BalancingGroupOcctoWeeklyGenerationPlanRecord'
          high: number
          low: number
          datetime: any
        }>
      } | null
    }
  }>
}

export type OcctoLongTermMonthlyPlanQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
  startDate: Scalars['Time']['input']
  gridZone: GridZone
}>

export type OcctoLongTermMonthlyPlanQuery = {
  __typename?: 'Query'
  balancingGroups: Array<{
    __typename?: 'BalancingGroup'
    id: string
    name: string
    forecasts: {
      __typename?: 'BalancingGroupForecasts'
      occtoMonthlyGenerationPlan?: {
        __typename?: 'BalancingGroupOcctoMonthlyGenerationPlan'
        totalAssets: number
        plan: Array<{
          __typename?: 'BalancingGroupOcctoMonthlyGenerationPlanRecord'
          high: number
          low: number
          timeSlot: {
            __typename?: 'OcctoMonthlyPlanTimeSlot'
            isWeekend: boolean
            weekNumberInMonth: number
            datetime: any
          }
        }>
      } | null
    }
  }>
}

export type OcctoLongTermYearlyPlanQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
  startDate: Scalars['Time']['input']
  gridZone: GridZone
}>

export type OcctoLongTermYearlyPlanQuery = {
  __typename?: 'Query'
  balancingGroups: Array<{
    __typename?: 'BalancingGroup'
    id: string
    name: string
    forecasts: {
      __typename?: 'BalancingGroupForecasts'
      occtoYearlyGenerationPlan?: {
        __typename?: 'BalancingGroupGenerationPlan'
        totalAssets: number
        plan: {
          __typename?: 'SingleSeries'
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
    }
  }>
}

export type SolarAssetCustomOptionsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type SolarAssetCustomOptionsQuery = {
  __typename?: 'Query'
  developerList: {
    __typename?: 'ThirdPartyCompanyList'
    records: Array<{
      __typename?: 'ThirdPartyCompany'
      id: string
      name: string
      createdAt: any
      type: ThirdPartyCompanyType
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
  epcCompanyList: {
    __typename?: 'ThirdPartyCompanyList'
    records: Array<{
      __typename?: 'ThirdPartyCompany'
      id: string
      name: string
      createdAt: any
      type: ThirdPartyCompanyType
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
  omProviderList: {
    __typename?: 'ThirdPartyCompanyList'
    records: Array<{
      __typename?: 'ThirdPartyCompany'
      id: string
      name: string
      createdAt: any
      type: ThirdPartyCompanyType
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
  scenarioList: {
    __typename?: 'ScenarioList'
    records: Array<{
      __typename?: 'MacroScenario'
      id: string
      readableId: string
      name: string
      description: string
      isDefault: boolean
      createdAt: any
      updatedAt: any
    }>
  }
  ppaList: {
    __typename?: 'PpaList'
    records: Array<{ __typename?: 'PpaX'; id: string; name: string }>
  }
}

export type SetupUserProfileMutationVariables = Exact<{
  input: UpdateUserAccountInput
}>

export type SetupUserProfileMutation = {
  __typename?: 'Mutation'
  userAccount: {
    __typename?: 'UserAccountMutations'
    update: {
      __typename?: 'UpdateUserAccountPayload'
      data?: {
        __typename?: 'UpdateUserAccountData'
        recordId: string
        record: {
          __typename?: 'UserAccount'
          id: string
          email: string
          firstName: string
          lastName: string
          createdAt: any
          updatedAt: any
          lastActivityAt: any
          preferences?: {
            __typename?: 'UserPreferences'
            electricalPowerUnit: UserElectricalPowerUnit
          } | null
        }
      } | null
    }
  }
}

export type CompleteUserProfileMutationVariables = Exact<{
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  id: Scalars['ID']['input']
}>

export type CompleteUserProfileMutation = {
  __typename?: 'Mutation'
  userAccount: {
    __typename?: 'UserAccountMutations'
    update: {
      __typename?: 'UpdateUserAccountPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      data?: {
        __typename?: 'UpdateUserAccountData'
        recordId: string
        record: {
          __typename?: 'UserAccount'
          id: string
          email: string
          firstName: string
          lastName: string
          createdAt: any
          updatedAt: any
          lastActivityAt: any
          preferences?: {
            __typename?: 'UserPreferences'
            electricalPowerUnit: UserElectricalPowerUnit
          } | null
        }
      } | null
    }
  }
}

export type ForecastsQueryVariables = Exact<{
  input: ElectricityGenerationForecastsInput
  filter: ElectricityGenerationForecastsFilter
  workspaceId: Scalars['ID']['input']
}>

export type ForecastsQuery = {
  __typename?: 'Query'
  forecasts: {
    __typename?: 'ElectricityGenerationForecasts'
    updatedAt?: any | null
    records: Array<{
      __typename?: 'ElectricityGenerationForecast'
      createdAt: any
      id: string
      balancingGroup: { __typename?: 'BalancingGroup'; id: string; name: string }
    }>
  }
  balancingGroupList: {
    __typename?: 'BalancingGroupList'
    records: Array<{
      __typename?: 'BalancingGroup'
      id: string
      name: string
      balancingGroupCode: string
    }>
  }
  solarAssetList: {
    __typename?: 'SolarAssetList'
    records: Array<{ __typename?: 'SolarAsset'; id: string; name: string }>
  }
}

export type CustomForecastsQueryVariables = Exact<{
  input: CustomElectricityGenerationForecastInput
}>

export type CustomForecastsQuery = {
  __typename?: 'Query'
  customForecast: {
    __typename?: 'CustomElectricityGenerationForecast'
    assets: Array<{ __typename?: 'SolarAsset'; id: string; name: string }>
    rows: Array<{
      __typename?: 'CustomElectricityGenerationForecastRow'
      datetime: any
      total: number
      values: Array<{
        __typename?: 'CustomElectricityGenerationForecastRowValue'
        assetId: string
        value?: number | null
      }>
    }>
  }
}

export type UserCoreDataFragment = {
  __typename?: 'User'
  id: string
  firstName: string
  lastName: string
  profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
  workspace: { __typename?: 'Workspace'; id: string }
}

export type UserDataFragment = {
  __typename?: 'User'
  email: string
  username?: string | null
  role?: UserPermission | null
  createdAt: any
  updatedAt: any
  lastActivityAt: any
  id: string
  firstName: string
  lastName: string
  profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
  workspace: { __typename?: 'Workspace'; id: string }
}

export type UserAccountDataFragment = {
  __typename?: 'UpdateUserAccountPayload'
  data?: {
    __typename?: 'UpdateUserAccountData'
    recordId: string
    record: {
      __typename?: 'UserAccount'
      id: string
      email: string
      firstName: string
      lastName: string
      createdAt: any
      updatedAt: any
      lastActivityAt: any
      preferences?: {
        __typename?: 'UserPreferences'
        electricalPowerUnit: UserElectricalPowerUnit
      } | null
    }
  } | null
}

export type CurrentUserAccountDataFragment = {
  __typename?: 'UserAccount'
  id: string
  email: string
  firstName: string
  lastName: string
  createdAt: any
  updatedAt: any
  lastActivityAt: any
  lastUsedWorkspaceId: string
  preferences?: {
    __typename?: 'UserPreferences'
    electricalPowerUnit: UserElectricalPowerUnit
    fileEncodingFormat: UserFileEncodingFormat
    language: Language
  } | null
  profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
  users: Array<{
    __typename?: 'User'
    id: string
    username?: string | null
    role?: UserPermission | null
    workspace: { __typename?: 'Workspace'; id: string; name: string; isBillable: boolean }
  }>
}

export type OrganizationCoreDataFragment = { __typename?: 'Organization'; id: string; name: string }

export type BenchmarkDataFragment = {
  __typename?: 'BenchmarkIndicator'
  value: number
  benchmark: number
}

export type CertificateValuesFragment = {
  __typename?: 'NfcSeriesSet'
  t: any
  v: {
    __typename?: 'NfcVolumes'
    hi?: number | null
    lo?: number | null
    avg?: number | null
    vol?: number | null
  }
}

export type ElectricityConsumptionEntriesFragment = {
  __typename?: 'ElectricityConsumptionVolumes'
  dem?: number | null
  itc?: number | null
  phy?: number | null
  sol?: number | null
  win?: number | null
}

export type ElectricityGenerationEntriesFragment = {
  __typename?: 'ElectricityGenerationVolumes'
  nuc?: number | null
  the?: number | null
  hyd?: number | null
  sol?: number | null
  win?: number | null
  bio?: number | null
  geo?: number | null
  phy?: number | null
  itc?: number | null
}

export type ZoneDatesEntriesFragment = {
  __typename?: 'ZoneDates'
  chb: any
  chg: any
  hkd: any
  hok: any
  kyu: any
  kan: any
  oki: any
  shi: any
  toh: any
  tok: any
}

export type SingleSeriesSetFragment = {
  __typename?: 'SingleSeries'
  set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
}

export type PaginationMetadataFragment = {
  __typename?: 'PaginationInfo'
  hasNextPage: boolean
  hasPreviousPage: boolean
  isEmpty: boolean
  page: number
  perPage: number
  totalPages: number
  totalRecords: number
}

export type HighPerformanceNumberSeriesDataFragment = {
  __typename?: 'HighPerformanceNumberSeries'
  data: Array<number>
  endTime: any
  startTime: any
  interval: number
}

export type HighPerformanceNullableNumberSeriesDataFragment = {
  __typename?: 'HighPerformanceNullableNumberSeries'
  data: Array<number | null>
  endTime: any
  startTime: any
  interval: number
}

export type SendInvitationsMutationVariables = Exact<{
  input: SendInvitationsInput
}>

export type SendInvitationsMutation = {
  __typename?: 'Mutation'
  sendInvitationsV2: {
    __typename?: 'sendInvitationsOutput'
    items: Array<{
      __typename?: 'sendInvitationOutput'
      succeed: boolean
      email: string
      error?: string | null
      errorCode?: InvitationErrorCode | null
    }>
  }
}

export type NotificationActorDataFragment = {
  __typename?: 'Actor'
  firstName: string
  lastName: string
  id: string
  email: string
  workspace?: { __typename?: 'Workspace'; id: string } | null
  profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
}

type NotificationData_NotificationJepxOpenedPosition_Fragment = {
  __typename: 'NotificationJepxOpenedPosition'
  category: NotificationCategory
  createdAt: any
  id: string
  status: NotificationStatus
  updatedAt: any
  metadata: { __typename?: 'NotificationJepxOpenedPositionMetadata'; date: any; gridZone: GridZone }
  actor: {
    __typename?: 'Actor'
    firstName: string
    lastName: string
    id: string
    email: string
    workspace?: { __typename?: 'Workspace'; id: string } | null
    profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
  }
}

type NotificationData_NotificationJepxTradeConfirmationReminder_Fragment = {
  __typename: 'NotificationJepxTradeConfirmationReminder'
  category: NotificationCategory
  createdAt: any
  id: string
  status: NotificationStatus
  updatedAt: any
  metadata: {
    __typename?: 'NotificationJepxTradeConfirmationReminderMetadata'
    gridZone: GridZone
    date: any
  }
}

type NotificationData_NotificationJepxTradeConfirmed_Fragment = {
  __typename: 'NotificationJepxTradeConfirmed'
  category: NotificationCategory
  createdAt: any
  id: string
  status: NotificationStatus
  updatedAt: any
  metadata: { __typename?: 'NotificationJepxTradeConfirmedMetadata'; gridZone: GridZone; date: any }
  actor: {
    __typename?: 'Actor'
    firstName: string
    lastName: string
    id: string
    email: string
    workspace?: { __typename?: 'Workspace'; id: string } | null
    profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
  }
}

type NotificationData_NotificationJepxTradingReminder_Fragment = {
  __typename: 'NotificationJepxTradingReminder'
  category: NotificationCategory
  createdAt: any
  id: string
  status: NotificationStatus
  updatedAt: any
  metadata: {
    __typename?: 'NotificationJepxTradingReminderMetadata'
    gridZone: GridZone
    date: any
  }
}

type NotificationData_NotificationTsoPlanSubmissionReminder_Fragment = {
  __typename: 'NotificationTSOPlanSubmissionReminder'
  category: NotificationCategory
  createdAt: any
  id: string
  status: NotificationStatus
  updatedAt: any
  metadata: {
    __typename?: 'NotificationTSOPlanSubmissionReminderMetadata'
    gridZone: GridZone
    date: any
  }
}

type NotificationData_NotificationTsoPlanSubmitted_Fragment = {
  __typename: 'NotificationTSOPlanSubmitted'
  category: NotificationCategory
  createdAt: any
  id: string
  status: NotificationStatus
  updatedAt: any
  metadata: { __typename?: 'NotificationTSOPlanSubmittedMetadata'; gridZone: GridZone; date: any }
  actor: {
    __typename?: 'Actor'
    firstName: string
    lastName: string
    id: string
    email: string
    workspace?: { __typename?: 'Workspace'; id: string } | null
    profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
  }
}

export type NotificationDataFragment =
  | NotificationData_NotificationJepxOpenedPosition_Fragment
  | NotificationData_NotificationJepxTradeConfirmationReminder_Fragment
  | NotificationData_NotificationJepxTradeConfirmed_Fragment
  | NotificationData_NotificationJepxTradingReminder_Fragment
  | NotificationData_NotificationTsoPlanSubmissionReminder_Fragment
  | NotificationData_NotificationTsoPlanSubmitted_Fragment

export type NotificationMarkAsReadMutationVariables = Exact<{
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}>

export type NotificationMarkAsReadMutation = {
  __typename?: 'Mutation'
  notification: {
    __typename?: 'NotificationMutations'
    markAsRead: {
      __typename?: 'MarkNotificationAsReadPayload'
      success: boolean
      data: {
        __typename?: 'MarkNotificationAsReadData'
        recordId: string
        record:
          | {
              __typename: 'NotificationJepxOpenedPosition'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationJepxOpenedPositionMetadata'
                date: any
                gridZone: GridZone
              }
              actor: {
                __typename?: 'Actor'
                firstName: string
                lastName: string
                id: string
                email: string
                workspace?: { __typename?: 'Workspace'; id: string } | null
                profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
              }
            }
          | {
              __typename: 'NotificationJepxTradeConfirmationReminder'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationJepxTradeConfirmationReminderMetadata'
                gridZone: GridZone
                date: any
              }
            }
          | {
              __typename: 'NotificationJepxTradeConfirmed'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationJepxTradeConfirmedMetadata'
                gridZone: GridZone
                date: any
              }
              actor: {
                __typename?: 'Actor'
                firstName: string
                lastName: string
                id: string
                email: string
                workspace?: { __typename?: 'Workspace'; id: string } | null
                profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
              }
            }
          | {
              __typename: 'NotificationJepxTradingReminder'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationJepxTradingReminderMetadata'
                gridZone: GridZone
                date: any
              }
            }
          | {
              __typename: 'NotificationTSOPlanSubmissionReminder'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationTSOPlanSubmissionReminderMetadata'
                gridZone: GridZone
                date: any
              }
            }
          | {
              __typename: 'NotificationTSOPlanSubmitted'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationTSOPlanSubmittedMetadata'
                gridZone: GridZone
                date: any
              }
              actor: {
                __typename?: 'Actor'
                firstName: string
                lastName: string
                id: string
                email: string
                workspace?: { __typename?: 'Workspace'; id: string } | null
                profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
              }
            }
      }
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        notifications: { __typename?: 'NotificationList'; unreadCount: number }
      } | null
    }
  }
}

export type NotificationMarkAsUnreadMutationVariables = Exact<{
  id: Scalars['ID']['input']
  workspaceId: Scalars['ID']['input']
}>

export type NotificationMarkAsUnreadMutation = {
  __typename?: 'Mutation'
  notification: {
    __typename?: 'NotificationMutations'
    markAsUnread: {
      __typename?: 'MarkNotificationAsUnreadPayload'
      success: boolean
      data: {
        __typename?: 'MarkNotificationAsUnreadData'
        recordId: string
        record:
          | {
              __typename: 'NotificationJepxOpenedPosition'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationJepxOpenedPositionMetadata'
                date: any
                gridZone: GridZone
              }
              actor: {
                __typename?: 'Actor'
                firstName: string
                lastName: string
                id: string
                email: string
                workspace?: { __typename?: 'Workspace'; id: string } | null
                profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
              }
            }
          | {
              __typename: 'NotificationJepxTradeConfirmationReminder'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationJepxTradeConfirmationReminderMetadata'
                gridZone: GridZone
                date: any
              }
            }
          | {
              __typename: 'NotificationJepxTradeConfirmed'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationJepxTradeConfirmedMetadata'
                gridZone: GridZone
                date: any
              }
              actor: {
                __typename?: 'Actor'
                firstName: string
                lastName: string
                id: string
                email: string
                workspace?: { __typename?: 'Workspace'; id: string } | null
                profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
              }
            }
          | {
              __typename: 'NotificationJepxTradingReminder'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationJepxTradingReminderMetadata'
                gridZone: GridZone
                date: any
              }
            }
          | {
              __typename: 'NotificationTSOPlanSubmissionReminder'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationTSOPlanSubmissionReminderMetadata'
                gridZone: GridZone
                date: any
              }
            }
          | {
              __typename: 'NotificationTSOPlanSubmitted'
              category: NotificationCategory
              createdAt: any
              id: string
              status: NotificationStatus
              updatedAt: any
              metadata: {
                __typename?: 'NotificationTSOPlanSubmittedMetadata'
                gridZone: GridZone
                date: any
              }
              actor: {
                __typename?: 'Actor'
                firstName: string
                lastName: string
                id: string
                email: string
                workspace?: { __typename?: 'Workspace'; id: string } | null
                profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
              }
            }
      }
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        notifications: { __typename?: 'NotificationList'; unreadCount: number }
      } | null
    }
  }
}

export type NotificationMarkAllAsReadMutationVariables = Exact<{
  input: MarkAllNotificationsAsReadInput
  queryInput: NotificationListInput
  queryFilter?: InputMaybe<NotificationListFilter>
}>

export type NotificationMarkAllAsReadMutation = {
  __typename?: 'Mutation'
  notification: {
    __typename?: 'NotificationMutations'
    markAllAsRead: {
      __typename?: 'MarkAllNotificationsAsReadPayload'
      success: boolean
      errors?: Array<{ __typename?: 'AuthorizationProblem'; message: string }> | null
      query?: {
        __typename?: 'Query'
        notifications: {
          __typename?: 'NotificationList'
          records: Array<
            | {
                __typename: 'NotificationJepxOpenedPosition'
                category: NotificationCategory
                createdAt: any
                id: string
                status: NotificationStatus
                updatedAt: any
                metadata: {
                  __typename?: 'NotificationJepxOpenedPositionMetadata'
                  date: any
                  gridZone: GridZone
                }
                actor: {
                  __typename?: 'Actor'
                  firstName: string
                  lastName: string
                  id: string
                  email: string
                  workspace?: { __typename?: 'Workspace'; id: string } | null
                  profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
                }
              }
            | {
                __typename: 'NotificationJepxTradeConfirmationReminder'
                category: NotificationCategory
                createdAt: any
                id: string
                status: NotificationStatus
                updatedAt: any
                metadata: {
                  __typename?: 'NotificationJepxTradeConfirmationReminderMetadata'
                  gridZone: GridZone
                  date: any
                }
              }
            | {
                __typename: 'NotificationJepxTradeConfirmed'
                category: NotificationCategory
                createdAt: any
                id: string
                status: NotificationStatus
                updatedAt: any
                metadata: {
                  __typename?: 'NotificationJepxTradeConfirmedMetadata'
                  gridZone: GridZone
                  date: any
                }
                actor: {
                  __typename?: 'Actor'
                  firstName: string
                  lastName: string
                  id: string
                  email: string
                  workspace?: { __typename?: 'Workspace'; id: string } | null
                  profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
                }
              }
            | {
                __typename: 'NotificationJepxTradingReminder'
                category: NotificationCategory
                createdAt: any
                id: string
                status: NotificationStatus
                updatedAt: any
                metadata: {
                  __typename?: 'NotificationJepxTradingReminderMetadata'
                  gridZone: GridZone
                  date: any
                }
              }
            | {
                __typename: 'NotificationTSOPlanSubmissionReminder'
                category: NotificationCategory
                createdAt: any
                id: string
                status: NotificationStatus
                updatedAt: any
                metadata: {
                  __typename?: 'NotificationTSOPlanSubmissionReminderMetadata'
                  gridZone: GridZone
                  date: any
                }
              }
            | {
                __typename: 'NotificationTSOPlanSubmitted'
                category: NotificationCategory
                createdAt: any
                id: string
                status: NotificationStatus
                updatedAt: any
                metadata: {
                  __typename?: 'NotificationTSOPlanSubmittedMetadata'
                  gridZone: GridZone
                  date: any
                }
                actor: {
                  __typename?: 'Actor'
                  firstName: string
                  lastName: string
                  id: string
                  email: string
                  workspace?: { __typename?: 'Workspace'; id: string } | null
                  profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
                }
              }
          >
        }
        notificationsCount: { __typename?: 'NotificationList'; unreadCount: number }
      } | null
    }
  }
}

export type NotificationsQueryVariables = Exact<{
  input: NotificationListInput
  filter?: InputMaybe<NotificationListFilter>
}>

export type NotificationsQuery = {
  __typename?: 'Query'
  notifications: {
    __typename?: 'NotificationList'
    records: Array<
      | {
          __typename: 'NotificationJepxOpenedPosition'
          category: NotificationCategory
          createdAt: any
          id: string
          status: NotificationStatus
          updatedAt: any
          metadata: {
            __typename?: 'NotificationJepxOpenedPositionMetadata'
            date: any
            gridZone: GridZone
          }
          actor: {
            __typename?: 'Actor'
            firstName: string
            lastName: string
            id: string
            email: string
            workspace?: { __typename?: 'Workspace'; id: string } | null
            profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
          }
        }
      | {
          __typename: 'NotificationJepxTradeConfirmationReminder'
          category: NotificationCategory
          createdAt: any
          id: string
          status: NotificationStatus
          updatedAt: any
          metadata: {
            __typename?: 'NotificationJepxTradeConfirmationReminderMetadata'
            gridZone: GridZone
            date: any
          }
        }
      | {
          __typename: 'NotificationJepxTradeConfirmed'
          category: NotificationCategory
          createdAt: any
          id: string
          status: NotificationStatus
          updatedAt: any
          metadata: {
            __typename?: 'NotificationJepxTradeConfirmedMetadata'
            gridZone: GridZone
            date: any
          }
          actor: {
            __typename?: 'Actor'
            firstName: string
            lastName: string
            id: string
            email: string
            workspace?: { __typename?: 'Workspace'; id: string } | null
            profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
          }
        }
      | {
          __typename: 'NotificationJepxTradingReminder'
          category: NotificationCategory
          createdAt: any
          id: string
          status: NotificationStatus
          updatedAt: any
          metadata: {
            __typename?: 'NotificationJepxTradingReminderMetadata'
            gridZone: GridZone
            date: any
          }
        }
      | {
          __typename: 'NotificationTSOPlanSubmissionReminder'
          category: NotificationCategory
          createdAt: any
          id: string
          status: NotificationStatus
          updatedAt: any
          metadata: {
            __typename?: 'NotificationTSOPlanSubmissionReminderMetadata'
            gridZone: GridZone
            date: any
          }
        }
      | {
          __typename: 'NotificationTSOPlanSubmitted'
          category: NotificationCategory
          createdAt: any
          id: string
          status: NotificationStatus
          updatedAt: any
          metadata: {
            __typename?: 'NotificationTSOPlanSubmittedMetadata'
            gridZone: GridZone
            date: any
          }
          actor: {
            __typename?: 'Actor'
            firstName: string
            lastName: string
            id: string
            email: string
            workspace?: { __typename?: 'Workspace'; id: string } | null
            profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
          }
        }
    >
  }
  notificationsCount: { __typename?: 'NotificationList'; unreadCount: number }
}

export type NotificationsUnreadCountQueryVariables = Exact<{
  input: NotificationListInput
}>

export type NotificationsUnreadCountQuery = {
  __typename?: 'Query'
  notifications: { __typename?: 'NotificationList'; unreadCount: number }
}

export type BalancingGroupIdDataFragment = {
  __typename?: 'BalancingGroup'
  id: string
  balancingGroupCode: string
}

export type BalancingGroupListItemFragment = {
  __typename?: 'BalancingGroup'
  balancingGroupCode: string
  createdAt: any
  gridZone?: GridZone | null
  id: string
  totalAssets: number
  updatedAt: any
  name: string
}

export type BalancingGroupAssetDataFragment = {
  __typename?: 'SolarAsset'
  acCapacity: number
  dcCapacity: number
  cod: any
  id: string
  readableId: string
  name: string
  locationName: string
  status: AssetStatus
}

export type BalancingGroupOverviewRecordFragment = {
  __typename?: 'BalancingGroup'
  balancingGroupCode: string
  contractClassificationNumber1?: string | null
  createdAt: any
  gridZone?: GridZone | null
  id: string
  name: string
  totalAssets: number
  updatedAt: any
  hasForecasts: boolean
  imbalanceValue: number
  assets: Array<{
    __typename?: 'SolarAsset'
    acCapacity: number
    dcCapacity: number
    cod: any
    id: string
    readableId: string
    name: string
    locationName: string
    status: AssetStatus
  }>
}

export type GenerateUploadGenerationPresignedUrlsMutationVariables = Exact<{
  input: GenerateSolarAssetUploadActualsUrlsInput
}>

export type GenerateUploadGenerationPresignedUrlsMutation = {
  __typename?: 'Mutation'
  solarAssets: {
    __typename?: 'SolarAssetsMutations'
    generateUploadActualsUrls: {
      __typename?: 'GenerateSolarAssetUploadActualsUrlsPayload'
      success: boolean
      data?: Array<{
        __typename?: 'GenerateSolarAssetUploadActualsUrlsData'
        key: string
        url: string
        headers: string
      }> | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem' }
      > | null
    }
  }
}

export type RenameBalancingGroupMutationVariables = Exact<{
  input: RenameBalancingGroupInput
}>

export type RenameBalancingGroupMutation = {
  __typename?: 'Mutation'
  balancingGroup: {
    __typename?: 'BalancingGroupMutations'
    rename: {
      __typename?: 'RenameBalancingGroupPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'RenameBalancingGroupData'
        recordId: string
        record: { __typename?: 'BalancingGroup'; id: string; name: string }
      } | null
    }
  }
}

export type CreateBalancingGroupMutationVariables = Exact<{
  input: CreateBalancingGroupInput
}>

export type CreateBalancingGroupMutation = {
  __typename?: 'Mutation'
  balancingGroup: {
    __typename?: 'BalancingGroupMutations'
    create: {
      __typename?: 'CreateBalancingGroupPayload'
      success: boolean
      data?: {
        __typename?: 'CreateBalancingGroupData'
        recordId: string
        record: { __typename?: 'BalancingGroup'; id: string; name: string }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | {
            __typename?: 'BalancingGroupAlreadyExistsProblem'
            message: string
            type: BalancingGroupAlreadyExistsProblemType
          }
        | { __typename?: 'MissingDependentsProblem'; message: string; recordId: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
        | { __typename?: 'ResourceIsAlreadyInUseProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type UpdateBalancingGroupMutationVariables = Exact<{
  input: UpdateBalancingGroupInput
}>

export type UpdateBalancingGroupMutation = {
  __typename?: 'Mutation'
  balancingGroup: {
    __typename?: 'BalancingGroupMutations'
    update: {
      __typename?: 'UpdateBalancingGroupPayload'
      success: boolean
      data?: {
        __typename?: 'UpdateBalancingGroupData'
        recordId: string
        record: {
          __typename?: 'BalancingGroup'
          balancingGroupCode: string
          contractClassificationNumber1?: string | null
          createdAt: any
          gridZone?: GridZone | null
          id: string
          name: string
          totalAssets: number
          updatedAt: any
          hasForecasts: boolean
          imbalanceValue: number
          assets: Array<{
            __typename?: 'SolarAsset'
            acCapacity: number
            dcCapacity: number
            cod: any
            id: string
            readableId: string
            name: string
            locationName: string
            status: AssetStatus
          }>
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | {
            __typename?: 'BalancingGroupAlreadyExistsProblem'
            message: string
            type: BalancingGroupAlreadyExistsProblemType
          }
        | { __typename?: 'MissingDependentsProblem'; message: string; recordId: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type DeleteBalancingGroupMutationVariables = Exact<{
  input: DeleteBalancingGroupInput
  queryInput: BalancingGroupListInput
}>

export type DeleteBalancingGroupMutation = {
  __typename?: 'Mutation'
  balancingGroup: {
    __typename?: 'BalancingGroupMutations'
    delete: {
      __typename?: 'DeleteBalancingGroupPayload'
      success: boolean
      data?: {
        __typename?: 'DeleteBalancingGroupData'
        recordId: string
        record: { __typename?: 'BalancingGroup'; id: string; name: string }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        balancingGroupList: {
          __typename?: 'BalancingGroupList'
          pageInfo: {
            __typename?: 'PaginationInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            isEmpty: boolean
            page: number
            perPage: number
            totalPages: number
            totalRecords: number
          }
          records: Array<{
            __typename?: 'BalancingGroup'
            balancingGroupCode: string
            createdAt: any
            gridZone?: GridZone | null
            id: string
            totalAssets: number
            updatedAt: any
            name: string
          }>
        }
      } | null
    }
  }
}

export type AddAssetsToBalancingGroupMutationVariables = Exact<{
  addAssetsInput: AddAssetsToBalancingGroupInput
  balancingGroupQueryInput: QueryEntityInput
  solarAssetQueryInput: SolarAssetListInput
  solarAssetQueryFilter?: InputMaybe<SolarAssetFilter>
}>

export type AddAssetsToBalancingGroupMutation = {
  __typename?: 'Mutation'
  balancingGroup: {
    __typename?: 'BalancingGroupMutations'
    addAssets: {
      __typename?: 'AddAssetsToBalancingGroupPayload'
      success: boolean
      data?: {
        __typename?: 'AddAssetsToBalancingGroupData'
        recordId: string
        record: {
          __typename?: 'BalancingGroup'
          name: string
          assets: Array<{ __typename?: 'SolarAsset'; id: string }>
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
        | { __typename?: 'ResourceIsAlreadyInUseProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        balancingGroup: {
          __typename?: 'BalancingGroup'
          balancingGroupCode: string
          contractClassificationNumber1?: string | null
          createdAt: any
          gridZone?: GridZone | null
          id: string
          name: string
          totalAssets: number
          updatedAt: any
          hasForecasts: boolean
          imbalanceValue: number
          assets: Array<{
            __typename?: 'SolarAsset'
            acCapacity: number
            dcCapacity: number
            cod: any
            id: string
            readableId: string
            name: string
            locationName: string
            status: AssetStatus
          }>
        }
        solarAssetList: {
          __typename?: 'SolarAssetList'
          records: Array<{
            __typename?: 'SolarAsset'
            acCapacity: number
            dcCapacity: number
            cod: any
            id: string
            readableId: string
            name: string
            locationName: string
            status: AssetStatus
          }>
        }
      } | null
    }
  }
}

export type RemoveAssetsFromBalancinGroupMutationVariables = Exact<{
  input: RemoveAssetsFromBalancingGroupInput
  queryInput: QueryEntityInput
}>

export type RemoveAssetsFromBalancinGroupMutation = {
  __typename?: 'Mutation'
  balancingGroup: {
    __typename?: 'BalancingGroupMutations'
    removeAssets: {
      __typename?: 'RemoveAssetsFromBalancingGroupPayload'
      success: boolean
      data?: {
        __typename?: 'RemoveAssetsFromBalancingGroupData'
        recordId: string
        record: { __typename?: 'BalancingGroup'; id: string; name: string }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        balancingGroup: {
          __typename?: 'BalancingGroup'
          id: string
          totalAssets: number
          assets: Array<{
            __typename?: 'SolarAsset'
            acCapacity: number
            dcCapacity: number
            cod: any
            id: string
            readableId: string
            name: string
            locationName: string
            status: AssetStatus
          }>
        }
      } | null
    }
  }
}

export type GenerationUploadsQueryVariables = Exact<{
  input: SolarAssetActualsUploadsInput
}>

export type GenerationUploadsQuery = {
  __typename?: 'Query'
  solarAssetActualsUploads: Array<{
    __typename?: 'File'
    createdAt: any
    id: string
    name: string
    status: { __typename?: 'FileStatus'; status: FileStatusType }
  }>
}

export type BalancingGroupsQueryVariables = Exact<{
  input: BalancingGroupListInput
}>

export type BalancingGroupsQuery = {
  __typename?: 'Query'
  balancingGroupList: {
    __typename?: 'BalancingGroupList'
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
    records: Array<{
      __typename?: 'BalancingGroup'
      balancingGroupCode: string
      createdAt: any
      gridZone?: GridZone | null
      id: string
      totalAssets: number
      updatedAt: any
      name: string
    }>
  }
}

export type BalancingGroupsNameAndCodeQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
}>

export type BalancingGroupsNameAndCodeQuery = {
  __typename?: 'Query'
  balancingGroupList: {
    __typename?: 'BalancingGroupList'
    records: Array<{
      __typename?: 'BalancingGroup'
      id: string
      balancingGroupCode: string
      name: string
    }>
  }
}

export type BalancingGroupQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type BalancingGroupQuery = {
  __typename?: 'Query'
  balancingGroup: {
    __typename?: 'BalancingGroup'
    balancingGroupCode: string
    contractClassificationNumber1?: string | null
    createdAt: any
    gridZone?: GridZone | null
    id: string
    name: string
    totalAssets: number
    updatedAt: any
    hasForecasts: boolean
    imbalanceValue: number
    assets: Array<{
      __typename?: 'SolarAsset'
      acCapacity: number
      dcCapacity: number
      cod: any
      id: string
      readableId: string
      name: string
      locationName: string
      status: AssetStatus
    }>
  }
}

export type BalancingGroupEditViewDataQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type BalancingGroupEditViewDataQuery = {
  __typename?: 'Query'
  balancingGroup: {
    __typename?: 'BalancingGroup'
    balancingGroupCode: string
    contractClassificationNumber1?: string | null
    createdAt: any
    gridZone?: GridZone | null
    id: string
    name: string
    totalAssets: number
    updatedAt: any
    hasForecasts: boolean
    imbalanceValue: number
    assets: Array<{
      __typename?: 'SolarAsset'
      acCapacity: number
      dcCapacity: number
      cod: any
      id: string
      readableId: string
      name: string
      locationName: string
      status: AssetStatus
    }>
  }
}

export type BalancingGroupAvailableAssetsQueryVariables = Exact<{
  input: SolarAssetListInput
  filter?: InputMaybe<SolarAssetFilter>
}>

export type BalancingGroupAvailableAssetsQuery = {
  __typename?: 'Query'
  solarAssetList: {
    __typename?: 'SolarAssetList'
    records: Array<{
      __typename?: 'SolarAsset'
      acCapacity: number
      dcCapacity: number
      cod: any
      id: string
      readableId: string
      name: string
      locationName: string
      status: AssetStatus
    }>
  }
}

export type MonitoringDataAssetsQueryVariables = Exact<{
  input: SolarAssetListInput
}>

export type MonitoringDataAssetsQuery = {
  __typename?: 'Query'
  solarAssetList: {
    __typename?: 'SolarAssetList'
    records: Array<{
      __typename?: 'SolarAsset'
      dcCapacity: number
      name: string
      locationName: string
      id: string
      readableId: string
    }>
  }
}

export type DemandDataAssetsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
}>

export type DemandDataAssetsQuery = {
  __typename?: 'Query'
  solarAssetList: {
    __typename?: 'SolarAssetList'
    records: Array<{
      __typename?: 'SolarAsset'
      dcCapacity: number
      name: string
      locationName: string
      id: string
      readableId: string
      load?: { __typename?: 'SolarAssetLoad'; id: string; name: string; isDeleted: boolean } | null
    }>
  }
}

export type PpaIdDataFragment = { __typename?: 'PpaX'; id: string; readableId: string }

export type PpaCoreDataFragment = {
  __typename?: 'PpaX'
  id: string
  readableId: string
  name: string
  type: PpaType
  status: PpaStatus
  capacity: number
  startDate: any
  term: number
  fipBeneficiary: FipBeneficiary
  pricingScheme: PricingScheme
  createdAt: any
  updatedAt: any
  userUpdatedAt: any
  totalAssets: number
  offtaker?: { __typename?: 'ThirdPartyCompany'; id: string; name: string } | null
}

export type PpaCrudDataFragment = {
  __typename?: 'PpaX'
  id: string
  readableId: string
  name: string
  type: PpaType
  status: PpaStatus
  isFlexiblePricingScheme: boolean
  startDate: any
  term: number
  fipBeneficiary: FipBeneficiary
  pricingScheme: PricingScheme
  floatingShare: number
  fixedShare: number
  settlementMarket: SettlementMarket
  settlementPricingZone: SettlementPricingZone
  calculationInterval: number
  createdAt: any
  updatedAt: any
  offtaker?: {
    __typename?: 'ThirdPartyCompany'
    id: string
    name: string
    createdAt: any
    type: ThirdPartyCompanyType
  } | null
  solarAssets: Array<{
    __typename?: 'SolarAsset'
    id: string
    name: string
    readableId: string
    dcCapacity: number
    locationName: string
  }>
  strikePrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
  discountToMarket?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
  floorPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
  capPrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
}

export type CreatePpaXMutationVariables = Exact<{
  input: CreatePpaXInput
}>

export type CreatePpaXMutation = {
  __typename?: 'Mutation'
  ppa: {
    __typename?: 'PpaMutations'
    create: {
      __typename?: 'CreatePpaPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
        | { __typename?: 'ResourceIsAlreadyInUseProblem'; message: string }
      > | null
      data?: {
        __typename?: 'CreatePpaData'
        recordId: string
        record: {
          __typename?: 'PpaX'
          id: string
          readableId: string
          name: string
          type: PpaType
          status: PpaStatus
          isFlexiblePricingScheme: boolean
          startDate: any
          term: number
          fipBeneficiary: FipBeneficiary
          pricingScheme: PricingScheme
          floatingShare: number
          fixedShare: number
          settlementMarket: SettlementMarket
          settlementPricingZone: SettlementPricingZone
          calculationInterval: number
          createdAt: any
          updatedAt: any
          offtaker?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          solarAssets: Array<{
            __typename?: 'SolarAsset'
            id: string
            name: string
            readableId: string
            dcCapacity: number
            locationName: string
          }>
          strikePrice?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          discountToMarket?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          floorPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
          capPrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
        }
      } | null
    }
  }
}

export type UpdatePpaXMutationVariables = Exact<{
  input: UpdatePpaXInput
}>

export type UpdatePpaXMutation = {
  __typename?: 'Mutation'
  ppa: {
    __typename?: 'PpaMutations'
    update: {
      __typename?: 'UpdatePpaPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'UpdatePpaData'
        recordId: string
        record: {
          __typename?: 'PpaX'
          id: string
          readableId: string
          name: string
          type: PpaType
          status: PpaStatus
          isFlexiblePricingScheme: boolean
          startDate: any
          term: number
          fipBeneficiary: FipBeneficiary
          pricingScheme: PricingScheme
          floatingShare: number
          fixedShare: number
          settlementMarket: SettlementMarket
          settlementPricingZone: SettlementPricingZone
          calculationInterval: number
          createdAt: any
          updatedAt: any
          offtaker?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          solarAssets: Array<{
            __typename?: 'SolarAsset'
            id: string
            name: string
            readableId: string
            dcCapacity: number
            locationName: string
          }>
          strikePrice?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          discountToMarket?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          floorPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
          capPrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
        }
      } | null
    }
  }
}

export type DeletePpaXMutationVariables = Exact<{
  input: DeletePpaXInput
}>

export type DeletePpaXMutation = {
  __typename?: 'Mutation'
  ppa: {
    __typename?: 'PpaMutations'
    delete: {
      __typename?: 'DeletePpaPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'DeletePpaData'
        recordId: string
        record: {
          __typename?: 'PpaX'
          id: string
          readableId: string
          name: string
          type: PpaType
          status: PpaStatus
          isFlexiblePricingScheme: boolean
          startDate: any
          term: number
          fipBeneficiary: FipBeneficiary
          pricingScheme: PricingScheme
          floatingShare: number
          fixedShare: number
          settlementMarket: SettlementMarket
          settlementPricingZone: SettlementPricingZone
          calculationInterval: number
          createdAt: any
          updatedAt: any
          offtaker?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          solarAssets: Array<{
            __typename?: 'SolarAsset'
            id: string
            name: string
            readableId: string
            dcCapacity: number
            locationName: string
          }>
          strikePrice?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          discountToMarket?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          floorPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
          capPrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
        }
      } | null
    }
  }
}

export type DeletePpasMutationVariables = Exact<{
  input: DeleteManyPpasInput
  queryInput: PpaListInput
}>

export type DeletePpasMutation = {
  __typename?: 'Mutation'
  ppas: {
    __typename?: 'PpasMutations'
    deleteMany: {
      __typename?: 'DeleteManyPpasPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: Array<{
        __typename?: 'DeleteManyPpasData'
        recordId: string
        record: {
          __typename?: 'PpaX'
          id: string
          readableId: string
          name: string
          type: PpaType
          status: PpaStatus
          isFlexiblePricingScheme: boolean
          startDate: any
          term: number
          fipBeneficiary: FipBeneficiary
          pricingScheme: PricingScheme
          floatingShare: number
          fixedShare: number
          settlementMarket: SettlementMarket
          settlementPricingZone: SettlementPricingZone
          calculationInterval: number
          createdAt: any
          updatedAt: any
          offtaker?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          solarAssets: Array<{
            __typename?: 'SolarAsset'
            id: string
            name: string
            readableId: string
            dcCapacity: number
            locationName: string
          }>
          strikePrice?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          discountToMarket?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          floorPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
          capPrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
        }
      }> | null
      query?: {
        __typename?: 'Query'
        ppaList: {
          __typename?: 'PpaList'
          records: Array<{
            __typename?: 'PpaX'
            id: string
            readableId: string
            name: string
            type: PpaType
            status: PpaStatus
            capacity: number
            startDate: any
            term: number
            fipBeneficiary: FipBeneficiary
            pricingScheme: PricingScheme
            createdAt: any
            updatedAt: any
            userUpdatedAt: any
            totalAssets: number
            offtaker?: { __typename?: 'ThirdPartyCompany'; id: string; name: string } | null
          }>
          pageInfo: {
            __typename?: 'PaginationInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            isEmpty: boolean
            page: number
            perPage: number
            totalPages: number
            totalRecords: number
          }
        }
      } | null
    }
  }
}

export type RenamePpaXMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  name: Scalars['String']['input']
}>

export type RenamePpaXMutation = {
  __typename?: 'Mutation'
  ppa: {
    __typename?: 'PpaMutations'
    update: {
      __typename?: 'UpdatePpaPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'UpdatePpaData'
        recordId: string
        record: { __typename?: 'PpaX'; id: string; name: string }
      } | null
    }
  }
}

export type AddAssetsToPpaMutationVariables = Exact<{
  addAssetsInput: AddAssetsToPpaInput
  ppaQueryInput: QueryEntityInput
  solarAssetQueryInput: SolarAssetListInput
  solarAssetFilter: SolarAssetFilter
}>

export type AddAssetsToPpaMutation = {
  __typename?: 'Mutation'
  ppa: {
    __typename?: 'PpaMutations'
    addAssets: {
      __typename?: 'AddAssetsToPpaPayload'
      success: boolean
      data?: {
        __typename?: 'AddAssetsToPpaData'
        recordId: string
        record: {
          __typename?: 'PpaX'
          name: string
          id: string
          solarAssets: Array<{ __typename?: 'SolarAsset'; id: string }>
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
        | { __typename?: 'ResourceIsAlreadyInUseProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        ppa?: {
          __typename?: 'PpaX'
          id: string
          readableId: string
          name: string
          type: PpaType
          status: PpaStatus
          isFlexiblePricingScheme: boolean
          startDate: any
          term: number
          fipBeneficiary: FipBeneficiary
          pricingScheme: PricingScheme
          floatingShare: number
          fixedShare: number
          settlementMarket: SettlementMarket
          settlementPricingZone: SettlementPricingZone
          calculationInterval: number
          createdAt: any
          updatedAt: any
          offtaker?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          solarAssets: Array<{
            __typename?: 'SolarAsset'
            id: string
            name: string
            readableId: string
            dcCapacity: number
            locationName: string
          }>
          strikePrice?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          discountToMarket?: Array<{
            __typename?: 'PpaPriceSchemeSet'
            value: number
            year: number
          }> | null
          floorPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
          capPrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
        } | null
        solarAssetList: {
          __typename?: 'SolarAssetList'
          records: Array<{
            __typename?: 'SolarAsset'
            id: string
            readableId: string
            dcCapacity: number
            name: string
            locationName: string
          }>
        }
      } | null
    }
  }
}

export type RemoveAssetsFromPpaMutationVariables = Exact<{
  input: RemoveAssetsFromPpaInput
  queryInput: QueryEntityInput
}>

export type RemoveAssetsFromPpaMutation = {
  __typename?: 'Mutation'
  ppa: {
    __typename?: 'PpaMutations'
    removeAssets: {
      __typename?: 'RemoveAssetsFromPpaPayload'
      success: boolean
      data?: {
        __typename?: 'RemoveAssetsFromPpaData'
        recordId: string
        record: { __typename?: 'PpaX'; id: string; name: string }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        ppa?: {
          __typename?: 'PpaX'
          id: string
          totalAssets: number
          solarAssets: Array<{
            __typename?: 'SolarAsset'
            id: string
            readableId: string
            dcCapacity: number
            name: string
            locationName: string
          }>
        } | null
      } | null
    }
  }
}

export type PpaQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type PpaQuery = {
  __typename?: 'Query'
  ppa?: {
    __typename?: 'PpaX'
    id: string
    readableId: string
    name: string
    type: PpaType
    status: PpaStatus
    isFlexiblePricingScheme: boolean
    startDate: any
    term: number
    fipBeneficiary: FipBeneficiary
    pricingScheme: PricingScheme
    floatingShare: number
    fixedShare: number
    settlementMarket: SettlementMarket
    settlementPricingZone: SettlementPricingZone
    calculationInterval: number
    createdAt: any
    updatedAt: any
    offtaker?: {
      __typename?: 'ThirdPartyCompany'
      id: string
      name: string
      createdAt: any
      type: ThirdPartyCompanyType
    } | null
    solarAssets: Array<{
      __typename?: 'SolarAsset'
      id: string
      name: string
      readableId: string
      dcCapacity: number
      locationName: string
    }>
    strikePrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
    discountToMarket?: Array<{
      __typename?: 'PpaPriceSchemeSet'
      value: number
      year: number
    }> | null
    floorPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
    capPrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
  } | null
}

export type PpaOverviewQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type PpaOverviewQuery = {
  __typename?: 'Query'
  ppa?: {
    __typename?: 'PpaX'
    capacity: number
    createdAt: any
    fipBeneficiary: FipBeneficiary
    id: string
    name: string
    organizationId: string
    pricingScheme: PricingScheme
    readableId: string
    updatedAt: any
    settlementMarket: SettlementMarket
    settlementPricingZone: SettlementPricingZone
    startDate: any
    status: PpaStatus
    term: number
    type: PpaType
    capPrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
    discountToMarket?: Array<{
      __typename?: 'PpaPriceSchemeSet'
      value: number
      year: number
    }> | null
    floorPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
    solarAssets: Array<{
      __typename?: 'SolarAsset'
      acCapacity: number
      dcCapacity: number
      id: string
      latitude: number
      longitude: number
      name: string
    }>
    strikePrice?: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }> | null
    volumes: {
      __typename?: 'PpaVolumes'
      totalAcCapacity: number
      totalDcCapacity: number
      averageAnnualGeneration: number
    }
    offtaker?: { __typename?: 'ThirdPartyCompany'; id: string; name: string } | null
  } | null
}

export type PpaBaseDataQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type PpaBaseDataQuery = {
  __typename?: 'Query'
  ppa?: {
    __typename?: 'PpaX'
    id: string
    readableId: string
    name: string
    status: PpaStatus
  } | null
}

export type PpasAsOptionsQueryVariables = Exact<{
  input: PpaListInput
  filter?: InputMaybe<PpaFilter>
  sort?: InputMaybe<PpaSort>
}>

export type PpasAsOptionsQuery = {
  __typename?: 'Query'
  ppaList: {
    __typename?: 'PpaList'
    records: Array<{ __typename?: 'PpaX'; id: string; name: string }>
  }
}

export type PpasQueryVariables = Exact<{
  input: PpaListInput
}>

export type PpasQuery = {
  __typename?: 'Query'
  ppaList: {
    __typename?: 'PpaList'
    records: Array<{
      __typename?: 'PpaX'
      id: string
      readableId: string
      name: string
      type: PpaType
      status: PpaStatus
      capacity: number
      startDate: any
      term: number
      fipBeneficiary: FipBeneficiary
      pricingScheme: PricingScheme
      createdAt: any
      updatedAt: any
      userUpdatedAt: any
      totalAssets: number
      offtaker?: { __typename?: 'ThirdPartyCompany'; id: string; name: string } | null
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
}

export type PpasNameListQueryVariables = Exact<{
  input: PpaListInput
}>

export type PpasNameListQuery = {
  __typename?: 'Query'
  ppaList: {
    __typename?: 'PpaList'
    records: Array<{ __typename?: 'PpaX'; id: string; name: string }>
  }
}

export type ReportIdDataFragment = { __typename?: 'Report'; id: string; readableId: string }

export type ReportCoreDataFragment = {
  __typename?: 'Report'
  id: string
  name: string
  createdAt: any
  updatedAt: any
  readableId: string
}

export type ReportListDataFragment = {
  __typename?: 'ReportList'
  records: Array<{
    __typename?: 'Report'
    isStarred: boolean
    id: string
    name: string
    createdAt: any
    updatedAt: any
    readableId: string
    scope: {
      __typename?: 'ReportScope'
      solarAssets: { __typename?: 'SolarAssetReportScope'; total: number }
      ppa: { __typename?: 'PpaReportScope'; total: number }
    }
    author: {
      __typename?: 'User'
      email: string
      id: string
      firstName: string
      lastName: string
      profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
      workspace: { __typename?: 'Workspace'; id: string }
    }
  }>
  pageInfo: {
    __typename?: 'PaginationInfo'
    hasNextPage: boolean
    hasPreviousPage: boolean
    isEmpty: boolean
    page: number
    perPage: number
    totalPages: number
    totalRecords: number
  }
}

export type ReportSolarAssetListItemRecordFragment = {
  __typename?: 'SolarAsset'
  id: string
  name: string
  locationName: string
  acCapacity: number
  createdAt: any
  ppa?: { __typename?: 'PpaX'; name: string; id: string } | null
}

export type ReportSolarAssetListDataFragment = {
  __typename?: 'SolarAssetList'
  records: Array<{
    __typename?: 'SolarAsset'
    id: string
    name: string
    locationName: string
    acCapacity: number
    createdAt: any
    ppa?: { __typename?: 'PpaX'; name: string; id: string } | null
  }>
  pageInfo: {
    __typename?: 'PaginationInfo'
    hasNextPage: boolean
    hasPreviousPage: boolean
    isEmpty: boolean
    page: number
    perPage: number
    totalPages: number
    totalRecords: number
  }
}

export type ReportScenarioListDataFragment = {
  __typename?: 'ScenarioList'
  records: Array<{
    __typename?: 'MacroScenario'
    id: string
    readableId: string
    name: string
    description: string
    isDefault: boolean
    createdAt: any
    updatedAt: any
  }>
  pageInfo: {
    __typename?: 'PaginationInfo'
    hasNextPage: boolean
    hasPreviousPage: boolean
    isEmpty: boolean
    page: number
    perPage: number
    totalPages: number
    totalRecords: number
  }
}

export type MacroScenarioAssumptionCurtailmentsDataFragment = {
  __typename?: 'MacroScenarioAssumptionCurtailments'
  chubu: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  chugoku: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  hokkaido: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  hokuriku: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  kansai: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  kyushu: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  okinawa: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  shikoku: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  tohoku: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  tokyo: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
}

export type MacroScenarioAssumptionPricesDataFragment = {
  __typename?: 'MacroScenarioAssumptionPrices'
  chubu: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  chugoku: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  hokkaido: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  hokuriku: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  kansai: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  kyushu: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  okinawa: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  shikoku: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  tohoku: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  tokyo: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  system: {
    __typename?: 'SingleSeries'
    set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
}

export type ReportSharingDataFragment = {
  __typename?: 'Report'
  accessExpiry?: any | null
  sharedWith: Array<string>
  id: string
  name: string
  createdAt: any
  updatedAt: any
  readableId: string
}

export type StarReportMutationVariables = Exact<{
  input: StarReportInput
}>

export type StarReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    star: {
      __typename?: 'StarReportPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'StarReportData'
        recordId: string
        record: { __typename?: 'Report'; id: string; isStarred: boolean }
      } | null
    }
  }
}

export type UnstarReportMutationVariables = Exact<{
  input: UnstarReportInput
}>

export type UnstarReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    unstar: {
      __typename?: 'UnstarReportPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'UnstarReportData'
        recordId: string
        record: { __typename?: 'Report'; id: string; isStarred: boolean }
      } | null
    }
  }
}

export type RenameReportMutationVariables = Exact<{
  input: RenameReportInput
}>

export type RenameReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    rename: {
      __typename?: 'RenameReportPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'RenameReportData'
        recordId: string
        record: { __typename?: 'Report'; id: string; name: string }
      } | null
    }
  }
}

export type DeleteReportsMutationVariables = Exact<{
  input: DeleteManyReportsInput
  queryInput: ReportListInput
}>

export type DeleteReportsMutation = {
  __typename?: 'Mutation'
  reports: {
    __typename?: 'ReportsMutations'
    deleteMany: {
      __typename?: 'DeleteManyReportsPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: Array<{
        __typename?: 'DeleteManyReportsData'
        recordId: string
        record: { __typename?: 'Report'; id: string; name: string; readableId: string }
      }> | null
      query?: {
        __typename?: 'Query'
        reportList: {
          __typename?: 'ReportList'
          records: Array<{
            __typename?: 'Report'
            isStarred: boolean
            id: string
            name: string
            createdAt: any
            updatedAt: any
            readableId: string
            scope: {
              __typename?: 'ReportScope'
              solarAssets: { __typename?: 'SolarAssetReportScope'; total: number }
              ppa: { __typename?: 'PpaReportScope'; total: number }
            }
            author: {
              __typename?: 'User'
              email: string
              id: string
              firstName: string
              lastName: string
              profilePicture?: {
                __typename?: 'ProfilePicture'
                default: string
                small: string
              } | null
              workspace: { __typename?: 'Workspace'; id: string }
            }
          }>
          pageInfo: {
            __typename?: 'PaginationInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            isEmpty: boolean
            page: number
            perPage: number
            totalPages: number
            totalRecords: number
          }
        }
      } | null
    }
  }
}

export type CreateReportMutationVariables = Exact<{
  input: CreateReportInput
  organizationId: Scalars['ID']['input']
}>

export type CreateReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    create: {
      __typename?: 'CreateReportPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: { __typename?: 'CreateReportData'; recordId: string } | null
      query?: {
        __typename?: 'Query'
        solarAssetList: {
          __typename?: 'SolarAssetList'
          records: Array<{
            __typename?: 'SolarAsset'
            id: string
            name: string
            locationName: string
            acCapacity: number
            createdAt: any
            ppa?: { __typename?: 'PpaX'; name: string; id: string } | null
          }>
          pageInfo: {
            __typename?: 'PaginationInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            isEmpty: boolean
            page: number
            perPage: number
            totalPages: number
            totalRecords: number
          }
        }
        scenarioList: {
          __typename?: 'ScenarioList'
          records: Array<{
            __typename?: 'MacroScenario'
            id: string
            readableId: string
            name: string
            description: string
            isDefault: boolean
            createdAt: any
            updatedAt: any
          }>
          pageInfo: {
            __typename?: 'PaginationInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            isEmpty: boolean
            page: number
            perPage: number
            totalPages: number
            totalRecords: number
          }
        }
      } | null
    }
  }
}

export type SendMagicLinkMutationVariables = Exact<{
  input: SendReportMagicLinkInput
}>

export type SendMagicLinkMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    sendMagicLink: {
      __typename?: 'SendReportMagicLinkPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type SetReportAccessExpiryMutationVariables = Exact<{
  input: SetReportAccessExpiryInput
}>

export type SetReportAccessExpiryMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    setAccessExpiry: {
      __typename?: 'SetReportAccessExpiryPayload'
      success: boolean
      data?: {
        __typename?: 'SetReportAccessExpiryData'
        recordId: string
        record: { __typename?: 'Report'; id: string; accessExpiry?: any | null }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type ShareReportMutationVariables = Exact<{
  input: ShareReportInput
}>

export type ShareReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    share: {
      __typename?: 'ShareReportPayload'
      success: boolean
      data?: {
        __typename?: 'ShareReportData'
        recordId: string
        record: {
          __typename?: 'Report'
          accessExpiry?: any | null
          sharedWith: Array<string>
          id: string
          name: string
          createdAt: any
          updatedAt: any
          readableId: string
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type RevokeReportAccessMutationVariables = Exact<{
  input: UnshareReportInput
}>

export type RevokeReportAccessMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    unshare: {
      __typename?: 'UnshareReportPayload'
      success: boolean
      data?: {
        __typename?: 'UnshareReportData'
        recordId: string
        record: {
          __typename?: 'Report'
          id: string
          sharedWith: Array<string>
          accessExpiry?: any | null
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type ReportsQueryVariables = Exact<{
  input: ReportListInput
  filter?: InputMaybe<ReportFilter>
  sort?: InputMaybe<ReportSort>
}>

export type ReportsQuery = {
  __typename?: 'Query'
  reportList: {
    __typename?: 'ReportList'
    records: Array<{
      __typename?: 'Report'
      isStarred: boolean
      id: string
      name: string
      createdAt: any
      updatedAt: any
      readableId: string
      scope: {
        __typename?: 'ReportScope'
        solarAssets: { __typename?: 'SolarAssetReportScope'; total: number }
        ppa: { __typename?: 'PpaReportScope'; total: number }
      }
      author: {
        __typename?: 'User'
        email: string
        id: string
        firstName: string
        lastName: string
        profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
        workspace: { __typename?: 'Workspace'; id: string }
      }
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
}

export type ReportScenariosQueryVariables = Exact<{
  reportInput: QueryEntityInput
}>

export type ReportScenariosQuery = {
  __typename?: 'Query'
  report?: {
    __typename?: 'Report'
    id: string
    name: string
    createdAt: any
    updatedAt: any
    readableId: string
    scope: {
      __typename?: 'ReportScope'
      macroScenarios: {
        __typename?: 'MacroScenarioReportScope'
        records: Array<{
          __typename?: 'ReportMacroScenario'
          id: string
          name: string
          isDefault: boolean
        }>
      }
    }
  } | null
}

export type ReportCoreQueryVariables = Exact<{
  reportInput: QueryEntityInput
  ppaScopeInput: ReportScopeBaseInput
  solarAssetScopeInput: SolarAssetReportScopeInput
}>

export type ReportCoreQuery = {
  __typename?: 'Query'
  report?: {
    __typename?: 'Report'
    simulationStatus: SimulationStatus
    id: string
    name: string
    createdAt: any
    updatedAt: any
    readableId: string
    scope: {
      __typename?: 'ReportScope'
      solarAssets: {
        __typename?: 'SolarAssetReportScope'
        records: Array<{ __typename?: 'ReportSolarAsset'; id: string; name: string }>
      }
      ppa: {
        __typename?: 'PpaReportScope'
        records: Array<{ __typename?: 'ReportPpa'; id: string; name: string }>
      }
      macroScenarios: {
        __typename?: 'MacroScenarioReportScope'
        records: Array<{
          __typename?: 'ReportMacroScenario'
          id: string
          name: string
          isDefault: boolean
        }>
      }
    }
  } | null
}

export type ReportDetailsQueryVariables = Exact<{
  reportInput: QueryEntityInput
  scopeInput: ReportScopeBaseInput
  scenarioInput: MacroScenarioSeriesInput
  solarAssetSimulationInput: SolarAssetReportScopeInput
}>

export type ReportDetailsQuery = {
  __typename?: 'Query'
  report?: {
    __typename?: 'Report'
    issuedFor?: string | null
    isStarred: boolean
    isBaseline: boolean
    isShareable: boolean
    isArchived: boolean
    simulationStatus: SimulationStatus
    id: string
    name: string
    createdAt: any
    updatedAt: any
    readableId: string
    author: {
      __typename?: 'User'
      id: string
      firstName: string
      lastName: string
      profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
      workspace: { __typename?: 'Workspace'; id: string }
    }
    organization: { __typename?: 'Organization'; id: string; name: string }
    scope: {
      __typename?: 'ReportScope'
      solarAssets: {
        __typename?: 'SolarAssetReportScope'
        total: number
        records: Array<{
          __typename?: 'ReportSolarAsset'
          id: string
          name: string
          acCapacity: number
          dcCapacity: number
          solarYieldFirstYear?: number | null
          irr?: number | null
          latitude: number
          longitude: number
          gridZone: GridZone
          ppa?: { __typename?: 'ReportPpa'; id: string; name: string } | null
        }>
        capexPayments: Array<{
          __typename?: 'CAPEXPayment'
          id: string
          date: any
          amount: number
          description?: string | null
          consumptionTax: boolean
          category: CapexCategory
        }>
        keyIndicators: {
          __typename?: 'SolarAssetReportScopeKeyIndicators'
          total: number
          acCapacity: number
          dcCapacity: number
          irr: {
            __typename?: 'IrrBenchmarkIndicator'
            error?: IrrCalculationError | null
            calculation?: {
              __typename?: 'BenchmarkIndicator'
              value: number
              benchmark: number
            } | null
          }
          totalCapex: { __typename?: 'BenchmarkIndicator'; value: number; benchmark: number }
          lifetimeRevenues: { __typename?: 'BenchmarkIndicator'; value: number; benchmark: number }
          kwpCapex: { __typename?: 'BenchmarkIndicator'; value: number; benchmark: number }
          kwpRevenues: { __typename?: 'BenchmarkIndicator'; value: number; benchmark: number }
        }
        simulationSummary: {
          __typename?: 'ReportSimulationSummary'
          netGenerationLifetimeTotal: number
          assetManagementLifetimeTotal: number
          capexLifetimeTotal: number
          decommissionReserveLifetimeTotal: number
          hardwareReplacementLifetimeTotal: number
          insuranceLifetimeTotal: number
          landLeaseLifetimeTotal: number
          marketRevenueLifetimeTotal: number
          netCashflowLifetimeTotal: number
          omLifetimeTotal: number
          otherLifetimeTotal: number
          opexLifetimeTotal: number
          ppaCashflowLifetimeTotal: number
          subsidyRevenueLifetimeTotal: number
          taxLifetimeTotal: number
          totalLifetimeRevenue: number
          assetManagementCostsMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          assetManagementCostsYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          capexMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          capexYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          cumulativeNetCashflowMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          cumulativeNetCashflowYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          curtailmentMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          curtailmentYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          decommissionReserveCostsMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          decommissionReserveCostsYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          hardwareReplacementCostsMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          hardwareReplacementCostsYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          insuranceCostsMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          insuranceCostsYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          landLeaseCostsMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          landLeaseCostsYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          marketRevenueMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          marketRevenueYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          netCashflowMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          netCashflowYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          netGenerationMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          netGenerationYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          omCostsMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          omCostsYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          otherCostsMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          otherCostsYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          operatingCashflowMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          operatingCashflowYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          opexTotalCostsMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          opexTotalCostsYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          ppaCashflowMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          ppaCashflowYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          subsidyRevenueMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          subsidyRevenueYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          taxCostsMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          taxCostsYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          totalGenerationMonthlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          totalGenerationYearlySeries: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
      }
      ppa: {
        __typename?: 'PpaReportScope'
        total: number
        records: Array<{
          __typename?: 'ReportPpa'
          isFlexiblePricingScheme: boolean
          calculationInterval: number
          createdAt: any
          capacity: number
          deleted: boolean
          fipBeneficiary: FipBeneficiary
          fixedShare: number
          floatingShare: number
          id: string
          name: string
          organizationId: string
          pricingScheme: PricingScheme
          settlementMarket: SettlementMarket
          settlementPricingZone: SettlementPricingZone
          startDate: any
          status: PpaStatus
          term: number
          totalAssets: number
          type: PpaType
          updatedAt: any
          userUpdatedAt: any
          capPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
          discountToMarket: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
          floorPrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
          offtaker?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            updatedAt: any
            type: ThirdPartyCompanyType
          } | null
          solarAssets: Array<{ __typename?: 'ReportSolarAsset'; id: string }>
          strikePrice: Array<{ __typename?: 'PpaPriceSchemeSet'; value: number; year: number }>
          volumes: {
            __typename?: 'PpaVolumes'
            totalAcCapacity: number
            totalDcCapacity: number
            averageAnnualGeneration: number
          }
        }>
        keyIndicators: { __typename?: 'PpaReportScopeKeyIndicators'; total: number }
      }
      macroScenarios: {
        __typename?: 'MacroScenarioReportScope'
        averageSystemPriceYearlySeries: {
          __typename?: 'MacroScenarioAssumptionPrices'
          chubu: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          chugoku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          hokkaido: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          hokuriku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          kansai: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          kyushu: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          okinawa: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          shikoku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          tohoku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          tokyo: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          system: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        curtailmentYearlySeries: {
          __typename?: 'MacroScenarioAssumptionCurtailments'
          chubu: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          chugoku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          hokkaido: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          hokuriku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          kansai: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          kyushu: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          okinawa: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          shikoku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          tohoku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          tokyo: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        forecastErrorDayAheadYearlySeries: {
          __typename?: 'SingleSeries'
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        forecastErrorIntradayYearlySeries: {
          __typename?: 'SingleSeries'
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        inflationRateYearlySeries: {
          __typename?: 'SingleSeries'
          set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        meanAveragePriceDeviationYearlySeries: {
          __typename?: 'MacroScenarioAssumptionPrices'
          chubu: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          chugoku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          hokkaido: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          hokuriku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          kansai: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          kyushu: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          okinawa: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          shikoku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          tohoku: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          tokyo: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
          system: {
            __typename?: 'SingleSeries'
            set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          }
        }
        records: Array<{ __typename?: 'ReportMacroScenario'; id: string; name: string }>
      }
    }
  } | null
}

export type ReportCreationInfoQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
}>

export type ReportCreationInfoQuery = {
  __typename?: 'Query'
  solarAssetList: {
    __typename?: 'SolarAssetList'
    records: Array<{
      __typename?: 'SolarAsset'
      id: string
      name: string
      locationName: string
      acCapacity: number
      createdAt: any
      ppa?: { __typename?: 'PpaX'; name: string; id: string } | null
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
  scenarioList: {
    __typename?: 'ScenarioList'
    records: Array<{
      __typename?: 'MacroScenario'
      id: string
      readableId: string
      name: string
      description: string
      isDefault: boolean
      createdAt: any
      updatedAt: any
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
}

export type ReportSharingInformationQueryVariables = Exact<{
  reportInput: QueryEntityInput
}>

export type ReportSharingInformationQuery = {
  __typename?: 'Query'
  report?: {
    __typename?: 'Report'
    accessExpiry?: any | null
    sharedWith: Array<string>
    id: string
    name: string
    createdAt: any
    updatedAt: any
    readableId: string
  } | null
}

export type ReportTopNavQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type ReportTopNavQuery = {
  __typename?: 'Query'
  report?: {
    __typename?: 'Report'
    sharedWith: Array<string>
    id: string
    name: string
    createdAt: any
    updatedAt: any
    readableId: string
  } | null
}

export type ScenarioIdDataFragment = {
  __typename?: 'MacroScenario'
  id: string
  readableId: string
}

export type ScenarioCoreDataFragment = {
  __typename?: 'MacroScenario'
  id: string
  readableId: string
  name: string
  description: string
  isDefault: boolean
  createdAt: any
  updatedAt: any
}

export type ScenarioCrudDataFragment = {
  __typename?: 'MacroScenario'
  id: string
  readableId: string
  name: string
  description: string
  isDefault: boolean
  createdAt: any
  updatedAt: any
  inflation: {
    __typename?: 'ScenarioInflation'
    id: string
    name: string
    isCustom: boolean
    isEditable: boolean
    isDeletable: boolean
    createdAt: any
  }
  curtailment: {
    __typename?: 'ScenarioCurtailment'
    id: string
    name: string
    isCustom: boolean
    isEditable: boolean
    isDeletable: boolean
    createdAt: any
  }
  priceAverage: {
    __typename?: 'ScenarioPriceAverage'
    id: string
    name: string
    isCustom: boolean
    isEditable: boolean
    isDeletable: boolean
    createdAt: any
  }
  priceVolatility: {
    __typename?: 'ScenarioPriceVolatility'
    id: string
    name: string
    isCustom: boolean
    isEditable: boolean
    isDeletable: boolean
    createdAt: any
  }
  forecastMapeDayAhead: {
    __typename?: 'ScenarioForecastMapeDayAhead'
    id: string
    name: string
    isCustom: boolean
    isEditable: boolean
    isDeletable: boolean
    createdAt: any
  }
  forecastMapeIntraday: {
    __typename?: 'ScenarioForecastMapeIntraday'
    id: string
    name: string
    isCustom: boolean
    isEditable: boolean
    isDeletable: boolean
    createdAt: any
  }
}

export type CreateScenarioMutationVariables = Exact<{
  input: CreateScenarioInput
  queryInput: GetAssumptionListInput
}>

export type CreateScenarioMutation = {
  __typename?: 'Mutation'
  scenario: {
    __typename?: 'ScenarioMutations'
    create: {
      __typename?: 'CreateScenarioResult'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'MissingDependentsProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
      > | null
      data?: {
        __typename?: 'CreateScenarioData'
        recordId: string
        record: {
          __typename?: 'MacroScenario'
          id: string
          readableId: string
          name: string
          description: string
          isDefault: boolean
          createdAt: any
          updatedAt: any
          inflation: {
            __typename?: 'ScenarioInflation'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          curtailment: {
            __typename?: 'ScenarioCurtailment'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          priceAverage: {
            __typename?: 'ScenarioPriceAverage'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          priceVolatility: {
            __typename?: 'ScenarioPriceVolatility'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          forecastMapeDayAhead: {
            __typename?: 'ScenarioForecastMapeDayAhead'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          forecastMapeIntraday: {
            __typename?: 'ScenarioForecastMapeIntraday'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
        }
      } | null
      query?: {
        __typename?: 'Query'
        scenarioAssumptions: {
          __typename?: 'ScenarioAssumptionsQuery'
          inflation: {
            __typename?: 'InflationList'
            records: Array<{
              __typename?: 'ScenarioInflation'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          curtailment: {
            __typename?: 'CurtailmentList'
            records: Array<{
              __typename?: 'ScenarioCurtailment'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          priceAverage: {
            __typename?: 'PriceAverageList'
            records: Array<{
              __typename?: 'ScenarioPriceAverage'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          priceVolatility: {
            __typename?: 'PriceVolatilityList'
            records: Array<{
              __typename?: 'ScenarioPriceVolatility'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          forecastMapeDayAhead: {
            __typename?: 'ForecastMapeDayAheadList'
            records: Array<{
              __typename?: 'ScenarioForecastMapeDayAhead'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          forecastMapeIntraday: {
            __typename?: 'ForecastMapeIntradayList'
            records: Array<{
              __typename?: 'ScenarioForecastMapeIntraday'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
        }
      } | null
    }
  }
}

export type UpdateScenarioMutationVariables = Exact<{
  input: UpdateScenarioInput
  queryInput: GetAssumptionListInput
}>

export type UpdateScenarioMutation = {
  __typename?: 'Mutation'
  scenario: {
    __typename?: 'ScenarioMutations'
    update: {
      __typename?: 'UpdateScenarioResult'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'MissingDependentsProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceCannotBeModifiedProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'UpdateScenarioData'
        recordId: string
        record: {
          __typename?: 'MacroScenario'
          id: string
          readableId: string
          name: string
          description: string
          isDefault: boolean
          createdAt: any
          updatedAt: any
          inflation: {
            __typename?: 'ScenarioInflation'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          curtailment: {
            __typename?: 'ScenarioCurtailment'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          priceAverage: {
            __typename?: 'ScenarioPriceAverage'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          priceVolatility: {
            __typename?: 'ScenarioPriceVolatility'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          forecastMapeDayAhead: {
            __typename?: 'ScenarioForecastMapeDayAhead'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          forecastMapeIntraday: {
            __typename?: 'ScenarioForecastMapeIntraday'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
        }
      } | null
      query?: {
        __typename?: 'Query'
        scenarioAssumptions: {
          __typename?: 'ScenarioAssumptionsQuery'
          inflation: {
            __typename?: 'InflationList'
            records: Array<{
              __typename?: 'ScenarioInflation'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          curtailment: {
            __typename?: 'CurtailmentList'
            records: Array<{
              __typename?: 'ScenarioCurtailment'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          priceAverage: {
            __typename?: 'PriceAverageList'
            records: Array<{
              __typename?: 'ScenarioPriceAverage'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          priceVolatility: {
            __typename?: 'PriceVolatilityList'
            records: Array<{
              __typename?: 'ScenarioPriceVolatility'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          forecastMapeDayAhead: {
            __typename?: 'ForecastMapeDayAheadList'
            records: Array<{
              __typename?: 'ScenarioForecastMapeDayAhead'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
          forecastMapeIntraday: {
            __typename?: 'ForecastMapeIntradayList'
            records: Array<{
              __typename?: 'ScenarioForecastMapeIntraday'
              id: string
              name: string
              isCustom: boolean
              isEditable: boolean
              isDeletable: boolean
              createdAt: any
            }>
          }
        }
      } | null
    }
  }
}

export type DeleteScenarioMutationVariables = Exact<{
  input: DeleteScenarioInput
  queryInput: ScenarioListInput
}>

export type DeleteScenarioMutation = {
  __typename?: 'Mutation'
  scenario: {
    __typename?: 'ScenarioMutations'
    delete: {
      __typename?: 'DeleteScenarioPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceCannotBeDeletedProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'DeleteScenarioData'
        recordId: string
        record: {
          __typename?: 'MacroScenario'
          id: string
          readableId: string
          name: string
          description: string
          isDefault: boolean
          createdAt: any
          updatedAt: any
          inflation: {
            __typename?: 'ScenarioInflation'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          curtailment: {
            __typename?: 'ScenarioCurtailment'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          priceAverage: {
            __typename?: 'ScenarioPriceAverage'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          priceVolatility: {
            __typename?: 'ScenarioPriceVolatility'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          forecastMapeDayAhead: {
            __typename?: 'ScenarioForecastMapeDayAhead'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          forecastMapeIntraday: {
            __typename?: 'ScenarioForecastMapeIntraday'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
        }
      } | null
      query?: {
        __typename?: 'Query'
        scenarioList: {
          __typename?: 'ScenarioList'
          pageInfo: {
            __typename?: 'PaginationInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            isEmpty: boolean
            page: number
            perPage: number
            totalPages: number
            totalRecords: number
          }
          records: Array<{
            __typename?: 'MacroScenario'
            id: string
            readableId: string
            name: string
            description: string
            isDefault: boolean
            createdAt: any
            updatedAt: any
          }>
        }
      } | null
    }
  }
}

export type DeleteScenariosMutationVariables = Exact<{
  input: DeleteManyScenariosInput
  queryInput: ScenarioListInput
}>

export type DeleteScenariosMutation = {
  __typename?: 'Mutation'
  scenarios: {
    __typename?: 'ScenariosMutations'
    deleteMany: {
      __typename?: 'DeleteManyScenariosPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceCannotBeDeletedProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: Array<{
        __typename?: 'DeleteManyScenariosData'
        recordId: string
        record: {
          __typename?: 'MacroScenario'
          id: string
          readableId: string
          name: string
          description: string
          isDefault: boolean
          createdAt: any
          updatedAt: any
          inflation: {
            __typename?: 'ScenarioInflation'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          curtailment: {
            __typename?: 'ScenarioCurtailment'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          priceAverage: {
            __typename?: 'ScenarioPriceAverage'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          priceVolatility: {
            __typename?: 'ScenarioPriceVolatility'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          forecastMapeDayAhead: {
            __typename?: 'ScenarioForecastMapeDayAhead'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
          forecastMapeIntraday: {
            __typename?: 'ScenarioForecastMapeIntraday'
            id: string
            name: string
            isCustom: boolean
            isEditable: boolean
            isDeletable: boolean
            createdAt: any
          }
        }
      }> | null
      query?: {
        __typename?: 'Query'
        scenarioList: {
          __typename?: 'ScenarioList'
          pageInfo: {
            __typename?: 'PaginationInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            isEmpty: boolean
            page: number
            perPage: number
            totalPages: number
            totalRecords: number
          }
          records: Array<{
            __typename?: 'MacroScenario'
            id: string
            readableId: string
            name: string
            description: string
            isDefault: boolean
            createdAt: any
            updatedAt: any
          }>
        }
      } | null
    }
  }
}

export type RenameScenarioMutationVariables = Exact<{
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  workspaceId: Scalars['ID']['input']
}>

export type RenameScenarioMutation = {
  __typename?: 'Mutation'
  scenario: {
    __typename?: 'ScenarioMutations'
    update: {
      __typename?: 'UpdateScenarioResult'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'MissingDependentsProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceCannotBeModifiedProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'UpdateScenarioData'
        recordId: string
        record: { __typename?: 'MacroScenario'; id: string; name: string }
      } | null
    }
  }
}

export type ScenarioQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type ScenarioQuery = {
  __typename?: 'Query'
  scenario?: {
    __typename?: 'MacroScenario'
    id: string
    readableId: string
    name: string
    description: string
    isDefault: boolean
    createdAt: any
    updatedAt: any
    inflation: {
      __typename?: 'ScenarioInflation'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }
    curtailment: {
      __typename?: 'ScenarioCurtailment'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }
    priceAverage: {
      __typename?: 'ScenarioPriceAverage'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }
    priceVolatility: {
      __typename?: 'ScenarioPriceVolatility'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }
    forecastMapeDayAhead: {
      __typename?: 'ScenarioForecastMapeDayAhead'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }
    forecastMapeIntraday: {
      __typename?: 'ScenarioForecastMapeIntraday'
      id: string
      name: string
      isCustom: boolean
      isEditable: boolean
      isDeletable: boolean
      createdAt: any
    }
  } | null
}

export type ScenariosQueryVariables = Exact<{
  input: ScenarioListInput
  filter?: InputMaybe<ScenarioFilter>
  sort?: InputMaybe<ScenarioSort>
}>

export type ScenariosQuery = {
  __typename?: 'Query'
  scenarioList: {
    __typename?: 'ScenarioList'
    records: Array<{
      __typename?: 'MacroScenario'
      id: string
      readableId: string
      name: string
      description: string
      isDefault: boolean
      createdAt: any
      updatedAt: any
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
    queryVariables: {
      __typename?: 'ScenarioListQueryVariables'
      sort?: ScenarioSort | null
      filter?: { __typename?: 'ScenarioFilterVariables'; text?: string | null } | null
      input: { __typename?: 'ScenarioListInputVariables'; page: number; perPage: number }
    }
  }
}

export type SolarAssetIdDataFragment = { __typename?: 'SolarAsset'; id: string; readableId: string }

export type OpexCostsFragment = { __typename?: 'OpexCostSet'; year: number; value: number }

export type CapexPaymentDataFragment = {
  __typename?: 'CAPEXPayment'
  id: string
  date: any
  amount: number
  description?: string | null
  consumptionTax: boolean
  category: CapexCategory
}

export type SolarAssetEntityFragment = {
  __typename?: 'SolarAsset'
  id: string
  readableId: string
  acCableLosses: number
  acCapacity: number
  altitude: number
  annualDegradation: number
  cod: any
  createdAt: any
  downtimeRate: number
  gridZone: GridZone
  inverterLosses: number
  inverterWarrantyPeriod: number
  isUsingAdvancedLosses: boolean
  latitude: number
  lifetime: number
  locationName: string
  longitude: number
  meteringDate: number
  metiRegistrationid: string
  metiid: string
  name: string
  shadingLosses: number
  simulationStatus: SimulationStatus
  status: AssetStatus
  subsidyRate?: number | null
  subsidyScheme: SubsidyScheme
  subsidyStartDate?: any | null
  updatedAt: any
  utilityid: string
  gridCode?: string | null
  contractClassificationNumber2?: string | null
  assetManagementCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
  balancingGroup: { __typename?: 'BalancingGroup'; id: string; balancingGroupCode: string }
  decommissionReserveCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
  insuranceCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
  inverterCost: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
  landLeaseCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
  omCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
  otherCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
  propertyTaxRate: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
  ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
  developer?: {
    __typename?: 'ThirdPartyCompany'
    id: string
    name: string
    createdAt: any
    type: ThirdPartyCompanyType
  } | null
  epcCompany?: {
    __typename?: 'ThirdPartyCompany'
    id: string
    name: string
    createdAt: any
    type: ThirdPartyCompanyType
  } | null
  omProvider?: {
    __typename?: 'ThirdPartyCompany'
    id: string
    name: string
    createdAt: any
    type: ThirdPartyCompanyType
  } | null
  macroScenario: { __typename?: 'MacroScenario'; id: string; name: string }
  capexPayments: Array<{
    __typename?: 'CAPEXPayment'
    id: string
    date: any
    amount: number
    category: CapexCategory
    description?: string | null
    consumptionTax: boolean
  } | null>
  pvArray: Array<{
    __typename?: 'PVModuleGeneric'
    id: string
    moduleType: ModuleType
    mountType: MountType
    tilt: number
    azimuth: number
    moduleParameters: {
      __typename?: 'PVModuleGenericParams'
      pdc0: number
      gammaPdc?: number | null
    }
  } | null>
  battery?: {
    __typename?: 'Battery'
    cycleCost: number
    energyCapacity: number
    expectedCycleLife: number
    id: string
    isDeleted: boolean
    maximumStateOfCharge: number
    minimumStateOfCharge: number
    operationalStartDate: any
    powerCapacity: number
    roundtripEfficiency: number
    status: BatteryStatus
    warrantyPeriod: number
  } | null
  load?: {
    __typename?: 'SolarAssetLoad'
    averageLoad: number
    id: string
    isDeleted: boolean
    maximumLoad: number
    minimumLoad: number
    name: string
    notes: string
    operationalStartDate: any
    status: SolarAssetLoadStatus
    type: SolarAssetLoadType
  } | null
}

export type SolarAssetTableRecordFragment = {
  __typename?: 'SolarAsset'
  id: string
  readableId: string
  acCapacity: number
  cod: any
  createdAt: any
  dcCapacity: number
  gridZone: GridZone
  locationName: string
  name: string
  simulationStatus: SimulationStatus
  subsidyScheme: SubsidyScheme
  status: AssetStatus
  updatedAt: any
  utilityid: string
  userUpdatedAt: any
  ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
  pvArray: Array<{
    __typename?: 'PVModuleGeneric'
    moduleParameters: { __typename?: 'PVModuleGenericParams'; pdc0: number }
  } | null>
}

export type PpaAssetDataFragment = {
  __typename?: 'SolarAsset'
  id: string
  readableId: string
  dcCapacity: number
  name: string
  locationName: string
}

export type SpvAssetDataFragment = {
  __typename?: 'SolarAsset'
  id: string
  readableId: string
  acCapacity: number
  dcCapacity: number
  name: string
  locationName: string
  cod: any
}

export type CreateSolarAssetMutationVariables = Exact<{
  input: CreateSolarAssetInput
}>

export type CreateSolarAssetMutation = {
  __typename?: 'Mutation'
  solarAsset: {
    __typename?: 'SolarAssetMutations'
    create: {
      __typename?: 'CreateSolarAssetPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | {
            __typename?: 'SolarAssetAlreadyExistsProblem'
            message: string
            type: SolarAssetAlreadyExistsProblemType
            name: string
          }
      > | null
      data?: {
        __typename?: 'CreateSolarAssetData'
        recordId: string
        record: {
          __typename?: 'SolarAsset'
          id: string
          readableId: string
          acCableLosses: number
          acCapacity: number
          altitude: number
          annualDegradation: number
          cod: any
          createdAt: any
          downtimeRate: number
          gridZone: GridZone
          inverterLosses: number
          inverterWarrantyPeriod: number
          isUsingAdvancedLosses: boolean
          latitude: number
          lifetime: number
          locationName: string
          longitude: number
          meteringDate: number
          metiRegistrationid: string
          metiid: string
          name: string
          shadingLosses: number
          simulationStatus: SimulationStatus
          status: AssetStatus
          subsidyRate?: number | null
          subsidyScheme: SubsidyScheme
          subsidyStartDate?: any | null
          updatedAt: any
          utilityid: string
          gridCode?: string | null
          contractClassificationNumber2?: string | null
          assetManagementCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          balancingGroup: { __typename?: 'BalancingGroup'; id: string; balancingGroupCode: string }
          decommissionReserveCosts: Array<{
            __typename?: 'OpexCostSet'
            year: number
            value: number
          }>
          insuranceCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          inverterCost: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          landLeaseCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          omCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          otherCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          propertyTaxRate: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
          developer?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          epcCompany?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          omProvider?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          macroScenario: { __typename?: 'MacroScenario'; id: string; name: string }
          capexPayments: Array<{
            __typename?: 'CAPEXPayment'
            id: string
            date: any
            amount: number
            category: CapexCategory
            description?: string | null
            consumptionTax: boolean
          } | null>
          pvArray: Array<{
            __typename?: 'PVModuleGeneric'
            id: string
            moduleType: ModuleType
            mountType: MountType
            tilt: number
            azimuth: number
            moduleParameters: {
              __typename?: 'PVModuleGenericParams'
              pdc0: number
              gammaPdc?: number | null
            }
          } | null>
          battery?: {
            __typename?: 'Battery'
            cycleCost: number
            energyCapacity: number
            expectedCycleLife: number
            id: string
            isDeleted: boolean
            maximumStateOfCharge: number
            minimumStateOfCharge: number
            operationalStartDate: any
            powerCapacity: number
            roundtripEfficiency: number
            status: BatteryStatus
            warrantyPeriod: number
          } | null
          load?: {
            __typename?: 'SolarAssetLoad'
            averageLoad: number
            id: string
            isDeleted: boolean
            maximumLoad: number
            minimumLoad: number
            name: string
            notes: string
            operationalStartDate: any
            status: SolarAssetLoadStatus
            type: SolarAssetLoadType
          } | null
        }
      } | null
    }
  }
}

export type UpdateSolarAssetMutationVariables = Exact<{
  input: UpdateSolarAssetInput
}>

export type UpdateSolarAssetMutation = {
  __typename?: 'Mutation'
  solarAsset: {
    __typename?: 'SolarAssetMutations'
    update: {
      __typename?: 'UpdateSolarAssetPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'BatteryValidationProblem'; message: string }
        | { __typename?: 'CAPEXPaymentValidationProblem'; message: string }
        | {
            __typename?: 'SolarAssetAlreadyExistsProblem'
            message: string
            type: SolarAssetAlreadyExistsProblemType
            name: string
          }
        | { __typename?: 'SolarAssetLoadValidationProblem'; message: string }
        | { __typename?: 'SolarAssetPropertiesCannotBeModifiedProblem'; message: string }
        | { __typename?: 'SolarAssetResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'UpdateSolarAssetData'
        recordId: string
        record: {
          __typename?: 'SolarAsset'
          id: string
          readableId: string
          acCableLosses: number
          acCapacity: number
          altitude: number
          annualDegradation: number
          cod: any
          createdAt: any
          downtimeRate: number
          gridZone: GridZone
          inverterLosses: number
          inverterWarrantyPeriod: number
          isUsingAdvancedLosses: boolean
          latitude: number
          lifetime: number
          locationName: string
          longitude: number
          meteringDate: number
          metiRegistrationid: string
          metiid: string
          name: string
          shadingLosses: number
          simulationStatus: SimulationStatus
          status: AssetStatus
          subsidyRate?: number | null
          subsidyScheme: SubsidyScheme
          subsidyStartDate?: any | null
          updatedAt: any
          utilityid: string
          gridCode?: string | null
          contractClassificationNumber2?: string | null
          assetManagementCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          balancingGroup: { __typename?: 'BalancingGroup'; id: string; balancingGroupCode: string }
          decommissionReserveCosts: Array<{
            __typename?: 'OpexCostSet'
            year: number
            value: number
          }>
          insuranceCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          inverterCost: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          landLeaseCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          omCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          otherCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          propertyTaxRate: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
          developer?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          epcCompany?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          omProvider?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          macroScenario: { __typename?: 'MacroScenario'; id: string; name: string }
          capexPayments: Array<{
            __typename?: 'CAPEXPayment'
            id: string
            date: any
            amount: number
            category: CapexCategory
            description?: string | null
            consumptionTax: boolean
          } | null>
          pvArray: Array<{
            __typename?: 'PVModuleGeneric'
            id: string
            moduleType: ModuleType
            mountType: MountType
            tilt: number
            azimuth: number
            moduleParameters: {
              __typename?: 'PVModuleGenericParams'
              pdc0: number
              gammaPdc?: number | null
            }
          } | null>
          battery?: {
            __typename?: 'Battery'
            cycleCost: number
            energyCapacity: number
            expectedCycleLife: number
            id: string
            isDeleted: boolean
            maximumStateOfCharge: number
            minimumStateOfCharge: number
            operationalStartDate: any
            powerCapacity: number
            roundtripEfficiency: number
            status: BatteryStatus
            warrantyPeriod: number
          } | null
          load?: {
            __typename?: 'SolarAssetLoad'
            averageLoad: number
            id: string
            isDeleted: boolean
            maximumLoad: number
            minimumLoad: number
            name: string
            notes: string
            operationalStartDate: any
            status: SolarAssetLoadStatus
            type: SolarAssetLoadType
          } | null
        }
      } | null
    }
  }
}

export type DeleteSolarAssetMutationVariables = Exact<{
  input: DeleteSolarAssetInput
}>

export type DeleteSolarAssetMutation = {
  __typename?: 'Mutation'
  solarAsset: {
    __typename?: 'SolarAssetMutations'
    delete: {
      __typename?: 'DeleteSolarAssetPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'DeleteSolarAssetData'
        recordId: string
        record: {
          __typename?: 'SolarAsset'
          id: string
          readableId: string
          acCableLosses: number
          acCapacity: number
          altitude: number
          annualDegradation: number
          cod: any
          createdAt: any
          downtimeRate: number
          gridZone: GridZone
          inverterLosses: number
          inverterWarrantyPeriod: number
          isUsingAdvancedLosses: boolean
          latitude: number
          lifetime: number
          locationName: string
          longitude: number
          meteringDate: number
          metiRegistrationid: string
          metiid: string
          name: string
          shadingLosses: number
          simulationStatus: SimulationStatus
          status: AssetStatus
          subsidyRate?: number | null
          subsidyScheme: SubsidyScheme
          subsidyStartDate?: any | null
          updatedAt: any
          utilityid: string
          gridCode?: string | null
          contractClassificationNumber2?: string | null
          assetManagementCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          balancingGroup: { __typename?: 'BalancingGroup'; id: string; balancingGroupCode: string }
          decommissionReserveCosts: Array<{
            __typename?: 'OpexCostSet'
            year: number
            value: number
          }>
          insuranceCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          inverterCost: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          landLeaseCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          omCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          otherCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          propertyTaxRate: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
          developer?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          epcCompany?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          omProvider?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          macroScenario: { __typename?: 'MacroScenario'; id: string; name: string }
          capexPayments: Array<{
            __typename?: 'CAPEXPayment'
            id: string
            date: any
            amount: number
            category: CapexCategory
            description?: string | null
            consumptionTax: boolean
          } | null>
          pvArray: Array<{
            __typename?: 'PVModuleGeneric'
            id: string
            moduleType: ModuleType
            mountType: MountType
            tilt: number
            azimuth: number
            moduleParameters: {
              __typename?: 'PVModuleGenericParams'
              pdc0: number
              gammaPdc?: number | null
            }
          } | null>
          battery?: {
            __typename?: 'Battery'
            cycleCost: number
            energyCapacity: number
            expectedCycleLife: number
            id: string
            isDeleted: boolean
            maximumStateOfCharge: number
            minimumStateOfCharge: number
            operationalStartDate: any
            powerCapacity: number
            roundtripEfficiency: number
            status: BatteryStatus
            warrantyPeriod: number
          } | null
          load?: {
            __typename?: 'SolarAssetLoad'
            averageLoad: number
            id: string
            isDeleted: boolean
            maximumLoad: number
            minimumLoad: number
            name: string
            notes: string
            operationalStartDate: any
            status: SolarAssetLoadStatus
            type: SolarAssetLoadType
          } | null
        }
      } | null
    }
  }
}

export type DeleteSolarAssetsMutationVariables = Exact<{
  input: DeleteManySolarAssetsInput
  queryInput: SolarAssetListInput
}>

export type DeleteSolarAssetsMutation = {
  __typename?: 'Mutation'
  solarAssets: {
    __typename?: 'SolarAssetsMutations'
    deleteMany: {
      __typename?: 'DeleteManySolarAssetsPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: Array<{
        __typename?: 'DeleteManySolarAssetsData'
        recordId: string
        record: {
          __typename?: 'SolarAsset'
          id: string
          readableId: string
          acCableLosses: number
          acCapacity: number
          altitude: number
          annualDegradation: number
          cod: any
          createdAt: any
          downtimeRate: number
          gridZone: GridZone
          inverterLosses: number
          inverterWarrantyPeriod: number
          isUsingAdvancedLosses: boolean
          latitude: number
          lifetime: number
          locationName: string
          longitude: number
          meteringDate: number
          metiRegistrationid: string
          metiid: string
          name: string
          shadingLosses: number
          simulationStatus: SimulationStatus
          status: AssetStatus
          subsidyRate?: number | null
          subsidyScheme: SubsidyScheme
          subsidyStartDate?: any | null
          updatedAt: any
          utilityid: string
          gridCode?: string | null
          contractClassificationNumber2?: string | null
          assetManagementCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          balancingGroup: { __typename?: 'BalancingGroup'; id: string; balancingGroupCode: string }
          decommissionReserveCosts: Array<{
            __typename?: 'OpexCostSet'
            year: number
            value: number
          }>
          insuranceCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          inverterCost: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          landLeaseCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          omCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          otherCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          propertyTaxRate: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
          ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
          developer?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          epcCompany?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          omProvider?: {
            __typename?: 'ThirdPartyCompany'
            id: string
            name: string
            createdAt: any
            type: ThirdPartyCompanyType
          } | null
          macroScenario: { __typename?: 'MacroScenario'; id: string; name: string }
          capexPayments: Array<{
            __typename?: 'CAPEXPayment'
            id: string
            date: any
            amount: number
            category: CapexCategory
            description?: string | null
            consumptionTax: boolean
          } | null>
          pvArray: Array<{
            __typename?: 'PVModuleGeneric'
            id: string
            moduleType: ModuleType
            mountType: MountType
            tilt: number
            azimuth: number
            moduleParameters: {
              __typename?: 'PVModuleGenericParams'
              pdc0: number
              gammaPdc?: number | null
            }
          } | null>
          battery?: {
            __typename?: 'Battery'
            cycleCost: number
            energyCapacity: number
            expectedCycleLife: number
            id: string
            isDeleted: boolean
            maximumStateOfCharge: number
            minimumStateOfCharge: number
            operationalStartDate: any
            powerCapacity: number
            roundtripEfficiency: number
            status: BatteryStatus
            warrantyPeriod: number
          } | null
          load?: {
            __typename?: 'SolarAssetLoad'
            averageLoad: number
            id: string
            isDeleted: boolean
            maximumLoad: number
            minimumLoad: number
            name: string
            notes: string
            operationalStartDate: any
            status: SolarAssetLoadStatus
            type: SolarAssetLoadType
          } | null
        }
      }> | null
      query?: {
        __typename?: 'Query'
        solarAssetList: {
          __typename?: 'SolarAssetList'
          records: Array<{
            __typename?: 'SolarAsset'
            id: string
            readableId: string
            acCapacity: number
            cod: any
            createdAt: any
            dcCapacity: number
            gridZone: GridZone
            locationName: string
            name: string
            simulationStatus: SimulationStatus
            subsidyScheme: SubsidyScheme
            status: AssetStatus
            updatedAt: any
            utilityid: string
            userUpdatedAt: any
            ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
            pvArray: Array<{
              __typename?: 'PVModuleGeneric'
              moduleParameters: { __typename?: 'PVModuleGenericParams'; pdc0: number }
            } | null>
          }>
          pageInfo: {
            __typename?: 'PaginationInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            isEmpty: boolean
            page: number
            perPage: number
            totalPages: number
            totalRecords: number
          }
        }
      } | null
    }
  }
}

export type RenameSolarAssetMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId: Scalars['ID']['input']
  name: Scalars['String']['input']
}>

export type RenameSolarAssetMutation = {
  __typename?: 'Mutation'
  solarAsset: {
    __typename?: 'SolarAssetMutations'
    update: {
      __typename?: 'UpdateSolarAssetPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'BatteryValidationProblem'; message: string }
        | { __typename?: 'CAPEXPaymentValidationProblem'; message: string }
        | { __typename?: 'SolarAssetAlreadyExistsProblem'; message: string }
        | { __typename?: 'SolarAssetLoadValidationProblem'; message: string }
        | { __typename?: 'SolarAssetPropertiesCannotBeModifiedProblem'; message: string }
        | { __typename?: 'SolarAssetResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'UpdateSolarAssetData'
        recordId: string
        record: { __typename?: 'SolarAsset'; id: string; name: string }
      } | null
    }
  }
}

export type AddSolarAssetsToPpaMutationVariables = Exact<{
  input: AddSolarAssetsPpaInput
}>

export type AddSolarAssetsToPpaMutation = {
  __typename?: 'Mutation'
  solarAssets: {
    __typename?: 'SolarAssetsMutations'
    addPpa: {
      __typename?: 'AddSolarAssetsPpaPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: Array<{
        __typename?: 'AddSolarAssetsPpaData'
        recordId: string
        record: {
          __typename?: 'SolarAsset'
          id: string
          name: string
          ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
        }
      }> | null
    }
  }
}

export type CopySolarAssetMutationVariables = Exact<{
  input: CopySolarAssetInput
}>

export type CopySolarAssetMutation = {
  __typename?: 'Mutation'
  solarAsset: {
    __typename?: 'SolarAssetMutations'
    copy: {
      __typename?: 'CopySolarAssetPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: Array<{ __typename?: 'CopySolarAssetData'; recordId: string }> | null
    }
  }
}

export type ModifyAssetPlanMutationVariables = Exact<{
  input: ModifySolarAssetPlanInput
  start: Scalars['Time']['input']
  end: Scalars['Time']['input']
}>

export type ModifyAssetPlanMutation = {
  __typename?: 'Mutation'
  solarAsset: {
    __typename?: 'SolarAssetMutations'
    modifyAssetPlan: {
      __typename?: 'ModifySolarAssetPlanPayload'
      success: boolean
      data: {
        __typename?: 'ModifySolarAssetPlanData'
        recordId: string
        record: {
          __typename?: 'SolarAsset'
          id: string
          operations?: {
            __typename?: 'SolarAssetOperations'
            solarGenerationForecast: {
              __typename?: 'HighPerformanceNullableNumberSeries'
              startTime: any
              endTime: any
              interval: number
              data: Array<number | null>
            }
          } | null
        }
      }
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
        | { __typename?: 'SolarAssetPlanDoesNotMatchProblem'; message: string }
      > | null
    }
  }
}

export type TriggerSolarAssetSimulationMutationVariables = Exact<{
  input: TriggerSolarAssetSimulationInput
}>

export type TriggerSolarAssetSimulationMutation = {
  __typename?: 'Mutation'
  solarAsset: {
    __typename?: 'SolarAssetMutations'
    triggerSimulation: {
      __typename?: 'TriggerSolarAssetSimulationPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      data?: { __typename?: 'TriggerSolarAssetSimulationData'; recordId: string } | null
    }
  }
}

export type SolarAssetQueryVariables = Exact<{
  input: SolarAssetInput
}>

export type SolarAssetQuery = {
  __typename?: 'Query'
  solarAsset: {
    __typename?: 'SolarAsset'
    id: string
    readableId: string
    acCableLosses: number
    acCapacity: number
    altitude: number
    annualDegradation: number
    cod: any
    createdAt: any
    downtimeRate: number
    gridZone: GridZone
    inverterLosses: number
    inverterWarrantyPeriod: number
    isUsingAdvancedLosses: boolean
    latitude: number
    lifetime: number
    locationName: string
    longitude: number
    meteringDate: number
    metiRegistrationid: string
    metiid: string
    name: string
    shadingLosses: number
    simulationStatus: SimulationStatus
    status: AssetStatus
    subsidyRate?: number | null
    subsidyScheme: SubsidyScheme
    subsidyStartDate?: any | null
    updatedAt: any
    utilityid: string
    gridCode?: string | null
    contractClassificationNumber2?: string | null
    assetManagementCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
    balancingGroup: { __typename?: 'BalancingGroup'; id: string; balancingGroupCode: string }
    decommissionReserveCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
    insuranceCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
    inverterCost: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
    landLeaseCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
    omCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
    otherCosts: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
    propertyTaxRate: Array<{ __typename?: 'OpexCostSet'; year: number; value: number }>
    ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
    developer?: {
      __typename?: 'ThirdPartyCompany'
      id: string
      name: string
      createdAt: any
      type: ThirdPartyCompanyType
    } | null
    epcCompany?: {
      __typename?: 'ThirdPartyCompany'
      id: string
      name: string
      createdAt: any
      type: ThirdPartyCompanyType
    } | null
    omProvider?: {
      __typename?: 'ThirdPartyCompany'
      id: string
      name: string
      createdAt: any
      type: ThirdPartyCompanyType
    } | null
    macroScenario: { __typename?: 'MacroScenario'; id: string; name: string }
    capexPayments: Array<{
      __typename?: 'CAPEXPayment'
      id: string
      date: any
      amount: number
      category: CapexCategory
      description?: string | null
      consumptionTax: boolean
    } | null>
    pvArray: Array<{
      __typename?: 'PVModuleGeneric'
      id: string
      moduleType: ModuleType
      mountType: MountType
      tilt: number
      azimuth: number
      moduleParameters: {
        __typename?: 'PVModuleGenericParams'
        pdc0: number
        gammaPdc?: number | null
      }
    } | null>
    battery?: {
      __typename?: 'Battery'
      cycleCost: number
      energyCapacity: number
      expectedCycleLife: number
      id: string
      isDeleted: boolean
      maximumStateOfCharge: number
      minimumStateOfCharge: number
      operationalStartDate: any
      powerCapacity: number
      roundtripEfficiency: number
      status: BatteryStatus
      warrantyPeriod: number
    } | null
    load?: {
      __typename?: 'SolarAssetLoad'
      averageLoad: number
      id: string
      isDeleted: boolean
      maximumLoad: number
      minimumLoad: number
      name: string
      notes: string
      operationalStartDate: any
      status: SolarAssetLoadStatus
      type: SolarAssetLoadType
    } | null
  }
}

export type SolarAssetsQueryVariables = Exact<{
  input: SolarAssetListInput
}>

export type SolarAssetsQuery = {
  __typename?: 'Query'
  solarAssetList: {
    __typename?: 'SolarAssetList'
    records: Array<{
      __typename?: 'SolarAsset'
      id: string
      readableId: string
      acCapacity: number
      cod: any
      createdAt: any
      dcCapacity: number
      gridZone: GridZone
      locationName: string
      name: string
      simulationStatus: SimulationStatus
      subsidyScheme: SubsidyScheme
      status: AssetStatus
      updatedAt: any
      utilityid: string
      userUpdatedAt: any
      ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
      pvArray: Array<{
        __typename?: 'PVModuleGeneric'
        moduleParameters: { __typename?: 'PVModuleGenericParams'; pdc0: number }
      } | null>
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
}

export type SolarAssetsForPpasQueryVariables = Exact<{
  input: SolarAssetListInput
  filter?: InputMaybe<SolarAssetFilter>
  sort?: InputMaybe<SolarAssetSort>
}>

export type SolarAssetsForPpasQuery = {
  __typename?: 'Query'
  solarAssetList: {
    __typename?: 'SolarAssetList'
    records: Array<{
      __typename?: 'SolarAsset'
      id: string
      readableId: string
      acCapacity: number
      cod: any
      createdAt: any
      dcCapacity: number
      gridZone: GridZone
      locationName: string
      name: string
      simulationStatus: SimulationStatus
      subsidyScheme: SubsidyScheme
      status: AssetStatus
      updatedAt: any
      utilityid: string
      userUpdatedAt: any
      ppa?: { __typename?: 'PpaX'; id: string; name: string } | null
      pvArray: Array<{
        __typename?: 'PVModuleGeneric'
        moduleParameters: { __typename?: 'PVModuleGenericParams'; pdc0: number }
      } | null>
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
}

export type SolarAssetBatteryChargeScheduleQueryVariables = Exact<{
  input: SolarAssetInput
  start: Scalars['Time']['input']
  end: Scalars['Time']['input']
}>

export type SolarAssetBatteryChargeScheduleQuery = {
  __typename?: 'Query'
  solarAsset: {
    __typename?: 'SolarAsset'
    name: string
    id: string
    readableId: string
    operations?: {
      __typename?: 'SolarAssetOperations'
      solarGenerationForecast: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        interval: number
        startTime: any
      }
    } | null
    battery?: {
      __typename?: 'Battery'
      isDeleted: boolean
      batteryChargeForecast: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        interval: number
        startTime: any
      }
    } | null
    load?: {
      __typename?: 'SolarAssetLoad'
      isDeleted: boolean
      demandForecast: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        interval: number
        startTime: any
      }
    } | null
  }
}

export type SolarAssetBaseQueryVariables = Exact<{
  input: SolarAssetInput
}>

export type SolarAssetBaseQuery = {
  __typename?: 'Query'
  solarAsset: {
    __typename?: 'SolarAsset'
    name: string
    status: AssetStatus
    updatedAt: any
    simulationStatus: SimulationStatus
    gridZone: GridZone
    id: string
    readableId: string
    battery?: { __typename?: 'Battery'; id: string; isDeleted: boolean } | null
    load?: { __typename?: 'SolarAssetLoad'; id: string; isDeleted: boolean } | null
    simulationSummary?: {
      __typename?: 'SimulationSummary'
      irr: { __typename?: 'SSProperty'; p50?: number | null }
    } | null
  }
}

export type SolarAssetProjectQueryVariables = Exact<{
  input: SolarAssetInput
}>

export type SolarAssetProjectQuery = {
  __typename?: 'Query'
  solarAsset: {
    __typename?: 'SolarAsset'
    name: string
    status: AssetStatus
    locationName: string
    latitude: number
    longitude: number
    gridZone: GridZone
    utilityid: string
    subsidyScheme: SubsidyScheme
    subsidyRate?: number | null
    subsidyStartDate?: any | null
    cod: any
    dcCapacity: number
    acCapacity: number
    lifetime: number
    inverterWarrantyPeriod: number
    id: string
    readableId: string
    spv?: {
      __typename?: 'Spv'
      id: string
      legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
    } | null
    ppa?: {
      __typename?: 'PpaX'
      id: string
      name: string
      term: number
      type: PpaType
      status: PpaStatus
      pricingScheme: PricingScheme
      startDate: any
    } | null
    balancingGroup: { __typename?: 'BalancingGroup'; id: string; balancingGroupCode: string }
    macroScenario: { __typename?: 'MacroScenario'; id: string; name: string; isDefault: boolean }
    capexPayments: Array<{
      __typename?: 'CAPEXPayment'
      id: string
      date: any
      amount: number
      description?: string | null
      consumptionTax: boolean
      category: CapexCategory
    } | null>
    pvArray: Array<{
      __typename?: 'PVModuleGeneric'
      moduleType: ModuleType
      tilt: number
      azimuth: number
    } | null>
    battery?: {
      __typename?: 'Battery'
      energyCapacity: number
      powerCapacity: number
      expectedCycleLife: number
      warrantyPeriod: number
      operationalStartDate: any
      isDeleted: boolean
    } | null
    load?: {
      __typename?: 'SolarAssetLoad'
      id: string
      name: string
      status: SolarAssetLoadStatus
      maximumLoad: number
      notes: string
      isDeleted: boolean
    } | null
  }
}

export type SolarAssetSimulationQueryVariables = Exact<{
  input: SolarAssetInput
}>

export type SolarAssetSimulationQuery = {
  __typename?: 'Query'
  solarAsset: {
    __typename?: 'SolarAsset'
    name: string
    status: AssetStatus
    annualDegradation: number
    acCableLosses: number
    shadingLosses: number
    inverterLosses: number
    downtimeRate: number
    subsidyScheme: SubsidyScheme
    id: string
    readableId: string
    simulationSummary?: {
      __typename?: 'SimulationSummary'
      solarYieldFirstYear?: number | null
      id: string
      lifetimeCurtailmentLosses?: number | null
      opexLifetimeTotal: number
      taxLifetimeTotal: number
      averageSubsidyRevenue?: number | null
      marketRevenueLifetimeTotal: number
      subsidyRevenueLifetimeTotal: number
      ppaCashflowLifetimeTotal: number
      totalLifetimeRevenue: number
      capexLifetimeTotal: number
      netCashflowLifetimeTotal: number
      assetManagementLifetimeTotal: number
      decommissionReserveLifetimeTotal: number
      hardwareReplacementLifetimeTotal: number
      insuranceLifetimeTotal: number
      landLeaseLifetimeTotal: number
      omLifetimeTotal: number
      otherLifetimeTotal: number
      solarYieldDistributionFirstYear: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      temperatureLosses: { __typename?: 'SSProperty'; p50?: number | null }
      opexTotalCostsMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      opexTotalCostsYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      netGeneration: { __typename?: 'SSProperty'; p50?: number | null }
      irr: { __typename?: 'SSProperty'; p50?: number | null }
      netRevenue: { __typename?: 'SSProperty'; p50?: number | null }
      lifetimeNetRevenue: { __typename?: 'SSProperty'; p50?: number | null }
      curtailmentMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      curtailmentYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      totalGeneration: { __typename?: 'SSProperty'; p50?: number | null }
      netGenerationMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      marketRevenueMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      subsidyRevenueMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      ppaCashflowMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      capexMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      operatingCashflowMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      netCashflowMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      cumulativeNetCashflowMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      netGenerationYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      marketRevenueYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      subsidyRevenueYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      ppaCashflowYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      capexYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      operatingCashflowYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      netCashflowYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      cumulativeNetCashflowYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      assetManagementCostsMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      assetManagementCostsYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      decommissionReserveCostsMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      decommissionReserveCostsYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      hardwareReplacementCostsMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      hardwareReplacementCostsYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      insuranceCostsMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      insuranceCostsYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      landLeaseCostsMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      landLeaseCostsYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      omCostsMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      omCostsYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      otherCostsMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      otherCostsYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      taxCostsMonthlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      taxCostsYearlySeries: {
        __typename?: 'SingleSeries'
        set: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
    } | null
    capexPayments: Array<{
      __typename?: 'CAPEXPayment'
      id: string
      date: any
      amount: number
      description?: string | null
      consumptionTax: boolean
      category: CapexCategory
    } | null>
  }
}

export type SolarAssetVisualOverviewQueryVariables = Exact<{
  input: SolarAssetInput
}>

export type SolarAssetVisualOverviewQuery = {
  __typename?: 'Query'
  solarAsset: {
    __typename?: 'SolarAsset'
    dcCapacity: number
    acCapacity: number
    id: string
    readableId: string
    operations?: {
      __typename?: 'SolarAssetOperations'
      realTimeSolarGeneration?: number | null
      realTimeTotalEnergyExported?: number | null
    } | null
    battery?: {
      __typename?: 'Battery'
      id: string
      energyCapacity: number
      powerCapacity: number
      totalCyclesUsed: number
      isDeleted: boolean
      liveStatus: BatteryLiveStatus
      currentStateOfCharge?: number | null
    } | null
    load?: {
      __typename?: 'SolarAssetLoad'
      id: string
      maximumLoad: number
      minimumLoad: number
      isDeleted: boolean
      realTimeDemand?: number | null
    } | null
  }
}

export type SolarAssetBatteryDashboardQueryVariables = Exact<{
  input: SolarAssetInput
  start: Scalars['Time']['input']
  end: Scalars['Time']['input']
  gridZone: GridZone
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SolarAssetBatteryDashboardQuery = {
  __typename?: 'Query'
  solarAsset: {
    __typename?: 'SolarAsset'
    name: string
    id: string
    readableId: string
    operations?: {
      __typename?: 'SolarAssetOperations'
      liveStatus: SolarAssetLiveStatus
      curtailmentActuals: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
      solarGenerationActuals: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
      solarGenerationDayAheadForecast: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
      totalEnergyExportedActuals: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
      totalEnergyExportedForecast: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
      alarms: Array<
        | {
            __typename: 'SolarAssetOperationAlarmEventBatteryFault'
            createdAt: any
            resolvedAt?: any | null
            isResolved: boolean
          }
        | {
            __typename: 'SolarAssetOperationAlarmEventBatteryScheduledModified'
            createdAt: any
            resolvedAt?: any | null
            isResolved: boolean
            metadata: {
              __typename?: 'SolarAssetOperationAlarmEventBatteryScheduledModifiedMetadata'
              scheduleDate: any
              actor: { __typename?: 'Actor'; id: string; firstName: string; lastName: string }
            }
          }
        | {
            __typename: 'SolarAssetOperationAlarmEventHighLatency'
            createdAt: any
            resolvedAt?: any | null
            isResolved: boolean
          }
        | {
            __typename: 'SolarAssetOperationAlarmEventLossBatteryCommunication'
            createdAt: any
            resolvedAt?: any | null
            isResolved: boolean
          }
        | {
            __typename: 'SolarAssetOperationAlarmEventLossMeterCommunication'
            createdAt: any
            resolvedAt?: any | null
            isResolved: boolean
          }
        | {
            __typename: 'SolarAssetOperationAlarmEventManualScheduleOperation'
            createdAt: any
            resolvedAt?: any | null
            isResolved: boolean
          }
      >
    } | null
    battery?: {
      __typename?: 'Battery'
      id: string
      liveStatus: BatteryLiveStatus
      totalEnergyDischarged: number
      warrantyCycleLimit: number
      totalCyclesUsed: number
      isDeleted: boolean
      batteryChargeActuals: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
      batteryChargeForecast: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
    } | null
    load?: {
      __typename?: 'SolarAssetLoad'
      id: string
      isDeleted: boolean
      demandActuals: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
      demandForecast: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
    } | null
  }
  marketIntelligence: {
    __typename?: 'MarketIntelligence'
    dayAhead?: {
      __typename?: 'MarketIntelligenceDayAhead'
      zonePriceSeries: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
    } | null
    imbalance?: {
      __typename?: 'MarketIntelligenceImbalance'
      zonePriceSeries: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
    } | null
    priceForecast?: {
      __typename?: 'MarketIntelligencePriceForecast'
      zoneDayAheadPriceSeries: {
        __typename?: 'HighPerformanceNullableNumberSeries'
        data: Array<number | null>
        endTime: any
        startTime: any
        interval: number
      }
    } | null
  }
}

export type SpvIdDataFragment = { __typename?: 'Spv'; id: string; readableId: string }

export type SpvsListDataFragment = {
  __typename?: 'Spv'
  id: string
  readableId: string
  totalDcCapacity: number
  totalAssets: number
  updatedAt: any
  createdAt: any
  legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
}

export type SpvOverviewQueryDataFragment = {
  __typename?: 'Spv'
  id: string
  readableId: string
  totalDcCapacity: number
  totalAssets: number
  legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
  investments: Array<{ __typename?: 'SpvInvestment'; amount: number }>
  loans: Array<{ __typename?: 'SpvLoan'; amount: number }>
  assets: Array<{
    __typename?: 'SolarAsset'
    id: string
    readableId: string
    acCapacity: number
    dcCapacity: number
    name: string
    locationName: string
    cod: any
  }>
}

export type SpvLoanDataFragment = {
  __typename?: 'SpvLoan'
  id: string
  description: string
  amount: number
  interestRate: number
  lenderName: string
  repaymentFrequency: RepaymentFrequency
  repaymentStartDate: any
  repaymentEndDate: any
  loanReceivedDate: any
}

export type SpvInvestmentDataFragment = {
  __typename?: 'SpvInvestment'
  id: string
  amount: number
  investmentDate: any
  investorName: string
}

export type SpvCrudDataFragment = {
  __typename?: 'Spv'
  id: string
  readableId: string
  companyType: SpvCompanyType
  companyRegistrationId?: string | null
  legalEntityCreationDate?: any | null
  financialYear: Month
  couponPaymentFrequency: CouponPaymentFrequency
  couponPaymentMonth: Month
  couponPayoutPercentage: number
  targetDSCR?: number | null
  legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
  investments: Array<{
    __typename?: 'SpvInvestment'
    id: string
    amount: number
    investmentDate: any
    investorName: string
  }>
  loans: Array<{
    __typename?: 'SpvLoan'
    id: string
    description: string
    amount: number
    interestRate: number
    lenderName: string
    repaymentFrequency: RepaymentFrequency
    repaymentStartDate: any
    repaymentEndDate: any
    loanReceivedDate: any
  }>
  assets: Array<{
    __typename?: 'SolarAsset'
    id: string
    readableId: string
    name: string
    dcCapacity: number
    locationName: string
  }>
}

export type CreateSpvMutationVariables = Exact<{
  input: CreateSpvInput
}>

export type CreateSpvMutation = {
  __typename?: 'Mutation'
  spv: {
    __typename?: 'SpvMutations'
    create: {
      __typename?: 'CreateSpvPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceIsAlreadyInUseProblem'; message: string; recordId: string }
        | {
            __typename?: 'SpvResourceDoesNotExistProblem'
            message: string
            recordId: string
            type: SpvResourceDoesNotExistProblemType
          }
      > | null
      data?: {
        __typename?: 'CreateSpvData'
        recordId: string
        record: {
          __typename?: 'Spv'
          id: string
          legalEntityName: { __typename?: 'SpvLegalEntityName'; ja: string; en?: string | null }
        }
      } | null
    }
  }
}

export type UpdateSpvMutationVariables = Exact<{
  input: UpdateSpvInput
}>

export type UpdateSpvMutation = {
  __typename?: 'Mutation'
  spv: {
    __typename?: 'SpvMutations'
    update: {
      __typename?: 'UpdateSpvPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceIsAlreadyInUseProblem'; message: string; recordId: string }
        | {
            __typename?: 'SpvInvestmentValidationProblem'
            message: string
            recordId: string
            record: {
              __typename?: 'SpvInvestment'
              id: string
              amount: number
              investmentDate: any
              investorName: string
            }
          }
        | {
            __typename?: 'SpvLoanValidationProblem'
            message: string
            recordId: string
            record: {
              __typename?: 'SpvLoan'
              id: string
              description: string
              amount: number
              interestRate: number
              lenderName: string
              repaymentFrequency: RepaymentFrequency
              repaymentStartDate: any
              repaymentEndDate: any
              loanReceivedDate: any
            }
          }
        | {
            __typename?: 'SpvResourceDoesNotExistProblem'
            message: string
            recordId: string
            type: SpvResourceDoesNotExistProblemType
          }
      > | null
      data?: {
        __typename?: 'UpdateSpvData'
        recordId: string
        record: {
          __typename?: 'Spv'
          id: string
          readableId: string
          companyType: SpvCompanyType
          companyRegistrationId?: string | null
          legalEntityCreationDate?: any | null
          financialYear: Month
          couponPaymentFrequency: CouponPaymentFrequency
          couponPaymentMonth: Month
          couponPayoutPercentage: number
          targetDSCR?: number | null
          legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
          investments: Array<{
            __typename?: 'SpvInvestment'
            id: string
            amount: number
            investmentDate: any
            investorName: string
          }>
          loans: Array<{
            __typename?: 'SpvLoan'
            id: string
            description: string
            amount: number
            interestRate: number
            lenderName: string
            repaymentFrequency: RepaymentFrequency
            repaymentStartDate: any
            repaymentEndDate: any
            loanReceivedDate: any
          }>
          assets: Array<{
            __typename?: 'SolarAsset'
            id: string
            readableId: string
            name: string
            dcCapacity: number
            locationName: string
          }>
        }
      } | null
    }
  }
}

export type DeleteSpvMutationVariables = Exact<{
  input: DeleteSpvInput
  queryInput: SpvListInput
}>

export type DeleteSpvMutation = {
  __typename?: 'Mutation'
  spv: {
    __typename?: 'SpvMutations'
    delete: {
      __typename?: 'DeleteSpvPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
      data?: {
        __typename?: 'DeleteSpvData'
        recordId: string
        record: {
          __typename?: 'Spv'
          id: string
          readableId: string
          totalDcCapacity: number
          totalAssets: number
          updatedAt: any
          createdAt: any
          legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
        }
      } | null
      query?: {
        __typename?: 'Query'
        spvList: {
          __typename?: 'SpvList'
          pageInfo: {
            __typename?: 'PaginationInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            isEmpty: boolean
            page: number
            perPage: number
            totalPages: number
            totalRecords: number
          }
          records: Array<{
            __typename?: 'Spv'
            id: string
            readableId: string
            totalDcCapacity: number
            totalAssets: number
            updatedAt: any
            createdAt: any
            legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
          }>
        }
      } | null
    }
  }
}

export type GenerateFinancialStatementUrlMutationVariables = Exact<{
  input: GenerateFinancialStatementInput
}>

export type GenerateFinancialStatementUrlMutation = {
  __typename?: 'Mutation'
  spv: {
    __typename?: 'SpvMutations'
    generateFinancialStatement: {
      __typename?: 'GenerateFinancialStatementPayload'
      success: boolean
      data?: string | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
    }
  }
}

export type AddAssetsToSpvMutationVariables = Exact<{
  addAssetsInput: AddAssetsToSpvInput
  spvQueryInput: QueryEntityInput
  solarAssetQueryInput: SolarAssetListInput
  solarAssetFilter: SolarAssetFilter
}>

export type AddAssetsToSpvMutation = {
  __typename?: 'Mutation'
  spv: {
    __typename?: 'SpvMutations'
    addAssets: {
      __typename?: 'AddAssetsToSpvPayload'
      success: boolean
      data?: {
        __typename?: 'AddAssetsToSpvData'
        recordId: string
        record: {
          __typename?: 'Spv'
          legalEntityName: { __typename?: 'SpvLegalEntityName'; ja: string; en?: string | null }
          assets: Array<{ __typename?: 'SolarAsset'; id: string }>
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
        | { __typename?: 'ResourceIsAlreadyInUseProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        spv: {
          __typename?: 'Spv'
          id: string
          readableId: string
          totalDcCapacity: number
          totalAssets: number
          legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
          investments: Array<{ __typename?: 'SpvInvestment'; amount: number }>
          loans: Array<{ __typename?: 'SpvLoan'; amount: number }>
          assets: Array<{
            __typename?: 'SolarAsset'
            id: string
            readableId: string
            acCapacity: number
            dcCapacity: number
            name: string
            locationName: string
            cod: any
          }>
        }
        solarAssetList: {
          __typename?: 'SolarAssetList'
          records: Array<{
            __typename?: 'SolarAsset'
            id: string
            readableId: string
            acCapacity: number
            dcCapacity: number
            name: string
            locationName: string
            cod: any
          }>
        }
      } | null
    }
  }
}

export type RemoveAssetsFromSpvMutationVariables = Exact<{
  input: RemoveAssetsFromSpvInput
  queryInput: QueryEntityInput
}>

export type RemoveAssetsFromSpvMutation = {
  __typename?: 'Mutation'
  spv: {
    __typename?: 'SpvMutations'
    removeAssets: {
      __typename?: 'RemoveAssetsFromSpvPayload'
      success: boolean
      data?: {
        __typename?: 'RemoveAssetsFromSpvData'
        recordId: string
        record: {
          __typename?: 'Spv'
          id: string
          legalEntityName: { __typename?: 'SpvLegalEntityName'; ja: string; en?: string | null }
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        spv: {
          __typename?: 'Spv'
          id: string
          totalAssets: number
          assets: Array<{
            __typename?: 'SolarAsset'
            id: string
            readableId: string
            acCapacity: number
            dcCapacity: number
            name: string
            locationName: string
            cod: any
          }>
        }
      } | null
    }
  }
}

export type SpvsQueryVariables = Exact<{
  input: SpvListInput
}>

export type SpvsQuery = {
  __typename?: 'Query'
  spvList: {
    __typename?: 'SpvList'
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
    records: Array<{
      __typename?: 'Spv'
      id: string
      readableId: string
      totalDcCapacity: number
      totalAssets: number
      updatedAt: any
      createdAt: any
      legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
    }>
  }
}

export type SpvsNameListQueryVariables = Exact<{
  input: SpvListInput
}>

export type SpvsNameListQuery = {
  __typename?: 'Query'
  spvList: {
    __typename?: 'SpvList'
    records: Array<{
      __typename?: 'Spv'
      id: string
      legalEntityName: { __typename?: 'SpvLegalEntityName'; ja: string }
    }>
  }
}

export type SpvOverviewQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type SpvOverviewQuery = {
  __typename?: 'Query'
  spv: {
    __typename?: 'Spv'
    id: string
    readableId: string
    totalDcCapacity: number
    totalAssets: number
    legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
    investments: Array<{ __typename?: 'SpvInvestment'; amount: number }>
    loans: Array<{ __typename?: 'SpvLoan'; amount: number }>
    assets: Array<{
      __typename?: 'SolarAsset'
      id: string
      readableId: string
      acCapacity: number
      dcCapacity: number
      name: string
      locationName: string
      cod: any
    }>
  }
}

export type SpvAvailableAssetsQueryVariables = Exact<{
  input: SolarAssetListInput
  filter: SolarAssetFilter
}>

export type SpvAvailableAssetsQuery = {
  __typename?: 'Query'
  solarAssetList: {
    __typename?: 'SolarAssetList'
    records: Array<{
      __typename?: 'SolarAsset'
      id: string
      readableId: string
      acCapacity: number
      dcCapacity: number
      name: string
      locationName: string
      cod: any
    }>
  }
}

export type PpaAvailableAssetsQueryVariables = Exact<{
  input: SolarAssetListInput
  filter: SolarAssetFilter
}>

export type PpaAvailableAssetsQuery = {
  __typename?: 'Query'
  solarAssetList: {
    __typename?: 'SolarAssetList'
    records: Array<{
      __typename?: 'SolarAsset'
      id: string
      readableId: string
      dcCapacity: number
      name: string
      locationName: string
    }>
  }
}

export type SpvEditViewDataQueryVariables = Exact<{
  input: QueryEntityInput
}>

export type SpvEditViewDataQuery = {
  __typename?: 'Query'
  spv: {
    __typename?: 'Spv'
    id: string
    readableId: string
    companyType: SpvCompanyType
    companyRegistrationId?: string | null
    legalEntityCreationDate?: any | null
    financialYear: Month
    couponPaymentFrequency: CouponPaymentFrequency
    couponPaymentMonth: Month
    couponPayoutPercentage: number
    targetDSCR?: number | null
    legalEntityName: { __typename?: 'SpvLegalEntityName'; en?: string | null; ja: string }
    investments: Array<{
      __typename?: 'SpvInvestment'
      id: string
      amount: number
      investmentDate: any
      investorName: string
    }>
    loans: Array<{
      __typename?: 'SpvLoan'
      id: string
      description: string
      amount: number
      interestRate: number
      lenderName: string
      repaymentFrequency: RepaymentFrequency
      repaymentStartDate: any
      repaymentEndDate: any
      loanReceivedDate: any
    }>
    assets: Array<{
      __typename?: 'SolarAsset'
      id: string
      readableId: string
      name: string
      dcCapacity: number
      locationName: string
    }>
  }
}

export type ThirdPartyListItemFragment = {
  __typename?: 'ThirdPartyCompany'
  id: string
  name: string
  createdAt: any
  type: ThirdPartyCompanyType
}

export type ThirdPartyListQueryFragment = {
  __typename?: 'ThirdPartyCompanyList'
  records: Array<{
    __typename?: 'ThirdPartyCompany'
    id: string
    name: string
    createdAt: any
    type: ThirdPartyCompanyType
  }>
  pageInfo: {
    __typename?: 'PaginationInfo'
    hasNextPage: boolean
    hasPreviousPage: boolean
    isEmpty: boolean
    page: number
    perPage: number
    totalPages: number
    totalRecords: number
  }
}

export type CreateThirdPartyCompanyMutationVariables = Exact<{
  input: CreateThirdPartyCompanyInput
}>

export type CreateThirdPartyCompanyMutation = {
  __typename?: 'Mutation'
  thirdPartyCompany: {
    __typename?: 'ThirdPartyCompanyMutations'
    create: {
      __typename?: 'CreateThirdPartyCompanyPayload'
      success: boolean
      data?: {
        __typename?: 'CreateThirdPartyCompanyData'
        recordId: string
        record: {
          __typename?: 'ThirdPartyCompany'
          id: string
          name: string
          createdAt: any
          type: ThirdPartyCompanyType
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
      > | null
    }
  }
}

export type EditThirdPartyCompanyMutationVariables = Exact<{
  input: RenameThirdPartyCompanyInput
}>

export type EditThirdPartyCompanyMutation = {
  __typename?: 'Mutation'
  thirdPartyCompany: {
    __typename?: 'ThirdPartyCompanyMutations'
    rename: {
      __typename?: 'RenameThirdPartyCompanyPayload'
      success: boolean
      data?: {
        __typename?: 'RenameThirdPartyCompanyData'
        record: {
          __typename?: 'ThirdPartyCompany'
          id: string
          name: string
          createdAt: any
          type: ThirdPartyCompanyType
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem' }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
    }
  }
}

export type DeleteThirdPartyCompanyMutationVariables = Exact<{
  input: DeleteThirdPartyCompanyInput
}>

export type DeleteThirdPartyCompanyMutation = {
  __typename?: 'Mutation'
  thirdPartyCompany: {
    __typename?: 'ThirdPartyCompanyMutations'
    delete: {
      __typename?: 'DeleteThirdPartyCompanyPayload'
      success: boolean
      data?: {
        __typename?: 'DeleteThirdPartyCompanyData'
        record: { __typename?: 'ThirdPartyCompany'; id: string }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
    }
  }
}

export type DeleteManyThirdPartyCompaniesMutationVariables = Exact<{
  input: DeleteManyThirdPartyCompaniesInput
}>

export type DeleteManyThirdPartyCompaniesMutation = {
  __typename?: 'Mutation'
  thirdPartyCompanies: {
    __typename?: 'ThirdPartyCompaniesMutations'
    deleteMany: {
      __typename?: 'DeleteManyThirdPartyCompaniesPayload'
      success: boolean
      data?: Array<{
        __typename?: 'DeleteManyThirdPartyCompaniesData'
        record: { __typename?: 'ThirdPartyCompany'; id: string }
      }> | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
    }
  }
}

export type ThirdPartyCompaniesQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
  filter?: InputMaybe<ThirdPartyCompanyFilter>
}>

export type ThirdPartyCompaniesQuery = {
  __typename?: 'Query'
  thirdPartyCompanyList: {
    __typename?: 'ThirdPartyCompanyList'
    records: Array<{
      __typename?: 'ThirdPartyCompany'
      id: string
      name: string
      createdAt: any
      type: ThirdPartyCompanyType
    }>
    pageInfo: {
      __typename?: 'PaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      isEmpty: boolean
      page: number
      perPage: number
      totalPages: number
      totalRecords: number
    }
  }
}

export type CustomTradeSetupDataFragment = {
  __typename?: 'JepxTradingZone'
  priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
  generationPlan: {
    __typename?: 'GenerationPlan'
    totalGeneration: number
    generatedAt: any
    plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
}

export type JepxTradingZoneDataFragment = {
  __typename?: 'JepxTradingZone'
  status: JepxTradeStatus
  hasPriceActuals: boolean
  priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
  priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
  generationPlan: {
    __typename?: 'GenerationPlan'
    totalGeneration: number
    generatedAt: any
    plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  }
  confirmedTrades?: {
    __typename?: 'JepxTrades'
    date: any
    totalVolume: number
    updatedAt?: any | null
    trades: Array<{ __typename?: 'JepxTradeDetails'; price: number; timeSlot: any; volume: number }>
  } | null
  openPositions?: {
    __typename?: 'JepxTrades'
    date: any
    totalVolume: number
    updatedAt?: any | null
    trades: Array<{ __typename?: 'JepxTradeDetails'; price: number; timeSlot: any; volume: number }>
  } | null
}

export type JepxTradesDataFragment = {
  __typename?: 'JepxTrades'
  date: any
  totalVolume: number
  updatedAt?: any | null
  trades: Array<{ __typename?: 'JepxTradeDetails'; price: number; timeSlot: any; volume: number }>
}

export type JepxTradingDataFragment = {
  __typename?: 'JepxTrading'
  id: string
  date: any
  generatedAt?: any | null
  hasPlan: boolean
  tradingZones: {
    __typename?: 'JepxTradingZones'
    chubu?: {
      __typename?: 'JepxTradingZone'
      status: JepxTradeStatus
      hasPriceActuals: boolean
      priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      generationPlan: {
        __typename?: 'GenerationPlan'
        totalGeneration: number
        generatedAt: any
        plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      confirmedTrades?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
      openPositions?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
    } | null
    chugoku?: {
      __typename?: 'JepxTradingZone'
      status: JepxTradeStatus
      hasPriceActuals: boolean
      priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      generationPlan: {
        __typename?: 'GenerationPlan'
        totalGeneration: number
        generatedAt: any
        plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      confirmedTrades?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
      openPositions?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
    } | null
    hokkaido?: {
      __typename?: 'JepxTradingZone'
      status: JepxTradeStatus
      hasPriceActuals: boolean
      priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      generationPlan: {
        __typename?: 'GenerationPlan'
        totalGeneration: number
        generatedAt: any
        plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      confirmedTrades?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
      openPositions?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
    } | null
    hokuriku?: {
      __typename?: 'JepxTradingZone'
      status: JepxTradeStatus
      hasPriceActuals: boolean
      priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      generationPlan: {
        __typename?: 'GenerationPlan'
        totalGeneration: number
        generatedAt: any
        plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      confirmedTrades?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
      openPositions?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
    } | null
    kansai?: {
      __typename?: 'JepxTradingZone'
      status: JepxTradeStatus
      hasPriceActuals: boolean
      priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      generationPlan: {
        __typename?: 'GenerationPlan'
        totalGeneration: number
        generatedAt: any
        plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      confirmedTrades?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
      openPositions?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
    } | null
    kyushu?: {
      __typename?: 'JepxTradingZone'
      status: JepxTradeStatus
      hasPriceActuals: boolean
      priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      generationPlan: {
        __typename?: 'GenerationPlan'
        totalGeneration: number
        generatedAt: any
        plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      confirmedTrades?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
      openPositions?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
    } | null
    shikoku?: {
      __typename?: 'JepxTradingZone'
      status: JepxTradeStatus
      hasPriceActuals: boolean
      priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      generationPlan: {
        __typename?: 'GenerationPlan'
        totalGeneration: number
        generatedAt: any
        plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      confirmedTrades?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
      openPositions?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
    } | null
    tohoku?: {
      __typename?: 'JepxTradingZone'
      status: JepxTradeStatus
      hasPriceActuals: boolean
      priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      generationPlan: {
        __typename?: 'GenerationPlan'
        totalGeneration: number
        generatedAt: any
        plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      confirmedTrades?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
      openPositions?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
    } | null
    tokyo?: {
      __typename?: 'JepxTradingZone'
      status: JepxTradeStatus
      hasPriceActuals: boolean
      priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
      generationPlan: {
        __typename?: 'GenerationPlan'
        totalGeneration: number
        generatedAt: any
        plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
      }
      confirmedTrades?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
      openPositions?: {
        __typename?: 'JepxTrades'
        date: any
        totalVolume: number
        updatedAt?: any | null
        trades: Array<{
          __typename?: 'JepxTradeDetails'
          price: number
          timeSlot: any
          volume: number
        }>
      } | null
    } | null
  }
  generationPlan?: {
    __typename?: 'GenerationPlan'
    totalGeneration: number
    generatedAt: any
    plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
  } | null
}

export type OpenCustomPositionMutationVariables = Exact<{
  input: OpenCustomJepxPositionInput
  queryInput: JepxTradingInput
}>

export type OpenCustomPositionMutation = {
  __typename?: 'Mutation'
  jepxTrading: {
    __typename?: 'JepxTradingMutations'
    openCustomPosition: {
      __typename?: 'OpenCustomJepxPositionPayload'
      success: boolean
      data?: {
        __typename?: 'OpenCustomJepxPositionData'
        record: {
          __typename?: 'JepxTrades'
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            volume: number
            timeSlot: any
          }>
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'NotWithinTimeFrameProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        jepxTrading: {
          __typename?: 'JepxTrading'
          id: string
          date: any
          generatedAt?: any | null
          hasPlan: boolean
          tradingZones: {
            __typename?: 'JepxTradingZones'
            chubu?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            chugoku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            hokkaido?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            hokuriku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            kansai?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            kyushu?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            shikoku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            tohoku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            tokyo?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
          }
          generationPlan?: {
            __typename?: 'GenerationPlan'
            totalGeneration: number
            generatedAt: any
            plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          } | null
        }
      } | null
    }
  }
}

export type OpenPlannedPositionMutationVariables = Exact<{
  input: OpenPlannedJepxPositionInput
  queryInput: JepxTradingInput
}>

export type OpenPlannedPositionMutation = {
  __typename?: 'Mutation'
  jepxTrading: {
    __typename?: 'JepxTradingMutations'
    openPlannedPosition: {
      __typename?: 'OpenPlannedJepxPositionPayload'
      success: boolean
      data?: {
        __typename?: 'OpenPlannedJepxPositionData'
        record: {
          __typename?: 'JepxTrades'
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            volume: number
            timeSlot: any
          }>
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'NotWithinTimeFrameProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        jepxTrading: {
          __typename?: 'JepxTrading'
          id: string
          date: any
          generatedAt?: any | null
          hasPlan: boolean
          tradingZones: {
            __typename?: 'JepxTradingZones'
            chubu?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            chugoku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            hokkaido?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            hokuriku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            kansai?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            kyushu?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            shikoku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            tohoku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            tokyo?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
          }
          generationPlan?: {
            __typename?: 'GenerationPlan'
            totalGeneration: number
            generatedAt: any
            plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          } | null
        }
      } | null
    }
  }
}

export type JepxDailyTradingConfirmTradeMutationVariables = Exact<{
  input: ConfirmJepxTradeInput
  queryInput: JepxTradingInput
}>

export type JepxDailyTradingConfirmTradeMutation = {
  __typename?: 'Mutation'
  jepxTrading: {
    __typename?: 'JepxTradingMutations'
    confirmTrade: {
      __typename?: 'ConfirmJepxTradePayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'NotWithinTimeFrameProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        jepxTrading: {
          __typename?: 'JepxTrading'
          id: string
          date: any
          generatedAt?: any | null
          hasPlan: boolean
          tradingZones: {
            __typename?: 'JepxTradingZones'
            chubu?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            chugoku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            hokkaido?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            hokuriku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            kansai?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            kyushu?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            shikoku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            tohoku?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
            tokyo?: {
              __typename?: 'JepxTradingZone'
              status: JepxTradeStatus
              hasPriceActuals: boolean
              priceForecast: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              priceActuals: Array<{
                __typename?: 'NilableSingleSeriesSet'
                t: any
                v?: number | null
              }>
              generationPlan: {
                __typename?: 'GenerationPlan'
                totalGeneration: number
                generatedAt: any
                plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
              }
              confirmedTrades?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
              openPositions?: {
                __typename?: 'JepxTrades'
                date: any
                totalVolume: number
                updatedAt?: any | null
                trades: Array<{
                  __typename?: 'JepxTradeDetails'
                  price: number
                  timeSlot: any
                  volume: number
                }>
              } | null
            } | null
          }
          generationPlan?: {
            __typename?: 'GenerationPlan'
            totalGeneration: number
            generatedAt: any
            plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
          } | null
        }
      } | null
    }
  }
}

export type JepxDailyTradingQueryVariables = Exact<{
  input: JepxTradingInput
}>

export type JepxDailyTradingQuery = {
  __typename?: 'Query'
  jepxTrading: {
    __typename?: 'JepxTrading'
    id: string
    date: any
    generatedAt?: any | null
    hasPlan: boolean
    tradingZones: {
      __typename?: 'JepxTradingZones'
      chubu?: {
        __typename?: 'JepxTradingZone'
        status: JepxTradeStatus
        hasPriceActuals: boolean
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        confirmedTrades?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
        openPositions?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
      } | null
      chugoku?: {
        __typename?: 'JepxTradingZone'
        status: JepxTradeStatus
        hasPriceActuals: boolean
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        confirmedTrades?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
        openPositions?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
      } | null
      hokkaido?: {
        __typename?: 'JepxTradingZone'
        status: JepxTradeStatus
        hasPriceActuals: boolean
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        confirmedTrades?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
        openPositions?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
      } | null
      hokuriku?: {
        __typename?: 'JepxTradingZone'
        status: JepxTradeStatus
        hasPriceActuals: boolean
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        confirmedTrades?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
        openPositions?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
      } | null
      kansai?: {
        __typename?: 'JepxTradingZone'
        status: JepxTradeStatus
        hasPriceActuals: boolean
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        confirmedTrades?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
        openPositions?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
      } | null
      kyushu?: {
        __typename?: 'JepxTradingZone'
        status: JepxTradeStatus
        hasPriceActuals: boolean
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        confirmedTrades?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
        openPositions?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
      } | null
      shikoku?: {
        __typename?: 'JepxTradingZone'
        status: JepxTradeStatus
        hasPriceActuals: boolean
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        confirmedTrades?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
        openPositions?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
      } | null
      tohoku?: {
        __typename?: 'JepxTradingZone'
        status: JepxTradeStatus
        hasPriceActuals: boolean
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        confirmedTrades?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
        openPositions?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
      } | null
      tokyo?: {
        __typename?: 'JepxTradingZone'
        status: JepxTradeStatus
        hasPriceActuals: boolean
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        priceActuals: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
        confirmedTrades?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
        openPositions?: {
          __typename?: 'JepxTrades'
          date: any
          totalVolume: number
          updatedAt?: any | null
          trades: Array<{
            __typename?: 'JepxTradeDetails'
            price: number
            timeSlot: any
            volume: number
          }>
        } | null
      } | null
    }
    generationPlan?: {
      __typename?: 'GenerationPlan'
      totalGeneration: number
      generatedAt: any
      plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
    } | null
  }
}

export type JepxCustomTradeQueryVariables = Exact<{
  input: JepxTradingInput
}>

export type JepxCustomTradeQuery = {
  __typename?: 'Query'
  jepxTrading: {
    __typename?: 'JepxTrading'
    date: any
    tradingZones: {
      __typename?: 'JepxTradingZones'
      chubu?: {
        __typename?: 'JepxTradingZone'
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
      chugoku?: {
        __typename?: 'JepxTradingZone'
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
      hokkaido?: {
        __typename?: 'JepxTradingZone'
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
      hokuriku?: {
        __typename?: 'JepxTradingZone'
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
      kansai?: {
        __typename?: 'JepxTradingZone'
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
      kyushu?: {
        __typename?: 'JepxTradingZone'
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
      shikoku?: {
        __typename?: 'JepxTradingZone'
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
      tohoku?: {
        __typename?: 'JepxTradingZone'
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
      tokyo?: {
        __typename?: 'JepxTradingZone'
        priceForecast: Array<{ __typename?: 'NilableSingleSeriesSet'; t: any; v?: number | null }>
        generationPlan: {
          __typename?: 'GenerationPlan'
          totalGeneration: number
          generatedAt: any
          plan: Array<{ __typename?: 'SingleSeriesSet'; t: any; v: number }>
        }
      } | null
    }
  }
}

export type JepxTradingHistoryQueryVariables = Exact<{
  input: JepxTradingHistoryInput
  filter?: InputMaybe<JepxTradingHistoryFilter>
}>

export type JepxTradingHistoryQuery = {
  __typename?: 'Query'
  jepxTradingHistory: Array<{
    __typename?: 'JepxTradingHistory'
    id: string
    status: JepxTradingHistoryStatus
    bidType: JepxTradeBidType
    totalVolume: number
    gridZone: GridZone
    createdAt: any
    date: any
    actor: {
      __typename?: 'Actor'
      id: string
      email: string
      firstName: string
      lastName: string
      profilePicture?: { __typename?: 'ProfilePicture'; small: string } | null
      workspace?: { __typename?: 'Workspace'; id: string } | null
    }
  }>
}

export type UserNotificationPreferencesDataFragment = {
  __typename?: 'User'
  id: string
  preferences?: {
    __typename?: 'UserPreferences'
    isOptedIntoActivityAppNotifications: boolean
    isOptedIntoActivityEmailNotifications: boolean
    isOptedIntoAlertAppNotifications: boolean
    isOptedIntoAlertEmailNotifications: boolean
    isOptedIntoReminderAppNotifications: boolean
    isOptedIntoReminderEmailNotifications: boolean
  } | null
  workspace: { __typename?: 'Workspace'; id: string }
}

export type UpdateUserAccountNotificationPreferencesMutationVariables = Exact<{
  input: ChangeUserPreferencesInput
}>

export type UpdateUserAccountNotificationPreferencesMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'UserMutations'
    changePreferences: {
      __typename?: 'ChangeUserPreferencesPayload'
      success: boolean
      data?: {
        __typename?: 'ChangeUserPreferencesData'
        recordId: string
        record: {
          __typename?: 'User'
          id: string
          preferences?: {
            __typename?: 'UserPreferences'
            isOptedIntoActivityAppNotifications: boolean
            isOptedIntoActivityEmailNotifications: boolean
            isOptedIntoAlertAppNotifications: boolean
            isOptedIntoAlertEmailNotifications: boolean
            isOptedIntoReminderAppNotifications: boolean
            isOptedIntoReminderEmailNotifications: boolean
          } | null
          workspace: { __typename?: 'Workspace'; id: string }
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type UpdateUserLanguagePreferencesMutationVariables = Exact<{
  id: Scalars['ID']['input']
  language: Language
}>

export type UpdateUserLanguagePreferencesMutation = {
  __typename?: 'Mutation'
  userAccount: {
    __typename?: 'UserAccountMutations'
    update: {
      __typename?: 'UpdateUserAccountPayload'
      success: boolean
      data?: { __typename?: 'UpdateUserAccountData'; recordId: string } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type UpdateUserRoleMutationVariables = Exact<{
  input: ChangeUserRoleInput
}>

export type UpdateUserRoleMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'UserMutations'
    changeRole: {
      __typename?: 'ChangeUserRolePayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      data?: {
        __typename?: 'ChangeUserRoleData'
        recordId: string
        record: {
          __typename?: 'User'
          email: string
          id: string
          firstName: string
          lastName: string
          profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
          workspace: { __typename?: 'Workspace'; id: string }
        }
      } | null
    }
  }
}

export type UpdateUserAccountMutationVariables = Exact<{
  input: UpdateUserAccountInput
}>

export type UpdateUserAccountMutation = {
  __typename?: 'Mutation'
  userAccount: {
    __typename?: 'UserAccountMutations'
    update: {
      __typename?: 'UpdateUserAccountPayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      data?: {
        __typename?: 'UpdateUserAccountData'
        recordId: string
        record: {
          __typename?: 'UserAccount'
          id: string
          email: string
          firstName: string
          lastName: string
          createdAt: any
          updatedAt: any
          lastActivityAt: any
          preferences?: {
            __typename?: 'UserPreferences'
            electricalPowerUnit: UserElectricalPowerUnit
          } | null
        }
      } | null
    }
  }
}

export type UpdateUserProfilePictureMutationVariables = Exact<{
  input: UploadProfilePictureInput
}>

export type UpdateUserProfilePictureMutation = {
  __typename?: 'Mutation'
  userAccount: {
    __typename?: 'UserAccountMutations'
    uploadProfilePicture: {
      __typename?: 'UploadProfilePicturePayload'
      success: boolean
      data: {
        __typename?: 'UploadProfilePictureData'
        recordId: string
        record: {
          __typename?: 'UserAccount'
          id: string
          profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
        }
      }
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type RemoveUserProfilePictureMutationVariables = Exact<{
  input: RemoveProfilePictureInput
}>

export type RemoveUserProfilePictureMutation = {
  __typename?: 'Mutation'
  userAccount: {
    __typename?: 'UserAccountMutations'
    removeProfilePicture: {
      __typename?: 'RemoveProfilePicturePayload'
      success: boolean
      data: {
        __typename?: 'RemoveProfilePictureData'
        recordId: string
        record: {
          __typename?: 'UserAccount'
          id: string
          profilePicture?: { __typename?: 'ProfilePicture'; default: string } | null
        }
      }
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type UpdateElectricalUnitPreferencesMutationVariables = Exact<{
  input: UpdateUserAccountInput
}>

export type UpdateElectricalUnitPreferencesMutation = {
  __typename?: 'Mutation'
  userAccount: {
    __typename?: 'UserAccountMutations'
    update: {
      __typename?: 'UpdateUserAccountPayload'
      success: boolean
      data?: {
        __typename?: 'UpdateUserAccountData'
        recordId: string
        record: {
          __typename?: 'UserAccount'
          id: string
          preferences?: {
            __typename?: 'UserPreferences'
            electricalPowerUnit: UserElectricalPowerUnit
          } | null
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type UpdateFileEncodingFormatMutationVariables = Exact<{
  id: Scalars['ID']['input']
  fileEncodingFormat: UserFileEncodingFormat
}>

export type UpdateFileEncodingFormatMutation = {
  __typename?: 'Mutation'
  userAccount: {
    __typename?: 'UserAccountMutations'
    update: {
      __typename?: 'UpdateUserAccountPayload'
      success: boolean
      data?: {
        __typename?: 'UpdateUserAccountData'
        recordId: string
        record: {
          __typename?: 'UserAccount'
          id: string
          preferences?: {
            __typename?: 'UserPreferences'
            fileEncodingFormat: UserFileEncodingFormat
          } | null
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type RemoveUserMutationVariables = Exact<{
  input: RemoveUserInput
}>

export type RemoveUserMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'UserMutations'
    remove: {
      __typename?: 'RemoveUserPayload'
      success: boolean
      data?: string | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
    }
  }
}

export type RequestCognitoEmailUpdateMutationVariables = Exact<{
  input?: InputMaybe<RequestCognitoEmailUpdateInput>
}>

export type RequestCognitoEmailUpdateMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'UserMutations'
    requestCognitoEmailUpdate: {
      __typename?: 'RequestCognitoEmailUpdatePayload'
      success: boolean
      data?: { __typename?: 'RequestCognitoEmailUpdateData'; email: string } | null
      errors?: Array<{ __typename?: 'RequestCognitoEmailUpdateProblems'; message: string }> | null
    }
  }
}

export type ConfirmCognitoEmailUpdateMutationVariables = Exact<{
  input?: InputMaybe<ConfirmCognitoEmailUpdateInput>
}>

export type ConfirmCognitoEmailUpdateMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'UserMutations'
    confirmCognitoEmailUpdate: {
      __typename?: 'ConfirmCognitoEmailUpdatePayload'
      success: boolean
      errors?: Array<{ __typename?: 'ConfirmCognitoEmailUpdateProblems'; message: string }> | null
      query?: {
        __typename?: 'Query'
        currentUserAccount?: {
          __typename?: 'UserAccount'
          id: string
          email: string
          firstName: string
          lastName: string
          createdAt: any
          updatedAt: any
          lastActivityAt: any
          lastUsedWorkspaceId: string
          preferences?: {
            __typename?: 'UserPreferences'
            electricalPowerUnit: UserElectricalPowerUnit
            fileEncodingFormat: UserFileEncodingFormat
            language: Language
          } | null
          profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
          users: Array<{
            __typename?: 'User'
            id: string
            username?: string | null
            role?: UserPermission | null
            workspace: { __typename?: 'Workspace'; id: string; name: string; isBillable: boolean }
          }>
        } | null
      } | null
    }
  }
}

export type CurrentUserAccountQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserAccountQuery = {
  __typename?: 'Query'
  currentUserAccount?: {
    __typename?: 'UserAccount'
    id: string
    email: string
    firstName: string
    lastName: string
    createdAt: any
    updatedAt: any
    lastActivityAt: any
    lastUsedWorkspaceId: string
    preferences?: {
      __typename?: 'UserPreferences'
      electricalPowerUnit: UserElectricalPowerUnit
      fileEncodingFormat: UserFileEncodingFormat
      language: Language
    } | null
    profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
    users: Array<{
      __typename?: 'User'
      id: string
      username?: string | null
      role?: UserPermission | null
      workspace: { __typename?: 'Workspace'; id: string; name: string; isBillable: boolean }
    }>
  } | null
}

export type CurrentUserAccountNotificationPreferencesQueryVariables = Exact<{
  [key: string]: never
}>

export type CurrentUserAccountNotificationPreferencesQuery = {
  __typename?: 'Query'
  currentUserAccount?: {
    __typename?: 'UserAccount'
    users: Array<{
      __typename?: 'User'
      id: string
      preferences?: {
        __typename?: 'UserPreferences'
        isOptedIntoActivityAppNotifications: boolean
        isOptedIntoActivityEmailNotifications: boolean
        isOptedIntoAlertAppNotifications: boolean
        isOptedIntoAlertEmailNotifications: boolean
        isOptedIntoReminderAppNotifications: boolean
        isOptedIntoReminderEmailNotifications: boolean
      } | null
      workspace: { __typename?: 'Workspace'; id: string }
    }>
  } | null
}

export type WorkspaceMemberFragment = {
  __typename?: 'User'
  createdAt: any
  email: string
  lastActivityAt: any
  role?: UserPermission | null
  updatedAt: any
  username?: string | null
  id: string
  firstName: string
  lastName: string
  profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
  workspace: { __typename?: 'Workspace'; id: string }
}

export type OcctoIdDataFragment = {
  __typename?: 'OcctoSubmitterData'
  name?: string | null
  code?: string | null
}

export type OcctoSettingsQueryDataFragment = {
  __typename?: 'Workspace'
  id: string
  isBillable: boolean
  operationalGridZones: Array<GridZone>
  occtoGenerationPlanGenerationMethod: OcctoGenerationPlanGenerationMethod
  occtoSender: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
  occtoSubmitterGridZones: {
    __typename?: 'OcctoSubmitterGridZones'
    chubu: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    chugoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    hokkaido: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    hokuriku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    kansai: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    kyushu: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    shikoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    tohoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    tokyo: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
  }
}

export type ApiKeysQueryDataFragment = {
  __typename?: 'Workspace'
  id: string
  apiKeys: Array<{
    __typename?: 'WorkspaceApiKey'
    createdAt: any
    id: string
    key?: string | null
    lastUsedAt?: any | null
    name: string
    permissions: Array<WorkspaceApiKeyPermission>
  }>
}

export type CreateWorkspaceMutationVariables = Exact<{
  input: CreateWorkspaceInputV2
}>

export type CreateWorkspaceMutation = {
  __typename?: 'Mutation'
  workspace: {
    __typename?: 'WorkspaceMutations'
    createV2: {
      __typename?: 'CreateWorkspacePayload'
      success: boolean
      data?: {
        __typename?: 'CreateWorkspaceData'
        record: { __typename?: 'Workspace'; id: string; name: string }
      } | null
      errors?: Array<
        | {
            __typename?: 'WorkspaceAlreadyExistsProblem'
            message: string
            type: WorkspaceAlreadyExistsProblemType
          }
        | { __typename?: 'WorkspaceInvitationIdProblem'; message: string }
      > | null
      query?: {
        __typename?: 'Query'
        currentUserAccount?: {
          __typename?: 'UserAccount'
          id: string
          email: string
          firstName: string
          lastName: string
          createdAt: any
          updatedAt: any
          lastActivityAt: any
          lastUsedWorkspaceId: string
          preferences?: {
            __typename?: 'UserPreferences'
            electricalPowerUnit: UserElectricalPowerUnit
            fileEncodingFormat: UserFileEncodingFormat
            language: Language
          } | null
          profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
          users: Array<{
            __typename?: 'User'
            id: string
            username?: string | null
            role?: UserPermission | null
            workspace: { __typename?: 'Workspace'; id: string; name: string; isBillable: boolean }
          }>
        } | null
      } | null
    }
  }
}

export type UpdateWorkspaceMutationVariables = Exact<{
  input: UpdateWorkspaceInput
}>

export type UpdateWorkspaceMutation = {
  __typename?: 'Mutation'
  workspace: {
    __typename?: 'WorkspaceMutations'
    update: {
      __typename?: 'UpdateWorkspacePayload'
      success: boolean
      data?: {
        __typename?: 'UpdateWorkspaceData'
        record: { __typename?: 'Workspace'; id: string; name: string }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string }
      > | null
    }
  }
}

export type UpdateWorkspaceOcctoSettingsMutationVariables = Exact<{
  input: UpdateWorkspaceInput
}>

export type UpdateWorkspaceOcctoSettingsMutation = {
  __typename?: 'Mutation'
  workspace: {
    __typename?: 'WorkspaceMutations'
    update: {
      __typename?: 'UpdateWorkspacePayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem' }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      data?: {
        __typename?: 'UpdateWorkspaceData'
        record: {
          __typename?: 'Workspace'
          id: string
          isBillable: boolean
          operationalGridZones: Array<GridZone>
          occtoGenerationPlanGenerationMethod: OcctoGenerationPlanGenerationMethod
          occtoSender: {
            __typename?: 'OcctoSubmitterData'
            name?: string | null
            code?: string | null
          }
          occtoSubmitterGridZones: {
            __typename?: 'OcctoSubmitterGridZones'
            chubu: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
            chugoku: {
              __typename?: 'OcctoSubmitterData'
              name?: string | null
              code?: string | null
            }
            hokkaido: {
              __typename?: 'OcctoSubmitterData'
              name?: string | null
              code?: string | null
            }
            hokuriku: {
              __typename?: 'OcctoSubmitterData'
              name?: string | null
              code?: string | null
            }
            kansai: {
              __typename?: 'OcctoSubmitterData'
              name?: string | null
              code?: string | null
            }
            kyushu: {
              __typename?: 'OcctoSubmitterData'
              name?: string | null
              code?: string | null
            }
            shikoku: {
              __typename?: 'OcctoSubmitterData'
              name?: string | null
              code?: string | null
            }
            tohoku: {
              __typename?: 'OcctoSubmitterData'
              name?: string | null
              code?: string | null
            }
            tokyo: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
          }
        }
      } | null
    }
  }
}

export type AcceptWorkspaceInviteMutationVariables = Exact<{
  input: AcceptWorkspaceInviteInput
}>

export type AcceptWorkspaceInviteMutation = {
  __typename?: 'Mutation'
  userAccount: {
    __typename?: 'UserAccountMutations'
    acceptWorkspaceInvite: {
      __typename?: 'AcceptWorkspaceInvitePayload'
      success: boolean
      errors?: Array<
        | { __typename?: 'ExpiredInvitationProblem'; message: string }
        | { __typename?: 'InvalidInvitationProblem'; message: string }
      > | null
    }
  }
}

export type DeleteApiKeyMutationVariables = Exact<{
  input: DeleteWorkspaceApiKeyInput
  queryInput: Scalars['ID']['input']
}>

export type DeleteApiKeyMutation = {
  __typename?: 'Mutation'
  workspace: {
    __typename?: 'WorkspaceMutations'
    deleteApiKey: {
      __typename?: 'DeleteWorkspaceApiKeyPayload'
      success: boolean
      data?: {
        __typename?: 'DeleteWorkspaceApiKeyData'
        record: { __typename?: 'WorkspaceApiKey'; id: string; name: string }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        workspace?: {
          __typename?: 'Workspace'
          id: string
          apiKeys: Array<{
            __typename?: 'WorkspaceApiKey'
            createdAt: any
            id: string
            key?: string | null
            lastUsedAt?: any | null
            name: string
            permissions: Array<WorkspaceApiKeyPermission>
          }>
        } | null
      } | null
    }
  }
}

export type GenerateApiKeyMutationVariables = Exact<{
  input: GenerateWorkspaceApiKeyInput
  queryInput: Scalars['ID']['input']
}>

export type GenerateApiKeyMutation = {
  __typename?: 'Mutation'
  workspace: {
    __typename?: 'WorkspaceMutations'
    generateApiKey: {
      __typename?: 'GenerateWorkspaceApiKeyPayload'
      success: boolean
      data?: {
        __typename?: 'GenerateWorkspaceApiKeyData'
        record: {
          __typename?: 'WorkspaceApiKey'
          id: string
          key?: string | null
          name: string
          permissions: Array<WorkspaceApiKeyPermission>
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
      > | null
      query?: {
        __typename?: 'Query'
        workspace?: {
          __typename?: 'Workspace'
          id: string
          apiKeys: Array<{
            __typename?: 'WorkspaceApiKey'
            createdAt: any
            id: string
            key?: string | null
            lastUsedAt?: any | null
            name: string
            permissions: Array<WorkspaceApiKeyPermission>
          }>
        } | null
      } | null
    }
  }
}

export type UpdateApiKeyMutationVariables = Exact<{
  input: UpdateWorkspaceApiKeyInput
  queryInput: Scalars['ID']['input']
}>

export type UpdateApiKeyMutation = {
  __typename?: 'Mutation'
  workspace: {
    __typename?: 'WorkspaceMutations'
    updateApiKey: {
      __typename?: 'UpdateWorkspaceApiKeyPayload'
      success: boolean
      data?: {
        __typename?: 'UpdateWorkspaceApiKeyData'
        record: {
          __typename?: 'WorkspaceApiKey'
          id: string
          name: string
          permissions: Array<WorkspaceApiKeyPermission>
        }
      } | null
      errors?: Array<
        | { __typename?: 'AuthorizationProblem'; message: string }
        | { __typename?: 'ResourceAlreadyExistsProblem'; message: string }
        | { __typename?: 'ResourceDoesNotExistProblem'; message: string; recordId: string }
      > | null
      query?: {
        __typename?: 'Query'
        workspace?: {
          __typename?: 'Workspace'
          id: string
          apiKeys: Array<{
            __typename?: 'WorkspaceApiKey'
            createdAt: any
            id: string
            key?: string | null
            lastUsedAt?: any | null
            name: string
            permissions: Array<WorkspaceApiKeyPermission>
          }>
        } | null
      } | null
    }
  }
}

export type WorkspaceDefaultsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
}>

export type WorkspaceDefaultsQuery = {
  __typename?: 'Query'
  balancingGroupList: {
    __typename?: 'BalancingGroupList'
    records: Array<{ __typename?: 'BalancingGroup'; id: string; name: string }>
  }
  workspace?: { __typename?: 'Workspace'; id: string; isBillable: boolean } | null
}

export type WorkspaceMembersQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
}>

export type WorkspaceMembersQuery = {
  __typename?: 'Query'
  workspace?: {
    __typename?: 'Workspace'
    id: string
    members?: Array<{
      __typename?: 'User'
      createdAt: any
      email: string
      lastActivityAt: any
      role?: UserPermission | null
      updatedAt: any
      username?: string | null
      id: string
      firstName: string
      lastName: string
      profilePicture?: { __typename?: 'ProfilePicture'; default: string; small: string } | null
      workspace: { __typename?: 'Workspace'; id: string }
    } | null> | null
  } | null
}

export type WorkspaceOcctoSettingsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
}>

export type WorkspaceOcctoSettingsQuery = {
  __typename?: 'Query'
  workspace?: {
    __typename?: 'Workspace'
    id: string
    isBillable: boolean
    operationalGridZones: Array<GridZone>
    occtoGenerationPlanGenerationMethod: OcctoGenerationPlanGenerationMethod
    occtoSender: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    occtoSubmitterGridZones: {
      __typename?: 'OcctoSubmitterGridZones'
      chubu: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      chugoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      hokkaido: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      hokuriku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      kansai: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      kyushu: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      shikoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      tohoku: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
      tokyo: { __typename?: 'OcctoSubmitterData'; name?: string | null; code?: string | null }
    }
  } | null
}

export type ApiKeysQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input']
}>

export type ApiKeysQuery = {
  __typename?: 'Query'
  workspace?: {
    __typename?: 'Workspace'
    id: string
    apiKeys: Array<{
      __typename?: 'WorkspaceApiKey'
      createdAt: any
      id: string
      key?: string | null
      lastUsedAt?: any | null
      name: string
      permissions: Array<WorkspaceApiKeyPermission>
    }>
  } | null
}

export const ScenarioAssumptionBaseDataFragmentDoc = gql`
  fragment ScenarioAssumptionBaseData on ScenarioAssumption {
    id
    name
    isCustom
    isEditable
    isDeletable
    createdAt
  }
`
export const ScenarioAssumptionCrudDataFragmentDoc = gql`
  fragment ScenarioAssumptionCrudData on ScenarioAssumption {
    ...ScenarioAssumptionBaseData
    scenarios {
      id
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`
export const BaseAssumptionFragmentDoc = gql`
  fragment BaseAssumption on AssumptionValues {
    year
    value
  }
`
export const InflationAssumptionCrudDataFragmentDoc = gql`
  fragment InflationAssumptionCrudData on ScenarioInflation {
    ...ScenarioAssumptionCrudData
    assumptions {
      ...BaseAssumption
    }
  }
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${BaseAssumptionFragmentDoc}
`
export const DayAheadMapeAssumptionCrudDataFragmentDoc = gql`
  fragment DayAheadMapeAssumptionCrudData on ScenarioForecastMapeDayAhead {
    ...ScenarioAssumptionCrudData
    assumptions {
      ...BaseAssumption
    }
  }
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${BaseAssumptionFragmentDoc}
`
export const IntradayMapeAssumptionCrudDataFragmentDoc = gql`
  fragment IntradayMapeAssumptionCrudData on ScenarioForecastMapeIntraday {
    ...ScenarioAssumptionCrudData
    assumptions {
      ...BaseAssumption
    }
  }
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${BaseAssumptionFragmentDoc}
`
export const RegionalAssumptionsWithSystemFragmentDoc = gql`
  fragment RegionalAssumptionsWithSystem on RegionalWithSystemAssumptions {
    system {
      ...BaseAssumption
    }
    hokkaido {
      ...BaseAssumption
    }
    tohoku {
      ...BaseAssumption
    }
    tokyo {
      ...BaseAssumption
    }
    chubu {
      ...BaseAssumption
    }
    hokuriku {
      ...BaseAssumption
    }
    kansai {
      ...BaseAssumption
    }
    chugoku {
      ...BaseAssumption
    }
    shikoku {
      ...BaseAssumption
    }
    kyushu {
      ...BaseAssumption
    }
    okinawa {
      ...BaseAssumption
    }
  }
  ${BaseAssumptionFragmentDoc}
`
export const PriceLevelAssumptionCrudDataFragmentDoc = gql`
  fragment PriceLevelAssumptionCrudData on ScenarioPriceAverage {
    ...ScenarioAssumptionCrudData
    assumptions {
      ...RegionalAssumptionsWithSystem
    }
  }
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${RegionalAssumptionsWithSystemFragmentDoc}
`
export const PriceVolatilityAssumptionCrudDataFragmentDoc = gql`
  fragment PriceVolatilityAssumptionCrudData on ScenarioPriceVolatility {
    ...ScenarioAssumptionCrudData
    assumptions {
      ...RegionalAssumptionsWithSystem
    }
  }
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${RegionalAssumptionsWithSystemFragmentDoc}
`
export const RegionalAssumptionsFragmentDoc = gql`
  fragment RegionalAssumptions on RegionalAssumptions {
    hokkaido {
      ...BaseAssumption
    }
    tohoku {
      ...BaseAssumption
    }
    tokyo {
      ...BaseAssumption
    }
    chubu {
      ...BaseAssumption
    }
    hokuriku {
      ...BaseAssumption
    }
    kansai {
      ...BaseAssumption
    }
    chugoku {
      ...BaseAssumption
    }
    shikoku {
      ...BaseAssumption
    }
    kyushu {
      ...BaseAssumption
    }
    okinawa {
      ...BaseAssumption
    }
  }
  ${BaseAssumptionFragmentDoc}
`
export const CurtailmentAssumptionCrudDataFragmentDoc = gql`
  fragment CurtailmentAssumptionCrudData on ScenarioCurtailment {
    ...ScenarioAssumptionCrudData
    assumptions {
      ...RegionalAssumptions
    }
  }
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${RegionalAssumptionsFragmentDoc}
`
export const DeleteAssumptionErrorsFragmentDoc = gql`
  fragment DeleteAssumptionErrors on DeleteAssumptionProblems {
    ... on ProblemInterface {
      message
    }
    ... on ReplacementRequiredProblem {
      message
      recordId
      dependentIds
    }
  }
`
export const InflationAssumptionOptionsQueryFragmentDoc = gql`
  fragment InflationAssumptionOptionsQuery on ScenarioAssumptionsQuery {
    inflation(input: $queryInput) {
      records {
        ...ScenarioAssumptionBaseData
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`
export const DeleteInflationDataFragmentDoc = gql`
  fragment DeleteInflationData on DeleteInflationPayload {
    success
    errors {
      ...DeleteAssumptionErrors
    }
    data {
      recordId
      record {
        ...ScenarioAssumptionCrudData
      }
    }
    query {
      scenarioAssumptions {
        ...InflationAssumptionOptionsQuery
      }
    }
  }
  ${DeleteAssumptionErrorsFragmentDoc}
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${InflationAssumptionOptionsQueryFragmentDoc}
`
export const MapeDayAheadAssumptionOptionsQueryFragmentDoc = gql`
  fragment MapeDayAheadAssumptionOptionsQuery on ScenarioAssumptionsQuery {
    forecastMapeDayAhead(input: $queryInput) {
      records {
        ...ScenarioAssumptionBaseData
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`
export const DeleteMapeDayAheadDataFragmentDoc = gql`
  fragment DeleteMapeDayAheadData on DeleteForecastMapeDayAheadPayload {
    success
    errors {
      ...DeleteAssumptionErrors
    }
    data {
      recordId
      record {
        ...ScenarioAssumptionCrudData
      }
    }
    query {
      scenarioAssumptions {
        ...MapeDayAheadAssumptionOptionsQuery
      }
    }
  }
  ${DeleteAssumptionErrorsFragmentDoc}
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${MapeDayAheadAssumptionOptionsQueryFragmentDoc}
`
export const MapeIntradayAssumptionOptionsQueryFragmentDoc = gql`
  fragment MapeIntradayAssumptionOptionsQuery on ScenarioAssumptionsQuery {
    forecastMapeIntraday(input: $queryInput) {
      records {
        ...ScenarioAssumptionBaseData
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`
export const DeleteMapeIntradayDataFragmentDoc = gql`
  fragment DeleteMapeIntradayData on DeleteForecastMapeIntradayPayload {
    success
    errors {
      ...DeleteAssumptionErrors
    }
    data {
      recordId
      record {
        ...ScenarioAssumptionCrudData
      }
    }
    query {
      scenarioAssumptions {
        ...MapeIntradayAssumptionOptionsQuery
      }
    }
  }
  ${DeleteAssumptionErrorsFragmentDoc}
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${MapeIntradayAssumptionOptionsQueryFragmentDoc}
`
export const PriceAverageAssumptionOptionsQueryFragmentDoc = gql`
  fragment PriceAverageAssumptionOptionsQuery on ScenarioAssumptionsQuery {
    priceAverage(input: $queryInput) {
      records {
        ...ScenarioAssumptionBaseData
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`
export const DeletePriceLevelDataFragmentDoc = gql`
  fragment DeletePriceLevelData on DeletePriceAveragePayload {
    success
    errors {
      ...DeleteAssumptionErrors
    }
    data {
      recordId
      record {
        ...ScenarioAssumptionCrudData
      }
    }
    query {
      scenarioAssumptions {
        ...PriceAverageAssumptionOptionsQuery
      }
    }
  }
  ${DeleteAssumptionErrorsFragmentDoc}
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${PriceAverageAssumptionOptionsQueryFragmentDoc}
`
export const PriceVolatilityAssumptionOptionsQueryFragmentDoc = gql`
  fragment PriceVolatilityAssumptionOptionsQuery on ScenarioAssumptionsQuery {
    priceVolatility(input: $queryInput) {
      records {
        ...ScenarioAssumptionBaseData
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`
export const DeletePriceVolatilityDataFragmentDoc = gql`
  fragment DeletePriceVolatilityData on DeletePriceVolatilityPayload {
    success
    errors {
      ...DeleteAssumptionErrors
    }
    data {
      recordId
      record {
        ...ScenarioAssumptionCrudData
      }
    }
    query {
      scenarioAssumptions {
        ...PriceVolatilityAssumptionOptionsQuery
      }
    }
  }
  ${DeleteAssumptionErrorsFragmentDoc}
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${PriceVolatilityAssumptionOptionsQueryFragmentDoc}
`
export const CurtailmentAssumptionOptionsQueryFragmentDoc = gql`
  fragment CurtailmentAssumptionOptionsQuery on ScenarioAssumptionsQuery {
    curtailment(input: $queryInput) {
      records {
        ...ScenarioAssumptionBaseData
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`
export const DeleteCurtailmentDataFragmentDoc = gql`
  fragment DeleteCurtailmentData on DeleteCurtailmentPayload {
    success
    errors {
      ...DeleteAssumptionErrors
    }
    data {
      recordId
      record {
        ...ScenarioAssumptionCrudData
      }
    }
    query {
      scenarioAssumptions {
        ...CurtailmentAssumptionOptionsQuery
      }
    }
  }
  ${DeleteAssumptionErrorsFragmentDoc}
  ${ScenarioAssumptionCrudDataFragmentDoc}
  ${CurtailmentAssumptionOptionsQueryFragmentDoc}
`
export const AssumptionOptionsBaseQueryFragmentDoc = gql`
  fragment AssumptionOptionsBaseQuery on ScenarioAssumptionsQuery {
    ...InflationAssumptionOptionsQuery
    ...CurtailmentAssumptionOptionsQuery
    ...PriceAverageAssumptionOptionsQuery
    ...PriceVolatilityAssumptionOptionsQuery
    ...MapeDayAheadAssumptionOptionsQuery
    ...MapeIntradayAssumptionOptionsQuery
  }
  ${InflationAssumptionOptionsQueryFragmentDoc}
  ${CurtailmentAssumptionOptionsQueryFragmentDoc}
  ${PriceAverageAssumptionOptionsQueryFragmentDoc}
  ${PriceVolatilityAssumptionOptionsQueryFragmentDoc}
  ${MapeDayAheadAssumptionOptionsQueryFragmentDoc}
  ${MapeIntradayAssumptionOptionsQueryFragmentDoc}
`
export const OcctoPlanFragmentFragmentDoc = gql`
  fragment OcctoPlanFragment on OcctoReportingZonePlan {
    plan {
      set {
        t
        v
      }
      updatedAt
    }
    totalVolume
  }
`
export const OcctoReportingZoneDataFragmentDoc = gql`
  fragment OcctoReportingZoneData on OcctoReportingZone {
    balancingGroups {
      forecasts {
        generationPlan(input: $generationPlanInput) {
          plan {
            set {
              t
              v
            }
            updatedAt
          }
          totalAssets
          totalGeneration
        }
      }
      name
      id
    }
    date
    generationPlan {
      ...OcctoPlanFragment
    }
    procurementPlan {
      ...OcctoPlanFragment
    }
    salesPlan {
      ...OcctoPlanFragment
    }
    status
    totalAssets
  }
  ${OcctoPlanFragmentFragmentDoc}
`
export const OcctoDayAheadPlanRecordFragmentDoc = gql`
  fragment OcctoDayAheadPlanRecord on OcctoDayAheadPlan {
    date
    id
    hasPlan
    updatedAt
    zones {
      chubu {
        ...OcctoReportingZoneData
      }
      chugoku {
        ...OcctoReportingZoneData
      }
      hokkaido {
        ...OcctoReportingZoneData
      }
      hokuriku {
        ...OcctoReportingZoneData
      }
      kansai {
        ...OcctoReportingZoneData
      }
      kyushu {
        ...OcctoReportingZoneData
      }
      shikoku {
        ...OcctoReportingZoneData
      }
      tohoku {
        ...OcctoReportingZoneData
      }
      tokyo {
        ...OcctoReportingZoneData
      }
    }
  }
  ${OcctoReportingZoneDataFragmentDoc}
`
export const UserCoreDataFragmentDoc = gql`
  fragment UserCoreData on User {
    id
    firstName
    lastName
    profilePicture {
      default
      small
    }
    workspace {
      id
    }
  }
`
export const UserDataFragmentDoc = gql`
  fragment UserData on User {
    ...UserCoreData
    email
    username
    role
    createdAt
    updatedAt
    lastActivityAt
  }
  ${UserCoreDataFragmentDoc}
`
export const UserAccountDataFragmentDoc = gql`
  fragment UserAccountData on UpdateUserAccountPayload {
    data {
      recordId
      record {
        id
        email
        firstName
        lastName
        createdAt
        updatedAt
        lastActivityAt
        preferences {
          electricalPowerUnit
        }
      }
    }
  }
`
export const CurrentUserAccountDataFragmentDoc = gql`
  fragment CurrentUserAccountData on UserAccount {
    id
    email
    firstName
    lastName
    createdAt
    updatedAt
    lastActivityAt
    preferences {
      electricalPowerUnit
      fileEncodingFormat
      language
    }
    profilePicture {
      default
      small
    }
    lastUsedWorkspaceId
    users {
      id
      username
      role
      workspace {
        id
        name
        isBillable
      }
    }
  }
`
export const OrganizationCoreDataFragmentDoc = gql`
  fragment OrganizationCoreData on Organization {
    id
    name
  }
`
export const BenchmarkDataFragmentDoc = gql`
  fragment BenchmarkData on BenchmarkIndicator {
    value
    benchmark
  }
`
export const CertificateValuesFragmentDoc = gql`
  fragment CertificateValues on NfcSeriesSet {
    t
    v {
      hi
      lo
      avg
      vol
    }
  }
`
export const ElectricityConsumptionEntriesFragmentDoc = gql`
  fragment ElectricityConsumptionEntries on ElectricityConsumptionVolumes {
    dem
    itc
    phy
    sol
    win
  }
`
export const ElectricityGenerationEntriesFragmentDoc = gql`
  fragment ElectricityGenerationEntries on ElectricityGenerationVolumes {
    nuc
    the
    hyd
    sol
    win
    bio
    geo
    phy
    itc
  }
`
export const ZoneDatesEntriesFragmentDoc = gql`
  fragment ZoneDatesEntries on ZoneDates {
    chb
    chg
    hkd
    hok
    kyu
    kan
    oki
    shi
    toh
    tok
  }
`
export const HighPerformanceNumberSeriesDataFragmentDoc = gql`
  fragment HighPerformanceNumberSeriesData on HighPerformanceNumberSeries {
    data
    endTime
    startTime
    interval
  }
`
export const HighPerformanceNullableNumberSeriesDataFragmentDoc = gql`
  fragment HighPerformanceNullableNumberSeriesData on HighPerformanceNullableNumberSeries {
    data
    endTime
    startTime
    interval
  }
`
export const NotificationActorDataFragmentDoc = gql`
  fragment NotificationActorData on Actor {
    firstName
    lastName
    id
    email
    workspace {
      id
    }
    profilePicture {
      default
    }
  }
`
export const NotificationDataFragmentDoc = gql`
  fragment NotificationData on Notification {
    __typename
    category
    createdAt
    id
    status
    updatedAt
    ... on NotificationJepxOpenedPosition {
      metadata {
        date
        gridZone
      }
      actor {
        ...NotificationActorData
      }
    }
    ... on NotificationJepxTradeConfirmationReminder {
      metadata {
        gridZone
        date
      }
    }
    ... on NotificationJepxTradeConfirmed {
      metadata {
        gridZone
        date
      }
      actor {
        ...NotificationActorData
      }
    }
    ... on NotificationJepxTradingReminder {
      metadata {
        gridZone
        date
      }
    }
    ... on NotificationTSOPlanSubmissionReminder {
      metadata {
        gridZone
        date
      }
    }
    ... on NotificationTSOPlanSubmitted {
      metadata {
        gridZone
        date
      }
      actor {
        ...NotificationActorData
      }
    }
  }
  ${NotificationActorDataFragmentDoc}
`
export const BalancingGroupIdDataFragmentDoc = gql`
  fragment BalancingGroupIdData on BalancingGroup {
    id
    balancingGroupCode
  }
`
export const BalancingGroupListItemFragmentDoc = gql`
  fragment BalancingGroupListItem on BalancingGroup {
    balancingGroupCode
    createdAt
    gridZone
    id
    totalAssets
    updatedAt
    name
  }
`
export const BalancingGroupAssetDataFragmentDoc = gql`
  fragment BalancingGroupAssetData on SolarAsset {
    acCapacity
    dcCapacity
    cod
    id
    readableId
    name
    locationName
    status
  }
`
export const BalancingGroupOverviewRecordFragmentDoc = gql`
  fragment BalancingGroupOverviewRecord on BalancingGroup {
    assets {
      ...BalancingGroupAssetData
    }
    balancingGroupCode
    contractClassificationNumber1
    createdAt
    gridZone
    id
    name
    totalAssets
    updatedAt
    hasForecasts
    imbalanceValue
  }
  ${BalancingGroupAssetDataFragmentDoc}
`
export const PpaIdDataFragmentDoc = gql`
  fragment PpaIdData on PpaX {
    id
    readableId
  }
`
export const PpaCoreDataFragmentDoc = gql`
  fragment PpaCoreData on PpaX {
    id
    readableId
    name
    type
    status
    capacity
    offtaker {
      id
      name
    }
    startDate
    term
    fipBeneficiary
    pricingScheme
    createdAt
    updatedAt
    userUpdatedAt
    totalAssets
  }
`
export const ThirdPartyListItemFragmentDoc = gql`
  fragment ThirdPartyListItem on ThirdPartyCompany {
    id
    name
    createdAt
    type
  }
`
export const PpaAssetDataFragmentDoc = gql`
  fragment PpaAssetData on SolarAsset {
    id
    readableId
    dcCapacity
    name
    locationName
  }
`
export const PpaCrudDataFragmentDoc = gql`
  fragment PpaCrudData on PpaX {
    id
    readableId
    name
    type
    status
    isFlexiblePricingScheme
    offtaker {
      ...ThirdPartyListItem
    }
    startDate
    term
    fipBeneficiary
    solarAssets {
      id
      name
    }
    pricingScheme
    strikePrice {
      value
      year
    }
    discountToMarket {
      value
      year
    }
    floorPrice {
      value
      year
    }
    capPrice {
      value
      year
    }
    floatingShare
    fixedShare
    settlementMarket
    settlementPricingZone
    calculationInterval
    createdAt
    updatedAt
    solarAssets {
      ...PpaAssetData
    }
  }
  ${ThirdPartyListItemFragmentDoc}
  ${PpaAssetDataFragmentDoc}
`
export const ReportIdDataFragmentDoc = gql`
  fragment ReportIdData on Report {
    id
    readableId
  }
`
export const ReportCoreDataFragmentDoc = gql`
  fragment ReportCoreData on Report {
    id
    name
    createdAt
    updatedAt
    readableId
  }
`
export const PaginationMetadataFragmentDoc = gql`
  fragment PaginationMetadata on PaginationInfo {
    hasNextPage
    hasPreviousPage
    isEmpty
    page
    perPage
    totalPages
    totalRecords
  }
`
export const ReportListDataFragmentDoc = gql`
  fragment ReportListData on ReportList {
    records {
      ...ReportCoreData
      scope {
        solarAssets {
          total
        }
        ppa {
          total
        }
      }
      author {
        ...UserCoreData
        email
      }
      isStarred
    }
    pageInfo {
      ...PaginationMetadata
    }
  }
  ${ReportCoreDataFragmentDoc}
  ${UserCoreDataFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`
export const ReportSolarAssetListItemRecordFragmentDoc = gql`
  fragment ReportSolarAssetListItemRecord on SolarAsset {
    id
    name
    ppa {
      name
      id
    }
    locationName
    acCapacity
    createdAt
  }
`
export const ReportSolarAssetListDataFragmentDoc = gql`
  fragment ReportSolarAssetListData on SolarAssetList {
    records {
      ...ReportSolarAssetListItemRecord
    }
    pageInfo {
      ...PaginationMetadata
    }
  }
  ${ReportSolarAssetListItemRecordFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`
export const ScenarioCoreDataFragmentDoc = gql`
  fragment ScenarioCoreData on MacroScenario {
    id
    readableId
    name
    description
    isDefault
    createdAt
    updatedAt
  }
`
export const ReportScenarioListDataFragmentDoc = gql`
  fragment ReportScenarioListData on ScenarioList {
    records {
      ...ScenarioCoreData
    }
    pageInfo {
      ...PaginationMetadata
    }
  }
  ${ScenarioCoreDataFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`
export const SingleSeriesSetFragmentDoc = gql`
  fragment SingleSeriesSet on SingleSeries {
    set {
      t
      v
    }
  }
`
export const MacroScenarioAssumptionCurtailmentsDataFragmentDoc = gql`
  fragment MacroScenarioAssumptionCurtailmentsData on MacroScenarioAssumptionCurtailments {
    chubu {
      ...SingleSeriesSet
    }
    chugoku {
      ...SingleSeriesSet
    }
    hokkaido {
      ...SingleSeriesSet
    }
    hokuriku {
      ...SingleSeriesSet
    }
    kansai {
      ...SingleSeriesSet
    }
    kyushu {
      ...SingleSeriesSet
    }
    okinawa {
      ...SingleSeriesSet
    }
    shikoku {
      ...SingleSeriesSet
    }
    tohoku {
      ...SingleSeriesSet
    }
    tokyo {
      ...SingleSeriesSet
    }
  }
  ${SingleSeriesSetFragmentDoc}
`
export const MacroScenarioAssumptionPricesDataFragmentDoc = gql`
  fragment MacroScenarioAssumptionPricesData on MacroScenarioAssumptionPrices {
    chubu {
      ...SingleSeriesSet
    }
    chugoku {
      ...SingleSeriesSet
    }
    hokkaido {
      ...SingleSeriesSet
    }
    hokuriku {
      ...SingleSeriesSet
    }
    kansai {
      ...SingleSeriesSet
    }
    kyushu {
      ...SingleSeriesSet
    }
    okinawa {
      ...SingleSeriesSet
    }
    shikoku {
      ...SingleSeriesSet
    }
    tohoku {
      ...SingleSeriesSet
    }
    tokyo {
      ...SingleSeriesSet
    }
    system {
      ...SingleSeriesSet
    }
  }
  ${SingleSeriesSetFragmentDoc}
`
export const ReportSharingDataFragmentDoc = gql`
  fragment ReportSharingData on Report {
    ...ReportCoreData
    accessExpiry
    sharedWith
  }
  ${ReportCoreDataFragmentDoc}
`
export const ScenarioIdDataFragmentDoc = gql`
  fragment ScenarioIdData on MacroScenario {
    id
    readableId
  }
`
export const ScenarioCrudDataFragmentDoc = gql`
  fragment ScenarioCrudData on MacroScenario {
    ...ScenarioCoreData
    inflation {
      ...ScenarioAssumptionBaseData
    }
    curtailment {
      ...ScenarioAssumptionBaseData
    }
    priceAverage {
      ...ScenarioAssumptionBaseData
    }
    priceVolatility {
      ...ScenarioAssumptionBaseData
    }
    forecastMapeDayAhead {
      ...ScenarioAssumptionBaseData
    }
    forecastMapeIntraday {
      ...ScenarioAssumptionBaseData
    }
  }
  ${ScenarioCoreDataFragmentDoc}
  ${ScenarioAssumptionBaseDataFragmentDoc}
`
export const SolarAssetIdDataFragmentDoc = gql`
  fragment SolarAssetIdData on SolarAsset {
    id
    readableId
  }
`
export const CapexPaymentDataFragmentDoc = gql`
  fragment CapexPaymentData on CAPEXPayment {
    id
    date
    amount
    description
    consumptionTax
    category
  }
`
export const OpexCostsFragmentDoc = gql`
  fragment OpexCosts on OpexCostSet {
    year
    value
  }
`
export const SolarAssetEntityFragmentDoc = gql`
  fragment SolarAssetEntity on SolarAsset {
    id
    readableId
    acCableLosses
    acCapacity
    altitude
    annualDegradation
    assetManagementCosts {
      ...OpexCosts
    }
    balancingGroup {
      id
      balancingGroupCode
    }
    cod
    createdAt
    decommissionReserveCosts {
      ...OpexCosts
    }
    downtimeRate
    gridZone
    insuranceCosts {
      ...OpexCosts
    }
    inverterCost {
      ...OpexCosts
    }
    inverterLosses
    inverterWarrantyPeriod
    isUsingAdvancedLosses
    landLeaseCosts {
      ...OpexCosts
    }
    latitude
    lifetime
    locationName
    longitude
    meteringDate
    metiRegistrationid
    metiid
    name
    omCosts {
      ...OpexCosts
    }
    otherCosts {
      ...OpexCosts
    }
    propertyTaxRate {
      ...OpexCosts
    }
    shadingLosses
    simulationStatus
    status
    subsidyRate
    subsidyScheme
    subsidyStartDate
    updatedAt
    utilityid
    ppa {
      id
      name
    }
    developer {
      ...ThirdPartyListItem
    }
    epcCompany {
      ...ThirdPartyListItem
    }
    omProvider {
      ...ThirdPartyListItem
    }
    macroScenario {
      id
      name
    }
    capexPayments {
      id
      date
      amount
      category
      description
      consumptionTax
    }
    pvArray {
      id
      moduleParameters {
        pdc0
        gammaPdc
      }
      moduleType
      mountType
      tilt
      azimuth
    }
    battery {
      cycleCost
      energyCapacity
      expectedCycleLife
      id
      isDeleted
      maximumStateOfCharge
      minimumStateOfCharge
      operationalStartDate
      powerCapacity
      roundtripEfficiency
      status
      warrantyPeriod
    }
    load {
      averageLoad
      id
      isDeleted
      maximumLoad
      minimumLoad
      name
      notes
      operationalStartDate
      status
      type
    }
    gridCode
    contractClassificationNumber2
  }
  ${OpexCostsFragmentDoc}
  ${ThirdPartyListItemFragmentDoc}
`
export const SolarAssetTableRecordFragmentDoc = gql`
  fragment SolarAssetTableRecord on SolarAsset {
    id
    readableId
    acCapacity
    cod
    createdAt
    dcCapacity
    gridZone
    locationName
    name
    simulationStatus
    subsidyScheme
    status
    updatedAt
    utilityid
    ppa {
      id
      name
    }
    pvArray {
      moduleParameters {
        pdc0
      }
    }
    userUpdatedAt
  }
`
export const SpvIdDataFragmentDoc = gql`
  fragment SpvIdData on Spv {
    id
    readableId
  }
`
export const SpvsListDataFragmentDoc = gql`
  fragment SpvsListData on Spv {
    id
    readableId
    legalEntityName {
      en
      ja
    }
    totalDcCapacity
    totalAssets
    updatedAt
    createdAt
  }
`
export const SpvAssetDataFragmentDoc = gql`
  fragment SpvAssetData on SolarAsset {
    id
    readableId
    acCapacity
    dcCapacity
    name
    locationName
    cod
  }
`
export const SpvOverviewQueryDataFragmentDoc = gql`
  fragment SpvOverviewQueryData on Spv {
    id
    readableId
    legalEntityName {
      en
      ja
    }
    totalDcCapacity
    investments {
      amount
    }
    loans {
      amount
    }
    assets {
      ...SpvAssetData
    }
    totalAssets
  }
  ${SpvAssetDataFragmentDoc}
`
export const SpvInvestmentDataFragmentDoc = gql`
  fragment SpvInvestmentData on SpvInvestment {
    id
    amount
    investmentDate
    investorName
  }
`
export const SpvLoanDataFragmentDoc = gql`
  fragment SpvLoanData on SpvLoan {
    id
    description
    amount
    interestRate
    lenderName
    repaymentFrequency
    repaymentStartDate
    repaymentEndDate
    loanReceivedDate
  }
`
export const SpvCrudDataFragmentDoc = gql`
  fragment SpvCrudData on Spv {
    id
    readableId
    companyType
    legalEntityName {
      en
      ja
    }
    companyRegistrationId
    legalEntityCreationDate
    financialYear
    investments {
      ...SpvInvestmentData
    }
    couponPaymentFrequency
    couponPaymentMonth
    couponPayoutPercentage
    loans {
      ...SpvLoanData
    }
    targetDSCR
    assets {
      id
      readableId
      name
      dcCapacity
      locationName
    }
  }
  ${SpvInvestmentDataFragmentDoc}
  ${SpvLoanDataFragmentDoc}
`
export const ThirdPartyListQueryFragmentDoc = gql`
  fragment ThirdPartyListQuery on ThirdPartyCompanyList {
    records {
      ...ThirdPartyListItem
    }
    pageInfo {
      ...PaginationMetadata
    }
  }
  ${ThirdPartyListItemFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`
export const CustomTradeSetupDataFragmentDoc = gql`
  fragment CustomTradeSetupData on JepxTradingZone {
    priceForecast {
      t
      v
    }
    generationPlan {
      totalGeneration
      plan {
        t
        v
      }
      generatedAt
    }
  }
`
export const JepxTradesDataFragmentDoc = gql`
  fragment JepxTradesData on JepxTrades {
    date
    totalVolume
    trades {
      price
      timeSlot
      volume
    }
    updatedAt
  }
`
export const JepxTradingZoneDataFragmentDoc = gql`
  fragment JepxTradingZoneData on JepxTradingZone {
    status
    priceForecast {
      t
      v
    }
    priceActuals {
      t
      v
    }
    hasPriceActuals
    generationPlan {
      totalGeneration
      plan {
        t
        v
      }
      generatedAt
    }
    confirmedTrades {
      ...JepxTradesData
    }
    openPositions {
      ...JepxTradesData
    }
  }
  ${JepxTradesDataFragmentDoc}
`
export const JepxTradingDataFragmentDoc = gql`
  fragment JepxTradingData on JepxTrading {
    id
    tradingZones {
      chubu {
        ...JepxTradingZoneData
      }
      chugoku {
        ...JepxTradingZoneData
      }
      hokkaido {
        ...JepxTradingZoneData
      }
      hokuriku {
        ...JepxTradingZoneData
      }
      kansai {
        ...JepxTradingZoneData
      }
      kyushu {
        ...JepxTradingZoneData
      }
      shikoku {
        ...JepxTradingZoneData
      }
      tohoku {
        ...JepxTradingZoneData
      }
      tokyo {
        ...JepxTradingZoneData
      }
    }
    date
    generationPlan {
      plan {
        t
        v
      }
      totalGeneration
      generatedAt
    }
    generatedAt
    hasPlan
  }
  ${JepxTradingZoneDataFragmentDoc}
`
export const UserNotificationPreferencesDataFragmentDoc = gql`
  fragment UserNotificationPreferencesData on User {
    id
    preferences {
      isOptedIntoActivityAppNotifications
      isOptedIntoActivityEmailNotifications
      isOptedIntoAlertAppNotifications
      isOptedIntoAlertEmailNotifications
      isOptedIntoReminderAppNotifications
      isOptedIntoReminderEmailNotifications
    }
    workspace {
      id
    }
  }
`
export const WorkspaceMemberFragmentDoc = gql`
  fragment WorkspaceMember on User {
    ...UserCoreData
    createdAt
    email
    lastActivityAt
    role
    updatedAt
    username
  }
  ${UserCoreDataFragmentDoc}
`
export const OcctoIdDataFragmentDoc = gql`
  fragment OcctoIdData on OcctoSubmitterData {
    name
    code
  }
`
export const OcctoSettingsQueryDataFragmentDoc = gql`
  fragment OcctoSettingsQueryData on Workspace {
    id
    isBillable
    operationalGridZones
    occtoGenerationPlanGenerationMethod
    occtoSender {
      ...OcctoIdData
    }
    occtoSubmitterGridZones {
      chubu {
        ...OcctoIdData
      }
      chugoku {
        ...OcctoIdData
      }
      hokkaido {
        ...OcctoIdData
      }
      hokuriku {
        ...OcctoIdData
      }
      kansai {
        ...OcctoIdData
      }
      kyushu {
        ...OcctoIdData
      }
      shikoku {
        ...OcctoIdData
      }
      tohoku {
        ...OcctoIdData
      }
      tokyo {
        ...OcctoIdData
      }
    }
  }
  ${OcctoIdDataFragmentDoc}
`
export const ApiKeysQueryDataFragmentDoc = gql`
  fragment ApiKeysQueryData on Workspace {
    id
    apiKeys {
      createdAt
      id
      key
      lastUsedAt
      name
      permissions
    }
  }
`
export const DayAheadMarketIntelDocument = gql`
  query DayAheadMarketIntel(
    $actualsInput: MarketIntelligenceSeriesInput!
    $predictionsInput: MarketIntelligenceSeriesInput!
  ) {
    marketIntelligence {
      dayAhead {
        dayAheadPriceVolumeSeries(input: $actualsInput) {
          set {
            t
            v {
              vol {
                da
                itd
              }
              zone {
                chg
                chb
                kyu
                hkd
                hok
                tok
                kan
                toh
                shi
                oki
                sys
              }
            }
          }
          updatedAt
        }
        maxDate
        minDate
      }
      priceForecast {
        electricityMarketPriceForecastSeries(input: $predictionsInput) {
          set {
            t
            v {
              chg
              chb
              kyu
              hkd
              hok
              tok
              kan
              toh
              shi
              oki
              sys
            }
          }
        }
        maxDate
        minDate
      }
    }
  }
`

/**
 * __useDayAheadMarketIntelQuery__
 *
 * To run a query within a React component, call `useDayAheadMarketIntelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayAheadMarketIntelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayAheadMarketIntelQuery({
 *   variables: {
 *      actualsInput: // value for 'actualsInput'
 *      predictionsInput: // value for 'predictionsInput'
 *   },
 * });
 */
export function useDayAheadMarketIntelQuery(
  baseOptions: Apollo.QueryHookOptions<
    DayAheadMarketIntelQuery,
    DayAheadMarketIntelQueryVariables
  > &
    ({ variables: DayAheadMarketIntelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DayAheadMarketIntelQuery, DayAheadMarketIntelQueryVariables>(
    DayAheadMarketIntelDocument,
    options
  )
}
export function useDayAheadMarketIntelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DayAheadMarketIntelQuery,
    DayAheadMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DayAheadMarketIntelQuery, DayAheadMarketIntelQueryVariables>(
    DayAheadMarketIntelDocument,
    options
  )
}
export function useDayAheadMarketIntelSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DayAheadMarketIntelQuery,
    DayAheadMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DayAheadMarketIntelQuery, DayAheadMarketIntelQueryVariables>(
    DayAheadMarketIntelDocument,
    options
  )
}
export type DayAheadMarketIntelQueryHookResult = ReturnType<typeof useDayAheadMarketIntelQuery>
export type DayAheadMarketIntelLazyQueryHookResult = ReturnType<
  typeof useDayAheadMarketIntelLazyQuery
>
export type DayAheadMarketIntelSuspenseQueryHookResult = ReturnType<
  typeof useDayAheadMarketIntelSuspenseQuery
>
export type DayAheadMarketIntelQueryResult = ApolloReactCommon.QueryResult<
  DayAheadMarketIntelQuery,
  DayAheadMarketIntelQueryVariables
>
export const IntradayMarketIntelDocument = gql`
  query IntradayMarketIntel($input: MarketIntelligenceSeriesInput!) {
    marketIntelligence {
      intraday {
        maxDate
        minDate
        priceVolumeSeries(input: $input) {
          set {
            t
            v {
              ap
              cp
              hp
              lp
              op
              vol
            }
          }
          updatedAt
        }
      }
    }
  }
`

/**
 * __useIntradayMarketIntelQuery__
 *
 * To run a query within a React component, call `useIntradayMarketIntelQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntradayMarketIntelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntradayMarketIntelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntradayMarketIntelQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntradayMarketIntelQuery,
    IntradayMarketIntelQueryVariables
  > &
    ({ variables: IntradayMarketIntelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IntradayMarketIntelQuery, IntradayMarketIntelQueryVariables>(
    IntradayMarketIntelDocument,
    options
  )
}
export function useIntradayMarketIntelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntradayMarketIntelQuery,
    IntradayMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IntradayMarketIntelQuery, IntradayMarketIntelQueryVariables>(
    IntradayMarketIntelDocument,
    options
  )
}
export function useIntradayMarketIntelSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IntradayMarketIntelQuery,
    IntradayMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IntradayMarketIntelQuery, IntradayMarketIntelQueryVariables>(
    IntradayMarketIntelDocument,
    options
  )
}
export type IntradayMarketIntelQueryHookResult = ReturnType<typeof useIntradayMarketIntelQuery>
export type IntradayMarketIntelLazyQueryHookResult = ReturnType<
  typeof useIntradayMarketIntelLazyQuery
>
export type IntradayMarketIntelSuspenseQueryHookResult = ReturnType<
  typeof useIntradayMarketIntelSuspenseQuery
>
export type IntradayMarketIntelQueryResult = ApolloReactCommon.QueryResult<
  IntradayMarketIntelQuery,
  IntradayMarketIntelQueryVariables
>
export const ImbalanceMarketIntelDocument = gql`
  query ImbalanceMarketIntel($input: MarketIntelligenceSeriesInput!) {
    marketIntelligence {
      imbalance {
        imbalanceVolumesSeries(input: $input) {
          updatedAt
          set {
            v {
              price {
                sur
                def
              }
              vol
            }
            t
          }
        }
      }
    }
  }
`

/**
 * __useImbalanceMarketIntelQuery__
 *
 * To run a query within a React component, call `useImbalanceMarketIntelQuery` and pass it any options that fit your needs.
 * When your component renders, `useImbalanceMarketIntelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImbalanceMarketIntelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImbalanceMarketIntelQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImbalanceMarketIntelQuery,
    ImbalanceMarketIntelQueryVariables
  > &
    ({ variables: ImbalanceMarketIntelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ImbalanceMarketIntelQuery, ImbalanceMarketIntelQueryVariables>(
    ImbalanceMarketIntelDocument,
    options
  )
}
export function useImbalanceMarketIntelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImbalanceMarketIntelQuery,
    ImbalanceMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ImbalanceMarketIntelQuery, ImbalanceMarketIntelQueryVariables>(
    ImbalanceMarketIntelDocument,
    options
  )
}
export function useImbalanceMarketIntelSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ImbalanceMarketIntelQuery,
    ImbalanceMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ImbalanceMarketIntelQuery, ImbalanceMarketIntelQueryVariables>(
    ImbalanceMarketIntelDocument,
    options
  )
}
export type ImbalanceMarketIntelQueryHookResult = ReturnType<typeof useImbalanceMarketIntelQuery>
export type ImbalanceMarketIntelLazyQueryHookResult = ReturnType<
  typeof useImbalanceMarketIntelLazyQuery
>
export type ImbalanceMarketIntelSuspenseQueryHookResult = ReturnType<
  typeof useImbalanceMarketIntelSuspenseQuery
>
export type ImbalanceMarketIntelQueryResult = ApolloReactCommon.QueryResult<
  ImbalanceMarketIntelQuery,
  ImbalanceMarketIntelQueryVariables
>
export const ConsumptionMarketIntelDocument = gql`
  query ConsumptionMarketIntel($input: MarketIntelligenceSeriesInput!) {
    marketIntelligence {
      electricityConsumption {
        electricityConsumptionSeries(input: $input) {
          updatedAt
          set {
            av {
              ...ElectricityConsumptionEntries
            }
            sv {
              ...ElectricityConsumptionEntries
            }
            t
          }
        }
      }
    }
  }
  ${ElectricityConsumptionEntriesFragmentDoc}
`

/**
 * __useConsumptionMarketIntelQuery__
 *
 * To run a query within a React component, call `useConsumptionMarketIntelQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumptionMarketIntelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumptionMarketIntelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumptionMarketIntelQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConsumptionMarketIntelQuery,
    ConsumptionMarketIntelQueryVariables
  > &
    ({ variables: ConsumptionMarketIntelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ConsumptionMarketIntelQuery, ConsumptionMarketIntelQueryVariables>(
    ConsumptionMarketIntelDocument,
    options
  )
}
export function useConsumptionMarketIntelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConsumptionMarketIntelQuery,
    ConsumptionMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ConsumptionMarketIntelQuery, ConsumptionMarketIntelQueryVariables>(
    ConsumptionMarketIntelDocument,
    options
  )
}
export function useConsumptionMarketIntelSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ConsumptionMarketIntelQuery,
    ConsumptionMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ConsumptionMarketIntelQuery, ConsumptionMarketIntelQueryVariables>(
    ConsumptionMarketIntelDocument,
    options
  )
}
export type ConsumptionMarketIntelQueryHookResult = ReturnType<
  typeof useConsumptionMarketIntelQuery
>
export type ConsumptionMarketIntelLazyQueryHookResult = ReturnType<
  typeof useConsumptionMarketIntelLazyQuery
>
export type ConsumptionMarketIntelSuspenseQueryHookResult = ReturnType<
  typeof useConsumptionMarketIntelSuspenseQuery
>
export type ConsumptionMarketIntelQueryResult = ApolloReactCommon.QueryResult<
  ConsumptionMarketIntelQuery,
  ConsumptionMarketIntelQueryVariables
>
export const GenerationMarketIntelDocument = gql`
  query GenerationMarketIntel($input: MarketIntelligenceSeriesInput!) {
    marketIntelligence {
      electricityGeneration {
        electricityGenerationSeries(input: $input) {
          updatedAt
          set {
            av {
              ...ElectricityGenerationEntries
            }
            sv {
              ...ElectricityGenerationEntries
            }
            t
          }
        }
      }
    }
  }
  ${ElectricityGenerationEntriesFragmentDoc}
`

/**
 * __useGenerationMarketIntelQuery__
 *
 * To run a query within a React component, call `useGenerationMarketIntelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerationMarketIntelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerationMarketIntelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerationMarketIntelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GenerationMarketIntelQuery,
    GenerationMarketIntelQueryVariables
  > &
    ({ variables: GenerationMarketIntelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GenerationMarketIntelQuery, GenerationMarketIntelQueryVariables>(
    GenerationMarketIntelDocument,
    options
  )
}
export function useGenerationMarketIntelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerationMarketIntelQuery,
    GenerationMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GenerationMarketIntelQuery, GenerationMarketIntelQueryVariables>(
    GenerationMarketIntelDocument,
    options
  )
}
export function useGenerationMarketIntelSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerationMarketIntelQuery,
    GenerationMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GenerationMarketIntelQuery, GenerationMarketIntelQueryVariables>(
    GenerationMarketIntelDocument,
    options
  )
}
export type GenerationMarketIntelQueryHookResult = ReturnType<typeof useGenerationMarketIntelQuery>
export type GenerationMarketIntelLazyQueryHookResult = ReturnType<
  typeof useGenerationMarketIntelLazyQuery
>
export type GenerationMarketIntelSuspenseQueryHookResult = ReturnType<
  typeof useGenerationMarketIntelSuspenseQuery
>
export type GenerationMarketIntelQueryResult = ApolloReactCommon.QueryResult<
  GenerationMarketIntelQuery,
  GenerationMarketIntelQueryVariables
>
export const CertificatesMarketIntelDocument = gql`
  query CertificatesMarketIntel($input: MarketIntelligenceSeriesInput!) {
    marketIntelligence {
      certificates {
        nfcPriceVolumeSeries(input: $input) {
          updatedAt
          nfn {
            ...CertificateValues
          }
          nfr {
            ...CertificateValues
          }
          fit {
            ...CertificateValues
          }
        }
      }
    }
  }
  ${CertificateValuesFragmentDoc}
`

/**
 * __useCertificatesMarketIntelQuery__
 *
 * To run a query within a React component, call `useCertificatesMarketIntelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificatesMarketIntelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificatesMarketIntelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCertificatesMarketIntelQuery(
  baseOptions: Apollo.QueryHookOptions<
    CertificatesMarketIntelQuery,
    CertificatesMarketIntelQueryVariables
  > &
    ({ variables: CertificatesMarketIntelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CertificatesMarketIntelQuery, CertificatesMarketIntelQueryVariables>(
    CertificatesMarketIntelDocument,
    options
  )
}
export function useCertificatesMarketIntelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CertificatesMarketIntelQuery,
    CertificatesMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CertificatesMarketIntelQuery, CertificatesMarketIntelQueryVariables>(
    CertificatesMarketIntelDocument,
    options
  )
}
export function useCertificatesMarketIntelSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CertificatesMarketIntelQuery,
    CertificatesMarketIntelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CertificatesMarketIntelQuery,
    CertificatesMarketIntelQueryVariables
  >(CertificatesMarketIntelDocument, options)
}
export type CertificatesMarketIntelQueryHookResult = ReturnType<
  typeof useCertificatesMarketIntelQuery
>
export type CertificatesMarketIntelLazyQueryHookResult = ReturnType<
  typeof useCertificatesMarketIntelLazyQuery
>
export type CertificatesMarketIntelSuspenseQueryHookResult = ReturnType<
  typeof useCertificatesMarketIntelSuspenseQuery
>
export type CertificatesMarketIntelQueryResult = ApolloReactCommon.QueryResult<
  CertificatesMarketIntelQuery,
  CertificatesMarketIntelQueryVariables
>
export const ConsumptionMinAndMaxDatesDocument = gql`
  query ConsumptionMinAndMaxDates {
    marketIntelligence {
      electricityConsumption {
        maxDates {
          ...ZoneDatesEntries
        }
        minDates {
          ...ZoneDatesEntries
        }
      }
    }
  }
  ${ZoneDatesEntriesFragmentDoc}
`

/**
 * __useConsumptionMinAndMaxDatesQuery__
 *
 * To run a query within a React component, call `useConsumptionMinAndMaxDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumptionMinAndMaxDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumptionMinAndMaxDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsumptionMinAndMaxDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConsumptionMinAndMaxDatesQuery,
    ConsumptionMinAndMaxDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ConsumptionMinAndMaxDatesQuery, ConsumptionMinAndMaxDatesQueryVariables>(
    ConsumptionMinAndMaxDatesDocument,
    options
  )
}
export function useConsumptionMinAndMaxDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConsumptionMinAndMaxDatesQuery,
    ConsumptionMinAndMaxDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ConsumptionMinAndMaxDatesQuery,
    ConsumptionMinAndMaxDatesQueryVariables
  >(ConsumptionMinAndMaxDatesDocument, options)
}
export function useConsumptionMinAndMaxDatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ConsumptionMinAndMaxDatesQuery,
    ConsumptionMinAndMaxDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ConsumptionMinAndMaxDatesQuery,
    ConsumptionMinAndMaxDatesQueryVariables
  >(ConsumptionMinAndMaxDatesDocument, options)
}
export type ConsumptionMinAndMaxDatesQueryHookResult = ReturnType<
  typeof useConsumptionMinAndMaxDatesQuery
>
export type ConsumptionMinAndMaxDatesLazyQueryHookResult = ReturnType<
  typeof useConsumptionMinAndMaxDatesLazyQuery
>
export type ConsumptionMinAndMaxDatesSuspenseQueryHookResult = ReturnType<
  typeof useConsumptionMinAndMaxDatesSuspenseQuery
>
export type ConsumptionMinAndMaxDatesQueryResult = ApolloReactCommon.QueryResult<
  ConsumptionMinAndMaxDatesQuery,
  ConsumptionMinAndMaxDatesQueryVariables
>
export const GenerationMinAndMaxDatesDocument = gql`
  query GenerationMinAndMaxDates {
    marketIntelligence {
      electricityGeneration {
        maxDates {
          ...ZoneDatesEntries
        }
        minDates {
          ...ZoneDatesEntries
        }
      }
    }
  }
  ${ZoneDatesEntriesFragmentDoc}
`

/**
 * __useGenerationMinAndMaxDatesQuery__
 *
 * To run a query within a React component, call `useGenerationMinAndMaxDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerationMinAndMaxDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerationMinAndMaxDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerationMinAndMaxDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GenerationMinAndMaxDatesQuery,
    GenerationMinAndMaxDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GenerationMinAndMaxDatesQuery, GenerationMinAndMaxDatesQueryVariables>(
    GenerationMinAndMaxDatesDocument,
    options
  )
}
export function useGenerationMinAndMaxDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerationMinAndMaxDatesQuery,
    GenerationMinAndMaxDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GenerationMinAndMaxDatesQuery, GenerationMinAndMaxDatesQueryVariables>(
    GenerationMinAndMaxDatesDocument,
    options
  )
}
export function useGenerationMinAndMaxDatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerationMinAndMaxDatesQuery,
    GenerationMinAndMaxDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GenerationMinAndMaxDatesQuery,
    GenerationMinAndMaxDatesQueryVariables
  >(GenerationMinAndMaxDatesDocument, options)
}
export type GenerationMinAndMaxDatesQueryHookResult = ReturnType<
  typeof useGenerationMinAndMaxDatesQuery
>
export type GenerationMinAndMaxDatesLazyQueryHookResult = ReturnType<
  typeof useGenerationMinAndMaxDatesLazyQuery
>
export type GenerationMinAndMaxDatesSuspenseQueryHookResult = ReturnType<
  typeof useGenerationMinAndMaxDatesSuspenseQuery
>
export type GenerationMinAndMaxDatesQueryResult = ApolloReactCommon.QueryResult<
  GenerationMinAndMaxDatesQuery,
  GenerationMinAndMaxDatesQueryVariables
>
export const AddInflationAssumptionDocument = gql`
  mutation AddInflationAssumption(
    $input: CreateInflationInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      inflation {
        create(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...InflationAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...InflationAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${InflationAssumptionCrudDataFragmentDoc}
  ${InflationAssumptionOptionsQueryFragmentDoc}
`
export type AddInflationAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  AddInflationAssumptionMutation,
  AddInflationAssumptionMutationVariables
>

/**
 * __useAddInflationAssumptionMutation__
 *
 * To run a mutation, you first call `useAddInflationAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInflationAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInflationAssumptionMutation, { data, loading, error }] = useAddInflationAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useAddInflationAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInflationAssumptionMutation,
    AddInflationAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddInflationAssumptionMutation,
    AddInflationAssumptionMutationVariables
  >(AddInflationAssumptionDocument, options)
}
export type AddInflationAssumptionMutationHookResult = ReturnType<
  typeof useAddInflationAssumptionMutation
>
export type AddInflationAssumptionMutationResult =
  ApolloReactCommon.MutationResult<AddInflationAssumptionMutation>
export type AddInflationAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddInflationAssumptionMutation,
  AddInflationAssumptionMutationVariables
>
export const AddMapeDayAheadAssumptionDocument = gql`
  mutation AddMapeDayAheadAssumption(
    $input: CreateForecastMapeDayAheadInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      forecastMapeDayAhead {
        create(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...DayAheadMapeAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...MapeDayAheadAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${DayAheadMapeAssumptionCrudDataFragmentDoc}
  ${MapeDayAheadAssumptionOptionsQueryFragmentDoc}
`
export type AddMapeDayAheadAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  AddMapeDayAheadAssumptionMutation,
  AddMapeDayAheadAssumptionMutationVariables
>

/**
 * __useAddMapeDayAheadAssumptionMutation__
 *
 * To run a mutation, you first call `useAddMapeDayAheadAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMapeDayAheadAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMapeDayAheadAssumptionMutation, { data, loading, error }] = useAddMapeDayAheadAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useAddMapeDayAheadAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMapeDayAheadAssumptionMutation,
    AddMapeDayAheadAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddMapeDayAheadAssumptionMutation,
    AddMapeDayAheadAssumptionMutationVariables
  >(AddMapeDayAheadAssumptionDocument, options)
}
export type AddMapeDayAheadAssumptionMutationHookResult = ReturnType<
  typeof useAddMapeDayAheadAssumptionMutation
>
export type AddMapeDayAheadAssumptionMutationResult =
  ApolloReactCommon.MutationResult<AddMapeDayAheadAssumptionMutation>
export type AddMapeDayAheadAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddMapeDayAheadAssumptionMutation,
  AddMapeDayAheadAssumptionMutationVariables
>
export const AddMapeIntradayAssumptionDocument = gql`
  mutation AddMapeIntradayAssumption(
    $input: CreateForecastMapeIntradayInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      forecastMapeIntraday {
        create(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...IntradayMapeAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...MapeIntradayAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${IntradayMapeAssumptionCrudDataFragmentDoc}
  ${MapeIntradayAssumptionOptionsQueryFragmentDoc}
`
export type AddMapeIntradayAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  AddMapeIntradayAssumptionMutation,
  AddMapeIntradayAssumptionMutationVariables
>

/**
 * __useAddMapeIntradayAssumptionMutation__
 *
 * To run a mutation, you first call `useAddMapeIntradayAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMapeIntradayAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMapeIntradayAssumptionMutation, { data, loading, error }] = useAddMapeIntradayAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useAddMapeIntradayAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMapeIntradayAssumptionMutation,
    AddMapeIntradayAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddMapeIntradayAssumptionMutation,
    AddMapeIntradayAssumptionMutationVariables
  >(AddMapeIntradayAssumptionDocument, options)
}
export type AddMapeIntradayAssumptionMutationHookResult = ReturnType<
  typeof useAddMapeIntradayAssumptionMutation
>
export type AddMapeIntradayAssumptionMutationResult =
  ApolloReactCommon.MutationResult<AddMapeIntradayAssumptionMutation>
export type AddMapeIntradayAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddMapeIntradayAssumptionMutation,
  AddMapeIntradayAssumptionMutationVariables
>
export const AddPriceLevelAssumptionDocument = gql`
  mutation AddPriceLevelAssumption(
    $input: CreatePriceAverageInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      priceAverage {
        create(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...PriceLevelAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...PriceAverageAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${PriceLevelAssumptionCrudDataFragmentDoc}
  ${PriceAverageAssumptionOptionsQueryFragmentDoc}
`
export type AddPriceLevelAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  AddPriceLevelAssumptionMutation,
  AddPriceLevelAssumptionMutationVariables
>

/**
 * __useAddPriceLevelAssumptionMutation__
 *
 * To run a mutation, you first call `useAddPriceLevelAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPriceLevelAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPriceLevelAssumptionMutation, { data, loading, error }] = useAddPriceLevelAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useAddPriceLevelAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPriceLevelAssumptionMutation,
    AddPriceLevelAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddPriceLevelAssumptionMutation,
    AddPriceLevelAssumptionMutationVariables
  >(AddPriceLevelAssumptionDocument, options)
}
export type AddPriceLevelAssumptionMutationHookResult = ReturnType<
  typeof useAddPriceLevelAssumptionMutation
>
export type AddPriceLevelAssumptionMutationResult =
  ApolloReactCommon.MutationResult<AddPriceLevelAssumptionMutation>
export type AddPriceLevelAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPriceLevelAssumptionMutation,
  AddPriceLevelAssumptionMutationVariables
>
export const AddPriceVolatilityAssumptionDocument = gql`
  mutation AddPriceVolatilityAssumption(
    $input: CreatePriceVolatilityInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      priceVolatility {
        create(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...PriceVolatilityAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...PriceVolatilityAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${PriceVolatilityAssumptionCrudDataFragmentDoc}
  ${PriceVolatilityAssumptionOptionsQueryFragmentDoc}
`
export type AddPriceVolatilityAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  AddPriceVolatilityAssumptionMutation,
  AddPriceVolatilityAssumptionMutationVariables
>

/**
 * __useAddPriceVolatilityAssumptionMutation__
 *
 * To run a mutation, you first call `useAddPriceVolatilityAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPriceVolatilityAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPriceVolatilityAssumptionMutation, { data, loading, error }] = useAddPriceVolatilityAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useAddPriceVolatilityAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPriceVolatilityAssumptionMutation,
    AddPriceVolatilityAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddPriceVolatilityAssumptionMutation,
    AddPriceVolatilityAssumptionMutationVariables
  >(AddPriceVolatilityAssumptionDocument, options)
}
export type AddPriceVolatilityAssumptionMutationHookResult = ReturnType<
  typeof useAddPriceVolatilityAssumptionMutation
>
export type AddPriceVolatilityAssumptionMutationResult =
  ApolloReactCommon.MutationResult<AddPriceVolatilityAssumptionMutation>
export type AddPriceVolatilityAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPriceVolatilityAssumptionMutation,
  AddPriceVolatilityAssumptionMutationVariables
>
export const AddCurtailmentAssumptionDocument = gql`
  mutation AddCurtailmentAssumption(
    $input: CreateCurtailmentInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      curtailment {
        create(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...CurtailmentAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...CurtailmentAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${CurtailmentAssumptionCrudDataFragmentDoc}
  ${CurtailmentAssumptionOptionsQueryFragmentDoc}
`
export type AddCurtailmentAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  AddCurtailmentAssumptionMutation,
  AddCurtailmentAssumptionMutationVariables
>

/**
 * __useAddCurtailmentAssumptionMutation__
 *
 * To run a mutation, you first call `useAddCurtailmentAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCurtailmentAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCurtailmentAssumptionMutation, { data, loading, error }] = useAddCurtailmentAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useAddCurtailmentAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCurtailmentAssumptionMutation,
    AddCurtailmentAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddCurtailmentAssumptionMutation,
    AddCurtailmentAssumptionMutationVariables
  >(AddCurtailmentAssumptionDocument, options)
}
export type AddCurtailmentAssumptionMutationHookResult = ReturnType<
  typeof useAddCurtailmentAssumptionMutation
>
export type AddCurtailmentAssumptionMutationResult =
  ApolloReactCommon.MutationResult<AddCurtailmentAssumptionMutation>
export type AddCurtailmentAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCurtailmentAssumptionMutation,
  AddCurtailmentAssumptionMutationVariables
>
export const UpdateInflationAssumptionDocument = gql`
  mutation UpdateInflationAssumption(
    $input: UpdateInflationInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      inflation {
        update(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...InflationAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...InflationAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${InflationAssumptionCrudDataFragmentDoc}
  ${InflationAssumptionOptionsQueryFragmentDoc}
`
export type UpdateInflationAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateInflationAssumptionMutation,
  UpdateInflationAssumptionMutationVariables
>

/**
 * __useUpdateInflationAssumptionMutation__
 *
 * To run a mutation, you first call `useUpdateInflationAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInflationAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInflationAssumptionMutation, { data, loading, error }] = useUpdateInflationAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useUpdateInflationAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInflationAssumptionMutation,
    UpdateInflationAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateInflationAssumptionMutation,
    UpdateInflationAssumptionMutationVariables
  >(UpdateInflationAssumptionDocument, options)
}
export type UpdateInflationAssumptionMutationHookResult = ReturnType<
  typeof useUpdateInflationAssumptionMutation
>
export type UpdateInflationAssumptionMutationResult =
  ApolloReactCommon.MutationResult<UpdateInflationAssumptionMutation>
export type UpdateInflationAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateInflationAssumptionMutation,
  UpdateInflationAssumptionMutationVariables
>
export const UpdateMapeDayAheadAssumptionDocument = gql`
  mutation UpdateMapeDayAheadAssumption(
    $input: UpdateForecastMapeDayAheadInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      forecastMapeDayAhead {
        update(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...DayAheadMapeAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...MapeDayAheadAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${DayAheadMapeAssumptionCrudDataFragmentDoc}
  ${MapeDayAheadAssumptionOptionsQueryFragmentDoc}
`
export type UpdateMapeDayAheadAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMapeDayAheadAssumptionMutation,
  UpdateMapeDayAheadAssumptionMutationVariables
>

/**
 * __useUpdateMapeDayAheadAssumptionMutation__
 *
 * To run a mutation, you first call `useUpdateMapeDayAheadAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMapeDayAheadAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMapeDayAheadAssumptionMutation, { data, loading, error }] = useUpdateMapeDayAheadAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useUpdateMapeDayAheadAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMapeDayAheadAssumptionMutation,
    UpdateMapeDayAheadAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMapeDayAheadAssumptionMutation,
    UpdateMapeDayAheadAssumptionMutationVariables
  >(UpdateMapeDayAheadAssumptionDocument, options)
}
export type UpdateMapeDayAheadAssumptionMutationHookResult = ReturnType<
  typeof useUpdateMapeDayAheadAssumptionMutation
>
export type UpdateMapeDayAheadAssumptionMutationResult =
  ApolloReactCommon.MutationResult<UpdateMapeDayAheadAssumptionMutation>
export type UpdateMapeDayAheadAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMapeDayAheadAssumptionMutation,
  UpdateMapeDayAheadAssumptionMutationVariables
>
export const UpdateMapeIntradayAssumptionDocument = gql`
  mutation UpdateMapeIntradayAssumption(
    $input: UpdateForecastMapeIntradayInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      forecastMapeIntraday {
        update(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...IntradayMapeAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...MapeIntradayAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${IntradayMapeAssumptionCrudDataFragmentDoc}
  ${MapeIntradayAssumptionOptionsQueryFragmentDoc}
`
export type UpdateMapeIntradayAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMapeIntradayAssumptionMutation,
  UpdateMapeIntradayAssumptionMutationVariables
>

/**
 * __useUpdateMapeIntradayAssumptionMutation__
 *
 * To run a mutation, you first call `useUpdateMapeIntradayAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMapeIntradayAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMapeIntradayAssumptionMutation, { data, loading, error }] = useUpdateMapeIntradayAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useUpdateMapeIntradayAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMapeIntradayAssumptionMutation,
    UpdateMapeIntradayAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMapeIntradayAssumptionMutation,
    UpdateMapeIntradayAssumptionMutationVariables
  >(UpdateMapeIntradayAssumptionDocument, options)
}
export type UpdateMapeIntradayAssumptionMutationHookResult = ReturnType<
  typeof useUpdateMapeIntradayAssumptionMutation
>
export type UpdateMapeIntradayAssumptionMutationResult =
  ApolloReactCommon.MutationResult<UpdateMapeIntradayAssumptionMutation>
export type UpdateMapeIntradayAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMapeIntradayAssumptionMutation,
  UpdateMapeIntradayAssumptionMutationVariables
>
export const UpdatePriceLevelAssumptionDocument = gql`
  mutation UpdatePriceLevelAssumption(
    $input: UpdatePriceAverageInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      priceAverage {
        update(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...PriceLevelAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...PriceAverageAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${PriceLevelAssumptionCrudDataFragmentDoc}
  ${PriceAverageAssumptionOptionsQueryFragmentDoc}
`
export type UpdatePriceLevelAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePriceLevelAssumptionMutation,
  UpdatePriceLevelAssumptionMutationVariables
>

/**
 * __useUpdatePriceLevelAssumptionMutation__
 *
 * To run a mutation, you first call `useUpdatePriceLevelAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePriceLevelAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePriceLevelAssumptionMutation, { data, loading, error }] = useUpdatePriceLevelAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useUpdatePriceLevelAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePriceLevelAssumptionMutation,
    UpdatePriceLevelAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePriceLevelAssumptionMutation,
    UpdatePriceLevelAssumptionMutationVariables
  >(UpdatePriceLevelAssumptionDocument, options)
}
export type UpdatePriceLevelAssumptionMutationHookResult = ReturnType<
  typeof useUpdatePriceLevelAssumptionMutation
>
export type UpdatePriceLevelAssumptionMutationResult =
  ApolloReactCommon.MutationResult<UpdatePriceLevelAssumptionMutation>
export type UpdatePriceLevelAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePriceLevelAssumptionMutation,
  UpdatePriceLevelAssumptionMutationVariables
>
export const UpdatePriceVolatilityAssumptionDocument = gql`
  mutation UpdatePriceVolatilityAssumption(
    $input: UpdatePriceVolatilityInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      priceVolatility {
        update(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...PriceVolatilityAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...PriceVolatilityAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${PriceVolatilityAssumptionCrudDataFragmentDoc}
  ${PriceVolatilityAssumptionOptionsQueryFragmentDoc}
`
export type UpdatePriceVolatilityAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePriceVolatilityAssumptionMutation,
  UpdatePriceVolatilityAssumptionMutationVariables
>

/**
 * __useUpdatePriceVolatilityAssumptionMutation__
 *
 * To run a mutation, you first call `useUpdatePriceVolatilityAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePriceVolatilityAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePriceVolatilityAssumptionMutation, { data, loading, error }] = useUpdatePriceVolatilityAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useUpdatePriceVolatilityAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePriceVolatilityAssumptionMutation,
    UpdatePriceVolatilityAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePriceVolatilityAssumptionMutation,
    UpdatePriceVolatilityAssumptionMutationVariables
  >(UpdatePriceVolatilityAssumptionDocument, options)
}
export type UpdatePriceVolatilityAssumptionMutationHookResult = ReturnType<
  typeof useUpdatePriceVolatilityAssumptionMutation
>
export type UpdatePriceVolatilityAssumptionMutationResult =
  ApolloReactCommon.MutationResult<UpdatePriceVolatilityAssumptionMutation>
export type UpdatePriceVolatilityAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePriceVolatilityAssumptionMutation,
  UpdatePriceVolatilityAssumptionMutationVariables
>
export const UpdateCurtailmentAssumptionDocument = gql`
  mutation UpdateCurtailmentAssumption(
    $input: UpdateCurtailmentInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      curtailment {
        update(input: $input) {
          success
          errors {
            ... on ProblemInterface {
              message
            }
          }
          data {
            recordId
            record {
              ...CurtailmentAssumptionCrudData
            }
          }
          query {
            scenarioAssumptions {
              ...CurtailmentAssumptionOptionsQuery
            }
          }
        }
      }
    }
  }
  ${CurtailmentAssumptionCrudDataFragmentDoc}
  ${CurtailmentAssumptionOptionsQueryFragmentDoc}
`
export type UpdateCurtailmentAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCurtailmentAssumptionMutation,
  UpdateCurtailmentAssumptionMutationVariables
>

/**
 * __useUpdateCurtailmentAssumptionMutation__
 *
 * To run a mutation, you first call `useUpdateCurtailmentAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurtailmentAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurtailmentAssumptionMutation, { data, loading, error }] = useUpdateCurtailmentAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useUpdateCurtailmentAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurtailmentAssumptionMutation,
    UpdateCurtailmentAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCurtailmentAssumptionMutation,
    UpdateCurtailmentAssumptionMutationVariables
  >(UpdateCurtailmentAssumptionDocument, options)
}
export type UpdateCurtailmentAssumptionMutationHookResult = ReturnType<
  typeof useUpdateCurtailmentAssumptionMutation
>
export type UpdateCurtailmentAssumptionMutationResult =
  ApolloReactCommon.MutationResult<UpdateCurtailmentAssumptionMutation>
export type UpdateCurtailmentAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCurtailmentAssumptionMutation,
  UpdateCurtailmentAssumptionMutationVariables
>
export const DeleteInflationAssumptionDocument = gql`
  mutation DeleteInflationAssumption(
    $input: DeleteAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      inflation {
        delete(input: $input) {
          ...DeleteInflationData
        }
      }
    }
  }
  ${DeleteInflationDataFragmentDoc}
`
export type DeleteInflationAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteInflationAssumptionMutation,
  DeleteInflationAssumptionMutationVariables
>

/**
 * __useDeleteInflationAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteInflationAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInflationAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInflationAssumptionMutation, { data, loading, error }] = useDeleteInflationAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteInflationAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInflationAssumptionMutation,
    DeleteInflationAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteInflationAssumptionMutation,
    DeleteInflationAssumptionMutationVariables
  >(DeleteInflationAssumptionDocument, options)
}
export type DeleteInflationAssumptionMutationHookResult = ReturnType<
  typeof useDeleteInflationAssumptionMutation
>
export type DeleteInflationAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteInflationAssumptionMutation>
export type DeleteInflationAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteInflationAssumptionMutation,
  DeleteInflationAssumptionMutationVariables
>
export const DeleteAndReplaceInflationAssumptionDocument = gql`
  mutation DeleteAndReplaceInflationAssumption(
    $input: DeleteAndReplaceAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      inflation {
        deleteAndReplace(input: $input) {
          ...DeleteInflationData
        }
      }
    }
  }
  ${DeleteInflationDataFragmentDoc}
`
export type DeleteAndReplaceInflationAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAndReplaceInflationAssumptionMutation,
  DeleteAndReplaceInflationAssumptionMutationVariables
>

/**
 * __useDeleteAndReplaceInflationAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteAndReplaceInflationAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAndReplaceInflationAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAndReplaceInflationAssumptionMutation, { data, loading, error }] = useDeleteAndReplaceInflationAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteAndReplaceInflationAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAndReplaceInflationAssumptionMutation,
    DeleteAndReplaceInflationAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAndReplaceInflationAssumptionMutation,
    DeleteAndReplaceInflationAssumptionMutationVariables
  >(DeleteAndReplaceInflationAssumptionDocument, options)
}
export type DeleteAndReplaceInflationAssumptionMutationHookResult = ReturnType<
  typeof useDeleteAndReplaceInflationAssumptionMutation
>
export type DeleteAndReplaceInflationAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteAndReplaceInflationAssumptionMutation>
export type DeleteAndReplaceInflationAssumptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteAndReplaceInflationAssumptionMutation,
    DeleteAndReplaceInflationAssumptionMutationVariables
  >
export const DeleteMapeDayAheadAssumptionDocument = gql`
  mutation DeleteMapeDayAheadAssumption(
    $input: DeleteAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      forecastMapeDayAhead {
        delete(input: $input) {
          ...DeleteMapeDayAheadData
        }
      }
    }
  }
  ${DeleteMapeDayAheadDataFragmentDoc}
`
export type DeleteMapeDayAheadAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMapeDayAheadAssumptionMutation,
  DeleteMapeDayAheadAssumptionMutationVariables
>

/**
 * __useDeleteMapeDayAheadAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteMapeDayAheadAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMapeDayAheadAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMapeDayAheadAssumptionMutation, { data, loading, error }] = useDeleteMapeDayAheadAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteMapeDayAheadAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMapeDayAheadAssumptionMutation,
    DeleteMapeDayAheadAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteMapeDayAheadAssumptionMutation,
    DeleteMapeDayAheadAssumptionMutationVariables
  >(DeleteMapeDayAheadAssumptionDocument, options)
}
export type DeleteMapeDayAheadAssumptionMutationHookResult = ReturnType<
  typeof useDeleteMapeDayAheadAssumptionMutation
>
export type DeleteMapeDayAheadAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteMapeDayAheadAssumptionMutation>
export type DeleteMapeDayAheadAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMapeDayAheadAssumptionMutation,
  DeleteMapeDayAheadAssumptionMutationVariables
>
export const DeleteAndReplaceMapeDayAheadAssumptionDocument = gql`
  mutation DeleteAndReplaceMapeDayAheadAssumption(
    $input: DeleteAndReplaceAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      forecastMapeDayAhead {
        deleteAndReplace(input: $input) {
          ...DeleteMapeDayAheadData
        }
      }
    }
  }
  ${DeleteMapeDayAheadDataFragmentDoc}
`
export type DeleteAndReplaceMapeDayAheadAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAndReplaceMapeDayAheadAssumptionMutation,
  DeleteAndReplaceMapeDayAheadAssumptionMutationVariables
>

/**
 * __useDeleteAndReplaceMapeDayAheadAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteAndReplaceMapeDayAheadAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAndReplaceMapeDayAheadAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAndReplaceMapeDayAheadAssumptionMutation, { data, loading, error }] = useDeleteAndReplaceMapeDayAheadAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteAndReplaceMapeDayAheadAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAndReplaceMapeDayAheadAssumptionMutation,
    DeleteAndReplaceMapeDayAheadAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAndReplaceMapeDayAheadAssumptionMutation,
    DeleteAndReplaceMapeDayAheadAssumptionMutationVariables
  >(DeleteAndReplaceMapeDayAheadAssumptionDocument, options)
}
export type DeleteAndReplaceMapeDayAheadAssumptionMutationHookResult = ReturnType<
  typeof useDeleteAndReplaceMapeDayAheadAssumptionMutation
>
export type DeleteAndReplaceMapeDayAheadAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteAndReplaceMapeDayAheadAssumptionMutation>
export type DeleteAndReplaceMapeDayAheadAssumptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteAndReplaceMapeDayAheadAssumptionMutation,
    DeleteAndReplaceMapeDayAheadAssumptionMutationVariables
  >
export const DeleteMapeIntradayAssumptionDocument = gql`
  mutation DeleteMapeIntradayAssumption(
    $input: DeleteAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      forecastMapeIntraday {
        delete(input: $input) {
          ...DeleteMapeIntradayData
        }
      }
    }
  }
  ${DeleteMapeIntradayDataFragmentDoc}
`
export type DeleteMapeIntradayAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMapeIntradayAssumptionMutation,
  DeleteMapeIntradayAssumptionMutationVariables
>

/**
 * __useDeleteMapeIntradayAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteMapeIntradayAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMapeIntradayAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMapeIntradayAssumptionMutation, { data, loading, error }] = useDeleteMapeIntradayAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteMapeIntradayAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMapeIntradayAssumptionMutation,
    DeleteMapeIntradayAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteMapeIntradayAssumptionMutation,
    DeleteMapeIntradayAssumptionMutationVariables
  >(DeleteMapeIntradayAssumptionDocument, options)
}
export type DeleteMapeIntradayAssumptionMutationHookResult = ReturnType<
  typeof useDeleteMapeIntradayAssumptionMutation
>
export type DeleteMapeIntradayAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteMapeIntradayAssumptionMutation>
export type DeleteMapeIntradayAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMapeIntradayAssumptionMutation,
  DeleteMapeIntradayAssumptionMutationVariables
>
export const DeleteAndReplaceMapeIntradayAssumptionDocument = gql`
  mutation DeleteAndReplaceMapeIntradayAssumption(
    $input: DeleteAndReplaceAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      forecastMapeIntraday {
        deleteAndReplace(input: $input) {
          ...DeleteMapeIntradayData
        }
      }
    }
  }
  ${DeleteMapeIntradayDataFragmentDoc}
`
export type DeleteAndReplaceMapeIntradayAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAndReplaceMapeIntradayAssumptionMutation,
  DeleteAndReplaceMapeIntradayAssumptionMutationVariables
>

/**
 * __useDeleteAndReplaceMapeIntradayAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteAndReplaceMapeIntradayAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAndReplaceMapeIntradayAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAndReplaceMapeIntradayAssumptionMutation, { data, loading, error }] = useDeleteAndReplaceMapeIntradayAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteAndReplaceMapeIntradayAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAndReplaceMapeIntradayAssumptionMutation,
    DeleteAndReplaceMapeIntradayAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAndReplaceMapeIntradayAssumptionMutation,
    DeleteAndReplaceMapeIntradayAssumptionMutationVariables
  >(DeleteAndReplaceMapeIntradayAssumptionDocument, options)
}
export type DeleteAndReplaceMapeIntradayAssumptionMutationHookResult = ReturnType<
  typeof useDeleteAndReplaceMapeIntradayAssumptionMutation
>
export type DeleteAndReplaceMapeIntradayAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteAndReplaceMapeIntradayAssumptionMutation>
export type DeleteAndReplaceMapeIntradayAssumptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteAndReplaceMapeIntradayAssumptionMutation,
    DeleteAndReplaceMapeIntradayAssumptionMutationVariables
  >
export const DeletePriceLevelAssumptionDocument = gql`
  mutation DeletePriceLevelAssumption(
    $input: DeleteAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      priceAverage {
        delete(input: $input) {
          ...DeletePriceLevelData
        }
      }
    }
  }
  ${DeletePriceLevelDataFragmentDoc}
`
export type DeletePriceLevelAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeletePriceLevelAssumptionMutation,
  DeletePriceLevelAssumptionMutationVariables
>

/**
 * __useDeletePriceLevelAssumptionMutation__
 *
 * To run a mutation, you first call `useDeletePriceLevelAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePriceLevelAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePriceLevelAssumptionMutation, { data, loading, error }] = useDeletePriceLevelAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeletePriceLevelAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePriceLevelAssumptionMutation,
    DeletePriceLevelAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePriceLevelAssumptionMutation,
    DeletePriceLevelAssumptionMutationVariables
  >(DeletePriceLevelAssumptionDocument, options)
}
export type DeletePriceLevelAssumptionMutationHookResult = ReturnType<
  typeof useDeletePriceLevelAssumptionMutation
>
export type DeletePriceLevelAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeletePriceLevelAssumptionMutation>
export type DeletePriceLevelAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePriceLevelAssumptionMutation,
  DeletePriceLevelAssumptionMutationVariables
>
export const DeleteAndReplacePriceLevelAssumptionDocument = gql`
  mutation DeleteAndReplacePriceLevelAssumption(
    $input: DeleteAndReplaceAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      priceAverage {
        deleteAndReplace(input: $input) {
          ...DeletePriceLevelData
        }
      }
    }
  }
  ${DeletePriceLevelDataFragmentDoc}
`
export type DeleteAndReplacePriceLevelAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAndReplacePriceLevelAssumptionMutation,
  DeleteAndReplacePriceLevelAssumptionMutationVariables
>

/**
 * __useDeleteAndReplacePriceLevelAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteAndReplacePriceLevelAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAndReplacePriceLevelAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAndReplacePriceLevelAssumptionMutation, { data, loading, error }] = useDeleteAndReplacePriceLevelAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteAndReplacePriceLevelAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAndReplacePriceLevelAssumptionMutation,
    DeleteAndReplacePriceLevelAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAndReplacePriceLevelAssumptionMutation,
    DeleteAndReplacePriceLevelAssumptionMutationVariables
  >(DeleteAndReplacePriceLevelAssumptionDocument, options)
}
export type DeleteAndReplacePriceLevelAssumptionMutationHookResult = ReturnType<
  typeof useDeleteAndReplacePriceLevelAssumptionMutation
>
export type DeleteAndReplacePriceLevelAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteAndReplacePriceLevelAssumptionMutation>
export type DeleteAndReplacePriceLevelAssumptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteAndReplacePriceLevelAssumptionMutation,
    DeleteAndReplacePriceLevelAssumptionMutationVariables
  >
export const DeletePriceVolatilityAssumptionDocument = gql`
  mutation DeletePriceVolatilityAssumption(
    $input: DeleteAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      priceVolatility {
        delete(input: $input) {
          ...DeletePriceVolatilityData
        }
      }
    }
  }
  ${DeletePriceVolatilityDataFragmentDoc}
`
export type DeletePriceVolatilityAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeletePriceVolatilityAssumptionMutation,
  DeletePriceVolatilityAssumptionMutationVariables
>

/**
 * __useDeletePriceVolatilityAssumptionMutation__
 *
 * To run a mutation, you first call `useDeletePriceVolatilityAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePriceVolatilityAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePriceVolatilityAssumptionMutation, { data, loading, error }] = useDeletePriceVolatilityAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeletePriceVolatilityAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePriceVolatilityAssumptionMutation,
    DeletePriceVolatilityAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePriceVolatilityAssumptionMutation,
    DeletePriceVolatilityAssumptionMutationVariables
  >(DeletePriceVolatilityAssumptionDocument, options)
}
export type DeletePriceVolatilityAssumptionMutationHookResult = ReturnType<
  typeof useDeletePriceVolatilityAssumptionMutation
>
export type DeletePriceVolatilityAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeletePriceVolatilityAssumptionMutation>
export type DeletePriceVolatilityAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePriceVolatilityAssumptionMutation,
  DeletePriceVolatilityAssumptionMutationVariables
>
export const DeleteAndReplacePriceVolatilityAssumptionDocument = gql`
  mutation DeleteAndReplacePriceVolatilityAssumption(
    $input: DeleteAndReplaceAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      priceVolatility {
        deleteAndReplace(input: $input) {
          ...DeletePriceVolatilityData
        }
      }
    }
  }
  ${DeletePriceVolatilityDataFragmentDoc}
`
export type DeleteAndReplacePriceVolatilityAssumptionMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteAndReplacePriceVolatilityAssumptionMutation,
    DeleteAndReplacePriceVolatilityAssumptionMutationVariables
  >

/**
 * __useDeleteAndReplacePriceVolatilityAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteAndReplacePriceVolatilityAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAndReplacePriceVolatilityAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAndReplacePriceVolatilityAssumptionMutation, { data, loading, error }] = useDeleteAndReplacePriceVolatilityAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteAndReplacePriceVolatilityAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAndReplacePriceVolatilityAssumptionMutation,
    DeleteAndReplacePriceVolatilityAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAndReplacePriceVolatilityAssumptionMutation,
    DeleteAndReplacePriceVolatilityAssumptionMutationVariables
  >(DeleteAndReplacePriceVolatilityAssumptionDocument, options)
}
export type DeleteAndReplacePriceVolatilityAssumptionMutationHookResult = ReturnType<
  typeof useDeleteAndReplacePriceVolatilityAssumptionMutation
>
export type DeleteAndReplacePriceVolatilityAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteAndReplacePriceVolatilityAssumptionMutation>
export type DeleteAndReplacePriceVolatilityAssumptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteAndReplacePriceVolatilityAssumptionMutation,
    DeleteAndReplacePriceVolatilityAssumptionMutationVariables
  >
export const DeleteCurtailmentAssumptionDocument = gql`
  mutation DeleteCurtailmentAssumption(
    $input: DeleteAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      curtailment {
        delete(input: $input) {
          ...DeleteCurtailmentData
        }
      }
    }
  }
  ${DeleteCurtailmentDataFragmentDoc}
`
export type DeleteCurtailmentAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCurtailmentAssumptionMutation,
  DeleteCurtailmentAssumptionMutationVariables
>

/**
 * __useDeleteCurtailmentAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteCurtailmentAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurtailmentAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurtailmentAssumptionMutation, { data, loading, error }] = useDeleteCurtailmentAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteCurtailmentAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCurtailmentAssumptionMutation,
    DeleteCurtailmentAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCurtailmentAssumptionMutation,
    DeleteCurtailmentAssumptionMutationVariables
  >(DeleteCurtailmentAssumptionDocument, options)
}
export type DeleteCurtailmentAssumptionMutationHookResult = ReturnType<
  typeof useDeleteCurtailmentAssumptionMutation
>
export type DeleteCurtailmentAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteCurtailmentAssumptionMutation>
export type DeleteCurtailmentAssumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCurtailmentAssumptionMutation,
  DeleteCurtailmentAssumptionMutationVariables
>
export const DeleteAndReplaceCurtailmentAssumptionDocument = gql`
  mutation DeleteAndReplaceCurtailmentAssumption(
    $input: DeleteAndReplaceAssumptionInput!
    $queryInput: GetAssumptionListInput!
  ) {
    scenarioAssumption {
      curtailment {
        deleteAndReplace(input: $input) {
          ...DeleteCurtailmentData
        }
      }
    }
  }
  ${DeleteCurtailmentDataFragmentDoc}
`
export type DeleteAndReplaceCurtailmentAssumptionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAndReplaceCurtailmentAssumptionMutation,
  DeleteAndReplaceCurtailmentAssumptionMutationVariables
>

/**
 * __useDeleteAndReplaceCurtailmentAssumptionMutation__
 *
 * To run a mutation, you first call `useDeleteAndReplaceCurtailmentAssumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAndReplaceCurtailmentAssumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAndReplaceCurtailmentAssumptionMutation, { data, loading, error }] = useDeleteAndReplaceCurtailmentAssumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteAndReplaceCurtailmentAssumptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAndReplaceCurtailmentAssumptionMutation,
    DeleteAndReplaceCurtailmentAssumptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAndReplaceCurtailmentAssumptionMutation,
    DeleteAndReplaceCurtailmentAssumptionMutationVariables
  >(DeleteAndReplaceCurtailmentAssumptionDocument, options)
}
export type DeleteAndReplaceCurtailmentAssumptionMutationHookResult = ReturnType<
  typeof useDeleteAndReplaceCurtailmentAssumptionMutation
>
export type DeleteAndReplaceCurtailmentAssumptionMutationResult =
  ApolloReactCommon.MutationResult<DeleteAndReplaceCurtailmentAssumptionMutation>
export type DeleteAndReplaceCurtailmentAssumptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteAndReplaceCurtailmentAssumptionMutation,
    DeleteAndReplaceCurtailmentAssumptionMutationVariables
  >
export const InflationAssumptionDocument = gql`
  query InflationAssumption($input: QueryEntityInput!) {
    scenarioAssumption {
      inflation(input: $input) {
        ...InflationAssumptionCrudData
      }
    }
  }
  ${InflationAssumptionCrudDataFragmentDoc}
`

/**
 * __useInflationAssumptionQuery__
 *
 * To run a query within a React component, call `useInflationAssumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInflationAssumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInflationAssumptionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInflationAssumptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    InflationAssumptionQuery,
    InflationAssumptionQueryVariables
  > &
    ({ variables: InflationAssumptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InflationAssumptionQuery, InflationAssumptionQueryVariables>(
    InflationAssumptionDocument,
    options
  )
}
export function useInflationAssumptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InflationAssumptionQuery,
    InflationAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InflationAssumptionQuery, InflationAssumptionQueryVariables>(
    InflationAssumptionDocument,
    options
  )
}
export function useInflationAssumptionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InflationAssumptionQuery,
    InflationAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<InflationAssumptionQuery, InflationAssumptionQueryVariables>(
    InflationAssumptionDocument,
    options
  )
}
export type InflationAssumptionQueryHookResult = ReturnType<typeof useInflationAssumptionQuery>
export type InflationAssumptionLazyQueryHookResult = ReturnType<
  typeof useInflationAssumptionLazyQuery
>
export type InflationAssumptionSuspenseQueryHookResult = ReturnType<
  typeof useInflationAssumptionSuspenseQuery
>
export type InflationAssumptionQueryResult = ApolloReactCommon.QueryResult<
  InflationAssumptionQuery,
  InflationAssumptionQueryVariables
>
export const CurtailmentAssumptionDocument = gql`
  query CurtailmentAssumption($input: QueryEntityInput!) {
    scenarioAssumption {
      curtailment(input: $input) {
        ...CurtailmentAssumptionCrudData
      }
    }
  }
  ${CurtailmentAssumptionCrudDataFragmentDoc}
`

/**
 * __useCurtailmentAssumptionQuery__
 *
 * To run a query within a React component, call `useCurtailmentAssumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurtailmentAssumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurtailmentAssumptionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCurtailmentAssumptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurtailmentAssumptionQuery,
    CurtailmentAssumptionQueryVariables
  > &
    ({ variables: CurtailmentAssumptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurtailmentAssumptionQuery, CurtailmentAssumptionQueryVariables>(
    CurtailmentAssumptionDocument,
    options
  )
}
export function useCurtailmentAssumptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurtailmentAssumptionQuery,
    CurtailmentAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurtailmentAssumptionQuery, CurtailmentAssumptionQueryVariables>(
    CurtailmentAssumptionDocument,
    options
  )
}
export function useCurtailmentAssumptionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurtailmentAssumptionQuery,
    CurtailmentAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CurtailmentAssumptionQuery, CurtailmentAssumptionQueryVariables>(
    CurtailmentAssumptionDocument,
    options
  )
}
export type CurtailmentAssumptionQueryHookResult = ReturnType<typeof useCurtailmentAssumptionQuery>
export type CurtailmentAssumptionLazyQueryHookResult = ReturnType<
  typeof useCurtailmentAssumptionLazyQuery
>
export type CurtailmentAssumptionSuspenseQueryHookResult = ReturnType<
  typeof useCurtailmentAssumptionSuspenseQuery
>
export type CurtailmentAssumptionQueryResult = ApolloReactCommon.QueryResult<
  CurtailmentAssumptionQuery,
  CurtailmentAssumptionQueryVariables
>
export const PriceLevelAssumptionDocument = gql`
  query PriceLevelAssumption($input: QueryEntityInput!) {
    scenarioAssumption {
      priceAverage(input: $input) {
        ...PriceLevelAssumptionCrudData
      }
    }
  }
  ${PriceLevelAssumptionCrudDataFragmentDoc}
`

/**
 * __usePriceLevelAssumptionQuery__
 *
 * To run a query within a React component, call `usePriceLevelAssumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceLevelAssumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceLevelAssumptionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePriceLevelAssumptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    PriceLevelAssumptionQuery,
    PriceLevelAssumptionQueryVariables
  > &
    ({ variables: PriceLevelAssumptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PriceLevelAssumptionQuery, PriceLevelAssumptionQueryVariables>(
    PriceLevelAssumptionDocument,
    options
  )
}
export function usePriceLevelAssumptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PriceLevelAssumptionQuery,
    PriceLevelAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PriceLevelAssumptionQuery, PriceLevelAssumptionQueryVariables>(
    PriceLevelAssumptionDocument,
    options
  )
}
export function usePriceLevelAssumptionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PriceLevelAssumptionQuery,
    PriceLevelAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PriceLevelAssumptionQuery, PriceLevelAssumptionQueryVariables>(
    PriceLevelAssumptionDocument,
    options
  )
}
export type PriceLevelAssumptionQueryHookResult = ReturnType<typeof usePriceLevelAssumptionQuery>
export type PriceLevelAssumptionLazyQueryHookResult = ReturnType<
  typeof usePriceLevelAssumptionLazyQuery
>
export type PriceLevelAssumptionSuspenseQueryHookResult = ReturnType<
  typeof usePriceLevelAssumptionSuspenseQuery
>
export type PriceLevelAssumptionQueryResult = ApolloReactCommon.QueryResult<
  PriceLevelAssumptionQuery,
  PriceLevelAssumptionQueryVariables
>
export const PriceVolatilityAssumptionDocument = gql`
  query PriceVolatilityAssumption($input: QueryEntityInput!) {
    scenarioAssumption {
      priceVolatility(input: $input) {
        ...PriceVolatilityAssumptionCrudData
      }
    }
  }
  ${PriceVolatilityAssumptionCrudDataFragmentDoc}
`

/**
 * __usePriceVolatilityAssumptionQuery__
 *
 * To run a query within a React component, call `usePriceVolatilityAssumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceVolatilityAssumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceVolatilityAssumptionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePriceVolatilityAssumptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    PriceVolatilityAssumptionQuery,
    PriceVolatilityAssumptionQueryVariables
  > &
    ({ variables: PriceVolatilityAssumptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PriceVolatilityAssumptionQuery, PriceVolatilityAssumptionQueryVariables>(
    PriceVolatilityAssumptionDocument,
    options
  )
}
export function usePriceVolatilityAssumptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PriceVolatilityAssumptionQuery,
    PriceVolatilityAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PriceVolatilityAssumptionQuery,
    PriceVolatilityAssumptionQueryVariables
  >(PriceVolatilityAssumptionDocument, options)
}
export function usePriceVolatilityAssumptionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PriceVolatilityAssumptionQuery,
    PriceVolatilityAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PriceVolatilityAssumptionQuery,
    PriceVolatilityAssumptionQueryVariables
  >(PriceVolatilityAssumptionDocument, options)
}
export type PriceVolatilityAssumptionQueryHookResult = ReturnType<
  typeof usePriceVolatilityAssumptionQuery
>
export type PriceVolatilityAssumptionLazyQueryHookResult = ReturnType<
  typeof usePriceVolatilityAssumptionLazyQuery
>
export type PriceVolatilityAssumptionSuspenseQueryHookResult = ReturnType<
  typeof usePriceVolatilityAssumptionSuspenseQuery
>
export type PriceVolatilityAssumptionQueryResult = ApolloReactCommon.QueryResult<
  PriceVolatilityAssumptionQuery,
  PriceVolatilityAssumptionQueryVariables
>
export const DayAheadMapeAssumptionDocument = gql`
  query DayAheadMapeAssumption($input: QueryEntityInput!) {
    scenarioAssumption {
      forecastMapeDayAhead(input: $input) {
        ...DayAheadMapeAssumptionCrudData
      }
    }
  }
  ${DayAheadMapeAssumptionCrudDataFragmentDoc}
`

/**
 * __useDayAheadMapeAssumptionQuery__
 *
 * To run a query within a React component, call `useDayAheadMapeAssumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayAheadMapeAssumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayAheadMapeAssumptionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDayAheadMapeAssumptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    DayAheadMapeAssumptionQuery,
    DayAheadMapeAssumptionQueryVariables
  > &
    ({ variables: DayAheadMapeAssumptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DayAheadMapeAssumptionQuery, DayAheadMapeAssumptionQueryVariables>(
    DayAheadMapeAssumptionDocument,
    options
  )
}
export function useDayAheadMapeAssumptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DayAheadMapeAssumptionQuery,
    DayAheadMapeAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DayAheadMapeAssumptionQuery, DayAheadMapeAssumptionQueryVariables>(
    DayAheadMapeAssumptionDocument,
    options
  )
}
export function useDayAheadMapeAssumptionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DayAheadMapeAssumptionQuery,
    DayAheadMapeAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DayAheadMapeAssumptionQuery, DayAheadMapeAssumptionQueryVariables>(
    DayAheadMapeAssumptionDocument,
    options
  )
}
export type DayAheadMapeAssumptionQueryHookResult = ReturnType<
  typeof useDayAheadMapeAssumptionQuery
>
export type DayAheadMapeAssumptionLazyQueryHookResult = ReturnType<
  typeof useDayAheadMapeAssumptionLazyQuery
>
export type DayAheadMapeAssumptionSuspenseQueryHookResult = ReturnType<
  typeof useDayAheadMapeAssumptionSuspenseQuery
>
export type DayAheadMapeAssumptionQueryResult = ApolloReactCommon.QueryResult<
  DayAheadMapeAssumptionQuery,
  DayAheadMapeAssumptionQueryVariables
>
export const IntradayMapeAssumptionDocument = gql`
  query IntradayMapeAssumption($input: QueryEntityInput!) {
    scenarioAssumption {
      forecastMapeIntraday(input: $input) {
        ...IntradayMapeAssumptionCrudData
      }
    }
  }
  ${IntradayMapeAssumptionCrudDataFragmentDoc}
`

/**
 * __useIntradayMapeAssumptionQuery__
 *
 * To run a query within a React component, call `useIntradayMapeAssumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntradayMapeAssumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntradayMapeAssumptionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntradayMapeAssumptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntradayMapeAssumptionQuery,
    IntradayMapeAssumptionQueryVariables
  > &
    ({ variables: IntradayMapeAssumptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IntradayMapeAssumptionQuery, IntradayMapeAssumptionQueryVariables>(
    IntradayMapeAssumptionDocument,
    options
  )
}
export function useIntradayMapeAssumptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntradayMapeAssumptionQuery,
    IntradayMapeAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IntradayMapeAssumptionQuery, IntradayMapeAssumptionQueryVariables>(
    IntradayMapeAssumptionDocument,
    options
  )
}
export function useIntradayMapeAssumptionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IntradayMapeAssumptionQuery,
    IntradayMapeAssumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IntradayMapeAssumptionQuery, IntradayMapeAssumptionQueryVariables>(
    IntradayMapeAssumptionDocument,
    options
  )
}
export type IntradayMapeAssumptionQueryHookResult = ReturnType<
  typeof useIntradayMapeAssumptionQuery
>
export type IntradayMapeAssumptionLazyQueryHookResult = ReturnType<
  typeof useIntradayMapeAssumptionLazyQuery
>
export type IntradayMapeAssumptionSuspenseQueryHookResult = ReturnType<
  typeof useIntradayMapeAssumptionSuspenseQuery
>
export type IntradayMapeAssumptionQueryResult = ApolloReactCommon.QueryResult<
  IntradayMapeAssumptionQuery,
  IntradayMapeAssumptionQueryVariables
>
export const InflationAssumptionsDocument = gql`
  query InflationAssumptions($input: GetAssumptionListInput!) {
    scenarioAssumptions {
      inflation(input: $input) {
        records {
          ...ScenarioAssumptionBaseData
        }
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`

/**
 * __useInflationAssumptionsQuery__
 *
 * To run a query within a React component, call `useInflationAssumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInflationAssumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInflationAssumptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInflationAssumptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InflationAssumptionsQuery,
    InflationAssumptionsQueryVariables
  > &
    ({ variables: InflationAssumptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InflationAssumptionsQuery, InflationAssumptionsQueryVariables>(
    InflationAssumptionsDocument,
    options
  )
}
export function useInflationAssumptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InflationAssumptionsQuery,
    InflationAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InflationAssumptionsQuery, InflationAssumptionsQueryVariables>(
    InflationAssumptionsDocument,
    options
  )
}
export function useInflationAssumptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InflationAssumptionsQuery,
    InflationAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<InflationAssumptionsQuery, InflationAssumptionsQueryVariables>(
    InflationAssumptionsDocument,
    options
  )
}
export type InflationAssumptionsQueryHookResult = ReturnType<typeof useInflationAssumptionsQuery>
export type InflationAssumptionsLazyQueryHookResult = ReturnType<
  typeof useInflationAssumptionsLazyQuery
>
export type InflationAssumptionsSuspenseQueryHookResult = ReturnType<
  typeof useInflationAssumptionsSuspenseQuery
>
export type InflationAssumptionsQueryResult = ApolloReactCommon.QueryResult<
  InflationAssumptionsQuery,
  InflationAssumptionsQueryVariables
>
export const CurtailmentAssumptionsDocument = gql`
  query CurtailmentAssumptions($input: GetAssumptionListInput!) {
    scenarioAssumptions {
      curtailment(input: $input) {
        records {
          ...ScenarioAssumptionBaseData
        }
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`

/**
 * __useCurtailmentAssumptionsQuery__
 *
 * To run a query within a React component, call `useCurtailmentAssumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurtailmentAssumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurtailmentAssumptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCurtailmentAssumptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurtailmentAssumptionsQuery,
    CurtailmentAssumptionsQueryVariables
  > &
    ({ variables: CurtailmentAssumptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurtailmentAssumptionsQuery, CurtailmentAssumptionsQueryVariables>(
    CurtailmentAssumptionsDocument,
    options
  )
}
export function useCurtailmentAssumptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurtailmentAssumptionsQuery,
    CurtailmentAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurtailmentAssumptionsQuery, CurtailmentAssumptionsQueryVariables>(
    CurtailmentAssumptionsDocument,
    options
  )
}
export function useCurtailmentAssumptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurtailmentAssumptionsQuery,
    CurtailmentAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CurtailmentAssumptionsQuery, CurtailmentAssumptionsQueryVariables>(
    CurtailmentAssumptionsDocument,
    options
  )
}
export type CurtailmentAssumptionsQueryHookResult = ReturnType<
  typeof useCurtailmentAssumptionsQuery
>
export type CurtailmentAssumptionsLazyQueryHookResult = ReturnType<
  typeof useCurtailmentAssumptionsLazyQuery
>
export type CurtailmentAssumptionsSuspenseQueryHookResult = ReturnType<
  typeof useCurtailmentAssumptionsSuspenseQuery
>
export type CurtailmentAssumptionsQueryResult = ApolloReactCommon.QueryResult<
  CurtailmentAssumptionsQuery,
  CurtailmentAssumptionsQueryVariables
>
export const PriceLevelAssumptionsDocument = gql`
  query PriceLevelAssumptions($input: GetAssumptionListInput!) {
    scenarioAssumptions {
      priceAverage(input: $input) {
        records {
          ...ScenarioAssumptionBaseData
        }
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`

/**
 * __usePriceLevelAssumptionsQuery__
 *
 * To run a query within a React component, call `usePriceLevelAssumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceLevelAssumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceLevelAssumptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePriceLevelAssumptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PriceLevelAssumptionsQuery,
    PriceLevelAssumptionsQueryVariables
  > &
    ({ variables: PriceLevelAssumptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PriceLevelAssumptionsQuery, PriceLevelAssumptionsQueryVariables>(
    PriceLevelAssumptionsDocument,
    options
  )
}
export function usePriceLevelAssumptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PriceLevelAssumptionsQuery,
    PriceLevelAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PriceLevelAssumptionsQuery, PriceLevelAssumptionsQueryVariables>(
    PriceLevelAssumptionsDocument,
    options
  )
}
export function usePriceLevelAssumptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PriceLevelAssumptionsQuery,
    PriceLevelAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PriceLevelAssumptionsQuery, PriceLevelAssumptionsQueryVariables>(
    PriceLevelAssumptionsDocument,
    options
  )
}
export type PriceLevelAssumptionsQueryHookResult = ReturnType<typeof usePriceLevelAssumptionsQuery>
export type PriceLevelAssumptionsLazyQueryHookResult = ReturnType<
  typeof usePriceLevelAssumptionsLazyQuery
>
export type PriceLevelAssumptionsSuspenseQueryHookResult = ReturnType<
  typeof usePriceLevelAssumptionsSuspenseQuery
>
export type PriceLevelAssumptionsQueryResult = ApolloReactCommon.QueryResult<
  PriceLevelAssumptionsQuery,
  PriceLevelAssumptionsQueryVariables
>
export const PriceVolatilityAssumptionsDocument = gql`
  query PriceVolatilityAssumptions($input: GetAssumptionListInput!) {
    scenarioAssumptions {
      priceVolatility(input: $input) {
        records {
          ...ScenarioAssumptionBaseData
        }
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`

/**
 * __usePriceVolatilityAssumptionsQuery__
 *
 * To run a query within a React component, call `usePriceVolatilityAssumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceVolatilityAssumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceVolatilityAssumptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePriceVolatilityAssumptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PriceVolatilityAssumptionsQuery,
    PriceVolatilityAssumptionsQueryVariables
  > &
    ({ variables: PriceVolatilityAssumptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PriceVolatilityAssumptionsQuery, PriceVolatilityAssumptionsQueryVariables>(
    PriceVolatilityAssumptionsDocument,
    options
  )
}
export function usePriceVolatilityAssumptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PriceVolatilityAssumptionsQuery,
    PriceVolatilityAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PriceVolatilityAssumptionsQuery,
    PriceVolatilityAssumptionsQueryVariables
  >(PriceVolatilityAssumptionsDocument, options)
}
export function usePriceVolatilityAssumptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PriceVolatilityAssumptionsQuery,
    PriceVolatilityAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PriceVolatilityAssumptionsQuery,
    PriceVolatilityAssumptionsQueryVariables
  >(PriceVolatilityAssumptionsDocument, options)
}
export type PriceVolatilityAssumptionsQueryHookResult = ReturnType<
  typeof usePriceVolatilityAssumptionsQuery
>
export type PriceVolatilityAssumptionsLazyQueryHookResult = ReturnType<
  typeof usePriceVolatilityAssumptionsLazyQuery
>
export type PriceVolatilityAssumptionsSuspenseQueryHookResult = ReturnType<
  typeof usePriceVolatilityAssumptionsSuspenseQuery
>
export type PriceVolatilityAssumptionsQueryResult = ApolloReactCommon.QueryResult<
  PriceVolatilityAssumptionsQuery,
  PriceVolatilityAssumptionsQueryVariables
>
export const DayAheadMapeAssumptionsDocument = gql`
  query DayAheadMapeAssumptions($input: GetAssumptionListInput!) {
    scenarioAssumptions {
      forecastMapeDayAhead(input: $input) {
        records {
          ...ScenarioAssumptionBaseData
        }
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`

/**
 * __useDayAheadMapeAssumptionsQuery__
 *
 * To run a query within a React component, call `useDayAheadMapeAssumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayAheadMapeAssumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayAheadMapeAssumptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDayAheadMapeAssumptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DayAheadMapeAssumptionsQuery,
    DayAheadMapeAssumptionsQueryVariables
  > &
    ({ variables: DayAheadMapeAssumptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DayAheadMapeAssumptionsQuery, DayAheadMapeAssumptionsQueryVariables>(
    DayAheadMapeAssumptionsDocument,
    options
  )
}
export function useDayAheadMapeAssumptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DayAheadMapeAssumptionsQuery,
    DayAheadMapeAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DayAheadMapeAssumptionsQuery, DayAheadMapeAssumptionsQueryVariables>(
    DayAheadMapeAssumptionsDocument,
    options
  )
}
export function useDayAheadMapeAssumptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DayAheadMapeAssumptionsQuery,
    DayAheadMapeAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    DayAheadMapeAssumptionsQuery,
    DayAheadMapeAssumptionsQueryVariables
  >(DayAheadMapeAssumptionsDocument, options)
}
export type DayAheadMapeAssumptionsQueryHookResult = ReturnType<
  typeof useDayAheadMapeAssumptionsQuery
>
export type DayAheadMapeAssumptionsLazyQueryHookResult = ReturnType<
  typeof useDayAheadMapeAssumptionsLazyQuery
>
export type DayAheadMapeAssumptionsSuspenseQueryHookResult = ReturnType<
  typeof useDayAheadMapeAssumptionsSuspenseQuery
>
export type DayAheadMapeAssumptionsQueryResult = ApolloReactCommon.QueryResult<
  DayAheadMapeAssumptionsQuery,
  DayAheadMapeAssumptionsQueryVariables
>
export const IntradayMapeAssumptionsDocument = gql`
  query IntradayMapeAssumptions($input: GetAssumptionListInput!) {
    scenarioAssumptions {
      forecastMapeIntraday(input: $input) {
        records {
          ...ScenarioAssumptionBaseData
        }
      }
    }
  }
  ${ScenarioAssumptionBaseDataFragmentDoc}
`

/**
 * __useIntradayMapeAssumptionsQuery__
 *
 * To run a query within a React component, call `useIntradayMapeAssumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntradayMapeAssumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntradayMapeAssumptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntradayMapeAssumptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntradayMapeAssumptionsQuery,
    IntradayMapeAssumptionsQueryVariables
  > &
    ({ variables: IntradayMapeAssumptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IntradayMapeAssumptionsQuery, IntradayMapeAssumptionsQueryVariables>(
    IntradayMapeAssumptionsDocument,
    options
  )
}
export function useIntradayMapeAssumptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntradayMapeAssumptionsQuery,
    IntradayMapeAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IntradayMapeAssumptionsQuery, IntradayMapeAssumptionsQueryVariables>(
    IntradayMapeAssumptionsDocument,
    options
  )
}
export function useIntradayMapeAssumptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IntradayMapeAssumptionsQuery,
    IntradayMapeAssumptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    IntradayMapeAssumptionsQuery,
    IntradayMapeAssumptionsQueryVariables
  >(IntradayMapeAssumptionsDocument, options)
}
export type IntradayMapeAssumptionsQueryHookResult = ReturnType<
  typeof useIntradayMapeAssumptionsQuery
>
export type IntradayMapeAssumptionsLazyQueryHookResult = ReturnType<
  typeof useIntradayMapeAssumptionsLazyQuery
>
export type IntradayMapeAssumptionsSuspenseQueryHookResult = ReturnType<
  typeof useIntradayMapeAssumptionsSuspenseQuery
>
export type IntradayMapeAssumptionsQueryResult = ApolloReactCommon.QueryResult<
  IntradayMapeAssumptionsQuery,
  IntradayMapeAssumptionsQueryVariables
>
export const AssumptionsOptionsDocument = gql`
  query AssumptionsOptions($queryInput: GetAssumptionListInput!) {
    scenarioAssumptions {
      ...AssumptionOptionsBaseQuery
    }
  }
  ${AssumptionOptionsBaseQueryFragmentDoc}
`

/**
 * __useAssumptionsOptionsQuery__
 *
 * To run a query within a React component, call `useAssumptionsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssumptionsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssumptionsOptionsQuery({
 *   variables: {
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useAssumptionsOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<AssumptionsOptionsQuery, AssumptionsOptionsQueryVariables> &
    ({ variables: AssumptionsOptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AssumptionsOptionsQuery, AssumptionsOptionsQueryVariables>(
    AssumptionsOptionsDocument,
    options
  )
}
export function useAssumptionsOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssumptionsOptionsQuery,
    AssumptionsOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AssumptionsOptionsQuery, AssumptionsOptionsQueryVariables>(
    AssumptionsOptionsDocument,
    options
  )
}
export function useAssumptionsOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AssumptionsOptionsQuery,
    AssumptionsOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AssumptionsOptionsQuery, AssumptionsOptionsQueryVariables>(
    AssumptionsOptionsDocument,
    options
  )
}
export type AssumptionsOptionsQueryHookResult = ReturnType<typeof useAssumptionsOptionsQuery>
export type AssumptionsOptionsLazyQueryHookResult = ReturnType<
  typeof useAssumptionsOptionsLazyQuery
>
export type AssumptionsOptionsSuspenseQueryHookResult = ReturnType<
  typeof useAssumptionsOptionsSuspenseQuery
>
export type AssumptionsOptionsQueryResult = ApolloReactCommon.QueryResult<
  AssumptionsOptionsQuery,
  AssumptionsOptionsQueryVariables
>
export const SendLoginMagicLinkDocument = gql`
  mutation SendLoginMagicLink($input: SendLoginMagicLinkInput!) {
    user {
      sendLoginMagicLink(input: $input) {
        errors {
          message
        }
        success
      }
    }
  }
`
export type SendLoginMagicLinkMutationFn = ApolloReactCommon.MutationFunction<
  SendLoginMagicLinkMutation,
  SendLoginMagicLinkMutationVariables
>

/**
 * __useSendLoginMagicLinkMutation__
 *
 * To run a mutation, you first call `useSendLoginMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLoginMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLoginMagicLinkMutation, { data, loading, error }] = useSendLoginMagicLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendLoginMagicLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendLoginMagicLinkMutation,
    SendLoginMagicLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendLoginMagicLinkMutation, SendLoginMagicLinkMutationVariables>(
    SendLoginMagicLinkDocument,
    options
  )
}
export type SendLoginMagicLinkMutationHookResult = ReturnType<typeof useSendLoginMagicLinkMutation>
export type SendLoginMagicLinkMutationResult =
  ApolloReactCommon.MutationResult<SendLoginMagicLinkMutation>
export type SendLoginMagicLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendLoginMagicLinkMutation,
  SendLoginMagicLinkMutationVariables
>
export const InvitationDocument = gql`
  query Invitation($input: InvitationInput!) {
    invitation(input: $input) {
      email
      id
      status
      type
      used
      workspaceName
    }
  }
`

/**
 * __useInvitationQuery__
 *
 * To run a query within a React component, call `useInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<InvitationQuery, InvitationQueryVariables> &
    ({ variables: InvitationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options)
}
export function useInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvitationQuery, InvitationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options)
}
export function useInvitationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationQuery, InvitationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<InvitationQuery, InvitationQueryVariables>(
    InvitationDocument,
    options
  )
}
export type InvitationQueryHookResult = ReturnType<typeof useInvitationQuery>
export type InvitationLazyQueryHookResult = ReturnType<typeof useInvitationLazyQuery>
export type InvitationSuspenseQueryHookResult = ReturnType<typeof useInvitationSuspenseQuery>
export type InvitationQueryResult = ApolloReactCommon.QueryResult<
  InvitationQuery,
  InvitationQueryVariables
>
export const SubmitOcctoPlanDocument = gql`
  mutation SubmitOcctoPlan(
    $input: SubmitOcctoPlanInput!
    $queryInput: OcctoDayAheadPlanInput!
    $generationPlanInput: BalancingGroupGenerationPlanInput!
  ) {
    occtoReporting {
      submitPlan(input: $input) {
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        query {
          occtoDayAheadPlan(input: $queryInput) {
            ...OcctoDayAheadPlanRecord
          }
        }
      }
    }
  }
  ${OcctoDayAheadPlanRecordFragmentDoc}
`
export type SubmitOcctoPlanMutationFn = ApolloReactCommon.MutationFunction<
  SubmitOcctoPlanMutation,
  SubmitOcctoPlanMutationVariables
>

/**
 * __useSubmitOcctoPlanMutation__
 *
 * To run a mutation, you first call `useSubmitOcctoPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOcctoPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOcctoPlanMutation, { data, loading, error }] = useSubmitOcctoPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *      generationPlanInput: // value for 'generationPlanInput'
 *   },
 * });
 */
export function useSubmitOcctoPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitOcctoPlanMutation,
    SubmitOcctoPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitOcctoPlanMutation, SubmitOcctoPlanMutationVariables>(
    SubmitOcctoPlanDocument,
    options
  )
}
export type SubmitOcctoPlanMutationHookResult = ReturnType<typeof useSubmitOcctoPlanMutation>
export type SubmitOcctoPlanMutationResult =
  ApolloReactCommon.MutationResult<SubmitOcctoPlanMutation>
export type SubmitOcctoPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitOcctoPlanMutation,
  SubmitOcctoPlanMutationVariables
>
export const UnSubmitOcctoPlanDocument = gql`
  mutation UnSubmitOcctoPlan(
    $input: UnsubmitOcctoPlanInput!
    $queryInput: OcctoDayAheadPlanInput!
    $generationPlanInput: BalancingGroupGenerationPlanInput!
  ) {
    occtoReporting {
      unsubmitPlan(input: $input) {
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        query {
          occtoDayAheadPlan(input: $queryInput) {
            ...OcctoDayAheadPlanRecord
          }
        }
      }
    }
  }
  ${OcctoDayAheadPlanRecordFragmentDoc}
`
export type UnSubmitOcctoPlanMutationFn = ApolloReactCommon.MutationFunction<
  UnSubmitOcctoPlanMutation,
  UnSubmitOcctoPlanMutationVariables
>

/**
 * __useUnSubmitOcctoPlanMutation__
 *
 * To run a mutation, you first call `useUnSubmitOcctoPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnSubmitOcctoPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unSubmitOcctoPlanMutation, { data, loading, error }] = useUnSubmitOcctoPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *      generationPlanInput: // value for 'generationPlanInput'
 *   },
 * });
 */
export function useUnSubmitOcctoPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnSubmitOcctoPlanMutation,
    UnSubmitOcctoPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnSubmitOcctoPlanMutation, UnSubmitOcctoPlanMutationVariables>(
    UnSubmitOcctoPlanDocument,
    options
  )
}
export type UnSubmitOcctoPlanMutationHookResult = ReturnType<typeof useUnSubmitOcctoPlanMutation>
export type UnSubmitOcctoPlanMutationResult =
  ApolloReactCommon.MutationResult<UnSubmitOcctoPlanMutation>
export type UnSubmitOcctoPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnSubmitOcctoPlanMutation,
  UnSubmitOcctoPlanMutationVariables
>
export const GenerateOcctoDayAheadPlanUrlDocument = gql`
  mutation GenerateOcctoDayAheadPlanUrl($input: GenerateOcctoPlanUrlInput!) {
    occtoReporting {
      generatePlanUrl(input: $input) {
        data
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on GenerateOcctoPlanUrlApiProblem {
            message
            type
          }
        }
        success
      }
    }
  }
`
export type GenerateOcctoDayAheadPlanUrlMutationFn = ApolloReactCommon.MutationFunction<
  GenerateOcctoDayAheadPlanUrlMutation,
  GenerateOcctoDayAheadPlanUrlMutationVariables
>

/**
 * __useGenerateOcctoDayAheadPlanUrlMutation__
 *
 * To run a mutation, you first call `useGenerateOcctoDayAheadPlanUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOcctoDayAheadPlanUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOcctoDayAheadPlanUrlMutation, { data, loading, error }] = useGenerateOcctoDayAheadPlanUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateOcctoDayAheadPlanUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateOcctoDayAheadPlanUrlMutation,
    GenerateOcctoDayAheadPlanUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GenerateOcctoDayAheadPlanUrlMutation,
    GenerateOcctoDayAheadPlanUrlMutationVariables
  >(GenerateOcctoDayAheadPlanUrlDocument, options)
}
export type GenerateOcctoDayAheadPlanUrlMutationHookResult = ReturnType<
  typeof useGenerateOcctoDayAheadPlanUrlMutation
>
export type GenerateOcctoDayAheadPlanUrlMutationResult =
  ApolloReactCommon.MutationResult<GenerateOcctoDayAheadPlanUrlMutation>
export type GenerateOcctoDayAheadPlanUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateOcctoDayAheadPlanUrlMutation,
  GenerateOcctoDayAheadPlanUrlMutationVariables
>
export const GenerateLongTermOcctoPlanUrlDocument = gql`
  mutation GenerateLongTermOcctoPlanUrl($input: GenerateOcctoLongTermPlanUrlInput!) {
    occtoReporting {
      generateLongTermPlanUrl(input: $input) {
        data
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on GenerateOcctoPlanUrlApiProblem {
            message
            type
          }
        }
        success
      }
    }
  }
`
export type GenerateLongTermOcctoPlanUrlMutationFn = ApolloReactCommon.MutationFunction<
  GenerateLongTermOcctoPlanUrlMutation,
  GenerateLongTermOcctoPlanUrlMutationVariables
>

/**
 * __useGenerateLongTermOcctoPlanUrlMutation__
 *
 * To run a mutation, you first call `useGenerateLongTermOcctoPlanUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLongTermOcctoPlanUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLongTermOcctoPlanUrlMutation, { data, loading, error }] = useGenerateLongTermOcctoPlanUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateLongTermOcctoPlanUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateLongTermOcctoPlanUrlMutation,
    GenerateLongTermOcctoPlanUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GenerateLongTermOcctoPlanUrlMutation,
    GenerateLongTermOcctoPlanUrlMutationVariables
  >(GenerateLongTermOcctoPlanUrlDocument, options)
}
export type GenerateLongTermOcctoPlanUrlMutationHookResult = ReturnType<
  typeof useGenerateLongTermOcctoPlanUrlMutation
>
export type GenerateLongTermOcctoPlanUrlMutationResult =
  ApolloReactCommon.MutationResult<GenerateLongTermOcctoPlanUrlMutation>
export type GenerateLongTermOcctoPlanUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateLongTermOcctoPlanUrlMutation,
  GenerateLongTermOcctoPlanUrlMutationVariables
>
export const OcctoDayAheadPlanDocument = gql`
  query OcctoDayAheadPlan(
    $input: OcctoDayAheadPlanInput!
    $generationPlanInput: BalancingGroupGenerationPlanInput!
    $workspaceId: ID!
  ) {
    occtoDayAheadPlan(input: $input) {
      ...OcctoDayAheadPlanRecord
    }
    workspace(input: $workspaceId) {
      ...OcctoSettingsQueryData
    }
  }
  ${OcctoDayAheadPlanRecordFragmentDoc}
  ${OcctoSettingsQueryDataFragmentDoc}
`

/**
 * __useOcctoDayAheadPlanQuery__
 *
 * To run a query within a React component, call `useOcctoDayAheadPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useOcctoDayAheadPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOcctoDayAheadPlanQuery({
 *   variables: {
 *      input: // value for 'input'
 *      generationPlanInput: // value for 'generationPlanInput'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useOcctoDayAheadPlanQuery(
  baseOptions: Apollo.QueryHookOptions<OcctoDayAheadPlanQuery, OcctoDayAheadPlanQueryVariables> &
    ({ variables: OcctoDayAheadPlanQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OcctoDayAheadPlanQuery, OcctoDayAheadPlanQueryVariables>(
    OcctoDayAheadPlanDocument,
    options
  )
}
export function useOcctoDayAheadPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OcctoDayAheadPlanQuery, OcctoDayAheadPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OcctoDayAheadPlanQuery, OcctoDayAheadPlanQueryVariables>(
    OcctoDayAheadPlanDocument,
    options
  )
}
export function useOcctoDayAheadPlanSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OcctoDayAheadPlanQuery,
    OcctoDayAheadPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OcctoDayAheadPlanQuery, OcctoDayAheadPlanQueryVariables>(
    OcctoDayAheadPlanDocument,
    options
  )
}
export type OcctoDayAheadPlanQueryHookResult = ReturnType<typeof useOcctoDayAheadPlanQuery>
export type OcctoDayAheadPlanLazyQueryHookResult = ReturnType<typeof useOcctoDayAheadPlanLazyQuery>
export type OcctoDayAheadPlanSuspenseQueryHookResult = ReturnType<
  typeof useOcctoDayAheadPlanSuspenseQuery
>
export type OcctoDayAheadPlanQueryResult = ApolloReactCommon.QueryResult<
  OcctoDayAheadPlanQuery,
  OcctoDayAheadPlanQueryVariables
>
export const OcctoWorkspaceSettingsDocument = gql`
  query OcctoWorkspaceSettings($workspaceId: ID!) {
    workspace(input: $workspaceId) {
      ...OcctoSettingsQueryData
    }
  }
  ${OcctoSettingsQueryDataFragmentDoc}
`

/**
 * __useOcctoWorkspaceSettingsQuery__
 *
 * To run a query within a React component, call `useOcctoWorkspaceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOcctoWorkspaceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOcctoWorkspaceSettingsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useOcctoWorkspaceSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OcctoWorkspaceSettingsQuery,
    OcctoWorkspaceSettingsQueryVariables
  > &
    ({ variables: OcctoWorkspaceSettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OcctoWorkspaceSettingsQuery, OcctoWorkspaceSettingsQueryVariables>(
    OcctoWorkspaceSettingsDocument,
    options
  )
}
export function useOcctoWorkspaceSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OcctoWorkspaceSettingsQuery,
    OcctoWorkspaceSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OcctoWorkspaceSettingsQuery, OcctoWorkspaceSettingsQueryVariables>(
    OcctoWorkspaceSettingsDocument,
    options
  )
}
export function useOcctoWorkspaceSettingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OcctoWorkspaceSettingsQuery,
    OcctoWorkspaceSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OcctoWorkspaceSettingsQuery, OcctoWorkspaceSettingsQueryVariables>(
    OcctoWorkspaceSettingsDocument,
    options
  )
}
export type OcctoWorkspaceSettingsQueryHookResult = ReturnType<
  typeof useOcctoWorkspaceSettingsQuery
>
export type OcctoWorkspaceSettingsLazyQueryHookResult = ReturnType<
  typeof useOcctoWorkspaceSettingsLazyQuery
>
export type OcctoWorkspaceSettingsSuspenseQueryHookResult = ReturnType<
  typeof useOcctoWorkspaceSettingsSuspenseQuery
>
export type OcctoWorkspaceSettingsQueryResult = ApolloReactCommon.QueryResult<
  OcctoWorkspaceSettingsQuery,
  OcctoWorkspaceSettingsQueryVariables
>
export const OcctoLongTermWeeklyPlanDocument = gql`
  query OcctoLongTermWeeklyPlan(
    $workspaceId: ID!
    $startDate: Time!
    $gridZone: GridZone!
    $updateDate: Time
  ) {
    balancingGroups(
      workspaceId: $workspaceId
      filter: { isOcctoCompliant: true, gridZone: $gridZone }
    ) {
      id
      name
      name
      id
      forecasts {
        occtoWeeklyGenerationPlan(input: { date: $startDate, updateDate: $updateDate }) {
          plan {
            high
            low
            datetime
          }
          totalAssets
        }
      }
    }
  }
`

/**
 * __useOcctoLongTermWeeklyPlanQuery__
 *
 * To run a query within a React component, call `useOcctoLongTermWeeklyPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useOcctoLongTermWeeklyPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOcctoLongTermWeeklyPlanQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      startDate: // value for 'startDate'
 *      gridZone: // value for 'gridZone'
 *      updateDate: // value for 'updateDate'
 *   },
 * });
 */
export function useOcctoLongTermWeeklyPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    OcctoLongTermWeeklyPlanQuery,
    OcctoLongTermWeeklyPlanQueryVariables
  > &
    ({ variables: OcctoLongTermWeeklyPlanQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OcctoLongTermWeeklyPlanQuery, OcctoLongTermWeeklyPlanQueryVariables>(
    OcctoLongTermWeeklyPlanDocument,
    options
  )
}
export function useOcctoLongTermWeeklyPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OcctoLongTermWeeklyPlanQuery,
    OcctoLongTermWeeklyPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OcctoLongTermWeeklyPlanQuery, OcctoLongTermWeeklyPlanQueryVariables>(
    OcctoLongTermWeeklyPlanDocument,
    options
  )
}
export function useOcctoLongTermWeeklyPlanSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OcctoLongTermWeeklyPlanQuery,
    OcctoLongTermWeeklyPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    OcctoLongTermWeeklyPlanQuery,
    OcctoLongTermWeeklyPlanQueryVariables
  >(OcctoLongTermWeeklyPlanDocument, options)
}
export type OcctoLongTermWeeklyPlanQueryHookResult = ReturnType<
  typeof useOcctoLongTermWeeklyPlanQuery
>
export type OcctoLongTermWeeklyPlanLazyQueryHookResult = ReturnType<
  typeof useOcctoLongTermWeeklyPlanLazyQuery
>
export type OcctoLongTermWeeklyPlanSuspenseQueryHookResult = ReturnType<
  typeof useOcctoLongTermWeeklyPlanSuspenseQuery
>
export type OcctoLongTermWeeklyPlanQueryResult = ApolloReactCommon.QueryResult<
  OcctoLongTermWeeklyPlanQuery,
  OcctoLongTermWeeklyPlanQueryVariables
>
export const OcctoLongTermMonthlyPlanDocument = gql`
  query OcctoLongTermMonthlyPlan($workspaceId: ID!, $startDate: Time!, $gridZone: GridZone!) {
    balancingGroups(
      workspaceId: $workspaceId
      filter: { isOcctoCompliant: true, gridZone: $gridZone }
    ) {
      id
      name
      name
      id
      forecasts {
        occtoMonthlyGenerationPlan(input: { date: $startDate }) {
          plan {
            high
            low
            timeSlot {
              isWeekend
              weekNumberInMonth
              datetime
            }
          }
          totalAssets
        }
      }
    }
  }
`

/**
 * __useOcctoLongTermMonthlyPlanQuery__
 *
 * To run a query within a React component, call `useOcctoLongTermMonthlyPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useOcctoLongTermMonthlyPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOcctoLongTermMonthlyPlanQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      startDate: // value for 'startDate'
 *      gridZone: // value for 'gridZone'
 *   },
 * });
 */
export function useOcctoLongTermMonthlyPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    OcctoLongTermMonthlyPlanQuery,
    OcctoLongTermMonthlyPlanQueryVariables
  > &
    ({ variables: OcctoLongTermMonthlyPlanQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OcctoLongTermMonthlyPlanQuery, OcctoLongTermMonthlyPlanQueryVariables>(
    OcctoLongTermMonthlyPlanDocument,
    options
  )
}
export function useOcctoLongTermMonthlyPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OcctoLongTermMonthlyPlanQuery,
    OcctoLongTermMonthlyPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OcctoLongTermMonthlyPlanQuery, OcctoLongTermMonthlyPlanQueryVariables>(
    OcctoLongTermMonthlyPlanDocument,
    options
  )
}
export function useOcctoLongTermMonthlyPlanSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OcctoLongTermMonthlyPlanQuery,
    OcctoLongTermMonthlyPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    OcctoLongTermMonthlyPlanQuery,
    OcctoLongTermMonthlyPlanQueryVariables
  >(OcctoLongTermMonthlyPlanDocument, options)
}
export type OcctoLongTermMonthlyPlanQueryHookResult = ReturnType<
  typeof useOcctoLongTermMonthlyPlanQuery
>
export type OcctoLongTermMonthlyPlanLazyQueryHookResult = ReturnType<
  typeof useOcctoLongTermMonthlyPlanLazyQuery
>
export type OcctoLongTermMonthlyPlanSuspenseQueryHookResult = ReturnType<
  typeof useOcctoLongTermMonthlyPlanSuspenseQuery
>
export type OcctoLongTermMonthlyPlanQueryResult = ApolloReactCommon.QueryResult<
  OcctoLongTermMonthlyPlanQuery,
  OcctoLongTermMonthlyPlanQueryVariables
>
export const OcctoLongTermYearlyPlanDocument = gql`
  query OcctoLongTermYearlyPlan($workspaceId: ID!, $startDate: Time!, $gridZone: GridZone!) {
    balancingGroups(
      workspaceId: $workspaceId
      filter: { isOcctoCompliant: true, gridZone: $gridZone }
    ) {
      id
      name
      name
      id
      forecasts {
        occtoYearlyGenerationPlan(input: { date: $startDate }) {
          plan {
            set {
              t
              v
            }
          }
          totalAssets
        }
      }
    }
  }
`

/**
 * __useOcctoLongTermYearlyPlanQuery__
 *
 * To run a query within a React component, call `useOcctoLongTermYearlyPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useOcctoLongTermYearlyPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOcctoLongTermYearlyPlanQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      startDate: // value for 'startDate'
 *      gridZone: // value for 'gridZone'
 *   },
 * });
 */
export function useOcctoLongTermYearlyPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    OcctoLongTermYearlyPlanQuery,
    OcctoLongTermYearlyPlanQueryVariables
  > &
    ({ variables: OcctoLongTermYearlyPlanQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OcctoLongTermYearlyPlanQuery, OcctoLongTermYearlyPlanQueryVariables>(
    OcctoLongTermYearlyPlanDocument,
    options
  )
}
export function useOcctoLongTermYearlyPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OcctoLongTermYearlyPlanQuery,
    OcctoLongTermYearlyPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OcctoLongTermYearlyPlanQuery, OcctoLongTermYearlyPlanQueryVariables>(
    OcctoLongTermYearlyPlanDocument,
    options
  )
}
export function useOcctoLongTermYearlyPlanSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OcctoLongTermYearlyPlanQuery,
    OcctoLongTermYearlyPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    OcctoLongTermYearlyPlanQuery,
    OcctoLongTermYearlyPlanQueryVariables
  >(OcctoLongTermYearlyPlanDocument, options)
}
export type OcctoLongTermYearlyPlanQueryHookResult = ReturnType<
  typeof useOcctoLongTermYearlyPlanQuery
>
export type OcctoLongTermYearlyPlanLazyQueryHookResult = ReturnType<
  typeof useOcctoLongTermYearlyPlanLazyQuery
>
export type OcctoLongTermYearlyPlanSuspenseQueryHookResult = ReturnType<
  typeof useOcctoLongTermYearlyPlanSuspenseQuery
>
export type OcctoLongTermYearlyPlanQueryResult = ApolloReactCommon.QueryResult<
  OcctoLongTermYearlyPlanQuery,
  OcctoLongTermYearlyPlanQueryVariables
>
export const SolarAssetCustomOptionsDocument = gql`
  query SolarAssetCustomOptions($organizationId: ID!) {
    developerList: thirdPartyCompanyList(
      input: { workspaceId: $organizationId, page: 1, perPage: 1000 }
      filter: { type: DEVELOPER }
    ) {
      ...ThirdPartyListQuery
    }
    epcCompanyList: thirdPartyCompanyList(
      input: { workspaceId: $organizationId, page: 1, perPage: 1000 }
      filter: { type: EPC_COMPANY }
    ) {
      ...ThirdPartyListQuery
    }
    omProviderList: thirdPartyCompanyList(
      input: { workspaceId: $organizationId, page: 1, perPage: 1000 }
      filter: { type: OM_PROVIDER }
    ) {
      ...ThirdPartyListQuery
    }
    scenarioList(input: { organizationId: $organizationId, page: 1, perPage: 1000 }) {
      records {
        ...ScenarioCoreData
      }
    }
    ppaList(input: { organizationId: $organizationId, page: 1, perPage: 1000 }) {
      records {
        id
        name
      }
    }
  }
  ${ThirdPartyListQueryFragmentDoc}
  ${ScenarioCoreDataFragmentDoc}
`

/**
 * __useSolarAssetCustomOptionsQuery__
 *
 * To run a query within a React component, call `useSolarAssetCustomOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetCustomOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetCustomOptionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSolarAssetCustomOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SolarAssetCustomOptionsQuery,
    SolarAssetCustomOptionsQueryVariables
  > &
    ({ variables: SolarAssetCustomOptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SolarAssetCustomOptionsQuery, SolarAssetCustomOptionsQueryVariables>(
    SolarAssetCustomOptionsDocument,
    options
  )
}
export function useSolarAssetCustomOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SolarAssetCustomOptionsQuery,
    SolarAssetCustomOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SolarAssetCustomOptionsQuery, SolarAssetCustomOptionsQueryVariables>(
    SolarAssetCustomOptionsDocument,
    options
  )
}
export function useSolarAssetCustomOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SolarAssetCustomOptionsQuery,
    SolarAssetCustomOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SolarAssetCustomOptionsQuery,
    SolarAssetCustomOptionsQueryVariables
  >(SolarAssetCustomOptionsDocument, options)
}
export type SolarAssetCustomOptionsQueryHookResult = ReturnType<
  typeof useSolarAssetCustomOptionsQuery
>
export type SolarAssetCustomOptionsLazyQueryHookResult = ReturnType<
  typeof useSolarAssetCustomOptionsLazyQuery
>
export type SolarAssetCustomOptionsSuspenseQueryHookResult = ReturnType<
  typeof useSolarAssetCustomOptionsSuspenseQuery
>
export type SolarAssetCustomOptionsQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetCustomOptionsQuery,
  SolarAssetCustomOptionsQueryVariables
>
export const SetupUserProfileDocument = gql`
  mutation SetupUserProfile($input: UpdateUserAccountInput!) {
    userAccount {
      update(input: $input) {
        ...UserAccountData
      }
    }
  }
  ${UserAccountDataFragmentDoc}
`
export type SetupUserProfileMutationFn = ApolloReactCommon.MutationFunction<
  SetupUserProfileMutation,
  SetupUserProfileMutationVariables
>

/**
 * __useSetupUserProfileMutation__
 *
 * To run a mutation, you first call `useSetupUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupUserProfileMutation, { data, loading, error }] = useSetupUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupUserProfileMutation,
    SetupUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetupUserProfileMutation, SetupUserProfileMutationVariables>(
    SetupUserProfileDocument,
    options
  )
}
export type SetupUserProfileMutationHookResult = ReturnType<typeof useSetupUserProfileMutation>
export type SetupUserProfileMutationResult =
  ApolloReactCommon.MutationResult<SetupUserProfileMutation>
export type SetupUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetupUserProfileMutation,
  SetupUserProfileMutationVariables
>
export const CompleteUserProfileDocument = gql`
  mutation CompleteUserProfile($firstName: String!, $lastName: String!, $id: ID!) {
    userAccount {
      update(input: { data: { firstName: $firstName, lastName: $lastName }, id: $id }) {
        ...UserAccountData
        success
        errors {
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on AuthorizationProblem {
            message
          }
        }
      }
    }
  }
  ${UserAccountDataFragmentDoc}
`
export type CompleteUserProfileMutationFn = ApolloReactCommon.MutationFunction<
  CompleteUserProfileMutation,
  CompleteUserProfileMutationVariables
>

/**
 * __useCompleteUserProfileMutation__
 *
 * To run a mutation, you first call `useCompleteUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUserProfileMutation, { data, loading, error }] = useCompleteUserProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteUserProfileMutation,
    CompleteUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompleteUserProfileMutation, CompleteUserProfileMutationVariables>(
    CompleteUserProfileDocument,
    options
  )
}
export type CompleteUserProfileMutationHookResult = ReturnType<
  typeof useCompleteUserProfileMutation
>
export type CompleteUserProfileMutationResult =
  ApolloReactCommon.MutationResult<CompleteUserProfileMutation>
export type CompleteUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CompleteUserProfileMutation,
  CompleteUserProfileMutationVariables
>
export const ForecastsDocument = gql`
  query Forecasts(
    $input: ElectricityGenerationForecastsInput!
    $filter: ElectricityGenerationForecastsFilter!
    $workspaceId: ID!
  ) {
    forecasts(input: $input, filter: $filter) {
      records {
        balancingGroup {
          id
          name
        }
        createdAt
        id
      }
      updatedAt
    }
    balancingGroupList(input: { workspaceId: $workspaceId, perPage: 1000, page: 1 }) {
      records {
        id
        name
        balancingGroupCode
      }
    }
    solarAssetList(input: { organizationId: $workspaceId, perPage: 1000, page: 1 }) {
      records {
        id
        name
      }
    }
  }
`

/**
 * __useForecastsQuery__
 *
 * To run a query within a React component, call `useForecastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useForecastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForecastsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useForecastsQuery(
  baseOptions: Apollo.QueryHookOptions<ForecastsQuery, ForecastsQueryVariables> &
    ({ variables: ForecastsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ForecastsQuery, ForecastsQueryVariables>(ForecastsDocument, options)
}
export function useForecastsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ForecastsQuery, ForecastsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ForecastsQuery, ForecastsQueryVariables>(ForecastsDocument, options)
}
export function useForecastsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ForecastsQuery, ForecastsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ForecastsQuery, ForecastsQueryVariables>(
    ForecastsDocument,
    options
  )
}
export type ForecastsQueryHookResult = ReturnType<typeof useForecastsQuery>
export type ForecastsLazyQueryHookResult = ReturnType<typeof useForecastsLazyQuery>
export type ForecastsSuspenseQueryHookResult = ReturnType<typeof useForecastsSuspenseQuery>
export type ForecastsQueryResult = ApolloReactCommon.QueryResult<
  ForecastsQuery,
  ForecastsQueryVariables
>
export const CustomForecastsDocument = gql`
  query CustomForecasts($input: CustomElectricityGenerationForecastInput!) {
    customForecast(input: $input) {
      assets {
        id
        name
      }
      rows {
        datetime
        total
        values {
          assetId
          value
        }
      }
    }
  }
`

/**
 * __useCustomForecastsQuery__
 *
 * To run a query within a React component, call `useCustomForecastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomForecastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomForecastsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomForecastsQuery(
  baseOptions: Apollo.QueryHookOptions<CustomForecastsQuery, CustomForecastsQueryVariables> &
    ({ variables: CustomForecastsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomForecastsQuery, CustomForecastsQueryVariables>(
    CustomForecastsDocument,
    options
  )
}
export function useCustomForecastsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomForecastsQuery, CustomForecastsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomForecastsQuery, CustomForecastsQueryVariables>(
    CustomForecastsDocument,
    options
  )
}
export function useCustomForecastsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CustomForecastsQuery, CustomForecastsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CustomForecastsQuery, CustomForecastsQueryVariables>(
    CustomForecastsDocument,
    options
  )
}
export type CustomForecastsQueryHookResult = ReturnType<typeof useCustomForecastsQuery>
export type CustomForecastsLazyQueryHookResult = ReturnType<typeof useCustomForecastsLazyQuery>
export type CustomForecastsSuspenseQueryHookResult = ReturnType<
  typeof useCustomForecastsSuspenseQuery
>
export type CustomForecastsQueryResult = ApolloReactCommon.QueryResult<
  CustomForecastsQuery,
  CustomForecastsQueryVariables
>
export const SendInvitationsDocument = gql`
  mutation SendInvitations($input: sendInvitationsInput!) {
    sendInvitationsV2(input: $input) {
      items {
        succeed
        email
        error
        errorCode
      }
    }
  }
`
export type SendInvitationsMutationFn = ApolloReactCommon.MutationFunction<
  SendInvitationsMutation,
  SendInvitationsMutationVariables
>

/**
 * __useSendInvitationsMutation__
 *
 * To run a mutation, you first call `useSendInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationsMutation, { data, loading, error }] = useSendInvitationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInvitationsMutation,
    SendInvitationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendInvitationsMutation, SendInvitationsMutationVariables>(
    SendInvitationsDocument,
    options
  )
}
export type SendInvitationsMutationHookResult = ReturnType<typeof useSendInvitationsMutation>
export type SendInvitationsMutationResult =
  ApolloReactCommon.MutationResult<SendInvitationsMutation>
export type SendInvitationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendInvitationsMutation,
  SendInvitationsMutationVariables
>
export const NotificationMarkAsReadDocument = gql`
  mutation NotificationMarkAsRead($id: ID!, $workspaceId: ID!) {
    notification {
      markAsRead(input: { id: $id, workspaceId: $workspaceId }) {
        data {
          record {
            ...NotificationData
          }
          recordId
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        query {
          notifications(input: { workspaceId: $workspaceId }) {
            unreadCount
          }
        }
      }
    }
  }
  ${NotificationDataFragmentDoc}
`
export type NotificationMarkAsReadMutationFn = ApolloReactCommon.MutationFunction<
  NotificationMarkAsReadMutation,
  NotificationMarkAsReadMutationVariables
>

/**
 * __useNotificationMarkAsReadMutation__
 *
 * To run a mutation, you first call `useNotificationMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationMarkAsReadMutation, { data, loading, error }] = useNotificationMarkAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useNotificationMarkAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationMarkAsReadMutation,
    NotificationMarkAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    NotificationMarkAsReadMutation,
    NotificationMarkAsReadMutationVariables
  >(NotificationMarkAsReadDocument, options)
}
export type NotificationMarkAsReadMutationHookResult = ReturnType<
  typeof useNotificationMarkAsReadMutation
>
export type NotificationMarkAsReadMutationResult =
  ApolloReactCommon.MutationResult<NotificationMarkAsReadMutation>
export type NotificationMarkAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  NotificationMarkAsReadMutation,
  NotificationMarkAsReadMutationVariables
>
export const NotificationMarkAsUnreadDocument = gql`
  mutation NotificationMarkAsUnread($id: ID!, $workspaceId: ID!) {
    notification {
      markAsUnread(input: { id: $id, workspaceId: $workspaceId }) {
        data {
          record {
            ...NotificationData
          }
          recordId
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        query {
          notifications(input: { workspaceId: $workspaceId }) {
            unreadCount
          }
        }
      }
    }
  }
  ${NotificationDataFragmentDoc}
`
export type NotificationMarkAsUnreadMutationFn = ApolloReactCommon.MutationFunction<
  NotificationMarkAsUnreadMutation,
  NotificationMarkAsUnreadMutationVariables
>

/**
 * __useNotificationMarkAsUnreadMutation__
 *
 * To run a mutation, you first call `useNotificationMarkAsUnreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationMarkAsUnreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationMarkAsUnreadMutation, { data, loading, error }] = useNotificationMarkAsUnreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useNotificationMarkAsUnreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationMarkAsUnreadMutation,
    NotificationMarkAsUnreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    NotificationMarkAsUnreadMutation,
    NotificationMarkAsUnreadMutationVariables
  >(NotificationMarkAsUnreadDocument, options)
}
export type NotificationMarkAsUnreadMutationHookResult = ReturnType<
  typeof useNotificationMarkAsUnreadMutation
>
export type NotificationMarkAsUnreadMutationResult =
  ApolloReactCommon.MutationResult<NotificationMarkAsUnreadMutation>
export type NotificationMarkAsUnreadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  NotificationMarkAsUnreadMutation,
  NotificationMarkAsUnreadMutationVariables
>
export const NotificationMarkAllAsReadDocument = gql`
  mutation NotificationMarkAllAsRead(
    $input: MarkAllNotificationsAsReadInput!
    $queryInput: NotificationListInput!
    $queryFilter: NotificationListFilter
  ) {
    notification {
      markAllAsRead(input: $input) {
        errors {
          ... on AuthorizationProblem {
            message
          }
        }
        success
        query {
          notifications(input: $queryInput, filter: $queryFilter) {
            records {
              ...NotificationData
            }
          }
          notificationsCount: notifications(input: $queryInput) {
            unreadCount
          }
        }
      }
    }
  }
  ${NotificationDataFragmentDoc}
`
export type NotificationMarkAllAsReadMutationFn = ApolloReactCommon.MutationFunction<
  NotificationMarkAllAsReadMutation,
  NotificationMarkAllAsReadMutationVariables
>

/**
 * __useNotificationMarkAllAsReadMutation__
 *
 * To run a mutation, you first call `useNotificationMarkAllAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationMarkAllAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationMarkAllAsReadMutation, { data, loading, error }] = useNotificationMarkAllAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *      queryFilter: // value for 'queryFilter'
 *   },
 * });
 */
export function useNotificationMarkAllAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationMarkAllAsReadMutation,
    NotificationMarkAllAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    NotificationMarkAllAsReadMutation,
    NotificationMarkAllAsReadMutationVariables
  >(NotificationMarkAllAsReadDocument, options)
}
export type NotificationMarkAllAsReadMutationHookResult = ReturnType<
  typeof useNotificationMarkAllAsReadMutation
>
export type NotificationMarkAllAsReadMutationResult =
  ApolloReactCommon.MutationResult<NotificationMarkAllAsReadMutation>
export type NotificationMarkAllAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  NotificationMarkAllAsReadMutation,
  NotificationMarkAllAsReadMutationVariables
>
export const NotificationsDocument = gql`
  query Notifications($input: NotificationListInput!, $filter: NotificationListFilter) {
    notifications(input: $input, filter: $filter) {
      records {
        ...NotificationData
      }
    }
    notificationsCount: notifications(input: $input) {
      unreadCount
    }
  }
  ${NotificationDataFragmentDoc}
`

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables> &
    ({ variables: NotificationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  )
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  )
}
export function useNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  )
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>
export const NotificationsUnreadCountDocument = gql`
  query NotificationsUnreadCount($input: NotificationListInput!) {
    notifications(input: $input) {
      unreadCount
    }
  }
`

/**
 * __useNotificationsUnreadCountQuery__
 *
 * To run a query within a React component, call `useNotificationsUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsUnreadCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationsUnreadCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotificationsUnreadCountQuery,
    NotificationsUnreadCountQueryVariables
  > &
    ({ variables: NotificationsUnreadCountQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationsUnreadCountQuery, NotificationsUnreadCountQueryVariables>(
    NotificationsUnreadCountDocument,
    options
  )
}
export function useNotificationsUnreadCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsUnreadCountQuery,
    NotificationsUnreadCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationsUnreadCountQuery, NotificationsUnreadCountQueryVariables>(
    NotificationsUnreadCountDocument,
    options
  )
}
export function useNotificationsUnreadCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NotificationsUnreadCountQuery,
    NotificationsUnreadCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    NotificationsUnreadCountQuery,
    NotificationsUnreadCountQueryVariables
  >(NotificationsUnreadCountDocument, options)
}
export type NotificationsUnreadCountQueryHookResult = ReturnType<
  typeof useNotificationsUnreadCountQuery
>
export type NotificationsUnreadCountLazyQueryHookResult = ReturnType<
  typeof useNotificationsUnreadCountLazyQuery
>
export type NotificationsUnreadCountSuspenseQueryHookResult = ReturnType<
  typeof useNotificationsUnreadCountSuspenseQuery
>
export type NotificationsUnreadCountQueryResult = ApolloReactCommon.QueryResult<
  NotificationsUnreadCountQuery,
  NotificationsUnreadCountQueryVariables
>
export const GenerateUploadGenerationPresignedUrlsDocument = gql`
  mutation GenerateUploadGenerationPresignedUrls(
    $input: GenerateSolarAssetUploadActualsUrlsInput!
  ) {
    solarAssets {
      generateUploadActualsUrls(input: $input) {
        data {
          key
          url
          headers
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
        }
        success
      }
    }
  }
`
export type GenerateUploadGenerationPresignedUrlsMutationFn = ApolloReactCommon.MutationFunction<
  GenerateUploadGenerationPresignedUrlsMutation,
  GenerateUploadGenerationPresignedUrlsMutationVariables
>

/**
 * __useGenerateUploadGenerationPresignedUrlsMutation__
 *
 * To run a mutation, you first call `useGenerateUploadGenerationPresignedUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUploadGenerationPresignedUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUploadGenerationPresignedUrlsMutation, { data, loading, error }] = useGenerateUploadGenerationPresignedUrlsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateUploadGenerationPresignedUrlsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateUploadGenerationPresignedUrlsMutation,
    GenerateUploadGenerationPresignedUrlsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GenerateUploadGenerationPresignedUrlsMutation,
    GenerateUploadGenerationPresignedUrlsMutationVariables
  >(GenerateUploadGenerationPresignedUrlsDocument, options)
}
export type GenerateUploadGenerationPresignedUrlsMutationHookResult = ReturnType<
  typeof useGenerateUploadGenerationPresignedUrlsMutation
>
export type GenerateUploadGenerationPresignedUrlsMutationResult =
  ApolloReactCommon.MutationResult<GenerateUploadGenerationPresignedUrlsMutation>
export type GenerateUploadGenerationPresignedUrlsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    GenerateUploadGenerationPresignedUrlsMutation,
    GenerateUploadGenerationPresignedUrlsMutationVariables
  >
export const RenameBalancingGroupDocument = gql`
  mutation RenameBalancingGroup($input: RenameBalancingGroupInput!) {
    balancingGroup {
      rename(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            id
            name
          }
        }
      }
    }
  }
`
export type RenameBalancingGroupMutationFn = ApolloReactCommon.MutationFunction<
  RenameBalancingGroupMutation,
  RenameBalancingGroupMutationVariables
>

/**
 * __useRenameBalancingGroupMutation__
 *
 * To run a mutation, you first call `useRenameBalancingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameBalancingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameBalancingGroupMutation, { data, loading, error }] = useRenameBalancingGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameBalancingGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameBalancingGroupMutation,
    RenameBalancingGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RenameBalancingGroupMutation, RenameBalancingGroupMutationVariables>(
    RenameBalancingGroupDocument,
    options
  )
}
export type RenameBalancingGroupMutationHookResult = ReturnType<
  typeof useRenameBalancingGroupMutation
>
export type RenameBalancingGroupMutationResult =
  ApolloReactCommon.MutationResult<RenameBalancingGroupMutation>
export type RenameBalancingGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenameBalancingGroupMutation,
  RenameBalancingGroupMutationVariables
>
export const CreateBalancingGroupDocument = gql`
  mutation CreateBalancingGroup($input: CreateBalancingGroupInput!) {
    balancingGroup {
      create(input: $input) {
        data {
          recordId
          record {
            id
            name
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on BalancingGroupAlreadyExistsProblem {
            message
            type
          }
          ... on MissingDependentsProblem {
            message
            recordId
          }
          ... on ResourceIsAlreadyInUseProblem {
            message
            recordId
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
      }
    }
  }
`
export type CreateBalancingGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateBalancingGroupMutation,
  CreateBalancingGroupMutationVariables
>

/**
 * __useCreateBalancingGroupMutation__
 *
 * To run a mutation, you first call `useCreateBalancingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBalancingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBalancingGroupMutation, { data, loading, error }] = useCreateBalancingGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBalancingGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBalancingGroupMutation,
    CreateBalancingGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBalancingGroupMutation, CreateBalancingGroupMutationVariables>(
    CreateBalancingGroupDocument,
    options
  )
}
export type CreateBalancingGroupMutationHookResult = ReturnType<
  typeof useCreateBalancingGroupMutation
>
export type CreateBalancingGroupMutationResult =
  ApolloReactCommon.MutationResult<CreateBalancingGroupMutation>
export type CreateBalancingGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBalancingGroupMutation,
  CreateBalancingGroupMutationVariables
>
export const UpdateBalancingGroupDocument = gql`
  mutation UpdateBalancingGroup($input: UpdateBalancingGroupInput!) {
    balancingGroup {
      update(input: $input) {
        data {
          recordId
          record {
            ...BalancingGroupOverviewRecord
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on BalancingGroupAlreadyExistsProblem {
            message
            type
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on MissingDependentsProblem {
            message
            recordId
          }
        }
        success
      }
    }
  }
  ${BalancingGroupOverviewRecordFragmentDoc}
`
export type UpdateBalancingGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBalancingGroupMutation,
  UpdateBalancingGroupMutationVariables
>

/**
 * __useUpdateBalancingGroupMutation__
 *
 * To run a mutation, you first call `useUpdateBalancingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBalancingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBalancingGroupMutation, { data, loading, error }] = useUpdateBalancingGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBalancingGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBalancingGroupMutation,
    UpdateBalancingGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateBalancingGroupMutation, UpdateBalancingGroupMutationVariables>(
    UpdateBalancingGroupDocument,
    options
  )
}
export type UpdateBalancingGroupMutationHookResult = ReturnType<
  typeof useUpdateBalancingGroupMutation
>
export type UpdateBalancingGroupMutationResult =
  ApolloReactCommon.MutationResult<UpdateBalancingGroupMutation>
export type UpdateBalancingGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBalancingGroupMutation,
  UpdateBalancingGroupMutationVariables
>
export const DeleteBalancingGroupDocument = gql`
  mutation DeleteBalancingGroup(
    $input: DeleteBalancingGroupInput!
    $queryInput: BalancingGroupListInput!
  ) {
    balancingGroup {
      delete(input: $input) {
        data {
          recordId
          record {
            id
            name
          }
        }
        errors {
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on AuthorizationProblem {
            message
          }
        }
        success
        query {
          balancingGroupList(input: $queryInput) {
            pageInfo {
              ...PaginationMetadata
            }
            records {
              ...BalancingGroupListItem
            }
          }
        }
      }
    }
  }
  ${PaginationMetadataFragmentDoc}
  ${BalancingGroupListItemFragmentDoc}
`
export type DeleteBalancingGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteBalancingGroupMutation,
  DeleteBalancingGroupMutationVariables
>

/**
 * __useDeleteBalancingGroupMutation__
 *
 * To run a mutation, you first call `useDeleteBalancingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBalancingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBalancingGroupMutation, { data, loading, error }] = useDeleteBalancingGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteBalancingGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBalancingGroupMutation,
    DeleteBalancingGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteBalancingGroupMutation, DeleteBalancingGroupMutationVariables>(
    DeleteBalancingGroupDocument,
    options
  )
}
export type DeleteBalancingGroupMutationHookResult = ReturnType<
  typeof useDeleteBalancingGroupMutation
>
export type DeleteBalancingGroupMutationResult =
  ApolloReactCommon.MutationResult<DeleteBalancingGroupMutation>
export type DeleteBalancingGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteBalancingGroupMutation,
  DeleteBalancingGroupMutationVariables
>
export const AddAssetsToBalancingGroupDocument = gql`
  mutation AddAssetsToBalancingGroup(
    $addAssetsInput: AddAssetsToBalancingGroupInput!
    $balancingGroupQueryInput: QueryEntityInput!
    $solarAssetQueryInput: SolarAssetListInput!
    $solarAssetQueryFilter: SolarAssetFilter
  ) {
    balancingGroup {
      addAssets(input: $addAssetsInput) {
        data {
          recordId
          record {
            name
            assets {
              id
            }
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on ResourceIsAlreadyInUseProblem {
            message
            recordId
          }
        }
        success
        query {
          balancingGroup(input: $balancingGroupQueryInput) {
            ...BalancingGroupOverviewRecord
          }
          solarAssetList(input: $solarAssetQueryInput, filter: $solarAssetQueryFilter) {
            records {
              ...BalancingGroupAssetData
            }
          }
        }
      }
    }
  }
  ${BalancingGroupOverviewRecordFragmentDoc}
  ${BalancingGroupAssetDataFragmentDoc}
`
export type AddAssetsToBalancingGroupMutationFn = ApolloReactCommon.MutationFunction<
  AddAssetsToBalancingGroupMutation,
  AddAssetsToBalancingGroupMutationVariables
>

/**
 * __useAddAssetsToBalancingGroupMutation__
 *
 * To run a mutation, you first call `useAddAssetsToBalancingGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetsToBalancingGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetsToBalancingGroupMutation, { data, loading, error }] = useAddAssetsToBalancingGroupMutation({
 *   variables: {
 *      addAssetsInput: // value for 'addAssetsInput'
 *      balancingGroupQueryInput: // value for 'balancingGroupQueryInput'
 *      solarAssetQueryInput: // value for 'solarAssetQueryInput'
 *      solarAssetQueryFilter: // value for 'solarAssetQueryFilter'
 *   },
 * });
 */
export function useAddAssetsToBalancingGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAssetsToBalancingGroupMutation,
    AddAssetsToBalancingGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddAssetsToBalancingGroupMutation,
    AddAssetsToBalancingGroupMutationVariables
  >(AddAssetsToBalancingGroupDocument, options)
}
export type AddAssetsToBalancingGroupMutationHookResult = ReturnType<
  typeof useAddAssetsToBalancingGroupMutation
>
export type AddAssetsToBalancingGroupMutationResult =
  ApolloReactCommon.MutationResult<AddAssetsToBalancingGroupMutation>
export type AddAssetsToBalancingGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddAssetsToBalancingGroupMutation,
  AddAssetsToBalancingGroupMutationVariables
>
export const RemoveAssetsFromBalancinGroupDocument = gql`
  mutation RemoveAssetsFromBalancinGroup(
    $input: RemoveAssetsFromBalancingGroupInput!
    $queryInput: QueryEntityInput!
  ) {
    balancingGroup {
      removeAssets(input: $input) {
        data {
          recordId
          record {
            id
            name
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        query {
          balancingGroup(input: $queryInput) {
            id
            totalAssets
            assets {
              ...BalancingGroupAssetData
            }
          }
        }
      }
    }
  }
  ${BalancingGroupAssetDataFragmentDoc}
`
export type RemoveAssetsFromBalancinGroupMutationFn = ApolloReactCommon.MutationFunction<
  RemoveAssetsFromBalancinGroupMutation,
  RemoveAssetsFromBalancinGroupMutationVariables
>

/**
 * __useRemoveAssetsFromBalancinGroupMutation__
 *
 * To run a mutation, you first call `useRemoveAssetsFromBalancinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssetsFromBalancinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssetsFromBalancinGroupMutation, { data, loading, error }] = useRemoveAssetsFromBalancinGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useRemoveAssetsFromBalancinGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAssetsFromBalancinGroupMutation,
    RemoveAssetsFromBalancinGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveAssetsFromBalancinGroupMutation,
    RemoveAssetsFromBalancinGroupMutationVariables
  >(RemoveAssetsFromBalancinGroupDocument, options)
}
export type RemoveAssetsFromBalancinGroupMutationHookResult = ReturnType<
  typeof useRemoveAssetsFromBalancinGroupMutation
>
export type RemoveAssetsFromBalancinGroupMutationResult =
  ApolloReactCommon.MutationResult<RemoveAssetsFromBalancinGroupMutation>
export type RemoveAssetsFromBalancinGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveAssetsFromBalancinGroupMutation,
  RemoveAssetsFromBalancinGroupMutationVariables
>
export const GenerationUploadsDocument = gql`
  query GenerationUploads($input: SolarAssetActualsUploadsInput!) {
    solarAssetActualsUploads(input: $input) {
      createdAt
      id
      name
      status {
        status
      }
    }
  }
`

/**
 * __useGenerationUploadsQuery__
 *
 * To run a query within a React component, call `useGenerationUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerationUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerationUploadsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerationUploadsQuery(
  baseOptions: Apollo.QueryHookOptions<GenerationUploadsQuery, GenerationUploadsQueryVariables> &
    ({ variables: GenerationUploadsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GenerationUploadsQuery, GenerationUploadsQueryVariables>(
    GenerationUploadsDocument,
    options
  )
}
export function useGenerationUploadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GenerationUploadsQuery, GenerationUploadsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GenerationUploadsQuery, GenerationUploadsQueryVariables>(
    GenerationUploadsDocument,
    options
  )
}
export function useGenerationUploadsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerationUploadsQuery,
    GenerationUploadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GenerationUploadsQuery, GenerationUploadsQueryVariables>(
    GenerationUploadsDocument,
    options
  )
}
export type GenerationUploadsQueryHookResult = ReturnType<typeof useGenerationUploadsQuery>
export type GenerationUploadsLazyQueryHookResult = ReturnType<typeof useGenerationUploadsLazyQuery>
export type GenerationUploadsSuspenseQueryHookResult = ReturnType<
  typeof useGenerationUploadsSuspenseQuery
>
export type GenerationUploadsQueryResult = ApolloReactCommon.QueryResult<
  GenerationUploadsQuery,
  GenerationUploadsQueryVariables
>
export const BalancingGroupsDocument = gql`
  query BalancingGroups($input: BalancingGroupListInput!) {
    balancingGroupList(input: $input) {
      pageInfo {
        ...PaginationMetadata
      }
      records {
        ...BalancingGroupListItem
      }
    }
  }
  ${PaginationMetadataFragmentDoc}
  ${BalancingGroupListItemFragmentDoc}
`

/**
 * __useBalancingGroupsQuery__
 *
 * To run a query within a React component, call `useBalancingGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancingGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancingGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBalancingGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<BalancingGroupsQuery, BalancingGroupsQueryVariables> &
    ({ variables: BalancingGroupsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BalancingGroupsQuery, BalancingGroupsQueryVariables>(
    BalancingGroupsDocument,
    options
  )
}
export function useBalancingGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BalancingGroupsQuery, BalancingGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BalancingGroupsQuery, BalancingGroupsQueryVariables>(
    BalancingGroupsDocument,
    options
  )
}
export function useBalancingGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BalancingGroupsQuery, BalancingGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BalancingGroupsQuery, BalancingGroupsQueryVariables>(
    BalancingGroupsDocument,
    options
  )
}
export type BalancingGroupsQueryHookResult = ReturnType<typeof useBalancingGroupsQuery>
export type BalancingGroupsLazyQueryHookResult = ReturnType<typeof useBalancingGroupsLazyQuery>
export type BalancingGroupsSuspenseQueryHookResult = ReturnType<
  typeof useBalancingGroupsSuspenseQuery
>
export type BalancingGroupsQueryResult = ApolloReactCommon.QueryResult<
  BalancingGroupsQuery,
  BalancingGroupsQueryVariables
>
export const BalancingGroupsNameAndCodeDocument = gql`
  query BalancingGroupsNameAndCode($workspaceId: ID!) {
    balancingGroupList(input: { workspaceId: $workspaceId, perPage: 1000 }) {
      records {
        id
        balancingGroupCode
        name
      }
    }
  }
`

/**
 * __useBalancingGroupsNameAndCodeQuery__
 *
 * To run a query within a React component, call `useBalancingGroupsNameAndCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancingGroupsNameAndCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancingGroupsNameAndCodeQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useBalancingGroupsNameAndCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalancingGroupsNameAndCodeQuery,
    BalancingGroupsNameAndCodeQueryVariables
  > &
    ({ variables: BalancingGroupsNameAndCodeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BalancingGroupsNameAndCodeQuery, BalancingGroupsNameAndCodeQueryVariables>(
    BalancingGroupsNameAndCodeDocument,
    options
  )
}
export function useBalancingGroupsNameAndCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalancingGroupsNameAndCodeQuery,
    BalancingGroupsNameAndCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BalancingGroupsNameAndCodeQuery,
    BalancingGroupsNameAndCodeQueryVariables
  >(BalancingGroupsNameAndCodeDocument, options)
}
export function useBalancingGroupsNameAndCodeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BalancingGroupsNameAndCodeQuery,
    BalancingGroupsNameAndCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BalancingGroupsNameAndCodeQuery,
    BalancingGroupsNameAndCodeQueryVariables
  >(BalancingGroupsNameAndCodeDocument, options)
}
export type BalancingGroupsNameAndCodeQueryHookResult = ReturnType<
  typeof useBalancingGroupsNameAndCodeQuery
>
export type BalancingGroupsNameAndCodeLazyQueryHookResult = ReturnType<
  typeof useBalancingGroupsNameAndCodeLazyQuery
>
export type BalancingGroupsNameAndCodeSuspenseQueryHookResult = ReturnType<
  typeof useBalancingGroupsNameAndCodeSuspenseQuery
>
export type BalancingGroupsNameAndCodeQueryResult = ApolloReactCommon.QueryResult<
  BalancingGroupsNameAndCodeQuery,
  BalancingGroupsNameAndCodeQueryVariables
>
export const BalancingGroupDocument = gql`
  query BalancingGroup($input: QueryEntityInput!) {
    balancingGroup(input: $input) {
      ...BalancingGroupOverviewRecord
    }
  }
  ${BalancingGroupOverviewRecordFragmentDoc}
`

/**
 * __useBalancingGroupQuery__
 *
 * To run a query within a React component, call `useBalancingGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancingGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancingGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBalancingGroupQuery(
  baseOptions: Apollo.QueryHookOptions<BalancingGroupQuery, BalancingGroupQueryVariables> &
    ({ variables: BalancingGroupQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BalancingGroupQuery, BalancingGroupQueryVariables>(
    BalancingGroupDocument,
    options
  )
}
export function useBalancingGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BalancingGroupQuery, BalancingGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BalancingGroupQuery, BalancingGroupQueryVariables>(
    BalancingGroupDocument,
    options
  )
}
export function useBalancingGroupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BalancingGroupQuery, BalancingGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BalancingGroupQuery, BalancingGroupQueryVariables>(
    BalancingGroupDocument,
    options
  )
}
export type BalancingGroupQueryHookResult = ReturnType<typeof useBalancingGroupQuery>
export type BalancingGroupLazyQueryHookResult = ReturnType<typeof useBalancingGroupLazyQuery>
export type BalancingGroupSuspenseQueryHookResult = ReturnType<
  typeof useBalancingGroupSuspenseQuery
>
export type BalancingGroupQueryResult = ApolloReactCommon.QueryResult<
  BalancingGroupQuery,
  BalancingGroupQueryVariables
>
export const BalancingGroupEditViewDataDocument = gql`
  query BalancingGroupEditViewData($input: QueryEntityInput!) {
    balancingGroup(input: $input) {
      ...BalancingGroupOverviewRecord
    }
  }
  ${BalancingGroupOverviewRecordFragmentDoc}
`

/**
 * __useBalancingGroupEditViewDataQuery__
 *
 * To run a query within a React component, call `useBalancingGroupEditViewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancingGroupEditViewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancingGroupEditViewDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBalancingGroupEditViewDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalancingGroupEditViewDataQuery,
    BalancingGroupEditViewDataQueryVariables
  > &
    ({ variables: BalancingGroupEditViewDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BalancingGroupEditViewDataQuery, BalancingGroupEditViewDataQueryVariables>(
    BalancingGroupEditViewDataDocument,
    options
  )
}
export function useBalancingGroupEditViewDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalancingGroupEditViewDataQuery,
    BalancingGroupEditViewDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BalancingGroupEditViewDataQuery,
    BalancingGroupEditViewDataQueryVariables
  >(BalancingGroupEditViewDataDocument, options)
}
export function useBalancingGroupEditViewDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BalancingGroupEditViewDataQuery,
    BalancingGroupEditViewDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BalancingGroupEditViewDataQuery,
    BalancingGroupEditViewDataQueryVariables
  >(BalancingGroupEditViewDataDocument, options)
}
export type BalancingGroupEditViewDataQueryHookResult = ReturnType<
  typeof useBalancingGroupEditViewDataQuery
>
export type BalancingGroupEditViewDataLazyQueryHookResult = ReturnType<
  typeof useBalancingGroupEditViewDataLazyQuery
>
export type BalancingGroupEditViewDataSuspenseQueryHookResult = ReturnType<
  typeof useBalancingGroupEditViewDataSuspenseQuery
>
export type BalancingGroupEditViewDataQueryResult = ApolloReactCommon.QueryResult<
  BalancingGroupEditViewDataQuery,
  BalancingGroupEditViewDataQueryVariables
>
export const BalancingGroupAvailableAssetsDocument = gql`
  query BalancingGroupAvailableAssets($input: SolarAssetListInput!, $filter: SolarAssetFilter) {
    solarAssetList(input: $input, filter: $filter) {
      records {
        ...BalancingGroupAssetData
      }
    }
  }
  ${BalancingGroupAssetDataFragmentDoc}
`

/**
 * __useBalancingGroupAvailableAssetsQuery__
 *
 * To run a query within a React component, call `useBalancingGroupAvailableAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancingGroupAvailableAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancingGroupAvailableAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBalancingGroupAvailableAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalancingGroupAvailableAssetsQuery,
    BalancingGroupAvailableAssetsQueryVariables
  > &
    ({ variables: BalancingGroupAvailableAssetsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BalancingGroupAvailableAssetsQuery,
    BalancingGroupAvailableAssetsQueryVariables
  >(BalancingGroupAvailableAssetsDocument, options)
}
export function useBalancingGroupAvailableAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalancingGroupAvailableAssetsQuery,
    BalancingGroupAvailableAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BalancingGroupAvailableAssetsQuery,
    BalancingGroupAvailableAssetsQueryVariables
  >(BalancingGroupAvailableAssetsDocument, options)
}
export function useBalancingGroupAvailableAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BalancingGroupAvailableAssetsQuery,
    BalancingGroupAvailableAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BalancingGroupAvailableAssetsQuery,
    BalancingGroupAvailableAssetsQueryVariables
  >(BalancingGroupAvailableAssetsDocument, options)
}
export type BalancingGroupAvailableAssetsQueryHookResult = ReturnType<
  typeof useBalancingGroupAvailableAssetsQuery
>
export type BalancingGroupAvailableAssetsLazyQueryHookResult = ReturnType<
  typeof useBalancingGroupAvailableAssetsLazyQuery
>
export type BalancingGroupAvailableAssetsSuspenseQueryHookResult = ReturnType<
  typeof useBalancingGroupAvailableAssetsSuspenseQuery
>
export type BalancingGroupAvailableAssetsQueryResult = ApolloReactCommon.QueryResult<
  BalancingGroupAvailableAssetsQuery,
  BalancingGroupAvailableAssetsQueryVariables
>
export const MonitoringDataAssetsDocument = gql`
  query MonitoringDataAssets($input: SolarAssetListInput!) {
    solarAssetList(input: $input) {
      records {
        ...SolarAssetIdData
        dcCapacity
        name
        locationName
      }
    }
  }
  ${SolarAssetIdDataFragmentDoc}
`

/**
 * __useMonitoringDataAssetsQuery__
 *
 * To run a query within a React component, call `useMonitoringDataAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringDataAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringDataAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMonitoringDataAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MonitoringDataAssetsQuery,
    MonitoringDataAssetsQueryVariables
  > &
    ({ variables: MonitoringDataAssetsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MonitoringDataAssetsQuery, MonitoringDataAssetsQueryVariables>(
    MonitoringDataAssetsDocument,
    options
  )
}
export function useMonitoringDataAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonitoringDataAssetsQuery,
    MonitoringDataAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MonitoringDataAssetsQuery, MonitoringDataAssetsQueryVariables>(
    MonitoringDataAssetsDocument,
    options
  )
}
export function useMonitoringDataAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MonitoringDataAssetsQuery,
    MonitoringDataAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MonitoringDataAssetsQuery, MonitoringDataAssetsQueryVariables>(
    MonitoringDataAssetsDocument,
    options
  )
}
export type MonitoringDataAssetsQueryHookResult = ReturnType<typeof useMonitoringDataAssetsQuery>
export type MonitoringDataAssetsLazyQueryHookResult = ReturnType<
  typeof useMonitoringDataAssetsLazyQuery
>
export type MonitoringDataAssetsSuspenseQueryHookResult = ReturnType<
  typeof useMonitoringDataAssetsSuspenseQuery
>
export type MonitoringDataAssetsQueryResult = ApolloReactCommon.QueryResult<
  MonitoringDataAssetsQuery,
  MonitoringDataAssetsQueryVariables
>
export const DemandDataAssetsDocument = gql`
  query DemandDataAssets($workspaceId: ID!) {
    solarAssetList(input: { organizationId: $workspaceId, perPage: 1000, page: 1 }) {
      records {
        ...SolarAssetIdData
        dcCapacity
        name
        locationName
        load {
          id
          name
          isDeleted
        }
      }
    }
  }
  ${SolarAssetIdDataFragmentDoc}
`

/**
 * __useDemandDataAssetsQuery__
 *
 * To run a query within a React component, call `useDemandDataAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemandDataAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemandDataAssetsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDemandDataAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<DemandDataAssetsQuery, DemandDataAssetsQueryVariables> &
    ({ variables: DemandDataAssetsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DemandDataAssetsQuery, DemandDataAssetsQueryVariables>(
    DemandDataAssetsDocument,
    options
  )
}
export function useDemandDataAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DemandDataAssetsQuery, DemandDataAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DemandDataAssetsQuery, DemandDataAssetsQueryVariables>(
    DemandDataAssetsDocument,
    options
  )
}
export function useDemandDataAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DemandDataAssetsQuery,
    DemandDataAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DemandDataAssetsQuery, DemandDataAssetsQueryVariables>(
    DemandDataAssetsDocument,
    options
  )
}
export type DemandDataAssetsQueryHookResult = ReturnType<typeof useDemandDataAssetsQuery>
export type DemandDataAssetsLazyQueryHookResult = ReturnType<typeof useDemandDataAssetsLazyQuery>
export type DemandDataAssetsSuspenseQueryHookResult = ReturnType<
  typeof useDemandDataAssetsSuspenseQuery
>
export type DemandDataAssetsQueryResult = ApolloReactCommon.QueryResult<
  DemandDataAssetsQuery,
  DemandDataAssetsQueryVariables
>
export const CreatePpaXDocument = gql`
  mutation CreatePpaX($input: CreatePpaXInput!) {
    ppa {
      create(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...PpaCrudData
          }
        }
      }
    }
  }
  ${PpaCrudDataFragmentDoc}
`
export type CreatePpaXMutationFn = ApolloReactCommon.MutationFunction<
  CreatePpaXMutation,
  CreatePpaXMutationVariables
>

/**
 * __useCreatePpaXMutation__
 *
 * To run a mutation, you first call `useCreatePpaXMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePpaXMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPpaXMutation, { data, loading, error }] = useCreatePpaXMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePpaXMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePpaXMutation, CreatePpaXMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePpaXMutation, CreatePpaXMutationVariables>(
    CreatePpaXDocument,
    options
  )
}
export type CreatePpaXMutationHookResult = ReturnType<typeof useCreatePpaXMutation>
export type CreatePpaXMutationResult = ApolloReactCommon.MutationResult<CreatePpaXMutation>
export type CreatePpaXMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePpaXMutation,
  CreatePpaXMutationVariables
>
export const UpdatePpaXDocument = gql`
  mutation UpdatePpaX($input: UpdatePpaXInput!) {
    ppa {
      update(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...PpaCrudData
          }
        }
      }
    }
  }
  ${PpaCrudDataFragmentDoc}
`
export type UpdatePpaXMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePpaXMutation,
  UpdatePpaXMutationVariables
>

/**
 * __useUpdatePpaXMutation__
 *
 * To run a mutation, you first call `useUpdatePpaXMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePpaXMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePpaXMutation, { data, loading, error }] = useUpdatePpaXMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePpaXMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePpaXMutation, UpdatePpaXMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePpaXMutation, UpdatePpaXMutationVariables>(
    UpdatePpaXDocument,
    options
  )
}
export type UpdatePpaXMutationHookResult = ReturnType<typeof useUpdatePpaXMutation>
export type UpdatePpaXMutationResult = ApolloReactCommon.MutationResult<UpdatePpaXMutation>
export type UpdatePpaXMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePpaXMutation,
  UpdatePpaXMutationVariables
>
export const DeletePpaXDocument = gql`
  mutation DeletePpaX($input: DeletePpaXInput!) {
    ppa {
      delete(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...PpaCrudData
          }
        }
      }
    }
  }
  ${PpaCrudDataFragmentDoc}
`
export type DeletePpaXMutationFn = ApolloReactCommon.MutationFunction<
  DeletePpaXMutation,
  DeletePpaXMutationVariables
>

/**
 * __useDeletePpaXMutation__
 *
 * To run a mutation, you first call `useDeletePpaXMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePpaXMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePpaXMutation, { data, loading, error }] = useDeletePpaXMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePpaXMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePpaXMutation, DeletePpaXMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePpaXMutation, DeletePpaXMutationVariables>(
    DeletePpaXDocument,
    options
  )
}
export type DeletePpaXMutationHookResult = ReturnType<typeof useDeletePpaXMutation>
export type DeletePpaXMutationResult = ApolloReactCommon.MutationResult<DeletePpaXMutation>
export type DeletePpaXMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePpaXMutation,
  DeletePpaXMutationVariables
>
export const DeletePpasDocument = gql`
  mutation DeletePpas($input: DeleteManyPpasInput!, $queryInput: PpaListInput!) {
    ppas {
      deleteMany(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...PpaCrudData
          }
        }
        query {
          ppaList(input: $queryInput) {
            records {
              ...PpaCoreData
            }
            pageInfo {
              ...PaginationMetadata
            }
          }
        }
      }
    }
  }
  ${PpaCrudDataFragmentDoc}
  ${PpaCoreDataFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`
export type DeletePpasMutationFn = ApolloReactCommon.MutationFunction<
  DeletePpasMutation,
  DeletePpasMutationVariables
>

/**
 * __useDeletePpasMutation__
 *
 * To run a mutation, you first call `useDeletePpasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePpasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePpasMutation, { data, loading, error }] = useDeletePpasMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeletePpasMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePpasMutation, DeletePpasMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePpasMutation, DeletePpasMutationVariables>(
    DeletePpasDocument,
    options
  )
}
export type DeletePpasMutationHookResult = ReturnType<typeof useDeletePpasMutation>
export type DeletePpasMutationResult = ApolloReactCommon.MutationResult<DeletePpasMutation>
export type DeletePpasMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePpasMutation,
  DeletePpasMutationVariables
>
export const RenamePpaXDocument = gql`
  mutation RenamePpaX($id: ID!, $organizationId: ID!, $name: String!) {
    ppa {
      update(input: { id: $id, organizationId: $organizationId, data: { name: $name } }) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            id
            name
          }
        }
      }
    }
  }
`
export type RenamePpaXMutationFn = ApolloReactCommon.MutationFunction<
  RenamePpaXMutation,
  RenamePpaXMutationVariables
>

/**
 * __useRenamePpaXMutation__
 *
 * To run a mutation, you first call `useRenamePpaXMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenamePpaXMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renamePpaXMutation, { data, loading, error }] = useRenamePpaXMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenamePpaXMutation(
  baseOptions?: Apollo.MutationHookOptions<RenamePpaXMutation, RenamePpaXMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RenamePpaXMutation, RenamePpaXMutationVariables>(
    RenamePpaXDocument,
    options
  )
}
export type RenamePpaXMutationHookResult = ReturnType<typeof useRenamePpaXMutation>
export type RenamePpaXMutationResult = ApolloReactCommon.MutationResult<RenamePpaXMutation>
export type RenamePpaXMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenamePpaXMutation,
  RenamePpaXMutationVariables
>
export const AddAssetsToPpaDocument = gql`
  mutation AddAssetsToPpa(
    $addAssetsInput: AddAssetsToPpaInput!
    $ppaQueryInput: QueryEntityInput!
    $solarAssetQueryInput: SolarAssetListInput!
    $solarAssetFilter: SolarAssetFilter!
  ) {
    ppa {
      addAssets(input: $addAssetsInput) {
        data {
          recordId
          record {
            name
            id
            solarAssets {
              id
            }
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on ResourceIsAlreadyInUseProblem {
            message
            recordId
          }
        }
        success
        query {
          ppa(input: $ppaQueryInput) {
            ...PpaCrudData
          }
          solarAssetList(input: $solarAssetQueryInput, filter: $solarAssetFilter) {
            records {
              ...PpaAssetData
            }
          }
        }
      }
    }
  }
  ${PpaCrudDataFragmentDoc}
  ${PpaAssetDataFragmentDoc}
`
export type AddAssetsToPpaMutationFn = ApolloReactCommon.MutationFunction<
  AddAssetsToPpaMutation,
  AddAssetsToPpaMutationVariables
>

/**
 * __useAddAssetsToPpaMutation__
 *
 * To run a mutation, you first call `useAddAssetsToPpaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetsToPpaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetsToPpaMutation, { data, loading, error }] = useAddAssetsToPpaMutation({
 *   variables: {
 *      addAssetsInput: // value for 'addAssetsInput'
 *      ppaQueryInput: // value for 'ppaQueryInput'
 *      solarAssetQueryInput: // value for 'solarAssetQueryInput'
 *      solarAssetFilter: // value for 'solarAssetFilter'
 *   },
 * });
 */
export function useAddAssetsToPpaMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAssetsToPpaMutation, AddAssetsToPpaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddAssetsToPpaMutation, AddAssetsToPpaMutationVariables>(
    AddAssetsToPpaDocument,
    options
  )
}
export type AddAssetsToPpaMutationHookResult = ReturnType<typeof useAddAssetsToPpaMutation>
export type AddAssetsToPpaMutationResult = ApolloReactCommon.MutationResult<AddAssetsToPpaMutation>
export type AddAssetsToPpaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddAssetsToPpaMutation,
  AddAssetsToPpaMutationVariables
>
export const RemoveAssetsFromPpaDocument = gql`
  mutation RemoveAssetsFromPpa($input: RemoveAssetsFromPpaInput!, $queryInput: QueryEntityInput!) {
    ppa {
      removeAssets(input: $input) {
        data {
          recordId
          record {
            id
            name
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        query {
          ppa(input: $queryInput) {
            id
            totalAssets
            solarAssets {
              ...PpaAssetData
            }
          }
        }
      }
    }
  }
  ${PpaAssetDataFragmentDoc}
`
export type RemoveAssetsFromPpaMutationFn = ApolloReactCommon.MutationFunction<
  RemoveAssetsFromPpaMutation,
  RemoveAssetsFromPpaMutationVariables
>

/**
 * __useRemoveAssetsFromPpaMutation__
 *
 * To run a mutation, you first call `useRemoveAssetsFromPpaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssetsFromPpaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssetsFromPpaMutation, { data, loading, error }] = useRemoveAssetsFromPpaMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useRemoveAssetsFromPpaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAssetsFromPpaMutation,
    RemoveAssetsFromPpaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveAssetsFromPpaMutation, RemoveAssetsFromPpaMutationVariables>(
    RemoveAssetsFromPpaDocument,
    options
  )
}
export type RemoveAssetsFromPpaMutationHookResult = ReturnType<
  typeof useRemoveAssetsFromPpaMutation
>
export type RemoveAssetsFromPpaMutationResult =
  ApolloReactCommon.MutationResult<RemoveAssetsFromPpaMutation>
export type RemoveAssetsFromPpaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveAssetsFromPpaMutation,
  RemoveAssetsFromPpaMutationVariables
>
export const PpaDocument = gql`
  query Ppa($input: QueryEntityInput!) {
    ppa(input: $input) {
      ...PpaCrudData
    }
  }
  ${PpaCrudDataFragmentDoc}
`

/**
 * __usePpaQuery__
 *
 * To run a query within a React component, call `usePpaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePpaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePpaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePpaQuery(
  baseOptions: Apollo.QueryHookOptions<PpaQuery, PpaQueryVariables> &
    ({ variables: PpaQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PpaQuery, PpaQueryVariables>(PpaDocument, options)
}
export function usePpaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PpaQuery, PpaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PpaQuery, PpaQueryVariables>(PpaDocument, options)
}
export function usePpaSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PpaQuery, PpaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PpaQuery, PpaQueryVariables>(PpaDocument, options)
}
export type PpaQueryHookResult = ReturnType<typeof usePpaQuery>
export type PpaLazyQueryHookResult = ReturnType<typeof usePpaLazyQuery>
export type PpaSuspenseQueryHookResult = ReturnType<typeof usePpaSuspenseQuery>
export type PpaQueryResult = ApolloReactCommon.QueryResult<PpaQuery, PpaQueryVariables>
export const PpaOverviewDocument = gql`
  query PpaOverview($input: QueryEntityInput!) {
    ppa(input: $input) {
      capacity
      capPrice {
        value
        year
      }
      createdAt
      discountToMarket {
        value
        year
      }
      fipBeneficiary
      floorPrice {
        value
        year
      }
      id
      name
      organizationId
      pricingScheme
      readableId
      updatedAt
      settlementMarket
      settlementPricingZone
      solarAssets {
        acCapacity
        dcCapacity
        id
        latitude
        longitude
        name
      }
      startDate
      status
      strikePrice {
        value
        year
      }
      term
      type
      volumes {
        totalAcCapacity
        totalDcCapacity
        averageAnnualGeneration
      }
      offtaker {
        id
        name
      }
    }
  }
`

/**
 * __usePpaOverviewQuery__
 *
 * To run a query within a React component, call `usePpaOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePpaOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePpaOverviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePpaOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<PpaOverviewQuery, PpaOverviewQueryVariables> &
    ({ variables: PpaOverviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PpaOverviewQuery, PpaOverviewQueryVariables>(PpaOverviewDocument, options)
}
export function usePpaOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PpaOverviewQuery, PpaOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PpaOverviewQuery, PpaOverviewQueryVariables>(
    PpaOverviewDocument,
    options
  )
}
export function usePpaOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PpaOverviewQuery, PpaOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PpaOverviewQuery, PpaOverviewQueryVariables>(
    PpaOverviewDocument,
    options
  )
}
export type PpaOverviewQueryHookResult = ReturnType<typeof usePpaOverviewQuery>
export type PpaOverviewLazyQueryHookResult = ReturnType<typeof usePpaOverviewLazyQuery>
export type PpaOverviewSuspenseQueryHookResult = ReturnType<typeof usePpaOverviewSuspenseQuery>
export type PpaOverviewQueryResult = ApolloReactCommon.QueryResult<
  PpaOverviewQuery,
  PpaOverviewQueryVariables
>
export const PpaBaseDataDocument = gql`
  query PpaBaseData($input: QueryEntityInput!) {
    ppa(input: $input) {
      id
      readableId
      name
      status
    }
  }
`

/**
 * __usePpaBaseDataQuery__
 *
 * To run a query within a React component, call `usePpaBaseDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePpaBaseDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePpaBaseDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePpaBaseDataQuery(
  baseOptions: Apollo.QueryHookOptions<PpaBaseDataQuery, PpaBaseDataQueryVariables> &
    ({ variables: PpaBaseDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PpaBaseDataQuery, PpaBaseDataQueryVariables>(PpaBaseDataDocument, options)
}
export function usePpaBaseDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PpaBaseDataQuery, PpaBaseDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PpaBaseDataQuery, PpaBaseDataQueryVariables>(
    PpaBaseDataDocument,
    options
  )
}
export function usePpaBaseDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PpaBaseDataQuery, PpaBaseDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PpaBaseDataQuery, PpaBaseDataQueryVariables>(
    PpaBaseDataDocument,
    options
  )
}
export type PpaBaseDataQueryHookResult = ReturnType<typeof usePpaBaseDataQuery>
export type PpaBaseDataLazyQueryHookResult = ReturnType<typeof usePpaBaseDataLazyQuery>
export type PpaBaseDataSuspenseQueryHookResult = ReturnType<typeof usePpaBaseDataSuspenseQuery>
export type PpaBaseDataQueryResult = ApolloReactCommon.QueryResult<
  PpaBaseDataQuery,
  PpaBaseDataQueryVariables
>
export const PpasAsOptionsDocument = gql`
  query PpasAsOptions($input: PpaListInput!, $filter: PpaFilter, $sort: PpaSort) {
    ppaList(input: $input, filter: $filter, sort: $sort) {
      records {
        id
        name
      }
    }
  }
`

/**
 * __usePpasAsOptionsQuery__
 *
 * To run a query within a React component, call `usePpasAsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePpasAsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePpasAsOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePpasAsOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<PpasAsOptionsQuery, PpasAsOptionsQueryVariables> &
    ({ variables: PpasAsOptionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PpasAsOptionsQuery, PpasAsOptionsQueryVariables>(
    PpasAsOptionsDocument,
    options
  )
}
export function usePpasAsOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PpasAsOptionsQuery, PpasAsOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PpasAsOptionsQuery, PpasAsOptionsQueryVariables>(
    PpasAsOptionsDocument,
    options
  )
}
export function usePpasAsOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PpasAsOptionsQuery, PpasAsOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PpasAsOptionsQuery, PpasAsOptionsQueryVariables>(
    PpasAsOptionsDocument,
    options
  )
}
export type PpasAsOptionsQueryHookResult = ReturnType<typeof usePpasAsOptionsQuery>
export type PpasAsOptionsLazyQueryHookResult = ReturnType<typeof usePpasAsOptionsLazyQuery>
export type PpasAsOptionsSuspenseQueryHookResult = ReturnType<typeof usePpasAsOptionsSuspenseQuery>
export type PpasAsOptionsQueryResult = ApolloReactCommon.QueryResult<
  PpasAsOptionsQuery,
  PpasAsOptionsQueryVariables
>
export const PpasDocument = gql`
  query Ppas($input: PpaListInput!) {
    ppaList(input: $input) {
      records {
        ...PpaCoreData
      }
      pageInfo {
        ...PaginationMetadata
      }
    }
  }
  ${PpaCoreDataFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`

/**
 * __usePpasQuery__
 *
 * To run a query within a React component, call `usePpasQuery` and pass it any options that fit your needs.
 * When your component renders, `usePpasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePpasQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePpasQuery(
  baseOptions: Apollo.QueryHookOptions<PpasQuery, PpasQueryVariables> &
    ({ variables: PpasQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PpasQuery, PpasQueryVariables>(PpasDocument, options)
}
export function usePpasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PpasQuery, PpasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PpasQuery, PpasQueryVariables>(PpasDocument, options)
}
export function usePpasSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PpasQuery, PpasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PpasQuery, PpasQueryVariables>(PpasDocument, options)
}
export type PpasQueryHookResult = ReturnType<typeof usePpasQuery>
export type PpasLazyQueryHookResult = ReturnType<typeof usePpasLazyQuery>
export type PpasSuspenseQueryHookResult = ReturnType<typeof usePpasSuspenseQuery>
export type PpasQueryResult = ApolloReactCommon.QueryResult<PpasQuery, PpasQueryVariables>
export const PpasNameListDocument = gql`
  query PpasNameList($input: PpaListInput!) {
    ppaList(input: $input) {
      records {
        id
        name
      }
    }
  }
`

/**
 * __usePpasNameListQuery__
 *
 * To run a query within a React component, call `usePpasNameListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePpasNameListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePpasNameListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePpasNameListQuery(
  baseOptions: Apollo.QueryHookOptions<PpasNameListQuery, PpasNameListQueryVariables> &
    ({ variables: PpasNameListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PpasNameListQuery, PpasNameListQueryVariables>(
    PpasNameListDocument,
    options
  )
}
export function usePpasNameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PpasNameListQuery, PpasNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PpasNameListQuery, PpasNameListQueryVariables>(
    PpasNameListDocument,
    options
  )
}
export function usePpasNameListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PpasNameListQuery, PpasNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PpasNameListQuery, PpasNameListQueryVariables>(
    PpasNameListDocument,
    options
  )
}
export type PpasNameListQueryHookResult = ReturnType<typeof usePpasNameListQuery>
export type PpasNameListLazyQueryHookResult = ReturnType<typeof usePpasNameListLazyQuery>
export type PpasNameListSuspenseQueryHookResult = ReturnType<typeof usePpasNameListSuspenseQuery>
export type PpasNameListQueryResult = ApolloReactCommon.QueryResult<
  PpasNameListQuery,
  PpasNameListQueryVariables
>
export const StarReportDocument = gql`
  mutation StarReport($input: StarReportInput!) {
    report {
      star(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            id
            isStarred
          }
        }
      }
    }
  }
`
export type StarReportMutationFn = ApolloReactCommon.MutationFunction<
  StarReportMutation,
  StarReportMutationVariables
>

/**
 * __useStarReportMutation__
 *
 * To run a mutation, you first call `useStarReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStarReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [starReportMutation, { data, loading, error }] = useStarReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStarReportMutation(
  baseOptions?: Apollo.MutationHookOptions<StarReportMutation, StarReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StarReportMutation, StarReportMutationVariables>(
    StarReportDocument,
    options
  )
}
export type StarReportMutationHookResult = ReturnType<typeof useStarReportMutation>
export type StarReportMutationResult = ApolloReactCommon.MutationResult<StarReportMutation>
export type StarReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StarReportMutation,
  StarReportMutationVariables
>
export const UnstarReportDocument = gql`
  mutation UnstarReport($input: UnstarReportInput!) {
    report {
      unstar(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            id
            isStarred
          }
        }
      }
    }
  }
`
export type UnstarReportMutationFn = ApolloReactCommon.MutationFunction<
  UnstarReportMutation,
  UnstarReportMutationVariables
>

/**
 * __useUnstarReportMutation__
 *
 * To run a mutation, you first call `useUnstarReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnstarReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unstarReportMutation, { data, loading, error }] = useUnstarReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnstarReportMutation(
  baseOptions?: Apollo.MutationHookOptions<UnstarReportMutation, UnstarReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnstarReportMutation, UnstarReportMutationVariables>(
    UnstarReportDocument,
    options
  )
}
export type UnstarReportMutationHookResult = ReturnType<typeof useUnstarReportMutation>
export type UnstarReportMutationResult = ApolloReactCommon.MutationResult<UnstarReportMutation>
export type UnstarReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnstarReportMutation,
  UnstarReportMutationVariables
>
export const RenameReportDocument = gql`
  mutation RenameReport($input: RenameReportInput!) {
    report {
      rename(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            id
            name
          }
        }
      }
    }
  }
`
export type RenameReportMutationFn = ApolloReactCommon.MutationFunction<
  RenameReportMutation,
  RenameReportMutationVariables
>

/**
 * __useRenameReportMutation__
 *
 * To run a mutation, you first call `useRenameReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameReportMutation, { data, loading, error }] = useRenameReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameReportMutation(
  baseOptions?: Apollo.MutationHookOptions<RenameReportMutation, RenameReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RenameReportMutation, RenameReportMutationVariables>(
    RenameReportDocument,
    options
  )
}
export type RenameReportMutationHookResult = ReturnType<typeof useRenameReportMutation>
export type RenameReportMutationResult = ApolloReactCommon.MutationResult<RenameReportMutation>
export type RenameReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenameReportMutation,
  RenameReportMutationVariables
>
export const DeleteReportsDocument = gql`
  mutation DeleteReports($input: DeleteManyReportsInput!, $queryInput: ReportListInput!) {
    reports {
      deleteMany(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            id
            name
            readableId
          }
        }
        query {
          reportList(input: $queryInput) {
            ...ReportListData
          }
        }
      }
    }
  }
  ${ReportListDataFragmentDoc}
`
export type DeleteReportsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteReportsMutation,
  DeleteReportsMutationVariables
>

/**
 * __useDeleteReportsMutation__
 *
 * To run a mutation, you first call `useDeleteReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportsMutation, { data, loading, error }] = useDeleteReportsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteReportsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteReportsMutation, DeleteReportsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteReportsMutation, DeleteReportsMutationVariables>(
    DeleteReportsDocument,
    options
  )
}
export type DeleteReportsMutationHookResult = ReturnType<typeof useDeleteReportsMutation>
export type DeleteReportsMutationResult = ApolloReactCommon.MutationResult<DeleteReportsMutation>
export type DeleteReportsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteReportsMutation,
  DeleteReportsMutationVariables
>
export const CreateReportDocument = gql`
  mutation CreateReport($input: CreateReportInput!, $organizationId: ID!) {
    report {
      create(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
        }
        query {
          solarAssetList(input: { organizationId: $organizationId, page: 1, perPage: 1000 }) {
            ...ReportSolarAssetListData
          }
          scenarioList(input: { organizationId: $organizationId, page: 1, perPage: 1000 }) {
            ...ReportScenarioListData
          }
        }
      }
    }
  }
  ${ReportSolarAssetListDataFragmentDoc}
  ${ReportScenarioListDataFragmentDoc}
`
export type CreateReportMutationFn = ApolloReactCommon.MutationFunction<
  CreateReportMutation,
  CreateReportMutationVariables
>

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(
    CreateReportDocument,
    options
  )
}
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>
export type CreateReportMutationResult = ApolloReactCommon.MutationResult<CreateReportMutation>
export type CreateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateReportMutation,
  CreateReportMutationVariables
>
export const SendMagicLinkDocument = gql`
  mutation SendMagicLink($input: SendReportMagicLinkInput!) {
    report {
      sendMagicLink(input: $input) {
        errors {
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on AuthorizationProblem {
            message
          }
        }
        success
      }
    }
  }
`
export type SendMagicLinkMutationFn = ApolloReactCommon.MutationFunction<
  SendMagicLinkMutation,
  SendMagicLinkMutationVariables
>

/**
 * __useSendMagicLinkMutation__
 *
 * To run a mutation, you first call `useSendMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMagicLinkMutation, { data, loading, error }] = useSendMagicLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMagicLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<SendMagicLinkMutation, SendMagicLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendMagicLinkMutation, SendMagicLinkMutationVariables>(
    SendMagicLinkDocument,
    options
  )
}
export type SendMagicLinkMutationHookResult = ReturnType<typeof useSendMagicLinkMutation>
export type SendMagicLinkMutationResult = ApolloReactCommon.MutationResult<SendMagicLinkMutation>
export type SendMagicLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendMagicLinkMutation,
  SendMagicLinkMutationVariables
>
export const SetReportAccessExpiryDocument = gql`
  mutation SetReportAccessExpiry($input: SetReportAccessExpiryInput!) {
    report {
      setAccessExpiry(input: $input) {
        data {
          recordId
          record {
            id
            accessExpiry
          }
        }
        errors {
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on AuthorizationProblem {
            message
          }
        }
        success
      }
    }
  }
`
export type SetReportAccessExpiryMutationFn = ApolloReactCommon.MutationFunction<
  SetReportAccessExpiryMutation,
  SetReportAccessExpiryMutationVariables
>

/**
 * __useSetReportAccessExpiryMutation__
 *
 * To run a mutation, you first call `useSetReportAccessExpiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReportAccessExpiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReportAccessExpiryMutation, { data, loading, error }] = useSetReportAccessExpiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReportAccessExpiryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetReportAccessExpiryMutation,
    SetReportAccessExpiryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetReportAccessExpiryMutation, SetReportAccessExpiryMutationVariables>(
    SetReportAccessExpiryDocument,
    options
  )
}
export type SetReportAccessExpiryMutationHookResult = ReturnType<
  typeof useSetReportAccessExpiryMutation
>
export type SetReportAccessExpiryMutationResult =
  ApolloReactCommon.MutationResult<SetReportAccessExpiryMutation>
export type SetReportAccessExpiryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetReportAccessExpiryMutation,
  SetReportAccessExpiryMutationVariables
>
export const ShareReportDocument = gql`
  mutation ShareReport($input: ShareReportInput!) {
    report {
      share(input: $input) {
        data {
          recordId
          record {
            ...ReportSharingData
          }
        }
        errors {
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on AuthorizationProblem {
            message
          }
        }
        success
      }
    }
  }
  ${ReportSharingDataFragmentDoc}
`
export type ShareReportMutationFn = ApolloReactCommon.MutationFunction<
  ShareReportMutation,
  ShareReportMutationVariables
>

/**
 * __useShareReportMutation__
 *
 * To run a mutation, you first call `useShareReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareReportMutation, { data, loading, error }] = useShareReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareReportMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareReportMutation, ShareReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ShareReportMutation, ShareReportMutationVariables>(
    ShareReportDocument,
    options
  )
}
export type ShareReportMutationHookResult = ReturnType<typeof useShareReportMutation>
export type ShareReportMutationResult = ApolloReactCommon.MutationResult<ShareReportMutation>
export type ShareReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ShareReportMutation,
  ShareReportMutationVariables
>
export const RevokeReportAccessDocument = gql`
  mutation RevokeReportAccess($input: UnshareReportInput!) {
    report {
      unshare(input: $input) {
        data {
          recordId
          record {
            id
            sharedWith
            accessExpiry
          }
        }
        errors {
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on AuthorizationProblem {
            message
          }
        }
        success
      }
    }
  }
`
export type RevokeReportAccessMutationFn = ApolloReactCommon.MutationFunction<
  RevokeReportAccessMutation,
  RevokeReportAccessMutationVariables
>

/**
 * __useRevokeReportAccessMutation__
 *
 * To run a mutation, you first call `useRevokeReportAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeReportAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeReportAccessMutation, { data, loading, error }] = useRevokeReportAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeReportAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeReportAccessMutation,
    RevokeReportAccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RevokeReportAccessMutation, RevokeReportAccessMutationVariables>(
    RevokeReportAccessDocument,
    options
  )
}
export type RevokeReportAccessMutationHookResult = ReturnType<typeof useRevokeReportAccessMutation>
export type RevokeReportAccessMutationResult =
  ApolloReactCommon.MutationResult<RevokeReportAccessMutation>
export type RevokeReportAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RevokeReportAccessMutation,
  RevokeReportAccessMutationVariables
>
export const ReportsDocument = gql`
  query Reports($input: ReportListInput!, $filter: ReportFilter, $sort: ReportSort) {
    reportList(input: $input, filter: $filter, sort: $sort) {
      ...ReportListData
    }
  }
  ${ReportListDataFragmentDoc}
`

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReportsQuery(
  baseOptions: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables> &
    ({ variables: ReportsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options)
}
export function useReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options)
}
export function useReportsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReportsQuery, ReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options)
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>
export type ReportsSuspenseQueryHookResult = ReturnType<typeof useReportsSuspenseQuery>
export type ReportsQueryResult = ApolloReactCommon.QueryResult<ReportsQuery, ReportsQueryVariables>
export const ReportScenariosDocument = gql`
  query ReportScenarios($reportInput: QueryEntityInput!) {
    report(input: $reportInput) {
      ...ReportCoreData
      scope {
        macroScenarios {
          records {
            id
            name
            isDefault
          }
        }
      }
    }
  }
  ${ReportCoreDataFragmentDoc}
`

/**
 * __useReportScenariosQuery__
 *
 * To run a query within a React component, call `useReportScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportScenariosQuery({
 *   variables: {
 *      reportInput: // value for 'reportInput'
 *   },
 * });
 */
export function useReportScenariosQuery(
  baseOptions: Apollo.QueryHookOptions<ReportScenariosQuery, ReportScenariosQueryVariables> &
    ({ variables: ReportScenariosQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportScenariosQuery, ReportScenariosQueryVariables>(
    ReportScenariosDocument,
    options
  )
}
export function useReportScenariosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportScenariosQuery, ReportScenariosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportScenariosQuery, ReportScenariosQueryVariables>(
    ReportScenariosDocument,
    options
  )
}
export function useReportScenariosSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReportScenariosQuery, ReportScenariosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReportScenariosQuery, ReportScenariosQueryVariables>(
    ReportScenariosDocument,
    options
  )
}
export type ReportScenariosQueryHookResult = ReturnType<typeof useReportScenariosQuery>
export type ReportScenariosLazyQueryHookResult = ReturnType<typeof useReportScenariosLazyQuery>
export type ReportScenariosSuspenseQueryHookResult = ReturnType<
  typeof useReportScenariosSuspenseQuery
>
export type ReportScenariosQueryResult = ApolloReactCommon.QueryResult<
  ReportScenariosQuery,
  ReportScenariosQueryVariables
>
export const ReportCoreDocument = gql`
  query ReportCore(
    $reportInput: QueryEntityInput!
    $ppaScopeInput: ReportScopeBaseInput!
    $solarAssetScopeInput: SolarAssetReportScopeInput!
  ) {
    report(input: $reportInput) {
      ...ReportCoreData
      simulationStatus
      scope {
        solarAssets {
          records(input: $solarAssetScopeInput) {
            id
            name
          }
        }
        ppa {
          records(input: $ppaScopeInput) {
            id
            name
          }
        }
        macroScenarios {
          records {
            id
            name
            isDefault
          }
        }
      }
    }
  }
  ${ReportCoreDataFragmentDoc}
`

/**
 * __useReportCoreQuery__
 *
 * To run a query within a React component, call `useReportCoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCoreQuery({
 *   variables: {
 *      reportInput: // value for 'reportInput'
 *      ppaScopeInput: // value for 'ppaScopeInput'
 *      solarAssetScopeInput: // value for 'solarAssetScopeInput'
 *   },
 * });
 */
export function useReportCoreQuery(
  baseOptions: Apollo.QueryHookOptions<ReportCoreQuery, ReportCoreQueryVariables> &
    ({ variables: ReportCoreQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportCoreQuery, ReportCoreQueryVariables>(ReportCoreDocument, options)
}
export function useReportCoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportCoreQuery, ReportCoreQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportCoreQuery, ReportCoreQueryVariables>(ReportCoreDocument, options)
}
export function useReportCoreSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReportCoreQuery, ReportCoreQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReportCoreQuery, ReportCoreQueryVariables>(
    ReportCoreDocument,
    options
  )
}
export type ReportCoreQueryHookResult = ReturnType<typeof useReportCoreQuery>
export type ReportCoreLazyQueryHookResult = ReturnType<typeof useReportCoreLazyQuery>
export type ReportCoreSuspenseQueryHookResult = ReturnType<typeof useReportCoreSuspenseQuery>
export type ReportCoreQueryResult = ApolloReactCommon.QueryResult<
  ReportCoreQuery,
  ReportCoreQueryVariables
>
export const ReportDetailsDocument = gql`
  query ReportDetails(
    $reportInput: QueryEntityInput!
    $scopeInput: ReportScopeBaseInput!
    $scenarioInput: MacroScenarioSeriesInput!
    $solarAssetSimulationInput: SolarAssetReportScopeInput!
  ) {
    report(input: $reportInput) {
      ...ReportCoreData
      author {
        ...UserCoreData
      }
      organization {
        ...OrganizationCoreData
      }
      issuedFor
      isStarred
      isBaseline
      isShareable
      isArchived
      scope {
        solarAssets {
          total
          records(input: $solarAssetSimulationInput) {
            id
            name
            acCapacity
            dcCapacity
            solarYieldFirstYear
            irr
            latitude
            longitude
            gridZone
            ppa {
              id
              name
            }
          }
          capexPayments(input: $scopeInput) {
            ...CapexPaymentData
          }
          keyIndicators(input: $solarAssetSimulationInput) {
            total
            acCapacity
            dcCapacity
            irr {
              calculation {
                ...BenchmarkData
              }
              error
            }
            totalCapex {
              ...BenchmarkData
            }
            lifetimeRevenues {
              ...BenchmarkData
            }
            kwpCapex {
              ...BenchmarkData
            }
            kwpRevenues {
              ...BenchmarkData
            }
          }
          simulationSummary(input: $solarAssetSimulationInput) {
            netGenerationLifetimeTotal
            assetManagementCostsMonthlySeries {
              ...SingleSeriesSet
            }
            assetManagementCostsYearlySeries {
              ...SingleSeriesSet
            }
            assetManagementLifetimeTotal
            capexLifetimeTotal
            capexMonthlySeries {
              ...SingleSeriesSet
            }
            capexYearlySeries {
              ...SingleSeriesSet
            }
            cumulativeNetCashflowMonthlySeries {
              ...SingleSeriesSet
            }
            cumulativeNetCashflowYearlySeries {
              ...SingleSeriesSet
            }
            curtailmentMonthlySeries {
              ...SingleSeriesSet
            }
            curtailmentYearlySeries {
              ...SingleSeriesSet
            }
            decommissionReserveCostsMonthlySeries {
              ...SingleSeriesSet
            }
            decommissionReserveCostsYearlySeries {
              ...SingleSeriesSet
            }
            decommissionReserveLifetimeTotal
            hardwareReplacementCostsMonthlySeries {
              ...SingleSeriesSet
            }
            hardwareReplacementCostsYearlySeries {
              ...SingleSeriesSet
            }
            hardwareReplacementLifetimeTotal
            insuranceCostsMonthlySeries {
              ...SingleSeriesSet
            }
            insuranceCostsYearlySeries {
              ...SingleSeriesSet
            }
            insuranceLifetimeTotal
            landLeaseCostsMonthlySeries {
              ...SingleSeriesSet
            }
            landLeaseCostsYearlySeries {
              ...SingleSeriesSet
            }
            landLeaseLifetimeTotal
            marketRevenueMonthlySeries {
              ...SingleSeriesSet
            }
            marketRevenueYearlySeries {
              ...SingleSeriesSet
            }
            marketRevenueLifetimeTotal
            netCashflowMonthlySeries {
              ...SingleSeriesSet
            }
            netCashflowYearlySeries {
              ...SingleSeriesSet
            }
            netCashflowLifetimeTotal
            netGenerationMonthlySeries {
              ...SingleSeriesSet
            }
            netGenerationYearlySeries {
              ...SingleSeriesSet
            }
            omCostsMonthlySeries {
              ...SingleSeriesSet
            }
            omCostsYearlySeries {
              ...SingleSeriesSet
            }
            omLifetimeTotal
            otherCostsMonthlySeries {
              ...SingleSeriesSet
            }
            otherCostsYearlySeries {
              ...SingleSeriesSet
            }
            otherLifetimeTotal
            operatingCashflowMonthlySeries {
              ...SingleSeriesSet
            }
            operatingCashflowYearlySeries {
              ...SingleSeriesSet
            }
            opexLifetimeTotal
            opexTotalCostsMonthlySeries {
              ...SingleSeriesSet
            }
            opexTotalCostsYearlySeries {
              ...SingleSeriesSet
            }
            ppaCashflowMonthlySeries {
              ...SingleSeriesSet
            }
            ppaCashflowYearlySeries {
              ...SingleSeriesSet
            }
            ppaCashflowLifetimeTotal
            subsidyRevenueMonthlySeries {
              ...SingleSeriesSet
            }
            subsidyRevenueYearlySeries {
              ...SingleSeriesSet
            }
            subsidyRevenueLifetimeTotal
            taxCostsMonthlySeries {
              ...SingleSeriesSet
            }
            taxCostsYearlySeries {
              ...SingleSeriesSet
            }
            taxLifetimeTotal
            totalGenerationMonthlySeries {
              ...SingleSeriesSet
            }
            totalGenerationYearlySeries {
              ...SingleSeriesSet
            }
            totalLifetimeRevenue
          }
        }
        ppa {
          records(input: $scopeInput) {
            isFlexiblePricingScheme
            calculationInterval
            capPrice {
              value
              year
            }
            createdAt
            capacity
            deleted
            discountToMarket {
              value
              year
            }
            fipBeneficiary
            fixedShare
            floatingShare
            floorPrice {
              value
              year
            }
            id
            name
            offtaker {
              id
              name
              createdAt
              updatedAt
              type
            }
            organizationId
            pricingScheme
            settlementMarket
            settlementPricingZone
            startDate
            solarAssets {
              id
            }
            status
            strikePrice {
              value
              year
            }
            term
            totalAssets
            type
            updatedAt
            userUpdatedAt
            volumes {
              totalAcCapacity
              totalDcCapacity
              averageAnnualGeneration
            }
          }
          total
          keyIndicators(input: $scopeInput) {
            total
          }
        }
        macroScenarios {
          averageSystemPriceYearlySeries(input: $scenarioInput) {
            ...MacroScenarioAssumptionPricesData
          }
          curtailmentYearlySeries(input: $scenarioInput) {
            ...MacroScenarioAssumptionCurtailmentsData
          }
          forecastErrorDayAheadYearlySeries(input: $scenarioInput) {
            ...SingleSeriesSet
          }
          forecastErrorIntradayYearlySeries(input: $scenarioInput) {
            ...SingleSeriesSet
          }
          inflationRateYearlySeries(input: $scenarioInput) {
            ...SingleSeriesSet
          }
          meanAveragePriceDeviationYearlySeries(input: $scenarioInput) {
            ...MacroScenarioAssumptionPricesData
          }
          records {
            id
            name
          }
        }
      }
      simulationStatus
    }
  }
  ${ReportCoreDataFragmentDoc}
  ${UserCoreDataFragmentDoc}
  ${OrganizationCoreDataFragmentDoc}
  ${CapexPaymentDataFragmentDoc}
  ${BenchmarkDataFragmentDoc}
  ${SingleSeriesSetFragmentDoc}
  ${MacroScenarioAssumptionPricesDataFragmentDoc}
  ${MacroScenarioAssumptionCurtailmentsDataFragmentDoc}
`

/**
 * __useReportDetailsQuery__
 *
 * To run a query within a React component, call `useReportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportDetailsQuery({
 *   variables: {
 *      reportInput: // value for 'reportInput'
 *      scopeInput: // value for 'scopeInput'
 *      scenarioInput: // value for 'scenarioInput'
 *      solarAssetSimulationInput: // value for 'solarAssetSimulationInput'
 *   },
 * });
 */
export function useReportDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ReportDetailsQuery, ReportDetailsQueryVariables> &
    ({ variables: ReportDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportDetailsQuery, ReportDetailsQueryVariables>(
    ReportDetailsDocument,
    options
  )
}
export function useReportDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportDetailsQuery, ReportDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportDetailsQuery, ReportDetailsQueryVariables>(
    ReportDetailsDocument,
    options
  )
}
export function useReportDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReportDetailsQuery, ReportDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReportDetailsQuery, ReportDetailsQueryVariables>(
    ReportDetailsDocument,
    options
  )
}
export type ReportDetailsQueryHookResult = ReturnType<typeof useReportDetailsQuery>
export type ReportDetailsLazyQueryHookResult = ReturnType<typeof useReportDetailsLazyQuery>
export type ReportDetailsSuspenseQueryHookResult = ReturnType<typeof useReportDetailsSuspenseQuery>
export type ReportDetailsQueryResult = ApolloReactCommon.QueryResult<
  ReportDetailsQuery,
  ReportDetailsQueryVariables
>
export const ReportCreationInfoDocument = gql`
  query ReportCreationInfo($organizationId: ID!) {
    solarAssetList(input: { organizationId: $organizationId, page: 1, perPage: 1000 }) {
      ...ReportSolarAssetListData
    }
    scenarioList(input: { organizationId: $organizationId, page: 1, perPage: 1000 }) {
      ...ReportScenarioListData
    }
  }
  ${ReportSolarAssetListDataFragmentDoc}
  ${ReportScenarioListDataFragmentDoc}
`

/**
 * __useReportCreationInfoQuery__
 *
 * To run a query within a React component, call `useReportCreationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCreationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCreationInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useReportCreationInfoQuery(
  baseOptions: Apollo.QueryHookOptions<ReportCreationInfoQuery, ReportCreationInfoQueryVariables> &
    ({ variables: ReportCreationInfoQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportCreationInfoQuery, ReportCreationInfoQueryVariables>(
    ReportCreationInfoDocument,
    options
  )
}
export function useReportCreationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportCreationInfoQuery,
    ReportCreationInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportCreationInfoQuery, ReportCreationInfoQueryVariables>(
    ReportCreationInfoDocument,
    options
  )
}
export function useReportCreationInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ReportCreationInfoQuery,
    ReportCreationInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReportCreationInfoQuery, ReportCreationInfoQueryVariables>(
    ReportCreationInfoDocument,
    options
  )
}
export type ReportCreationInfoQueryHookResult = ReturnType<typeof useReportCreationInfoQuery>
export type ReportCreationInfoLazyQueryHookResult = ReturnType<
  typeof useReportCreationInfoLazyQuery
>
export type ReportCreationInfoSuspenseQueryHookResult = ReturnType<
  typeof useReportCreationInfoSuspenseQuery
>
export type ReportCreationInfoQueryResult = ApolloReactCommon.QueryResult<
  ReportCreationInfoQuery,
  ReportCreationInfoQueryVariables
>
export const ReportSharingInformationDocument = gql`
  query ReportSharingInformation($reportInput: QueryEntityInput!) {
    report(input: $reportInput) {
      ...ReportSharingData
    }
  }
  ${ReportSharingDataFragmentDoc}
`

/**
 * __useReportSharingInformationQuery__
 *
 * To run a query within a React component, call `useReportSharingInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSharingInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSharingInformationQuery({
 *   variables: {
 *      reportInput: // value for 'reportInput'
 *   },
 * });
 */
export function useReportSharingInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportSharingInformationQuery,
    ReportSharingInformationQueryVariables
  > &
    ({ variables: ReportSharingInformationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportSharingInformationQuery, ReportSharingInformationQueryVariables>(
    ReportSharingInformationDocument,
    options
  )
}
export function useReportSharingInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportSharingInformationQuery,
    ReportSharingInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportSharingInformationQuery, ReportSharingInformationQueryVariables>(
    ReportSharingInformationDocument,
    options
  )
}
export function useReportSharingInformationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ReportSharingInformationQuery,
    ReportSharingInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ReportSharingInformationQuery,
    ReportSharingInformationQueryVariables
  >(ReportSharingInformationDocument, options)
}
export type ReportSharingInformationQueryHookResult = ReturnType<
  typeof useReportSharingInformationQuery
>
export type ReportSharingInformationLazyQueryHookResult = ReturnType<
  typeof useReportSharingInformationLazyQuery
>
export type ReportSharingInformationSuspenseQueryHookResult = ReturnType<
  typeof useReportSharingInformationSuspenseQuery
>
export type ReportSharingInformationQueryResult = ApolloReactCommon.QueryResult<
  ReportSharingInformationQuery,
  ReportSharingInformationQueryVariables
>
export const ReportTopNavDocument = gql`
  query ReportTopNav($input: QueryEntityInput!) {
    report(input: $input) {
      ...ReportCoreData
      sharedWith
    }
  }
  ${ReportCoreDataFragmentDoc}
`

/**
 * __useReportTopNavQuery__
 *
 * To run a query within a React component, call `useReportTopNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTopNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTopNavQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTopNavQuery(
  baseOptions: Apollo.QueryHookOptions<ReportTopNavQuery, ReportTopNavQueryVariables> &
    ({ variables: ReportTopNavQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportTopNavQuery, ReportTopNavQueryVariables>(
    ReportTopNavDocument,
    options
  )
}
export function useReportTopNavLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportTopNavQuery, ReportTopNavQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportTopNavQuery, ReportTopNavQueryVariables>(
    ReportTopNavDocument,
    options
  )
}
export function useReportTopNavSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReportTopNavQuery, ReportTopNavQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReportTopNavQuery, ReportTopNavQueryVariables>(
    ReportTopNavDocument,
    options
  )
}
export type ReportTopNavQueryHookResult = ReturnType<typeof useReportTopNavQuery>
export type ReportTopNavLazyQueryHookResult = ReturnType<typeof useReportTopNavLazyQuery>
export type ReportTopNavSuspenseQueryHookResult = ReturnType<typeof useReportTopNavSuspenseQuery>
export type ReportTopNavQueryResult = ApolloReactCommon.QueryResult<
  ReportTopNavQuery,
  ReportTopNavQueryVariables
>
export const CreateScenarioDocument = gql`
  mutation CreateScenario($input: CreateScenarioInput!, $queryInput: GetAssumptionListInput!) {
    scenario {
      create(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...ScenarioCrudData
          }
        }
        query {
          scenarioAssumptions {
            ...AssumptionOptionsBaseQuery
          }
        }
      }
    }
  }
  ${ScenarioCrudDataFragmentDoc}
  ${AssumptionOptionsBaseQueryFragmentDoc}
`
export type CreateScenarioMutationFn = ApolloReactCommon.MutationFunction<
  CreateScenarioMutation,
  CreateScenarioMutationVariables
>

/**
 * __useCreateScenarioMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMutation, { data, loading, error }] = useCreateScenarioMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useCreateScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateScenarioMutation, CreateScenarioMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateScenarioMutation, CreateScenarioMutationVariables>(
    CreateScenarioDocument,
    options
  )
}
export type CreateScenarioMutationHookResult = ReturnType<typeof useCreateScenarioMutation>
export type CreateScenarioMutationResult = ApolloReactCommon.MutationResult<CreateScenarioMutation>
export type CreateScenarioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateScenarioMutation,
  CreateScenarioMutationVariables
>
export const UpdateScenarioDocument = gql`
  mutation UpdateScenario($input: UpdateScenarioInput!, $queryInput: GetAssumptionListInput!) {
    scenario {
      update(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...ScenarioCrudData
          }
        }
        query {
          scenarioAssumptions {
            ...AssumptionOptionsBaseQuery
          }
        }
      }
    }
  }
  ${ScenarioCrudDataFragmentDoc}
  ${AssumptionOptionsBaseQueryFragmentDoc}
`
export type UpdateScenarioMutationFn = ApolloReactCommon.MutationFunction<
  UpdateScenarioMutation,
  UpdateScenarioMutationVariables
>

/**
 * __useUpdateScenarioMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioMutation, { data, loading, error }] = useUpdateScenarioMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useUpdateScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(
    UpdateScenarioDocument,
    options
  )
}
export type UpdateScenarioMutationHookResult = ReturnType<typeof useUpdateScenarioMutation>
export type UpdateScenarioMutationResult = ApolloReactCommon.MutationResult<UpdateScenarioMutation>
export type UpdateScenarioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateScenarioMutation,
  UpdateScenarioMutationVariables
>
export const DeleteScenarioDocument = gql`
  mutation DeleteScenario($input: DeleteScenarioInput!, $queryInput: ScenarioListInput!) {
    scenario {
      delete(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...ScenarioCrudData
          }
        }
        query {
          scenarioList(input: $queryInput) {
            pageInfo {
              ...PaginationMetadata
            }
            records {
              ...ScenarioCoreData
            }
          }
        }
      }
    }
  }
  ${ScenarioCrudDataFragmentDoc}
  ${PaginationMetadataFragmentDoc}
  ${ScenarioCoreDataFragmentDoc}
`
export type DeleteScenarioMutationFn = ApolloReactCommon.MutationFunction<
  DeleteScenarioMutation,
  DeleteScenarioMutationVariables
>

/**
 * __useDeleteScenarioMutation__
 *
 * To run a mutation, you first call `useDeleteScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenarioMutation, { data, loading, error }] = useDeleteScenarioMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteScenarioMutation, DeleteScenarioMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteScenarioMutation, DeleteScenarioMutationVariables>(
    DeleteScenarioDocument,
    options
  )
}
export type DeleteScenarioMutationHookResult = ReturnType<typeof useDeleteScenarioMutation>
export type DeleteScenarioMutationResult = ApolloReactCommon.MutationResult<DeleteScenarioMutation>
export type DeleteScenarioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteScenarioMutation,
  DeleteScenarioMutationVariables
>
export const DeleteScenariosDocument = gql`
  mutation DeleteScenarios($input: DeleteManyScenariosInput!, $queryInput: ScenarioListInput!) {
    scenarios {
      deleteMany(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...ScenarioCrudData
          }
        }
        query {
          scenarioList(input: $queryInput) {
            pageInfo {
              ...PaginationMetadata
            }
            records {
              ...ScenarioCoreData
            }
          }
        }
      }
    }
  }
  ${ScenarioCrudDataFragmentDoc}
  ${PaginationMetadataFragmentDoc}
  ${ScenarioCoreDataFragmentDoc}
`
export type DeleteScenariosMutationFn = ApolloReactCommon.MutationFunction<
  DeleteScenariosMutation,
  DeleteScenariosMutationVariables
>

/**
 * __useDeleteScenariosMutation__
 *
 * To run a mutation, you first call `useDeleteScenariosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenariosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenariosMutation, { data, loading, error }] = useDeleteScenariosMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteScenariosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScenariosMutation,
    DeleteScenariosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteScenariosMutation, DeleteScenariosMutationVariables>(
    DeleteScenariosDocument,
    options
  )
}
export type DeleteScenariosMutationHookResult = ReturnType<typeof useDeleteScenariosMutation>
export type DeleteScenariosMutationResult =
  ApolloReactCommon.MutationResult<DeleteScenariosMutation>
export type DeleteScenariosMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteScenariosMutation,
  DeleteScenariosMutationVariables
>
export const RenameScenarioDocument = gql`
  mutation RenameScenario($id: ID!, $name: String!, $workspaceId: ID!) {
    scenario {
      update(input: { id: $id, workspaceId: $workspaceId, data: { name: $name } }) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            id
            name
          }
        }
      }
    }
  }
`
export type RenameScenarioMutationFn = ApolloReactCommon.MutationFunction<
  RenameScenarioMutation,
  RenameScenarioMutationVariables
>

/**
 * __useRenameScenarioMutation__
 *
 * To run a mutation, you first call `useRenameScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameScenarioMutation, { data, loading, error }] = useRenameScenarioMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useRenameScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<RenameScenarioMutation, RenameScenarioMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RenameScenarioMutation, RenameScenarioMutationVariables>(
    RenameScenarioDocument,
    options
  )
}
export type RenameScenarioMutationHookResult = ReturnType<typeof useRenameScenarioMutation>
export type RenameScenarioMutationResult = ApolloReactCommon.MutationResult<RenameScenarioMutation>
export type RenameScenarioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenameScenarioMutation,
  RenameScenarioMutationVariables
>
export const ScenarioDocument = gql`
  query Scenario($input: QueryEntityInput!) {
    scenario(input: $input) {
      ...ScenarioCrudData
    }
  }
  ${ScenarioCrudDataFragmentDoc}
`

/**
 * __useScenarioQuery__
 *
 * To run a query within a React component, call `useScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenarioQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScenarioQuery(
  baseOptions: Apollo.QueryHookOptions<ScenarioQuery, ScenarioQueryVariables> &
    ({ variables: ScenarioQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ScenarioQuery, ScenarioQueryVariables>(ScenarioDocument, options)
}
export function useScenarioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScenarioQuery, ScenarioQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ScenarioQuery, ScenarioQueryVariables>(ScenarioDocument, options)
}
export function useScenarioSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ScenarioQuery, ScenarioQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ScenarioQuery, ScenarioQueryVariables>(ScenarioDocument, options)
}
export type ScenarioQueryHookResult = ReturnType<typeof useScenarioQuery>
export type ScenarioLazyQueryHookResult = ReturnType<typeof useScenarioLazyQuery>
export type ScenarioSuspenseQueryHookResult = ReturnType<typeof useScenarioSuspenseQuery>
export type ScenarioQueryResult = ApolloReactCommon.QueryResult<
  ScenarioQuery,
  ScenarioQueryVariables
>
export const ScenariosDocument = gql`
  query Scenarios($input: ScenarioListInput!, $filter: ScenarioFilter, $sort: ScenarioSort) {
    scenarioList(input: $input, filter: $filter, sort: $sort) {
      records {
        ...ScenarioCoreData
      }
      pageInfo {
        ...PaginationMetadata
      }
      queryVariables {
        filter {
          text
        }
        input {
          page
          perPage
        }
        sort
      }
    }
  }
  ${ScenarioCoreDataFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`

/**
 * __useScenariosQuery__
 *
 * To run a query within a React component, call `useScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenariosQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useScenariosQuery(
  baseOptions: Apollo.QueryHookOptions<ScenariosQuery, ScenariosQueryVariables> &
    ({ variables: ScenariosQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ScenariosQuery, ScenariosQueryVariables>(ScenariosDocument, options)
}
export function useScenariosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScenariosQuery, ScenariosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ScenariosQuery, ScenariosQueryVariables>(ScenariosDocument, options)
}
export function useScenariosSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ScenariosQuery, ScenariosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ScenariosQuery, ScenariosQueryVariables>(
    ScenariosDocument,
    options
  )
}
export type ScenariosQueryHookResult = ReturnType<typeof useScenariosQuery>
export type ScenariosLazyQueryHookResult = ReturnType<typeof useScenariosLazyQuery>
export type ScenariosSuspenseQueryHookResult = ReturnType<typeof useScenariosSuspenseQuery>
export type ScenariosQueryResult = ApolloReactCommon.QueryResult<
  ScenariosQuery,
  ScenariosQueryVariables
>
export const CreateSolarAssetDocument = gql`
  mutation CreateSolarAsset($input: CreateSolarAssetInput!) {
    solarAsset {
      create(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
          ... on SolarAssetAlreadyExistsProblem {
            message
            type
            name
          }
        }
        data {
          recordId
          record {
            ...SolarAssetEntity
          }
        }
      }
    }
  }
  ${SolarAssetEntityFragmentDoc}
`
export type CreateSolarAssetMutationFn = ApolloReactCommon.MutationFunction<
  CreateSolarAssetMutation,
  CreateSolarAssetMutationVariables
>

/**
 * __useCreateSolarAssetMutation__
 *
 * To run a mutation, you first call `useCreateSolarAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSolarAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSolarAssetMutation, { data, loading, error }] = useCreateSolarAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSolarAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSolarAssetMutation,
    CreateSolarAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSolarAssetMutation, CreateSolarAssetMutationVariables>(
    CreateSolarAssetDocument,
    options
  )
}
export type CreateSolarAssetMutationHookResult = ReturnType<typeof useCreateSolarAssetMutation>
export type CreateSolarAssetMutationResult =
  ApolloReactCommon.MutationResult<CreateSolarAssetMutation>
export type CreateSolarAssetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSolarAssetMutation,
  CreateSolarAssetMutationVariables
>
export const UpdateSolarAssetDocument = gql`
  mutation UpdateSolarAsset($input: UpdateSolarAssetInput!) {
    solarAsset {
      update(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
          ... on SolarAssetPropertiesCannotBeModifiedProblem {
            message
          }
          ... on SolarAssetAlreadyExistsProblem {
            message
            type
            name
          }
        }
        data {
          recordId
          record {
            ...SolarAssetEntity
          }
        }
      }
    }
  }
  ${SolarAssetEntityFragmentDoc}
`
export type UpdateSolarAssetMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSolarAssetMutation,
  UpdateSolarAssetMutationVariables
>

/**
 * __useUpdateSolarAssetMutation__
 *
 * To run a mutation, you first call `useUpdateSolarAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSolarAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSolarAssetMutation, { data, loading, error }] = useUpdateSolarAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSolarAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSolarAssetMutation,
    UpdateSolarAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSolarAssetMutation, UpdateSolarAssetMutationVariables>(
    UpdateSolarAssetDocument,
    options
  )
}
export type UpdateSolarAssetMutationHookResult = ReturnType<typeof useUpdateSolarAssetMutation>
export type UpdateSolarAssetMutationResult =
  ApolloReactCommon.MutationResult<UpdateSolarAssetMutation>
export type UpdateSolarAssetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSolarAssetMutation,
  UpdateSolarAssetMutationVariables
>
export const DeleteSolarAssetDocument = gql`
  mutation DeleteSolarAsset($input: DeleteSolarAssetInput!) {
    solarAsset {
      delete(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...SolarAssetEntity
          }
        }
      }
    }
  }
  ${SolarAssetEntityFragmentDoc}
`
export type DeleteSolarAssetMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSolarAssetMutation,
  DeleteSolarAssetMutationVariables
>

/**
 * __useDeleteSolarAssetMutation__
 *
 * To run a mutation, you first call `useDeleteSolarAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSolarAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSolarAssetMutation, { data, loading, error }] = useDeleteSolarAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSolarAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSolarAssetMutation,
    DeleteSolarAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSolarAssetMutation, DeleteSolarAssetMutationVariables>(
    DeleteSolarAssetDocument,
    options
  )
}
export type DeleteSolarAssetMutationHookResult = ReturnType<typeof useDeleteSolarAssetMutation>
export type DeleteSolarAssetMutationResult =
  ApolloReactCommon.MutationResult<DeleteSolarAssetMutation>
export type DeleteSolarAssetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSolarAssetMutation,
  DeleteSolarAssetMutationVariables
>
export const DeleteSolarAssetsDocument = gql`
  mutation DeleteSolarAssets(
    $input: DeleteManySolarAssetsInput!
    $queryInput: SolarAssetListInput!
  ) {
    solarAssets {
      deleteMany(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          record {
            ...SolarAssetEntity
          }
          recordId
        }
        query {
          solarAssetList(input: $queryInput) {
            records {
              ...SolarAssetTableRecord
            }
            pageInfo {
              ...PaginationMetadata
            }
          }
        }
      }
    }
  }
  ${SolarAssetEntityFragmentDoc}
  ${SolarAssetTableRecordFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`
export type DeleteSolarAssetsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSolarAssetsMutation,
  DeleteSolarAssetsMutationVariables
>

/**
 * __useDeleteSolarAssetsMutation__
 *
 * To run a mutation, you first call `useDeleteSolarAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSolarAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSolarAssetsMutation, { data, loading, error }] = useDeleteSolarAssetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteSolarAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSolarAssetsMutation,
    DeleteSolarAssetsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSolarAssetsMutation, DeleteSolarAssetsMutationVariables>(
    DeleteSolarAssetsDocument,
    options
  )
}
export type DeleteSolarAssetsMutationHookResult = ReturnType<typeof useDeleteSolarAssetsMutation>
export type DeleteSolarAssetsMutationResult =
  ApolloReactCommon.MutationResult<DeleteSolarAssetsMutation>
export type DeleteSolarAssetsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSolarAssetsMutation,
  DeleteSolarAssetsMutationVariables
>
export const RenameSolarAssetDocument = gql`
  mutation RenameSolarAsset($id: ID!, $organizationId: ID!, $name: String!) {
    solarAsset {
      update(input: { id: $id, organizationId: $organizationId, data: { name: $name } }) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            id
            name
          }
        }
      }
    }
  }
`
export type RenameSolarAssetMutationFn = ApolloReactCommon.MutationFunction<
  RenameSolarAssetMutation,
  RenameSolarAssetMutationVariables
>

/**
 * __useRenameSolarAssetMutation__
 *
 * To run a mutation, you first call `useRenameSolarAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameSolarAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameSolarAssetMutation, { data, loading, error }] = useRenameSolarAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameSolarAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameSolarAssetMutation,
    RenameSolarAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RenameSolarAssetMutation, RenameSolarAssetMutationVariables>(
    RenameSolarAssetDocument,
    options
  )
}
export type RenameSolarAssetMutationHookResult = ReturnType<typeof useRenameSolarAssetMutation>
export type RenameSolarAssetMutationResult =
  ApolloReactCommon.MutationResult<RenameSolarAssetMutation>
export type RenameSolarAssetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenameSolarAssetMutation,
  RenameSolarAssetMutationVariables
>
export const AddSolarAssetsToPpaDocument = gql`
  mutation AddSolarAssetsToPpa($input: AddSolarAssetsPpaInput!) {
    solarAssets {
      addPpa(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            id
            name
            ppa {
              id
              name
            }
          }
        }
      }
    }
  }
`
export type AddSolarAssetsToPpaMutationFn = ApolloReactCommon.MutationFunction<
  AddSolarAssetsToPpaMutation,
  AddSolarAssetsToPpaMutationVariables
>

/**
 * __useAddSolarAssetsToPpaMutation__
 *
 * To run a mutation, you first call `useAddSolarAssetsToPpaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSolarAssetsToPpaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSolarAssetsToPpaMutation, { data, loading, error }] = useAddSolarAssetsToPpaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSolarAssetsToPpaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSolarAssetsToPpaMutation,
    AddSolarAssetsToPpaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddSolarAssetsToPpaMutation, AddSolarAssetsToPpaMutationVariables>(
    AddSolarAssetsToPpaDocument,
    options
  )
}
export type AddSolarAssetsToPpaMutationHookResult = ReturnType<
  typeof useAddSolarAssetsToPpaMutation
>
export type AddSolarAssetsToPpaMutationResult =
  ApolloReactCommon.MutationResult<AddSolarAssetsToPpaMutation>
export type AddSolarAssetsToPpaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSolarAssetsToPpaMutation,
  AddSolarAssetsToPpaMutationVariables
>
export const CopySolarAssetDocument = gql`
  mutation CopySolarAsset($input: CopySolarAssetInput!) {
    solarAsset {
      copy(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
        }
      }
    }
  }
`
export type CopySolarAssetMutationFn = ApolloReactCommon.MutationFunction<
  CopySolarAssetMutation,
  CopySolarAssetMutationVariables
>

/**
 * __useCopySolarAssetMutation__
 *
 * To run a mutation, you first call `useCopySolarAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySolarAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySolarAssetMutation, { data, loading, error }] = useCopySolarAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopySolarAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<CopySolarAssetMutation, CopySolarAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CopySolarAssetMutation, CopySolarAssetMutationVariables>(
    CopySolarAssetDocument,
    options
  )
}
export type CopySolarAssetMutationHookResult = ReturnType<typeof useCopySolarAssetMutation>
export type CopySolarAssetMutationResult = ApolloReactCommon.MutationResult<CopySolarAssetMutation>
export type CopySolarAssetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CopySolarAssetMutation,
  CopySolarAssetMutationVariables
>
export const ModifyAssetPlanDocument = gql`
  mutation ModifyAssetPlan($input: ModifySolarAssetPlanInput!, $start: Time!, $end: Time!) {
    solarAsset {
      modifyAssetPlan(input: $input) {
        data {
          record {
            operations {
              solarGenerationForecast(start: $start, end: $end) {
                startTime
                endTime
                interval
                data
              }
            }
            id
          }
          recordId
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on SolarAssetPlanDoesNotMatchProblem {
            message
          }
        }
        success
      }
    }
  }
`
export type ModifyAssetPlanMutationFn = ApolloReactCommon.MutationFunction<
  ModifyAssetPlanMutation,
  ModifyAssetPlanMutationVariables
>

/**
 * __useModifyAssetPlanMutation__
 *
 * To run a mutation, you first call `useModifyAssetPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyAssetPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyAssetPlanMutation, { data, loading, error }] = useModifyAssetPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useModifyAssetPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifyAssetPlanMutation,
    ModifyAssetPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ModifyAssetPlanMutation, ModifyAssetPlanMutationVariables>(
    ModifyAssetPlanDocument,
    options
  )
}
export type ModifyAssetPlanMutationHookResult = ReturnType<typeof useModifyAssetPlanMutation>
export type ModifyAssetPlanMutationResult =
  ApolloReactCommon.MutationResult<ModifyAssetPlanMutation>
export type ModifyAssetPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ModifyAssetPlanMutation,
  ModifyAssetPlanMutationVariables
>
export const TriggerSolarAssetSimulationDocument = gql`
  mutation TriggerSolarAssetSimulation($input: TriggerSolarAssetSimulationInput!) {
    solarAsset {
      triggerSimulation(input: $input) {
        success
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        data {
          recordId
        }
      }
    }
  }
`
export type TriggerSolarAssetSimulationMutationFn = ApolloReactCommon.MutationFunction<
  TriggerSolarAssetSimulationMutation,
  TriggerSolarAssetSimulationMutationVariables
>

/**
 * __useTriggerSolarAssetSimulationMutation__
 *
 * To run a mutation, you first call `useTriggerSolarAssetSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerSolarAssetSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerSolarAssetSimulationMutation, { data, loading, error }] = useTriggerSolarAssetSimulationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerSolarAssetSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerSolarAssetSimulationMutation,
    TriggerSolarAssetSimulationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TriggerSolarAssetSimulationMutation,
    TriggerSolarAssetSimulationMutationVariables
  >(TriggerSolarAssetSimulationDocument, options)
}
export type TriggerSolarAssetSimulationMutationHookResult = ReturnType<
  typeof useTriggerSolarAssetSimulationMutation
>
export type TriggerSolarAssetSimulationMutationResult =
  ApolloReactCommon.MutationResult<TriggerSolarAssetSimulationMutation>
export type TriggerSolarAssetSimulationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TriggerSolarAssetSimulationMutation,
  TriggerSolarAssetSimulationMutationVariables
>
export const SolarAssetDocument = gql`
  query SolarAsset($input: SolarAssetInput!) {
    solarAsset(input: $input) {
      ...SolarAssetEntity
    }
  }
  ${SolarAssetEntityFragmentDoc}
`

/**
 * __useSolarAssetQuery__
 *
 * To run a query within a React component, call `useSolarAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolarAssetQuery(
  baseOptions: Apollo.QueryHookOptions<SolarAssetQuery, SolarAssetQueryVariables> &
    ({ variables: SolarAssetQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SolarAssetQuery, SolarAssetQueryVariables>(SolarAssetDocument, options)
}
export function useSolarAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SolarAssetQuery, SolarAssetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SolarAssetQuery, SolarAssetQueryVariables>(SolarAssetDocument, options)
}
export function useSolarAssetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SolarAssetQuery, SolarAssetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SolarAssetQuery, SolarAssetQueryVariables>(
    SolarAssetDocument,
    options
  )
}
export type SolarAssetQueryHookResult = ReturnType<typeof useSolarAssetQuery>
export type SolarAssetLazyQueryHookResult = ReturnType<typeof useSolarAssetLazyQuery>
export type SolarAssetSuspenseQueryHookResult = ReturnType<typeof useSolarAssetSuspenseQuery>
export type SolarAssetQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetQuery,
  SolarAssetQueryVariables
>
export const SolarAssetsDocument = gql`
  query SolarAssets($input: SolarAssetListInput!) {
    solarAssetList(input: $input) {
      records {
        ...SolarAssetTableRecord
      }
      pageInfo {
        ...PaginationMetadata
      }
    }
  }
  ${SolarAssetTableRecordFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`

/**
 * __useSolarAssetsQuery__
 *
 * To run a query within a React component, call `useSolarAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolarAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<SolarAssetsQuery, SolarAssetsQueryVariables> &
    ({ variables: SolarAssetsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SolarAssetsQuery, SolarAssetsQueryVariables>(SolarAssetsDocument, options)
}
export function useSolarAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SolarAssetsQuery, SolarAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SolarAssetsQuery, SolarAssetsQueryVariables>(
    SolarAssetsDocument,
    options
  )
}
export function useSolarAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SolarAssetsQuery, SolarAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SolarAssetsQuery, SolarAssetsQueryVariables>(
    SolarAssetsDocument,
    options
  )
}
export type SolarAssetsQueryHookResult = ReturnType<typeof useSolarAssetsQuery>
export type SolarAssetsLazyQueryHookResult = ReturnType<typeof useSolarAssetsLazyQuery>
export type SolarAssetsSuspenseQueryHookResult = ReturnType<typeof useSolarAssetsSuspenseQuery>
export type SolarAssetsQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetsQuery,
  SolarAssetsQueryVariables
>
export const SolarAssetsForPpasDocument = gql`
  query SolarAssetsForPpas(
    $input: SolarAssetListInput!
    $filter: SolarAssetFilter
    $sort: SolarAssetSort
  ) {
    solarAssetList(input: $input, filter: $filter, sort: $sort) {
      records {
        ...SolarAssetTableRecord
      }
      pageInfo {
        ...PaginationMetadata
      }
    }
  }
  ${SolarAssetTableRecordFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`

/**
 * __useSolarAssetsForPpasQuery__
 *
 * To run a query within a React component, call `useSolarAssetsForPpasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetsForPpasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetsForPpasQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSolarAssetsForPpasQuery(
  baseOptions: Apollo.QueryHookOptions<SolarAssetsForPpasQuery, SolarAssetsForPpasQueryVariables> &
    ({ variables: SolarAssetsForPpasQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SolarAssetsForPpasQuery, SolarAssetsForPpasQueryVariables>(
    SolarAssetsForPpasDocument,
    options
  )
}
export function useSolarAssetsForPpasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SolarAssetsForPpasQuery,
    SolarAssetsForPpasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SolarAssetsForPpasQuery, SolarAssetsForPpasQueryVariables>(
    SolarAssetsForPpasDocument,
    options
  )
}
export function useSolarAssetsForPpasSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SolarAssetsForPpasQuery,
    SolarAssetsForPpasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SolarAssetsForPpasQuery, SolarAssetsForPpasQueryVariables>(
    SolarAssetsForPpasDocument,
    options
  )
}
export type SolarAssetsForPpasQueryHookResult = ReturnType<typeof useSolarAssetsForPpasQuery>
export type SolarAssetsForPpasLazyQueryHookResult = ReturnType<
  typeof useSolarAssetsForPpasLazyQuery
>
export type SolarAssetsForPpasSuspenseQueryHookResult = ReturnType<
  typeof useSolarAssetsForPpasSuspenseQuery
>
export type SolarAssetsForPpasQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetsForPpasQuery,
  SolarAssetsForPpasQueryVariables
>
export const SolarAssetBatteryChargeScheduleDocument = gql`
  query SolarAssetBatteryChargeSchedule($input: SolarAssetInput!, $start: Time!, $end: Time!) {
    solarAsset(input: $input) {
      ...SolarAssetIdData
      name
      operations {
        solarGenerationForecast(start: $start, end: $end) {
          data
          endTime
          interval
          startTime
        }
      }
      battery {
        isDeleted
        batteryChargeForecast(start: $start, end: $end) {
          data
          endTime
          interval
          startTime
        }
      }
      load {
        isDeleted
        demandForecast(start: $start, end: $end) {
          data
          endTime
          interval
          startTime
        }
      }
    }
  }
  ${SolarAssetIdDataFragmentDoc}
`

/**
 * __useSolarAssetBatteryChargeScheduleQuery__
 *
 * To run a query within a React component, call `useSolarAssetBatteryChargeScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetBatteryChargeScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetBatteryChargeScheduleQuery({
 *   variables: {
 *      input: // value for 'input'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useSolarAssetBatteryChargeScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<
    SolarAssetBatteryChargeScheduleQuery,
    SolarAssetBatteryChargeScheduleQueryVariables
  > &
    (
      | { variables: SolarAssetBatteryChargeScheduleQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SolarAssetBatteryChargeScheduleQuery,
    SolarAssetBatteryChargeScheduleQueryVariables
  >(SolarAssetBatteryChargeScheduleDocument, options)
}
export function useSolarAssetBatteryChargeScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SolarAssetBatteryChargeScheduleQuery,
    SolarAssetBatteryChargeScheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SolarAssetBatteryChargeScheduleQuery,
    SolarAssetBatteryChargeScheduleQueryVariables
  >(SolarAssetBatteryChargeScheduleDocument, options)
}
export function useSolarAssetBatteryChargeScheduleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SolarAssetBatteryChargeScheduleQuery,
    SolarAssetBatteryChargeScheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SolarAssetBatteryChargeScheduleQuery,
    SolarAssetBatteryChargeScheduleQueryVariables
  >(SolarAssetBatteryChargeScheduleDocument, options)
}
export type SolarAssetBatteryChargeScheduleQueryHookResult = ReturnType<
  typeof useSolarAssetBatteryChargeScheduleQuery
>
export type SolarAssetBatteryChargeScheduleLazyQueryHookResult = ReturnType<
  typeof useSolarAssetBatteryChargeScheduleLazyQuery
>
export type SolarAssetBatteryChargeScheduleSuspenseQueryHookResult = ReturnType<
  typeof useSolarAssetBatteryChargeScheduleSuspenseQuery
>
export type SolarAssetBatteryChargeScheduleQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetBatteryChargeScheduleQuery,
  SolarAssetBatteryChargeScheduleQueryVariables
>
export const SolarAssetBaseDocument = gql`
  query SolarAssetBase($input: SolarAssetInput!) {
    solarAsset(input: $input) {
      ...SolarAssetIdData
      name
      status
      updatedAt
      simulationStatus
      gridZone
      battery {
        id
        isDeleted
      }
      load {
        id
        isDeleted
      }
      simulationSummary {
        irr {
          p50
        }
      }
    }
  }
  ${SolarAssetIdDataFragmentDoc}
`

/**
 * __useSolarAssetBaseQuery__
 *
 * To run a query within a React component, call `useSolarAssetBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetBaseQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolarAssetBaseQuery(
  baseOptions: Apollo.QueryHookOptions<SolarAssetBaseQuery, SolarAssetBaseQueryVariables> &
    ({ variables: SolarAssetBaseQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SolarAssetBaseQuery, SolarAssetBaseQueryVariables>(
    SolarAssetBaseDocument,
    options
  )
}
export function useSolarAssetBaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SolarAssetBaseQuery, SolarAssetBaseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SolarAssetBaseQuery, SolarAssetBaseQueryVariables>(
    SolarAssetBaseDocument,
    options
  )
}
export function useSolarAssetBaseSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SolarAssetBaseQuery, SolarAssetBaseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SolarAssetBaseQuery, SolarAssetBaseQueryVariables>(
    SolarAssetBaseDocument,
    options
  )
}
export type SolarAssetBaseQueryHookResult = ReturnType<typeof useSolarAssetBaseQuery>
export type SolarAssetBaseLazyQueryHookResult = ReturnType<typeof useSolarAssetBaseLazyQuery>
export type SolarAssetBaseSuspenseQueryHookResult = ReturnType<
  typeof useSolarAssetBaseSuspenseQuery
>
export type SolarAssetBaseQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetBaseQuery,
  SolarAssetBaseQueryVariables
>
export const SolarAssetProjectDocument = gql`
  query SolarAssetProject($input: SolarAssetInput!) {
    solarAsset(input: $input) {
      ...SolarAssetIdData
      name
      status
      locationName
      latitude
      longitude
      gridZone
      utilityid
      spv {
        id
        legalEntityName {
          en
          ja
        }
      }
      ppa {
        id
        name
      }
      balancingGroup {
        id
        balancingGroupCode
      }
      macroScenario {
        id
        name
        isDefault
      }
      ppa {
        id
        name
        term
        type
        status
        pricingScheme
        startDate
      }
      subsidyScheme
      subsidyRate
      subsidyStartDate
      capexPayments {
        ...CapexPaymentData
      }
      cod
      dcCapacity
      acCapacity
      pvArray {
        moduleType
        tilt
        azimuth
      }
      lifetime
      inverterWarrantyPeriod
      battery {
        energyCapacity
        powerCapacity
        expectedCycleLife
        warrantyPeriod
        operationalStartDate
        warrantyPeriod
        isDeleted
      }
      load {
        id
        name
        status
        maximumLoad
        notes
        isDeleted
      }
      capexPayments {
        ...CapexPaymentData
      }
    }
  }
  ${SolarAssetIdDataFragmentDoc}
  ${CapexPaymentDataFragmentDoc}
`

/**
 * __useSolarAssetProjectQuery__
 *
 * To run a query within a React component, call `useSolarAssetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetProjectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolarAssetProjectQuery(
  baseOptions: Apollo.QueryHookOptions<SolarAssetProjectQuery, SolarAssetProjectQueryVariables> &
    ({ variables: SolarAssetProjectQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SolarAssetProjectQuery, SolarAssetProjectQueryVariables>(
    SolarAssetProjectDocument,
    options
  )
}
export function useSolarAssetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SolarAssetProjectQuery, SolarAssetProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SolarAssetProjectQuery, SolarAssetProjectQueryVariables>(
    SolarAssetProjectDocument,
    options
  )
}
export function useSolarAssetProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SolarAssetProjectQuery,
    SolarAssetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SolarAssetProjectQuery, SolarAssetProjectQueryVariables>(
    SolarAssetProjectDocument,
    options
  )
}
export type SolarAssetProjectQueryHookResult = ReturnType<typeof useSolarAssetProjectQuery>
export type SolarAssetProjectLazyQueryHookResult = ReturnType<typeof useSolarAssetProjectLazyQuery>
export type SolarAssetProjectSuspenseQueryHookResult = ReturnType<
  typeof useSolarAssetProjectSuspenseQuery
>
export type SolarAssetProjectQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetProjectQuery,
  SolarAssetProjectQueryVariables
>
export const SolarAssetSimulationDocument = gql`
  query SolarAssetSimulation($input: SolarAssetInput!) {
    solarAsset(input: $input) {
      ...SolarAssetIdData
      name
      status
      simulationSummary {
        solarYieldFirstYear
        solarYieldDistributionFirstYear {
          t
          v
        }
        temperatureLosses {
          p50
        }
      }
      annualDegradation
      acCableLosses
      shadingLosses
      inverterLosses
      downtimeRate
      capexPayments {
        ...CapexPaymentData
      }
      subsidyScheme
      simulationSummary {
        id
        lifetimeCurtailmentLosses
        opexTotalCostsMonthlySeries {
          ...SingleSeriesSet
        }
        opexTotalCostsYearlySeries {
          ...SingleSeriesSet
        }
        opexLifetimeTotal
        taxLifetimeTotal
        netGeneration {
          p50
        }
        irr {
          p50
        }
        netRevenue {
          p50
        }
        averageSubsidyRevenue
        lifetimeNetRevenue {
          p50
        }
        curtailmentMonthlySeries {
          ...SingleSeriesSet
        }
        curtailmentYearlySeries {
          ...SingleSeriesSet
        }
        netGeneration {
          p50
        }
        totalGeneration {
          p50
        }
        marketRevenueLifetimeTotal
        subsidyRevenueLifetimeTotal
        ppaCashflowLifetimeTotal
        totalLifetimeRevenue
        capexLifetimeTotal
        netCashflowLifetimeTotal
        netGenerationMonthlySeries {
          ...SingleSeriesSet
        }
        marketRevenueMonthlySeries {
          ...SingleSeriesSet
        }
        subsidyRevenueMonthlySeries {
          ...SingleSeriesSet
        }
        ppaCashflowMonthlySeries {
          ...SingleSeriesSet
        }
        capexMonthlySeries {
          ...SingleSeriesSet
        }
        operatingCashflowMonthlySeries {
          ...SingleSeriesSet
        }
        netCashflowMonthlySeries {
          ...SingleSeriesSet
        }
        cumulativeNetCashflowMonthlySeries {
          ...SingleSeriesSet
        }
        netGenerationYearlySeries {
          ...SingleSeriesSet
        }
        marketRevenueYearlySeries {
          ...SingleSeriesSet
        }
        subsidyRevenueYearlySeries {
          ...SingleSeriesSet
        }
        ppaCashflowYearlySeries {
          ...SingleSeriesSet
        }
        capexYearlySeries {
          ...SingleSeriesSet
        }
        operatingCashflowYearlySeries {
          ...SingleSeriesSet
        }
        netCashflowYearlySeries {
          ...SingleSeriesSet
        }
        cumulativeNetCashflowYearlySeries {
          ...SingleSeriesSet
        }
        assetManagementCostsMonthlySeries {
          ...SingleSeriesSet
        }
        assetManagementCostsYearlySeries {
          ...SingleSeriesSet
        }
        assetManagementLifetimeTotal
        decommissionReserveCostsMonthlySeries {
          ...SingleSeriesSet
        }
        decommissionReserveCostsYearlySeries {
          ...SingleSeriesSet
        }
        decommissionReserveLifetimeTotal
        hardwareReplacementCostsMonthlySeries {
          ...SingleSeriesSet
        }
        hardwareReplacementCostsYearlySeries {
          ...SingleSeriesSet
        }
        hardwareReplacementLifetimeTotal
        insuranceCostsMonthlySeries {
          ...SingleSeriesSet
        }
        insuranceCostsYearlySeries {
          ...SingleSeriesSet
        }
        insuranceLifetimeTotal
        landLeaseCostsMonthlySeries {
          ...SingleSeriesSet
        }
        landLeaseCostsYearlySeries {
          ...SingleSeriesSet
        }
        landLeaseLifetimeTotal
        omCostsMonthlySeries {
          ...SingleSeriesSet
        }
        omCostsYearlySeries {
          ...SingleSeriesSet
        }
        omLifetimeTotal
        otherCostsMonthlySeries {
          ...SingleSeriesSet
        }
        otherCostsYearlySeries {
          ...SingleSeriesSet
        }
        otherLifetimeTotal
        taxCostsMonthlySeries {
          ...SingleSeriesSet
        }
        taxCostsYearlySeries {
          ...SingleSeriesSet
        }
      }
    }
  }
  ${SolarAssetIdDataFragmentDoc}
  ${CapexPaymentDataFragmentDoc}
  ${SingleSeriesSetFragmentDoc}
`

/**
 * __useSolarAssetSimulationQuery__
 *
 * To run a query within a React component, call `useSolarAssetSimulationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetSimulationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetSimulationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolarAssetSimulationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SolarAssetSimulationQuery,
    SolarAssetSimulationQueryVariables
  > &
    ({ variables: SolarAssetSimulationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SolarAssetSimulationQuery, SolarAssetSimulationQueryVariables>(
    SolarAssetSimulationDocument,
    options
  )
}
export function useSolarAssetSimulationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SolarAssetSimulationQuery,
    SolarAssetSimulationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SolarAssetSimulationQuery, SolarAssetSimulationQueryVariables>(
    SolarAssetSimulationDocument,
    options
  )
}
export function useSolarAssetSimulationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SolarAssetSimulationQuery,
    SolarAssetSimulationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SolarAssetSimulationQuery, SolarAssetSimulationQueryVariables>(
    SolarAssetSimulationDocument,
    options
  )
}
export type SolarAssetSimulationQueryHookResult = ReturnType<typeof useSolarAssetSimulationQuery>
export type SolarAssetSimulationLazyQueryHookResult = ReturnType<
  typeof useSolarAssetSimulationLazyQuery
>
export type SolarAssetSimulationSuspenseQueryHookResult = ReturnType<
  typeof useSolarAssetSimulationSuspenseQuery
>
export type SolarAssetSimulationQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetSimulationQuery,
  SolarAssetSimulationQueryVariables
>
export const SolarAssetVisualOverviewDocument = gql`
  query SolarAssetVisualOverview($input: SolarAssetInput!) {
    solarAsset(input: $input) {
      ...SolarAssetIdData
      dcCapacity
      acCapacity
      operations {
        realTimeSolarGeneration
        realTimeTotalEnergyExported
      }
      battery {
        id
        energyCapacity
        powerCapacity
        totalCyclesUsed
        isDeleted
        liveStatus
        currentStateOfCharge
      }
      load {
        id
        maximumLoad
        minimumLoad
        isDeleted
        realTimeDemand
      }
    }
  }
  ${SolarAssetIdDataFragmentDoc}
`

/**
 * __useSolarAssetVisualOverviewQuery__
 *
 * To run a query within a React component, call `useSolarAssetVisualOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetVisualOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetVisualOverviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolarAssetVisualOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SolarAssetVisualOverviewQuery,
    SolarAssetVisualOverviewQueryVariables
  > &
    ({ variables: SolarAssetVisualOverviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SolarAssetVisualOverviewQuery, SolarAssetVisualOverviewQueryVariables>(
    SolarAssetVisualOverviewDocument,
    options
  )
}
export function useSolarAssetVisualOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SolarAssetVisualOverviewQuery,
    SolarAssetVisualOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SolarAssetVisualOverviewQuery, SolarAssetVisualOverviewQueryVariables>(
    SolarAssetVisualOverviewDocument,
    options
  )
}
export function useSolarAssetVisualOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SolarAssetVisualOverviewQuery,
    SolarAssetVisualOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SolarAssetVisualOverviewQuery,
    SolarAssetVisualOverviewQueryVariables
  >(SolarAssetVisualOverviewDocument, options)
}
export type SolarAssetVisualOverviewQueryHookResult = ReturnType<
  typeof useSolarAssetVisualOverviewQuery
>
export type SolarAssetVisualOverviewLazyQueryHookResult = ReturnType<
  typeof useSolarAssetVisualOverviewLazyQuery
>
export type SolarAssetVisualOverviewSuspenseQueryHookResult = ReturnType<
  typeof useSolarAssetVisualOverviewSuspenseQuery
>
export type SolarAssetVisualOverviewQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetVisualOverviewQuery,
  SolarAssetVisualOverviewQueryVariables
>
export const SolarAssetBatteryDashboardDocument = gql`
  query SolarAssetBatteryDashboard(
    $input: SolarAssetInput!
    $start: Time!
    $end: Time!
    $gridZone: GridZone!
    $limit: Int
  ) {
    solarAsset(input: $input) {
      ...SolarAssetIdData
      name
      operations {
        liveStatus
        curtailmentActuals(start: $start, end: $end) {
          ...HighPerformanceNullableNumberSeriesData
        }
        solarGenerationActuals(start: $start, end: $end) {
          ...HighPerformanceNullableNumberSeriesData
        }
        solarGenerationDayAheadForecast(start: $start, end: $end) {
          ...HighPerformanceNullableNumberSeriesData
        }
        totalEnergyExportedActuals(start: $start, end: $end) {
          ...HighPerformanceNullableNumberSeriesData
        }
        totalEnergyExportedForecast(start: $start, end: $end) {
          ...HighPerformanceNullableNumberSeriesData
        }
        alarms(limit: $limit) {
          __typename
          createdAt
          resolvedAt
          isResolved
          ... on SolarAssetOperationAlarmEventBatteryScheduledModified {
            metadata {
              actor {
                id
                firstName
                lastName
              }
              scheduleDate
            }
          }
        }
      }
      battery {
        id
        batteryChargeActuals(start: $start, end: $end) {
          ...HighPerformanceNullableNumberSeriesData
        }
        batteryChargeForecast(start: $start, end: $end) {
          ...HighPerformanceNullableNumberSeriesData
        }
        liveStatus
        totalEnergyDischarged
        warrantyCycleLimit
        totalCyclesUsed
        isDeleted
      }
      load {
        id
        isDeleted
        demandActuals(start: $start, end: $end) {
          ...HighPerformanceNullableNumberSeriesData
        }
        demandForecast(start: $start, end: $end) {
          ...HighPerformanceNullableNumberSeriesData
        }
      }
    }
    marketIntelligence {
      dayAhead {
        zonePriceSeries(input: { start: $start, end: $end, zone: $gridZone }) {
          ...HighPerformanceNullableNumberSeriesData
        }
      }
      imbalance {
        zonePriceSeries(input: { start: $start, end: $end, zone: $gridZone }) {
          ...HighPerformanceNullableNumberSeriesData
        }
      }
      priceForecast {
        zoneDayAheadPriceSeries(input: { start: $start, end: $end, zone: $gridZone }) {
          ...HighPerformanceNullableNumberSeriesData
        }
      }
    }
  }
  ${SolarAssetIdDataFragmentDoc}
  ${HighPerformanceNullableNumberSeriesDataFragmentDoc}
`

/**
 * __useSolarAssetBatteryDashboardQuery__
 *
 * To run a query within a React component, call `useSolarAssetBatteryDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolarAssetBatteryDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolarAssetBatteryDashboardQuery({
 *   variables: {
 *      input: // value for 'input'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      gridZone: // value for 'gridZone'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSolarAssetBatteryDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    SolarAssetBatteryDashboardQuery,
    SolarAssetBatteryDashboardQueryVariables
  > &
    ({ variables: SolarAssetBatteryDashboardQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SolarAssetBatteryDashboardQuery, SolarAssetBatteryDashboardQueryVariables>(
    SolarAssetBatteryDashboardDocument,
    options
  )
}
export function useSolarAssetBatteryDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SolarAssetBatteryDashboardQuery,
    SolarAssetBatteryDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SolarAssetBatteryDashboardQuery,
    SolarAssetBatteryDashboardQueryVariables
  >(SolarAssetBatteryDashboardDocument, options)
}
export function useSolarAssetBatteryDashboardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SolarAssetBatteryDashboardQuery,
    SolarAssetBatteryDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SolarAssetBatteryDashboardQuery,
    SolarAssetBatteryDashboardQueryVariables
  >(SolarAssetBatteryDashboardDocument, options)
}
export type SolarAssetBatteryDashboardQueryHookResult = ReturnType<
  typeof useSolarAssetBatteryDashboardQuery
>
export type SolarAssetBatteryDashboardLazyQueryHookResult = ReturnType<
  typeof useSolarAssetBatteryDashboardLazyQuery
>
export type SolarAssetBatteryDashboardSuspenseQueryHookResult = ReturnType<
  typeof useSolarAssetBatteryDashboardSuspenseQuery
>
export type SolarAssetBatteryDashboardQueryResult = ApolloReactCommon.QueryResult<
  SolarAssetBatteryDashboardQuery,
  SolarAssetBatteryDashboardQueryVariables
>
export const CreateSpvDocument = gql`
  mutation CreateSpv($input: CreateSpvInput!) {
    spv {
      create(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
          ... on ResourceIsAlreadyInUseProblem {
            message
            recordId
          }
          ... on SpvResourceDoesNotExistProblem {
            message
            recordId
            type
          }
        }
        data {
          recordId
          record {
            id
            legalEntityName {
              ja
              en
            }
          }
        }
      }
    }
  }
`
export type CreateSpvMutationFn = ApolloReactCommon.MutationFunction<
  CreateSpvMutation,
  CreateSpvMutationVariables
>

/**
 * __useCreateSpvMutation__
 *
 * To run a mutation, you first call `useCreateSpvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpvMutation, { data, loading, error }] = useCreateSpvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpvMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSpvMutation, CreateSpvMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSpvMutation, CreateSpvMutationVariables>(
    CreateSpvDocument,
    options
  )
}
export type CreateSpvMutationHookResult = ReturnType<typeof useCreateSpvMutation>
export type CreateSpvMutationResult = ApolloReactCommon.MutationResult<CreateSpvMutation>
export type CreateSpvMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSpvMutation,
  CreateSpvMutationVariables
>
export const UpdateSpvDocument = gql`
  mutation UpdateSpv($input: UpdateSpvInput!) {
    spv {
      update(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
          ... on ResourceIsAlreadyInUseProblem {
            message
            recordId
          }
          ... on SpvResourceDoesNotExistProblem {
            message
            recordId
            type
          }
          ... on SpvLoanValidationProblem {
            message
            recordId
            record {
              ...SpvLoanData
            }
          }
          ... on SpvInvestmentValidationProblem {
            message
            recordId
            record {
              ...SpvInvestmentData
            }
          }
        }
        data {
          recordId
          record {
            ...SpvCrudData
          }
        }
      }
    }
  }
  ${SpvLoanDataFragmentDoc}
  ${SpvInvestmentDataFragmentDoc}
  ${SpvCrudDataFragmentDoc}
`
export type UpdateSpvMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSpvMutation,
  UpdateSpvMutationVariables
>

/**
 * __useUpdateSpvMutation__
 *
 * To run a mutation, you first call `useUpdateSpvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpvMutation, { data, loading, error }] = useUpdateSpvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpvMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSpvMutation, UpdateSpvMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSpvMutation, UpdateSpvMutationVariables>(
    UpdateSpvDocument,
    options
  )
}
export type UpdateSpvMutationHookResult = ReturnType<typeof useUpdateSpvMutation>
export type UpdateSpvMutationResult = ApolloReactCommon.MutationResult<UpdateSpvMutation>
export type UpdateSpvMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSpvMutation,
  UpdateSpvMutationVariables
>
export const DeleteSpvDocument = gql`
  mutation DeleteSpv($input: DeleteSpvInput!, $queryInput: SpvListInput!) {
    spv {
      delete(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data {
          recordId
          record {
            ...SpvsListData
          }
        }
        query {
          spvList(input: $queryInput) {
            pageInfo {
              ...PaginationMetadata
            }
            records {
              ...SpvsListData
            }
          }
        }
      }
    }
  }
  ${SpvsListDataFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`
export type DeleteSpvMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSpvMutation,
  DeleteSpvMutationVariables
>

/**
 * __useDeleteSpvMutation__
 *
 * To run a mutation, you first call `useDeleteSpvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpvMutation, { data, loading, error }] = useDeleteSpvMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteSpvMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSpvMutation, DeleteSpvMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSpvMutation, DeleteSpvMutationVariables>(
    DeleteSpvDocument,
    options
  )
}
export type DeleteSpvMutationHookResult = ReturnType<typeof useDeleteSpvMutation>
export type DeleteSpvMutationResult = ApolloReactCommon.MutationResult<DeleteSpvMutation>
export type DeleteSpvMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSpvMutation,
  DeleteSpvMutationVariables
>
export const GenerateFinancialStatementUrlDocument = gql`
  mutation GenerateFinancialStatementUrl($input: GenerateFinancialStatementInput!) {
    spv {
      generateFinancialStatement(input: $input) {
        success
        errors {
          ... on ProblemInterface {
            message
          }
        }
        data
      }
    }
  }
`
export type GenerateFinancialStatementUrlMutationFn = ApolloReactCommon.MutationFunction<
  GenerateFinancialStatementUrlMutation,
  GenerateFinancialStatementUrlMutationVariables
>

/**
 * __useGenerateFinancialStatementUrlMutation__
 *
 * To run a mutation, you first call `useGenerateFinancialStatementUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFinancialStatementUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFinancialStatementUrlMutation, { data, loading, error }] = useGenerateFinancialStatementUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateFinancialStatementUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateFinancialStatementUrlMutation,
    GenerateFinancialStatementUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GenerateFinancialStatementUrlMutation,
    GenerateFinancialStatementUrlMutationVariables
  >(GenerateFinancialStatementUrlDocument, options)
}
export type GenerateFinancialStatementUrlMutationHookResult = ReturnType<
  typeof useGenerateFinancialStatementUrlMutation
>
export type GenerateFinancialStatementUrlMutationResult =
  ApolloReactCommon.MutationResult<GenerateFinancialStatementUrlMutation>
export type GenerateFinancialStatementUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateFinancialStatementUrlMutation,
  GenerateFinancialStatementUrlMutationVariables
>
export const AddAssetsToSpvDocument = gql`
  mutation AddAssetsToSpv(
    $addAssetsInput: AddAssetsToSpvInput!
    $spvQueryInput: QueryEntityInput!
    $solarAssetQueryInput: SolarAssetListInput!
    $solarAssetFilter: SolarAssetFilter!
  ) {
    spv {
      addAssets(input: $addAssetsInput) {
        data {
          recordId
          record {
            legalEntityName {
              ja
              en
            }
            assets {
              id
            }
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on ResourceIsAlreadyInUseProblem {
            message
            recordId
          }
        }
        success
        query {
          spv(input: $spvQueryInput) {
            ...SpvOverviewQueryData
          }
          solarAssetList(input: $solarAssetQueryInput, filter: $solarAssetFilter) {
            records {
              ...SpvAssetData
            }
          }
        }
      }
    }
  }
  ${SpvOverviewQueryDataFragmentDoc}
  ${SpvAssetDataFragmentDoc}
`
export type AddAssetsToSpvMutationFn = ApolloReactCommon.MutationFunction<
  AddAssetsToSpvMutation,
  AddAssetsToSpvMutationVariables
>

/**
 * __useAddAssetsToSpvMutation__
 *
 * To run a mutation, you first call `useAddAssetsToSpvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetsToSpvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetsToSpvMutation, { data, loading, error }] = useAddAssetsToSpvMutation({
 *   variables: {
 *      addAssetsInput: // value for 'addAssetsInput'
 *      spvQueryInput: // value for 'spvQueryInput'
 *      solarAssetQueryInput: // value for 'solarAssetQueryInput'
 *      solarAssetFilter: // value for 'solarAssetFilter'
 *   },
 * });
 */
export function useAddAssetsToSpvMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAssetsToSpvMutation, AddAssetsToSpvMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddAssetsToSpvMutation, AddAssetsToSpvMutationVariables>(
    AddAssetsToSpvDocument,
    options
  )
}
export type AddAssetsToSpvMutationHookResult = ReturnType<typeof useAddAssetsToSpvMutation>
export type AddAssetsToSpvMutationResult = ApolloReactCommon.MutationResult<AddAssetsToSpvMutation>
export type AddAssetsToSpvMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddAssetsToSpvMutation,
  AddAssetsToSpvMutationVariables
>
export const RemoveAssetsFromSpvDocument = gql`
  mutation RemoveAssetsFromSpv($input: RemoveAssetsFromSpvInput!, $queryInput: QueryEntityInput!) {
    spv {
      removeAssets(input: $input) {
        data {
          recordId
          record {
            id
            legalEntityName {
              ja
              en
            }
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        query {
          spv(input: $queryInput) {
            id
            totalAssets
            assets {
              ...SpvAssetData
            }
          }
        }
      }
    }
  }
  ${SpvAssetDataFragmentDoc}
`
export type RemoveAssetsFromSpvMutationFn = ApolloReactCommon.MutationFunction<
  RemoveAssetsFromSpvMutation,
  RemoveAssetsFromSpvMutationVariables
>

/**
 * __useRemoveAssetsFromSpvMutation__
 *
 * To run a mutation, you first call `useRemoveAssetsFromSpvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssetsFromSpvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssetsFromSpvMutation, { data, loading, error }] = useRemoveAssetsFromSpvMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useRemoveAssetsFromSpvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAssetsFromSpvMutation,
    RemoveAssetsFromSpvMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveAssetsFromSpvMutation, RemoveAssetsFromSpvMutationVariables>(
    RemoveAssetsFromSpvDocument,
    options
  )
}
export type RemoveAssetsFromSpvMutationHookResult = ReturnType<
  typeof useRemoveAssetsFromSpvMutation
>
export type RemoveAssetsFromSpvMutationResult =
  ApolloReactCommon.MutationResult<RemoveAssetsFromSpvMutation>
export type RemoveAssetsFromSpvMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveAssetsFromSpvMutation,
  RemoveAssetsFromSpvMutationVariables
>
export const SpvsDocument = gql`
  query Spvs($input: SpvListInput!) {
    spvList(input: $input) {
      pageInfo {
        ...PaginationMetadata
      }
      records {
        ...SpvsListData
      }
    }
  }
  ${PaginationMetadataFragmentDoc}
  ${SpvsListDataFragmentDoc}
`

/**
 * __useSpvsQuery__
 *
 * To run a query within a React component, call `useSpvsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpvsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpvsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpvsQuery(
  baseOptions: Apollo.QueryHookOptions<SpvsQuery, SpvsQueryVariables> &
    ({ variables: SpvsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpvsQuery, SpvsQueryVariables>(SpvsDocument, options)
}
export function useSpvsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SpvsQuery, SpvsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpvsQuery, SpvsQueryVariables>(SpvsDocument, options)
}
export function useSpvsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SpvsQuery, SpvsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SpvsQuery, SpvsQueryVariables>(SpvsDocument, options)
}
export type SpvsQueryHookResult = ReturnType<typeof useSpvsQuery>
export type SpvsLazyQueryHookResult = ReturnType<typeof useSpvsLazyQuery>
export type SpvsSuspenseQueryHookResult = ReturnType<typeof useSpvsSuspenseQuery>
export type SpvsQueryResult = ApolloReactCommon.QueryResult<SpvsQuery, SpvsQueryVariables>
export const SpvsNameListDocument = gql`
  query SpvsNameList($input: SpvListInput!) {
    spvList(input: $input) {
      records {
        id
        legalEntityName {
          ja
        }
      }
    }
  }
`

/**
 * __useSpvsNameListQuery__
 *
 * To run a query within a React component, call `useSpvsNameListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpvsNameListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpvsNameListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpvsNameListQuery(
  baseOptions: Apollo.QueryHookOptions<SpvsNameListQuery, SpvsNameListQueryVariables> &
    ({ variables: SpvsNameListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpvsNameListQuery, SpvsNameListQueryVariables>(
    SpvsNameListDocument,
    options
  )
}
export function useSpvsNameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SpvsNameListQuery, SpvsNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpvsNameListQuery, SpvsNameListQueryVariables>(
    SpvsNameListDocument,
    options
  )
}
export function useSpvsNameListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SpvsNameListQuery, SpvsNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SpvsNameListQuery, SpvsNameListQueryVariables>(
    SpvsNameListDocument,
    options
  )
}
export type SpvsNameListQueryHookResult = ReturnType<typeof useSpvsNameListQuery>
export type SpvsNameListLazyQueryHookResult = ReturnType<typeof useSpvsNameListLazyQuery>
export type SpvsNameListSuspenseQueryHookResult = ReturnType<typeof useSpvsNameListSuspenseQuery>
export type SpvsNameListQueryResult = ApolloReactCommon.QueryResult<
  SpvsNameListQuery,
  SpvsNameListQueryVariables
>
export const SpvOverviewDocument = gql`
  query SpvOverview($input: QueryEntityInput!) {
    spv(input: $input) {
      ...SpvOverviewQueryData
    }
  }
  ${SpvOverviewQueryDataFragmentDoc}
`

/**
 * __useSpvOverviewQuery__
 *
 * To run a query within a React component, call `useSpvOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpvOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpvOverviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpvOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<SpvOverviewQuery, SpvOverviewQueryVariables> &
    ({ variables: SpvOverviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpvOverviewQuery, SpvOverviewQueryVariables>(SpvOverviewDocument, options)
}
export function useSpvOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SpvOverviewQuery, SpvOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpvOverviewQuery, SpvOverviewQueryVariables>(
    SpvOverviewDocument,
    options
  )
}
export function useSpvOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SpvOverviewQuery, SpvOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SpvOverviewQuery, SpvOverviewQueryVariables>(
    SpvOverviewDocument,
    options
  )
}
export type SpvOverviewQueryHookResult = ReturnType<typeof useSpvOverviewQuery>
export type SpvOverviewLazyQueryHookResult = ReturnType<typeof useSpvOverviewLazyQuery>
export type SpvOverviewSuspenseQueryHookResult = ReturnType<typeof useSpvOverviewSuspenseQuery>
export type SpvOverviewQueryResult = ApolloReactCommon.QueryResult<
  SpvOverviewQuery,
  SpvOverviewQueryVariables
>
export const SpvAvailableAssetsDocument = gql`
  query SpvAvailableAssets($input: SolarAssetListInput!, $filter: SolarAssetFilter!) {
    solarAssetList(input: $input, filter: $filter) {
      records {
        ...SpvAssetData
      }
    }
  }
  ${SpvAssetDataFragmentDoc}
`

/**
 * __useSpvAvailableAssetsQuery__
 *
 * To run a query within a React component, call `useSpvAvailableAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpvAvailableAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpvAvailableAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSpvAvailableAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<SpvAvailableAssetsQuery, SpvAvailableAssetsQueryVariables> &
    ({ variables: SpvAvailableAssetsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpvAvailableAssetsQuery, SpvAvailableAssetsQueryVariables>(
    SpvAvailableAssetsDocument,
    options
  )
}
export function useSpvAvailableAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpvAvailableAssetsQuery,
    SpvAvailableAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpvAvailableAssetsQuery, SpvAvailableAssetsQueryVariables>(
    SpvAvailableAssetsDocument,
    options
  )
}
export function useSpvAvailableAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SpvAvailableAssetsQuery,
    SpvAvailableAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SpvAvailableAssetsQuery, SpvAvailableAssetsQueryVariables>(
    SpvAvailableAssetsDocument,
    options
  )
}
export type SpvAvailableAssetsQueryHookResult = ReturnType<typeof useSpvAvailableAssetsQuery>
export type SpvAvailableAssetsLazyQueryHookResult = ReturnType<
  typeof useSpvAvailableAssetsLazyQuery
>
export type SpvAvailableAssetsSuspenseQueryHookResult = ReturnType<
  typeof useSpvAvailableAssetsSuspenseQuery
>
export type SpvAvailableAssetsQueryResult = ApolloReactCommon.QueryResult<
  SpvAvailableAssetsQuery,
  SpvAvailableAssetsQueryVariables
>
export const PpaAvailableAssetsDocument = gql`
  query PpaAvailableAssets($input: SolarAssetListInput!, $filter: SolarAssetFilter!) {
    solarAssetList(input: $input, filter: $filter) {
      records {
        ...PpaAssetData
      }
    }
  }
  ${PpaAssetDataFragmentDoc}
`

/**
 * __usePpaAvailableAssetsQuery__
 *
 * To run a query within a React component, call `usePpaAvailableAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePpaAvailableAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePpaAvailableAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePpaAvailableAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<PpaAvailableAssetsQuery, PpaAvailableAssetsQueryVariables> &
    ({ variables: PpaAvailableAssetsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PpaAvailableAssetsQuery, PpaAvailableAssetsQueryVariables>(
    PpaAvailableAssetsDocument,
    options
  )
}
export function usePpaAvailableAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PpaAvailableAssetsQuery,
    PpaAvailableAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PpaAvailableAssetsQuery, PpaAvailableAssetsQueryVariables>(
    PpaAvailableAssetsDocument,
    options
  )
}
export function usePpaAvailableAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PpaAvailableAssetsQuery,
    PpaAvailableAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PpaAvailableAssetsQuery, PpaAvailableAssetsQueryVariables>(
    PpaAvailableAssetsDocument,
    options
  )
}
export type PpaAvailableAssetsQueryHookResult = ReturnType<typeof usePpaAvailableAssetsQuery>
export type PpaAvailableAssetsLazyQueryHookResult = ReturnType<
  typeof usePpaAvailableAssetsLazyQuery
>
export type PpaAvailableAssetsSuspenseQueryHookResult = ReturnType<
  typeof usePpaAvailableAssetsSuspenseQuery
>
export type PpaAvailableAssetsQueryResult = ApolloReactCommon.QueryResult<
  PpaAvailableAssetsQuery,
  PpaAvailableAssetsQueryVariables
>
export const SpvEditViewDataDocument = gql`
  query SpvEditViewData($input: QueryEntityInput!) {
    spv(input: $input) {
      ...SpvCrudData
    }
  }
  ${SpvCrudDataFragmentDoc}
`

/**
 * __useSpvEditViewDataQuery__
 *
 * To run a query within a React component, call `useSpvEditViewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpvEditViewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpvEditViewDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpvEditViewDataQuery(
  baseOptions: Apollo.QueryHookOptions<SpvEditViewDataQuery, SpvEditViewDataQueryVariables> &
    ({ variables: SpvEditViewDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SpvEditViewDataQuery, SpvEditViewDataQueryVariables>(
    SpvEditViewDataDocument,
    options
  )
}
export function useSpvEditViewDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SpvEditViewDataQuery, SpvEditViewDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SpvEditViewDataQuery, SpvEditViewDataQueryVariables>(
    SpvEditViewDataDocument,
    options
  )
}
export function useSpvEditViewDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SpvEditViewDataQuery, SpvEditViewDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SpvEditViewDataQuery, SpvEditViewDataQueryVariables>(
    SpvEditViewDataDocument,
    options
  )
}
export type SpvEditViewDataQueryHookResult = ReturnType<typeof useSpvEditViewDataQuery>
export type SpvEditViewDataLazyQueryHookResult = ReturnType<typeof useSpvEditViewDataLazyQuery>
export type SpvEditViewDataSuspenseQueryHookResult = ReturnType<
  typeof useSpvEditViewDataSuspenseQuery
>
export type SpvEditViewDataQueryResult = ApolloReactCommon.QueryResult<
  SpvEditViewDataQuery,
  SpvEditViewDataQueryVariables
>
export const CreateThirdPartyCompanyDocument = gql`
  mutation CreateThirdPartyCompany($input: CreateThirdPartyCompanyInput!) {
    thirdPartyCompany {
      create(input: $input) {
        data {
          recordId
          record {
            ...ThirdPartyListItem
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceAlreadyExistsProblem {
            message
          }
        }
        success
      }
    }
  }
  ${ThirdPartyListItemFragmentDoc}
`
export type CreateThirdPartyCompanyMutationFn = ApolloReactCommon.MutationFunction<
  CreateThirdPartyCompanyMutation,
  CreateThirdPartyCompanyMutationVariables
>

/**
 * __useCreateThirdPartyCompanyMutation__
 *
 * To run a mutation, you first call `useCreateThirdPartyCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThirdPartyCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThirdPartyCompanyMutation, { data, loading, error }] = useCreateThirdPartyCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateThirdPartyCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateThirdPartyCompanyMutation,
    CreateThirdPartyCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateThirdPartyCompanyMutation,
    CreateThirdPartyCompanyMutationVariables
  >(CreateThirdPartyCompanyDocument, options)
}
export type CreateThirdPartyCompanyMutationHookResult = ReturnType<
  typeof useCreateThirdPartyCompanyMutation
>
export type CreateThirdPartyCompanyMutationResult =
  ApolloReactCommon.MutationResult<CreateThirdPartyCompanyMutation>
export type CreateThirdPartyCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateThirdPartyCompanyMutation,
  CreateThirdPartyCompanyMutationVariables
>
export const EditThirdPartyCompanyDocument = gql`
  mutation EditThirdPartyCompany($input: RenameThirdPartyCompanyInput!) {
    thirdPartyCompany {
      rename(input: $input) {
        data {
          record {
            ...ThirdPartyListItem
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
          }
        }
        success
      }
    }
  }
  ${ThirdPartyListItemFragmentDoc}
`
export type EditThirdPartyCompanyMutationFn = ApolloReactCommon.MutationFunction<
  EditThirdPartyCompanyMutation,
  EditThirdPartyCompanyMutationVariables
>

/**
 * __useEditThirdPartyCompanyMutation__
 *
 * To run a mutation, you first call `useEditThirdPartyCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditThirdPartyCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editThirdPartyCompanyMutation, { data, loading, error }] = useEditThirdPartyCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditThirdPartyCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditThirdPartyCompanyMutation,
    EditThirdPartyCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditThirdPartyCompanyMutation, EditThirdPartyCompanyMutationVariables>(
    EditThirdPartyCompanyDocument,
    options
  )
}
export type EditThirdPartyCompanyMutationHookResult = ReturnType<
  typeof useEditThirdPartyCompanyMutation
>
export type EditThirdPartyCompanyMutationResult =
  ApolloReactCommon.MutationResult<EditThirdPartyCompanyMutation>
export type EditThirdPartyCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditThirdPartyCompanyMutation,
  EditThirdPartyCompanyMutationVariables
>
export const DeleteThirdPartyCompanyDocument = gql`
  mutation DeleteThirdPartyCompany($input: DeleteThirdPartyCompanyInput!) {
    thirdPartyCompany {
      delete(input: $input) {
        data {
          record {
            id
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
          }
        }
        success
      }
    }
  }
`
export type DeleteThirdPartyCompanyMutationFn = ApolloReactCommon.MutationFunction<
  DeleteThirdPartyCompanyMutation,
  DeleteThirdPartyCompanyMutationVariables
>

/**
 * __useDeleteThirdPartyCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteThirdPartyCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThirdPartyCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThirdPartyCompanyMutation, { data, loading, error }] = useDeleteThirdPartyCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteThirdPartyCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteThirdPartyCompanyMutation,
    DeleteThirdPartyCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteThirdPartyCompanyMutation,
    DeleteThirdPartyCompanyMutationVariables
  >(DeleteThirdPartyCompanyDocument, options)
}
export type DeleteThirdPartyCompanyMutationHookResult = ReturnType<
  typeof useDeleteThirdPartyCompanyMutation
>
export type DeleteThirdPartyCompanyMutationResult =
  ApolloReactCommon.MutationResult<DeleteThirdPartyCompanyMutation>
export type DeleteThirdPartyCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteThirdPartyCompanyMutation,
  DeleteThirdPartyCompanyMutationVariables
>
export const DeleteManyThirdPartyCompaniesDocument = gql`
  mutation DeleteManyThirdPartyCompanies($input: DeleteManyThirdPartyCompaniesInput!) {
    thirdPartyCompanies {
      deleteMany(input: $input) {
        data {
          record {
            id
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
          }
        }
        success
      }
    }
  }
`
export type DeleteManyThirdPartyCompaniesMutationFn = ApolloReactCommon.MutationFunction<
  DeleteManyThirdPartyCompaniesMutation,
  DeleteManyThirdPartyCompaniesMutationVariables
>

/**
 * __useDeleteManyThirdPartyCompaniesMutation__
 *
 * To run a mutation, you first call `useDeleteManyThirdPartyCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyThirdPartyCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyThirdPartyCompaniesMutation, { data, loading, error }] = useDeleteManyThirdPartyCompaniesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyThirdPartyCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteManyThirdPartyCompaniesMutation,
    DeleteManyThirdPartyCompaniesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteManyThirdPartyCompaniesMutation,
    DeleteManyThirdPartyCompaniesMutationVariables
  >(DeleteManyThirdPartyCompaniesDocument, options)
}
export type DeleteManyThirdPartyCompaniesMutationHookResult = ReturnType<
  typeof useDeleteManyThirdPartyCompaniesMutation
>
export type DeleteManyThirdPartyCompaniesMutationResult =
  ApolloReactCommon.MutationResult<DeleteManyThirdPartyCompaniesMutation>
export type DeleteManyThirdPartyCompaniesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteManyThirdPartyCompaniesMutation,
  DeleteManyThirdPartyCompaniesMutationVariables
>
export const ThirdPartyCompaniesDocument = gql`
  query ThirdPartyCompanies($workspaceId: ID!, $filter: ThirdPartyCompanyFilter) {
    thirdPartyCompanyList(
      input: { page: 1, perPage: 1000, workspaceId: $workspaceId }
      filter: $filter
    ) {
      records {
        ...ThirdPartyListItem
      }
      pageInfo {
        ...PaginationMetadata
      }
    }
  }
  ${ThirdPartyListItemFragmentDoc}
  ${PaginationMetadataFragmentDoc}
`

/**
 * __useThirdPartyCompaniesQuery__
 *
 * To run a query within a React component, call `useThirdPartyCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThirdPartyCompaniesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useThirdPartyCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ThirdPartyCompaniesQuery,
    ThirdPartyCompaniesQueryVariables
  > &
    ({ variables: ThirdPartyCompaniesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ThirdPartyCompaniesQuery, ThirdPartyCompaniesQueryVariables>(
    ThirdPartyCompaniesDocument,
    options
  )
}
export function useThirdPartyCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThirdPartyCompaniesQuery,
    ThirdPartyCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ThirdPartyCompaniesQuery, ThirdPartyCompaniesQueryVariables>(
    ThirdPartyCompaniesDocument,
    options
  )
}
export function useThirdPartyCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ThirdPartyCompaniesQuery,
    ThirdPartyCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ThirdPartyCompaniesQuery, ThirdPartyCompaniesQueryVariables>(
    ThirdPartyCompaniesDocument,
    options
  )
}
export type ThirdPartyCompaniesQueryHookResult = ReturnType<typeof useThirdPartyCompaniesQuery>
export type ThirdPartyCompaniesLazyQueryHookResult = ReturnType<
  typeof useThirdPartyCompaniesLazyQuery
>
export type ThirdPartyCompaniesSuspenseQueryHookResult = ReturnType<
  typeof useThirdPartyCompaniesSuspenseQuery
>
export type ThirdPartyCompaniesQueryResult = ApolloReactCommon.QueryResult<
  ThirdPartyCompaniesQuery,
  ThirdPartyCompaniesQueryVariables
>
export const OpenCustomPositionDocument = gql`
  mutation OpenCustomPosition(
    $input: OpenCustomJepxPositionInput!
    $queryInput: JepxTradingInput!
  ) {
    jepxTrading {
      openCustomPosition(input: $input) {
        data {
          record {
            trades {
              price
              volume
              timeSlot
            }
          }
        }
        success
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on NotWithinTimeFrameProblem {
            message
          }
        }
        query {
          jepxTrading(input: $queryInput) {
            ...JepxTradingData
          }
        }
      }
    }
  }
  ${JepxTradingDataFragmentDoc}
`
export type OpenCustomPositionMutationFn = ApolloReactCommon.MutationFunction<
  OpenCustomPositionMutation,
  OpenCustomPositionMutationVariables
>

/**
 * __useOpenCustomPositionMutation__
 *
 * To run a mutation, you first call `useOpenCustomPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenCustomPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openCustomPositionMutation, { data, loading, error }] = useOpenCustomPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useOpenCustomPositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpenCustomPositionMutation,
    OpenCustomPositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<OpenCustomPositionMutation, OpenCustomPositionMutationVariables>(
    OpenCustomPositionDocument,
    options
  )
}
export type OpenCustomPositionMutationHookResult = ReturnType<typeof useOpenCustomPositionMutation>
export type OpenCustomPositionMutationResult =
  ApolloReactCommon.MutationResult<OpenCustomPositionMutation>
export type OpenCustomPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OpenCustomPositionMutation,
  OpenCustomPositionMutationVariables
>
export const OpenPlannedPositionDocument = gql`
  mutation OpenPlannedPosition(
    $input: OpenPlannedJepxPositionInput!
    $queryInput: JepxTradingInput!
  ) {
    jepxTrading {
      openPlannedPosition(input: $input) {
        data {
          record {
            trades {
              price
              volume
              timeSlot
            }
          }
        }
        success
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on NotWithinTimeFrameProblem {
            message
          }
        }
        query {
          jepxTrading(input: $queryInput) {
            ...JepxTradingData
          }
        }
      }
    }
  }
  ${JepxTradingDataFragmentDoc}
`
export type OpenPlannedPositionMutationFn = ApolloReactCommon.MutationFunction<
  OpenPlannedPositionMutation,
  OpenPlannedPositionMutationVariables
>

/**
 * __useOpenPlannedPositionMutation__
 *
 * To run a mutation, you first call `useOpenPlannedPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenPlannedPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openPlannedPositionMutation, { data, loading, error }] = useOpenPlannedPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useOpenPlannedPositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpenPlannedPositionMutation,
    OpenPlannedPositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<OpenPlannedPositionMutation, OpenPlannedPositionMutationVariables>(
    OpenPlannedPositionDocument,
    options
  )
}
export type OpenPlannedPositionMutationHookResult = ReturnType<
  typeof useOpenPlannedPositionMutation
>
export type OpenPlannedPositionMutationResult =
  ApolloReactCommon.MutationResult<OpenPlannedPositionMutation>
export type OpenPlannedPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OpenPlannedPositionMutation,
  OpenPlannedPositionMutationVariables
>
export const JepxDailyTradingConfirmTradeDocument = gql`
  mutation JepxDailyTradingConfirmTrade(
    $input: ConfirmJepxTradeInput!
    $queryInput: JepxTradingInput!
  ) {
    jepxTrading {
      confirmTrade(input: $input) {
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on NotWithinTimeFrameProblem {
            message
          }
        }
        success
        query {
          jepxTrading(input: $queryInput) {
            ...JepxTradingData
          }
        }
      }
    }
  }
  ${JepxTradingDataFragmentDoc}
`
export type JepxDailyTradingConfirmTradeMutationFn = ApolloReactCommon.MutationFunction<
  JepxDailyTradingConfirmTradeMutation,
  JepxDailyTradingConfirmTradeMutationVariables
>

/**
 * __useJepxDailyTradingConfirmTradeMutation__
 *
 * To run a mutation, you first call `useJepxDailyTradingConfirmTradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJepxDailyTradingConfirmTradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jepxDailyTradingConfirmTradeMutation, { data, loading, error }] = useJepxDailyTradingConfirmTradeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useJepxDailyTradingConfirmTradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JepxDailyTradingConfirmTradeMutation,
    JepxDailyTradingConfirmTradeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    JepxDailyTradingConfirmTradeMutation,
    JepxDailyTradingConfirmTradeMutationVariables
  >(JepxDailyTradingConfirmTradeDocument, options)
}
export type JepxDailyTradingConfirmTradeMutationHookResult = ReturnType<
  typeof useJepxDailyTradingConfirmTradeMutation
>
export type JepxDailyTradingConfirmTradeMutationResult =
  ApolloReactCommon.MutationResult<JepxDailyTradingConfirmTradeMutation>
export type JepxDailyTradingConfirmTradeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  JepxDailyTradingConfirmTradeMutation,
  JepxDailyTradingConfirmTradeMutationVariables
>
export const JepxDailyTradingDocument = gql`
  query JepxDailyTrading($input: JepxTradingInput!) {
    jepxTrading(input: $input) {
      ...JepxTradingData
    }
  }
  ${JepxTradingDataFragmentDoc}
`

/**
 * __useJepxDailyTradingQuery__
 *
 * To run a query within a React component, call `useJepxDailyTradingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJepxDailyTradingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJepxDailyTradingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJepxDailyTradingQuery(
  baseOptions: Apollo.QueryHookOptions<JepxDailyTradingQuery, JepxDailyTradingQueryVariables> &
    ({ variables: JepxDailyTradingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JepxDailyTradingQuery, JepxDailyTradingQueryVariables>(
    JepxDailyTradingDocument,
    options
  )
}
export function useJepxDailyTradingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JepxDailyTradingQuery, JepxDailyTradingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JepxDailyTradingQuery, JepxDailyTradingQueryVariables>(
    JepxDailyTradingDocument,
    options
  )
}
export function useJepxDailyTradingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    JepxDailyTradingQuery,
    JepxDailyTradingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JepxDailyTradingQuery, JepxDailyTradingQueryVariables>(
    JepxDailyTradingDocument,
    options
  )
}
export type JepxDailyTradingQueryHookResult = ReturnType<typeof useJepxDailyTradingQuery>
export type JepxDailyTradingLazyQueryHookResult = ReturnType<typeof useJepxDailyTradingLazyQuery>
export type JepxDailyTradingSuspenseQueryHookResult = ReturnType<
  typeof useJepxDailyTradingSuspenseQuery
>
export type JepxDailyTradingQueryResult = ApolloReactCommon.QueryResult<
  JepxDailyTradingQuery,
  JepxDailyTradingQueryVariables
>
export const JepxCustomTradeDocument = gql`
  query JepxCustomTrade($input: JepxTradingInput!) {
    jepxTrading(input: $input) {
      tradingZones {
        chubu {
          ...CustomTradeSetupData
        }
        chugoku {
          ...CustomTradeSetupData
        }
        hokkaido {
          ...CustomTradeSetupData
        }
        hokuriku {
          ...CustomTradeSetupData
        }
        kansai {
          ...CustomTradeSetupData
        }
        kyushu {
          ...CustomTradeSetupData
        }
        shikoku {
          ...CustomTradeSetupData
        }
        tohoku {
          ...CustomTradeSetupData
        }
        tokyo {
          ...CustomTradeSetupData
        }
      }
      date
    }
  }
  ${CustomTradeSetupDataFragmentDoc}
`

/**
 * __useJepxCustomTradeQuery__
 *
 * To run a query within a React component, call `useJepxCustomTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useJepxCustomTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJepxCustomTradeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJepxCustomTradeQuery(
  baseOptions: Apollo.QueryHookOptions<JepxCustomTradeQuery, JepxCustomTradeQueryVariables> &
    ({ variables: JepxCustomTradeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JepxCustomTradeQuery, JepxCustomTradeQueryVariables>(
    JepxCustomTradeDocument,
    options
  )
}
export function useJepxCustomTradeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JepxCustomTradeQuery, JepxCustomTradeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JepxCustomTradeQuery, JepxCustomTradeQueryVariables>(
    JepxCustomTradeDocument,
    options
  )
}
export function useJepxCustomTradeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<JepxCustomTradeQuery, JepxCustomTradeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JepxCustomTradeQuery, JepxCustomTradeQueryVariables>(
    JepxCustomTradeDocument,
    options
  )
}
export type JepxCustomTradeQueryHookResult = ReturnType<typeof useJepxCustomTradeQuery>
export type JepxCustomTradeLazyQueryHookResult = ReturnType<typeof useJepxCustomTradeLazyQuery>
export type JepxCustomTradeSuspenseQueryHookResult = ReturnType<
  typeof useJepxCustomTradeSuspenseQuery
>
export type JepxCustomTradeQueryResult = ApolloReactCommon.QueryResult<
  JepxCustomTradeQuery,
  JepxCustomTradeQueryVariables
>
export const JepxTradingHistoryDocument = gql`
  query JepxTradingHistory($input: JepxTradingHistoryInput!, $filter: JepxTradingHistoryFilter) {
    jepxTradingHistory(input: $input, filter: $filter) {
      id
      status
      actor {
        id
        email
        firstName
        lastName
        profilePicture {
          small
        }
        workspace {
          id
        }
      }
      bidType
      totalVolume
      gridZone
      createdAt
      date
    }
  }
`

/**
 * __useJepxTradingHistoryQuery__
 *
 * To run a query within a React component, call `useJepxTradingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJepxTradingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJepxTradingHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useJepxTradingHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<JepxTradingHistoryQuery, JepxTradingHistoryQueryVariables> &
    ({ variables: JepxTradingHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JepxTradingHistoryQuery, JepxTradingHistoryQueryVariables>(
    JepxTradingHistoryDocument,
    options
  )
}
export function useJepxTradingHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JepxTradingHistoryQuery,
    JepxTradingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JepxTradingHistoryQuery, JepxTradingHistoryQueryVariables>(
    JepxTradingHistoryDocument,
    options
  )
}
export function useJepxTradingHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    JepxTradingHistoryQuery,
    JepxTradingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JepxTradingHistoryQuery, JepxTradingHistoryQueryVariables>(
    JepxTradingHistoryDocument,
    options
  )
}
export type JepxTradingHistoryQueryHookResult = ReturnType<typeof useJepxTradingHistoryQuery>
export type JepxTradingHistoryLazyQueryHookResult = ReturnType<
  typeof useJepxTradingHistoryLazyQuery
>
export type JepxTradingHistorySuspenseQueryHookResult = ReturnType<
  typeof useJepxTradingHistorySuspenseQuery
>
export type JepxTradingHistoryQueryResult = ApolloReactCommon.QueryResult<
  JepxTradingHistoryQuery,
  JepxTradingHistoryQueryVariables
>
export const UpdateUserAccountNotificationPreferencesDocument = gql`
  mutation UpdateUserAccountNotificationPreferences($input: ChangeUserPreferencesInput!) {
    user {
      changePreferences(input: $input) {
        data {
          recordId
          record {
            ...UserNotificationPreferencesData
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
      }
    }
  }
  ${UserNotificationPreferencesDataFragmentDoc}
`
export type UpdateUserAccountNotificationPreferencesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserAccountNotificationPreferencesMutation,
  UpdateUserAccountNotificationPreferencesMutationVariables
>

/**
 * __useUpdateUserAccountNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountNotificationPreferencesMutation, { data, loading, error }] = useUpdateUserAccountNotificationPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAccountNotificationPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAccountNotificationPreferencesMutation,
    UpdateUserAccountNotificationPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserAccountNotificationPreferencesMutation,
    UpdateUserAccountNotificationPreferencesMutationVariables
  >(UpdateUserAccountNotificationPreferencesDocument, options)
}
export type UpdateUserAccountNotificationPreferencesMutationHookResult = ReturnType<
  typeof useUpdateUserAccountNotificationPreferencesMutation
>
export type UpdateUserAccountNotificationPreferencesMutationResult =
  ApolloReactCommon.MutationResult<UpdateUserAccountNotificationPreferencesMutation>
export type UpdateUserAccountNotificationPreferencesMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateUserAccountNotificationPreferencesMutation,
    UpdateUserAccountNotificationPreferencesMutationVariables
  >
export const UpdateUserLanguagePreferencesDocument = gql`
  mutation UpdateUserLanguagePreferences($id: ID!, $language: Language!) {
    userAccount {
      update(input: { id: $id, data: { language: $language } }) {
        data {
          recordId
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
      }
    }
  }
`
export type UpdateUserLanguagePreferencesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserLanguagePreferencesMutation,
  UpdateUserLanguagePreferencesMutationVariables
>

/**
 * __useUpdateUserLanguagePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserLanguagePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguagePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguagePreferencesMutation, { data, loading, error }] = useUpdateUserLanguagePreferencesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateUserLanguagePreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserLanguagePreferencesMutation,
    UpdateUserLanguagePreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserLanguagePreferencesMutation,
    UpdateUserLanguagePreferencesMutationVariables
  >(UpdateUserLanguagePreferencesDocument, options)
}
export type UpdateUserLanguagePreferencesMutationHookResult = ReturnType<
  typeof useUpdateUserLanguagePreferencesMutation
>
export type UpdateUserLanguagePreferencesMutationResult =
  ApolloReactCommon.MutationResult<UpdateUserLanguagePreferencesMutation>
export type UpdateUserLanguagePreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserLanguagePreferencesMutation,
  UpdateUserLanguagePreferencesMutationVariables
>
export const UpdateUserRoleDocument = gql`
  mutation UpdateUserRole($input: ChangeUserRoleInput!) {
    user {
      changeRole(input: $input) {
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        data {
          recordId
          record {
            ...UserCoreData
            email
          }
        }
      }
    }
  }
  ${UserCoreDataFragmentDoc}
`
export type UpdateUserRoleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(
    UpdateUserRoleDocument,
    options
  )
}
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>
export type UpdateUserRoleMutationResult = ApolloReactCommon.MutationResult<UpdateUserRoleMutation>
export type UpdateUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>
export const UpdateUserAccountDocument = gql`
  mutation UpdateUserAccount($input: UpdateUserAccountInput!) {
    userAccount {
      update(input: $input) {
        ...UserAccountData
        success
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
      }
    }
  }
  ${UserAccountDataFragmentDoc}
`
export type UpdateUserAccountMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAccountMutation,
    UpdateUserAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(
    UpdateUserAccountDocument,
    options
  )
}
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>
export type UpdateUserAccountMutationResult =
  ApolloReactCommon.MutationResult<UpdateUserAccountMutation>
export type UpdateUserAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>
export const UpdateUserProfilePictureDocument = gql`
  mutation UpdateUserProfilePicture($input: UploadProfilePictureInput!) {
    userAccount {
      uploadProfilePicture(input: $input) {
        success
        data {
          recordId
          record {
            id
            profilePicture {
              default
            }
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
      }
    }
  }
`
export type UpdateUserProfilePictureMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfilePictureMutation,
  UpdateUserProfilePictureMutationVariables
>

/**
 * __useUpdateUserProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfilePictureMutation, { data, loading, error }] = useUpdateUserProfilePictureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProfilePictureMutation,
    UpdateUserProfilePictureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserProfilePictureMutation,
    UpdateUserProfilePictureMutationVariables
  >(UpdateUserProfilePictureDocument, options)
}
export type UpdateUserProfilePictureMutationHookResult = ReturnType<
  typeof useUpdateUserProfilePictureMutation
>
export type UpdateUserProfilePictureMutationResult =
  ApolloReactCommon.MutationResult<UpdateUserProfilePictureMutation>
export type UpdateUserProfilePictureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfilePictureMutation,
  UpdateUserProfilePictureMutationVariables
>
export const RemoveUserProfilePictureDocument = gql`
  mutation RemoveUserProfilePicture($input: RemoveProfilePictureInput!) {
    userAccount {
      removeProfilePicture(input: $input) {
        success
        data {
          recordId
          record {
            id
            profilePicture {
              default
            }
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
      }
    }
  }
`
export type RemoveUserProfilePictureMutationFn = ApolloReactCommon.MutationFunction<
  RemoveUserProfilePictureMutation,
  RemoveUserProfilePictureMutationVariables
>

/**
 * __useRemoveUserProfilePictureMutation__
 *
 * To run a mutation, you first call `useRemoveUserProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserProfilePictureMutation, { data, loading, error }] = useRemoveUserProfilePictureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserProfilePictureMutation,
    RemoveUserProfilePictureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveUserProfilePictureMutation,
    RemoveUserProfilePictureMutationVariables
  >(RemoveUserProfilePictureDocument, options)
}
export type RemoveUserProfilePictureMutationHookResult = ReturnType<
  typeof useRemoveUserProfilePictureMutation
>
export type RemoveUserProfilePictureMutationResult =
  ApolloReactCommon.MutationResult<RemoveUserProfilePictureMutation>
export type RemoveUserProfilePictureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveUserProfilePictureMutation,
  RemoveUserProfilePictureMutationVariables
>
export const UpdateElectricalUnitPreferencesDocument = gql`
  mutation UpdateElectricalUnitPreferences($input: UpdateUserAccountInput!) {
    userAccount {
      update(input: $input) {
        data {
          recordId
          record {
            id
            preferences {
              electricalPowerUnit
            }
          }
        }
        success
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
      }
    }
  }
`
export type UpdateElectricalUnitPreferencesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateElectricalUnitPreferencesMutation,
  UpdateElectricalUnitPreferencesMutationVariables
>

/**
 * __useUpdateElectricalUnitPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateElectricalUnitPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateElectricalUnitPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateElectricalUnitPreferencesMutation, { data, loading, error }] = useUpdateElectricalUnitPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateElectricalUnitPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateElectricalUnitPreferencesMutation,
    UpdateElectricalUnitPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateElectricalUnitPreferencesMutation,
    UpdateElectricalUnitPreferencesMutationVariables
  >(UpdateElectricalUnitPreferencesDocument, options)
}
export type UpdateElectricalUnitPreferencesMutationHookResult = ReturnType<
  typeof useUpdateElectricalUnitPreferencesMutation
>
export type UpdateElectricalUnitPreferencesMutationResult =
  ApolloReactCommon.MutationResult<UpdateElectricalUnitPreferencesMutation>
export type UpdateElectricalUnitPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateElectricalUnitPreferencesMutation,
  UpdateElectricalUnitPreferencesMutationVariables
>
export const UpdateFileEncodingFormatDocument = gql`
  mutation UpdateFileEncodingFormat($id: ID!, $fileEncodingFormat: UserFileEncodingFormat!) {
    userAccount {
      update(input: { id: $id, data: { fileEncodingFormat: $fileEncodingFormat } }) {
        data {
          recordId
          record {
            id
            preferences {
              fileEncodingFormat
            }
          }
        }
        success
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
      }
    }
  }
`
export type UpdateFileEncodingFormatMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFileEncodingFormatMutation,
  UpdateFileEncodingFormatMutationVariables
>

/**
 * __useUpdateFileEncodingFormatMutation__
 *
 * To run a mutation, you first call `useUpdateFileEncodingFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileEncodingFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileEncodingFormatMutation, { data, loading, error }] = useUpdateFileEncodingFormatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileEncodingFormat: // value for 'fileEncodingFormat'
 *   },
 * });
 */
export function useUpdateFileEncodingFormatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFileEncodingFormatMutation,
    UpdateFileEncodingFormatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFileEncodingFormatMutation,
    UpdateFileEncodingFormatMutationVariables
  >(UpdateFileEncodingFormatDocument, options)
}
export type UpdateFileEncodingFormatMutationHookResult = ReturnType<
  typeof useUpdateFileEncodingFormatMutation
>
export type UpdateFileEncodingFormatMutationResult =
  ApolloReactCommon.MutationResult<UpdateFileEncodingFormatMutation>
export type UpdateFileEncodingFormatMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFileEncodingFormatMutation,
  UpdateFileEncodingFormatMutationVariables
>
export const RemoveUserDocument = gql`
  mutation RemoveUser($input: RemoveUserInput!) {
    user {
      remove(input: $input) {
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        data
      }
    }
  }
`
export type RemoveUserMutationFn = ApolloReactCommon.MutationFunction<
  RemoveUserMutation,
  RemoveUserMutationVariables
>

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(
    RemoveUserDocument,
    options
  )
}
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>
export type RemoveUserMutationResult = ApolloReactCommon.MutationResult<RemoveUserMutation>
export type RemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveUserMutation,
  RemoveUserMutationVariables
>
export const RequestCognitoEmailUpdateDocument = gql`
  mutation RequestCognitoEmailUpdate($input: RequestCognitoEmailUpdateInput) {
    user {
      requestCognitoEmailUpdate(input: $input) {
        data {
          email
        }
        errors {
          message
        }
        success
      }
    }
  }
`
export type RequestCognitoEmailUpdateMutationFn = ApolloReactCommon.MutationFunction<
  RequestCognitoEmailUpdateMutation,
  RequestCognitoEmailUpdateMutationVariables
>

/**
 * __useRequestCognitoEmailUpdateMutation__
 *
 * To run a mutation, you first call `useRequestCognitoEmailUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCognitoEmailUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCognitoEmailUpdateMutation, { data, loading, error }] = useRequestCognitoEmailUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestCognitoEmailUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestCognitoEmailUpdateMutation,
    RequestCognitoEmailUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestCognitoEmailUpdateMutation,
    RequestCognitoEmailUpdateMutationVariables
  >(RequestCognitoEmailUpdateDocument, options)
}
export type RequestCognitoEmailUpdateMutationHookResult = ReturnType<
  typeof useRequestCognitoEmailUpdateMutation
>
export type RequestCognitoEmailUpdateMutationResult =
  ApolloReactCommon.MutationResult<RequestCognitoEmailUpdateMutation>
export type RequestCognitoEmailUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestCognitoEmailUpdateMutation,
  RequestCognitoEmailUpdateMutationVariables
>
export const ConfirmCognitoEmailUpdateDocument = gql`
  mutation ConfirmCognitoEmailUpdate($input: ConfirmCognitoEmailUpdateInput) {
    user {
      confirmCognitoEmailUpdate(input: $input) {
        errors {
          message
        }
        query {
          currentUserAccount {
            ...CurrentUserAccountData
          }
        }
        success
      }
    }
  }
  ${CurrentUserAccountDataFragmentDoc}
`
export type ConfirmCognitoEmailUpdateMutationFn = ApolloReactCommon.MutationFunction<
  ConfirmCognitoEmailUpdateMutation,
  ConfirmCognitoEmailUpdateMutationVariables
>

/**
 * __useConfirmCognitoEmailUpdateMutation__
 *
 * To run a mutation, you first call `useConfirmCognitoEmailUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCognitoEmailUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCognitoEmailUpdateMutation, { data, loading, error }] = useConfirmCognitoEmailUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmCognitoEmailUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmCognitoEmailUpdateMutation,
    ConfirmCognitoEmailUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConfirmCognitoEmailUpdateMutation,
    ConfirmCognitoEmailUpdateMutationVariables
  >(ConfirmCognitoEmailUpdateDocument, options)
}
export type ConfirmCognitoEmailUpdateMutationHookResult = ReturnType<
  typeof useConfirmCognitoEmailUpdateMutation
>
export type ConfirmCognitoEmailUpdateMutationResult =
  ApolloReactCommon.MutationResult<ConfirmCognitoEmailUpdateMutation>
export type ConfirmCognitoEmailUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConfirmCognitoEmailUpdateMutation,
  ConfirmCognitoEmailUpdateMutationVariables
>
export const CurrentUserAccountDocument = gql`
  query CurrentUserAccount {
    currentUserAccount {
      ...CurrentUserAccountData
    }
  }
  ${CurrentUserAccountDataFragmentDoc}
`

/**
 * __useCurrentUserAccountQuery__
 *
 * To run a query within a React component, call `useCurrentUserAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserAccountQuery, CurrentUserAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserAccountQuery, CurrentUserAccountQueryVariables>(
    CurrentUserAccountDocument,
    options
  )
}
export function useCurrentUserAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserAccountQuery,
    CurrentUserAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserAccountQuery, CurrentUserAccountQueryVariables>(
    CurrentUserAccountDocument,
    options
  )
}
export function useCurrentUserAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentUserAccountQuery,
    CurrentUserAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CurrentUserAccountQuery, CurrentUserAccountQueryVariables>(
    CurrentUserAccountDocument,
    options
  )
}
export type CurrentUserAccountQueryHookResult = ReturnType<typeof useCurrentUserAccountQuery>
export type CurrentUserAccountLazyQueryHookResult = ReturnType<
  typeof useCurrentUserAccountLazyQuery
>
export type CurrentUserAccountSuspenseQueryHookResult = ReturnType<
  typeof useCurrentUserAccountSuspenseQuery
>
export type CurrentUserAccountQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserAccountQuery,
  CurrentUserAccountQueryVariables
>
export const CurrentUserAccountNotificationPreferencesDocument = gql`
  query CurrentUserAccountNotificationPreferences {
    currentUserAccount {
      users {
        ...UserNotificationPreferencesData
      }
    }
  }
  ${UserNotificationPreferencesDataFragmentDoc}
`

/**
 * __useCurrentUserAccountNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useCurrentUserAccountNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserAccountNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserAccountNotificationPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserAccountNotificationPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserAccountNotificationPreferencesQuery,
    CurrentUserAccountNotificationPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CurrentUserAccountNotificationPreferencesQuery,
    CurrentUserAccountNotificationPreferencesQueryVariables
  >(CurrentUserAccountNotificationPreferencesDocument, options)
}
export function useCurrentUserAccountNotificationPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserAccountNotificationPreferencesQuery,
    CurrentUserAccountNotificationPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CurrentUserAccountNotificationPreferencesQuery,
    CurrentUserAccountNotificationPreferencesQueryVariables
  >(CurrentUserAccountNotificationPreferencesDocument, options)
}
export function useCurrentUserAccountNotificationPreferencesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentUserAccountNotificationPreferencesQuery,
    CurrentUserAccountNotificationPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CurrentUserAccountNotificationPreferencesQuery,
    CurrentUserAccountNotificationPreferencesQueryVariables
  >(CurrentUserAccountNotificationPreferencesDocument, options)
}
export type CurrentUserAccountNotificationPreferencesQueryHookResult = ReturnType<
  typeof useCurrentUserAccountNotificationPreferencesQuery
>
export type CurrentUserAccountNotificationPreferencesLazyQueryHookResult = ReturnType<
  typeof useCurrentUserAccountNotificationPreferencesLazyQuery
>
export type CurrentUserAccountNotificationPreferencesSuspenseQueryHookResult = ReturnType<
  typeof useCurrentUserAccountNotificationPreferencesSuspenseQuery
>
export type CurrentUserAccountNotificationPreferencesQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserAccountNotificationPreferencesQuery,
  CurrentUserAccountNotificationPreferencesQueryVariables
>
export const CreateWorkspaceDocument = gql`
  mutation CreateWorkspace($input: CreateWorkspaceInputV2!) {
    workspace {
      createV2(input: $input) {
        data {
          record {
            id
            name
          }
        }
        errors {
          ... on WorkspaceAlreadyExistsProblem {
            message
            type
          }
          ... on ProblemInterface {
            message
          }
        }
        query {
          currentUserAccount {
            ...CurrentUserAccountData
          }
        }
        success
      }
    }
  }
  ${CurrentUserAccountDataFragmentDoc}
`
export type CreateWorkspaceMutationFn = ApolloReactCommon.MutationFunction<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(
    CreateWorkspaceDocument,
    options
  )
}
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>
export type CreateWorkspaceMutationResult =
  ApolloReactCommon.MutationResult<CreateWorkspaceMutation>
export type CreateWorkspaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>
export const UpdateWorkspaceDocument = gql`
  mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
    workspace {
      update(input: $input) {
        data {
          record {
            id
            name
          }
        }
        errors {
          ... on ProblemInterface {
            message
          }
        }
        success
      }
    }
  }
`
export type UpdateWorkspaceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceMutation,
    UpdateWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>(
    UpdateWorkspaceDocument,
    options
  )
}
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>
export type UpdateWorkspaceMutationResult =
  ApolloReactCommon.MutationResult<UpdateWorkspaceMutation>
export type UpdateWorkspaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>
export const UpdateWorkspaceOcctoSettingsDocument = gql`
  mutation UpdateWorkspaceOcctoSettings($input: UpdateWorkspaceInput!) {
    workspace {
      update(input: $input) {
        success
        errors {
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
          ... on AuthorizationProblem {
            message
          }
        }
        data {
          record {
            ...OcctoSettingsQueryData
          }
        }
      }
    }
  }
  ${OcctoSettingsQueryDataFragmentDoc}
`
export type UpdateWorkspaceOcctoSettingsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWorkspaceOcctoSettingsMutation,
  UpdateWorkspaceOcctoSettingsMutationVariables
>

/**
 * __useUpdateWorkspaceOcctoSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceOcctoSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceOcctoSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceOcctoSettingsMutation, { data, loading, error }] = useUpdateWorkspaceOcctoSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceOcctoSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceOcctoSettingsMutation,
    UpdateWorkspaceOcctoSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateWorkspaceOcctoSettingsMutation,
    UpdateWorkspaceOcctoSettingsMutationVariables
  >(UpdateWorkspaceOcctoSettingsDocument, options)
}
export type UpdateWorkspaceOcctoSettingsMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceOcctoSettingsMutation
>
export type UpdateWorkspaceOcctoSettingsMutationResult =
  ApolloReactCommon.MutationResult<UpdateWorkspaceOcctoSettingsMutation>
export type UpdateWorkspaceOcctoSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWorkspaceOcctoSettingsMutation,
  UpdateWorkspaceOcctoSettingsMutationVariables
>
export const AcceptWorkspaceInviteDocument = gql`
  mutation AcceptWorkspaceInvite($input: AcceptWorkspaceInviteInput!) {
    userAccount {
      acceptWorkspaceInvite(input: $input) {
        errors {
          ... on ProblemInterface {
            message
          }
        }
        success
      }
    }
  }
`
export type AcceptWorkspaceInviteMutationFn = ApolloReactCommon.MutationFunction<
  AcceptWorkspaceInviteMutation,
  AcceptWorkspaceInviteMutationVariables
>

/**
 * __useAcceptWorkspaceInviteMutation__
 *
 * To run a mutation, you first call `useAcceptWorkspaceInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptWorkspaceInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptWorkspaceInviteMutation, { data, loading, error }] = useAcceptWorkspaceInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptWorkspaceInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptWorkspaceInviteMutation,
    AcceptWorkspaceInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptWorkspaceInviteMutation, AcceptWorkspaceInviteMutationVariables>(
    AcceptWorkspaceInviteDocument,
    options
  )
}
export type AcceptWorkspaceInviteMutationHookResult = ReturnType<
  typeof useAcceptWorkspaceInviteMutation
>
export type AcceptWorkspaceInviteMutationResult =
  ApolloReactCommon.MutationResult<AcceptWorkspaceInviteMutation>
export type AcceptWorkspaceInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AcceptWorkspaceInviteMutation,
  AcceptWorkspaceInviteMutationVariables
>
export const DeleteApiKeyDocument = gql`
  mutation DeleteApiKey($input: DeleteWorkspaceApiKeyInput!, $queryInput: ID!) {
    workspace {
      deleteApiKey(input: $input) {
        data {
          record {
            id
            name
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        query {
          workspace(input: $queryInput) {
            ...ApiKeysQueryData
          }
        }
      }
    }
  }
  ${ApiKeysQueryDataFragmentDoc}
`
export type DeleteApiKeyMutationFn = ApolloReactCommon.MutationFunction<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(
    DeleteApiKeyDocument,
    options
  )
}
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>
export type DeleteApiKeyMutationResult = ApolloReactCommon.MutationResult<DeleteApiKeyMutation>
export type DeleteApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>
export const GenerateApiKeyDocument = gql`
  mutation GenerateApiKey($input: GenerateWorkspaceApiKeyInput!, $queryInput: ID!) {
    workspace {
      generateApiKey(input: $input) {
        data {
          record {
            id
            key
            name
            permissions
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceAlreadyExistsProblem {
            message
          }
        }
        success
        query {
          workspace(input: $queryInput) {
            ...ApiKeysQueryData
          }
        }
      }
    }
  }
  ${ApiKeysQueryDataFragmentDoc}
`
export type GenerateApiKeyMutationFn = ApolloReactCommon.MutationFunction<
  GenerateApiKeyMutation,
  GenerateApiKeyMutationVariables
>

/**
 * __useGenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useGenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiKeyMutation, { data, loading, error }] = useGenerateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useGenerateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>(
    GenerateApiKeyDocument,
    options
  )
}
export type GenerateApiKeyMutationHookResult = ReturnType<typeof useGenerateApiKeyMutation>
export type GenerateApiKeyMutationResult = ApolloReactCommon.MutationResult<GenerateApiKeyMutation>
export type GenerateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateApiKeyMutation,
  GenerateApiKeyMutationVariables
>
export const UpdateApiKeyDocument = gql`
  mutation UpdateApiKey($input: UpdateWorkspaceApiKeyInput!, $queryInput: ID!) {
    workspace {
      updateApiKey(input: $input) {
        data {
          record {
            id
            name
            permissions
          }
        }
        errors {
          ... on AuthorizationProblem {
            message
          }
          ... on ResourceAlreadyExistsProblem {
            message
          }
          ... on ResourceDoesNotExistProblem {
            message
            recordId
          }
        }
        success
        query {
          workspace(input: $queryInput) {
            ...ApiKeysQueryData
          }
        }
      }
    }
  }
  ${ApiKeysQueryDataFragmentDoc}
`
export type UpdateApiKeyMutationFn = ApolloReactCommon.MutationFunction<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>

/**
 * __useUpdateApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyMutation, { data, loading, error }] = useUpdateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queryInput: // value for 'queryInput'
 *   },
 * });
 */
export function useUpdateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>(
    UpdateApiKeyDocument,
    options
  )
}
export type UpdateApiKeyMutationHookResult = ReturnType<typeof useUpdateApiKeyMutation>
export type UpdateApiKeyMutationResult = ApolloReactCommon.MutationResult<UpdateApiKeyMutation>
export type UpdateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>
export const WorkspaceDefaultsDocument = gql`
  query WorkspaceDefaults($workspaceId: ID!) {
    balancingGroupList(input: { workspaceId: $workspaceId }, filter: { isDefault: true }) {
      records {
        id
        name
      }
    }
    workspace(input: $workspaceId) {
      id
      isBillable
    }
  }
`

/**
 * __useWorkspaceDefaultsQuery__
 *
 * To run a query within a React component, call `useWorkspaceDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceDefaultsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceDefaultsQuery(
  baseOptions: Apollo.QueryHookOptions<WorkspaceDefaultsQuery, WorkspaceDefaultsQueryVariables> &
    ({ variables: WorkspaceDefaultsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WorkspaceDefaultsQuery, WorkspaceDefaultsQueryVariables>(
    WorkspaceDefaultsDocument,
    options
  )
}
export function useWorkspaceDefaultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceDefaultsQuery, WorkspaceDefaultsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WorkspaceDefaultsQuery, WorkspaceDefaultsQueryVariables>(
    WorkspaceDefaultsDocument,
    options
  )
}
export function useWorkspaceDefaultsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    WorkspaceDefaultsQuery,
    WorkspaceDefaultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkspaceDefaultsQuery, WorkspaceDefaultsQueryVariables>(
    WorkspaceDefaultsDocument,
    options
  )
}
export type WorkspaceDefaultsQueryHookResult = ReturnType<typeof useWorkspaceDefaultsQuery>
export type WorkspaceDefaultsLazyQueryHookResult = ReturnType<typeof useWorkspaceDefaultsLazyQuery>
export type WorkspaceDefaultsSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceDefaultsSuspenseQuery
>
export type WorkspaceDefaultsQueryResult = ApolloReactCommon.QueryResult<
  WorkspaceDefaultsQuery,
  WorkspaceDefaultsQueryVariables
>
export const WorkspaceMembersDocument = gql`
  query WorkspaceMembers($workspaceId: ID!) {
    workspace(input: $workspaceId) {
      id
      members {
        ...WorkspaceMember
      }
    }
  }
  ${WorkspaceMemberFragmentDoc}
`

/**
 * __useWorkspaceMembersQuery__
 *
 * To run a query within a React component, call `useWorkspaceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceMembersQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceMembersQuery(
  baseOptions: Apollo.QueryHookOptions<WorkspaceMembersQuery, WorkspaceMembersQueryVariables> &
    ({ variables: WorkspaceMembersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WorkspaceMembersQuery, WorkspaceMembersQueryVariables>(
    WorkspaceMembersDocument,
    options
  )
}
export function useWorkspaceMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceMembersQuery, WorkspaceMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WorkspaceMembersQuery, WorkspaceMembersQueryVariables>(
    WorkspaceMembersDocument,
    options
  )
}
export function useWorkspaceMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    WorkspaceMembersQuery,
    WorkspaceMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkspaceMembersQuery, WorkspaceMembersQueryVariables>(
    WorkspaceMembersDocument,
    options
  )
}
export type WorkspaceMembersQueryHookResult = ReturnType<typeof useWorkspaceMembersQuery>
export type WorkspaceMembersLazyQueryHookResult = ReturnType<typeof useWorkspaceMembersLazyQuery>
export type WorkspaceMembersSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceMembersSuspenseQuery
>
export type WorkspaceMembersQueryResult = ApolloReactCommon.QueryResult<
  WorkspaceMembersQuery,
  WorkspaceMembersQueryVariables
>
export const WorkspaceOcctoSettingsDocument = gql`
  query WorkspaceOcctoSettings($workspaceId: ID!) {
    workspace(input: $workspaceId) {
      ...OcctoSettingsQueryData
    }
  }
  ${OcctoSettingsQueryDataFragmentDoc}
`

/**
 * __useWorkspaceOcctoSettingsQuery__
 *
 * To run a query within a React component, call `useWorkspaceOcctoSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceOcctoSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceOcctoSettingsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceOcctoSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceOcctoSettingsQuery,
    WorkspaceOcctoSettingsQueryVariables
  > &
    ({ variables: WorkspaceOcctoSettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WorkspaceOcctoSettingsQuery, WorkspaceOcctoSettingsQueryVariables>(
    WorkspaceOcctoSettingsDocument,
    options
  )
}
export function useWorkspaceOcctoSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceOcctoSettingsQuery,
    WorkspaceOcctoSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WorkspaceOcctoSettingsQuery, WorkspaceOcctoSettingsQueryVariables>(
    WorkspaceOcctoSettingsDocument,
    options
  )
}
export function useWorkspaceOcctoSettingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    WorkspaceOcctoSettingsQuery,
    WorkspaceOcctoSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WorkspaceOcctoSettingsQuery, WorkspaceOcctoSettingsQueryVariables>(
    WorkspaceOcctoSettingsDocument,
    options
  )
}
export type WorkspaceOcctoSettingsQueryHookResult = ReturnType<
  typeof useWorkspaceOcctoSettingsQuery
>
export type WorkspaceOcctoSettingsLazyQueryHookResult = ReturnType<
  typeof useWorkspaceOcctoSettingsLazyQuery
>
export type WorkspaceOcctoSettingsSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceOcctoSettingsSuspenseQuery
>
export type WorkspaceOcctoSettingsQueryResult = ApolloReactCommon.QueryResult<
  WorkspaceOcctoSettingsQuery,
  WorkspaceOcctoSettingsQueryVariables
>
export const ApiKeysDocument = gql`
  query ApiKeys($workspaceId: ID!) {
    workspace(input: $workspaceId) {
      ...ApiKeysQueryData
    }
  }
  ${ApiKeysQueryDataFragmentDoc}
`

/**
 * __useApiKeysQuery__
 *
 * To run a query within a React component, call `useApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useApiKeysQuery(
  baseOptions: Apollo.QueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables> &
    ({ variables: ApiKeysQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options)
}
export function useApiKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options)
}
export function useApiKeysSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options)
}
export type ApiKeysQueryHookResult = ReturnType<typeof useApiKeysQuery>
export type ApiKeysLazyQueryHookResult = ReturnType<typeof useApiKeysLazyQuery>
export type ApiKeysSuspenseQueryHookResult = ReturnType<typeof useApiKeysSuspenseQuery>
export type ApiKeysQueryResult = ApolloReactCommon.QueryResult<ApiKeysQuery, ApiKeysQueryVariables>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AcceptWorkspaceInviteProblems: ['ExpiredInvitationProblem', 'InvalidInvitationProblem'],
    AddAssetsToBalancingGroupProblems: [
      'AuthorizationProblem',
      'ResourceDoesNotExistProblem',
      'ResourceIsAlreadyInUseProblem',
    ],
    AddAssetsToPpaProblems: [
      'AuthorizationProblem',
      'ResourceDoesNotExistProblem',
      'ResourceIsAlreadyInUseProblem',
    ],
    AddAssetsToSpvProblems: [
      'AuthorizationProblem',
      'ResourceDoesNotExistProblem',
      'ResourceIsAlreadyInUseProblem',
    ],
    AddSolarAssetsPpaProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    ArchiveReportProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    BaseAsset: ['ReportSolarAsset'],
    BasePpa: ['ReportPpa'],
    ChangeUserPreferencesProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    ChangeUserRoleProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    ConfirmJepxTradeProblems: [
      'AuthorizationProblem',
      'NotWithinTimeFrameProblem',
      'ResourceDoesNotExistProblem',
    ],
    CopyBaselineProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    CopySolarAssetProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    CreateAssumptionProblems: ['AuthorizationProblem', 'ResourceAlreadyExistsProblem'],
    CreateBalancingGroupProblems: [
      'AuthorizationProblem',
      'BalancingGroupAlreadyExistsProblem',
      'MissingDependentsProblem',
      'ResourceDoesNotExistProblem',
      'ResourceIsAlreadyInUseProblem',
    ],
    CreateBaselineProblems: ['AuthorizationProblem', 'BaselineResourceDoesNotExistProblem'],
    CreatePpaProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceDoesNotExistProblem',
      'ResourceIsAlreadyInUseProblem',
    ],
    CreateReportProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    CreateScenarioProblems: [
      'AuthorizationProblem',
      'MissingDependentsProblem',
      'ResourceAlreadyExistsProblem',
    ],
    CreateSolarAssetProblems: ['AuthorizationProblem', 'SolarAssetAlreadyExistsProblem'],
    CreateSpvProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceIsAlreadyInUseProblem',
      'SpvResourceDoesNotExistProblem',
    ],
    CreateThirdPartyCompanyProblems: ['AuthorizationProblem', 'ResourceAlreadyExistsProblem'],
    CreateWorkspaceProblems: ['WorkspaceAlreadyExistsProblem', 'WorkspaceInvitationIdProblem'],
    DeleteAssumptionProblems: [
      'AuthorizationProblem',
      'ReplacementDoesNotExistProblem',
      'ReplacementRequiredProblem',
      'ResourceDoesNotExistProblem',
    ],
    DeleteBalancingGroupProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteManyBaselinesProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteManyPpasProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteManyReportsProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteManyScenariosProblems: [
      'AuthorizationProblem',
      'ResourceCannotBeDeletedProblem',
      'ResourceDoesNotExistProblem',
    ],
    DeleteManySolarAssetsProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteManyThirdPartyCompaniesProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeletePpaProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteReportProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteScenarioProblems: [
      'AuthorizationProblem',
      'ResourceCannotBeDeletedProblem',
      'ResourceDoesNotExistProblem',
    ],
    DeleteSolarAssetProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteSpvProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteThirdPartyCompanyProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    DeleteWorkspaceApiKeyProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    GenerateFinancialStatementProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    GenerateOcctoLongTermPlanUrlProblems: [
      'AuthorizationProblem',
      'GenerateOcctoPlanUrlApiProblem',
      'ResourceDoesNotExistProblem',
    ],
    GenerateOcctoPlanUrlProblems: [
      'AuthorizationProblem',
      'GenerateOcctoPlanUrlApiProblem',
      'ResourceDoesNotExistProblem',
    ],
    GeneratePresignedUrlProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    GenerateSolarAssetUploadActualsUrlsProblems: [
      'AuthorizationProblem',
      'ResourceDoesNotExistProblem',
    ],
    GenerateUploadGenerationDataUrlsProblems: [
      'AuthorizationProblem',
      'ResourceDoesNotExistProblem',
    ],
    GenerateWorkspaceApiKeyProblems: ['AuthorizationProblem', 'ResourceAlreadyExistsProblem'],
    MarkAllNotificationsAsReadProblems: ['AuthorizationProblem'],
    MarkNotificationAsReadProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    MarkNotificationAsUnreadProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    ModifySolarAssetPlanProblems: [
      'AuthorizationProblem',
      'ResourceDoesNotExistProblem',
      'SolarAssetPlanDoesNotMatchProblem',
    ],
    Notification: [
      'NotificationJepxOpenedPosition',
      'NotificationJepxTradeConfirmationReminder',
      'NotificationJepxTradeConfirmed',
      'NotificationJepxTradingReminder',
      'NotificationTSOPlanSubmissionReminder',
      'NotificationTSOPlanSubmitted',
    ],
    OpenCustomJepxPositionProblems: [
      'AuthorizationProblem',
      'NotWithinTimeFrameProblem',
      'ResourceDoesNotExistProblem',
    ],
    OpenPlannedJepxPositionProblems: [
      'AuthorizationProblem',
      'NotWithinTimeFrameProblem',
      'ResourceDoesNotExistProblem',
    ],
    ProblemInterface: [
      'AuthorizationProblem',
      'BalancingGroupAlreadyExistsProblem',
      'BaselineResourceDoesNotExistProblem',
      'BatteryValidationProblem',
      'CAPEXPaymentValidationProblem',
      'ConfirmCognitoEmailUpdateProblems',
      'ExpiredInvitationProblem',
      'GenerateOcctoPlanUrlApiProblem',
      'InvalidInvitationProblem',
      'LoginWithMagicLinkProblem',
      'MissingDependentsProblem',
      'NotWithinTimeFrameProblem',
      'ReplacementDoesNotExistProblem',
      'ReplacementRequiredProblem',
      'RequestCognitoEmailUpdateProblems',
      'ResourceAlreadyExistsProblem',
      'ResourceCannotBeDeletedProblem',
      'ResourceCannotBeModifiedProblem',
      'ResourceDoesNotExistProblem',
      'ResourceIsAlreadyInUseProblem',
      'ResourceIsAssignedProblem',
      'SolarAssetAlreadyExistsProblem',
      'SolarAssetLoadValidationProblem',
      'SolarAssetPlanDoesNotMatchProblem',
      'SolarAssetPropertiesCannotBeModifiedProblem',
      'SolarAssetResourceDoesNotExistProblem',
      'SpvInvestmentValidationProblem',
      'SpvLoanValidationProblem',
      'SpvResourceDoesNotExistProblem',
      'WorkspaceAlreadyExistsProblem',
      'WorkspaceInvitationIdProblem',
    ],
    RemoveAssetsFromBalancingGroupProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    RemoveAssetsFromPpaProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    RemoveAssetsFromSpvProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    RemoveProfilePictureProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    RemoveUserProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    RenameBalancingGroupProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceDoesNotExistProblem',
    ],
    RenameReportProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceDoesNotExistProblem',
    ],
    RenameThirdPartyCompanyProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceDoesNotExistProblem',
    ],
    ScenarioAssumption: [
      'ScenarioCurtailment',
      'ScenarioForecastMapeDayAhead',
      'ScenarioForecastMapeIntraday',
      'ScenarioInflation',
      'ScenarioPriceAverage',
      'ScenarioPriceVolatility',
    ],
    SendReportMagicLinkProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    SetReportAccessExpiryProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    ShareReportProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    SolarAssetOperationAlarmEvent: [
      'SolarAssetOperationAlarmEventBatteryFault',
      'SolarAssetOperationAlarmEventBatteryScheduledModified',
      'SolarAssetOperationAlarmEventHighLatency',
      'SolarAssetOperationAlarmEventLossBatteryCommunication',
      'SolarAssetOperationAlarmEventLossMeterCommunication',
      'SolarAssetOperationAlarmEventManualScheduleOperation',
    ],
    StarReportProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    SubmitOcctoPlanProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    TriggerSolarAssetSimulationProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    UnarchiveReportProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    UnshareReportProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    UnstarReportProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    UnsubmitOcctoPlanProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    UpdateAssumptionProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceCannotBeModifiedProblem',
      'ResourceDoesNotExistProblem',
    ],
    UpdateBalancingGroupProblems: [
      'AuthorizationProblem',
      'BalancingGroupAlreadyExistsProblem',
      'MissingDependentsProblem',
      'ResourceDoesNotExistProblem',
    ],
    UpdateBaselineProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    UpdatePpaProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceDoesNotExistProblem',
    ],
    UpdateReportLabelsProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    UpdateScenarioProblems: [
      'AuthorizationProblem',
      'MissingDependentsProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceCannotBeModifiedProblem',
      'ResourceDoesNotExistProblem',
    ],
    UpdateSolarAssetProblems: [
      'AuthorizationProblem',
      'BatteryValidationProblem',
      'CAPEXPaymentValidationProblem',
      'SolarAssetAlreadyExistsProblem',
      'SolarAssetLoadValidationProblem',
      'SolarAssetPropertiesCannotBeModifiedProblem',
      'SolarAssetResourceDoesNotExistProblem',
    ],
    UpdateSpvProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceIsAlreadyInUseProblem',
      'SpvInvestmentValidationProblem',
      'SpvLoanValidationProblem',
      'SpvResourceDoesNotExistProblem',
    ],
    UpdateUserAccountProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
    UpdateWorkspaceApiKeyProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceDoesNotExistProblem',
    ],
    UpdateWorkspaceProblems: [
      'AuthorizationProblem',
      'ResourceAlreadyExistsProblem',
      'ResourceDoesNotExistProblem',
    ],
    UploadProfilePictureProblems: ['AuthorizationProblem', 'ResourceDoesNotExistProblem'],
  },
}
export default result
