import { forwardRef, useState } from 'react'
import { mergeProps, useFocus, useObjectRef } from 'react-aria'
import { TextareaBox } from '../input-boxes'
import { TextareaBoxProps } from '../input-boxes/types'
import { FieldWrapper } from './common/FieldWrapper'
import { useFieldWrapperProps } from './common/useFieldWrapperProps'
import { FieldWrapperProps } from './types'

type BaseProps = Omit<FieldWrapperProps, 'id' | 'adornment' | 'inputElement'> &
  Omit<TextareaBoxProps, 'ref'> & {
    id?: string
    inputRef?: React.Ref<HTMLTextAreaElement>
  }
type NoCharCountProps = BaseProps & {
  hasCharacterCount?: never
}
type CharCountProps = BaseProps & {
  hasCharacterCount: boolean
  maxLength: number
}
export type TextareaFieldProps = NoCharCountProps | CharCountProps

export const TextareaField = forwardRef<HTMLDivElement, TextareaFieldProps>(
  ({ inputRef, ...props }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef)
    const { fieldWrapperProps, testProps, ...rest } = useFieldWrapperProps(props)
    const [isFocused, onFocusChange] = useState(false)
    const { focusProps } = useFocus({
      onFocusChange,
      isDisabled: props.isDisabled,
    })
    const [length, setLength] = useState(0)
    const charCountText = `${length}/${props.maxLength}`
    const showCharCount = props.hasCharacterCount && props.maxLength

    const mergedInputProps = mergeProps(rest, focusProps)
    const mergedWrapperProps = mergeProps(fieldWrapperProps, testProps)
    return (
      <FieldWrapper
        {...mergedWrapperProps}
        hasFocus={isFocused}
        characterCount={showCharCount ? charCountText : undefined}
        ref={ref}
        inputElement="textarea"
      >
        <TextareaBox
          {...mergedInputProps}
          aria-errormessage={fieldWrapperProps.errorId}
          aria-invalid={fieldWrapperProps.isError}
          id={fieldWrapperProps.id}
          ref={inputRef}
          size={fieldWrapperProps.size}
          isDisabled={props.isDisabled}
          setCharCount={showCharCount ? setLength : undefined}
        />
      </FieldWrapper>
    )
  }
)

TextareaField.displayName = 'TextareaField'
