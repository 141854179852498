import { FC } from 'react'
import { TestProps } from 'typ'
import { Button, ButtonProps } from '../button'
import { dialogButtonsCx } from './Dialog.css'

export interface DialogButtonProps
  extends TestProps,
    Pick<ButtonProps, 'onPress' | 'children' | 'isDisabled' | 'isLoading' | 'type'> {
  size?: ButtonProps['size']
}
export interface DialogConfirmButtonProps extends DialogButtonProps {
  color?: 'danger' | 'primary'
}

export const DialogCancelButton: FC<DialogButtonProps> = ({ children, ...props }) => (
  <Button variant="soft" color="neutral" size="md" className={dialogButtonsCx} {...props}>
    {children}
  </Button>
)

export const DialogConfirmButton: FC<DialogConfirmButtonProps> = ({
  children,
  color = 'primary',
  ...props
}) => (
  <Button variant="solid" color={color} size="md" className={dialogButtonsCx} {...props}>
    {children}
  </Button>
)
