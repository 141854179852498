import type { StepVariant } from 'ui'
import { MONITORING_DATA_UPLOAD_STEPS as STEPS } from '../constants'
import { MonitoringDataUploadStepId, OperationUploadsAcceptedFile, StoreSlice } from '../types'

type Step = {
  variant: StepVariant
  isActive: boolean
}
const INITIAL_STEPS: Step[] = [
  {
    variant: 'in-progress',
    isActive: true,
  },
  {
    variant: 'pending',
    isActive: false,
  },
]

export interface MonitoringDataUploadSlice {
  monitoringDataUploadSteps: Step[]
  monitoringDataAcceptedFiles: OperationUploadsAcceptedFile[]
  monitoringDataSelectedAssetId: string
  setMonitoringDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) => void
  addMonitoringDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) => void
  removeMonitoringDataAcceptedFile: (id: string) => void
  setMonitoringDataSelectedAssetId: (assetId: string) => void
  selectMonitoringDataUploadStep: (step: MonitoringDataUploadStepId) => void
  completeMonitoringAssetSelectionStep: VoidFunction
  resetMonitoringDataUpload: VoidFunction
  getMonitoringDataActiveStep: () => MonitoringDataUploadStepId
}

const initialValues = {
  monitoringDataUploadSteps: INITIAL_STEPS,
  monitoringDataAcceptedFiles: [],
  monitoringDataSelectedAssetId: '',
}

export const createMonitoringDataUploadSlice: StoreSlice<MonitoringDataUploadSlice> = (
  set,
  get
) => ({
  ...initialValues,
  setMonitoringDataSelectedAssetId: (assetId: string) =>
    set({ monitoringDataSelectedAssetId: assetId }),
  setMonitoringDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) =>
    set({ monitoringDataAcceptedFiles: files }),
  addMonitoringDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) => {
    const existingFiles = get().monitoringDataAcceptedFiles
    set({ monitoringDataAcceptedFiles: [...files, ...existingFiles] })
  },
  removeMonitoringDataAcceptedFile: (id: string) => {
    const files = get().monitoringDataAcceptedFiles
    set({
      monitoringDataAcceptedFiles: files.filter((f) => f.data.id !== id),
    })
  },
  completeMonitoringAssetSelectionStep: () => {
    const steps: Step[] = [
      {
        variant: 'completed',
        isActive: false,
      },
      {
        variant: 'in-progress',
        isActive: true,
      },
    ]
    set({ monitoringDataUploadSteps: steps })
  },
  selectMonitoringDataUploadStep: (step) => {
    const state = get()
    if (state.monitoringDataUploadSteps[0].variant !== 'completed') return
    const stepIndex = STEPS.indexOf(step)
    const steps = state.monitoringDataUploadSteps.map((s, i) => ({
      ...s,
      isActive: i === stepIndex,
    }))
    set({ monitoringDataUploadSteps: steps })
  },
  getMonitoringDataActiveStep: () => {
    const state = get()
    return STEPS[state.monitoringDataUploadSteps.findIndex((s) => s.isActive)]
  },
  resetMonitoringDataUpload: () => set(initialValues),
})
