import { DOCS_DEV_BASE_URL, DOCS_PROD_BASE_URL } from '../tensor-cloud/index'

const ENVIRONMENT = process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT

const DOCS_BASE_URL =
  ENVIRONMENT === 'production' || ENVIRONMENT === 'staging'
    ? DOCS_PROD_BASE_URL
    : DOCS_DEV_BASE_URL

export const getDocsLink = (link: string = '', lang: string = 'ja') =>
  lang === 'ja' ? `${DOCS_BASE_URL}${link}` : `${DOCS_BASE_URL}/en${link}`
