import type { StepVariant } from 'ui'
import {
  BG_ASSETS_FORM_INITIAL_VALUES,
  BG_ENTITY_FORM_INITIAL_VALUES,
  NEW_BG_CREATION_STEPS as STEPS,
} from '../constants'
import {
  BgAssetsFormValues,
  BgCreationFormValues,
  BgCreationSliceFormValues,
  BgFormValues,
  NewBgCreationStepId,
  StoreSlice,
} from '../types'

type Step = {
  errors: string[]
  variant: StepVariant
  isActive: boolean
  lastSubmitted: number
}

export type BgCreationDataSlice = BgCreationSliceFormValues & {
  bgCreationSteps: Step[]
  isBgCreationNameTaken: boolean
  isBgCreationCodeTaken: boolean
  isSubmittingBgCreationForm: boolean
  isBgCreationComplete: boolean
  bgCreatedId: string
  setBgCreatedId: (id: string) => void
  setIsBgCreationComplete: (value: boolean) => void
  setIsSubmittingBgCreationForm: (value: boolean) => void
  setBgSelectedAssets: (assets: string[]) => void
  setIsBgCreationNameTaken: (value: boolean) => void
  setIsBgCreationCodeTaken: (value: boolean) => void
  toggleBgCreationStepCompletion: (step: NewBgCreationStepId) => void
  updateBgCreationStepErrors: (step: NewBgCreationStepId, errors: string[]) => void
  selectBgCreationStep: (step: NewBgCreationStepId) => void
  resetBgCreationForm: VoidFunction
  setBgCreationFormValues: (values: BgFormValues) => void
  setBgCreationAssetsValues: (values: BgAssetsFormValues) => void
  getBgCreationFormData: () => BgCreationFormValues
  getBgCreationActiveStep: () => NewBgCreationStepId
  updateBgCreationLastSubmitted: (step: NewBgCreationStepId, ms?: number) => void
  resetBgCreationSectionErrors: (step: NewBgCreationStepId) => void
}

const INITIAL_STEPS: Step[] = [
  {
    errors: [],
    variant: 'in-progress',
    isActive: true,
    lastSubmitted: 0,
  },
  {
    errors: [],
    variant: 'pending',
    isActive: false,
    lastSubmitted: 0,
  },
]

const getInitialValues = () => ({
  isBgCreationNameTaken: false,
  isBgCreationCodeTaken: false,
  isSubmittingBgCreationForm: false,
  isBgCreationComplete: false,
  bgCreationSteps: INITIAL_STEPS,
  bgCreationFormValues: BG_ENTITY_FORM_INITIAL_VALUES,
  bgCreationAssetsValues: BG_ASSETS_FORM_INITIAL_VALUES,
  bgCreatedId: '',
})

export const createBgCreationDataSlice: StoreSlice<BgCreationDataSlice> = (set, get) => ({
  ...getInitialValues(),
  setBgCreatedId: (id) => set(() => ({ bgCreatedId: id })),
  setIsBgCreationComplete: (value: boolean) => set(() => ({ isBgCreationComplete: value })),
  setBgSelectedAssets: (assets) => set(() => ({ bgCreationAssetsValues: { assets } })),
  setIsSubmittingBgCreationForm: (value: boolean) =>
    set(() => ({ isSubmittingBgCreationForm: value })),
  setIsBgCreationNameTaken: (value: boolean) => set(() => ({ isBgCreationNameTaken: value })),
  setIsBgCreationCodeTaken: (value: boolean) => set(() => ({ isBgCreationCodeTaken: value })),
  toggleBgCreationStepCompletion: (step) => {
    const state = get()
    if (state.isSubmittingBgCreationForm || state.isBgCreationComplete) return
    const stepIndex = STEPS.indexOf(step)
    const steps: Step[] = state.bgCreationSteps.map((s, i) => {
      if (i <= stepIndex) {
        return {
          ...s,
          variant: 'completed',
          isActive: false,
          hasSumbitted: true,
        }
      }
      if (i === stepIndex + 1) {
        return {
          ...s,
          variant: s.variant === 'pending' ? 'in-progress' : s.variant,
          isActive: true,
        }
      }
      return s
    })
    set(() => ({ bgCreationSteps: steps }))
  },
  updateBgCreationStepErrors: (step, errors) => {
    const state = get()
    const stepIndex = STEPS.indexOf(step)
    const lastTouchedStep = state.bgCreationSteps.findIndex((s) => s.variant === 'in-progress')
    const steps: Step[] = state.bgCreationSteps.map((s, i) => {
      if (i === stepIndex) {
        return {
          ...s,
          errors,
          variant: errors.length
            ? 'error'
            : lastTouchedStep > stepIndex
              ? 'completed'
              : 'in-progress',
        }
      }
      return s
    })
    set(() => ({ bgCreationSteps: steps }))
  },
  selectBgCreationStep: (step) => {
    const state = get()
    if (state.isSubmittingBgCreationForm || state.isBgCreationComplete) return
    const stepIndex = STEPS.indexOf(step)
    const currentStepIndex = state.bgCreationSteps.findIndex((s) => s.isActive)
    const steps: Step[] = state.bgCreationSteps.map((s, i) => ({
      ...s,
      isActive: i === stepIndex,
      variant:
        i === currentStepIndex && !s.errors.length && stepIndex > currentStepIndex
          ? 'completed'
          : s.variant,
    }))
    set(() => ({
      bgCreationSteps: steps,
    }))
  },
  updateBgCreationLastSubmitted: (step, ms = Date.now()) => {
    const state = get()
    const stepIndex = STEPS.indexOf(step)
    const steps: Step[] = state.bgCreationSteps.map((s, i) => ({
      ...s,
      lastSubmitted: i === stepIndex ? ms : s.lastSubmitted,
    }))
    set(() => ({ bgCreationSteps: steps }))
  },
  setBgCreationFormValues: (values) => set(() => ({ bgCreationFormValues: values })),
  setBgCreationAssetsValues: (values) => set(() => ({ bgCreationAssetsValues: values })),
  getBgCreationFormData: () => {
    const state = get()
    return {
      ...state.bgCreationFormValues,
      ...state.bgCreationAssetsValues,
    }
  },
  getBgCreationActiveStep: () => {
    const state = get()
    return STEPS[state.bgCreationSteps.findIndex((s) => s.isActive)]
  },
  resetBgCreationSectionErrors: (step) => {
    const state = get()
    state.updateBgCreationStepErrors(step, [])
  },
  resetBgCreationForm: () => set(getInitialValues()),
})
