import { SolarAssetMapLayerType, StoreSlice } from '../types'

export interface SolarAssetMapSlice {
  solarAssetMapActiveLayer: SolarAssetMapLayerType
  solarAssetMapSetActiveLayer: (layer: SolarAssetMapLayerType) => void
  solarAssetMapShowTerrain: boolean
  solarAssetMapSetShowTerrain: (showTerrain: boolean) => void
  solarAssetMapShowTransmissionLines: boolean
  solarAssetMapSetShowTransmissionLines: (showTransmissionLines: boolean) => void
}

export const createSolarAssetMapSlice: StoreSlice<SolarAssetMapSlice> = (set, _get) => ({
  solarAssetMapActiveLayer: 'map',
  solarAssetMapSetActiveLayer: (layer) => set({ solarAssetMapActiveLayer: layer }),
  solarAssetMapShowTerrain: false,
  solarAssetMapSetShowTerrain: (showTerrain) => set({ solarAssetMapShowTerrain: showTerrain }),
  solarAssetMapShowTransmissionLines: false,
  solarAssetMapSetShowTransmissionLines: (showTransmissionLines) =>
    set({ solarAssetMapShowTransmissionLines: showTransmissionLines }),
})
